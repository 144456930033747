import {
  BLUE_BUTTON,
  FLAG_DISABLE_SSR_WARNING,
  SMALL_TEXT_FIELD,
  theme,
  WHITE_BUTTON,
} from "portal/utils/theme";
import {
  Button,
  InputAdornment,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { isUndefined } from "portal/utils/identity";
import { makeStyles } from "tss-react/mui";
import { numberOrEmptyString } from "portal/utils/forms";
import { useTranslation } from "react-i18next";
import Color from "color";
import CopyIcon from "@mui/icons-material/ContentCopyOutlined";
import PasteIcon from "@mui/icons-material/ContentPasteOutlined";
import React, { Fragment, FunctionComponent } from "react";

interface Props {
  copiedSizes: number[] | undefined;
  isProtected?: boolean;
  onChange: (sizes: number[]) => void;
  setCopiedSizes: (sizes: number[] | undefined) => void;
  sizes: number[];
}

export const CategoryThresholds: FunctionComponent<Props> = ({
  copiedSizes,
  isProtected = false,
  onChange,
  setCopiedSizes,
  sizes,
}) => {
  const { t } = useTranslation();
  const thumb1Color = new Color(theme.colors.violet["500"]);
  const thumb2Color = new Color(theme.colors.teal["500"]);

  // track the state for these two fields locally because we need to allow them
  // to be empty string to allow the user to delete the value but we don't want
  // to propagate that invalid state to the parent
  const [thumb1, setThumb1] = React.useState<number | "">(sizes[0] ?? "");
  const [thumb2, setThumb2] = React.useState<number | "">(sizes[1] ?? "");

  const useStyles = makeStyles()({
    root: {
      "&>*": {
        // color first thumb and label
        [`&:nth-child(1 of .MuiSlider-thumb), &:nth-child(3 of .MuiSlider-markLabel) ${FLAG_DISABLE_SSR_WARNING}`]:
          {
            color: `${thumb1Color.hex()} !important`,
          },
        [`&:nth-child(1 of .MuiSlider-thumb):hover ${FLAG_DISABLE_SSR_WARNING}`]:
          {
            boxShadow: `0px 0px 0px 8px ${thumb1Color
              .alpha(0.16)
              .rgb()} !important`,
          },

        // color second thumb and label
        [`&:nth-child(2 of .MuiSlider-thumb), &:nth-child(5 of .MuiSlider-markLabel) ${FLAG_DISABLE_SSR_WARNING}`]:
          {
            color: `${thumb2Color.hex()} !important`,
          },
        [`&:nth-child(2 of .MuiSlider-thumb):hover ${FLAG_DISABLE_SSR_WARNING}`]:
          {
            boxShadow: `0px 0px 0px 8px ${thumb2Color
              .alpha(0.16)
              .rgb()} !important`,
          },

        // move size labels above rail
        [`&:nth-child(2n of .MuiSlider-markLabel) ${FLAG_DISABLE_SSR_WARNING}`]:
          {
            marginTop: -50,
          },
      },
    },
  });

  const { classes } = useStyles();

  const thumb1DisplayValue = thumb1 || 0;
  const thumb2DisplayValue = thumb2 || 100;

  return (
    <>
      {/* Header */}
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-8 w-full gap-4">
        <Typography variant="h4" className="text-xl">
          {t("components.almanac.formulas.thresholds")}
        </Typography>
        <div className="flex items-center gap-2">
          <Button
            {...WHITE_BUTTON}
            startIcon={<CopyIcon />}
            onClick={() => setCopiedSizes(sizes)}
          >
            {t("components.almanac.formulas.copySize")}
          </Button>
          {copiedSizes && !isProtected && (
            <Button
              {...BLUE_BUTTON}
              startIcon={<PasteIcon />}
              onClick={() => onChange([...copiedSizes])}
            >
              {t("components.almanac.formulas.pasteSize")}
            </Button>
          )}
        </div>
      </div>

      {/* Text entry */}
      <div className="flex justify-evenly items-center">
        <TextField
          {...SMALL_TEXT_FIELD}
          type="number"
          className="w-28"
          disabled={isProtected}
          value={thumb1}
          InputProps={{
            classes: {
              ...SMALL_TEXT_FIELD.InputProps?.classes,
              notchedOutline: "border-violet-500 border-2",
            },
            endAdornment: (
              <InputAdornment position="end">
                {t("utils.units.mm")}
              </InputAdornment>
            ),
          }}
          onChange={(event) => {
            const value = numberOrEmptyString(event.currentTarget.value);
            setThumb1(value);
            if (typeof value === "number" && typeof thumb2 === "number") {
              onChange([value, thumb2]);
            }
          }}
        />
        <TextField
          {...SMALL_TEXT_FIELD}
          type="number"
          className="w-28"
          disabled={isProtected}
          value={sizes[1]}
          InputProps={{
            classes: {
              ...SMALL_TEXT_FIELD.InputProps?.classes,
              notchedOutline: "border-teal-500 border-2",
            },
            endAdornment: (
              <InputAdornment position="end">
                {t("utils.units.mm")}
              </InputAdornment>
            ),
          }}
          onChange={(event) => {
            const value = numberOrEmptyString(event.currentTarget.value);
            setThumb2(value);
            if (typeof value === "number" && typeof thumb1 === "number") {
              onChange([thumb1, value]);
            }
          }}
        />
      </div>

      {/* Slider */}
      <Slider
        track={false}
        min={0}
        max={100}
        disabled={isProtected}
        className={classes.root}
        classes={{
          root: "my-10",
          rail: "bg-white opacity-100",
          markLabel: "text-white",
          mark: "hidden",
        }}
        value={[thumb1DisplayValue, thumb2DisplayValue]}
        onChange={(event, value) => {
          if (typeof value === "number") {
            return;
          }
          const [thumb1Value, thumb2Value] = value;
          if (isUndefined(thumb1Value) || isUndefined(thumb2Value)) {
            return;
          }
          setThumb1(thumb1Value);
          setThumb2(thumb2Value);
          if (
            typeof thumb1Value === "number" &&
            typeof thumb2Value === "number"
          ) {
            onChange([thumb1Value, thumb2Value]);
          }
        }}
        marks={[
          { value: 0, label: `0${t("utils.units.mm")}` },
          {
            value: (thumb1 || 0) / 2,
            label: t("utils.descriptors.small"),
          },
          {
            value: thumb1DisplayValue,
            label: `${thumb1DisplayValue}${t("utils.units.mm")}`,
          },
          {
            value:
              thumb1DisplayValue +
              (thumb2DisplayValue - thumb1DisplayValue) / 2,
            label: t("utils.descriptors.medium"),
          },
          {
            value: thumb2DisplayValue,
            label: `${thumb2DisplayValue}${t("utils.units.mm")}`,
          },
          {
            value: thumb2DisplayValue + (100 - thumb2DisplayValue) / 2,
            label: t("utils.descriptors.large"),
          },
          { value: 100, label: `100${t("utils.units.mm")}` },
        ]}
      />
    </>
  );
};
