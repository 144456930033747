import { capitalize, titleCase } from "portal/utils/strings";
import {
  CategoryClassification,
  categoryClassificationFromJSON,
  ModelTrust,
} from "protos/almanac/almanac";
import {
  CROP_TRUSTS,
  getColumnStrings,
  WEED_TRUSTS,
} from "portal/components/modelinator/trusts";
import { findWhere, sortBy } from "portal/utils/arrays";
import { getTypeCategoryTitle, SIZE_INDEX } from "portal/utils/almanac";
import {
  PartitionedModelinator,
  StructuredCategory,
  StructuredModelinator,
  Uniformity,
} from "portal/utils/modelinator";
import { portalLanguageOrDefault } from "portal/i18nConstants";
import type { Crop } from "protos/portal/veselka";
import type { i18n as I18n, TFunction } from "i18next";

export const formatModelinator = (
  t: TFunction,
  i18n: I18n,
  modelinators: PartitionedModelinator,
  crops: Crop[],
  hasUnsavedChanges: boolean
): string => {
  const locale = portalLanguageOrDefault(i18n.language);
  const segmenter = new Intl.Segmenter(locale);
  const countGraphemeClusters = (s: string): number =>
    [...segmenter.segment(s)].length;
  const numberFormatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 1,
    maximumFractionDigits: 3,
  });

  const sizeLabels: Record<SIZE_INDEX, string> = {
    [SIZE_INDEX.SMALL]: t("utils.descriptors.small"),
    [SIZE_INDEX.MEDIUM]: t("utils.descriptors.medium"),
    [SIZE_INDEX.LARGE]: t("utils.descriptors.large"),
  };
  const allSizesLabel = t("components.almanac.formulas.all");
  const sizeLabelWidths = [...Object.values(sizeLabels), allSizesLabel].map(
    (s) => countGraphemeClusters(s)
  );
  // make sure it's even, for "personal reasons"
  const sizeLabelWidth = Math.ceil(Math.max(...sizeLabelWidths) / 2) * 2;
  const formatSizeLabel = (label: string): string => {
    const width = countGraphemeClusters(label);
    return " ".repeat(sizeLabelWidth - width) + label;
  };

  const formatCropsOrWeeds = (
    classification: CategoryClassification,
    modelinator: StructuredModelinator
  ): string => {
    switch (modelinator.type) {
      case Uniformity.SYNCED: {
        const { eachCategory } = modelinator;
        const allCategoriesLabel =
          classification ===
          categoryClassificationFromJSON(CategoryClassification.CATEGORY_CROP)
            ? t("components.almanac.cropsSynced")
            : t("components.almanac.weedsSynced");
        const body = formatCategory(classification, eachCategory);
        return [bold(allCategoriesLabel), body].join("\n\n");
      }
      case Uniformity.SPLIT: {
        const categories = sortBy(modelinator.categories, ({ type }) =>
          getTypeCategoryTitle(t, type, crops)
        ).map(({ type, category }) => {
          const label = getTypeCategoryTitle(t, type, crops);
          const body = formatCategory(classification, category);
          return [bold(label), body].join("\n\n");
        });
        return categories.join("\n\n");
      }
    }
  };

  const formatCategory = (
    classification: CategoryClassification,
    category: StructuredCategory
  ): string => {
    switch (category.type) {
      case Uniformity.SYNCED: {
        const { eachSize } = category;
        const allSizes = formatSizeLabel(allSizesLabel);
        const body = formatTrusts(classification, eachSize);
        return codeFence(`${allSizes}: ${body}`);
      }
      case Uniformity.SPLIT: {
        const { sizes } = category;
        const lines = sizes.map((trusts, index) => {
          const name = formatSizeLabel(sizeLabels[index as SIZE_INDEX]);
          const body = formatTrusts(classification, trusts);
          return `${name}: ${body}`;
        });
        return codeFence(lines.join("\n"));
      }
    }
  };

  // sample output: "MinDOO=0.3, WeedingThreshold=0.8, ReverseCropProtectThreshold=0.5"
  const formatTrusts = (
    classification: CategoryClassification,
    trusts: ModelTrust
  ): string => {
    const keys =
      classification ===
      categoryClassificationFromJSON(CategoryClassification.CATEGORY_CROP)
        ? CROP_TRUSTS
        : WEED_TRUSTS;
    const entries = keys.map((key: keyof ModelTrust): string => {
      const { label } = getColumnStrings(t, classification, key);
      const value = trusts[key] || 0;
      return `${wordsToPascal(label)}=${numberFormatter.format(value)}`;
    });
    return entries.join(", ");
  };

  const { modelId, cropId } = modelinators.metadata;

  const paragraphs: string[] = [];
  const crop = findWhere(crops, { id: cropId });
  const cropName = crop?.commonName ?? t("models.crops.categories.unknown");
  const headerLines: [string, string][] = [
    [t("models.models.model_one"), inlineCode(modelId)],
    [t("models.crops.crop_one"), `${cropName} (${inlineCode(cropId)})`],
  ];
  const header: string = headerLines
    .map(([key, value]) => [bold(`${titleCase(key)}:`), value].join(" "))
    .join("\n");
  paragraphs.push(header);

  if (hasUnsavedChanges) {
    const warning = t(
      "components.modelinator.warnings.exportingUnsavedChanges",
      { startEmphasis: "**", stopEmphasis: "**" }
    );
    paragraphs.push(`> ${warning}`);
  }
  {
    const { crops, weeds } = modelinators;
    paragraphs.push(
      formatCropsOrWeeds(
        categoryClassificationFromJSON(CategoryClassification.CATEGORY_CROP),
        crops
      ),
      formatCropsOrWeeds(
        categoryClassificationFromJSON(CategoryClassification.CATEGORY_WEED),
        weeds
      )
    );
  }
  return paragraphs.join("\n\n");
};

const wordsToPascal = (s: string): string =>
  s
    .split(" ")
    .map((s) => capitalize(s))
    .join("");

// using Jira markup
const bold = (text: string): string => `**${text}**`;
const inlineCode = (text: string): string => `\`${text}\``;
const codeFence = (text: string): string => ["```", text, "```"].join("\n");
