import { auth } from "./auth";
import { entries, isEmpty } from "portal/utils/objects";

export enum Method {
  DELETE = "DELETE",
  GET = "GET",
  PATCH = "PATCH",
  POST = "POST",
  PUT = "PUT",
}

export interface Pagination {
  limit?: number;
  page?: number;
  sort?: string;
}

// query string (?foo=1&bar=2)
export interface Query {
  [index: string]: string | string[] | boolean | number;
}

/**
 * Converts an Object of key/value pairs into a URL encoded query string
 */
export const buildQueryString = (query?: Query): string => {
  if (isEmpty(query)) {
    return "";
  }
  const result = entries(query).map(([key, value]) => {
    const encodedValue = Array.isArray(value) ? value.join(",") : String(value);
    return `${key}=${encodeURIComponent(encodedValue)}`;
  });

  return `?${result.join("&")}`;
};

export interface Download {
  file: Blob;
  name: string;
}

type ProgressCallback = (progress: number) => void;
class ProgressPromise<T = unknown> extends Promise<T> {
  private progressCallbacks: ProgressCallback[];

  constructor(
    executor: (
      resolve: (result: T) => void,
      reject: (error: any) => void,
      progress: ProgressCallback
    ) => void
  ) {
    super((resolve, reject) =>
      executor(resolve, reject, (progress: number) => {
        try {
          for (const callback of this.progressCallbacks) {
            callback(progress);
          }
        } catch (error) {
          reject(error);
        }
      })
    );
    this.progressCallbacks = [];
  }

  public progress(callback: ProgressCallback): ProgressPromise<T> {
    this.progressCallbacks.push(callback);
    return this;
  }
}

/**
 * get method which tracks progress
 */
export const download = (
  path: string,
  query?: Query
): ProgressPromise<Download> => {
  const xhr = new XMLHttpRequest();
  return new ProgressPromise<Download>(async (resolve, reject, progress) => {
    xhr.addEventListener("progress", (event) => {
      if (event.lengthComputable) {
        progress((event.loaded / event.total) * 100);
      }
    });
    xhr.addEventListener("error", reject);
    xhr.addEventListener("abort", reject);
    xhr.addEventListener("load", () => {
      resolve({
        file: xhr.response,
        name:
          xhr
            .getResponseHeader("Content-Disposition")
            ?.replace("attachment; filename=", "") ?? "unknown",
      });
    });
    xhr.open(Method.GET, `/v1${path}${buildQueryString(query)}`, true);
    const accessToken = await auth.getAccessTokenSilently();
    xhr.setRequestHeader("Authorization", `Bearer ${accessToken}`);
    xhr.responseType = "blob";
    xhr.send(undefined);
  });
};
