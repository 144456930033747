import { Alert, Button, Card, Typography } from "@mui/material";
import { capitalize } from "portal/utils/strings";
import { RED_BUTTON } from "portal/utils/theme";
import { useDeleteCachesMutation } from "portal/state/portalApi";
import { useMutationPopups } from "portal/utils/hooks/useApiPopups";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import ClearCachesIcon from "@mui/icons-material/RestartAlt";
import Grid from "@mui/system/Unstable_Grid";
import React, { FunctionComponent } from "react";

const ADMINS = new Set<string | undefined>([
  "ansel@carbonrobotics.com",
  "benw@carbonrobotics.com",
  "wchargin@carbonrobotics.com",
]);

export const AdminPortal: FunctionComponent = withAuthenticationRequired(
  function AdminPortal() {
    const { user, isInternal } = useSelf();
    const { t } = useTranslation();

    const [clearCaches] = useMutationPopups(useDeleteCachesMutation(), {
      success: capitalize(t("utils.actions.updated")),
    });

    if (!isInternal) {
      return;
    }

    return (
      <>
        {user && !ADMINS.has(user.email) && (
          <Alert severity="error" className="mb-4">
            {t("views.admin.portal.warnings.notPortalAdmin")}
          </Alert>
        )}
        <Alert severity="warning" className="mb-8">
          {t("views.admin.portal.warnings.global")}
        </Alert>
        <Grid container className="w-full items-stretch" spacing={2}>
          <Grid xs={12} lg={6}>
            <Card className="p-4 h-full flex flex-col gap-2">
              <Typography variant="h4" className="mb-2 text-lg">
                {t("views.admin.portal.clearCaches.action")}
              </Typography>
              <div>
                <p className="mt-0 mb-1">
                  {t("views.admin.portal.clearCaches.description")}
                </p>
                <Button
                  {...RED_BUTTON}
                  onClick={() => clearCaches()}
                  startIcon={<ClearCachesIcon />}
                >
                  {t("views.admin.portal.clearCaches.action")}
                </Button>
              </div>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }
);
