import { AlarmResponse } from "protos/portal/alarms";
import {
  AlmanacConfig,
  DiscriminatorConfig,
  ModelinatorConfig,
} from "protos/almanac/almanac";
import { APIErrorResponse } from "stream-chat";
import { auth, CarbonUser } from "portal/utils/auth";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import {
  BlocksByDateByRobotResponse,
  BlocksResponse,
} from "protos/portal/spatial";
import { camelToSnake, snakeToCamel } from "portal/utils/strings";
import { ConfigNode } from "protos/config/api/config_service";
import { ConfigResponse } from "protos/portal/configs";
import { Crop, Image, Model, RobotCrop } from "protos/portal/veselka";
import { CustomerResponse } from "protos/portal/customers";
import {
  DailyMetricResponse,
  DailyMetricsByDateByRobotResponse,
} from "protos/portal/metrics";
import { DateTime } from "luxon";
import {
  entries,
  isEmpty,
  isObject,
  transformKeys,
  values,
} from "portal/utils/objects";
import { FieldDefinition } from "protos/portal/geo";
import { findWhere, sortBy } from "portal/utils/arrays";
import { FleetView, UserResponse } from "protos/portal/users";
import { getModelinatorId } from "portal/utils/almanac";
import { GlobalAlarmlists, RobotAlarmlists } from "protos/portal/admin";
import { HardwareResponse } from "protos/portal/hardware";
import { HistoryResponse } from "protos/portal/health";
import { isUndefined } from "portal/utils/identity";
import { Message, MessagesResponse } from "protos/portal/messages";
import { Method, Pagination } from "portal/utils/api";
import { PortalJob } from "protos/portal/jobs";
import { ReportInstanceResponse, ReportResponse } from "protos/portal/reports";
import { RobotResponse, RobotSummaryResponse } from "protos/portal/robots";
import { robotSort } from "portal/utils/robots";
import { SerializedError } from "@reduxjs/toolkit";
import { sortByName } from "portal/utils/configs";
import { TagDescription } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { TFunction } from "i18next";
import { toQuery } from "portal/utils/browser";
import { TVEProfile } from "protos/target_velocity_estimator/target_velocity_estimator";

enum Tag {
  ADMIN_ALARM = "Admin/Alarm",
  ADMIN_ALMANAC = "Admin/Almananc",
  ADMIN_TARGET_VELOCITY_ESTIMATOR = "Admin/TargetVelocityEstimator",
  ALARM = "Alarm",
  ALMANAC = "Almananc",
  CONFIG = "Config",
  CONFIG_TEMPLATE = "ConfigTemplate",
  CROP = "Crop",
  CUSTOMER = "Customer",
  DISCRIMINATOR = "Discriminator",
  FIELD_DEFINITION = "FieldDefinition",
  IMAGE = "Image",
  JOB_METRIC = "Job/Metric",
  MESSAGE = "Message",
  METRIC = "Metric",
  MODEL = "Model",
  MODELINDATOR = "Modelinator",
  REPORT = "Report",
  REPORT_INSTANCE = "ReportInstance",
  ROBOT = "Robot",
  ROBOT_CROP = "Robot/Crop",
  ROBOT_HARDWARE = "Robot/Hardware",
  ROBOT_JOB = "Robot/Job",
  SPATIAL = "SPATIAL",
  TARGET_VELOCITY_ESTIMATOR = "TargetVelocityEstimator",
  USER = "User",
  FLEET_VIEW = "FleetView",
}

interface ErrorResponse {
  error?: string;
}

export const formatError = (
  t: TFunction,
  error: FetchBaseQueryError | SerializedError | string | undefined
): string | undefined => {
  if (isUndefined(error)) {
    return;
  }
  if (typeof error === "string") {
    return error;
  }
  if ("message" in error) {
    return error.message;
  }
  if ("data" in error && isObject(error.data) && "error" in error.data) {
    return error.data.error;
  }
  if ("error" in error) {
    return error.error;
  }
  if ("status" in error) {
    return String(error.status);
  }
  return t("components.ErrorBoundary.error");
};

export const isAPIErrorResponse = (error: unknown): error is APIErrorResponse =>
  isObject(error) && "StatusCode" in error;

export const handleError = (
  t: TFunction,
  isError: boolean,
  error: FetchBaseQueryError | SerializedError | undefined,
  callback: (message?: string) => void
): void => {
  if (!isError) {
    return;
  }
  const fallback = t("components.ErrorBoundary.error");
  if (!error || !("data" in error)) {
    callback(fallback);
    return;
  }
  callback((error.data as ErrorResponse).error ?? fallback);
};

const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (arguments_, api, extraOptions = {}) => {
  return await fetchBaseQuery({
    baseUrl: "/v1/",
    prepareHeaders: async (headers) => {
      const accessToken = await auth.getAccessTokenSilently();
      headers.set("Authorization", `Bearer ${accessToken}`);
      headers.set("X-AUTH0-audience", window._jsenv.REACT_APP_AUTH0_AUDIENCE);
      headers.set(
        "X-CARBON-Feature-Flags",
        JSON.stringify([
          "MetricValidCrops",
          "EncodingProtoJSON",
          "MetricOperatorEffectiveness",
        ])
      );

      const now = DateTime.local();
      if (now.zoneName) {
        headers.set("Timezone", now.zoneName);
      }
      return headers;
    },
    paramsSerializer: toQuery,
  })(arguments_, api, extraOptions);
};

export const portalApi = createApi({
  reducerPath: "portalApi",
  baseQuery,
  tagTypes: values(Tag),
  endpoints: (builder) => ({
    // AWS
    getS3UploadUrl: builder.query<string, string>({
      query: (filename) => ({
        url: `aws/s3/upload`,
        params: { filename },
      }),
      transformResponse: ({ url }: { url: string }) => url,
    }),

    // ALARMS
    listAlarms: builder.query<AlarmResponse[], void>({
      query: () => ({
        url: `alarms`,
      }),
      transformResponse: (response: AlarmResponse[]) =>
        response.map((alarm) => AlarmResponse.fromJSON(alarm)),
      providesTags: (result) =>
        result
          ? result.map((alarm) => ({ type: Tag.ALARM, id: alarm.db?.id }))
          : [],
    }),

    // HARDWARE
    getHardware: builder.query<HardwareResponse, void>({
      query: () => `hardware`,
      transformResponse: (response: HardwareResponse) =>
        HardwareResponse.fromJSON(response),
      providesTags: (result) => {
        const tags: TagDescription<any>[] = [];
        const robots = new Map<number, boolean>();
        if (result?.lasers) {
          for (const laser of result.lasers) {
            robots.set(laser.robotId, true);
          }
        }
        for (const robotId of robots.keys()) {
          tags.push({
            type: Tag.ROBOT_HARDWARE,
            id: robotId,
          });
        }
        return tags;
      },
    }),

    // CONFIGS
    getConfig: builder.query<
      ConfigResponse,
      [string, { fetchFirst?: boolean; noCache?: boolean }]
    >({
      query: ([serial, options]) => {
        if (options.noCache) {
          portalApi.util.invalidateTags([{ type: Tag.CONFIG, id: serial }]);
        }
        return { url: `configs/${serial}`, params: options };
      },
      transformResponse: (response: ConfigResponse) => {
        const { config, template, ...result } =
          ConfigResponse.fromJSON(response);
        return {
          ...result,
          config: config ? sortByName(config) : undefined,
          template: template ? sortByName(template) : undefined,
        };
      },
      providesTags: (result, error, [serial]) => [
        { type: Tag.CONFIG, id: serial },
      ],
    }),
    setConfigValue: builder.mutation<undefined, [string, string, any]>({
      query: ([serial, path, value]) => ({
        url: `configs/${serial}`,
        method: Method.POST,
        body: { path, value },
      }),
      invalidatesTags: (result, error, [serial]) => [
        { type: Tag.CONFIG, id: serial },
      ],
    }),
    deleteConfigPath: builder.mutation<undefined, [string, string]>({
      query: ([serial, path]) => ({
        url: `configs/${serial}/${path}`,
        method: Method.DELETE,
      }),
      invalidatesTags: (result, error, [serial]) => [
        { type: Tag.CONFIG, id: serial },
      ],
    }),
    getConfigTemplate: builder.query<ConfigNode, string>({
      query: (robotClass) => ({ url: `configs/templates/${robotClass}` }),
      transformResponse: (response: ConfigNode) => {
        const result = ConfigNode.fromJSON(response);
        return sortByName(result);
      },
      providesTags: (result, error, [robotClass]) => [
        { type: Tag.CONFIG_TEMPLATE, id: robotClass },
      ],
    }),
    setConfigTemplateValue: builder.mutation<undefined, [string, string, any]>({
      query: ([robotClass, path, value]) => ({
        url: `configs/templates/${robotClass}`,
        method: Method.POST,
        body: { path, value, timestamp: Date.now() },
      }),
      invalidatesTags: (result, error, [robotClass]) => [
        { type: Tag.CONFIG_TEMPLATE, id: robotClass },
      ],
    }),
    deleteConfigTemplatePath: builder.mutation<undefined, [string, string]>({
      query: ([robotClass, path]) => ({
        url: `configs/templates/${robotClass}`,
        method: Method.DELETE,
        body: {
          path,
          timestamp: Date.now(),
        },
      }),
      invalidatesTags: (result, error, [robotClass]) => [
        { type: Tag.CONFIG_TEMPLATE, id: robotClass },
      ],
    }),

    // CUSTOMERS
    listCustomers: builder.query<CustomerResponse[], void>({
      query: () => "customers",
      transformResponse: (response: CustomerResponse[]) =>
        sortBy(response, "name").map((customer) =>
          CustomerResponse.fromJSON(customer)
        ),
      providesTags: (customers) => [
        Tag.CUSTOMER,
        ...(customers?.map((customer) => ({
          type: Tag.CUSTOMER,
          id: customer.db?.id,
        })) ?? []),
      ],
    }),
    getCustomer: builder.query<CustomerResponse | undefined, number>({
      query: () => "customers",
      transformResponse: (response: CustomerResponse[] = [], _, id) => {
        const customer = response.find((customer) => customer.db?.id === id);
        return customer ? CustomerResponse.fromJSON(customer) : undefined;
      },
      providesTags: (customer) => [
        { type: Tag.CUSTOMER, id: customer?.db?.id },
      ],
    }),
    createCustomer: builder.mutation<
      CustomerResponse,
      Omit<CustomerResponse, "db" | "featureFlags">
    >({
      query: (customer) => ({
        url: "customers",
        method: Method.POST,
        body: CustomerResponse.toJSON(CustomerResponse.fromPartial(customer)),
      }),
      transformResponse: (customer) => CustomerResponse.fromJSON(customer),
      invalidatesTags: (customer) => [
        Tag.CUSTOMER,
        { type: Tag.CUSTOMER, id: customer?.db?.id },
      ],
    }),
    updateCustomer: builder.mutation<
      CustomerResponse,
      [number, Omit<CustomerResponse, "db" | "featureFlags">]
    >({
      query: ([customerId, customer]) => ({
        url: `customers/${customerId}`,
        method: Method.POST,
        body: CustomerResponse.toJSON(CustomerResponse.fromPartial(customer)),
      }),
      transformResponse: (customer) => CustomerResponse.fromJSON(customer),
      invalidatesTags: (result, error, [id]) => [{ type: Tag.CUSTOMER, id }],
    }),

    // MESSAGES
    listMessages: builder.query<Message[], [string, Pagination?]>({
      query: ([serial, pagination]) => ({
        url: `messages/robot/${serial}`,
        params: pagination,
      }),
      transformResponse: (response: MessagesResponse) => {
        const { messages } = MessagesResponse.fromJSON(response);
        return sortBy(messages, (message) => message.db?.createdAt ?? 0);
      },
      providesTags: (messages) => [
        Tag.MESSAGE,
        ...(messages?.map((message) => ({
          type: Tag.MESSAGE,
          id: message.db?.id,
        })) ?? []),
      ],
    }),
    sendMessage: builder.mutation<Message, [string, string]>({
      query: ([serial, message]) => ({
        url: `messages/robot/${serial}`,
        method: Method.POST,
        body: { message },
      }),
      transformResponse: (response: Message) => Message.fromJSON(response),
      invalidatesTags: (message) => [
        Tag.MESSAGE,
        { type: Tag.MESSAGE, id: message?.db?.id },
      ],
    }),

    // REPORTS
    listReports: builder.query<ReportResponse[], void>({
      query: () => "reports",
      transformResponse: (response: ReportResponse[]) =>
        response.map((report) => ReportResponse.fromJSON(report)),
      providesTags: (reports) => [
        Tag.REPORT,
        ...(reports?.map((report) => ({
          type: Tag.REPORT,
          id: report.slug,
        })) ?? []),
      ],
    }),
    getReport: builder.query<ReportResponse, [string, { instance?: string }]>({
      query: ([reportSlug, options]) => ({
        url: `reports/${reportSlug}`,
        params: options,
      }),
      transformResponse: (response: ReportResponse) =>
        ReportResponse.fromJSON(response),
      providesTags: (report) => [{ type: Tag.REPORT, id: report?.slug }],
    }),
    createReport: builder.mutation<ReportResponse, ReportResponse>({
      query: (report) => ({
        url: "reports/",
        method: Method.POST,
        body: ReportResponse.toJSON(ReportResponse.fromPartial(report)),
      }),
      transformResponse: (response: ReportResponse) =>
        ReportResponse.fromJSON(response),
      invalidatesTags: (report) => [
        Tag.REPORT,
        { type: Tag.REPORT, id: report?.slug },
      ],
    }),
    updateReport: builder.mutation<ReportResponse, ReportResponse>({
      query: (report) => ({
        url: `reports/${report.slug}`,
        method: Method.POST,
        body: ReportResponse.toJSON(ReportResponse.fromPartial(report)),
      }),
      transformResponse: (response: ReportResponse) =>
        ReportResponse.fromJSON(response),
      invalidatesTags: (report) => [{ type: Tag.REPORT, id: report?.slug }],
    }),
    deleteReport: builder.mutation<void, string>({
      query: (slug) => ({
        url: `reports/${slug}`,
        method: Method.DELETE,
      }),
      invalidatesTags: (result, error, slug) => [
        { type: Tag.REPORT, id: slug },
      ],
    }),
    listReportInstances: builder.query<ReportInstanceResponse[], string>({
      query: (slug) => `reports/${slug}/runs`,
      transformResponse: (response: ReportInstanceResponse[]) =>
        response.map((instance) => ReportInstanceResponse.fromJSON(instance)),
      providesTags: (instances) => [
        Tag.REPORT_INSTANCE,
        ...(instances?.map((instance) => ({
          type: Tag.REPORT_INSTANCE,
          id: instance.slug,
        })) ?? []),
      ],
    }),
    getReportInstance: builder.query<ReportInstanceResponse, [string, string]>({
      query: ([reportSlug, instanceSlug]) =>
        `reports/${reportSlug}/runs/${instanceSlug}`,
      transformResponse: (response: ReportInstanceResponse) =>
        ReportInstanceResponse.fromJSON(response),
      providesTags: (instance) => [
        { type: Tag.REPORT_INSTANCE, id: instance?.slug },
      ],
    }),
    createReportInstance: builder.mutation<
      ReportInstanceResponse,
      [string, ReportInstanceResponse]
    >({
      query: ([reportSlug, instance]) => ({
        url: `reports/${reportSlug}/runs`,
        method: Method.POST,
        body: ReportInstanceResponse.toJSON(
          ReportInstanceResponse.fromPartial(instance)
        ),
      }),
      transformResponse: (response: ReportInstanceResponse) =>
        ReportInstanceResponse.fromJSON(response),
      invalidatesTags: (instance) => [
        Tag.REPORT_INSTANCE,
        { type: Tag.REPORT_INSTANCE, id: instance?.slug },
      ],
    }),
    updateReportInstance: builder.mutation<
      ReportInstanceResponse,
      [string, ReportInstanceResponse]
    >({
      query: ([reportSlug, instance]) => ({
        url: `reports/${reportSlug}/runs/${instance.slug}`,
        method: Method.POST,
        body: ReportInstanceResponse.toJSON(
          ReportInstanceResponse.fromPartial(instance)
        ),
      }),
      transformResponse: (response: ReportInstanceResponse) =>
        ReportInstanceResponse.fromJSON(response),
      invalidatesTags: (instance) => [
        { type: Tag.REPORT_INSTANCE, id: instance?.slug },
      ],
    }),
    deleteReportInstance: builder.mutation<void, [string, string]>({
      query: ([reportSlug, reportInstanceSlug]) => ({
        url: `reports/${reportSlug}/runs/${reportInstanceSlug}`,
        method: Method.DELETE,
      }),
      invalidatesTags: (result, error, [, reportInstanceSlug]) => [
        { type: Tag.REPORT_INSTANCE, id: reportInstanceSlug },
      ],
    }),
    getDateMetrics: builder.query<
      DailyMetricsByDateByRobotResponse,
      { serials: string[]; dates: string[]; instance?: string }
    >({
      query: (parameters) => ({
        url: "metrics/certified",
        params: parameters,
      }),
      transformResponse: (response: DailyMetricsByDateByRobotResponse) =>
        DailyMetricsByDateByRobotResponse.fromJSON(response),
      providesTags: (response) => {
        const tags: TagDescription<Tag>[] = [];
        for (const [serial, metricsByDate] of entries(
          response?.metrics ?? {}
        )) {
          for (const [date] of entries(metricsByDate.metrics)) {
            tags.push({ type: Tag.METRIC, id: `${serial}-${date}` });
          }
        }
        return tags;
      },
    }),
    getSpatial: builder.query<
      BlocksByDateByRobotResponse,
      { serials: string[]; dates: string[] }
    >({
      query: (parameters) => ({
        url: "metrics/spatial",
        params: parameters,
      }),
      transformResponse: (response: BlocksByDateByRobotResponse) =>
        BlocksByDateByRobotResponse.fromJSON(response),
      providesTags: (response) => {
        const tags: TagDescription<Tag>[] = [];
        for (const [serial, blocksByDate] of entries(response?.blocks ?? {})) {
          for (const [date] of entries(blocksByDate.blocks)) {
            tags.push({ type: Tag.SPATIAL, id: `${serial}-${date}` });
          }
        }
        return tags;
      },
    }),

    // ROBOTS
    listRobots: builder.query<
      RobotSummaryResponse[],
      {
        showOffline?: boolean;
        showInternal?: boolean;
        instance?: string;
        latestMetrics?: boolean;
      }
    >({
      query: ({
        showOffline = true,
        showInternal = true,
        instance,
        latestMetrics = true,
      }) => ({
        url: `robots`,
        params: { showOffline, showInternal, instance, latestMetrics },
      }),
      transformResponse: (response: RobotSummaryResponse[] = []) => {
        response.sort(robotSort);
        for (const summary of response) {
          if (summary.config) {
            summary.config = sortByName(ConfigNode.fromJSON(summary.config));
          }
        }
        return response.map((summary) =>
          RobotSummaryResponse.fromJSON(summary)
        );
      },
      providesTags: (summaries) => [
        Tag.ROBOT,
        ...(summaries?.map((summary) => ({
          type: Tag.ROBOT,
          id: summary.robot?.db?.id,
        })) ?? []),
      ],
    }),
    getRobot: builder.query<RobotSummaryResponse, string>({
      query: (serial) => `robots/${serial}`,
      providesTags: (summary) => [
        { type: Tag.ROBOT, id: summary?.robot?.db?.id },
        ...(summary?.alarmList
          ? summary.alarmList.map((alarm) => ({
              type: Tag.ALARM,
              id: alarm.db?.id,
            }))
          : []),
      ],
      transformResponse: (summary: RobotSummaryResponse) =>
        RobotSummaryResponse.fromJSON(summary),
    }),
    getRobotHistory: builder.query<HistoryResponse, [string, string]>({
      query: ([serial, date]) => `robots/${serial}/history/${date}`,
      transformResponse: (response: HistoryResponse) =>
        HistoryResponse.fromJSON(response),
    }),
    getRobotBlocks: builder.query<BlocksResponse, [string, string]>({
      query: ([serial, date]) => `robots/${serial}/blocks/${date}`,
      transformResponse: (response: BlocksResponse) =>
        BlocksResponse.fromJSON(response),
    }),
    getRobotMetrics: builder.query<DailyMetricResponse, [string, string]>({
      query: ([serial, date]) => `robots/${serial}/metrics/${date}`,
      transformResponse: (response: DailyMetricResponse) =>
        DailyMetricResponse.fromJSON(response),
      providesTags: (result, error, [serial, date]) => [
        { type: Tag.METRIC, id: `${serial}-${date}` },
      ],
    }),
    createRobot: builder.mutation<
      RobotResponse,
      { serial: string; copyFrom: string }
    >({
      query: (parameters) => ({
        url: "robots",
        method: Method.POST,
        body: parameters,
      }),
      transformResponse: (summary: RobotResponse) =>
        RobotResponse.fromJSON(summary),
      invalidatesTags: (robot) => [
        Tag.ROBOT,
        { type: Tag.ROBOT, id: robot?.db?.id },
      ],
    }),
    updateRobot: builder.mutation<
      RobotResponse,
      Pick<RobotResponse, "serial"> &
        Partial<Pick<RobotResponse, "implementationStatus" | "supportSlack">>
    >({
      query: (robot) => ({
        url: `robots/${robot.serial}`,
        method: Method.POST,
        body: RobotResponse.toJSON(RobotResponse.fromPartial(robot)),
      }),
      transformResponse: (summary: RobotResponse) =>
        RobotResponse.fromJSON(summary),
      invalidatesTags: (robot) => [
        Tag.ROBOT,
        { type: Tag.ROBOT, id: robot?.db?.id },
      ],
    }),
    assignRobot: builder.mutation<RobotResponse, [string, number]>({
      query: ([serial, customerId]) => ({
        url: `robots/${serial}/assign`,
        method: Method.POST,
        body: { customerId },
      }),
      transformResponse: (summary: RobotResponse) =>
        RobotResponse.fromJSON(summary),
      invalidatesTags: (robot) => [{ type: Tag.ROBOT, id: robot?.db?.id }],
    }),
    getRobotProxy: builder.mutation<RobotResponse, string>({
      query: (serial) => `robots/${serial}/remote`,
      transformResponse: (summary: RobotResponse) =>
        RobotResponse.fromJSON(summary),
    }),
    listRobotCrops: builder.query<RobotCrop[], string>({
      query: (serial) => `robots/${serial}/crops`,
      transformResponse: (response: RobotCrop[]) =>
        response.map((crop) => RobotCrop.fromJSON(crop)),
      providesTags: (robotCrops) => [
        Tag.ROBOT_CROP,
        ...(robotCrops?.map(({ crop }) => ({
          type: Tag.ROBOT_CROP,
          id: crop?.id,
        })) ?? []),
      ],
    }),
    listRobotJobs: builder.query<PortalJob[], [string, string, string]>({
      query: ([serial, startDate, endDate]) =>
        `robots/${serial}/jobs/${startDate}/${endDate}`,
      transformResponse: (response: PortalJob[]) =>
        response.map((job) => PortalJob.fromJSON(job)),
      providesTags: (jobs) => [
        Tag.ROBOT_JOB,
        ...(jobs?.map((job) => ({
          type: Tag.ROBOT_JOB,
          id: job.jobId,
        })) ?? []),
      ],
    }),
    getJob: builder.query<PortalJob, string>({
      query: (jobId) => `jobs/${jobId}`,
      transformResponse: (response: PortalJob) => PortalJob.fromJSON(response),
      providesTags: (job) => [
        Tag.ROBOT_JOB,
        { type: Tag.ROBOT_JOB, id: job?.jobId },
        { type: Tag.JOB_METRIC, id: job?.jobId },
      ],
    }),
    getJobHistory: builder.query<HistoryResponse, string>({
      query: (jobId) => `jobs/${jobId}/history`,
      transformResponse: (response: HistoryResponse) =>
        HistoryResponse.fromJSON(response),
    }),
    getJobBlocks: builder.query<BlocksResponse, string>({
      query: (jobId) => `jobs/${jobId}/blocks`,
      transformResponse: (response: BlocksResponse) =>
        BlocksResponse.fromJSON(response),
    }),
    getRobotHardware: builder.query<HardwareResponse, string>({
      query: (serial) => `robots/${serial}/hardware`,
      transformResponse: (response: HardwareResponse) =>
        HardwareResponse.fromJSON(response),
      providesTags: (robotCrops, error, serial) => [
        Tag.ROBOT_HARDWARE,
        {
          type: Tag.ROBOT_HARDWARE,
          id: serial,
        },
      ],
    }),

    // FIELD DEFINITIONS
    createFieldDefinition: builder.mutation<
      FieldDefinition,
      {
        serial: string;
        fieldDefinition: Required<
          Omit<FieldDefinition, "db" | "fieldId" | "robotId">
        >;
      }
    >({
      query: ({ serial, fieldDefinition }) => ({
        url: `fields/robots/${serial}/definitions`,
        method: "POST",
        body: FieldDefinition.toJSON(
          FieldDefinition.fromPartial(fieldDefinition)
        ),
      }),
      transformResponse: (response: FieldDefinition) =>
        FieldDefinition.fromJSON(response),
      invalidatesTags: (field) => [
        Tag.FIELD_DEFINITION,
        { type: Tag.FIELD_DEFINITION, id: field?.fieldId },
      ],
    }),

    // USERS
    listUsers: builder.query<CarbonUser[], void>({
      query: () => "users",
      transformResponse: (response: CarbonUser[]) =>
        transformKeys(response, snakeToCamel),
      providesTags: (users) => [
        Tag.USER,
        ...(users?.map((user) => ({
          type: Tag.USER,
          id: user.email,
        })) ?? []),
      ],
    }),
    getUser: builder.query<UserResponse, string>({
      query: (id) => {
        return `users/${id}`;
      },
      transformResponse: (response: UserResponse) =>
        UserResponse.fromJSON(response),
      providesTags: (response) => {
        const tags = [{ type: Tag.USER, id: response?.user?.userId }];
        if (!isEmpty(response?.customer)) {
          tags.push({
            type: Tag.CUSTOMER,
            id: String(response?.customer?.db?.id),
          });
        }
        for (const fleetView of response?.fleetViews ?? []) {
          if (fleetView.db) {
            tags.push({
              type: Tag.FLEET_VIEW,
              id: String(fleetView.db.id),
            });
          }
        }
        return tags;
      },
    }),
    updateUser: builder.mutation<undefined, [string, CarbonUser]>({
      query: ([id, user]) => {
        const { appMetadata, userMetadata } = user;
        return {
          url: `users/${id}`,
          method: Method.POST,
          body: {
            ...transformKeys(user, camelToSnake),
            app_metadata: appMetadata,
            user_metadata: userMetadata,
          },
        };
      },
      invalidatesTags: (result, error, [id]) => [{ type: Tag.USER, id }],
    }),
    deleteUser: builder.mutation<undefined, string>({
      query: (id) => ({
        url: `users/${id}`,
        method: Method.DELETE,
      }),
      invalidatesTags: (result, error, [id]) => [{ type: Tag.USER, id }],
    }),
    inviteUser: builder.mutation<undefined, [string, number | undefined]>({
      query: ([email, customerId]) => ({
        url: "users/invite",
        method: Method.POST,
        body: { email, customerId },
      }),
      invalidatesTags: (result, error, [email]) => [
        Tag.USER,
        { type: Tag.USER, id: email },
      ],
    }),
    createFleetView: builder.mutation<FleetView, [string, FleetView]>({
      query: ([id, fleetView]) => {
        return {
          url: `users/${id}/fleetviews`,
          method: Method.POST,
          body: FleetView.toJSON(FleetView.fromPartial(fleetView)),
        };
      },
      transformResponse: (fleetView: FleetView) =>
        FleetView.fromJSON(fleetView),
      invalidatesTags: (fleetView) =>
        fleetView?.db ? [{ type: Tag.FLEET_VIEW, id: fleetView.db.id }] : [],
    }),
    updateFleetView: builder.mutation<undefined, [string, FleetView]>({
      query: ([id, fleetView]) => {
        return {
          url: `users/${id}/fleetviews/${fleetView.db?.id}`,
          method: Method.POST,
          body: FleetView.toJSON(FleetView.fromPartial(fleetView)),
        };
      },
      invalidatesTags: (result, error, [, fleetView]) =>
        fleetView.db ? [{ type: Tag.FLEET_VIEW, id: fleetView.db.id }] : [],
    }),
    deleteFleetView: builder.mutation<undefined, [string, number]>({
      query: ([userId, fleetViewId]) => ({
        url: `users/${userId}/fleetviews/${fleetViewId}`,
        method: Method.DELETE,
      }),
      invalidatesTags: (result, error, [, fleetViewId]) => [
        { type: Tag.FLEET_VIEW, id: fleetViewId },
      ],
    }),

    // VESELKA
    listUploads: builder.query<Image[], [string, Pagination?]>({
      query: ([serial, pagination]) => ({
        url: `veselka/uploads/${serial}`,
        params: pagination,
      }),
      transformResponse: (images: Image[]) =>
        images.map((image) => Image.fromJSON(image)),
      providesTags: (images) => [
        Tag.IMAGE,
        ...(images?.map((image) => ({
          type: Tag.IMAGE,
          id: image.id,
        })) ?? []),
      ],
    }),
    listCrops: builder.query<Crop[], void>({
      query: () => "veselka/models/crops",
      transformResponse: (crops: Crop[]) =>
        crops.map((crop) => Crop.fromJSON(crop)),
      providesTags: (crops) => [
        Tag.CROP,
        ...(crops?.map((crop) => ({
          type: Tag.CROP,
          id: crop.id,
        })) ?? []),
      ],
    }),
    getCrop: builder.query<Crop | undefined, string>({
      query: () => "veselka/models/crops",
      providesTags: (crop) =>
        crop
          ? [
              {
                type: Tag.CROP,
                id: crop.id,
              },
            ]
          : [],
      transformResponse: (crops: Crop[], error, cropId) => {
        const crop = findWhere(crops, { id: cropId });
        if (!crop) {
          return;
        }
        return Crop.fromJSON(crop);
      },
    }),
    getModel: builder.query<Model, string>({
      query: (modelId) => `veselka/models/${modelId}`,
      transformResponse: (model: Model) => Model.fromJSON(model),
      providesTags: (result, error, id) => [{ type: Tag.MODEL, id }],
    }),

    // ADMIN
    deleteCaches: builder.mutation<void, void>({
      query: () => ({
        url: `/admin/caches`,
        method: Method.DELETE,
      }),
    }),
    getGlobalAlarmLists: builder.query<GlobalAlarmlists, void>({
      query: () => "/admin/alarms/lists",
      transformResponse: (response: GlobalAlarmlists) =>
        GlobalAlarmlists.fromJSON(response),
      providesTags: () => [
        {
          type: Tag.ADMIN_ALARM,
          id: -1,
        },
      ],
    }),
    getRobotAlarmLists: builder.query<RobotAlarmlists, string>({
      query: (serial) => `/admin/alarms/lists/${serial}`,
      transformResponse: (response: RobotAlarmlists) =>
        RobotAlarmlists.fromJSON(response),
      providesTags: (result, error, serial) => [
        {
          type: Tag.ADMIN_ALARM,
          id: serial,
        },
      ],
    }),
    setGlobalAlarmLists: builder.mutation<
      GlobalAlarmlists,
      Omit<GlobalAlarmlists, "db">
    >({
      query: (lists) => ({
        url: "admin/alarms/lists",
        method: Method.POST,
        body: GlobalAlarmlists.toJSON(lists),
      }),
      transformResponse: (response: GlobalAlarmlists) =>
        GlobalAlarmlists.fromJSON(response),
      invalidatesTags: () => [
        {
          type: Tag.ADMIN_ALARM,
          id: -1,
        },
      ],
    }),
    setRobotAlarmLists: builder.mutation<
      GlobalAlarmlists,
      [string, Omit<RobotAlarmlists, "db">]
    >({
      query: ([serial, lists]) => ({
        url: `admin/alarms/lists/${serial}`,
        method: Method.POST,
        body: RobotAlarmlists.toJSON(lists),
      }),
      transformResponse: (response: GlobalAlarmlists) =>
        RobotAlarmlists.fromJSON(response),
      invalidatesTags: (result, error, [serial]) => [
        {
          type: Tag.ADMIN_ALARM,
          id: serial,
        },
      ],
    }),

    // ALMANANC
    listAlmanacsForRobot: builder.query<AlmanacConfig[], string>({
      query: (serial) => `/profiles/almanacs/robots/${serial}`,
      transformResponse: (response: AlmanacConfig[]) =>
        response.map((almanac) => AlmanacConfig.fromJSON(almanac)),
      providesTags: (almanacs) =>
        almanacs?.map((almanac) => ({
          type: Tag.ALMANAC,
          id: almanac.id,
        })) ?? [],
    }),
    getAlmanac: builder.query<AlmanacConfig, string>({
      query: (uuid) => `/profiles/almanacs/profiles/${uuid}`,
      transformResponse: (response: AlmanacConfig) =>
        AlmanacConfig.fromJSON(response),
      providesTags: (result, error, uuid) => [
        {
          type: Tag.ALMANAC,
          id: uuid,
        },
      ],
    }),
    deleteAlmanac: builder.mutation<void, string>({
      query: (uuid) => ({
        url: `/profiles/almanacs/profiles/${uuid}`,
        method: Method.DELETE,
      }),
      invalidatesTags: (result, error, uuid) => [
        { type: Tag.ALMANAC, id: uuid },
      ],
    }),
    setAlmanac: builder.mutation<AlmanacConfig, [string, AlmanacConfig]>({
      query: ([serial, almanac]) => ({
        url: `/profiles/almanacs/robots/${serial}`,
        method: Method.POST,
        body: AlmanacConfig.toJSON(almanac),
      }),
      transformResponse: (response: AlmanacConfig) =>
        AlmanacConfig.fromJSON(response),
      invalidatesTags: (almanac) =>
        almanac
          ? [
              {
                type: Tag.ALMANAC,
                id: almanac.id,
              },
            ]
          : [],
    }),
    // any string is valid for the input. Just matching the signature of
    // listAlmanacsForRobot so they can be used interchangeably
    listGlobalAlmanacs: builder.query<AlmanacConfig[], string>({
      query: () => `/profiles/almanacs/global`,
      transformResponse: (response: AlmanacConfig[]) =>
        response.map((almanac) => AlmanacConfig.fromJSON(almanac)),
      providesTags: (almanacs) =>
        almanacs?.map((almanac) => ({
          type: Tag.ADMIN_ALMANAC,
          id: almanac.id,
        })) ?? [],
    }),
    getGlobalAlmanac: builder.query<AlmanacConfig, string>({
      query: (uuid) => `/profiles/almanacs/global/${uuid}`,
      transformResponse: (response: AlmanacConfig) =>
        AlmanacConfig.fromJSON(response),
      providesTags: (result, error, uuid) => [
        {
          type: Tag.ADMIN_ALMANAC,
          id: uuid,
        },
      ],
    }),
    deleteGlobalAlmanac: builder.mutation<void, string>({
      query: (uuid) => ({
        url: `/profiles/almanacs/global/${uuid}`,
        method: Method.DELETE,
      }),
      invalidatesTags: (result, error, uuid) => [
        { type: Tag.ADMIN_ALMANAC, id: uuid },
      ],
    }),
    setGlobalAlmanac: builder.mutation<AlmanacConfig, [string, AlmanacConfig]>({
      query: ([_, almanac]) => {
        return {
          url: `/profiles/almanacs/global/${almanac.id}`,
          method: Method.POST,
          body: AlmanacConfig.toJSON(almanac),
        };
      },
      transformResponse: (response: AlmanacConfig) =>
        AlmanacConfig.fromJSON(response),
      invalidatesTags: (almanac) =>
        almanac
          ? [
              {
                type: Tag.ADMIN_ALMANAC,
                id: almanac.id,
              },
            ]
          : [],
    }),

    // DISCRIMINATOR
    listDiscriminatorsForRobot: builder.query<DiscriminatorConfig[], string>({
      query: (serial) => `/profiles/discriminators/robots/${serial}`,
      transformResponse: (response: DiscriminatorConfig[]) =>
        response.map((discriminator) =>
          DiscriminatorConfig.fromJSON(discriminator)
        ),
      providesTags: (discriminators) =>
        discriminators?.map((discrimininator) => ({
          type: Tag.DISCRIMINATOR,
          id: discrimininator.id,
        })) ?? [],
    }),
    getDiscriminator: builder.query<DiscriminatorConfig, string>({
      query: (uuid) => `/profiles/discriminators/profiles/${uuid}`,
      transformResponse: (response: DiscriminatorConfig) =>
        DiscriminatorConfig.fromJSON(response),
      providesTags: (result, error, uuid) => [
        {
          type: Tag.DISCRIMINATOR,
          id: uuid,
        },
      ],
    }),
    deleteDiscriminator: builder.mutation<void, string>({
      query: (uuid) => ({
        url: `/profiles/discriminators/profiles/${uuid}`,
        method: Method.DELETE,
      }),
      invalidatesTags: (result, error, uuid) => [
        { type: Tag.DISCRIMINATOR, id: uuid },
      ],
    }),
    setDiscriminator: builder.mutation<
      DiscriminatorConfig,
      [string, DiscriminatorConfig]
    >({
      query: ([serial, discriminator]) => ({
        url: `/profiles/discriminators/robots/${serial}`,
        method: Method.POST,
        body: DiscriminatorConfig.toJSON(discriminator),
      }),
      transformResponse: (response: DiscriminatorConfig) =>
        DiscriminatorConfig.fromJSON(response),
      invalidatesTags: (discriminator) =>
        discriminator
          ? [
              {
                type: Tag.DISCRIMINATOR,
                id: discriminator.id,
              },
            ]
          : [],
    }),

    // MODELINATOR
    listModelinatorsForRobotAndCrop: builder.query<
      ModelinatorConfig[],
      [string, string]
    >({
      query: ([serial, cropId]) =>
        `/profiles/modelinators/robots/${serial}/crops/${cropId}`,
      transformResponse: (response: ModelinatorConfig[]) =>
        response.map((modelinator) => ModelinatorConfig.fromJSON(modelinator)),
      providesTags: (modelinators, error, [serial]) =>
        modelinators?.map((modelinator) => ({
          type: Tag.MODELINDATOR,
          id: getModelinatorId(serial, modelinator),
        })) ?? [],
    }),
    getModelinator: builder.query<
      ModelinatorConfig | undefined,
      [string, string, string]
    >({
      query: ([serial, cropId]) =>
        `/profiles/modelinators/robots/${serial}/crops/${cropId}`,
      transformResponse: (
        modelinators: ModelinatorConfig[],
        error,
        [, cropId, modelId]
      ) => {
        const modelinator = findWhere(modelinators, { cropId, modelId });
        if (!modelinator) {
          return;
        }
        return ModelinatorConfig.fromJSON(modelinator);
      },
      providesTags: (modelinator, error, [serial]) =>
        modelinator
          ? [
              {
                type: Tag.MODELINDATOR,
                id: getModelinatorId(serial, modelinator),
              },
            ]
          : [],
    }),
    setModelinator: builder.mutation<
      ModelinatorConfig,
      [string, ModelinatorConfig]
    >({
      query: ([serial, modelinator]) => ({
        url: `/profiles/modelinators/robots/${serial}/`,
        method: Method.POST,
        body: ModelinatorConfig.toJSON(modelinator),
      }),
      transformResponse: (response: ModelinatorConfig) =>
        ModelinatorConfig.fromJSON(response),
      invalidatesTags: (modelinator, error, [serial]) =>
        modelinator
          ? [
              {
                type: Tag.MODELINDATOR,
                id: getModelinatorId(serial, modelinator),
              },
            ]
          : [],
    }),

    // TARGET VELOCITY ESTIMATOR
    // any string is valid for the input. Just matching the signature of
    // listTargetVelocityEstimators so they can be used interchangeably
    listGlobalTargetVelocityEstimators: builder.query<TVEProfile[], string>({
      query: () => `/profiles/velocity/global`,
      transformResponse: (response: TVEProfile[]) =>
        response.map((targetVelocityEstimator) =>
          TVEProfile.fromJSON(targetVelocityEstimator)
        ),
      providesTags: (targetVelocityEstimators) =>
        targetVelocityEstimators?.map((targetVelocityEstimator) => ({
          type: Tag.TARGET_VELOCITY_ESTIMATOR,
          id: targetVelocityEstimator.id,
        })) ?? [],
    }),
    getGlobalTargetVelocityEstimator: builder.query<TVEProfile, string>({
      query: (uuid) => `/profiles/velocity/global/${uuid}`,
      transformResponse: (response: TVEProfile) =>
        TVEProfile.fromJSON(response),
      providesTags: (result, error, uuid) => [
        {
          type: Tag.ADMIN_TARGET_VELOCITY_ESTIMATOR,
          id: uuid,
        },
      ],
    }),
    deleteTargetVelocityEstimator: builder.mutation<void, string>({
      query: (uuid) => ({
        url: `/profiles/velocity/profiles/${uuid}`,
        method: Method.DELETE,
      }),
      invalidatesTags: (result, error, uuid) => [
        { type: Tag.TARGET_VELOCITY_ESTIMATOR, id: uuid },
      ],
    }),
    deleteGlobalTargetVelocityEstimator: builder.mutation<void, string>({
      query: (uuid) => ({
        url: `/profiles/velocity/global/${uuid}`,
        method: Method.DELETE,
      }),
      invalidatesTags: (result, error, uuid) => [
        { type: Tag.ADMIN_TARGET_VELOCITY_ESTIMATOR, id: uuid },
      ],
    }),
    setGlobalTargetVelocityEstimator: builder.mutation<
      TVEProfile,
      [string, TVEProfile]
    >({
      query: ([, targetVelocityEstimator]) => {
        return {
          url: `/profiles/velocity/global/${targetVelocityEstimator.id}`,
          method: Method.POST,
          body: TVEProfile.toJSON(targetVelocityEstimator),
        };
      },
      transformResponse: (response: TVEProfile) =>
        TVEProfile.fromJSON(response),
      invalidatesTags: (targetVelocityEstimator) =>
        targetVelocityEstimator
          ? [
              {
                type: Tag.ADMIN_TARGET_VELOCITY_ESTIMATOR,
                id: targetVelocityEstimator.id,
              },
            ]
          : [],
    }),
    listTargetVelocityEstimators: builder.query<TVEProfile[], string>({
      query: (serial) => `/profiles/velocity/robots/${serial}`,
      transformResponse: (response: TVEProfile[]) =>
        response.map((targetVelocityEstimator) =>
          TVEProfile.fromJSON(targetVelocityEstimator)
        ),
      providesTags: (targetVelocityEstimators) =>
        targetVelocityEstimators?.map((targetVelocityEstimator) => ({
          type: Tag.TARGET_VELOCITY_ESTIMATOR,
          id: targetVelocityEstimator.id,
        })) ?? [],
    }),
    getTargetVelocityEstimator: builder.query<TVEProfile, string>({
      query: (uuid) => `/profiles/velocity/profiles/${uuid}`,
      transformResponse: (response: TVEProfile) =>
        TVEProfile.fromJSON(response),
      providesTags: (targetVelocityTestimator) =>
        targetVelocityTestimator
          ? [
              {
                type: Tag.TARGET_VELOCITY_ESTIMATOR,
                id: targetVelocityTestimator.id,
              },
            ]
          : [],
    }),
    setTargetVelocityEstimator: builder.mutation<
      TVEProfile,
      [string, TVEProfile]
    >({
      query: ([serial, targetVelocityEstimator]) => ({
        url: `/profiles/velocity/robots/${serial}`,
        method: Method.POST,
        body: TVEProfile.toJSON(targetVelocityEstimator),
      }),
      transformResponse: (response: TVEProfile) =>
        TVEProfile.fromJSON(response),
      invalidatesTags: (targetVelocityEstimator) =>
        targetVelocityEstimator
          ? [
              {
                type: Tag.TARGET_VELOCITY_ESTIMATOR,
                id: targetVelocityEstimator.id,
              },
            ]
          : [],
    }),
  }),
});

export const {
  // AWS
  useLazyGetS3UploadUrlQuery,

  // ALARMS
  useListAlarmsQuery,

  // HARDWARE
  useGetHardwareQuery,

  // CONFIGS
  useLazyGetConfigQuery,
  useGetConfigQuery,
  useSetConfigValueMutation,
  useDeleteConfigPathMutation,
  useLazyGetConfigTemplateQuery,
  useGetConfigTemplateQuery,
  useSetConfigTemplateValueMutation,
  useDeleteConfigTemplatePathMutation,

  // CUSTOMERS
  useListCustomersQuery,
  useGetCustomerQuery,
  useCreateCustomerMutation,
  useUpdateCustomerMutation,

  // MESSAGES
  useListMessagesQuery,
  useSendMessageMutation,

  // REPORTS
  useListReportsQuery,
  useGetReportQuery,
  useCreateReportMutation,
  useDeleteReportMutation,
  useUpdateReportMutation,
  useListReportInstancesQuery,
  useGetReportInstanceQuery,
  useCreateReportInstanceMutation,
  useUpdateReportInstanceMutation,
  useDeleteReportInstanceMutation,

  // METRICS
  useLazyGetDateMetricsQuery,
  useLazyGetSpatialQuery,

  // ROBOTS
  useListRobotsQuery,
  useLazyListRobotsQuery,
  useGetRobotQuery,
  useGetRobotHistoryQuery,
  useGetRobotBlocksQuery,
  useLazyGetRobotBlocksQuery,
  useGetRobotMetricsQuery,
  useLazyGetRobotMetricsQuery,
  useCreateRobotMutation,
  useUpdateRobotMutation,
  useAssignRobotMutation,
  useGetRobotProxyMutation,
  useListRobotCropsQuery,
  useListRobotJobsQuery,
  useGetJobQuery,
  useGetJobHistoryQuery,
  useGetJobBlocksQuery,
  useGetRobotHardwareQuery,
  useLazyListRobotJobsQuery,

  // FIELD DEFINITIONS
  useCreateFieldDefinitionMutation,

  // USERS
  useListUsersQuery,
  useGetUserQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useInviteUserMutation,
  useCreateFleetViewMutation,
  useUpdateFleetViewMutation,
  useDeleteFleetViewMutation,

  // VESELKA
  useListUploadsQuery,
  useListCropsQuery,
  useGetCropQuery,
  useGetModelQuery,
  useLazyListUploadsQuery,

  // ADMIN
  useDeleteCachesMutation,
  useGetGlobalAlarmListsQuery,
  useGetRobotAlarmListsQuery,
  useSetGlobalAlarmListsMutation,
  useSetRobotAlarmListsMutation,

  // ALMANAC
  useListAlmanacsForRobotQuery,
  useGetAlmanacQuery,
  useDeleteAlmanacMutation,
  useSetAlmanacMutation,
  useListGlobalAlmanacsQuery,
  useGetGlobalAlmanacQuery,
  useDeleteGlobalAlmanacMutation,
  useSetGlobalAlmanacMutation,

  // DISCRIMINATOR
  useListDiscriminatorsForRobotQuery,
  useGetDiscriminatorQuery,
  useDeleteDiscriminatorMutation,
  useSetDiscriminatorMutation,

  // MODELINATOR
  useListModelinatorsForRobotAndCropQuery,
  useGetModelinatorQuery,
  useSetModelinatorMutation,

  // TARGET VELOCITY ESTIMATOR
  useDeleteGlobalTargetVelocityEstimatorMutation,
  useDeleteTargetVelocityEstimatorMutation,
  useGetGlobalTargetVelocityEstimatorQuery,
  useGetTargetVelocityEstimatorQuery,
  useListGlobalTargetVelocityEstimatorsQuery,
  useListTargetVelocityEstimatorsQuery,
  useSetGlobalTargetVelocityEstimatorMutation,
  useSetTargetVelocityEstimatorMutation,
} = portalApi;
