import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  formatImplementationStatus,
  ImplementationStatus,
  toImplementationStatus,
} from "portal/utils/robots";
import {
  SMALL_INPUT_DARK,
  SMALL_LABEL,
  SMALL_SELECT_DARK,
} from "portal/utils/theme";
import { useTranslation } from "react-i18next";
import { values } from "portal/utils/objects";
import React, { FunctionComponent } from "react";

interface Props {
  value: ImplementationStatus[];
  onChange: (statuses: ImplementationStatus[]) => void;
}

export const StatusSelector: FunctionComponent<Props> = ({
  onChange,
  value,
}) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth className="mt-1">
      <InputLabel {...SMALL_LABEL}>{t("views.fleet.robots.status")}</InputLabel>
      <Select
        {...SMALL_SELECT_DARK}
        input={
          <OutlinedInput
            {...SMALL_INPUT_DARK}
            label={t("views.fleet.robots.status")}
          />
        }
        defaultValue={[]}
        multiple
        value={value}
        onChange={(event: SelectChangeEvent<ImplementationStatus[]>) => {
          const { value } = event.target;
          onChange(
            // On autofill we get a stringified value.
            typeof value === "string"
              ? value.split(",").map((status) => toImplementationStatus(status))
              : value
          );
        }}
        renderValue={(value) =>
          value
            .map((status) => formatImplementationStatus(t, status))
            .join(", ")
        }
      >
        {values(ImplementationStatus).map((status) => (
          <MenuItem value={status} key={status}>
            <Checkbox checked={value.includes(status)} />
            <ListItemText
              primary={formatImplementationStatus(
                t,
                toImplementationStatus(status)
              )}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
