import { AlarmTable } from "portal/components/AlarmTable";
import { NoScroll } from "portal/components/Page";
import { useListAlarmsQuery } from "portal/state/portalApi";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { FunctionComponent } from "react";

export const FleetAlarmList: FunctionComponent = withAuthenticationRequired(
  function FleetAlarmList() {
    const { data: alarms } = useQueryPopups(useListAlarmsQuery());

    return (
      <NoScroll>
        <AlarmTable alarms={alarms} fleet className="flex-1 flex-grow-0" />
      </NoScroll>
    );
  }
);
