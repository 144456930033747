// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.19.4
// source: portal/robots.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { ConfigNode } from "../config/api/config_service";
import { Status, statusFromJSON, statusToJSON } from "../frontend/status_bar";
import { AlarmResponse } from "./alarms";
import { CustomerResponse } from "./customers";
import { DB } from "./db";
import { FieldConfig, HealthLog, Location, Performance } from "./health";
import { DailyMetricResponse } from "./metrics";

export const protobufPackage = "carbon.portal.robots";

export interface CachedRobotHealth {
  location: Location | undefined;
  model: string;
  performance: Performance | undefined;
  reportedAt: number;
  status: Status;
  statusChangedAt: number;
  /**
   * DEPRECATED: use crop_id
   *
   * @deprecated
   */
  crop: string;
  p2p: string;
  softwareVersion: string;
  targetVersion: string;
  targetVersionReady: boolean;
  metricTotals: { [key: string]: number };
  fieldConfig: FieldConfig | undefined;
  cropId: string;
  robotRuntime240v: number;
  activeAlarmCount: number;
  timezone: string;
}

export interface CachedRobotHealth_MetricTotalsEntry {
  key: string;
  value: number;
}

export interface RobotResponse {
  db: DB | undefined;
  health: CachedRobotHealth | undefined;
  serial: string;
  implementationStatus: string;
  featureFlags: { [key: string]: boolean };
  customerSerial: string;
  supportSlack: string;
}

export interface RobotResponse_FeatureFlagsEntry {
  key: string;
  value: boolean;
}

export interface RobotSummaryResponse {
  robot:
    | RobotResponse
    | undefined;
  /**
   * DEPRECATED: use alarm_list instead
   *
   * @deprecated
   */
  alarms: AlarmResponse[];
  healthLogs: HealthLog[];
  customer: CustomerResponse | undefined;
  alarmList: AlarmResponse[];
  dailyMetrics: DailyMetricResponse | undefined;
  config: ConfigNode | undefined;
}

export interface RobotSummaryListReponse {
  robots: RobotSummaryResponse[];
  total: number;
}

export interface RemoteResponse {
  host: string;
}

function createBaseCachedRobotHealth(): CachedRobotHealth {
  return {
    location: undefined,
    model: "",
    performance: undefined,
    reportedAt: 0,
    status: 0,
    statusChangedAt: 0,
    crop: "",
    p2p: "",
    softwareVersion: "",
    targetVersion: "",
    targetVersionReady: false,
    metricTotals: {},
    fieldConfig: undefined,
    cropId: "",
    robotRuntime240v: 0,
    activeAlarmCount: 0,
    timezone: "",
  };
}

export const CachedRobotHealth: MessageFns<CachedRobotHealth> = {
  encode(message: CachedRobotHealth, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.location !== undefined) {
      Location.encode(message.location, writer.uint32(18).fork()).join();
    }
    if (message.model !== "") {
      writer.uint32(26).string(message.model);
    }
    if (message.performance !== undefined) {
      Performance.encode(message.performance, writer.uint32(42).fork()).join();
    }
    if (message.reportedAt !== 0) {
      writer.uint32(48).int64(message.reportedAt);
    }
    if (message.status !== 0) {
      writer.uint32(72).int32(message.status);
    }
    if (message.statusChangedAt !== 0) {
      writer.uint32(80).int64(message.statusChangedAt);
    }
    if (message.crop !== "") {
      writer.uint32(90).string(message.crop);
    }
    if (message.p2p !== "") {
      writer.uint32(98).string(message.p2p);
    }
    if (message.softwareVersion !== "") {
      writer.uint32(106).string(message.softwareVersion);
    }
    if (message.targetVersion !== "") {
      writer.uint32(114).string(message.targetVersion);
    }
    if (message.targetVersionReady !== false) {
      writer.uint32(120).bool(message.targetVersionReady);
    }
    Object.entries(message.metricTotals).forEach(([key, value]) => {
      CachedRobotHealth_MetricTotalsEntry.encode({ key: key as any, value }, writer.uint32(138).fork()).join();
    });
    if (message.fieldConfig !== undefined) {
      FieldConfig.encode(message.fieldConfig, writer.uint32(154).fork()).join();
    }
    if (message.cropId !== "") {
      writer.uint32(170).string(message.cropId);
    }
    if (message.robotRuntime240v !== 0) {
      writer.uint32(176).uint32(message.robotRuntime240v);
    }
    if (message.activeAlarmCount !== 0) {
      writer.uint32(184).int64(message.activeAlarmCount);
    }
    if (message.timezone !== "") {
      writer.uint32(194).string(message.timezone);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CachedRobotHealth {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCachedRobotHealth();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.location = Location.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.model = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.performance = Performance.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.reportedAt = longToNumber(reader.int64());
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.statusChangedAt = longToNumber(reader.int64());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.crop = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.p2p = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.softwareVersion = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.targetVersion = reader.string();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.targetVersionReady = reader.bool();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          const entry17 = CachedRobotHealth_MetricTotalsEntry.decode(reader, reader.uint32());
          if (entry17.value !== undefined) {
            message.metricTotals[entry17.key] = entry17.value;
          }
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.fieldConfig = FieldConfig.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.cropId = reader.string();
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.robotRuntime240v = reader.uint32();
          continue;
        case 23:
          if (tag !== 184) {
            break;
          }

          message.activeAlarmCount = longToNumber(reader.int64());
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.timezone = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CachedRobotHealth {
    return {
      location: isSet(object.location) ? Location.fromJSON(object.location) : undefined,
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      performance: isSet(object.performance) ? Performance.fromJSON(object.performance) : undefined,
      reportedAt: isSet(object.reportedAt) ? globalThis.Number(object.reportedAt) : 0,
      status: isSet(object.status) ? statusFromJSON(object.status) : 0,
      statusChangedAt: isSet(object.statusChangedAt) ? globalThis.Number(object.statusChangedAt) : 0,
      crop: isSet(object.crop) ? globalThis.String(object.crop) : "",
      p2p: isSet(object.p2p) ? globalThis.String(object.p2p) : "",
      softwareVersion: isSet(object.softwareVersion) ? globalThis.String(object.softwareVersion) : "",
      targetVersion: isSet(object.targetVersion) ? globalThis.String(object.targetVersion) : "",
      targetVersionReady: isSet(object.targetVersionReady) ? globalThis.Boolean(object.targetVersionReady) : false,
      metricTotals: isObject(object.metricTotals)
        ? Object.entries(object.metricTotals).reduce<{ [key: string]: number }>((acc, [key, value]) => {
          acc[key] = Number(value);
          return acc;
        }, {})
        : {},
      fieldConfig: isSet(object.fieldConfig) ? FieldConfig.fromJSON(object.fieldConfig) : undefined,
      cropId: isSet(object.cropId) ? globalThis.String(object.cropId) : "",
      robotRuntime240v: isSet(object.robotRuntime240v) ? globalThis.Number(object.robotRuntime240v) : 0,
      activeAlarmCount: isSet(object.activeAlarmCount) ? globalThis.Number(object.activeAlarmCount) : 0,
      timezone: isSet(object.timezone) ? globalThis.String(object.timezone) : "",
    };
  },

  toJSON(message: CachedRobotHealth): unknown {
    const obj: any = {};
    if (message.location !== undefined) {
      obj.location = Location.toJSON(message.location);
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.performance !== undefined) {
      obj.performance = Performance.toJSON(message.performance);
    }
    if (message.reportedAt !== 0) {
      obj.reportedAt = Math.round(message.reportedAt);
    }
    if (message.status !== 0) {
      obj.status = statusToJSON(message.status);
    }
    if (message.statusChangedAt !== 0) {
      obj.statusChangedAt = Math.round(message.statusChangedAt);
    }
    if (message.crop !== "") {
      obj.crop = message.crop;
    }
    if (message.p2p !== "") {
      obj.p2p = message.p2p;
    }
    if (message.softwareVersion !== "") {
      obj.softwareVersion = message.softwareVersion;
    }
    if (message.targetVersion !== "") {
      obj.targetVersion = message.targetVersion;
    }
    if (message.targetVersionReady !== false) {
      obj.targetVersionReady = message.targetVersionReady;
    }
    if (message.metricTotals) {
      const entries = Object.entries(message.metricTotals);
      if (entries.length > 0) {
        obj.metricTotals = {};
        entries.forEach(([k, v]) => {
          obj.metricTotals[k] = Math.round(v);
        });
      }
    }
    if (message.fieldConfig !== undefined) {
      obj.fieldConfig = FieldConfig.toJSON(message.fieldConfig);
    }
    if (message.cropId !== "") {
      obj.cropId = message.cropId;
    }
    if (message.robotRuntime240v !== 0) {
      obj.robotRuntime240v = Math.round(message.robotRuntime240v);
    }
    if (message.activeAlarmCount !== 0) {
      obj.activeAlarmCount = Math.round(message.activeAlarmCount);
    }
    if (message.timezone !== "") {
      obj.timezone = message.timezone;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CachedRobotHealth>, I>>(base?: I): CachedRobotHealth {
    return CachedRobotHealth.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CachedRobotHealth>, I>>(object: I): CachedRobotHealth {
    const message = createBaseCachedRobotHealth();
    message.location = (object.location !== undefined && object.location !== null)
      ? Location.fromPartial(object.location)
      : undefined;
    message.model = object.model ?? "";
    message.performance = (object.performance !== undefined && object.performance !== null)
      ? Performance.fromPartial(object.performance)
      : undefined;
    message.reportedAt = object.reportedAt ?? 0;
    message.status = object.status ?? 0;
    message.statusChangedAt = object.statusChangedAt ?? 0;
    message.crop = object.crop ?? "";
    message.p2p = object.p2p ?? "";
    message.softwareVersion = object.softwareVersion ?? "";
    message.targetVersion = object.targetVersion ?? "";
    message.targetVersionReady = object.targetVersionReady ?? false;
    message.metricTotals = Object.entries(object.metricTotals ?? {}).reduce<{ [key: string]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.Number(value);
        }
        return acc;
      },
      {},
    );
    message.fieldConfig = (object.fieldConfig !== undefined && object.fieldConfig !== null)
      ? FieldConfig.fromPartial(object.fieldConfig)
      : undefined;
    message.cropId = object.cropId ?? "";
    message.robotRuntime240v = object.robotRuntime240v ?? 0;
    message.activeAlarmCount = object.activeAlarmCount ?? 0;
    message.timezone = object.timezone ?? "";
    return message;
  },
};

function createBaseCachedRobotHealth_MetricTotalsEntry(): CachedRobotHealth_MetricTotalsEntry {
  return { key: "", value: 0 };
}

export const CachedRobotHealth_MetricTotalsEntry: MessageFns<CachedRobotHealth_MetricTotalsEntry> = {
  encode(message: CachedRobotHealth_MetricTotalsEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(16).uint64(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CachedRobotHealth_MetricTotalsEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCachedRobotHealth_MetricTotalsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = longToNumber(reader.uint64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CachedRobotHealth_MetricTotalsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
    };
  },

  toJSON(message: CachedRobotHealth_MetricTotalsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== 0) {
      obj.value = Math.round(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CachedRobotHealth_MetricTotalsEntry>, I>>(
    base?: I,
  ): CachedRobotHealth_MetricTotalsEntry {
    return CachedRobotHealth_MetricTotalsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CachedRobotHealth_MetricTotalsEntry>, I>>(
    object: I,
  ): CachedRobotHealth_MetricTotalsEntry {
    const message = createBaseCachedRobotHealth_MetricTotalsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseRobotResponse(): RobotResponse {
  return {
    db: undefined,
    health: undefined,
    serial: "",
    implementationStatus: "",
    featureFlags: {},
    customerSerial: "",
    supportSlack: "",
  };
}

export const RobotResponse: MessageFns<RobotResponse> = {
  encode(message: RobotResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.db !== undefined) {
      DB.encode(message.db, writer.uint32(10).fork()).join();
    }
    if (message.health !== undefined) {
      CachedRobotHealth.encode(message.health, writer.uint32(18).fork()).join();
    }
    if (message.serial !== "") {
      writer.uint32(26).string(message.serial);
    }
    if (message.implementationStatus !== "") {
      writer.uint32(34).string(message.implementationStatus);
    }
    Object.entries(message.featureFlags).forEach(([key, value]) => {
      RobotResponse_FeatureFlagsEntry.encode({ key: key as any, value }, writer.uint32(42).fork()).join();
    });
    if (message.customerSerial !== "") {
      writer.uint32(50).string(message.customerSerial);
    }
    if (message.supportSlack !== "") {
      writer.uint32(58).string(message.supportSlack);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RobotResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRobotResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.db = DB.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.health = CachedRobotHealth.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.serial = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.implementationStatus = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          const entry5 = RobotResponse_FeatureFlagsEntry.decode(reader, reader.uint32());
          if (entry5.value !== undefined) {
            message.featureFlags[entry5.key] = entry5.value;
          }
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.customerSerial = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.supportSlack = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RobotResponse {
    return {
      db: isSet(object.db) ? DB.fromJSON(object.db) : undefined,
      health: isSet(object.health) ? CachedRobotHealth.fromJSON(object.health) : undefined,
      serial: isSet(object.serial) ? globalThis.String(object.serial) : "",
      implementationStatus: isSet(object.implementationStatus) ? globalThis.String(object.implementationStatus) : "",
      featureFlags: isObject(object.featureFlags)
        ? Object.entries(object.featureFlags).reduce<{ [key: string]: boolean }>((acc, [key, value]) => {
          acc[key] = Boolean(value);
          return acc;
        }, {})
        : {},
      customerSerial: isSet(object.customerSerial) ? globalThis.String(object.customerSerial) : "",
      supportSlack: isSet(object.supportSlack) ? globalThis.String(object.supportSlack) : "",
    };
  },

  toJSON(message: RobotResponse): unknown {
    const obj: any = {};
    if (message.db !== undefined) {
      obj.db = DB.toJSON(message.db);
    }
    if (message.health !== undefined) {
      obj.health = CachedRobotHealth.toJSON(message.health);
    }
    if (message.serial !== "") {
      obj.serial = message.serial;
    }
    if (message.implementationStatus !== "") {
      obj.implementationStatus = message.implementationStatus;
    }
    if (message.featureFlags) {
      const entries = Object.entries(message.featureFlags);
      if (entries.length > 0) {
        obj.featureFlags = {};
        entries.forEach(([k, v]) => {
          obj.featureFlags[k] = v;
        });
      }
    }
    if (message.customerSerial !== "") {
      obj.customerSerial = message.customerSerial;
    }
    if (message.supportSlack !== "") {
      obj.supportSlack = message.supportSlack;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RobotResponse>, I>>(base?: I): RobotResponse {
    return RobotResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RobotResponse>, I>>(object: I): RobotResponse {
    const message = createBaseRobotResponse();
    message.db = (object.db !== undefined && object.db !== null) ? DB.fromPartial(object.db) : undefined;
    message.health = (object.health !== undefined && object.health !== null)
      ? CachedRobotHealth.fromPartial(object.health)
      : undefined;
    message.serial = object.serial ?? "";
    message.implementationStatus = object.implementationStatus ?? "";
    message.featureFlags = Object.entries(object.featureFlags ?? {}).reduce<{ [key: string]: boolean }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.Boolean(value);
        }
        return acc;
      },
      {},
    );
    message.customerSerial = object.customerSerial ?? "";
    message.supportSlack = object.supportSlack ?? "";
    return message;
  },
};

function createBaseRobotResponse_FeatureFlagsEntry(): RobotResponse_FeatureFlagsEntry {
  return { key: "", value: false };
}

export const RobotResponse_FeatureFlagsEntry: MessageFns<RobotResponse_FeatureFlagsEntry> = {
  encode(message: RobotResponse_FeatureFlagsEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== false) {
      writer.uint32(16).bool(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RobotResponse_FeatureFlagsEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRobotResponse_FeatureFlagsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RobotResponse_FeatureFlagsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.Boolean(object.value) : false,
    };
  },

  toJSON(message: RobotResponse_FeatureFlagsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== false) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RobotResponse_FeatureFlagsEntry>, I>>(base?: I): RobotResponse_FeatureFlagsEntry {
    return RobotResponse_FeatureFlagsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RobotResponse_FeatureFlagsEntry>, I>>(
    object: I,
  ): RobotResponse_FeatureFlagsEntry {
    const message = createBaseRobotResponse_FeatureFlagsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? false;
    return message;
  },
};

function createBaseRobotSummaryResponse(): RobotSummaryResponse {
  return {
    robot: undefined,
    alarms: [],
    healthLogs: [],
    customer: undefined,
    alarmList: [],
    dailyMetrics: undefined,
    config: undefined,
  };
}

export const RobotSummaryResponse: MessageFns<RobotSummaryResponse> = {
  encode(message: RobotSummaryResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.robot !== undefined) {
      RobotResponse.encode(message.robot, writer.uint32(10).fork()).join();
    }
    for (const v of message.alarms) {
      AlarmResponse.encode(v!, writer.uint32(26).fork()).join();
    }
    for (const v of message.healthLogs) {
      HealthLog.encode(v!, writer.uint32(34).fork()).join();
    }
    if (message.customer !== undefined) {
      CustomerResponse.encode(message.customer, writer.uint32(42).fork()).join();
    }
    for (const v of message.alarmList) {
      AlarmResponse.encode(v!, writer.uint32(50).fork()).join();
    }
    if (message.dailyMetrics !== undefined) {
      DailyMetricResponse.encode(message.dailyMetrics, writer.uint32(58).fork()).join();
    }
    if (message.config !== undefined) {
      ConfigNode.encode(message.config, writer.uint32(66).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RobotSummaryResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRobotSummaryResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.robot = RobotResponse.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.alarms.push(AlarmResponse.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.healthLogs.push(HealthLog.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.customer = CustomerResponse.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.alarmList.push(AlarmResponse.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.dailyMetrics = DailyMetricResponse.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.config = ConfigNode.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RobotSummaryResponse {
    return {
      robot: isSet(object.robot) ? RobotResponse.fromJSON(object.robot) : undefined,
      alarms: globalThis.Array.isArray(object?.alarms) ? object.alarms.map((e: any) => AlarmResponse.fromJSON(e)) : [],
      healthLogs: globalThis.Array.isArray(object?.healthLogs)
        ? object.healthLogs.map((e: any) => HealthLog.fromJSON(e))
        : [],
      customer: isSet(object.customer) ? CustomerResponse.fromJSON(object.customer) : undefined,
      alarmList: globalThis.Array.isArray(object?.alarmList)
        ? object.alarmList.map((e: any) => AlarmResponse.fromJSON(e))
        : [],
      dailyMetrics: isSet(object.dailyMetrics) ? DailyMetricResponse.fromJSON(object.dailyMetrics) : undefined,
      config: isSet(object.config) ? ConfigNode.fromJSON(object.config) : undefined,
    };
  },

  toJSON(message: RobotSummaryResponse): unknown {
    const obj: any = {};
    if (message.robot !== undefined) {
      obj.robot = RobotResponse.toJSON(message.robot);
    }
    if (message.alarms?.length) {
      obj.alarms = message.alarms.map((e) => AlarmResponse.toJSON(e));
    }
    if (message.healthLogs?.length) {
      obj.healthLogs = message.healthLogs.map((e) => HealthLog.toJSON(e));
    }
    if (message.customer !== undefined) {
      obj.customer = CustomerResponse.toJSON(message.customer);
    }
    if (message.alarmList?.length) {
      obj.alarmList = message.alarmList.map((e) => AlarmResponse.toJSON(e));
    }
    if (message.dailyMetrics !== undefined) {
      obj.dailyMetrics = DailyMetricResponse.toJSON(message.dailyMetrics);
    }
    if (message.config !== undefined) {
      obj.config = ConfigNode.toJSON(message.config);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RobotSummaryResponse>, I>>(base?: I): RobotSummaryResponse {
    return RobotSummaryResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RobotSummaryResponse>, I>>(object: I): RobotSummaryResponse {
    const message = createBaseRobotSummaryResponse();
    message.robot = (object.robot !== undefined && object.robot !== null)
      ? RobotResponse.fromPartial(object.robot)
      : undefined;
    message.alarms = object.alarms?.map((e) => AlarmResponse.fromPartial(e)) || [];
    message.healthLogs = object.healthLogs?.map((e) => HealthLog.fromPartial(e)) || [];
    message.customer = (object.customer !== undefined && object.customer !== null)
      ? CustomerResponse.fromPartial(object.customer)
      : undefined;
    message.alarmList = object.alarmList?.map((e) => AlarmResponse.fromPartial(e)) || [];
    message.dailyMetrics = (object.dailyMetrics !== undefined && object.dailyMetrics !== null)
      ? DailyMetricResponse.fromPartial(object.dailyMetrics)
      : undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? ConfigNode.fromPartial(object.config)
      : undefined;
    return message;
  },
};

function createBaseRobotSummaryListReponse(): RobotSummaryListReponse {
  return { robots: [], total: 0 };
}

export const RobotSummaryListReponse: MessageFns<RobotSummaryListReponse> = {
  encode(message: RobotSummaryListReponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.robots) {
      RobotSummaryResponse.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.total !== 0) {
      writer.uint32(16).int64(message.total);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RobotSummaryListReponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRobotSummaryListReponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.robots.push(RobotSummaryResponse.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.total = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RobotSummaryListReponse {
    return {
      robots: globalThis.Array.isArray(object?.robots)
        ? object.robots.map((e: any) => RobotSummaryResponse.fromJSON(e))
        : [],
      total: isSet(object.total) ? globalThis.Number(object.total) : 0,
    };
  },

  toJSON(message: RobotSummaryListReponse): unknown {
    const obj: any = {};
    if (message.robots?.length) {
      obj.robots = message.robots.map((e) => RobotSummaryResponse.toJSON(e));
    }
    if (message.total !== 0) {
      obj.total = Math.round(message.total);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RobotSummaryListReponse>, I>>(base?: I): RobotSummaryListReponse {
    return RobotSummaryListReponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RobotSummaryListReponse>, I>>(object: I): RobotSummaryListReponse {
    const message = createBaseRobotSummaryListReponse();
    message.robots = object.robots?.map((e) => RobotSummaryResponse.fromPartial(e)) || [];
    message.total = object.total ?? 0;
    return message;
  },
};

function createBaseRemoteResponse(): RemoteResponse {
  return { host: "" };
}

export const RemoteResponse: MessageFns<RemoteResponse> = {
  encode(message: RemoteResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.host !== "") {
      writer.uint32(10).string(message.host);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RemoteResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoteResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.host = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemoteResponse {
    return { host: isSet(object.host) ? globalThis.String(object.host) : "" };
  },

  toJSON(message: RemoteResponse): unknown {
    const obj: any = {};
    if (message.host !== "") {
      obj.host = message.host;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoteResponse>, I>>(base?: I): RemoteResponse {
    return RemoteResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoteResponse>, I>>(object: I): RemoteResponse {
    const message = createBaseRemoteResponse();
    message.host = object.host ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
