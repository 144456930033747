import { LaserTable } from "portal/components/LaserTable";
import { NoScroll } from "portal/components/Page";
import { useGetHardwareQuery } from "portal/state/portalApi";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { FunctionComponent } from "react";

export const FleetHardware: FunctionComponent = withAuthenticationRequired(
  function FleetHardware() {
    const { data: hardware, isLoading } = useQueryPopups(useGetHardwareQuery());

    return (
      <NoScroll>
        <LaserTable lasers={hardware?.lasers} fleet loading={isLoading} />
      </NoScroll>
    );
  }
);
