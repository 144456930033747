import { isNil, isUndefined } from "./identity";

/**
 * A `Comparator<T>` is a function that takes two arguments of type `T` and
 * returns a positive, negative, or zero number as the first argument is
 * greater than, less than, or equal to the second. If `cmp: Comparator<T>` and
 * `ts: T[]`, then `ts.sort(cmp)` sorts `ts` according to the ordering defined
 * by `cmp`.
 */
export type Comparator<T> = (a: T, b: T) => number;

/**
 * A comparator that follows elements' natural ordering. Useful for comparing
 * numbers: `[8, 9, 10].sort()` is broken, but `[8, 9, 10].sort(natural)` works
 * as expected. Also useful as a "neutral" comparator to give to combinators.
 */
export const natural: Comparator<unknown> = (a: any, b: any) => {
  if (a > b) {
    return 1;
  }
  if (a < b) {
    return -1;
  }
  return 0;
};

/**
 * Creates a comparator that yields the reverse order of the given comparator.
 */
export function reverse<T>(comparator: Comparator<T> = natural): Comparator<T> {
  return (a, b) => -comparator(a, b);
}

/**
 * Creates a comparator that passes elements through a key-extraction function
 * and compares the resulting keys. The extracted keys are compared using the
 * given comparator (or natural order if none is given).
 *
 * For instance, to sort strings by their length, leaving strings of the same
 * length in the same order relative to each other:
 *
 *    const byLength = cmp.comparing((s) => s.length);
 *    words.sort(byLength);
 *
 * Or, to sort people by their names, locale-sensitively:
 *
 *    const byName = cmp.comparing((p) => p.name, (a, b) => a.localeCompare(b));
 *    people.sort(byName);
 */
export function comparing<T, U>(
  extractKey: (t: T) => U,
  comparator: Comparator<U> = natural
): Comparator<T> {
  return (a, b) => comparator(extractKey(a), extractKey(b));
}

/**
 * Creates a comparator that tries each of the given comparators in turn,
 * honoring the first one that gives a nonzero result. For instance, to sort
 * strings by their length, breaking ties by lexicographical order:
 *
 *    const byLength = cmp.comparing((s) => s.length);
 *    const byValue = cmp.natural;
 *    const shortlex = first([byLength, byValue]);
 *    words.sort(shortlex);
 */
export function first<T>(comparators: Array<Comparator<T>>): Comparator<T> {
  return (a, b) => {
    for (const comparator of comparators) {
      const result = comparator(a, b);
      if (result !== 0) {
        return result;
      }
    }
    return 0;
  };
}

/**
 * Creates a comparator that treats `null` and `undefined` values as equal to
 * each other but greater than all other elements, falling back to `cmp` for
 * comparisons between non-nullish values. It is therefore guaranteed that
 * `cmp` will only be called with non-nullish inputs.
 *
 * Note that `Array.prototype.sort` also specifies that `undefined` values are
 * sent to the end and not passed to the comparator function, but this is not
 * the case for `null`s and also TypeScript doesn't know about this, anyway.
 */
export function nullsLast<T>(
  comparator: Comparator<T> = natural
): Comparator<T | null | undefined> {
  return (a: T | null | undefined, b: T | null | undefined) => {
    if (isNil(a) && !isNil(b)) {
      return 1;
    }
    if (!isNil(a) && isNil(b)) {
      return -1;
    }
    if (isNil(a) || isNil(b)) {
      return 0;
    }
    return comparator(a, b);
  };
}

/**
 * Lifts an comparator of elements to a lexicographic comparator of arrays. The
 * first index at which the array elements compare unequal, if any, determines
 * the order of the arrays. If one array is a strict prefix of the other, the
 * shorter array compares smaller.
 */
export function array<T>(
  comparator: Comparator<T> = natural
): Comparator<Array<T>> {
  return (a: T[], b: T[]) => {
    // Loop invariant: at the start of each iteration, `a[:index]` and `b[:index]`
    // compare equal. (Initially trivially true because `index === 0`.)
    for (const [index] of a.entries()) {
      if (index >= b.length) {
        // `a[:index]` and `b` compare equal, but `a` has more parts, so `a`
        // follows `b`.
        return 1;
      }
      const aValue = a[index];
      const bValue = b[index];
      if (isUndefined(aValue) || isUndefined(bValue)) {
        return 0;
      }
      const result = comparator(aValue, bValue);
      if (result !== 0) {
        return result;
      }
    }
    if (a.length < b.length) {
      // `a` and `b[:a.length]` compare equal, but `b` has more parts, so
      // `a` precedes `b`.
      return -1;
    }
    return 0;
  };
}
