import { classes } from "portal/utils/theme";
import { Typography } from "@mui/material";
import React, { FunctionComponent, ReactElement } from "react";

interface Props {
  icon?: ReactElement;
  text?: string | ReactElement;
}

export const ViewPlaceholder: FunctionComponent<Props> = ({
  icon,
  text = "",
}) => (
  <Typography
    variant="body2"
    className={classes(
      "text-gray-500",
      "h-full w-full flex-grow",
      "flex items-center justify-center gap-2"
    )}
  >
    {icon}
    {text}
  </Typography>
);
