import { BLANK_VALUE } from "./measurement/formatters";
import {
  ButtonBase,
  Card,
  CardContent,
  Grid2TypeMap,
  Link,
  Typography,
} from "@mui/material";
import { classes } from "portal/utils/theme";
import { CycleSlot } from "portal/utils/metrics";
import { DailyMetricResponse } from "protos/portal/metrics";
import { DateTime } from "luxon";
import {
  getMetricName,
  METRIC_COVERAGE_SPEED,
  METRIC_VALID_CROPS,
  METRIC_WEED_DENSITY,
} from "portal/utils/certifiedMetrics";
import { isSameDate, isToday } from "portal/utils/dates";
import { keys } from "portal/utils/objects";
import { Measurement } from "./measurement/Measurement";
import { OverrideProps } from "@mui/material/OverridableComponent";
import { PortalJob } from "protos/portal/jobs";
import { titleCase } from "portal/utils/strings";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import AcreageIcon from "@mui/icons-material/StraightenOutlined";
import BandingIcon from "@mui/icons-material/ViewColumnOutlined";
import CropIcon from "@mui/icons-material/GrassOutlined";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import React, { Fragment, FunctionComponent } from "react";
import ThinningIcon from "@mui/icons-material/FlakyOutlined";

const SUMMARY_METRICS = [
  METRIC_VALID_CROPS,
  METRIC_WEED_DENSITY,
  METRIC_COVERAGE_SPEED,
];

export const isDay = (
  job: Partial<PortalJob> | undefined
): job is Pick<PortalJob, "name" | "timestampMs"> =>
  Boolean(
    job && keys(job).length === 2 && "name" in job && "timestampMs" in job
  );

export const isJob = (job: Partial<PortalJob>): job is PortalJob => !isDay(job);

const hasThinning = (job: Partial<PortalJob>): boolean =>
  (job.metrics?.thinnedCrops ?? 0) > 0;

interface JobSummaryProps {
  className?: string;
  job: Partial<PortalJob>;
  metrics?: DailyMetricResponse;
  onClick?: () => void;
  onMetricsClick?: () => void;
  showMetrics?: boolean;
}

export const JobSummary: FunctionComponent<JobSummaryProps> = ({
  className,
  job,
  onClick,
  metrics,
  showMetrics = false,
  onMetricsClick,
}) => {
  const { t, i18n } = useTranslation();
  const { isInternal } = useSelf();
  const startTime = DateTime.fromMillis(job.timestampMs ?? 0);
  const endTime = job.stopTimestampMs
    ? DateTime.fromMillis(job.stopTimestampMs)
    : undefined;
  const chipProps: OverrideProps<Grid2TypeMap<object, "div">, "div"> = {
    xs: 6,
    className: classes(
      "flex items-center gap-1",
      "whitespace-nowrap ellipsis overflow-hidden"
    ),
  };
  let formattedTime;
  if (isDay(job)) {
    formattedTime = startTime.toLocaleString(
      { month: "numeric", day: "numeric" },
      {
        locale: i18n.language,
      }
    );
  } else if (!endTime && isToday(startTime)) {
    formattedTime = t("components.JobSummary.singleDay", {
      date: startTime.toLocaleString(
        { month: "numeric", day: "numeric" },
        {
          locale: i18n.language,
        }
      ),
      startTime: startTime.toLocaleString(
        { hour: "numeric", minute: "2-digit" },
        {
          locale: i18n.language,
        }
      ),
      endTime: "___",
    });
  } else if (!endTime) {
    formattedTime = t("components.JobSummary.multiDay", {
      startDate: startTime.toLocaleString(
        { month: "numeric", day: "numeric" },
        {
          locale: i18n.language,
        }
      ),
      endDate: "___",
    });
  } else if (isSameDate(startTime, endTime)) {
    formattedTime = t("components.JobSummary.singleDay", {
      date: startTime.toLocaleString(
        { month: "numeric", day: "numeric" },
        {
          locale: i18n.language,
        }
      ),
      startTime: startTime.toLocaleString(
        { hour: "numeric", minute: "2-digit" },
        {
          locale: i18n.language,
        }
      ),
      endTime: endTime.toLocaleString(
        { hour: "numeric", minute: "2-digit" },
        {
          locale: i18n.language,
        }
      ),
    });
  } else {
    formattedTime = t("components.JobSummary.multiDay", {
      startDate: startTime.toLocaleString(
        { month: "numeric", day: "numeric" },
        {
          locale: i18n.language,
        }
      ),
      endDate: endTime.toLocaleString(
        { month: "numeric", day: "numeric" },
        {
          locale: i18n.language,
        }
      ),
    });
  }

  return (
    <Card className={className}>
      <ButtonBase
        className="w-full"
        onClick={() => onClick?.()}
        focusVisibleClassName="border-solid border-2 border-white"
      >
        <CardContent className="flex flex-col w-full gap-2">
          <div className="flex items-baseline justify-between">
            <Typography variant="h2" className="text-xl">
              {titleCase(job.name)}
            </Typography>
            <span className="font-normal opacity-60">{formattedTime}</span>
          </div>
          {isJob(job) && (
            <Grid
              container
              gridArea={12}
              spacing={1}
              className="flex justify-between items-center text-sm w-full"
            >
              <Grid {...chipProps}>
                <CropIcon />
                {job.crop || t("models.crops.categories.unknown")}
              </Grid>
              <Grid {...chipProps}>
                <AcreageIcon />
                <Measurement
                  value={job.metrics?.acresWeeded}
                  fromUnits="ac"
                  className="justify-start flex-shrink w-auto"
                  unitClassName="hidden"
                  cycleSlot={CycleSlot.AREA}
                />
                {" / "}
                <Measurement
                  value={job.acreage}
                  fromUnits="ac"
                  longUnits
                  className="justify-start"
                  cycleSlot={CycleSlot.AREA}
                />
              </Grid>
              <Grid {...chipProps}>
                <BandingIcon />
                {t("components.robots.RobotSummary.banding.withName", {
                  name: job.bandingProfile || t("utils.descriptors.unknown"),
                })}
              </Grid>
              <Grid {...chipProps}>
                <ThinningIcon />
                {t("components.robots.RobotSummary.thinning.withName", {
                  name: hasThinning(job)
                    ? job.thinningProfile || t("utils.descriptors.unknown")
                    : BLANK_VALUE,
                })}
              </Grid>
            </Grid>
          )}

          {showMetrics && (
            <div className="text-left">
              {metrics &&
                SUMMARY_METRICS.map((metric) => {
                  return (
                    <Fragment key={metric.id}>
                      <div className="border-0 border-t-1 border-slate-600 border-solid w-full" />
                      <Typography variant="h3" className="text-lg">
                        {getMetricName(t, metric)}
                      </Typography>
                      <span className="whitespace-nowrap text-md">
                        <Measurement
                          value={metric.getValue(
                            metrics[metric.id as keyof DailyMetricResponse],
                            metrics,
                            isInternal
                          )}
                          metric={metric}
                          className="justify-start"
                          valueClassName="font-mono"
                          unitClassName="text-base"
                        />
                      </span>
                    </Fragment>
                  );
                })}
              {onMetricsClick && (
                <Link
                  onClick={() => onMetricsClick()}
                  className="text-lighten-600 font-bold block mt-2 decoration-lighten-600"
                >
                  {t("views.fleet.robots.history.moreMetrics")}
                </Link>
              )}
            </div>
          )}
        </CardContent>
      </ButtonBase>
    </Card>
  );
};
