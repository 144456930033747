// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.19.4
// source: portal/alarms.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { AlarmRow } from "../frontend/alarm";
import { DB } from "./db";

export const protobufPackage = "carbon.portal.alarms";

export interface AlarmResponse {
  db: DB | undefined;
  alarm: AlarmRow | undefined;
  startedAt: number;
  endedAt: number;
  robotId: number;
}

function createBaseAlarmResponse(): AlarmResponse {
  return { db: undefined, alarm: undefined, startedAt: 0, endedAt: 0, robotId: 0 };
}

export const AlarmResponse: MessageFns<AlarmResponse> = {
  encode(message: AlarmResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.db !== undefined) {
      DB.encode(message.db, writer.uint32(10).fork()).join();
    }
    if (message.alarm !== undefined) {
      AlarmRow.encode(message.alarm, writer.uint32(18).fork()).join();
    }
    if (message.startedAt !== 0) {
      writer.uint32(88).int64(message.startedAt);
    }
    if (message.endedAt !== 0) {
      writer.uint32(96).int64(message.endedAt);
    }
    if (message.robotId !== 0) {
      writer.uint32(104).int64(message.robotId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AlarmResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAlarmResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.db = DB.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.alarm = AlarmRow.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.startedAt = longToNumber(reader.int64());
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.endedAt = longToNumber(reader.int64());
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.robotId = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AlarmResponse {
    return {
      db: isSet(object.db) ? DB.fromJSON(object.db) : undefined,
      alarm: isSet(object.alarm) ? AlarmRow.fromJSON(object.alarm) : undefined,
      startedAt: isSet(object.startedAt) ? globalThis.Number(object.startedAt) : 0,
      endedAt: isSet(object.endedAt) ? globalThis.Number(object.endedAt) : 0,
      robotId: isSet(object.robotId) ? globalThis.Number(object.robotId) : 0,
    };
  },

  toJSON(message: AlarmResponse): unknown {
    const obj: any = {};
    if (message.db !== undefined) {
      obj.db = DB.toJSON(message.db);
    }
    if (message.alarm !== undefined) {
      obj.alarm = AlarmRow.toJSON(message.alarm);
    }
    if (message.startedAt !== 0) {
      obj.startedAt = Math.round(message.startedAt);
    }
    if (message.endedAt !== 0) {
      obj.endedAt = Math.round(message.endedAt);
    }
    if (message.robotId !== 0) {
      obj.robotId = Math.round(message.robotId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AlarmResponse>, I>>(base?: I): AlarmResponse {
    return AlarmResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AlarmResponse>, I>>(object: I): AlarmResponse {
    const message = createBaseAlarmResponse();
    message.db = (object.db !== undefined && object.db !== null) ? DB.fromPartial(object.db) : undefined;
    message.alarm = (object.alarm !== undefined && object.alarm !== null)
      ? AlarmRow.fromPartial(object.alarm)
      : undefined;
    message.startedAt = object.startedAt ?? 0;
    message.endedAt = object.endedAt ?? 0;
    message.robotId = object.robotId ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
