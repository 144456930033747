import { Auth0Provider } from "@auth0/auth0-react";
import { Path } from "portal/utils/routing";
import { useNavigate } from "react-router-dom";
import React, { FunctionComponent, PropsWithChildren } from "react";

export const CarbonAuth0Provider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const navigate = useNavigate();
  return (
    <Auth0Provider
      domain={window._jsenv.REACT_APP_AUTH0_AUTH_DOMAIN}
      clientId={window._jsenv.REACT_APP_AUTH0_REST_CLIENT_ID}
      useRefreshTokens
      useRefreshTokensFallback
      onRedirectCallback={(appState, ...parameters) => {
        console.debug(
          "CarbonAuth0Provider.tsx:onRedirectCallback: appState, args:",
          appState,
          ...parameters
        );
        const to = appState?.returnTo || Path.BASE;
        navigate(to, { replace: true });
      }}
      authorizationParams={{
        redirect_uri: window._jsenv.REACT_APP_AUTH0_REST_CALLBACK_URL,
        audience: window._jsenv.REACT_APP_AUTH0_AUDIENCE,
        scope:
          "openid profile email read:users:self update:users:self offline_access",
      }}
    >
      {children}
    </Auth0Provider>
  );
};
