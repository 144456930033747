import { addNotification, type VariantType } from "portal/state/notifications";
import { handleError } from "portal/state/portalApi";
import { isObject } from "../objects";
import {
  LazyQueryTrigger,
  MutationTrigger,
  UseLazyQueryLastPromiseInfo,
  UseMutationStateResult,
  UseQueryHookResult,
  UseQueryStateResult,
} from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { MutationDefinition, QueryDefinition } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { TFunction } from "i18next";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelf } from "portal/state/store";
import { useStableObject } from "./useStable";
import { useTranslation } from "react-i18next";

/**
 * Wrappers for RTK Query and Mutation hooks (including lazy ones) to handle
 * automatic error and success toast notifications.
 *
 * If success string is passed, that message will be shown on query success
 *
 * If user-facing error message is included in error response, it will be shown
 * (special error message will be shown for queryLimitReached errors)
 * errorVariant can be set to "warning" to show warning style toast instead of
 * error style toast on errors
 */

export interface UseApiPopupsOptions {
  success?: string;
  errorVariant?: VariantType;
}

const DEFAULT_OPTIONS: UseApiPopupsOptions = {};

export const onPopupStateChange = (
  t: TFunction,
  data: unknown,
  isInternal: boolean,
  dispatch: ReturnType<typeof useDispatch>,
  isError: boolean,
  error: SerializedError | undefined,
  isSuccess: boolean,
  { success, errorVariant }: UseApiPopupsOptions
): void => {
  if (isObject(data) && "queryLimitReached" in data && data.queryLimitReached) {
    dispatch(
      addNotification({
        message: t("components.ErrorBoundary.queryLimitReached"),
        variant: "warning",
      })
    );
  }

  if (isError) {
    handleError(t, isError, error, (message) => {
      const variant = errorVariant ?? "error";
      if (variant === "error" || isInternal) {
        dispatch(
          addNotification({
            message: message ?? t("components.ErrorBoundary.error"),
            variant,
          })
        );
      }
    });
  }
  if (success && isSuccess) {
    dispatch(
      addNotification({
        message: success,
        variant: "success",
      })
    );
  }
};

export const useQueryPopups = <T extends QueryDefinition<any, any, any, any>>(
  useQueryHookResult: UseQueryHookResult<T>,
  options?: UseApiPopupsOptions
): UseQueryHookResult<T> => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isInternal } = useSelf();
  const { data, isError, error, isSuccess } = useQueryHookResult;
  const stableOptions = useStableObject(options ?? DEFAULT_OPTIONS);
  useEffect(() => {
    if (!data) {
      return;
    }
    onPopupStateChange(
      t,
      data,
      isInternal,
      dispatch,
      isError,
      error,
      isSuccess,
      stableOptions
    );
  }, [isError, error, isSuccess, t, data, isInternal, dispatch, stableOptions]);

  return useQueryHookResult;
};

export const useLazyPopups = <T extends QueryDefinition<any, any, any, any>>(
  useLazyQueryHookResult: [
    LazyQueryTrigger<T>,
    UseQueryStateResult<T, any>,
    UseLazyQueryLastPromiseInfo<T>
  ],
  options?: UseApiPopupsOptions
): [
  LazyQueryTrigger<T>,
  UseQueryStateResult<T, any>,
  UseLazyQueryLastPromiseInfo<T>
] => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isInternal } = useSelf();
  const [, { data, isError, error, isSuccess }] = useLazyQueryHookResult;
  const stableOptions = useStableObject(options ?? DEFAULT_OPTIONS);
  useEffect(() => {
    if (!data) {
      return;
    }
    onPopupStateChange(
      t,
      data,
      isInternal,
      dispatch,
      isError,
      error,
      isSuccess,
      stableOptions
    );
  }, [isError, error, isSuccess, t, data, isInternal, dispatch, stableOptions]);

  return useLazyQueryHookResult;
};

export const useMutationPopups = <
  T extends MutationDefinition<any, any, any, any>
>(
  useMutationHookResult: readonly [
    MutationTrigger<T>,
    UseMutationStateResult<T, any>
  ],
  options?: UseApiPopupsOptions
): readonly [MutationTrigger<T>, UseMutationStateResult<T, any>] => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isInternal } = useSelf();
  const [, { data, isError, error, isSuccess }] = useMutationHookResult;
  const stableOptions = useStableObject(options ?? DEFAULT_OPTIONS);
  useEffect(() => {
    if (!data) {
      return;
    }
    onPopupStateChange(
      t,
      data,
      isInternal,
      dispatch,
      isError,
      error,
      isSuccess,
      stableOptions
    );
  }, [isError, error, isSuccess, t, data, isInternal, dispatch, stableOptions]);

  return useMutationHookResult;
};
