import { CarbonDataGrid } from "portal/components/CarbonDataGrid";
import { classes } from "portal/utils/theme";
import { GridColDef } from "@mui/x-data-grid-premium";
import { NewVelocityEstimator } from "portal/components/velocityEstimator/NewVelocityEstimator";
import { NoScroll } from "../Page";
import { SearchField } from "../header/SearchField";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { titleCase } from "portal/utils/strings";
import { TVEProfile } from "protos/target_velocity_estimator/target_velocity_estimator";
import {
  useDeleteGlobalTargetVelocityEstimatorMutation,
  useDeleteTargetVelocityEstimatorMutation,
  useGetGlobalTargetVelocityEstimatorQuery,
  useGetRobotQuery,
  useGetTargetVelocityEstimatorQuery,
  useListGlobalTargetVelocityEstimatorsQuery,
  useListTargetVelocityEstimatorsQuery,
  useSetGlobalTargetVelocityEstimatorMutation,
  useSetTargetVelocityEstimatorMutation,
} from "portal/state/portalApi";
import { useFuzzySearch } from "portal/utils/hooks/useFuzzySearch";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { useTranslation } from "react-i18next";
import { VelocityEstimator } from "portal/components/velocityEstimator/VelocityEstimator";
import React, { FunctionComponent } from "react";

const CELL_PADDING_CLASS = "py-4";

const defaultColumn: Partial<GridColDef> = {
  disableColumnMenu: true,
  cellClassName: () => CELL_PADDING_CLASS,
};

interface Props {
  adminEditing?: boolean;
  serial?: string;
  basePath: string;
  useListQuery:
    | typeof useListTargetVelocityEstimatorsQuery
    | typeof useListGlobalTargetVelocityEstimatorsQuery;
  useGetQuery:
    | typeof useGetTargetVelocityEstimatorQuery
    | typeof useGetGlobalTargetVelocityEstimatorQuery;
  useSetMutation:
    | typeof useSetTargetVelocityEstimatorMutation
    | typeof useSetGlobalTargetVelocityEstimatorMutation;
  useDeleteMutation:
    | typeof useDeleteTargetVelocityEstimatorMutation
    | typeof useDeleteGlobalTargetVelocityEstimatorMutation;
}

export const VelocityEstimatorList: FunctionComponent<Props> = ({
  adminEditing = false,
  serial,
  basePath,
  useListQuery,
  useGetQuery,
  useSetMutation,
  useDeleteMutation,
}) => {
  const { t } = useTranslation();

  const { data: summary, isSuccess: isRobotSuccess } = useQueryPopups(
    useGetRobotQuery(serial ?? skipToken),
    { errorVariant: "warning" }
  );

  const { data: targetVelocityEstimators, isSuccess: hasLoaded } =
    useQueryPopups(useListQuery(serial ?? ""));

  const navigate = useNavigate();
  const { pathname } = useLocation();
  let uuid: string = "";
  if (!pathname.endsWith(basePath)) {
    uuid = pathname.replace(`${basePath}/`, "");
  }

  const { searchText, setSearchText, results } = useFuzzySearch<TVEProfile>({
    items: targetVelocityEstimators ?? [],
  });

  const columns: readonly GridColDef<TVEProfile>[] = [
    {
      ...defaultColumn,
      field: "name",
      headerName: t("models.velocityEstimators.fields.name"),
    },
    {
      ...defaultColumn,
      field: "isActive",
      headerName: t("utils.descriptors.active"),
      cellClassName: ({ row: targetVelocityEstimator }) => {
        const isActive =
          targetVelocityEstimator.id ===
          summary?.robot?.health?.fieldConfig?.activeVelocityEstimatorId;
        return classes(
          CELL_PADDING_CLASS,
          isActive ? "font-bold bg-green-500 text-white" : "text-gray-500"
        );
      },
      valueGetter: (value, targetVelocityEstimator) => {
        if (!isRobotSuccess) {
          return t("components.Loading.placeholder");
        }
        const isActive =
          targetVelocityEstimator.id ===
          summary.robot?.health?.fieldConfig?.activeVelocityEstimatorId;
        return isActive
          ? t("utils.descriptors.active")
          : t("utils.descriptors.inactive");
      },
    },
  ];

  if (uuid) {
    return (
      <VelocityEstimator
        adminEditing={adminEditing}
        parentLink={basePath}
        serial={serial}
        uuid={uuid}
        useGetQuery={useGetQuery}
        useSetMutation={useSetMutation}
        useDeleteMutation={useDeleteMutation}
      />
    );
  }

  return (
    <NoScroll>
      <div className="flex flex-col h-full">
        <CarbonDataGrid<TVEProfile>
          header={
            <>
              <SearchField
                value={searchText}
                onChange={setSearchText}
                label={t("utils.actions.searchLong", {
                  subject: titleCase(
                    t("models.velocityEstimators.velocityEstimator", {
                      count: targetVelocityEstimators?.length ?? 0,
                    })
                  ),
                })}
              />
              <NewVelocityEstimator
                serial={serial}
                basePath={basePath}
                hotkey="n"
                useSetMutation={useSetMutation}
              />
            </>
          }
          className="flex flex-1"
          rows={results}
          getRowId={({ id }) => id}
          columns={columns}
          getRowHeight={() => "auto"}
          hideFooter
          onRowClick={({ row: targetVelocityEstimator }) =>
            navigate(`${basePath}/${targetVelocityEstimator.id}`)
          }
          disableRowSelectionOnClick
          getRowClassName={() => "cursor-pointer"}
          loading={!hasLoaded}
        />
      </div>
    </NoScroll>
  );
};
