import { classes } from "portal/utils/theme";
import { DRAWING_LABEL_STYLE } from "portal/utils/geo";
import { Feature, Geometry } from "geojson";
import { featureCollection, point, Properties } from "@turf/helpers";
import { formatMeasurement } from "portal/components/measurement/formatters";
import { GeoJSONSource, MapRef } from "react-map-gl";
import { Tooltip } from "@mui/material";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import AreaIcon from "@mui/icons-material/StraightenOutlined";
import centroid from "@turf/centroid";
import CheapRuler, { Points } from "cheap-ruler";
import mapboxgl from "mapbox-gl";
import React, { FunctionComponent, useEffect, useState } from "react";
import turfArea from "@turf/area";

const MEASUREMENT_SOURCE_ID = "measurement-source";
const MEASUREMENT_LAYER_ID = "measurement-labels";
const ACREAGE_SOURCE_ID = "measurement-acreage-source";
const ACREAGE_LABEL_LAYER_ID = "measurement-acreage-label";

const mapgl = (m: MapRef): mapboxgl.Map => m.getMap();

interface MeasureControlProps {
  className?: string;
  draw: MapboxDraw;
  map: MapRef | null;
  isMapReady: boolean;
}

export const MeasureControl: FunctionComponent<MeasureControlProps> = ({
  className,
  draw,
  map,
  isMapReady,
}) => {
  const { t, i18n } = useTranslation();
  const [isEnabled, setIsEnabled] = useState(false);
  const { measurementSystem } = useSelf();

  useEffect(() => {
    const clearMap = (): void => {
      // mapgl() can return undefined if the map is not ready
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!map || !mapgl(map) || !isMapReady) {
        return;
      }

      setIsEnabled(false);

      setTimeout(() => {
        // timing issues between js controls and react controls make this necessary
        mapgl(map).setLayoutProperty(
          MEASUREMENT_LAYER_ID,
          "visibility",
          "none"
        );
        mapgl(map).setLayoutProperty(
          ACREAGE_LABEL_LAYER_ID,
          "visibility",
          "none"
        );
        (mapgl(map).getSource(MEASUREMENT_SOURCE_ID) as GeoJSONSource).setData(
          featureCollection([])
        );
        (mapgl(map).getSource(ACREAGE_SOURCE_ID) as GeoJSONSource).setData(
          featureCollection([])
        );
        draw.deleteAll();
      }, 0);
    };

    const onSelectionChange = ({ features }: any): void => {
      // if we select anything that isn't the measurement polygon, assume we're clicking away
      // and remove the measurement drawing
      if (features.length === 0) {
        clearMap();
      }
    };

    const onDrawRender = (): void => {
      // mapgl() can return undefined if the map is not ready
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!map || !mapgl(map) || !isMapReady || !isEnabled) {
        return;
      }

      const all = draw.getAll();
      // getAll() can actually return undefined if no features exist
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!all?.features) {
        return;
      }

      const ruler = new CheapRuler(mapgl(map).getCenter().lat, "meters");
      const labelFeatures: Feature<Geometry, Properties>[] = [];
      const acreageLabelFeatures: Feature<Geometry, Properties>[] = [];

      for (const feature of all.features) {
        if (feature.geometry.type !== "Polygon") {
          continue;
        }
        // label polygon line distances
        const coords = feature.geometry.coordinates;
        const firstCoordinate = coords[0];
        if (!firstCoordinate) {
          continue;
        }
        if (coords.length > 0 && firstCoordinate.length > 2) {
          for (let index = 0; index < firstCoordinate.length; index++) {
            if (index < firstCoordinate.length - 1) {
              // handle the case where the polygon is really a line so we don't show double labels
              // since the polygon automatically doubles back on itself when it's a line
              if (firstCoordinate.length === 3 && index >= 1) {
                break;
              }
              const segment = firstCoordinate.slice(index, index + 2);
              const length = ruler.lineDistance(segment as unknown as Points);
              const labelCoord = ruler.along(
                segment as unknown as Points,
                length * 0.4 // placing slightly off center so that the vertex nodes can be accessed
              );
              labelFeatures.push(
                point(labelCoord, {
                  type: "line",
                  label: formatMeasurement(
                    t,
                    i18n,
                    measurementSystem,
                    length,
                    "m"
                  ).toString(),
                })
              );
            }
          }
        }
        // label Polygon area
        if (
          feature.geometry.coordinates.length > 0 &&
          (feature.geometry.coordinates[0]?.length ?? 0) > 3
        ) {
          const labelFeature = centroid(feature.geometry);
          const area = turfArea(feature);
          labelFeature.properties = {
            type: "area",
            label: formatMeasurement(
              t,
              i18n,
              measurementSystem,
              area,
              "m2"
            ).toString(),
          };
          acreageLabelFeatures.push(labelFeature);
        }
      }

      const labelFeatureCollection = featureCollection(labelFeatures);
      const acreageLabelFeatureCollection =
        featureCollection(acreageLabelFeatures);
      mapgl(map).setLayoutProperty(
        MEASUREMENT_LAYER_ID,
        "visibility",
        "visible"
      );
      mapgl(map).setLayoutProperty(
        ACREAGE_LABEL_LAYER_ID,
        "visibility",
        "visible"
      );
      (mapgl(map).getSource(MEASUREMENT_SOURCE_ID) as GeoJSONSource).setData(
        labelFeatureCollection
      );
      (mapgl(map).getSource(ACREAGE_SOURCE_ID) as GeoJSONSource).setData(
        acreageLabelFeatureCollection
      );
    };

    const escFunction = (event: KeyboardEvent): void => {
      if (event.key === "Escape") {
        clearMap();
      }
    };

    if (map && isMapReady && mapgl(map).isStyleLoaded()) {
      // One time map setup
      // getSource() can actually return undefined if no such source exists
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!mapgl(map).getSource(MEASUREMENT_SOURCE_ID)) {
        mapgl(map).addSource(MEASUREMENT_SOURCE_ID, {
          type: "geojson",
          data: featureCollection([]),
        });

        mapgl(map).addLayer({
          id: MEASUREMENT_LAYER_ID,
          source: MEASUREMENT_SOURCE_ID,
          ...DRAWING_LABEL_STYLE,
        });
      }

      // One time map setup
      // getSource() can actually return undefined if no such source exists
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!mapgl(map).getSource(ACREAGE_SOURCE_ID)) {
        mapgl(map).addSource(ACREAGE_SOURCE_ID, {
          type: "geojson",
          data: featureCollection([]),
        });

        // we have a separate layer for the acreage label
        // so that we can control its zoom level visibility to make sure
        // it doesn't disappear due to clustering on zoom out
        mapgl(map).addLayer({
          id: ACREAGE_LABEL_LAYER_ID,
          source: ACREAGE_SOURCE_ID,
          ...DRAWING_LABEL_STYLE,
          layout: {
            ...DRAWING_LABEL_STYLE.layout,
            "text-allow-overlap": true, // allows the acreage label to not disappear on zoom out
          },
        });
      }

      // clear map if drawing becomes disabled
      if (!isEnabled) {
        clearMap();
      }

      document.addEventListener("keydown", escFunction, false);
      mapgl(map).on("draw.selectionchange", onSelectionChange);
      // adding measurement labels to the measurement area
      mapgl(map).on("draw.render", onDrawRender);
    }
    return () => {
      if (map) {
        document.removeEventListener("keydown", escFunction, false);
        mapgl(map).off("draw.render", onDrawRender);
        mapgl(map).off("draw.selectionchange", onSelectionChange);
      }
    };
  }, [map, isMapReady, isEnabled, draw, measurementSystem, t, i18n]);

  return (
    <Tooltip title={t("components.map.measure.name")} placement="right" arrow>
      <div
        className={classes(
          className,
          "mapboxgl-ctrl mapboxgl-ctrl-group w-fit",
          {
            "bg-slate-200 text-slate-400": isEnabled,
          }
        )}
      >
        <button
          onClick={() => {
            if (!isEnabled) {
              draw.changeMode("draw_polygon");
            }
            setIsEnabled(!isEnabled);
          }}
        >
          <AreaIcon className="p-1 mapboxgl-ctrl-icon" />
        </button>
      </div>
    </Tooltip>
  );
};
