import { auth } from "portal/utils/auth";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { snakeToCamel } from "portal/utils/strings";
import { transformKeys, values } from "portal/utils/objects";

export interface IDMToken {
  exp: number;
  iat: number;
  token: string;
  userId: string;
}

enum Tag {
  STREAM_TOKEN = "Stream/Token",
}

const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (arguments_, api, extraOptions = {}) => {
  const result = await fetchBaseQuery({
    baseUrl: window._jsenv.REACT_APP_IDM_URL,
    prepareHeaders: async (headers) => {
      const accessToken = await auth.getAccessTokenSilently();
      headers.set("Authorization", `Bearer ${accessToken}`);
      headers.set("X-AUTH0-audience", window._jsenv.REACT_APP_AUTH0_AUDIENCE);
      return headers;
    },
  })(arguments_, api, extraOptions);
  // transform all API responses from snake_case (Go) to camelCase (Typescript)
  if (result.data) {
    return { ...result, data: transformKeys<any>(result.data, snakeToCamel) };
  }
  return result;
};

export const idmApi = createApi({
  reducerPath: "idmApi",
  baseQuery,
  tagTypes: values(Tag),
  endpoints: (builder) => ({
    getStreamToken: builder.query<IDMToken, void>({
      query: () => ({
        method: "POST",
        url: `oauth/stream/token`,
      }),
      providesTags: [Tag.STREAM_TOKEN],
    }),
  }),
});

export const { useGetStreamTokenQuery } = idmApi;
