// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.19.4
// source: core/controls/exterminator/controllers/aimbot/process/aimbot.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";

export const protobufPackage = "aimbot";

export interface PingRequest {
  x: number;
}

export interface PongReply {
  x: number;
}

export interface StartAimbotRequest {
  /** @deprecated */
  algorithm: string;
}

export interface StartAimbotReply {
}

export interface StopAimbotRequest {
}

export interface StopAimbotReply {
}

export interface AimbotState {
  algorithm: string;
  running: boolean;
  armed: boolean;
}

export interface TargetVelocityRequest {
}

export interface TargetVelocityReply {
  velocityMin: number;
  velocityMax: number;
}

export interface LensSetRequest {
  scannerId: number;
  value: number;
}

export interface LensSetReply {
}

export interface LensGetRequest {
  scannerId: number;
}

export interface LensGetReply {
  value: number;
  minValue: number;
  maxValue: number;
  manualAutofocusPercent: number;
  manualAutofocusing: boolean;
  scannerId: number;
}

export interface LensGetAllReply {
  lensStatus: LensGetReply[];
}

export interface LensAutoFocusRequest {
  scannerId: number;
}

export interface LensAutoFocusReply {
}

export interface StopLensAutoFocusRequest {
  scannerId: number;
}

export interface LaserArmRequest {
  scannerId: number;
  armed: boolean;
}

export interface LaserArmReply {
}

export interface LaserEnableRequest {
  scannerId: number;
  enabled: boolean;
}

export interface LaserEnableReply {
  status: boolean;
  message: string;
}

export interface LaserFireRequest {
  scannerId: number;
  fire: boolean;
}

export interface LaserFireReply {
}

export interface LaserSetRequest {
  scannerId: number;
  on: boolean;
}

export interface LaserSetReply {
}

export interface LaserState {
  enabled: boolean;
  firing: boolean;
  error: boolean;
  /**
   * Deprecated, use scanner state
   *
   * @deprecated
   */
  errorCode: string;
  /**
   * Deprecated, use scanner state
   *
   * @deprecated
   */
  errorMessage: string;
  power: boolean;
  deltaTemp: number;
  current: number;
}

export interface ServoGoToRequest {
  scannerId: number;
  servoType: ServoGoToRequest_ServoType;
  position: number;
  timeMs: number;
  awaitSettle: boolean;
}

export enum ServoGoToRequest_ServoType {
  PAN = 0,
  TILT = 1,
  UNRECOGNIZED = -1,
}

export function servoGoToRequest_ServoTypeFromJSON(object: any): ServoGoToRequest_ServoType {
  switch (object) {
    case 0:
    case "PAN":
      return ServoGoToRequest_ServoType.PAN;
    case 1:
    case "TILT":
      return ServoGoToRequest_ServoType.TILT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ServoGoToRequest_ServoType.UNRECOGNIZED;
  }
}

export function servoGoToRequest_ServoTypeToJSON(object: ServoGoToRequest_ServoType): string {
  switch (object) {
    case ServoGoToRequest_ServoType.PAN:
      return "PAN";
    case ServoGoToRequest_ServoType.TILT:
      return "TILT";
    case ServoGoToRequest_ServoType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ServoGoToReply {
}

export interface ServoGetPosVelRequest {
  scannerId: number;
}

export interface ServoGetPosVelReply {
  panPosition: number;
  tiltPosition: number;
  panVelocity: number;
  tiltVelocity: number;
}

export interface ServoGetLimitsRequest {
  scannerId: number;
}

export interface ServoGetLimitsReply {
  panMin: number;
  panMax: number;
  tiltMin: number;
  tiltMax: number;
}

export interface TuningParam {
  name: string;
  vUint?: number | undefined;
  vInt?: number | undefined;
  vBool?: boolean | undefined;
  vFloat?: number | undefined;
  vString?: string | undefined;
}

export interface TuningParamsUpdateRequest {
  params: TuningParam[];
}

export interface TuningParamsUpdateReply {
}

export interface TuningParamsGetRequest {
  name: string;
}

export interface TuningParamsGetReply {
  params: TuningParam[];
}

export interface GetLoadEstimateRequest {
}

export interface GetLoadEstimateReply {
  currentLoad: number;
  targetLoad: number;
}

export interface GetDiagnosticRequest {
}

export interface GetDiagnosticReply {
  diagnostic: string;
}

export interface ResetDevicesRequest {
  deviceId: string;
}

export interface ResetDevicesReply {
}

export interface ScannerDescriptor {
  id: number;
}

export interface ScannerTargetPosition {
  scannerDescriptor: ScannerDescriptor | undefined;
  x: number;
  y: number;
}

export interface CrosshairState {
  x: number;
  y: number;
}

export interface ScannerState {
  scannerDescriptor: ScannerDescriptor | undefined;
  laserState: LaserState | undefined;
  crosshairState: CrosshairState | undefined;
  scannerError: boolean;
  errorCode: string;
  errorMessage: string;
}

export interface ScannerStatusReply {
  states: ScannerState[];
}

export interface BootedReply {
  booted: boolean;
}

export interface TrackerState {
  id: number;
  atWeedLimit: boolean;
}

export interface SchedulerState {
  overCapacity: boolean;
}

export interface TrackingState {
  states: TrackerState[];
  schedulerState: SchedulerState | undefined;
}

export interface BedtopHeightProfile {
  weedHeightColumns: number[];
  cropHeightColumns: number[];
  pcamId: string;
}

export interface TrackerBedtopHeightProfile {
  profiles: BedtopHeightProfile[];
}

export interface GetDimensionsResponse {
  minXMm: number;
  maxXMm: number;
  minYMm: number;
  maxYMm: number;
}

function createBasePingRequest(): PingRequest {
  return { x: 0 };
}

export const PingRequest: MessageFns<PingRequest> = {
  encode(message: PingRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.x !== 0) {
      writer.uint32(8).uint32(message.x);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PingRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.x = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PingRequest {
    return { x: isSet(object.x) ? globalThis.Number(object.x) : 0 };
  },

  toJSON(message: PingRequest): unknown {
    const obj: any = {};
    if (message.x !== 0) {
      obj.x = Math.round(message.x);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PingRequest>, I>>(base?: I): PingRequest {
    return PingRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PingRequest>, I>>(object: I): PingRequest {
    const message = createBasePingRequest();
    message.x = object.x ?? 0;
    return message;
  },
};

function createBasePongReply(): PongReply {
  return { x: 0 };
}

export const PongReply: MessageFns<PongReply> = {
  encode(message: PongReply, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.x !== 0) {
      writer.uint32(8).uint32(message.x);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PongReply {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePongReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.x = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PongReply {
    return { x: isSet(object.x) ? globalThis.Number(object.x) : 0 };
  },

  toJSON(message: PongReply): unknown {
    const obj: any = {};
    if (message.x !== 0) {
      obj.x = Math.round(message.x);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PongReply>, I>>(base?: I): PongReply {
    return PongReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PongReply>, I>>(object: I): PongReply {
    const message = createBasePongReply();
    message.x = object.x ?? 0;
    return message;
  },
};

function createBaseStartAimbotRequest(): StartAimbotRequest {
  return { algorithm: "" };
}

export const StartAimbotRequest: MessageFns<StartAimbotRequest> = {
  encode(message: StartAimbotRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.algorithm !== "") {
      writer.uint32(10).string(message.algorithm);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StartAimbotRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartAimbotRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.algorithm = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StartAimbotRequest {
    return { algorithm: isSet(object.algorithm) ? globalThis.String(object.algorithm) : "" };
  },

  toJSON(message: StartAimbotRequest): unknown {
    const obj: any = {};
    if (message.algorithm !== "") {
      obj.algorithm = message.algorithm;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StartAimbotRequest>, I>>(base?: I): StartAimbotRequest {
    return StartAimbotRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StartAimbotRequest>, I>>(object: I): StartAimbotRequest {
    const message = createBaseStartAimbotRequest();
    message.algorithm = object.algorithm ?? "";
    return message;
  },
};

function createBaseStartAimbotReply(): StartAimbotReply {
  return {};
}

export const StartAimbotReply: MessageFns<StartAimbotReply> = {
  encode(_: StartAimbotReply, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StartAimbotReply {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartAimbotReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): StartAimbotReply {
    return {};
  },

  toJSON(_: StartAimbotReply): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<StartAimbotReply>, I>>(base?: I): StartAimbotReply {
    return StartAimbotReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StartAimbotReply>, I>>(_: I): StartAimbotReply {
    const message = createBaseStartAimbotReply();
    return message;
  },
};

function createBaseStopAimbotRequest(): StopAimbotRequest {
  return {};
}

export const StopAimbotRequest: MessageFns<StopAimbotRequest> = {
  encode(_: StopAimbotRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StopAimbotRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStopAimbotRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): StopAimbotRequest {
    return {};
  },

  toJSON(_: StopAimbotRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<StopAimbotRequest>, I>>(base?: I): StopAimbotRequest {
    return StopAimbotRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StopAimbotRequest>, I>>(_: I): StopAimbotRequest {
    const message = createBaseStopAimbotRequest();
    return message;
  },
};

function createBaseStopAimbotReply(): StopAimbotReply {
  return {};
}

export const StopAimbotReply: MessageFns<StopAimbotReply> = {
  encode(_: StopAimbotReply, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StopAimbotReply {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStopAimbotReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): StopAimbotReply {
    return {};
  },

  toJSON(_: StopAimbotReply): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<StopAimbotReply>, I>>(base?: I): StopAimbotReply {
    return StopAimbotReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StopAimbotReply>, I>>(_: I): StopAimbotReply {
    const message = createBaseStopAimbotReply();
    return message;
  },
};

function createBaseAimbotState(): AimbotState {
  return { algorithm: "", running: false, armed: false };
}

export const AimbotState: MessageFns<AimbotState> = {
  encode(message: AimbotState, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.algorithm !== "") {
      writer.uint32(10).string(message.algorithm);
    }
    if (message.running !== false) {
      writer.uint32(16).bool(message.running);
    }
    if (message.armed !== false) {
      writer.uint32(24).bool(message.armed);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AimbotState {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAimbotState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.algorithm = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.running = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.armed = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AimbotState {
    return {
      algorithm: isSet(object.algorithm) ? globalThis.String(object.algorithm) : "",
      running: isSet(object.running) ? globalThis.Boolean(object.running) : false,
      armed: isSet(object.armed) ? globalThis.Boolean(object.armed) : false,
    };
  },

  toJSON(message: AimbotState): unknown {
    const obj: any = {};
    if (message.algorithm !== "") {
      obj.algorithm = message.algorithm;
    }
    if (message.running !== false) {
      obj.running = message.running;
    }
    if (message.armed !== false) {
      obj.armed = message.armed;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AimbotState>, I>>(base?: I): AimbotState {
    return AimbotState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AimbotState>, I>>(object: I): AimbotState {
    const message = createBaseAimbotState();
    message.algorithm = object.algorithm ?? "";
    message.running = object.running ?? false;
    message.armed = object.armed ?? false;
    return message;
  },
};

function createBaseTargetVelocityRequest(): TargetVelocityRequest {
  return {};
}

export const TargetVelocityRequest: MessageFns<TargetVelocityRequest> = {
  encode(_: TargetVelocityRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TargetVelocityRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTargetVelocityRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): TargetVelocityRequest {
    return {};
  },

  toJSON(_: TargetVelocityRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<TargetVelocityRequest>, I>>(base?: I): TargetVelocityRequest {
    return TargetVelocityRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TargetVelocityRequest>, I>>(_: I): TargetVelocityRequest {
    const message = createBaseTargetVelocityRequest();
    return message;
  },
};

function createBaseTargetVelocityReply(): TargetVelocityReply {
  return { velocityMin: 0, velocityMax: 0 };
}

export const TargetVelocityReply: MessageFns<TargetVelocityReply> = {
  encode(message: TargetVelocityReply, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.velocityMin !== 0) {
      writer.uint32(13).float(message.velocityMin);
    }
    if (message.velocityMax !== 0) {
      writer.uint32(21).float(message.velocityMax);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TargetVelocityReply {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTargetVelocityReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.velocityMin = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.velocityMax = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TargetVelocityReply {
    return {
      velocityMin: isSet(object.velocityMin) ? globalThis.Number(object.velocityMin) : 0,
      velocityMax: isSet(object.velocityMax) ? globalThis.Number(object.velocityMax) : 0,
    };
  },

  toJSON(message: TargetVelocityReply): unknown {
    const obj: any = {};
    if (message.velocityMin !== 0) {
      obj.velocityMin = message.velocityMin;
    }
    if (message.velocityMax !== 0) {
      obj.velocityMax = message.velocityMax;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TargetVelocityReply>, I>>(base?: I): TargetVelocityReply {
    return TargetVelocityReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TargetVelocityReply>, I>>(object: I): TargetVelocityReply {
    const message = createBaseTargetVelocityReply();
    message.velocityMin = object.velocityMin ?? 0;
    message.velocityMax = object.velocityMax ?? 0;
    return message;
  },
};

function createBaseLensSetRequest(): LensSetRequest {
  return { scannerId: 0, value: 0 };
}

export const LensSetRequest: MessageFns<LensSetRequest> = {
  encode(message: LensSetRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.scannerId !== 0) {
      writer.uint32(8).uint32(message.scannerId);
    }
    if (message.value !== 0) {
      writer.uint32(16).uint32(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LensSetRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLensSetRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.scannerId = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LensSetRequest {
    return {
      scannerId: isSet(object.scannerId) ? globalThis.Number(object.scannerId) : 0,
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
    };
  },

  toJSON(message: LensSetRequest): unknown {
    const obj: any = {};
    if (message.scannerId !== 0) {
      obj.scannerId = Math.round(message.scannerId);
    }
    if (message.value !== 0) {
      obj.value = Math.round(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LensSetRequest>, I>>(base?: I): LensSetRequest {
    return LensSetRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LensSetRequest>, I>>(object: I): LensSetRequest {
    const message = createBaseLensSetRequest();
    message.scannerId = object.scannerId ?? 0;
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseLensSetReply(): LensSetReply {
  return {};
}

export const LensSetReply: MessageFns<LensSetReply> = {
  encode(_: LensSetReply, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LensSetReply {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLensSetReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): LensSetReply {
    return {};
  },

  toJSON(_: LensSetReply): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<LensSetReply>, I>>(base?: I): LensSetReply {
    return LensSetReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LensSetReply>, I>>(_: I): LensSetReply {
    const message = createBaseLensSetReply();
    return message;
  },
};

function createBaseLensGetRequest(): LensGetRequest {
  return { scannerId: 0 };
}

export const LensGetRequest: MessageFns<LensGetRequest> = {
  encode(message: LensGetRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.scannerId !== 0) {
      writer.uint32(8).uint32(message.scannerId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LensGetRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLensGetRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.scannerId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LensGetRequest {
    return { scannerId: isSet(object.scannerId) ? globalThis.Number(object.scannerId) : 0 };
  },

  toJSON(message: LensGetRequest): unknown {
    const obj: any = {};
    if (message.scannerId !== 0) {
      obj.scannerId = Math.round(message.scannerId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LensGetRequest>, I>>(base?: I): LensGetRequest {
    return LensGetRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LensGetRequest>, I>>(object: I): LensGetRequest {
    const message = createBaseLensGetRequest();
    message.scannerId = object.scannerId ?? 0;
    return message;
  },
};

function createBaseLensGetReply(): LensGetReply {
  return { value: 0, minValue: 0, maxValue: 0, manualAutofocusPercent: 0, manualAutofocusing: false, scannerId: 0 };
}

export const LensGetReply: MessageFns<LensGetReply> = {
  encode(message: LensGetReply, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.value !== 0) {
      writer.uint32(8).uint32(message.value);
    }
    if (message.minValue !== 0) {
      writer.uint32(16).uint32(message.minValue);
    }
    if (message.maxValue !== 0) {
      writer.uint32(24).uint32(message.maxValue);
    }
    if (message.manualAutofocusPercent !== 0) {
      writer.uint32(37).float(message.manualAutofocusPercent);
    }
    if (message.manualAutofocusing !== false) {
      writer.uint32(40).bool(message.manualAutofocusing);
    }
    if (message.scannerId !== 0) {
      writer.uint32(48).uint32(message.scannerId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LensGetReply {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLensGetReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.value = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.minValue = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.maxValue = reader.uint32();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.manualAutofocusPercent = reader.float();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.manualAutofocusing = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.scannerId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LensGetReply {
    return {
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
      minValue: isSet(object.minValue) ? globalThis.Number(object.minValue) : 0,
      maxValue: isSet(object.maxValue) ? globalThis.Number(object.maxValue) : 0,
      manualAutofocusPercent: isSet(object.manualAutofocusPercent)
        ? globalThis.Number(object.manualAutofocusPercent)
        : 0,
      manualAutofocusing: isSet(object.manualAutofocusing) ? globalThis.Boolean(object.manualAutofocusing) : false,
      scannerId: isSet(object.scannerId) ? globalThis.Number(object.scannerId) : 0,
    };
  },

  toJSON(message: LensGetReply): unknown {
    const obj: any = {};
    if (message.value !== 0) {
      obj.value = Math.round(message.value);
    }
    if (message.minValue !== 0) {
      obj.minValue = Math.round(message.minValue);
    }
    if (message.maxValue !== 0) {
      obj.maxValue = Math.round(message.maxValue);
    }
    if (message.manualAutofocusPercent !== 0) {
      obj.manualAutofocusPercent = message.manualAutofocusPercent;
    }
    if (message.manualAutofocusing !== false) {
      obj.manualAutofocusing = message.manualAutofocusing;
    }
    if (message.scannerId !== 0) {
      obj.scannerId = Math.round(message.scannerId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LensGetReply>, I>>(base?: I): LensGetReply {
    return LensGetReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LensGetReply>, I>>(object: I): LensGetReply {
    const message = createBaseLensGetReply();
    message.value = object.value ?? 0;
    message.minValue = object.minValue ?? 0;
    message.maxValue = object.maxValue ?? 0;
    message.manualAutofocusPercent = object.manualAutofocusPercent ?? 0;
    message.manualAutofocusing = object.manualAutofocusing ?? false;
    message.scannerId = object.scannerId ?? 0;
    return message;
  },
};

function createBaseLensGetAllReply(): LensGetAllReply {
  return { lensStatus: [] };
}

export const LensGetAllReply: MessageFns<LensGetAllReply> = {
  encode(message: LensGetAllReply, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.lensStatus) {
      LensGetReply.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LensGetAllReply {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLensGetAllReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.lensStatus.push(LensGetReply.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LensGetAllReply {
    return {
      lensStatus: globalThis.Array.isArray(object?.lensStatus)
        ? object.lensStatus.map((e: any) => LensGetReply.fromJSON(e))
        : [],
    };
  },

  toJSON(message: LensGetAllReply): unknown {
    const obj: any = {};
    if (message.lensStatus?.length) {
      obj.lensStatus = message.lensStatus.map((e) => LensGetReply.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LensGetAllReply>, I>>(base?: I): LensGetAllReply {
    return LensGetAllReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LensGetAllReply>, I>>(object: I): LensGetAllReply {
    const message = createBaseLensGetAllReply();
    message.lensStatus = object.lensStatus?.map((e) => LensGetReply.fromPartial(e)) || [];
    return message;
  },
};

function createBaseLensAutoFocusRequest(): LensAutoFocusRequest {
  return { scannerId: 0 };
}

export const LensAutoFocusRequest: MessageFns<LensAutoFocusRequest> = {
  encode(message: LensAutoFocusRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.scannerId !== 0) {
      writer.uint32(8).uint32(message.scannerId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LensAutoFocusRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLensAutoFocusRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.scannerId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LensAutoFocusRequest {
    return { scannerId: isSet(object.scannerId) ? globalThis.Number(object.scannerId) : 0 };
  },

  toJSON(message: LensAutoFocusRequest): unknown {
    const obj: any = {};
    if (message.scannerId !== 0) {
      obj.scannerId = Math.round(message.scannerId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LensAutoFocusRequest>, I>>(base?: I): LensAutoFocusRequest {
    return LensAutoFocusRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LensAutoFocusRequest>, I>>(object: I): LensAutoFocusRequest {
    const message = createBaseLensAutoFocusRequest();
    message.scannerId = object.scannerId ?? 0;
    return message;
  },
};

function createBaseLensAutoFocusReply(): LensAutoFocusReply {
  return {};
}

export const LensAutoFocusReply: MessageFns<LensAutoFocusReply> = {
  encode(_: LensAutoFocusReply, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LensAutoFocusReply {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLensAutoFocusReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): LensAutoFocusReply {
    return {};
  },

  toJSON(_: LensAutoFocusReply): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<LensAutoFocusReply>, I>>(base?: I): LensAutoFocusReply {
    return LensAutoFocusReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LensAutoFocusReply>, I>>(_: I): LensAutoFocusReply {
    const message = createBaseLensAutoFocusReply();
    return message;
  },
};

function createBaseStopLensAutoFocusRequest(): StopLensAutoFocusRequest {
  return { scannerId: 0 };
}

export const StopLensAutoFocusRequest: MessageFns<StopLensAutoFocusRequest> = {
  encode(message: StopLensAutoFocusRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.scannerId !== 0) {
      writer.uint32(8).uint32(message.scannerId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StopLensAutoFocusRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStopLensAutoFocusRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.scannerId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StopLensAutoFocusRequest {
    return { scannerId: isSet(object.scannerId) ? globalThis.Number(object.scannerId) : 0 };
  },

  toJSON(message: StopLensAutoFocusRequest): unknown {
    const obj: any = {};
    if (message.scannerId !== 0) {
      obj.scannerId = Math.round(message.scannerId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StopLensAutoFocusRequest>, I>>(base?: I): StopLensAutoFocusRequest {
    return StopLensAutoFocusRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StopLensAutoFocusRequest>, I>>(object: I): StopLensAutoFocusRequest {
    const message = createBaseStopLensAutoFocusRequest();
    message.scannerId = object.scannerId ?? 0;
    return message;
  },
};

function createBaseLaserArmRequest(): LaserArmRequest {
  return { scannerId: 0, armed: false };
}

export const LaserArmRequest: MessageFns<LaserArmRequest> = {
  encode(message: LaserArmRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.scannerId !== 0) {
      writer.uint32(8).uint32(message.scannerId);
    }
    if (message.armed !== false) {
      writer.uint32(16).bool(message.armed);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LaserArmRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLaserArmRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.scannerId = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.armed = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LaserArmRequest {
    return {
      scannerId: isSet(object.scannerId) ? globalThis.Number(object.scannerId) : 0,
      armed: isSet(object.armed) ? globalThis.Boolean(object.armed) : false,
    };
  },

  toJSON(message: LaserArmRequest): unknown {
    const obj: any = {};
    if (message.scannerId !== 0) {
      obj.scannerId = Math.round(message.scannerId);
    }
    if (message.armed !== false) {
      obj.armed = message.armed;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LaserArmRequest>, I>>(base?: I): LaserArmRequest {
    return LaserArmRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LaserArmRequest>, I>>(object: I): LaserArmRequest {
    const message = createBaseLaserArmRequest();
    message.scannerId = object.scannerId ?? 0;
    message.armed = object.armed ?? false;
    return message;
  },
};

function createBaseLaserArmReply(): LaserArmReply {
  return {};
}

export const LaserArmReply: MessageFns<LaserArmReply> = {
  encode(_: LaserArmReply, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LaserArmReply {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLaserArmReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): LaserArmReply {
    return {};
  },

  toJSON(_: LaserArmReply): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<LaserArmReply>, I>>(base?: I): LaserArmReply {
    return LaserArmReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LaserArmReply>, I>>(_: I): LaserArmReply {
    const message = createBaseLaserArmReply();
    return message;
  },
};

function createBaseLaserEnableRequest(): LaserEnableRequest {
  return { scannerId: 0, enabled: false };
}

export const LaserEnableRequest: MessageFns<LaserEnableRequest> = {
  encode(message: LaserEnableRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.scannerId !== 0) {
      writer.uint32(8).uint32(message.scannerId);
    }
    if (message.enabled !== false) {
      writer.uint32(16).bool(message.enabled);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LaserEnableRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLaserEnableRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.scannerId = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.enabled = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LaserEnableRequest {
    return {
      scannerId: isSet(object.scannerId) ? globalThis.Number(object.scannerId) : 0,
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
    };
  },

  toJSON(message: LaserEnableRequest): unknown {
    const obj: any = {};
    if (message.scannerId !== 0) {
      obj.scannerId = Math.round(message.scannerId);
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LaserEnableRequest>, I>>(base?: I): LaserEnableRequest {
    return LaserEnableRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LaserEnableRequest>, I>>(object: I): LaserEnableRequest {
    const message = createBaseLaserEnableRequest();
    message.scannerId = object.scannerId ?? 0;
    message.enabled = object.enabled ?? false;
    return message;
  },
};

function createBaseLaserEnableReply(): LaserEnableReply {
  return { status: false, message: "" };
}

export const LaserEnableReply: MessageFns<LaserEnableReply> = {
  encode(message: LaserEnableReply, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.status !== false) {
      writer.uint32(8).bool(message.status);
    }
    if (message.message !== "") {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LaserEnableReply {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLaserEnableReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LaserEnableReply {
    return {
      status: isSet(object.status) ? globalThis.Boolean(object.status) : false,
      message: isSet(object.message) ? globalThis.String(object.message) : "",
    };
  },

  toJSON(message: LaserEnableReply): unknown {
    const obj: any = {};
    if (message.status !== false) {
      obj.status = message.status;
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LaserEnableReply>, I>>(base?: I): LaserEnableReply {
    return LaserEnableReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LaserEnableReply>, I>>(object: I): LaserEnableReply {
    const message = createBaseLaserEnableReply();
    message.status = object.status ?? false;
    message.message = object.message ?? "";
    return message;
  },
};

function createBaseLaserFireRequest(): LaserFireRequest {
  return { scannerId: 0, fire: false };
}

export const LaserFireRequest: MessageFns<LaserFireRequest> = {
  encode(message: LaserFireRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.scannerId !== 0) {
      writer.uint32(8).uint32(message.scannerId);
    }
    if (message.fire !== false) {
      writer.uint32(16).bool(message.fire);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LaserFireRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLaserFireRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.scannerId = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.fire = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LaserFireRequest {
    return {
      scannerId: isSet(object.scannerId) ? globalThis.Number(object.scannerId) : 0,
      fire: isSet(object.fire) ? globalThis.Boolean(object.fire) : false,
    };
  },

  toJSON(message: LaserFireRequest): unknown {
    const obj: any = {};
    if (message.scannerId !== 0) {
      obj.scannerId = Math.round(message.scannerId);
    }
    if (message.fire !== false) {
      obj.fire = message.fire;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LaserFireRequest>, I>>(base?: I): LaserFireRequest {
    return LaserFireRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LaserFireRequest>, I>>(object: I): LaserFireRequest {
    const message = createBaseLaserFireRequest();
    message.scannerId = object.scannerId ?? 0;
    message.fire = object.fire ?? false;
    return message;
  },
};

function createBaseLaserFireReply(): LaserFireReply {
  return {};
}

export const LaserFireReply: MessageFns<LaserFireReply> = {
  encode(_: LaserFireReply, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LaserFireReply {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLaserFireReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): LaserFireReply {
    return {};
  },

  toJSON(_: LaserFireReply): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<LaserFireReply>, I>>(base?: I): LaserFireReply {
    return LaserFireReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LaserFireReply>, I>>(_: I): LaserFireReply {
    const message = createBaseLaserFireReply();
    return message;
  },
};

function createBaseLaserSetRequest(): LaserSetRequest {
  return { scannerId: 0, on: false };
}

export const LaserSetRequest: MessageFns<LaserSetRequest> = {
  encode(message: LaserSetRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.scannerId !== 0) {
      writer.uint32(8).uint32(message.scannerId);
    }
    if (message.on !== false) {
      writer.uint32(16).bool(message.on);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LaserSetRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLaserSetRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.scannerId = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.on = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LaserSetRequest {
    return {
      scannerId: isSet(object.scannerId) ? globalThis.Number(object.scannerId) : 0,
      on: isSet(object.on) ? globalThis.Boolean(object.on) : false,
    };
  },

  toJSON(message: LaserSetRequest): unknown {
    const obj: any = {};
    if (message.scannerId !== 0) {
      obj.scannerId = Math.round(message.scannerId);
    }
    if (message.on !== false) {
      obj.on = message.on;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LaserSetRequest>, I>>(base?: I): LaserSetRequest {
    return LaserSetRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LaserSetRequest>, I>>(object: I): LaserSetRequest {
    const message = createBaseLaserSetRequest();
    message.scannerId = object.scannerId ?? 0;
    message.on = object.on ?? false;
    return message;
  },
};

function createBaseLaserSetReply(): LaserSetReply {
  return {};
}

export const LaserSetReply: MessageFns<LaserSetReply> = {
  encode(_: LaserSetReply, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LaserSetReply {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLaserSetReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): LaserSetReply {
    return {};
  },

  toJSON(_: LaserSetReply): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<LaserSetReply>, I>>(base?: I): LaserSetReply {
    return LaserSetReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LaserSetReply>, I>>(_: I): LaserSetReply {
    const message = createBaseLaserSetReply();
    return message;
  },
};

function createBaseLaserState(): LaserState {
  return {
    enabled: false,
    firing: false,
    error: false,
    errorCode: "",
    errorMessage: "",
    power: false,
    deltaTemp: 0,
    current: 0,
  };
}

export const LaserState: MessageFns<LaserState> = {
  encode(message: LaserState, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.enabled !== false) {
      writer.uint32(8).bool(message.enabled);
    }
    if (message.firing !== false) {
      writer.uint32(16).bool(message.firing);
    }
    if (message.error !== false) {
      writer.uint32(24).bool(message.error);
    }
    if (message.errorCode !== "") {
      writer.uint32(34).string(message.errorCode);
    }
    if (message.errorMessage !== "") {
      writer.uint32(42).string(message.errorMessage);
    }
    if (message.power !== false) {
      writer.uint32(48).bool(message.power);
    }
    if (message.deltaTemp !== 0) {
      writer.uint32(61).float(message.deltaTemp);
    }
    if (message.current !== 0) {
      writer.uint32(69).float(message.current);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LaserState {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLaserState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.firing = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.error = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.errorCode = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.errorMessage = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.power = reader.bool();
          continue;
        case 7:
          if (tag !== 61) {
            break;
          }

          message.deltaTemp = reader.float();
          continue;
        case 8:
          if (tag !== 69) {
            break;
          }

          message.current = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LaserState {
    return {
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      firing: isSet(object.firing) ? globalThis.Boolean(object.firing) : false,
      error: isSet(object.error) ? globalThis.Boolean(object.error) : false,
      errorCode: isSet(object.errorCode) ? globalThis.String(object.errorCode) : "",
      errorMessage: isSet(object.errorMessage) ? globalThis.String(object.errorMessage) : "",
      power: isSet(object.power) ? globalThis.Boolean(object.power) : false,
      deltaTemp: isSet(object.deltaTemp) ? globalThis.Number(object.deltaTemp) : 0,
      current: isSet(object.current) ? globalThis.Number(object.current) : 0,
    };
  },

  toJSON(message: LaserState): unknown {
    const obj: any = {};
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.firing !== false) {
      obj.firing = message.firing;
    }
    if (message.error !== false) {
      obj.error = message.error;
    }
    if (message.errorCode !== "") {
      obj.errorCode = message.errorCode;
    }
    if (message.errorMessage !== "") {
      obj.errorMessage = message.errorMessage;
    }
    if (message.power !== false) {
      obj.power = message.power;
    }
    if (message.deltaTemp !== 0) {
      obj.deltaTemp = message.deltaTemp;
    }
    if (message.current !== 0) {
      obj.current = message.current;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LaserState>, I>>(base?: I): LaserState {
    return LaserState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LaserState>, I>>(object: I): LaserState {
    const message = createBaseLaserState();
    message.enabled = object.enabled ?? false;
    message.firing = object.firing ?? false;
    message.error = object.error ?? false;
    message.errorCode = object.errorCode ?? "";
    message.errorMessage = object.errorMessage ?? "";
    message.power = object.power ?? false;
    message.deltaTemp = object.deltaTemp ?? 0;
    message.current = object.current ?? 0;
    return message;
  },
};

function createBaseServoGoToRequest(): ServoGoToRequest {
  return { scannerId: 0, servoType: 0, position: 0, timeMs: 0, awaitSettle: false };
}

export const ServoGoToRequest: MessageFns<ServoGoToRequest> = {
  encode(message: ServoGoToRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.scannerId !== 0) {
      writer.uint32(8).uint32(message.scannerId);
    }
    if (message.servoType !== 0) {
      writer.uint32(16).int32(message.servoType);
    }
    if (message.position !== 0) {
      writer.uint32(24).int32(message.position);
    }
    if (message.timeMs !== 0) {
      writer.uint32(32).uint32(message.timeMs);
    }
    if (message.awaitSettle !== false) {
      writer.uint32(40).bool(message.awaitSettle);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ServoGoToRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServoGoToRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.scannerId = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.servoType = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.position = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.timeMs = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.awaitSettle = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServoGoToRequest {
    return {
      scannerId: isSet(object.scannerId) ? globalThis.Number(object.scannerId) : 0,
      servoType: isSet(object.servoType) ? servoGoToRequest_ServoTypeFromJSON(object.servoType) : 0,
      position: isSet(object.position) ? globalThis.Number(object.position) : 0,
      timeMs: isSet(object.timeMs) ? globalThis.Number(object.timeMs) : 0,
      awaitSettle: isSet(object.awaitSettle) ? globalThis.Boolean(object.awaitSettle) : false,
    };
  },

  toJSON(message: ServoGoToRequest): unknown {
    const obj: any = {};
    if (message.scannerId !== 0) {
      obj.scannerId = Math.round(message.scannerId);
    }
    if (message.servoType !== 0) {
      obj.servoType = servoGoToRequest_ServoTypeToJSON(message.servoType);
    }
    if (message.position !== 0) {
      obj.position = Math.round(message.position);
    }
    if (message.timeMs !== 0) {
      obj.timeMs = Math.round(message.timeMs);
    }
    if (message.awaitSettle !== false) {
      obj.awaitSettle = message.awaitSettle;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServoGoToRequest>, I>>(base?: I): ServoGoToRequest {
    return ServoGoToRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServoGoToRequest>, I>>(object: I): ServoGoToRequest {
    const message = createBaseServoGoToRequest();
    message.scannerId = object.scannerId ?? 0;
    message.servoType = object.servoType ?? 0;
    message.position = object.position ?? 0;
    message.timeMs = object.timeMs ?? 0;
    message.awaitSettle = object.awaitSettle ?? false;
    return message;
  },
};

function createBaseServoGoToReply(): ServoGoToReply {
  return {};
}

export const ServoGoToReply: MessageFns<ServoGoToReply> = {
  encode(_: ServoGoToReply, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ServoGoToReply {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServoGoToReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ServoGoToReply {
    return {};
  },

  toJSON(_: ServoGoToReply): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ServoGoToReply>, I>>(base?: I): ServoGoToReply {
    return ServoGoToReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServoGoToReply>, I>>(_: I): ServoGoToReply {
    const message = createBaseServoGoToReply();
    return message;
  },
};

function createBaseServoGetPosVelRequest(): ServoGetPosVelRequest {
  return { scannerId: 0 };
}

export const ServoGetPosVelRequest: MessageFns<ServoGetPosVelRequest> = {
  encode(message: ServoGetPosVelRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.scannerId !== 0) {
      writer.uint32(8).uint32(message.scannerId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ServoGetPosVelRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServoGetPosVelRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.scannerId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServoGetPosVelRequest {
    return { scannerId: isSet(object.scannerId) ? globalThis.Number(object.scannerId) : 0 };
  },

  toJSON(message: ServoGetPosVelRequest): unknown {
    const obj: any = {};
    if (message.scannerId !== 0) {
      obj.scannerId = Math.round(message.scannerId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServoGetPosVelRequest>, I>>(base?: I): ServoGetPosVelRequest {
    return ServoGetPosVelRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServoGetPosVelRequest>, I>>(object: I): ServoGetPosVelRequest {
    const message = createBaseServoGetPosVelRequest();
    message.scannerId = object.scannerId ?? 0;
    return message;
  },
};

function createBaseServoGetPosVelReply(): ServoGetPosVelReply {
  return { panPosition: 0, tiltPosition: 0, panVelocity: 0, tiltVelocity: 0 };
}

export const ServoGetPosVelReply: MessageFns<ServoGetPosVelReply> = {
  encode(message: ServoGetPosVelReply, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.panPosition !== 0) {
      writer.uint32(8).int32(message.panPosition);
    }
    if (message.tiltPosition !== 0) {
      writer.uint32(16).int32(message.tiltPosition);
    }
    if (message.panVelocity !== 0) {
      writer.uint32(24).uint32(message.panVelocity);
    }
    if (message.tiltVelocity !== 0) {
      writer.uint32(32).uint32(message.tiltVelocity);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ServoGetPosVelReply {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServoGetPosVelReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.panPosition = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.tiltPosition = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.panVelocity = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.tiltVelocity = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServoGetPosVelReply {
    return {
      panPosition: isSet(object.panPosition) ? globalThis.Number(object.panPosition) : 0,
      tiltPosition: isSet(object.tiltPosition) ? globalThis.Number(object.tiltPosition) : 0,
      panVelocity: isSet(object.panVelocity) ? globalThis.Number(object.panVelocity) : 0,
      tiltVelocity: isSet(object.tiltVelocity) ? globalThis.Number(object.tiltVelocity) : 0,
    };
  },

  toJSON(message: ServoGetPosVelReply): unknown {
    const obj: any = {};
    if (message.panPosition !== 0) {
      obj.panPosition = Math.round(message.panPosition);
    }
    if (message.tiltPosition !== 0) {
      obj.tiltPosition = Math.round(message.tiltPosition);
    }
    if (message.panVelocity !== 0) {
      obj.panVelocity = Math.round(message.panVelocity);
    }
    if (message.tiltVelocity !== 0) {
      obj.tiltVelocity = Math.round(message.tiltVelocity);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServoGetPosVelReply>, I>>(base?: I): ServoGetPosVelReply {
    return ServoGetPosVelReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServoGetPosVelReply>, I>>(object: I): ServoGetPosVelReply {
    const message = createBaseServoGetPosVelReply();
    message.panPosition = object.panPosition ?? 0;
    message.tiltPosition = object.tiltPosition ?? 0;
    message.panVelocity = object.panVelocity ?? 0;
    message.tiltVelocity = object.tiltVelocity ?? 0;
    return message;
  },
};

function createBaseServoGetLimitsRequest(): ServoGetLimitsRequest {
  return { scannerId: 0 };
}

export const ServoGetLimitsRequest: MessageFns<ServoGetLimitsRequest> = {
  encode(message: ServoGetLimitsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.scannerId !== 0) {
      writer.uint32(8).uint32(message.scannerId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ServoGetLimitsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServoGetLimitsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.scannerId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServoGetLimitsRequest {
    return { scannerId: isSet(object.scannerId) ? globalThis.Number(object.scannerId) : 0 };
  },

  toJSON(message: ServoGetLimitsRequest): unknown {
    const obj: any = {};
    if (message.scannerId !== 0) {
      obj.scannerId = Math.round(message.scannerId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServoGetLimitsRequest>, I>>(base?: I): ServoGetLimitsRequest {
    return ServoGetLimitsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServoGetLimitsRequest>, I>>(object: I): ServoGetLimitsRequest {
    const message = createBaseServoGetLimitsRequest();
    message.scannerId = object.scannerId ?? 0;
    return message;
  },
};

function createBaseServoGetLimitsReply(): ServoGetLimitsReply {
  return { panMin: 0, panMax: 0, tiltMin: 0, tiltMax: 0 };
}

export const ServoGetLimitsReply: MessageFns<ServoGetLimitsReply> = {
  encode(message: ServoGetLimitsReply, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.panMin !== 0) {
      writer.uint32(8).int32(message.panMin);
    }
    if (message.panMax !== 0) {
      writer.uint32(16).int32(message.panMax);
    }
    if (message.tiltMin !== 0) {
      writer.uint32(24).int32(message.tiltMin);
    }
    if (message.tiltMax !== 0) {
      writer.uint32(32).int32(message.tiltMax);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ServoGetLimitsReply {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServoGetLimitsReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.panMin = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.panMax = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.tiltMin = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.tiltMax = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServoGetLimitsReply {
    return {
      panMin: isSet(object.panMin) ? globalThis.Number(object.panMin) : 0,
      panMax: isSet(object.panMax) ? globalThis.Number(object.panMax) : 0,
      tiltMin: isSet(object.tiltMin) ? globalThis.Number(object.tiltMin) : 0,
      tiltMax: isSet(object.tiltMax) ? globalThis.Number(object.tiltMax) : 0,
    };
  },

  toJSON(message: ServoGetLimitsReply): unknown {
    const obj: any = {};
    if (message.panMin !== 0) {
      obj.panMin = Math.round(message.panMin);
    }
    if (message.panMax !== 0) {
      obj.panMax = Math.round(message.panMax);
    }
    if (message.tiltMin !== 0) {
      obj.tiltMin = Math.round(message.tiltMin);
    }
    if (message.tiltMax !== 0) {
      obj.tiltMax = Math.round(message.tiltMax);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServoGetLimitsReply>, I>>(base?: I): ServoGetLimitsReply {
    return ServoGetLimitsReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServoGetLimitsReply>, I>>(object: I): ServoGetLimitsReply {
    const message = createBaseServoGetLimitsReply();
    message.panMin = object.panMin ?? 0;
    message.panMax = object.panMax ?? 0;
    message.tiltMin = object.tiltMin ?? 0;
    message.tiltMax = object.tiltMax ?? 0;
    return message;
  },
};

function createBaseTuningParam(): TuningParam {
  return { name: "", vUint: undefined, vInt: undefined, vBool: undefined, vFloat: undefined, vString: undefined };
}

export const TuningParam: MessageFns<TuningParam> = {
  encode(message: TuningParam, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.vUint !== undefined) {
      writer.uint32(16).uint32(message.vUint);
    }
    if (message.vInt !== undefined) {
      writer.uint32(24).int32(message.vInt);
    }
    if (message.vBool !== undefined) {
      writer.uint32(32).bool(message.vBool);
    }
    if (message.vFloat !== undefined) {
      writer.uint32(45).float(message.vFloat);
    }
    if (message.vString !== undefined) {
      writer.uint32(50).string(message.vString);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TuningParam {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTuningParam();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.vUint = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.vInt = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.vBool = reader.bool();
          continue;
        case 5:
          if (tag !== 45) {
            break;
          }

          message.vFloat = reader.float();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.vString = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TuningParam {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      vUint: isSet(object.vUint) ? globalThis.Number(object.vUint) : undefined,
      vInt: isSet(object.vInt) ? globalThis.Number(object.vInt) : undefined,
      vBool: isSet(object.vBool) ? globalThis.Boolean(object.vBool) : undefined,
      vFloat: isSet(object.vFloat) ? globalThis.Number(object.vFloat) : undefined,
      vString: isSet(object.vString) ? globalThis.String(object.vString) : undefined,
    };
  },

  toJSON(message: TuningParam): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.vUint !== undefined) {
      obj.vUint = Math.round(message.vUint);
    }
    if (message.vInt !== undefined) {
      obj.vInt = Math.round(message.vInt);
    }
    if (message.vBool !== undefined) {
      obj.vBool = message.vBool;
    }
    if (message.vFloat !== undefined) {
      obj.vFloat = message.vFloat;
    }
    if (message.vString !== undefined) {
      obj.vString = message.vString;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TuningParam>, I>>(base?: I): TuningParam {
    return TuningParam.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TuningParam>, I>>(object: I): TuningParam {
    const message = createBaseTuningParam();
    message.name = object.name ?? "";
    message.vUint = object.vUint ?? undefined;
    message.vInt = object.vInt ?? undefined;
    message.vBool = object.vBool ?? undefined;
    message.vFloat = object.vFloat ?? undefined;
    message.vString = object.vString ?? undefined;
    return message;
  },
};

function createBaseTuningParamsUpdateRequest(): TuningParamsUpdateRequest {
  return { params: [] };
}

export const TuningParamsUpdateRequest: MessageFns<TuningParamsUpdateRequest> = {
  encode(message: TuningParamsUpdateRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.params) {
      TuningParam.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TuningParamsUpdateRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTuningParamsUpdateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.params.push(TuningParam.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TuningParamsUpdateRequest {
    return {
      params: globalThis.Array.isArray(object?.params) ? object.params.map((e: any) => TuningParam.fromJSON(e)) : [],
    };
  },

  toJSON(message: TuningParamsUpdateRequest): unknown {
    const obj: any = {};
    if (message.params?.length) {
      obj.params = message.params.map((e) => TuningParam.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TuningParamsUpdateRequest>, I>>(base?: I): TuningParamsUpdateRequest {
    return TuningParamsUpdateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TuningParamsUpdateRequest>, I>>(object: I): TuningParamsUpdateRequest {
    const message = createBaseTuningParamsUpdateRequest();
    message.params = object.params?.map((e) => TuningParam.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTuningParamsUpdateReply(): TuningParamsUpdateReply {
  return {};
}

export const TuningParamsUpdateReply: MessageFns<TuningParamsUpdateReply> = {
  encode(_: TuningParamsUpdateReply, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TuningParamsUpdateReply {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTuningParamsUpdateReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): TuningParamsUpdateReply {
    return {};
  },

  toJSON(_: TuningParamsUpdateReply): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<TuningParamsUpdateReply>, I>>(base?: I): TuningParamsUpdateReply {
    return TuningParamsUpdateReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TuningParamsUpdateReply>, I>>(_: I): TuningParamsUpdateReply {
    const message = createBaseTuningParamsUpdateReply();
    return message;
  },
};

function createBaseTuningParamsGetRequest(): TuningParamsGetRequest {
  return { name: "" };
}

export const TuningParamsGetRequest: MessageFns<TuningParamsGetRequest> = {
  encode(message: TuningParamsGetRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TuningParamsGetRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTuningParamsGetRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TuningParamsGetRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: TuningParamsGetRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TuningParamsGetRequest>, I>>(base?: I): TuningParamsGetRequest {
    return TuningParamsGetRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TuningParamsGetRequest>, I>>(object: I): TuningParamsGetRequest {
    const message = createBaseTuningParamsGetRequest();
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseTuningParamsGetReply(): TuningParamsGetReply {
  return { params: [] };
}

export const TuningParamsGetReply: MessageFns<TuningParamsGetReply> = {
  encode(message: TuningParamsGetReply, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.params) {
      TuningParam.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TuningParamsGetReply {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTuningParamsGetReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.params.push(TuningParam.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TuningParamsGetReply {
    return {
      params: globalThis.Array.isArray(object?.params) ? object.params.map((e: any) => TuningParam.fromJSON(e)) : [],
    };
  },

  toJSON(message: TuningParamsGetReply): unknown {
    const obj: any = {};
    if (message.params?.length) {
      obj.params = message.params.map((e) => TuningParam.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TuningParamsGetReply>, I>>(base?: I): TuningParamsGetReply {
    return TuningParamsGetReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TuningParamsGetReply>, I>>(object: I): TuningParamsGetReply {
    const message = createBaseTuningParamsGetReply();
    message.params = object.params?.map((e) => TuningParam.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetLoadEstimateRequest(): GetLoadEstimateRequest {
  return {};
}

export const GetLoadEstimateRequest: MessageFns<GetLoadEstimateRequest> = {
  encode(_: GetLoadEstimateRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetLoadEstimateRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLoadEstimateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetLoadEstimateRequest {
    return {};
  },

  toJSON(_: GetLoadEstimateRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLoadEstimateRequest>, I>>(base?: I): GetLoadEstimateRequest {
    return GetLoadEstimateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLoadEstimateRequest>, I>>(_: I): GetLoadEstimateRequest {
    const message = createBaseGetLoadEstimateRequest();
    return message;
  },
};

function createBaseGetLoadEstimateReply(): GetLoadEstimateReply {
  return { currentLoad: 0, targetLoad: 0 };
}

export const GetLoadEstimateReply: MessageFns<GetLoadEstimateReply> = {
  encode(message: GetLoadEstimateReply, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.currentLoad !== 0) {
      writer.uint32(13).float(message.currentLoad);
    }
    if (message.targetLoad !== 0) {
      writer.uint32(21).float(message.targetLoad);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetLoadEstimateReply {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLoadEstimateReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.currentLoad = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.targetLoad = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetLoadEstimateReply {
    return {
      currentLoad: isSet(object.currentLoad) ? globalThis.Number(object.currentLoad) : 0,
      targetLoad: isSet(object.targetLoad) ? globalThis.Number(object.targetLoad) : 0,
    };
  },

  toJSON(message: GetLoadEstimateReply): unknown {
    const obj: any = {};
    if (message.currentLoad !== 0) {
      obj.currentLoad = message.currentLoad;
    }
    if (message.targetLoad !== 0) {
      obj.targetLoad = message.targetLoad;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLoadEstimateReply>, I>>(base?: I): GetLoadEstimateReply {
    return GetLoadEstimateReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLoadEstimateReply>, I>>(object: I): GetLoadEstimateReply {
    const message = createBaseGetLoadEstimateReply();
    message.currentLoad = object.currentLoad ?? 0;
    message.targetLoad = object.targetLoad ?? 0;
    return message;
  },
};

function createBaseGetDiagnosticRequest(): GetDiagnosticRequest {
  return {};
}

export const GetDiagnosticRequest: MessageFns<GetDiagnosticRequest> = {
  encode(_: GetDiagnosticRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDiagnosticRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDiagnosticRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetDiagnosticRequest {
    return {};
  },

  toJSON(_: GetDiagnosticRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDiagnosticRequest>, I>>(base?: I): GetDiagnosticRequest {
    return GetDiagnosticRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDiagnosticRequest>, I>>(_: I): GetDiagnosticRequest {
    const message = createBaseGetDiagnosticRequest();
    return message;
  },
};

function createBaseGetDiagnosticReply(): GetDiagnosticReply {
  return { diagnostic: "" };
}

export const GetDiagnosticReply: MessageFns<GetDiagnosticReply> = {
  encode(message: GetDiagnosticReply, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.diagnostic !== "") {
      writer.uint32(10).string(message.diagnostic);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDiagnosticReply {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDiagnosticReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.diagnostic = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDiagnosticReply {
    return { diagnostic: isSet(object.diagnostic) ? globalThis.String(object.diagnostic) : "" };
  },

  toJSON(message: GetDiagnosticReply): unknown {
    const obj: any = {};
    if (message.diagnostic !== "") {
      obj.diagnostic = message.diagnostic;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDiagnosticReply>, I>>(base?: I): GetDiagnosticReply {
    return GetDiagnosticReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDiagnosticReply>, I>>(object: I): GetDiagnosticReply {
    const message = createBaseGetDiagnosticReply();
    message.diagnostic = object.diagnostic ?? "";
    return message;
  },
};

function createBaseResetDevicesRequest(): ResetDevicesRequest {
  return { deviceId: "" };
}

export const ResetDevicesRequest: MessageFns<ResetDevicesRequest> = {
  encode(message: ResetDevicesRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.deviceId !== "") {
      writer.uint32(10).string(message.deviceId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ResetDevicesRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResetDevicesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deviceId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ResetDevicesRequest {
    return { deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "" };
  },

  toJSON(message: ResetDevicesRequest): unknown {
    const obj: any = {};
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ResetDevicesRequest>, I>>(base?: I): ResetDevicesRequest {
    return ResetDevicesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ResetDevicesRequest>, I>>(object: I): ResetDevicesRequest {
    const message = createBaseResetDevicesRequest();
    message.deviceId = object.deviceId ?? "";
    return message;
  },
};

function createBaseResetDevicesReply(): ResetDevicesReply {
  return {};
}

export const ResetDevicesReply: MessageFns<ResetDevicesReply> = {
  encode(_: ResetDevicesReply, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ResetDevicesReply {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResetDevicesReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ResetDevicesReply {
    return {};
  },

  toJSON(_: ResetDevicesReply): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ResetDevicesReply>, I>>(base?: I): ResetDevicesReply {
    return ResetDevicesReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ResetDevicesReply>, I>>(_: I): ResetDevicesReply {
    const message = createBaseResetDevicesReply();
    return message;
  },
};

function createBaseScannerDescriptor(): ScannerDescriptor {
  return { id: 0 };
}

export const ScannerDescriptor: MessageFns<ScannerDescriptor> = {
  encode(message: ScannerDescriptor, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== 0) {
      writer.uint32(8).uint32(message.id);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ScannerDescriptor {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScannerDescriptor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScannerDescriptor {
    return { id: isSet(object.id) ? globalThis.Number(object.id) : 0 };
  },

  toJSON(message: ScannerDescriptor): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScannerDescriptor>, I>>(base?: I): ScannerDescriptor {
    return ScannerDescriptor.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ScannerDescriptor>, I>>(object: I): ScannerDescriptor {
    const message = createBaseScannerDescriptor();
    message.id = object.id ?? 0;
    return message;
  },
};

function createBaseScannerTargetPosition(): ScannerTargetPosition {
  return { scannerDescriptor: undefined, x: 0, y: 0 };
}

export const ScannerTargetPosition: MessageFns<ScannerTargetPosition> = {
  encode(message: ScannerTargetPosition, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.scannerDescriptor !== undefined) {
      ScannerDescriptor.encode(message.scannerDescriptor, writer.uint32(10).fork()).join();
    }
    if (message.x !== 0) {
      writer.uint32(16).uint32(message.x);
    }
    if (message.y !== 0) {
      writer.uint32(24).uint32(message.y);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ScannerTargetPosition {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScannerTargetPosition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scannerDescriptor = ScannerDescriptor.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.x = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.y = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScannerTargetPosition {
    return {
      scannerDescriptor: isSet(object.scannerDescriptor)
        ? ScannerDescriptor.fromJSON(object.scannerDescriptor)
        : undefined,
      x: isSet(object.x) ? globalThis.Number(object.x) : 0,
      y: isSet(object.y) ? globalThis.Number(object.y) : 0,
    };
  },

  toJSON(message: ScannerTargetPosition): unknown {
    const obj: any = {};
    if (message.scannerDescriptor !== undefined) {
      obj.scannerDescriptor = ScannerDescriptor.toJSON(message.scannerDescriptor);
    }
    if (message.x !== 0) {
      obj.x = Math.round(message.x);
    }
    if (message.y !== 0) {
      obj.y = Math.round(message.y);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScannerTargetPosition>, I>>(base?: I): ScannerTargetPosition {
    return ScannerTargetPosition.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ScannerTargetPosition>, I>>(object: I): ScannerTargetPosition {
    const message = createBaseScannerTargetPosition();
    message.scannerDescriptor = (object.scannerDescriptor !== undefined && object.scannerDescriptor !== null)
      ? ScannerDescriptor.fromPartial(object.scannerDescriptor)
      : undefined;
    message.x = object.x ?? 0;
    message.y = object.y ?? 0;
    return message;
  },
};

function createBaseCrosshairState(): CrosshairState {
  return { x: 0, y: 0 };
}

export const CrosshairState: MessageFns<CrosshairState> = {
  encode(message: CrosshairState, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.x !== 0) {
      writer.uint32(8).uint32(message.x);
    }
    if (message.y !== 0) {
      writer.uint32(16).uint32(message.y);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CrosshairState {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCrosshairState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.x = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.y = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CrosshairState {
    return {
      x: isSet(object.x) ? globalThis.Number(object.x) : 0,
      y: isSet(object.y) ? globalThis.Number(object.y) : 0,
    };
  },

  toJSON(message: CrosshairState): unknown {
    const obj: any = {};
    if (message.x !== 0) {
      obj.x = Math.round(message.x);
    }
    if (message.y !== 0) {
      obj.y = Math.round(message.y);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CrosshairState>, I>>(base?: I): CrosshairState {
    return CrosshairState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CrosshairState>, I>>(object: I): CrosshairState {
    const message = createBaseCrosshairState();
    message.x = object.x ?? 0;
    message.y = object.y ?? 0;
    return message;
  },
};

function createBaseScannerState(): ScannerState {
  return {
    scannerDescriptor: undefined,
    laserState: undefined,
    crosshairState: undefined,
    scannerError: false,
    errorCode: "",
    errorMessage: "",
  };
}

export const ScannerState: MessageFns<ScannerState> = {
  encode(message: ScannerState, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.scannerDescriptor !== undefined) {
      ScannerDescriptor.encode(message.scannerDescriptor, writer.uint32(10).fork()).join();
    }
    if (message.laserState !== undefined) {
      LaserState.encode(message.laserState, writer.uint32(18).fork()).join();
    }
    if (message.crosshairState !== undefined) {
      CrosshairState.encode(message.crosshairState, writer.uint32(26).fork()).join();
    }
    if (message.scannerError !== false) {
      writer.uint32(32).bool(message.scannerError);
    }
    if (message.errorCode !== "") {
      writer.uint32(42).string(message.errorCode);
    }
    if (message.errorMessage !== "") {
      writer.uint32(50).string(message.errorMessage);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ScannerState {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScannerState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scannerDescriptor = ScannerDescriptor.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.laserState = LaserState.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.crosshairState = CrosshairState.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.scannerError = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.errorCode = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.errorMessage = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScannerState {
    return {
      scannerDescriptor: isSet(object.scannerDescriptor)
        ? ScannerDescriptor.fromJSON(object.scannerDescriptor)
        : undefined,
      laserState: isSet(object.laserState) ? LaserState.fromJSON(object.laserState) : undefined,
      crosshairState: isSet(object.crosshairState) ? CrosshairState.fromJSON(object.crosshairState) : undefined,
      scannerError: isSet(object.scannerError) ? globalThis.Boolean(object.scannerError) : false,
      errorCode: isSet(object.errorCode) ? globalThis.String(object.errorCode) : "",
      errorMessage: isSet(object.errorMessage) ? globalThis.String(object.errorMessage) : "",
    };
  },

  toJSON(message: ScannerState): unknown {
    const obj: any = {};
    if (message.scannerDescriptor !== undefined) {
      obj.scannerDescriptor = ScannerDescriptor.toJSON(message.scannerDescriptor);
    }
    if (message.laserState !== undefined) {
      obj.laserState = LaserState.toJSON(message.laserState);
    }
    if (message.crosshairState !== undefined) {
      obj.crosshairState = CrosshairState.toJSON(message.crosshairState);
    }
    if (message.scannerError !== false) {
      obj.scannerError = message.scannerError;
    }
    if (message.errorCode !== "") {
      obj.errorCode = message.errorCode;
    }
    if (message.errorMessage !== "") {
      obj.errorMessage = message.errorMessage;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScannerState>, I>>(base?: I): ScannerState {
    return ScannerState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ScannerState>, I>>(object: I): ScannerState {
    const message = createBaseScannerState();
    message.scannerDescriptor = (object.scannerDescriptor !== undefined && object.scannerDescriptor !== null)
      ? ScannerDescriptor.fromPartial(object.scannerDescriptor)
      : undefined;
    message.laserState = (object.laserState !== undefined && object.laserState !== null)
      ? LaserState.fromPartial(object.laserState)
      : undefined;
    message.crosshairState = (object.crosshairState !== undefined && object.crosshairState !== null)
      ? CrosshairState.fromPartial(object.crosshairState)
      : undefined;
    message.scannerError = object.scannerError ?? false;
    message.errorCode = object.errorCode ?? "";
    message.errorMessage = object.errorMessage ?? "";
    return message;
  },
};

function createBaseScannerStatusReply(): ScannerStatusReply {
  return { states: [] };
}

export const ScannerStatusReply: MessageFns<ScannerStatusReply> = {
  encode(message: ScannerStatusReply, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.states) {
      ScannerState.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ScannerStatusReply {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScannerStatusReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.states.push(ScannerState.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScannerStatusReply {
    return {
      states: globalThis.Array.isArray(object?.states) ? object.states.map((e: any) => ScannerState.fromJSON(e)) : [],
    };
  },

  toJSON(message: ScannerStatusReply): unknown {
    const obj: any = {};
    if (message.states?.length) {
      obj.states = message.states.map((e) => ScannerState.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScannerStatusReply>, I>>(base?: I): ScannerStatusReply {
    return ScannerStatusReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ScannerStatusReply>, I>>(object: I): ScannerStatusReply {
    const message = createBaseScannerStatusReply();
    message.states = object.states?.map((e) => ScannerState.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBootedReply(): BootedReply {
  return { booted: false };
}

export const BootedReply: MessageFns<BootedReply> = {
  encode(message: BootedReply, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.booted !== false) {
      writer.uint32(8).bool(message.booted);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BootedReply {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBootedReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.booted = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BootedReply {
    return { booted: isSet(object.booted) ? globalThis.Boolean(object.booted) : false };
  },

  toJSON(message: BootedReply): unknown {
    const obj: any = {};
    if (message.booted !== false) {
      obj.booted = message.booted;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BootedReply>, I>>(base?: I): BootedReply {
    return BootedReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BootedReply>, I>>(object: I): BootedReply {
    const message = createBaseBootedReply();
    message.booted = object.booted ?? false;
    return message;
  },
};

function createBaseTrackerState(): TrackerState {
  return { id: 0, atWeedLimit: false };
}

export const TrackerState: MessageFns<TrackerState> = {
  encode(message: TrackerState, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== 0) {
      writer.uint32(8).uint32(message.id);
    }
    if (message.atWeedLimit !== false) {
      writer.uint32(16).bool(message.atWeedLimit);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TrackerState {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrackerState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.atWeedLimit = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TrackerState {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      atWeedLimit: isSet(object.atWeedLimit) ? globalThis.Boolean(object.atWeedLimit) : false,
    };
  },

  toJSON(message: TrackerState): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.atWeedLimit !== false) {
      obj.atWeedLimit = message.atWeedLimit;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TrackerState>, I>>(base?: I): TrackerState {
    return TrackerState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TrackerState>, I>>(object: I): TrackerState {
    const message = createBaseTrackerState();
    message.id = object.id ?? 0;
    message.atWeedLimit = object.atWeedLimit ?? false;
    return message;
  },
};

function createBaseSchedulerState(): SchedulerState {
  return { overCapacity: false };
}

export const SchedulerState: MessageFns<SchedulerState> = {
  encode(message: SchedulerState, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.overCapacity !== false) {
      writer.uint32(8).bool(message.overCapacity);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SchedulerState {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSchedulerState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.overCapacity = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SchedulerState {
    return { overCapacity: isSet(object.overCapacity) ? globalThis.Boolean(object.overCapacity) : false };
  },

  toJSON(message: SchedulerState): unknown {
    const obj: any = {};
    if (message.overCapacity !== false) {
      obj.overCapacity = message.overCapacity;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SchedulerState>, I>>(base?: I): SchedulerState {
    return SchedulerState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SchedulerState>, I>>(object: I): SchedulerState {
    const message = createBaseSchedulerState();
    message.overCapacity = object.overCapacity ?? false;
    return message;
  },
};

function createBaseTrackingState(): TrackingState {
  return { states: [], schedulerState: undefined };
}

export const TrackingState: MessageFns<TrackingState> = {
  encode(message: TrackingState, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.states) {
      TrackerState.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.schedulerState !== undefined) {
      SchedulerState.encode(message.schedulerState, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TrackingState {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrackingState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.states.push(TrackerState.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.schedulerState = SchedulerState.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TrackingState {
    return {
      states: globalThis.Array.isArray(object?.states) ? object.states.map((e: any) => TrackerState.fromJSON(e)) : [],
      schedulerState: isSet(object.schedulerState) ? SchedulerState.fromJSON(object.schedulerState) : undefined,
    };
  },

  toJSON(message: TrackingState): unknown {
    const obj: any = {};
    if (message.states?.length) {
      obj.states = message.states.map((e) => TrackerState.toJSON(e));
    }
    if (message.schedulerState !== undefined) {
      obj.schedulerState = SchedulerState.toJSON(message.schedulerState);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TrackingState>, I>>(base?: I): TrackingState {
    return TrackingState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TrackingState>, I>>(object: I): TrackingState {
    const message = createBaseTrackingState();
    message.states = object.states?.map((e) => TrackerState.fromPartial(e)) || [];
    message.schedulerState = (object.schedulerState !== undefined && object.schedulerState !== null)
      ? SchedulerState.fromPartial(object.schedulerState)
      : undefined;
    return message;
  },
};

function createBaseBedtopHeightProfile(): BedtopHeightProfile {
  return { weedHeightColumns: [], cropHeightColumns: [], pcamId: "" };
}

export const BedtopHeightProfile: MessageFns<BedtopHeightProfile> = {
  encode(message: BedtopHeightProfile, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    writer.uint32(10).fork();
    for (const v of message.weedHeightColumns) {
      writer.double(v);
    }
    writer.join();
    writer.uint32(18).fork();
    for (const v of message.cropHeightColumns) {
      writer.double(v);
    }
    writer.join();
    if (message.pcamId !== "") {
      writer.uint32(26).string(message.pcamId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BedtopHeightProfile {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBedtopHeightProfile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 9) {
            message.weedHeightColumns.push(reader.double());

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.weedHeightColumns.push(reader.double());
            }

            continue;
          }

          break;
        case 2:
          if (tag === 17) {
            message.cropHeightColumns.push(reader.double());

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.cropHeightColumns.push(reader.double());
            }

            continue;
          }

          break;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pcamId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BedtopHeightProfile {
    return {
      weedHeightColumns: globalThis.Array.isArray(object?.weedHeightColumns)
        ? object.weedHeightColumns.map((e: any) => globalThis.Number(e))
        : [],
      cropHeightColumns: globalThis.Array.isArray(object?.cropHeightColumns)
        ? object.cropHeightColumns.map((e: any) => globalThis.Number(e))
        : [],
      pcamId: isSet(object.pcamId) ? globalThis.String(object.pcamId) : "",
    };
  },

  toJSON(message: BedtopHeightProfile): unknown {
    const obj: any = {};
    if (message.weedHeightColumns?.length) {
      obj.weedHeightColumns = message.weedHeightColumns;
    }
    if (message.cropHeightColumns?.length) {
      obj.cropHeightColumns = message.cropHeightColumns;
    }
    if (message.pcamId !== "") {
      obj.pcamId = message.pcamId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BedtopHeightProfile>, I>>(base?: I): BedtopHeightProfile {
    return BedtopHeightProfile.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BedtopHeightProfile>, I>>(object: I): BedtopHeightProfile {
    const message = createBaseBedtopHeightProfile();
    message.weedHeightColumns = object.weedHeightColumns?.map((e) => e) || [];
    message.cropHeightColumns = object.cropHeightColumns?.map((e) => e) || [];
    message.pcamId = object.pcamId ?? "";
    return message;
  },
};

function createBaseTrackerBedtopHeightProfile(): TrackerBedtopHeightProfile {
  return { profiles: [] };
}

export const TrackerBedtopHeightProfile: MessageFns<TrackerBedtopHeightProfile> = {
  encode(message: TrackerBedtopHeightProfile, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.profiles) {
      BedtopHeightProfile.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TrackerBedtopHeightProfile {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrackerBedtopHeightProfile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.profiles.push(BedtopHeightProfile.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TrackerBedtopHeightProfile {
    return {
      profiles: globalThis.Array.isArray(object?.profiles)
        ? object.profiles.map((e: any) => BedtopHeightProfile.fromJSON(e))
        : [],
    };
  },

  toJSON(message: TrackerBedtopHeightProfile): unknown {
    const obj: any = {};
    if (message.profiles?.length) {
      obj.profiles = message.profiles.map((e) => BedtopHeightProfile.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TrackerBedtopHeightProfile>, I>>(base?: I): TrackerBedtopHeightProfile {
    return TrackerBedtopHeightProfile.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TrackerBedtopHeightProfile>, I>>(object: I): TrackerBedtopHeightProfile {
    const message = createBaseTrackerBedtopHeightProfile();
    message.profiles = object.profiles?.map((e) => BedtopHeightProfile.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetDimensionsResponse(): GetDimensionsResponse {
  return { minXMm: 0, maxXMm: 0, minYMm: 0, maxYMm: 0 };
}

export const GetDimensionsResponse: MessageFns<GetDimensionsResponse> = {
  encode(message: GetDimensionsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.minXMm !== 0) {
      writer.uint32(9).double(message.minXMm);
    }
    if (message.maxXMm !== 0) {
      writer.uint32(17).double(message.maxXMm);
    }
    if (message.minYMm !== 0) {
      writer.uint32(25).double(message.minYMm);
    }
    if (message.maxYMm !== 0) {
      writer.uint32(33).double(message.maxYMm);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDimensionsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDimensionsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.minXMm = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.maxXMm = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.minYMm = reader.double();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.maxYMm = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDimensionsResponse {
    return {
      minXMm: isSet(object.minXMm) ? globalThis.Number(object.minXMm) : 0,
      maxXMm: isSet(object.maxXMm) ? globalThis.Number(object.maxXMm) : 0,
      minYMm: isSet(object.minYMm) ? globalThis.Number(object.minYMm) : 0,
      maxYMm: isSet(object.maxYMm) ? globalThis.Number(object.maxYMm) : 0,
    };
  },

  toJSON(message: GetDimensionsResponse): unknown {
    const obj: any = {};
    if (message.minXMm !== 0) {
      obj.minXMm = message.minXMm;
    }
    if (message.maxXMm !== 0) {
      obj.maxXMm = message.maxXMm;
    }
    if (message.minYMm !== 0) {
      obj.minYMm = message.minYMm;
    }
    if (message.maxYMm !== 0) {
      obj.maxYMm = message.maxYMm;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDimensionsResponse>, I>>(base?: I): GetDimensionsResponse {
    return GetDimensionsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDimensionsResponse>, I>>(object: I): GetDimensionsResponse {
    const message = createBaseGetDimensionsResponse();
    message.minXMm = object.minXMm ?? 0;
    message.maxXMm = object.maxXMm ?? 0;
    message.minYMm = object.minYMm ?? 0;
    message.maxYMm = object.maxYMm ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
