import {
  BLUE_LOADING_BUTTON,
  INPUT_DARK,
  LABEL,
  SELECT_DARK,
  TEXT_FIELD_DARK,
} from "portal/utils/theme";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { capitalize } from "portal/utils/strings";
import { Field, Form, Formik } from "formik";
import { getReportInstancePath, Slug } from "portal/utils/routing";
import {
  isNewInstance,
  ReportInstanceProperty,
  secondsToRange,
  toNewInstance,
} from "portal/utils/reports";
import { LoadingButton } from "@mui/lab";
import { object, string } from "yup";
import { ReportInstanceResponse, ReportResponse } from "protos/portal/reports";
import { ReportTools } from "portal/components/reports/ReportTools";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { TextField } from "formik-mui";
import { TFunction } from "i18next";
import {
  useCreateReportInstanceMutation,
  useGetCustomerQuery,
  useUpdateReportInstanceMutation,
} from "portal/state/portalApi";
import {
  useMutationPopups,
  useQueryPopups,
} from "portal/utils/hooks/useApiPopups";
import { useNavigate } from "react-router-dom";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import React, { FunctionComponent, useEffect, useState } from "react";
import SendIcon from "@mui/icons-material/SendOutlined";

interface RunReportProps {
  instance?: ReportInstanceResponse;
  onCancel: () => void;
  saveAs?: boolean;
  open?: boolean;
  report?: ReportResponse;
}

export const RunReportDialog: FunctionComponent<RunReportProps> = ({
  instance: inputInstance,
  onCancel,
  saveAs = false,
  open = false,
  report,
}) => {
  const { user } = useSelf();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [createInstance, { isLoading: isCreateLoading }] = useMutationPopups(
    useCreateReportInstanceMutation(),
    {
      success: capitalize(
        t("utils.actions.ranLong", { subject: t("models.reports.report_one") })
      ),
    }
  );

  const [updateInstance, { isLoading: isUpdateLoading }] = useMutationPopups(
    useUpdateReportInstanceMutation(),
    {
      success: capitalize(
        t("utils.actions.saved", {
          subject: t("models.reportInstances.run_one"),
        })
      ),
    }
  );

  const isLoading = isCreateLoading || isUpdateLoading;

  const [instance, setInstance] = useState<ReportInstanceResponse>();
  useEffect(() => {
    if (!user) {
      return;
    }
    if (inputInstance) {
      setInstance({ ...inputInstance });
    } else if (report) {
      setInstance(toNewInstance(user, report));
    }
  }, [report, inputInstance, user]);

  // use default emails
  const { data: customer } = useQueryPopups(
    useGetCustomerQuery(report?.customerId ?? skipToken)
  );
  useEffect(() => {
    if (
      instance &&
      instance.publishEmails.length === 0 &&
      customer?.emails.length
    ) {
      instance.publishEmails = customer.emails;
    }
  }, [customer?.emails, instance, instance?.publishEmails]);

  const setInstanceProperty = (
    key: ReportInstanceProperty,
    value: unknown
  ): void => {
    setInstance((instance) => {
      let newInstance = instance;
      if (instance) {
        newInstance = {
          ...instance,
          [key]: value,
        };
      }
      return newInstance;
    });
  };

  return (
    <>
      <Dialog open={open} onClose={isLoading ? undefined : onCancel}>
        <DialogTitle>{getTitle(t, { report, instance, saveAs })}</DialogTitle>
        <Formik
          enableReinitialize
          initialValues={{
            name: instance?.name ?? "",
            publishEmails: instance?.publishEmails.join("\n") ?? "",
          }}
          validationSchema={object({
            name: string(),
            publishEmails: string(),
            textNotes: string(),
            textDowntime: string(),
            textParts: string(),
            textEvents: string(),
            textUpcoming: string(),
          })}
          onSubmit={async ({ publishEmails, ...values }) => {
            if (report && instance) {
              const instanceData: ReportInstanceResponse = {
                ...instance,
                publishEmails: publishEmails
                  .split(/[\n,]/g)
                  .map((email) => email.trim()),
                ...values,
              };
              let newInstance: ReportInstanceResponse;
              if (saveAs || isNewInstance(instance)) {
                instance.slug = Slug.NEW;
                newInstance = await createInstance([
                  report.slug,
                  ReportInstanceResponse.fromPartial(instanceData),
                ]).unwrap();
                navigate(getReportInstancePath(report.slug, newInstance.slug));
                onCancel();
              } else {
                newInstance = await updateInstance([
                  report.slug,
                  ReportInstanceResponse.fromPartial(instanceData),
                ]).unwrap();
                onCancel();
              }
            }
          }}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <DialogContent
                dividers
                className="flex flex-col gap-4 items-stretch"
              >
                <Field
                  {...TEXT_FIELD_DARK}
                  component={TextField}
                  label={t("models.reports.fields.name")}
                  name="name"
                />
                <Field
                  {...TEXT_FIELD_DARK}
                  component={TextField}
                  multiline
                  disabled={!saveAs && !isNewInstance(instance)}
                  label={t("models.customers.fields.emails.name")}
                  name="publishEmails"
                  helperText={
                    !saveAs && !isNewInstance(instance)
                      ? t(
                          "views.reports.scheduled.runDialog.fields.publishEmailsHelperNew"
                        )
                      : t(
                          "views.reports.scheduled.runDialog.fields.publishEmailsHelperExisting"
                        )
                  }
                />
                <ReportTools
                  selectedRobots={instance?.robotIds ?? report?.robotIds}
                  direction="vertical"
                  dateRange={
                    instance
                      ? secondsToRange(instance.startDate, instance.endDate)
                      : secondsToRange(report?.startDate, report?.endDate)
                  }
                  onDateRangeChange={([startDate, endDate]) => {
                    if (!startDate || !endDate) {
                      return;
                    }
                    setInstanceProperty("startDate", startDate.toUnixInteger());
                    setInstanceProperty("endDate", endDate.toUnixInteger());
                  }}
                  onSelectedRobotsChange={(selectedRobots) => {
                    setInstanceProperty("robotIds", selectedRobots);
                  }}
                  themeProps={{
                    field: TEXT_FIELD_DARK,
                    label: LABEL,
                    select: SELECT_DARK,
                    input: INPUT_DARK,
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  variant="text"
                  className="text-white"
                  disabled={isLoading}
                  onClick={onCancel}
                >
                  {t("utils.actions.cancel")}
                </Button>
                <LoadingButton
                  {...BLUE_LOADING_BUTTON}
                  loading={isSubmitting}
                  disabled={!report || !instance}
                  onClick={submitForm}
                  startIcon={<SendIcon />}
                >
                  {getAction(t, { instance, saveAs })}
                </LoadingButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

const getTitle = (
  t: TFunction,
  {
    report,
    instance,
    saveAs,
  }: Pick<RunReportProps, "report" | "saveAs"> & {
    instance?: ReportInstanceResponse;
  }
): string => {
  let i18nKey = "utils.actions.runLong";
  if (isNewInstance(instance)) {
    i18nKey = "utils.actions.runLong";
  } else if (saveAs) {
    i18nKey = "utils.actions.runLong";
  } else if (!isNewInstance(instance)) {
    i18nKey = "utils.actions.editLong";
  }
  return t(i18nKey, {
    subject: instance?.name ?? report?.name ?? t("models.reports.report_one"),
  });
};

const getAction = (
  t: TFunction,
  {
    instance,
    saveAs,
  }: Pick<RunReportProps, "saveAs"> & {
    instance?: ReportInstanceResponse;
  }
): string => {
  if (isNewInstance(instance)) {
    return t("utils.actions.run");
  }
  if (saveAs) {
    return t("utils.actions.run");
  }
  if (!isNewInstance(instance)) {
    return t("utils.actions.save");
  }
  return t("utils.actions.run");
};
