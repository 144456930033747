import { AlarmResponse } from "protos/portal/alarms";
import { capitalize } from "@mui/material";
import { checkNever } from "portal/utils/identity";
import { ConfigNode } from "protos/config/api/config_service";
import { DateTime } from "luxon";
import { formatDuration, titleCase } from "./strings";
import { getNodeFromPath, getValue, TemplateSerial } from "./configs";
import { i18n as I18n, TFunction } from "i18next";
import { isObject } from "./objects";
import { isOngoing } from "./alarms";
import { range } from "./arrays";
import { RobotResponse, RobotSummaryResponse } from "protos/portal/robots";
import { Status, statusFromJSON } from "protos/frontend/status_bar";
import { STATUS_TEXT } from "portal/utils/theme";

export const COLOR_GRAY = "text-gray-500";
export const COLOR_PURPLE = "text-purple-500";

export enum RobotClass {
  Buds = "buds",
  Reapers = "reapers",
  Rtcs = "rtcs",
  Simulators = "simulators",
  Slayers = "slayers",
  Unknown = "unknown",
}

export const toLocalizedRobotClass = (
  t: TFunction,
  isInternal: boolean,
  robotClass: RobotClass
): string => {
  switch (robotClass) {
    case RobotClass.Buds: {
      return t("models.robots.classes.buds_one");
    }
    case RobotClass.Slayers: {
      return isInternal
        ? t("models.robots.classes.slayersCarbon_one")
        : t("models.robots.classes.slayersCustomer_one");
    }
    case RobotClass.Rtcs: {
      return t("models.robots.classes.rtcs_one");
    }
    case RobotClass.Reapers: {
      return isInternal
        ? t("models.robots.classes.reapersCarbon_one")
        : t("models.robots.classes.reapersCustomer_one");
    }
    case RobotClass.Simulators: {
      return t("models.robots.classes.simulators_one");
    }
    case RobotClass.Unknown: {
      return t("models.robots.classes.unknown_one");
    }
    default: {
      checkNever(robotClass);
      return t("models.robots.classes.unknown_one");
    }
  }
};

export const CUSTOMER_FACING_CLASSES = [RobotClass.Slayers, RobotClass.Reapers];

export enum ImplementationStatus {
  PRE_MANUFACTURING = "pre_manufacturing",
  MANUFACTURING = "manufacturing",
  INVENTORY = "inventory",
  DELIVERY = "delivery",
  IMPLEMENTATION = "implementation",
  ACTIVE = "active",
  INACTIVE = "inactive",
  OFF_SEASON = "off_season",
  WINTERIZED = "winterized",
}

export const toImplementationStatus = (
  status?: string
): ImplementationStatus => {
  switch (status) {
    case ImplementationStatus.PRE_MANUFACTURING:
    case ImplementationStatus.MANUFACTURING:
    case ImplementationStatus.INVENTORY:
    case ImplementationStatus.DELIVERY:
    case ImplementationStatus.IMPLEMENTATION:
    case ImplementationStatus.ACTIVE:
    case ImplementationStatus.INACTIVE:
    case ImplementationStatus.OFF_SEASON:
    case ImplementationStatus.WINTERIZED: {
      return status;
    }
    default: {
      return ImplementationStatus.ACTIVE;
    }
  }
};

export const formatImplementationStatus = (
  t: TFunction,
  status: ImplementationStatus | string | undefined
): string =>
  // carbon.actions.compareKeys.ignoreDynamic
  t(
    `components.robots.RobotSummary.${
      status ? toImplementationStatus(status) : "unknown"
    }`
  );

export const DEFAULT_IMPLEMENT_WIDTH_FT = 20;

export const getTemplateSerial = (robotClass: RobotClass): TemplateSerial => {
  switch (robotClass) {
    case RobotClass.Buds: {
      return TemplateSerial.BUD;
    }
    case RobotClass.Simulators: {
      return TemplateSerial.SIMULATOR;
    }
    case RobotClass.Slayers: {
      return TemplateSerial.SLAYER;
    }
    case RobotClass.Reapers: {
      return TemplateSerial.REAPER;
    }
    case RobotClass.Rtcs: {
      return TemplateSerial.RTC;
    }
    case RobotClass.Unknown: {
      return TemplateSerial.SLAYER;
    }
    default: {
      checkNever(robotClass);
      return TemplateSerial.SLAYER;
    }
  }
};

export const formatRobotClass = (
  t: TFunction,
  isInternal: boolean,
  robotClass: RobotClass
): string => {
  const nameByClass: Record<RobotClass, string> = {
    [RobotClass.Buds]: t("models.robots.classes.buds_other"),
    [RobotClass.Slayers]: isInternal
      ? t("models.robots.classes.slayersCarbon_other")
      : t("models.robots.classes.slayersCustomer_other"),
    [RobotClass.Reapers]: isInternal
      ? t("models.robots.classes.reapersCarbon_other")
      : t("models.robots.classes.reapersCustomer_other"),
    [RobotClass.Rtcs]: t("models.robots.classes.rtcs_other"),
    [RobotClass.Simulators]: t("models.robots.classes.simulators_other"),
    [RobotClass.Unknown]: t("models.robots.classes.unknown"),
  };
  return nameByClass[robotClass];
};

export interface HistoryDay {
  year: number;
  month: number;
  day: number;
}

export const getClass = (robot?: RobotResponse | string): RobotClass => {
  const serial = typeof robot === "string" ? robot : robot?.serial ?? "";
  if (serial.startsWith("bud") || serial === TemplateSerial.BUD) {
    return RobotClass.Buds;
  } else if (
    serial.startsWith("slayer") ||
    serial.startsWith("CR-LW-") ||
    serial === TemplateSerial.SLAYER
  ) {
    return RobotClass.Slayers;
  } else if (serial.startsWith("reaper") || serial === TemplateSerial.REAPER) {
    return RobotClass.Reapers;
  } else if (
    serial.startsWith("dev") ||
    serial.endsWith("dev") ||
    serial.includes("diagnostic") ||
    serial === TemplateSerial.SIMULATOR
  ) {
    return RobotClass.Simulators;
  } else {
    return RobotClass.Simulators;
  }
};

export const isBud = (robot?: RobotResponse | string): boolean =>
  getClass(robot) === RobotClass.Buds;

export const isSlayer = (robot?: RobotResponse | string): boolean =>
  getClass(robot) === RobotClass.Slayers;

export const isReaper = (robot?: RobotResponse | string): boolean =>
  getClass(robot) === RobotClass.Reapers;

export const isSimulator = (robot?: RobotResponse | string): boolean =>
  getClass(robot) === RobotClass.Simulators;

export const isOffline = (
  input?: RobotResponse | number | undefined
): boolean => {
  const reportedAt = isObject(input)
    ? input.health?.reportedAt ?? 0
    : input ?? 0;
  return reportedAt < DateTime.utc().minus({ minutes: 30 }).toUnixInteger();
};

export const getColorClass = (
  summary: RobotSummaryResponse,
  successColor: string = "text-white"
): string => {
  const status = summary.robot?.health?.status;
  if (isOffline(summary.robot)) {
    return COLOR_GRAY;
  }
  if (status) {
    switch (status) {
      // case statusFromJSON(Status.STATUS_ERROR):
      case statusFromJSON(Status.STATUS_DISCONNECTED):
      case statusFromJSON(Status.STATUS_ESTOPPED):
      case statusFromJSON(Status.STATUS_LIFTED):
      case statusFromJSON(Status.STATUS_POWERED_DOWN): {
        return STATUS_TEXT.RED;
      }
      case statusFromJSON(Status.STATUS_MODEL_INSTALLING):
      case statusFromJSON(Status.STATUS_MODEL_LOADING):
      case statusFromJSON(Status.STATUS_UPDATE_INSTALLING):
      case statusFromJSON(Status.STATUS_POWERING_UP):
      case statusFromJSON(Status.STATUS_PRE_ARMED): {
        return STATUS_TEXT.YELLOW;
      }
      case statusFromJSON(Status.STATUS_STANDBY):
      case statusFromJSON(Status.STATUS_WEEDING): {
        return STATUS_TEXT.GREEN;
      }
      default: {
        return COLOR_GRAY;
      }
    }
  } else if (hasAlarms(summary)) {
    return STATUS_TEXT.YELLOW;
  } else {
    return successColor;
  }
};

export const formatStatus = (
  t: TFunction,
  i18n: I18n,
  robot?: RobotResponse
): string => {
  if (!robot) {
    return t("components.robots.RobotSummary.unknown");
  } else if (isOffline(robot)) {
    const checkin = robot.health?.reportedAt
      ? DateTime.fromSeconds(robot.health.reportedAt, {
          zone: "utc",
        }).setZone(robot.health.timezone)
      : undefined;
    return t("components.robots.RobotSummary.offline", {
      duration: DateTime.isDateTime(checkin)
        ? formatDuration(checkin.diffNow().toMillis(), i18n)
        : t("components.robots.RobotSummary.unknown"),
    });
    // this is much less confusing as-is than as a nested switch
    // eslint-disable-next-line unicorn/prefer-switch
  } else if (
    robot.health?.status ===
    statusFromJSON(Status.STATUS_ALARM_AUTOFIX_IN_PROGRESS)
  ) {
    return t("components.robots.RobotSummary.autofixing");
  } else if (robot.health?.status === statusFromJSON(Status.STATUS_ERROR)) {
    return capitalize(t("utils.descriptors.error"));
  } else if (robot.health?.status === statusFromJSON(Status.STATUS_ESTOPPED)) {
    return t("utils.descriptors.estopOn");
  } else if (robot.health?.status === statusFromJSON(Status.STATUS_LIFTED)) {
    return t("components.robots.RobotSummary.lifted");
  } else if (
    robot.health?.status === statusFromJSON(Status.STATUS_POWERED_DOWN)
  ) {
    return t("components.robots.RobotSummary.poweringDown");
  } else if (
    robot.health?.status === statusFromJSON(Status.STATUS_POWERING_UP)
  ) {
    return t("components.robots.RobotSummary.poweringUp");
  } else if (
    robot.health?.status === statusFromJSON(Status.STATUS_UPDATE_INSTALLING)
  ) {
    return t("components.robots.RobotSummary.updating");
  } else if (
    robot.health?.status === statusFromJSON(Status.STATUS_MODEL_LOADING) ||
    robot.health?.status === statusFromJSON(Status.STATUS_MODEL_INSTALLING)
  ) {
    return t("components.robots.RobotSummary.modelLoading");
  } else if (robot.health?.status === statusFromJSON(Status.STATUS_PRE_ARMED)) {
    return t("components.robots.RobotSummary.notArmed");
  } else if (
    robot.health?.fieldConfig?.isWeeding &&
    robot.health.fieldConfig.isThinning
  ) {
    return t("components.robots.RobotSummary.weedingThinning", {
      crop: titleCase(robot.health.crop),
    });
  } else if (robot.health?.fieldConfig?.isThinning) {
    return t("components.robots.RobotSummary.thinning.withName", {
      name: titleCase(robot.health.crop),
    });
  } else if (
    robot.health?.fieldConfig?.isWeeding ||
    robot.health?.status === statusFromJSON(Status.STATUS_WEEDING)
  ) {
    return t("components.robots.RobotSummary.weeding", {
      crop: titleCase(robot.health.crop),
    });
  } else {
    switch (robot.health?.status) {
      case statusFromJSON(Status.STATUS_STANDBY): {
        return t("components.robots.RobotSummary.standby");
      }
      case statusFromJSON(Status.STATUS_DISCONNECTED): {
        return t("components.robots.RobotSummary.disconnected");
      }
      case statusFromJSON(Status.STATUS_LOADING): {
        return t("components.robots.RobotSummary.loading");
      }
      default: {
        return t("components.robots.RobotSummary.unknown");
      }
    }
  }
};

export const hasAlarms = (summary?: RobotSummaryResponse): boolean =>
  (summary?.robot?.health?.activeAlarmCount ??
    getActiveAlarms(summary).length) > 0;

export const getActiveAlarms = (
  robot?: RobotSummaryResponse
): AlarmResponse[] =>
  robot?.alarmList.filter((alarm) => isOngoing(alarm)) ?? [];

export const getCustomerSerial = (t: TFunction, serial?: string): string => {
  if (!serial) {
    return t("components.Loading.placeholder");
  } else if (getClass(serial) === RobotClass.Slayers) {
    const sequenceNumber = getSequence(serial);
    return `LW-${String(sequenceNumber).padStart(3, "0")}`;
  } else {
    return serial;
  }
};

export const getSequence = (serial?: string): number => {
  if (!serial) {
    return -1;
  } else if (serial.includes("tb")) {
    return -1;
  } else if (getClass(serial) === RobotClass.Slayers) {
    return Number(serial.replaceAll(/slayer|CR-LW-|-D/g, ""));
  } else if (getClass(serial) === RobotClass.Buds) {
    return Number(serial.replace("bud", ""));
  } else {
    return -1;
  }
};

const isRobotSummaryResponse = (
  robot: RobotResponse | RobotSummaryResponse
): robot is RobotSummaryResponse => !("serial" in robot);

export const robotSort = (
  a?: RobotResponse | RobotSummaryResponse,
  b?: RobotResponse | RobotSummaryResponse
): number => {
  if (!a && !b) {
    return 0;
  }
  if (!a) {
    return 1;
  }
  if (!b) {
    return -1;
  }
  const aRobot = isRobotSummaryResponse(a) ? a.robot : a;
  const bRobot = isRobotSummaryResponse(b) ? b.robot : b;
  const aSerial = aRobot?.serial ?? "";
  const bSerial = bRobot?.serial ?? "";
  const aClass = getClass(aRobot);
  const bClass = getClass(bRobot);
  if (aClass !== bClass) {
    return aClass === RobotClass.Slayers ? -1 : 1;
  }
  return aSerial.localeCompare(bSerial, undefined, {
    numeric: true,
    sensitivity: "base",
  });
};

export const getDisabledLasers = (
  serial: string | undefined,
  config?: ConfigNode | undefined
): string[] => {
  const disabledLasers: string[] = [];
  if (!serial || !config) {
    return disabledLasers;
  }
  if (isBud(serial)) {
    for (const laser of range(8, true)) {
      if (
        !getValue<boolean>(
          getNodeFromPath(config, `bud/aimbot/scanners/scanner${laser}/enabled`)
        )
      ) {
        disabledLasers.push(`Laser ${laser}`);
      }
    }
  } else if (isSlayer(serial) || isSimulator(serial)) {
    for (const row of range(3, true)) {
      for (const laser of range(10, true)) {
        if (
          !getValue<boolean>(
            getNodeFromPath(
              config,
              `row${row}/aimbot/scanners/scanner${laser}/enabled`
            )
          )
        ) {
          disabledLasers.push(`Row ${row} Laser ${laser}`);
        }
      }
    }
  }
  return disabledLasers;
};

export const generateAliases = (
  t: TFunction,
  robot?: RobotResponse
): string[] => {
  const aliases: string[] = [];

  if (!robot) {
    return aliases;
  }

  const { serial } = robot;
  if (!serial) {
    return aliases;
  }

  const matches = serial.match(/(\d+)$/);
  if (!matches) {
    return aliases;
  }

  aliases.push(getCustomerSerial(t, serial));

  const sequence = matches[1];
  let prefix = "";
  switch (getClass(serial)) {
    case RobotClass.Buds: {
      prefix = "b";
      break;
    }
    case RobotClass.Slayers: {
      prefix = "s";
      break;
    }
    case RobotClass.Reapers: {
      prefix = "r";
      break;
    }
    case RobotClass.Simulators: {
      prefix = "d";
      break;
    }
    default: {
      prefix = "";
      break;
    }
  }
  aliases.push(`${prefix}${sequence}`);
  return aliases;
};
