import * as Sentry from "@sentry/browser";
import {
  BLUE_LOADING_BUTTON,
  SMALL_TEXT_FIELD_DARK,
  WHITE_BUTTON,
} from "portal/utils/theme";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { convert } from "portal/utils/units/units";
import { LoadingButton } from "@mui/lab";
import { sleep } from "portal/utils/timing";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import FeedbackIcon from "@mui/icons-material/FeedbackOutlined";
import React, {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";

interface Feedback {
  comments: string;
  email: string;
  isLoading: boolean;
  sendFeedback: () => void;
  setComments: (comments: string) => void;
  setEmail: (email: string) => void;
}

const FeedbackContext = createContext<Feedback>({
  comments: "",
  email: "",
  isLoading: false,
  sendFeedback: () => console.warn("sendFeedback not initialized"),
  setComments: () => console.warn("setComments not initialized"),
  setEmail: () => console.warn("setEmail not initialized"),
});

export const FeedbackContextProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { user } = useSelf();

  const [comments, setComments] = useState<string>("");
  const [email, setEmail] = useState<string>(user?.email ?? "");
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (email === "" && user?.email) {
      setEmail(user.email);
    }
  }, [email, user?.email]);

  const sendFeedback = async (): Promise<void> => {
    setLoading(true);
    const sentryUser = user
      ? {
          email: email || user.email,
          name: user.name,
        }
      : undefined;
    Sentry.captureMessage("Feedback form submitted", {
      user: sentryUser,
    });
    Sentry.captureUserFeedback({
      event_id: Sentry.lastEventId() ?? "",
      name: user?.name ?? (email || ""),
      email: email || user?.email || "",
      comments: comments || "",
    });
    await sleep(convert(3).from("s").to("ms"));
    setLoading(false);
  };

  return (
    <FeedbackContext.Provider
      value={{
        comments,
        email,
        isLoading,
        sendFeedback,
        setComments,
        setEmail,
      }}
    >
      {children}
    </FeedbackContext.Provider>
  );
};

interface FeedbackFormProps {
  commentText?: string;
  disableButtons?: boolean;
  required?: boolean;
}

export const FeedbackForm: FunctionComponent<FeedbackFormProps> = ({
  commentText,
  disableButtons = false,
  required = false,
}) => {
  const { t } = useTranslation();
  const { email, comments, sendFeedback, setEmail, setComments, isLoading } =
    useContext(FeedbackContext);
  return (
    <div className="flex flex-col gap-4">
      <TextField
        {...SMALL_TEXT_FIELD_DARK}
        // the whole dialog opens as a response to user action,
        // so it's fine to autofocus the email field
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        className="w-full"
        label="Email"
        disabled={isLoading}
      />
      <TextField
        {...SMALL_TEXT_FIELD_DARK}
        multiline
        minRows={3}
        value={comments}
        onChange={(event) => setComments(event.target.value)}
        className="w-full"
        label={`${commentText ?? t("components.FeedbackDialog.comment")}${
          required ? "*" : ` ${t("utils.form.optional")}`
        }`}
        disabled={isLoading}
      />
      {!disableButtons && (
        <LoadingButton
          {...BLUE_LOADING_BUTTON}
          loading={isLoading}
          onClick={async () => {
            await sendFeedback();
            window.location.reload();
          }}
          startIcon={<FeedbackIcon />}
        >
          {t("components.FeedbackDialog.submit")}
        </LoadingButton>
      )}
    </div>
  );
};

interface FeedbackDialogProps {
  commentText?: string;
  onSuccess?: () => void;
  open: boolean;
  required?: boolean;
  setOpen: (open: boolean) => void;
  yesText?: string;
}
export const FeedbackDialog: FunctionComponent<FeedbackDialogProps> = ({
  commentText,
  onSuccess,
  open,
  required = false,
  setOpen,
  yesText,
}) => {
  const { t } = useTranslation();
  const { sendFeedback, isLoading, comments } = useContext(FeedbackContext);

  return (
    <Dialog open={open}>
      <DialogTitle>{t("components.FeedbackDialog.feedback")}</DialogTitle>
      <DialogContent className="overflow-visible">
        <FeedbackForm
          disableButtons
          required={required}
          commentText={commentText}
        />
      </DialogContent>
      <DialogActions>
        <Button {...WHITE_BUTTON} onClick={() => setOpen(false)}>
          {t("utils.actions.cancel")}
        </Button>
        <LoadingButton
          {...BLUE_LOADING_BUTTON}
          loading={isLoading}
          disabled={required === !comments}
          onClick={async () => {
            await sendFeedback();
            onSuccess?.();
          }}
          startIcon={<FeedbackIcon />}
        >
          {yesText ?? t("utils.actions.submit")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
