import { Duration } from "luxon";
import { isUndefined } from "./identity";
import { Message } from "protos/portal/messages";

export const isSameAuthor = (a?: Message, b?: Message): boolean =>
  !isUndefined(a) &&
  !isUndefined(b) &&
  a.authorRobotId === b.authorRobotId &&
  a.authorUserId === b.authorUserId;

export const isSoonAfter = (a?: Message, b?: Message): boolean =>
  !isUndefined(a) &&
  !isUndefined(b) &&
  Math.abs(
    (a.db?.createdAt || Number.POSITIVE_INFINITY) -
      (b.db?.createdAt || Number.NEGATIVE_INFINITY)
  ) < Duration.fromObject({ hours: 1 }).toMillis();
