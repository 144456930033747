import {
  dismissNotification,
  notificationExited,
  type Key as NotificationKey,
} from "portal/state/notifications";
import { IconButton, Snackbar } from "@mui/material";
import {
  SnackbarProvider,
  useSnackbar as useNotistackSnackbar,
} from "notistack";
import { useDispatch } from "react-redux";
import { useNotifications, useSelf } from "portal/state/store";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useRef,
} from "react";

export const CarbonNotifications: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const { isInternal } = useSelf();

  return (
    <SnackbarProvider
      action={(snackbarKey: string | number) => (
        <IconButton
          onClick={() => {
            // We only ever use strings (nanoids) for our keys.
            const key: NotificationKey = snackbarKey as string;
            dispatch(dismissNotification(key));
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      preventDuplicate={!isInternal}
      maxSnack={isInternal ? 10 : 3}
      Components={{ default: Snackbar }}
      classes={{ containerRoot: "mt-16" }}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
    >
      <CarbonNotificationsInner>{children}</CarbonNotificationsInner>
    </SnackbarProvider>
  );
};

const CarbonNotificationsInner: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  useCarbonNotifications();
  return children;
};

function useCarbonNotifications(): void {
  const dispatch = useDispatch();
  const { slots } = useNotifications();

  const { enqueueSnackbar, closeSnackbar } = useNotistackSnackbar();
  const displayedKeysRef = useRef<Set<NotificationKey> | undefined>(undefined);
  if (!displayedKeysRef.current) {
    displayedKeysRef.current = new Set();
  }
  const { current: displayedKeys } = displayedKeysRef;

  // Whenever Redux state changes, sync it out to Notistack state. And, when
  // Notistack is done with a notification, remove it from the Redux state.
  useEffect(() => {
    for (const slot of slots) {
      const { key, dismissed, notification } = slot;
      const displayed = displayedKeys.has(key);
      if (dismissed && displayed) {
        closeSnackbar(key);
      } else if (!dismissed && !displayed) {
        enqueueSnackbar({
          ...notification,
          key,
          onExited: () => {
            dispatch(notificationExited(key));
            displayedKeys.delete(key);
          },
        });
        displayedKeys.add(key);
      }
    }
  }, [dispatch, displayedKeys, slots, closeSnackbar, enqueueSnackbar]);
}
