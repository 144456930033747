import { CarbonUnit } from "portal/utils/units/units";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { CycleSlot } from "portal/utils/metrics";

interface State {
  cycleSlots: Record<string, CarbonUnit>;
}

const initialState: State = {
  cycleSlots: {
    [CycleSlot.STATUS_TIME]: "h",
    [CycleSlot.TIME]: "h",
    [CycleSlot.COMPONENT_ENCODERS]: "in",
  },
};

export const measurement = createSlice({
  name: "measurement",
  initialState,
  reducers: {
    setCycleSlot: (state, action: PayloadAction<[string, CarbonUnit]>) => {
      const [slot, unit] = action.payload;

      state.cycleSlots[slot] = unit;
    },
  },
});

export const { setCycleSlot } = measurement.actions;
