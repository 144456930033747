import { capitalize } from "./strings";
import {
  CategoryClassification,
  categoryClassificationFromJSON,
  DiscriminatorConfig,
  DiscriminatorTypeCategory,
  Trust,
} from "protos/almanac/almanac";
import { ConfigCrop } from "protos/portal/configs";
import { findWhere } from "./arrays";
import {
  getWeedCategoryName,
  WEED_CATEGORIES,
  WEED_CATEGORY_BROADLEAF,
  WEED_CATEGORY_GRASS,
  WEED_CATEGORY_OFFSHOOT,
  WEED_CATEGORY_PURSLANE,
} from "./almanac";
import { keys } from "./objects";
import { TFunction } from "i18next";

export const DEFAULT_TRUST = Trust.fromPartial({
  avoid: false,
  ignorable: false,
});

export const DEFAULT_CATEGORY = DiscriminatorTypeCategory.fromPartial({
  type: {
    category: "",
    classification: categoryClassificationFromJSON(
      CategoryClassification.CATEGORY_CROP
    ),
  },
  trusts: [DEFAULT_TRUST, DEFAULT_TRUST, DEFAULT_TRUST],
});

export const DEFAULT_WEED_BROADLEAF = DiscriminatorTypeCategory.fromPartial({
  ...DEFAULT_CATEGORY,
  type: {
    category: WEED_CATEGORY_BROADLEAF,
    classification: categoryClassificationFromJSON(
      CategoryClassification.CATEGORY_WEED
    ),
  },
});

export const DEFAULT_WEED_GRASS = DiscriminatorTypeCategory.fromPartial({
  ...DEFAULT_CATEGORY,
  type: {
    category: WEED_CATEGORY_GRASS,
    classification: categoryClassificationFromJSON(
      CategoryClassification.CATEGORY_WEED
    ),
  },
});

export const DEFAULT_WEED_OFFSHOOT = DiscriminatorTypeCategory.fromPartial({
  ...DEFAULT_CATEGORY,
  type: {
    category: WEED_CATEGORY_OFFSHOOT,
    classification: categoryClassificationFromJSON(
      CategoryClassification.CATEGORY_WEED
    ),
  },
});

export const DEFAULT_WEED_PURSLANE = DiscriminatorTypeCategory.fromPartial({
  ...DEFAULT_CATEGORY,
  type: {
    category: WEED_CATEGORY_PURSLANE,
    classification: categoryClassificationFromJSON(
      CategoryClassification.CATEGORY_WEED
    ),
  },
});

export const DEFAULT_DISCRIMINATOR = DiscriminatorConfig.fromPartial({
  categories: [
    DEFAULT_WEED_BROADLEAF,
    DEFAULT_WEED_GRASS,
    DEFAULT_WEED_OFFSHOOT,
    DEFAULT_WEED_PURSLANE,
  ],
  protected: false,
});

export const getDefaultWeedCategory = (
  uuid: string
): DiscriminatorTypeCategory => {
  let newCategory = DEFAULT_CATEGORY;
  switch (uuid) {
    case WEED_CATEGORY_BROADLEAF: {
      newCategory = DEFAULT_WEED_BROADLEAF;
      break;
    }
    case WEED_CATEGORY_GRASS: {
      newCategory = DEFAULT_WEED_GRASS;
      break;
    }
    case WEED_CATEGORY_OFFSHOOT: {
      newCategory = DEFAULT_WEED_OFFSHOOT;
      break;
    }
    case WEED_CATEGORY_PURSLANE: {
      newCategory = DEFAULT_WEED_PURSLANE;
      break;
    }
  }
  return structuredClone(newCategory);
};

export const getCategoryTitle = (
  t: TFunction,
  category: DiscriminatorTypeCategory,
  crops: ConfigCrop[] = []
): string => {
  if (!category.type?.category) {
    return t("models.weeds.categories.unknown");
  }
  if (category.type.category === "DEFAULT") {
    return capitalize(t("utils.descriptors.default"));
  }

  if (WEED_CATEGORIES.has(category.type.category)) {
    return getWeedCategoryName(t, category.type.category);
  }

  const crop = findWhere(crops, { id: category.type.category });
  return crop?.commonName ?? t("models.crops.categories.unknown");
};

export const isCategorySynced = (
  category: DiscriminatorTypeCategory
): boolean => {
  for (const key of keys(DEFAULT_TRUST)) {
    const values = new Set();
    for (const trust of category.trusts) {
      values.add(trust[key]);
    }
    if (values.size > 1) {
      return false;
    }
  }
  return true;
};
