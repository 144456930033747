// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.19.4
// source: frontend/profile_sync.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";

export const protobufPackage = "carbon.frontend.profile_sync";

export enum ProfileType {
  ALMANAC = 0,
  DISCRIMINATOR = 1,
  MODELINATOR = 3,
  BANDING = 4,
  THINNING = 5,
  TARGET_VELOCITY_ESTIMATOR = 6,
  UNRECOGNIZED = -1,
}

export function profileTypeFromJSON(object: any): ProfileType {
  switch (object) {
    case 0:
    case "ALMANAC":
      return ProfileType.ALMANAC;
    case 1:
    case "DISCRIMINATOR":
      return ProfileType.DISCRIMINATOR;
    case 3:
    case "MODELINATOR":
      return ProfileType.MODELINATOR;
    case 4:
    case "BANDING":
      return ProfileType.BANDING;
    case 5:
    case "THINNING":
      return ProfileType.THINNING;
    case 6:
    case "TARGET_VELOCITY_ESTIMATOR":
      return ProfileType.TARGET_VELOCITY_ESTIMATOR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProfileType.UNRECOGNIZED;
  }
}

export function profileTypeToJSON(object: ProfileType): string {
  switch (object) {
    case ProfileType.ALMANAC:
      return "ALMANAC";
    case ProfileType.DISCRIMINATOR:
      return "DISCRIMINATOR";
    case ProfileType.MODELINATOR:
      return "MODELINATOR";
    case ProfileType.BANDING:
      return "BANDING";
    case ProfileType.THINNING:
      return "THINNING";
    case ProfileType.TARGET_VELOCITY_ESTIMATOR:
      return "TARGET_VELOCITY_ESTIMATOR";
    case ProfileType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ProfileSyncData {
  profileType: ProfileType;
  lastUpdatedTsMs: number;
  deleted: boolean;
}

function createBaseProfileSyncData(): ProfileSyncData {
  return { profileType: 0, lastUpdatedTsMs: 0, deleted: false };
}

export const ProfileSyncData: MessageFns<ProfileSyncData> = {
  encode(message: ProfileSyncData, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.profileType !== 0) {
      writer.uint32(8).int32(message.profileType);
    }
    if (message.lastUpdatedTsMs !== 0) {
      writer.uint32(16).int64(message.lastUpdatedTsMs);
    }
    if (message.deleted !== false) {
      writer.uint32(24).bool(message.deleted);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ProfileSyncData {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProfileSyncData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.profileType = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.lastUpdatedTsMs = longToNumber(reader.int64());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.deleted = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProfileSyncData {
    return {
      profileType: isSet(object.profileType) ? profileTypeFromJSON(object.profileType) : 0,
      lastUpdatedTsMs: isSet(object.lastUpdatedTsMs) ? globalThis.Number(object.lastUpdatedTsMs) : 0,
      deleted: isSet(object.deleted) ? globalThis.Boolean(object.deleted) : false,
    };
  },

  toJSON(message: ProfileSyncData): unknown {
    const obj: any = {};
    if (message.profileType !== 0) {
      obj.profileType = profileTypeToJSON(message.profileType);
    }
    if (message.lastUpdatedTsMs !== 0) {
      obj.lastUpdatedTsMs = Math.round(message.lastUpdatedTsMs);
    }
    if (message.deleted !== false) {
      obj.deleted = message.deleted;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProfileSyncData>, I>>(base?: I): ProfileSyncData {
    return ProfileSyncData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProfileSyncData>, I>>(object: I): ProfileSyncData {
    const message = createBaseProfileSyncData();
    message.profileType = object.profileType ?? 0;
    message.lastUpdatedTsMs = object.lastUpdatedTsMs ?? 0;
    message.deleted = object.deleted ?? false;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
