import { CycleSlot } from "../metrics";
import { keys, values } from "../objects";
import area, { AreaUnits } from "convert-units/definitions/area";
import configMeasurements, { BestResult, Measure } from "convert-units";
import coverage, { CoverageUnits } from "./coverage";
import density, { DensityUnits } from "./density";
import length, { LengthUnits } from "convert-units/definitions/length";
import power, { PowerUnits } from "convert-units/definitions/power";
import speed, { SpeedUnits } from "convert-units/definitions/speed";
import time, { TimeUnits } from "convert-units/definitions/time";

type ConvertUnits =
  | AreaUnits
  | CoverageUnits
  | DensityUnits
  | LengthUnits
  | PowerUnits
  | SpeedUnits
  | TimeUnits;

export const convert = configMeasurements<
  "area" | "coverage" | "density" | "length" | "power" | "speed" | "time",
  MeasurementSystem,
  ConvertUnits
>({
  area,
  coverage,
  density,
  length,
  power,
  speed,
  time,
});

export enum MeasurementSystem {
  imperial = "imperial",
  metric = "metric",
  si = "SI",
}

export const bestString = (best: BestResult, toFixed?: number): string =>
  `${best.val.toFixed(toFixed ?? 0)}${best.unit}`;

export enum UnitNumberType {
  PERCENT = "%",
  COUNT = "_count",
}

export const isUnitNumberType = (units: CarbonUnit): units is UnitNumberType =>
  values(UnitNumberType).includes(units as UnitNumberType);

export type CarbonUnit = ConvertUnits | UnitNumberType;

// denylist units that are never appropriate in scale for our usecases
const EXCLUDED_UNITS: ConvertUnits[] = [
  // AREA
  "nm2",
  "μm2",
  "mm2",
  "cm2",
  "yd2",
  "in2",
  // LENGTH
  "nm",
  "μm",
  "mm",
  "cm",
  "mil",
  "yd",
  "ft-us",
  "fathom",
  "nMi",
  // SPEED
  "mm/h",
  "knot",
  "m/s",
  "ft/min",
  "in/h",
  // POWER
  "mW",
  "kW",
  "MW",
  "GW",
  "PS",
  "Btu/s",
  "ft-lb/s",
  "hp",
  // TIME
  "ns",
  "mu",
  "ms",
];

export const loadSavedUnit = (
  cycleSlots: Record<CycleSlot, CarbonUnit>,
  cycleSlot: CycleSlot | undefined,
  toSystem: MeasurementSystem,
  cycle?: CarbonUnit[] | undefined
): CarbonUnit | undefined => {
  let savedUnit = cycleSlot ? cycleSlots[cycleSlot] : undefined;
  const savedSystem =
    savedUnit && !isUnitNumberType(savedUnit)
      ? convert().describe(savedUnit).system
      : undefined;
  if (
    // dependencies for below checks
    cycleSlot &&
    savedUnit &&
    !isUnitNumberType(savedUnit) &&
    // if saved unit is SI, don't check against toSystem
    savedSystem !== MeasurementSystem.si &&
    // if saved unit is in the cycle, don't check against toSystem
    // (in cases like mm and W where we use metric units in imperial cycles)
    !cycle?.includes(savedUnit) &&
    // if saved system is different from target system, ignore saved unit
    savedSystem !== toSystem
  ) {
    savedUnit = cycle?.[0];
  }
  if (savedUnit && cycle && !cycle.includes(savedUnit)) {
    savedUnit = cycle[0];
  }
  return savedUnit;
};

export const getCycle = (
  fromUnit?: CarbonUnit,
  toSystem?: MeasurementSystem
): CarbonUnit[] | undefined => {
  if (!fromUnit) {
    return;
  }
  // if it's a metric number type, it can't cycle
  if (isUnitNumberType(fromUnit)) {
    return;
  }
  let cycle = convert().from(fromUnit).possibilities();
  const fromSystem = convert().describe(fromUnit).system;
  if (fromSystem === MeasurementSystem.si) {
    toSystem = MeasurementSystem.si;
  }

  cycle = cycle.filter((unit) => {
    if (EXCLUDED_UNITS.includes(unit)) {
      // remove excluded units
      return false;
    }

    // it's clearer formatted this way than a ternary
    // eslint-disable-next-line sonarjs/prefer-single-boolean-return
    if (convert().describe(unit).system !== toSystem) {
      // only show target system
      return false;
    }

    // otherwise it's part of the cycle
    return true;
  });
  return cycle;
};

export const getCarbonBest = (
  value: number,
  fromUnits: ConvertUnits,
  toSystem: MeasurementSystem
): BestResult | null => {
  const system =
    convert().describe(fromUnits).system === MeasurementSystem.si
      ? undefined
      : toSystem;
  // try to get whole number best units but allow smaller numbers if none match
  let converted = convert(value).from(fromUnits).toBest({
    system,
    exclude: EXCLUDED_UNITS,
    cutOffNumber: 1,
  });
  if (!converted) {
    converted = convert(value).from(fromUnits).toBest({
      system,
      exclude: EXCLUDED_UNITS,
      cutOffNumber: 0.1,
    });
  }
  if (!converted) {
    converted = convert(value).from(fromUnits).toBest({
      system,
      exclude: EXCLUDED_UNITS,
      cutOffNumber: 0,
    });
  }
  return converted;
};

export const resultToTArguments = (
  best: BestResult,
  longUnits: boolean = false
): { i18nKey: string; options?: any } => {
  const result: { i18nKey: string; options?: any } = {
    // carbon.actions.compareKeys.ignoreDynamic
    i18nKey: `utils.units.${best.unit}`,
  };
  if (longUnits) {
    result.i18nKey += "Long";
    result.options = { count: best.val };
  }
  return result;
};

export const allUnits = (measure: Measure<any, any>): string[] => [
  ...keys(measure.systems.imperial),
  ...keys(measure.systems.metric),
];

export const isSpeed = (units: CarbonUnit): units is SpeedUnits =>
  allUnits(speed).includes(units);

export const isDensity = (units: CarbonUnit): units is DensityUnits =>
  allUnits(density).includes(units);

export const isCoverage = (units: CarbonUnit): units is CoverageUnits =>
  allUnits(coverage).includes(units);

export const isArea = (units: CarbonUnit): units is AreaUnits =>
  allUnits(area).includes(units);

export const isTime = (units: CarbonUnit): units is TimeUnits =>
  allUnits(time).includes(units);
