import { classes } from "portal/utils/theme";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { entries } from "portal/utils/objects";
import { getApplicationKeyMap, GlobalHotKeys } from "react-hotkeys";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import React, { ReactElement, useState } from "react";

export const KeyboardShortcutsDialog = (): ReactElement => {
  const { t } = useTranslation();
  const { isInternal } = useSelf();
  const [showHotkeys, setShowHotkeys] = useState<boolean>(false);
  const keyMap = getApplicationKeyMap();

  return (
    <>
      <GlobalHotKeys
        keyMap={{
          TOGGLE_HELP: {
            name: t("components.KeyboardShortcutsDialog.help"),
            action: "keypress",
            sequence: "shift+?",
          },
        }}
        handlers={{
          TOGGLE_HELP: () => setShowHotkeys((showHotkeys) => !showHotkeys),
        }}
      />
      <Dialog open={showHotkeys} onClose={() => setShowHotkeys(false)}>
        <DialogTitle>
          {t("components.KeyboardShortcutsDialog.title")}
        </DialogTitle>
        <DialogContent dividers>
          <ul className="p-0 flex flex-col gap-4">
            {entries(keyMap).map(([key, { name, sequences }]) => {
              // don't show debug shortcuts to customers
              if (key.startsWith("DEBUG_") && !isInternal) {
                return;
              }
              return (
                <li key={key} className="flex justify-between">
                  <div>
                    {sequences.map(({ sequence }, index) => (
                      <span
                        key={index}
                        className={classes(
                          "rounded bg-black border-[2px] border-solid border-gray-600",
                          "p-2 text-xs font-mono",
                          {
                            "ml-2": index > 0,
                          }
                        )}
                      >
                        {sequence}
                      </span>
                    ))}
                  </div>
                  <span className="ml-4">{name}</span>
                </li>
              );
            })}
          </ul>
        </DialogContent>
      </Dialog>
    </>
  );
};
