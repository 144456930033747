import {
  aggregateConclusionCounts,
  BlockColors,
  COLOR_BLUE,
  COLOR_BROWN,
  COLOR_GREEN,
  COLOR_RED,
  COLOR_WHITE,
  COLOR_YELLOW,
  getLength,
  isThinning,
  isWeeding,
  mixColor,
} from "./blocks";
import { BlockResponse } from "protos/portal/spatial";
import { clamp, mean } from "./math";
import {
  ConclusionType,
  conclusionTypeFromJSON,
} from "protos/weed_tracking/weed_tracking";
import { convert, MeasurementSystem, UnitNumberType } from "./units/units";
import { CycleSlot, MetricValidation, SpatialMetric } from "./metrics";
import { DateTime, Duration } from "luxon";
import { findWhere } from "./arrays";
import { isUndefined } from "./identity";
import { ParseKeys } from "i18next";
import { SIZE_MEDIUM_TO_LARGE, SIZE_SMALL_TO_MEDIUM } from "./almanac";
import { values } from "./objects";
import { WeedCounterChunk } from "protos/metrics/metrics";

export const getSpatialMetricById = (id: string): SpatialMetric | undefined =>
  findWhere(SPATIAL_METRICS, { id });

export const isSpatialOneOf = (id: string, list: SpatialMetric[]): boolean =>
  !isUndefined(findWhere(list, { id }));

const KILLED_CONCLUSIONS: ConclusionType[] = [
  conclusionTypeFromJSON(ConclusionType.SHOT),
];
const SKIPPED_CONCLUSIONS: ConclusionType[] = [
  conclusionTypeFromJSON(ConclusionType.INTERSECTS_WITH_NON_SHOOTABLE),
  conclusionTypeFromJSON(ConclusionType.OUT_OF_BAND),
  conclusionTypeFromJSON(ConclusionType.UNIMPORTANT),
  conclusionTypeFromJSON(ConclusionType.NOT_TARGETED),
  conclusionTypeFromJSON(ConclusionType.OUT_OF_RANGE),
];
const MISSED_CONCLUSIONS: ConclusionType[] = [
  conclusionTypeFromJSON(ConclusionType.ERROR),
  conclusionTypeFromJSON(ConclusionType.PARTIALLY_SHOT),
  conclusionTypeFromJSON(ConclusionType.NOT_SHOT),
  conclusionTypeFromJSON(ConclusionType.P2P_NOT_FOUND),
  conclusionTypeFromJSON(ConclusionType.P2P_MISSING_CONTEXT),
  conclusionTypeFromJSON(ConclusionType.MARKED_FOR_THINNING),
];

const COLORS_MORE_BAD: BlockColors = {
  colors: [COLOR_GREEN, COLOR_YELLOW, COLOR_RED],
};

const COLORS_WEED_TYPE: BlockColors = {
  absoluteBreakpoints: [0, 25, 50],
  colors: [COLOR_GREEN, COLOR_YELLOW, COLOR_RED],
  labels: [
    { i18nKey: "utils.descriptors.few" },
    { i18nKey: "utils.descriptors.minority" },
    { i18nKey: "utils.descriptors.majority" },
  ],
};

const COLORS_ACTIVITY: BlockColors = {
  absoluteBreakpoints: [0, 1],
  binary: true,
  colors: [COLOR_RED, COLOR_GREEN],
};

const COLORS_MORE_GOOD: BlockColors = {
  colors: [COLOR_RED, COLOR_YELLOW, COLOR_GREEN],
};

const COLORS_LARGE_BAD: BlockColors = {
  colors: [COLOR_GREEN, COLOR_YELLOW, COLOR_RED],
};

const COLORS_PERFORMANCE: BlockColors = {
  absoluteBreakpoints: [75, 90, 100],
  colors: [COLOR_RED, COLOR_YELLOW, COLOR_GREEN],
  labels: [
    { i18nKey: "utils.descriptors.poor" },
    { i18nKey: "utils.descriptors.ok" },
    { i18nKey: "utils.descriptors.good" },
  ],
};

const COLORS_SPEED: BlockColors = {
  absoluteBreakpoints: [0.2, 0.6, 1],
  colors: [COLOR_RED, COLOR_YELLOW, COLOR_GREEN],
  labels: [
    { i18nKey: "utils.descriptors.slow" },
    { i18nKey: "utils.descriptors.ok" },
    { i18nKey: "utils.descriptors.fast" },
  ],
};

const COLORS_SHOOT_TIME: BlockColors = {
  absoluteBreakpoints: [100, 300, 500],
  colors: [COLOR_GREEN, COLOR_YELLOW, COLOR_RED],
  labels: [
    { i18nKey: "utils.descriptors.fast" },
    { i18nKey: "utils.descriptors.ok" },
    { i18nKey: "utils.descriptors.slow" },
  ],
};

const COLORS_CROP_DENSITY: BlockColors = {
  colors: [COLOR_RED, COLOR_GREEN, COLOR_BLUE],
};

const COLORS_KEPT_CROP_DENSITY: BlockColors = {
  colors: [COLOR_RED, COLOR_GREEN, COLOR_YELLOW],
};

const DEFAULT_METRIC: Pick<
  SpatialMetric,
  "canAverage" | "canTotal" | "canMap" | "validation" | "getColor" | "colors"
> = {
  canAverage: true,
  canTotal: true,
  canMap: true,
  validation: MetricValidation.PENDING,
  colors: {
    absoluteBreakpoints: [0, 100],
    colors: [COLOR_GREEN, COLOR_GREEN],
    labels: [
      { i18nKey: "utils.metrics.aggregates.min" },
      { i18nKey: "utils.metrics.aggregates.max" },
    ],
  },
  getColor(value, range, isRelative = false) {
    const { colors } = this;
    colors.relativeBreakpoints = [range.low, range.medium, range.high];
    return mixColor(value, colors, isRelative);
  },
};

export const SPATIAL_AVERAGE_WEED_SIZE: SpatialMetric = {
  ...DEFAULT_METRIC,
  canTotal: false,
  type: "number",
  units: "mm",
  toUnits: "mm",
  cycle: {
    [MeasurementSystem.imperial]: ["in", "mm"],
    [MeasurementSystem.metric]: ["mm"],
  },
  id: "averageWeedSize",
  validation: MetricValidation.VALIDATED,
  colors: {
    ...COLORS_LARGE_BAD,
    absoluteBreakpoints: [
      0,
      mean([SIZE_SMALL_TO_MEDIUM, SIZE_MEDIUM_TO_LARGE]) ?? 0,
      SIZE_MEDIUM_TO_LARGE * 2,
    ],
    labels: [
      { i18nKey: "utils.descriptors.small" },
      { i18nKey: "utils.descriptors.medium" },
      { i18nKey: "utils.descriptors.large" },
    ],
  },
  getValue: (block) => {
    const cumulativeSize = block.block?.weedCount?.weedSizeData?.cumulativeSize;
    const totalWeeds = block.block?.weedCount?.weedSizeData?.count;
    if (!cumulativeSize || !totalWeeds) {
      return;
    }
    return cumulativeSize / totalWeeds;
  },
  cycleSlot: CycleSlot.SIZE,
};

export const SPATIAL_TOTAL_WEEDS: SpatialMetric = {
  ...DEFAULT_METRIC,
  canMap: false,
  id: "totalWeeds",
  type: "number",
  units: UnitNumberType.COUNT,
  validation: MetricValidation.INTERNAL,
  colors: COLORS_MORE_BAD,
  getValue: (block) => block.block?.weedCount?.weedSizeData?.count,
};

export const SPATIAL_TOTAL_WEEDS_IN_BAND: SpatialMetric = {
  ...DEFAULT_METRIC,
  canMap: false,
  id: "totalWeedsInBand",
  type: "number",
  units: UnitNumberType.COUNT,
  validation: MetricValidation.VALIDATED,
  colors: COLORS_MORE_BAD,
  getValue: (block) => {
    const killed = SPATIAL_KILLED_WEEDS.getValue(block);
    const skipped = SPATIAL_SKIPPED_WEEDS.getValue(block);
    return killed + skipped;
  },
};

export const SPATIAL_AVERAGE_CROP_SIZE: SpatialMetric = {
  ...DEFAULT_METRIC,
  canTotal: false,
  type: "number",
  units: "mm",
  toUnits: "mm",
  cycle: {
    [MeasurementSystem.imperial]: ["in", "mm"],
    [MeasurementSystem.metric]: ["mm"],
  },
  id: "averageCropSize",
  validation: MetricValidation.VALIDATED,
  colors: COLORS_MORE_GOOD,
  getValue: (block) => {
    const cumulativeSize = block.block?.weedCount?.cropSizeData?.cumulativeSize;
    const totalCrops = block.block?.weedCount?.cropSizeData?.count;
    if (!cumulativeSize || !totalCrops) {
      return;
    }
    return cumulativeSize / totalCrops;
  },
  cycleSlot: CycleSlot.SIZE,
};

export const SPATIAL_AVERAGE_UNTARGETED_REQ_LASER_TIME: SpatialMetric = {
  ...DEFAULT_METRIC,
  canTotal: false,
  type: "number",
  units: "ms",
  toUnits: "ms",
  cycle: {
    [MeasurementSystem.imperial]: ["ms"],
    [MeasurementSystem.metric]: ["ms"],
  },
  id: "avgUntargetedReqLaserTime",
  validation: MetricValidation.INTERNAL,
  minY: 100,
  maxY: 300,
  colors: COLORS_SHOOT_TIME,
  getValue: (block) => {
    if (!block.block?.weedCount?.untargetedLaserTimeData) {
      return;
    }
    const cumulativeSize =
      block.block.weedCount.untargetedLaserTimeData.cumulativeTime;
    const totalCount = block.block.weedCount.untargetedLaserTimeData.count;
    if (!totalCount) {
      return;
    }
    return cumulativeSize / totalCount;
  },
  cycleSlot: CycleSlot.SHOOT,
};

export const SPATIAL_AVERAGE_TARGETED_REQ_LASER_TIME: SpatialMetric = {
  ...DEFAULT_METRIC,
  canTotal: false,
  type: "number",
  units: "ms",
  toUnits: "ms",
  cycle: {
    [MeasurementSystem.imperial]: ["ms"],
    [MeasurementSystem.metric]: ["ms"],
  },
  id: "avgTargetedReqLaserTime",
  colors: COLORS_SHOOT_TIME,
  minY: 100,
  maxY: 300,
  getValue: (block) => {
    if (!block.block?.weedCount?.targetedLaserTimeData) {
      return;
    }
    const cumulativeSize =
      block.block.weedCount.targetedLaserTimeData.cumulativeTime;
    const totalCount = block.block.weedCount.targetedLaserTimeData.count;
    if (!totalCount) {
      return;
    }
    return cumulativeSize / totalCount;
  },
  cycleSlot: CycleSlot.SHOOT,
};

export const SPATIAL_TOTAL_CROPS: SpatialMetric = {
  ...DEFAULT_METRIC,
  id: "totalCrops",
  type: "number",
  units: UnitNumberType.COUNT,
  colors: COLORS_CROP_DENSITY,
  getValue: (block) => block.block?.weedCount?.cropSizeData?.count,
};

export const SPATIAL_TOTAL_VALID_CROPS: SpatialMetric = {
  ...DEFAULT_METRIC,
  id: "totalCropsValid",
  type: "number",
  units: UnitNumberType.COUNT,
  colors: COLORS_CROP_DENSITY,
  getValue: (block) => block.block?.weedCount?.validCropCount,
};

export const SPATIAL_PERCENT_BANDED: SpatialMetric = {
  ...DEFAULT_METRIC,
  canTotal: false,
  canMap: false,
  id: "percentBanded",
  type: "number",
  fill: true,
  units: UnitNumberType.PERCENT,
  unitsDelimiter: "",
  minY: 0,
  maxY: 100,
  colors: {
    absoluteBreakpoints: [0, 50, 100],
    colors: [COLOR_GREEN, COLOR_YELLOW, COLOR_RED],
  },
  getValue: (block) =>
    clamp(0, 100, block.block?.bandingData?.percentBanded ?? 100),
};

const percentOfCategorized = (
  category: keyof WeedCounterChunk["countsByCategory"],
  block: BlockResponse
): any => {
  const totalCategory = block.block?.weedCount?.countsByCategory[category];
  const totalCategorized = values(
    block.block?.weedCount?.countsByCategory ?? {}
  ).reduce((partialSum, count) => partialSum + count, 0);
  if (!totalCategory || !totalCategorized) {
    return;
  }
  return (totalCategory / totalCategorized) * 100;
};

export const SPATIAL_BROADLEAF: SpatialMetric = {
  ...DEFAULT_METRIC,
  canTotal: false,
  id: "broadleaf",
  type: "number",
  units: UnitNumberType.PERCENT,
  unitsDelimiter: "",
  decimalPlaces: 0,
  validation: MetricValidation.VALIDATED,
  colors: COLORS_WEED_TYPE,
  getValue: (block) => percentOfCategorized("BROADLEAF", block),
};

export const SPATIAL_GRASS: SpatialMetric = {
  ...DEFAULT_METRIC,
  canTotal: false,
  id: "grass",
  type: "number",
  units: UnitNumberType.PERCENT,
  unitsDelimiter: "",
  decimalPlaces: 0,
  validation: MetricValidation.VALIDATED,
  colors: COLORS_WEED_TYPE,
  getValue: (block) => percentOfCategorized("GRASS", block),
};

export const SPATIAL_OFFSHOOT: SpatialMetric = {
  ...DEFAULT_METRIC,
  canTotal: false,
  id: "offshoot",
  type: "number",
  units: UnitNumberType.PERCENT,
  unitsDelimiter: "",
  decimalPlaces: 0,
  validation: MetricValidation.VALIDATED,
  colors: COLORS_WEED_TYPE,
  getValue: (block) => percentOfCategorized("OFFSHOOT", block),
};

export const SPATIAL_PURSLANE: SpatialMetric = {
  ...DEFAULT_METRIC,
  canTotal: false,
  id: "purslane",
  type: "number",
  units: UnitNumberType.PERCENT,
  unitsDelimiter: "",
  decimalPlaces: 0,
  validation: MetricValidation.VALIDATED,
  colors: COLORS_WEED_TYPE,
  getValue: (block) => percentOfCategorized("PURSLANE", block),
};

export const SPATIAL_COVERAGE: SpatialMetric = {
  ...DEFAULT_METRIC,
  canTotal: false,
  canAverage: false,
  id: "coverage",
  type: "boolean",
  validation: MetricValidation.VALIDATED,
  getColor: (isActive) =>
    isActive ? COLOR_BLUE.toString() : COLOR_WHITE.toString(),
  truei18nKey: "utils.descriptors.active",
  falsei18nKey: "utils.descriptors.inactive",
  getValue: (block) => isWeeding(block) || isThinning(block),
};

export const SPATIAL_TIME: SpatialMetric = {
  ...DEFAULT_METRIC,
  canTotal: false,
  canAverage: false,
  canMap: false,
  id: "dateTime",
  units: "ms",
  type: "number",
  getColor: () => COLOR_WHITE.toString(),
  getValue: (block) =>
    block.block?.start?.timestampMs
      ? DateTime.fromMillis(block.block.start.timestampMs).toJSDate()
      : undefined,
  cycleSlot: CycleSlot.TIME,
};

export const SPATIAL_ALTITUDE: SpatialMetric = {
  ...DEFAULT_METRIC,
  canTotal: false,
  id: "altitude",
  units: "ft",
  type: "number",
  colors: {
    colors: [COLOR_BLUE, COLOR_GREEN, COLOR_BROWN],
  },
  getValue: (block) => {
    const altitudeMm = block.block?.start?.heightMm;
    if (!altitudeMm) {
      return;
    }
    return convert(altitudeMm).from("mm").to("ft");
  },
  cycleSlot: CycleSlot.ALTITUDE,
};

export const SPATIAL_WEEDING: SpatialMetric = {
  ...DEFAULT_METRIC,
  canTotal: false,
  id: "weeding",
  type: "boolean",
  validation: MetricValidation.VALIDATED,
  truei18nKey: "utils.descriptors.active",
  falsei18nKey: "utils.descriptors.inactive",
  renderWhenNotCountable: true,
  colors: COLORS_ACTIVITY,
  getColor(value) {
    const falseColor = this.colors.colors[0];
    const trueColor = this.colors.colors[1];
    if (!falseColor || !trueColor) {
      return "";
    }
    return value ? trueColor.toString() : falseColor.toString();
  },
  getValue: (block) => isWeeding(block),
};

export const SPATIAL_THINNING: SpatialMetric = {
  ...DEFAULT_METRIC,
  canTotal: false,
  id: "thinning",
  type: "boolean",
  validation: MetricValidation.VALIDATED,
  truei18nKey: "utils.descriptors.active",
  falsei18nKey: "utils.descriptors.inactive",
  renderWhenNotCountable: true,
  colors: COLORS_ACTIVITY,
  getColor(value) {
    const falseColor = this.colors.colors[0];
    const trueColor = this.colors.colors[1];
    if (!falseColor || !trueColor) {
      return "";
    }
    return value ? trueColor.toString() : falseColor.toString();
  },
  getValue: (block) => isThinning(block),
};

export const SPATIAL_KILLED_WEEDS: SpatialMetric = {
  ...DEFAULT_METRIC,
  id: "weedsKilled",
  type: "number",
  units: UnitNumberType.COUNT,
  colors: COLORS_MORE_GOOD,
  getValue: (block) =>
    block.block?.weedCount?.conclusionCounts?.armedWeed[
      conclusionTypeFromJSON(ConclusionType.SHOT)
    ],
};

export const SPATIAL_SKIPPED_WEEDS: SpatialMetric = {
  ...DEFAULT_METRIC,
  id: "weedsSkipped",
  type: "number",
  units: UnitNumberType.COUNT,
  colors: COLORS_MORE_BAD,
  getValue: (block) =>
    aggregateConclusionCounts(
      block.block?.weedCount?.conclusionCounts?.armedWeed ?? [],
      SKIPPED_CONCLUSIONS
    ),
};

export const SPATIAL_MISSED_WEEDS: SpatialMetric = {
  ...DEFAULT_METRIC,
  id: "weedsMissed",
  type: "number",
  units: UnitNumberType.COUNT,
  colors: COLORS_MORE_BAD,
  getValue: (block) =>
    aggregateConclusionCounts(
      block.block?.weedCount?.conclusionCounts?.armedWeed ?? [],
      MISSED_CONCLUSIONS
    ),
};

export const SPATIAL_KILLED_CROPS: SpatialMetric = {
  ...DEFAULT_METRIC,
  id: "cropsKilled",
  type: "number",
  units: UnitNumberType.COUNT,
  colors: COLORS_MORE_GOOD,
  getValue: (block) =>
    aggregateConclusionCounts(
      block.block?.weedCount?.conclusionCounts?.armedCrop ?? [],
      KILLED_CONCLUSIONS
    ),
};

export const SPATIAL_SKIPPED_CROPS: SpatialMetric = {
  ...DEFAULT_METRIC,
  id: "cropsSkipped",
  type: "number",
  units: UnitNumberType.COUNT,
  colors: COLORS_MORE_BAD,
  getValue: (block) =>
    aggregateConclusionCounts(
      block.block?.weedCount?.conclusionCounts?.armedCrop ?? [],
      SKIPPED_CONCLUSIONS
    ),
};

export const SPATIAL_MISSED_CROPS: SpatialMetric = {
  ...DEFAULT_METRIC,
  id: "cropsMissed",
  type: "number",
  units: UnitNumberType.COUNT,
  colors: COLORS_MORE_BAD,
  getValue: (block) =>
    aggregateConclusionCounts(
      block.block?.weedCount?.conclusionCounts?.armedCrop ?? [],
      MISSED_CONCLUSIONS
    ),
};

export const SPATIAL_KEPT_CROPS: SpatialMetric = {
  ...DEFAULT_METRIC,
  id: "cropsKept",
  type: "number",
  units: UnitNumberType.COUNT,
  colors: COLORS_KEPT_CROP_DENSITY,
  getValue: (block) => {
    const total = SPATIAL_TOTAL_CROPS.getValue(block);
    const killed = SPATIAL_KILLED_CROPS.getValue(block);
    return total - killed;
  },
};

export const SPATIAL_AVERAGE_SPEED: SpatialMetric = {
  ...DEFAULT_METRIC,
  canTotal: false,
  id: "speedTravel",
  type: "number",
  units: "mph",
  validation: MetricValidation.VALIDATED,
  minY: 0,
  maxY: 2,
  colors: COLORS_SPEED,
  getValue: (block) => {
    const startTime = block.block?.start?.timestampMs;
    const endTime = block.block?.end?.timestampMs;
    const length = getLength(block, "miles");
    if (!startTime || !endTime || isUndefined(length)) {
      return;
    }
    const time = Duration.fromMillis(endTime - startTime);
    return length / time.as("hours");
  },
  cycleSlot: CycleSlot.SPEED,
};

export const SPATIAL_SMOOTHED_TARGET_SPEED: SpatialMetric = {
  ...DEFAULT_METRIC,
  canTotal: false,
  id: "speedTargetSmoothed",
  type: "number",
  units: "mph",
  validation: MetricValidation.VALIDATED,
  minY: 0,
  maxY: 2,
  colors: COLORS_SPEED,
  getValue: (block) => {
    const smoothedTargetSpeed = block.block?.velData?.avgTargetVel.cmd;
    if (!smoothedTargetSpeed) {
      return;
    }
    return convert(smoothedTargetSpeed).from("m/s").to("mph");
  },
  cycleSlot: CycleSlot.SPEED,
};

export const SPATIAL_MINIMUM_TARGET_SPEED: SpatialMetric = {
  ...DEFAULT_METRIC,
  canTotal: false,
  id: "speedTargetMinimum",
  type: "number",
  units: "mph",
  validation: MetricValidation.INTERNAL,
  minY: 0,
  maxY: 2,
  colors: COLORS_SPEED,
  getValue: (block) => {
    const targetSpeedsByRow = block.block?.velData?.avgTargetVel;
    if (!targetSpeedsByRow) {
      return;
    }
    const speeds = [
      targetSpeedsByRow.row1,
      targetSpeedsByRow.row2,
      targetSpeedsByRow.row3,
    ].filter((speed) => !isUndefined(speed));
    const minTargetSpeedMps = Math.min(...speeds);
    return convert(minTargetSpeedMps).from("m/s").to("mph");
  },
  cycleSlot: CycleSlot.SPEED,
};

export const SPATIAL_ROW1_TARGET_SPEED: SpatialMetric = {
  ...DEFAULT_METRIC,
  canTotal: false,
  id: "speedTargetRow1",
  type: "number",
  units: "mph",
  validation: MetricValidation.INTERNAL,
  minY: 0,
  maxY: 2,
  colors: COLORS_SPEED,
  getValue: (block) => {
    const smoothedTargetSpeed = block.block?.velData?.avgTargetVel.row1;
    if (!smoothedTargetSpeed) {
      return;
    }
    return convert(smoothedTargetSpeed).from("m/s").to("mph");
  },
  cycleSlot: CycleSlot.SPEED,
};

export const SPATIAL_ROW2_TARGET_SPEED: SpatialMetric = {
  ...DEFAULT_METRIC,
  canTotal: false,
  id: "speedTargetRow2",
  type: "number",
  units: "mph",
  validation: MetricValidation.INTERNAL,
  minY: 0,
  maxY: 2,
  colors: COLORS_SPEED,
  getValue: (block) => {
    const smoothedTargetSpeed = block.block?.velData?.avgTargetVel.row2;
    if (!smoothedTargetSpeed) {
      return;
    }
    return convert(smoothedTargetSpeed).from("m/s").to("mph");
  },
  cycleSlot: CycleSlot.SPEED,
};

export const SPATIAL_ROW3_TARGET_SPEED: SpatialMetric = {
  ...DEFAULT_METRIC,
  canTotal: false,
  id: "speedTargetRow3",
  type: "number",
  units: "mph",
  validation: MetricValidation.INTERNAL,
  minY: 0,
  maxY: 2,
  colors: COLORS_SPEED,
  getValue: (block) => {
    const smoothedTargetSpeed = block.block?.velData?.avgTargetVel.row3;
    if (!smoothedTargetSpeed) {
      return;
    }
    return convert(smoothedTargetSpeed).from("m/s").to("mph");
  },
  cycleSlot: CycleSlot.SPEED,
};

export const SPATIAL_SPEED_EFFICIENCY: SpatialMetric = {
  ...DEFAULT_METRIC,
  canTotal: false,
  id: "speed",
  type: "number",
  units: UnitNumberType.PERCENT,
  minY: 0,
  maxY: 100,
  unitsDelimiter: "",
  validation: MetricValidation.VALIDATED,
  colors: {
    absoluteBreakpoints: [75, 100, 110, 125],
    colors: [COLOR_BLUE, COLOR_GREEN, COLOR_YELLOW, COLOR_RED],
    labels: [
      { i18nKey: "utils.descriptors.slow" },
      { i18nKey: "utils.descriptors.good" },
      { i18nKey: "utils.descriptors.ok" },
      { i18nKey: "utils.descriptors.fast" },
    ],
  },
  getValue: (block) => {
    const speed = SPATIAL_AVERAGE_SPEED.getValue(block);
    const target = SPATIAL_SMOOTHED_TARGET_SPEED.getValue(block);
    if (!speed || !target) {
      return;
    }
    return (speed / target) * 100;
  },
};

export const SPATIAL_WEED_DENSITY: SpatialMetric = {
  ...DEFAULT_METRIC,
  canTotal: false,
  id: "weedDensity",
  type: "number",
  units: "/ft2",
  unitsDelimiter: "",
  validation: MetricValidation.VALIDATED,
  colors: {
    absoluteBreakpoints: [0, 15, 25],
    colors: [COLOR_GREEN, COLOR_YELLOW, COLOR_RED],
    labels: [
      { i18nKey: "utils.descriptors.sparse" },
      { i18nKey: "utils.descriptors.ok" },
      { i18nKey: "utils.descriptors.dense" },
    ],
  },
  getValue: (block) => {
    const widthMm =
      block.block?.implementWidthData?.widthMm ??
      convert(20).from("ft").to("mm");
    const length = getLength(block, "feet");
    if (!widthMm || isUndefined(length)) {
      return;
    }
    const totalWeeds = block.block?.weedCount?.weedSizeData?.count;
    const area = convert(widthMm).from("mm").to("ft") * length;
    if (!totalWeeds || !area) {
      return;
    }
    return totalWeeds / area;
  },
  cycleSlot: CycleSlot.DENSITY,
};

export const SPATIAL_CROP_DENSITY: SpatialMetric = {
  ...DEFAULT_METRIC,
  canTotal: false,
  id: "cropDensity",
  type: "number",
  units: "/ft2",
  unitsDelimiter: "",
  validation: MetricValidation.VALIDATED,
  colors: COLORS_CROP_DENSITY,
  getValue: (block) => {
    const widthMm = block.block?.implementWidthData?.widthMm;
    const length = getLength(block, "feet");
    if (!widthMm || isUndefined(length)) {
      return;
    }
    const totalCrops = block.block?.weedCount?.cropSizeData?.count;
    const area = convert(widthMm).from("mm").to("ft") * length;
    if (!totalCrops || !area) {
      return;
    }
    return totalCrops / area;
  },
  cycleSlot: CycleSlot.DENSITY,
};

export const SPATIAL_KEPT_CROP_DENSITY: SpatialMetric = {
  ...DEFAULT_METRIC,
  canTotal: false,
  id: "keptCropDensity",
  type: "number",
  units: "/ft2",
  unitsDelimiter: "",
  validation: MetricValidation.VALIDATED,
  colors: COLORS_KEPT_CROP_DENSITY,
  getValue: (block) => {
    const widthMm = block.block?.implementWidthData?.widthMm;
    const length = getLength(block, "feet");
    if (!widthMm || isUndefined(length)) {
      return;
    }
    const keptCrops = SPATIAL_KEPT_CROPS.getValue(block);
    const area = convert(widthMm).from("mm").to("ft") * length;
    return keptCrops / area;
  },
  cycleSlot: CycleSlot.DENSITY,
};

export const SPATIAL_WEEDING_EFFICIENCY: SpatialMetric = {
  ...DEFAULT_METRIC,
  type: "number",
  units: UnitNumberType.PERCENT,
  unitsDelimiter: "",
  minY: 0,
  maxY: 100,
  canTotal: false,
  id: "weedingEfficiency",
  validation: MetricValidation.VALIDATED,
  colors: COLORS_PERFORMANCE,
  getValue: (block) => {
    const killed = aggregateConclusionCounts(
      block.block?.weedCount?.conclusionCounts?.armedWeed ?? [],
      KILLED_CONCLUSIONS
    );
    const missed = aggregateConclusionCounts(
      block.block?.weedCount?.conclusionCounts?.armedWeed ?? [],
      MISSED_CONCLUSIONS
    );
    if (killed + missed === 0) {
      return;
    }
    return (killed / (killed + missed)) * 100;
  },
};

export const SPATIAL_OVERALL_EFFICIENCY: SpatialMetric = {
  ...DEFAULT_METRIC,
  type: "number",
  units: UnitNumberType.PERCENT,
  unitsDelimiter: "",
  minY: 0,
  maxY: 100,
  canTotal: false,
  id: "overallEfficiency",
  validation: MetricValidation.VALIDATED,
  colors: COLORS_PERFORMANCE,
  getValue: (block) => {
    const weeding = SPATIAL_WEEDING_EFFICIENCY.getValue(block);
    const thinning = SPATIAL_THINNING_EFFICIENCY.getValue(block);
    if (weeding && !thinning) {
      return weeding;
    } else if (thinning && !weeding) {
      return thinning;
    } else if (thinning && weeding) {
      return mean([weeding, thinning]);
    }
  },
};

export const SPATIAL_THINNING_EFFICIENCY: SpatialMetric = {
  ...DEFAULT_METRIC,
  type: "number",
  units: UnitNumberType.PERCENT,
  unitsDelimiter: "",
  minY: 0,
  maxY: 100,
  canTotal: false,
  id: "thinningEfficiency",
  validation: MetricValidation.VALIDATED,
  colors: COLORS_PERFORMANCE,
  getValue: (block) => {
    const killed = aggregateConclusionCounts(
      block.block?.weedCount?.conclusionCounts?.armedCrop ?? [],
      KILLED_CONCLUSIONS
    );
    const missed = aggregateConclusionCounts(
      block.block?.weedCount?.conclusionCounts?.armedCrop ?? [],
      MISSED_CONCLUSIONS
    );
    if (killed + missed === 0) {
      return;
    }
    return (killed / (killed + missed)) * 100;
  },
};

export const SPATIAL_LIFTED: SpatialMetric = {
  ...DEFAULT_METRIC,
  type: "boolean",
  canTotal: false,
  canAverage: false,
  id: "lifted",
  validation: MetricValidation.INTERNAL,
  truei18nKey: "utils.descriptors.liftedOn",
  falsei18nKey: "utils.descriptors.liftedOff",
  renderWhenNotCountable: true,
  colors: {
    absoluteBreakpoints: [0, 1],
    binary: true,
    colors: [COLOR_GREEN, COLOR_RED],
    labels: [
      { i18nKey: "utils.descriptors.liftedOff" },
      { i18nKey: "utils.descriptors.liftedOn" },
    ],
  },
  getValue: (block) => block.block?.hwMetric?.lifted ?? false,
};

export const SPATIAL_ESTOPPED: SpatialMetric = {
  ...DEFAULT_METRIC,
  type: "boolean",
  canTotal: false,
  canAverage: false,
  id: "estopped",
  validation: MetricValidation.INTERNAL,
  truei18nKey: "utils.descriptors.estopOn",
  falsei18nKey: "utils.descriptors.estopOff",
  renderWhenNotCountable: true,
  colors: {
    absoluteBreakpoints: [0, 1],
    binary: true,
    colors: [COLOR_GREEN, COLOR_RED],
    labels: [
      { i18nKey: "utils.descriptors.estopOff" },
      { i18nKey: "utils.descriptors.estopOn" },
    ],
  },
  getValue: (block) => block.block?.hwMetric?.estopped ?? false,
};

export const SPATIAL_LASER_KEY: SpatialMetric = {
  ...DEFAULT_METRIC,
  type: "boolean",
  canTotal: false,
  canAverage: false,
  id: "laserKey",
  validation: MetricValidation.INTERNAL,
  truei18nKey: "utils.descriptors.laserKeyOn",
  falsei18nKey: "utils.descriptors.laserKeyOff",
  renderWhenNotCountable: true,
  colors: {
    absoluteBreakpoints: [0, 1],
    binary: true,
    colors: [COLOR_RED, COLOR_GREEN],
    labels: [
      { i18nKey: "utils.descriptors.laserKeyOff" },
      { i18nKey: "utils.descriptors.laserKeyOn" },
    ],
  },
  getValue: (block) => block.block?.hwMetric?.laserKey,
};

export const SPATIAL_INTERLOCK: SpatialMetric = {
  ...DEFAULT_METRIC,
  type: "boolean",
  canTotal: false,
  canAverage: false,
  id: "interlock",
  validation: MetricValidation.INTERNAL,
  truei18nKey: "utils.descriptors.interlockSafe",
  falsei18nKey: "utils.descriptors.interlockUnsafe",
  renderWhenNotCountable: true,
  colors: {
    absoluteBreakpoints: [0, 1],
    binary: true,
    colors: [COLOR_RED, COLOR_GREEN],
    labels: [
      { i18nKey: "utils.descriptors.interlockUnsafe" },
      { i18nKey: "utils.descriptors.interlockSafe" },
    ],
  },
  getValue: (block) => block.block?.hwMetric?.interlock,
};

export const SPATIAL_WATER_PROTECT: SpatialMetric = {
  ...DEFAULT_METRIC,
  type: "boolean",
  canTotal: false,
  canAverage: false,
  id: "waterProtect",
  validation: MetricValidation.INTERNAL,
  truei18nKey: "utils.descriptors.waterProtectNormal",
  falsei18nKey: "utils.descriptors.waterProtectTriggered",
  renderWhenNotCountable: true,
  colors: {
    absoluteBreakpoints: [0, 1],
    binary: true,
    colors: [COLOR_RED, COLOR_GREEN],
    labels: [
      { i18nKey: "utils.descriptors.waterProtectTriggered" },
      { i18nKey: "utils.descriptors.waterProtectNormal" },
    ],
  },
  getValue: (block) => block.block?.hwMetric?.waterProtect,
};

// add popups (have to do this after all of them are declared)
SPATIAL_COVERAGE.popup = [SPATIAL_WEEDING, SPATIAL_THINNING];
SPATIAL_TIME.popup = [];
SPATIAL_ALTITUDE.popup = [SPATIAL_ALTITUDE];
SPATIAL_PERCENT_BANDED.popup = [SPATIAL_PERCENT_BANDED];
SPATIAL_AVERAGE_TARGETED_REQ_LASER_TIME.popup = [
  SPATIAL_AVERAGE_TARGETED_REQ_LASER_TIME,
  SPATIAL_AVERAGE_UNTARGETED_REQ_LASER_TIME,
];
SPATIAL_AVERAGE_UNTARGETED_REQ_LASER_TIME.popup = [
  SPATIAL_AVERAGE_UNTARGETED_REQ_LASER_TIME,
  SPATIAL_AVERAGE_TARGETED_REQ_LASER_TIME,
];
SPATIAL_AVERAGE_SPEED.popup = [
  SPATIAL_AVERAGE_SPEED,
  SPATIAL_SMOOTHED_TARGET_SPEED,
  SPATIAL_SPEED_EFFICIENCY,
  SPATIAL_MINIMUM_TARGET_SPEED,
  SPATIAL_ROW1_TARGET_SPEED,
  SPATIAL_ROW2_TARGET_SPEED,
  SPATIAL_ROW3_TARGET_SPEED,
];
SPATIAL_SMOOTHED_TARGET_SPEED.popup = [
  SPATIAL_SMOOTHED_TARGET_SPEED,
  SPATIAL_AVERAGE_SPEED,
  SPATIAL_SPEED_EFFICIENCY,
  SPATIAL_MINIMUM_TARGET_SPEED,
  SPATIAL_ROW1_TARGET_SPEED,
  SPATIAL_ROW2_TARGET_SPEED,
  SPATIAL_ROW3_TARGET_SPEED,
];
SPATIAL_MINIMUM_TARGET_SPEED.popup = [
  SPATIAL_MINIMUM_TARGET_SPEED,
  SPATIAL_SMOOTHED_TARGET_SPEED,
  SPATIAL_AVERAGE_SPEED,
  SPATIAL_SPEED_EFFICIENCY,
  SPATIAL_ROW1_TARGET_SPEED,
  SPATIAL_ROW2_TARGET_SPEED,
  SPATIAL_ROW3_TARGET_SPEED,
];
SPATIAL_ROW1_TARGET_SPEED.popup = [
  SPATIAL_ROW1_TARGET_SPEED,
  SPATIAL_ROW2_TARGET_SPEED,
  SPATIAL_ROW3_TARGET_SPEED,
  SPATIAL_MINIMUM_TARGET_SPEED,
  SPATIAL_SMOOTHED_TARGET_SPEED,
  SPATIAL_AVERAGE_SPEED,
  SPATIAL_SPEED_EFFICIENCY,
];
SPATIAL_ROW2_TARGET_SPEED.popup = [
  SPATIAL_ROW2_TARGET_SPEED,
  SPATIAL_ROW1_TARGET_SPEED,
  SPATIAL_ROW3_TARGET_SPEED,
  SPATIAL_MINIMUM_TARGET_SPEED,
  SPATIAL_SMOOTHED_TARGET_SPEED,
  SPATIAL_AVERAGE_SPEED,
  SPATIAL_SPEED_EFFICIENCY,
];
SPATIAL_ROW3_TARGET_SPEED.popup = [
  SPATIAL_ROW3_TARGET_SPEED,
  SPATIAL_ROW1_TARGET_SPEED,
  SPATIAL_ROW2_TARGET_SPEED,
  SPATIAL_MINIMUM_TARGET_SPEED,
  SPATIAL_SMOOTHED_TARGET_SPEED,
  SPATIAL_AVERAGE_SPEED,
  SPATIAL_SPEED_EFFICIENCY,
];
SPATIAL_SPEED_EFFICIENCY.popup = [
  SPATIAL_SPEED_EFFICIENCY,
  SPATIAL_AVERAGE_SPEED,
  SPATIAL_SMOOTHED_TARGET_SPEED,
  SPATIAL_MINIMUM_TARGET_SPEED,
  SPATIAL_ROW1_TARGET_SPEED,
  SPATIAL_ROW2_TARGET_SPEED,
  SPATIAL_ROW3_TARGET_SPEED,
];
SPATIAL_WEEDING.popup = [
  SPATIAL_WEEDING,
  SPATIAL_THINNING,
  SPATIAL_PERCENT_BANDED,
];
SPATIAL_WEEDING_EFFICIENCY.popup = [
  SPATIAL_WEEDING_EFFICIENCY,
  SPATIAL_TOTAL_WEEDS,
  SPATIAL_KILLED_WEEDS,
  SPATIAL_SKIPPED_WEEDS,
  SPATIAL_MISSED_WEEDS,
];
SPATIAL_THINNING.popup = [
  SPATIAL_THINNING,
  SPATIAL_WEEDING,
  SPATIAL_PERCENT_BANDED,
];
SPATIAL_OVERALL_EFFICIENCY.popup = [
  SPATIAL_OVERALL_EFFICIENCY,
  SPATIAL_WEEDING_EFFICIENCY,
  SPATIAL_THINNING_EFFICIENCY,
];
SPATIAL_THINNING_EFFICIENCY.popup = [
  SPATIAL_THINNING_EFFICIENCY,
  SPATIAL_TOTAL_CROPS,
  SPATIAL_TOTAL_VALID_CROPS,
  SPATIAL_KEPT_CROPS,
  SPATIAL_KILLED_CROPS,
  SPATIAL_SKIPPED_CROPS,
  SPATIAL_MISSED_CROPS,
];
SPATIAL_AVERAGE_WEED_SIZE.popup = [
  SPATIAL_AVERAGE_WEED_SIZE,
  SPATIAL_WEED_DENSITY,
  SPATIAL_TOTAL_WEEDS,
];
SPATIAL_AVERAGE_CROP_SIZE.popup = [
  SPATIAL_AVERAGE_CROP_SIZE,
  SPATIAL_CROP_DENSITY,
  SPATIAL_TOTAL_CROPS,
  SPATIAL_TOTAL_VALID_CROPS,
];
SPATIAL_WEED_DENSITY.popup = [
  SPATIAL_WEED_DENSITY,
  SPATIAL_AVERAGE_WEED_SIZE,
  SPATIAL_TOTAL_WEEDS,
  SPATIAL_TOTAL_WEEDS_IN_BAND,
];
SPATIAL_TOTAL_WEEDS.popup = [
  SPATIAL_TOTAL_WEEDS,
  SPATIAL_TOTAL_WEEDS_IN_BAND,
  SPATIAL_WEED_DENSITY,
  SPATIAL_WEEDING_EFFICIENCY,
];
SPATIAL_TOTAL_WEEDS_IN_BAND.popup = [
  SPATIAL_TOTAL_WEEDS_IN_BAND,
  SPATIAL_TOTAL_WEEDS,
  SPATIAL_WEED_DENSITY,
  SPATIAL_WEEDING_EFFICIENCY,
];
SPATIAL_KILLED_WEEDS.popup = [
  SPATIAL_KILLED_WEEDS,
  SPATIAL_WEEDING_EFFICIENCY,
  SPATIAL_TOTAL_WEEDS,
  SPATIAL_TOTAL_WEEDS_IN_BAND,
  SPATIAL_SKIPPED_WEEDS,
  SPATIAL_MISSED_WEEDS,
];
SPATIAL_MISSED_WEEDS.popup = [
  SPATIAL_MISSED_WEEDS,
  SPATIAL_WEEDING_EFFICIENCY,
  SPATIAL_TOTAL_WEEDS,
  SPATIAL_TOTAL_WEEDS_IN_BAND,
  SPATIAL_KILLED_WEEDS,
  SPATIAL_SKIPPED_WEEDS,
];
SPATIAL_SKIPPED_WEEDS.popup = [
  SPATIAL_SKIPPED_WEEDS,
  SPATIAL_WEEDING_EFFICIENCY,
  SPATIAL_TOTAL_WEEDS,
  SPATIAL_TOTAL_WEEDS_IN_BAND,
  SPATIAL_KILLED_WEEDS,
  SPATIAL_MISSED_WEEDS,
];
SPATIAL_BROADLEAF.popup = [
  SPATIAL_BROADLEAF,
  SPATIAL_GRASS,
  SPATIAL_OFFSHOOT,
  SPATIAL_PURSLANE,
  SPATIAL_TOTAL_WEEDS,
];
SPATIAL_GRASS.popup = [
  SPATIAL_GRASS,
  SPATIAL_BROADLEAF,
  SPATIAL_OFFSHOOT,
  SPATIAL_PURSLANE,
  SPATIAL_TOTAL_WEEDS,
];
SPATIAL_OFFSHOOT.popup = [
  SPATIAL_OFFSHOOT,
  SPATIAL_BROADLEAF,
  SPATIAL_GRASS,
  SPATIAL_PURSLANE,
  SPATIAL_TOTAL_WEEDS,
];
SPATIAL_PURSLANE.popup = [
  SPATIAL_PURSLANE,
  SPATIAL_BROADLEAF,
  SPATIAL_GRASS,
  SPATIAL_OFFSHOOT,
  SPATIAL_TOTAL_WEEDS,
];
SPATIAL_AVERAGE_CROP_SIZE.popup = [
  SPATIAL_AVERAGE_CROP_SIZE,
  SPATIAL_CROP_DENSITY,
  SPATIAL_TOTAL_CROPS,
  SPATIAL_TOTAL_VALID_CROPS,
  SPATIAL_KEPT_CROPS,
];
SPATIAL_CROP_DENSITY.popup = [
  SPATIAL_CROP_DENSITY,
  SPATIAL_KEPT_CROP_DENSITY,
  SPATIAL_AVERAGE_CROP_SIZE,
  SPATIAL_TOTAL_CROPS,
  SPATIAL_TOTAL_VALID_CROPS,
];
SPATIAL_KEPT_CROP_DENSITY.popup = [
  SPATIAL_KEPT_CROP_DENSITY,
  SPATIAL_CROP_DENSITY,
  SPATIAL_AVERAGE_CROP_SIZE,
  SPATIAL_KEPT_CROPS,
  SPATIAL_TOTAL_CROPS,
  SPATIAL_TOTAL_VALID_CROPS,
];
SPATIAL_TOTAL_CROPS.popup = [
  SPATIAL_TOTAL_CROPS,
  SPATIAL_TOTAL_VALID_CROPS,
  SPATIAL_THINNING_EFFICIENCY,
  SPATIAL_KEPT_CROPS,
  SPATIAL_SKIPPED_CROPS,
  SPATIAL_MISSED_CROPS,
];
SPATIAL_KILLED_CROPS.popup = [
  SPATIAL_KILLED_CROPS,
  SPATIAL_THINNING_EFFICIENCY,
  SPATIAL_TOTAL_CROPS,
  SPATIAL_TOTAL_VALID_CROPS,
  SPATIAL_KEPT_CROPS,
  SPATIAL_SKIPPED_CROPS,
  SPATIAL_MISSED_CROPS,
];
SPATIAL_MISSED_CROPS.popup = [
  SPATIAL_MISSED_CROPS,
  SPATIAL_THINNING_EFFICIENCY,
  SPATIAL_TOTAL_CROPS,
  SPATIAL_TOTAL_VALID_CROPS,
  SPATIAL_KEPT_CROPS,
  SPATIAL_KILLED_CROPS,
  SPATIAL_SKIPPED_CROPS,
];
SPATIAL_SKIPPED_CROPS.popup = [
  SPATIAL_SKIPPED_CROPS,
  SPATIAL_THINNING_EFFICIENCY,
  SPATIAL_TOTAL_CROPS,
  SPATIAL_TOTAL_VALID_CROPS,
  SPATIAL_KEPT_CROPS,
  SPATIAL_KILLED_CROPS,
  SPATIAL_MISSED_CROPS,
];
SPATIAL_KEPT_CROPS.popup = [
  SPATIAL_KEPT_CROPS,
  SPATIAL_KEPT_CROP_DENSITY,
  SPATIAL_THINNING_EFFICIENCY,
  SPATIAL_TOTAL_CROPS,
  SPATIAL_TOTAL_VALID_CROPS,
  SPATIAL_KILLED_CROPS,
  SPATIAL_SKIPPED_CROPS,
  SPATIAL_MISSED_CROPS,
];
SPATIAL_LIFTED.popup = [
  SPATIAL_LIFTED,
  SPATIAL_ESTOPPED,
  SPATIAL_LASER_KEY,
  SPATIAL_INTERLOCK,
  SPATIAL_WATER_PROTECT,
];
SPATIAL_ESTOPPED.popup = [
  SPATIAL_ESTOPPED,
  SPATIAL_LIFTED,
  SPATIAL_LASER_KEY,
  SPATIAL_INTERLOCK,
  SPATIAL_WATER_PROTECT,
];
SPATIAL_LASER_KEY.popup = [
  SPATIAL_LASER_KEY,
  SPATIAL_LIFTED,
  SPATIAL_ESTOPPED,
  SPATIAL_INTERLOCK,
  SPATIAL_WATER_PROTECT,
];
SPATIAL_INTERLOCK.popup = [
  SPATIAL_INTERLOCK,
  SPATIAL_LIFTED,
  SPATIAL_ESTOPPED,
  SPATIAL_LASER_KEY,
  SPATIAL_WATER_PROTECT,
];
SPATIAL_WATER_PROTECT.popup = [
  SPATIAL_WATER_PROTECT,
  SPATIAL_LIFTED,
  SPATIAL_ESTOPPED,
  SPATIAL_LASER_KEY,
  SPATIAL_INTERLOCK,
];

export const SPATIAL_WEEDING_METRICS: SpatialMetric[] = [
  SPATIAL_KILLED_WEEDS,
  SPATIAL_MISSED_WEEDS,
  SPATIAL_SKIPPED_WEEDS,
];

export const SPATIAL_THINNING_METRICS: SpatialMetric[] = [
  SPATIAL_KEPT_CROPS,
  SPATIAL_KEPT_CROP_DENSITY,
  SPATIAL_KILLED_CROPS,
  SPATIAL_MISSED_CROPS,
  SPATIAL_SKIPPED_CROPS,
];

export const SPATIAL_DEFAULT = SPATIAL_AVERAGE_SPEED;

export const SPATIAL_METRICS = [
  SPATIAL_COVERAGE,

  // field
  SPATIAL_TIME,
  SPATIAL_ALTITUDE,
  SPATIAL_PERCENT_BANDED,
  SPATIAL_AVERAGE_SPEED,
  SPATIAL_SMOOTHED_TARGET_SPEED,
  SPATIAL_MINIMUM_TARGET_SPEED,
  SPATIAL_ROW1_TARGET_SPEED,
  SPATIAL_ROW2_TARGET_SPEED,
  SPATIAL_ROW3_TARGET_SPEED,
  SPATIAL_SPEED_EFFICIENCY,
  SPATIAL_WEEDING,
  SPATIAL_WEEDING_EFFICIENCY,
  SPATIAL_THINNING,
  SPATIAL_THINNING_EFFICIENCY,
  SPATIAL_OVERALL_EFFICIENCY,
  SPATIAL_AVERAGE_TARGETED_REQ_LASER_TIME,
  SPATIAL_AVERAGE_UNTARGETED_REQ_LASER_TIME,

  // weeds
  SPATIAL_AVERAGE_WEED_SIZE,
  SPATIAL_WEED_DENSITY,
  SPATIAL_TOTAL_WEEDS,
  SPATIAL_TOTAL_WEEDS_IN_BAND,
  SPATIAL_KILLED_WEEDS,
  SPATIAL_MISSED_WEEDS,
  SPATIAL_SKIPPED_WEEDS,
  SPATIAL_BROADLEAF,
  SPATIAL_GRASS,
  SPATIAL_OFFSHOOT,
  SPATIAL_PURSLANE,

  // crops
  SPATIAL_AVERAGE_CROP_SIZE,
  SPATIAL_CROP_DENSITY,
  SPATIAL_KEPT_CROP_DENSITY,
  SPATIAL_TOTAL_CROPS,
  SPATIAL_TOTAL_VALID_CROPS,
  SPATIAL_KEPT_CROPS,
  SPATIAL_KILLED_CROPS,
  SPATIAL_MISSED_CROPS,
  SPATIAL_SKIPPED_CROPS,

  // hardware
  SPATIAL_LIFTED,
  SPATIAL_ESTOPPED,
  SPATIAL_LASER_KEY,
  SPATIAL_INTERLOCK,
  SPATIAL_WATER_PROTECT,
];

export const VALIDATED_SPATIAL_METRICS = SPATIAL_METRICS.filter(
  (metric) => metric.validation === MetricValidation.VALIDATED
);

// crops
export interface SpatialMetricGroup {
  i18nKey: ParseKeys;
  metrics: SpatialMetric[];
}

export const SPATIAL_METRIC_GROUPS: SpatialMetricGroup[] = [
  {
    i18nKey: "utils.metrics.groups.speed",
    metrics: [
      SPATIAL_SPEED_EFFICIENCY,
      SPATIAL_AVERAGE_SPEED,
      SPATIAL_SMOOTHED_TARGET_SPEED,
      SPATIAL_MINIMUM_TARGET_SPEED,
      SPATIAL_ROW1_TARGET_SPEED,
      SPATIAL_ROW2_TARGET_SPEED,
      SPATIAL_ROW3_TARGET_SPEED,
      SPATIAL_PERCENT_BANDED,
    ],
  },
  {
    i18nKey: "utils.metrics.groups.performance",
    metrics: [
      SPATIAL_OVERALL_EFFICIENCY,
      SPATIAL_WEEDING_EFFICIENCY,
      SPATIAL_WEEDING,
      SPATIAL_THINNING_EFFICIENCY,
      SPATIAL_THINNING,
      SPATIAL_AVERAGE_TARGETED_REQ_LASER_TIME,
      SPATIAL_AVERAGE_UNTARGETED_REQ_LASER_TIME,
    ],
  },
  {
    i18nKey: "models.weeds.weed_other",
    metrics: [
      SPATIAL_WEED_DENSITY,
      SPATIAL_AVERAGE_WEED_SIZE,
      SPATIAL_TOTAL_WEEDS,
      SPATIAL_KILLED_WEEDS,
      SPATIAL_MISSED_WEEDS,
      SPATIAL_SKIPPED_WEEDS,
      SPATIAL_BROADLEAF,
      SPATIAL_GRASS,
      SPATIAL_OFFSHOOT,
      SPATIAL_PURSLANE,
    ],
  },
  {
    i18nKey: "models.crops.crop_other",
    metrics: [
      SPATIAL_CROP_DENSITY,
      SPATIAL_KEPT_CROP_DENSITY,
      SPATIAL_AVERAGE_CROP_SIZE,
      SPATIAL_TOTAL_CROPS,
      SPATIAL_TOTAL_VALID_CROPS,
      SPATIAL_KEPT_CROPS,
      SPATIAL_KILLED_CROPS,
      SPATIAL_MISSED_CROPS,
      SPATIAL_SKIPPED_CROPS,
    ],
  },
  {
    i18nKey: "utils.metrics.groups.field",
    metrics: [SPATIAL_ALTITUDE],
  },
  {
    i18nKey: "views.fleet.robots.hardware.title",
    metrics: [
      SPATIAL_LIFTED,
      SPATIAL_ESTOPPED,
      SPATIAL_LASER_KEY,
      SPATIAL_INTERLOCK,
      SPATIAL_WATER_PROTECT,
    ],
  },
];
