import { useState } from "react";

/**
 * Hook that shadows a prop from component state
 * Its value is unconditionally overwritten by the input prop when it changes
 * But it can also be set manually by the component
 *
 * This is useful when the component needs to modify the prop internally to
 * support a wider range of values than the parent component can handle (for
 * example, allowing blanks temporarily in the input)
 *
 * Also returns a `skipRender` option if the prop was changed this render
 * This can be used to short-circuit the render in the component that will
 * need to be redone
 *
 **/
export const useShadowValue = <T>(
  input: T
): [T, (value: T) => void, { skipRender: boolean }] => {
  // this is the value and setter we expose to the caller
  const [shadowedValue, setShadowedValue] = useState<T>(input);

  // keep track of the last unique input value
  const [lastInput, setLastInput] = useState<T>(input);
  // if the input changes update the shadowed value unconditionally
  if (input !== lastInput) {
    setLastInput(input);
    setShadowedValue(input);
    // let the caller know that they can skip the render
    return [input, setShadowedValue, { skipRender: true }];
  }

  return [shadowedValue, setShadowedValue, { skipRender: false }];
};
