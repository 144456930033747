// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.19.4
// source: portal/admin.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { DB } from "./db";

export const protobufPackage = "carbon.portal.admin";

export interface GlobalAllowedAlarm {
  db: DB | undefined;
  code: string;
}

export interface RobotAllowedAlarm {
  db: DB | undefined;
  code: string;
  robotId: number;
}

export interface RobotBlockedAlarm {
  db: DB | undefined;
  code: string;
  robotId: number;
}

export interface GlobalAlarmAllowlist {
  alarms: GlobalAllowedAlarm[];
}

export interface RobotAlarmAllowlist {
  alarms: RobotAllowedAlarm[];
}

export interface RobotAlarmBlocklist {
  alarms: RobotBlockedAlarm[];
}

export interface GlobalAlarmlists {
  allowed: GlobalAlarmAllowlist | undefined;
}

export interface RobotAlarmlists {
  allowed: RobotAlarmAllowlist | undefined;
  blocked: RobotAlarmBlocklist | undefined;
}

export interface Maintenance {
  db: DB | undefined;
  message: string;
  restrictAccess: boolean;
}

function createBaseGlobalAllowedAlarm(): GlobalAllowedAlarm {
  return { db: undefined, code: "" };
}

export const GlobalAllowedAlarm: MessageFns<GlobalAllowedAlarm> = {
  encode(message: GlobalAllowedAlarm, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.db !== undefined) {
      DB.encode(message.db, writer.uint32(10).fork()).join();
    }
    if (message.code !== "") {
      writer.uint32(18).string(message.code);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GlobalAllowedAlarm {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGlobalAllowedAlarm();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.db = DB.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.code = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GlobalAllowedAlarm {
    return {
      db: isSet(object.db) ? DB.fromJSON(object.db) : undefined,
      code: isSet(object.code) ? globalThis.String(object.code) : "",
    };
  },

  toJSON(message: GlobalAllowedAlarm): unknown {
    const obj: any = {};
    if (message.db !== undefined) {
      obj.db = DB.toJSON(message.db);
    }
    if (message.code !== "") {
      obj.code = message.code;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GlobalAllowedAlarm>, I>>(base?: I): GlobalAllowedAlarm {
    return GlobalAllowedAlarm.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GlobalAllowedAlarm>, I>>(object: I): GlobalAllowedAlarm {
    const message = createBaseGlobalAllowedAlarm();
    message.db = (object.db !== undefined && object.db !== null) ? DB.fromPartial(object.db) : undefined;
    message.code = object.code ?? "";
    return message;
  },
};

function createBaseRobotAllowedAlarm(): RobotAllowedAlarm {
  return { db: undefined, code: "", robotId: 0 };
}

export const RobotAllowedAlarm: MessageFns<RobotAllowedAlarm> = {
  encode(message: RobotAllowedAlarm, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.db !== undefined) {
      DB.encode(message.db, writer.uint32(10).fork()).join();
    }
    if (message.code !== "") {
      writer.uint32(18).string(message.code);
    }
    if (message.robotId !== 0) {
      writer.uint32(24).int64(message.robotId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RobotAllowedAlarm {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRobotAllowedAlarm();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.db = DB.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.code = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.robotId = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RobotAllowedAlarm {
    return {
      db: isSet(object.db) ? DB.fromJSON(object.db) : undefined,
      code: isSet(object.code) ? globalThis.String(object.code) : "",
      robotId: isSet(object.robotId) ? globalThis.Number(object.robotId) : 0,
    };
  },

  toJSON(message: RobotAllowedAlarm): unknown {
    const obj: any = {};
    if (message.db !== undefined) {
      obj.db = DB.toJSON(message.db);
    }
    if (message.code !== "") {
      obj.code = message.code;
    }
    if (message.robotId !== 0) {
      obj.robotId = Math.round(message.robotId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RobotAllowedAlarm>, I>>(base?: I): RobotAllowedAlarm {
    return RobotAllowedAlarm.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RobotAllowedAlarm>, I>>(object: I): RobotAllowedAlarm {
    const message = createBaseRobotAllowedAlarm();
    message.db = (object.db !== undefined && object.db !== null) ? DB.fromPartial(object.db) : undefined;
    message.code = object.code ?? "";
    message.robotId = object.robotId ?? 0;
    return message;
  },
};

function createBaseRobotBlockedAlarm(): RobotBlockedAlarm {
  return { db: undefined, code: "", robotId: 0 };
}

export const RobotBlockedAlarm: MessageFns<RobotBlockedAlarm> = {
  encode(message: RobotBlockedAlarm, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.db !== undefined) {
      DB.encode(message.db, writer.uint32(10).fork()).join();
    }
    if (message.code !== "") {
      writer.uint32(18).string(message.code);
    }
    if (message.robotId !== 0) {
      writer.uint32(24).int64(message.robotId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RobotBlockedAlarm {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRobotBlockedAlarm();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.db = DB.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.code = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.robotId = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RobotBlockedAlarm {
    return {
      db: isSet(object.db) ? DB.fromJSON(object.db) : undefined,
      code: isSet(object.code) ? globalThis.String(object.code) : "",
      robotId: isSet(object.robotId) ? globalThis.Number(object.robotId) : 0,
    };
  },

  toJSON(message: RobotBlockedAlarm): unknown {
    const obj: any = {};
    if (message.db !== undefined) {
      obj.db = DB.toJSON(message.db);
    }
    if (message.code !== "") {
      obj.code = message.code;
    }
    if (message.robotId !== 0) {
      obj.robotId = Math.round(message.robotId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RobotBlockedAlarm>, I>>(base?: I): RobotBlockedAlarm {
    return RobotBlockedAlarm.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RobotBlockedAlarm>, I>>(object: I): RobotBlockedAlarm {
    const message = createBaseRobotBlockedAlarm();
    message.db = (object.db !== undefined && object.db !== null) ? DB.fromPartial(object.db) : undefined;
    message.code = object.code ?? "";
    message.robotId = object.robotId ?? 0;
    return message;
  },
};

function createBaseGlobalAlarmAllowlist(): GlobalAlarmAllowlist {
  return { alarms: [] };
}

export const GlobalAlarmAllowlist: MessageFns<GlobalAlarmAllowlist> = {
  encode(message: GlobalAlarmAllowlist, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.alarms) {
      GlobalAllowedAlarm.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GlobalAlarmAllowlist {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGlobalAlarmAllowlist();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.alarms.push(GlobalAllowedAlarm.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GlobalAlarmAllowlist {
    return {
      alarms: globalThis.Array.isArray(object?.alarms)
        ? object.alarms.map((e: any) => GlobalAllowedAlarm.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GlobalAlarmAllowlist): unknown {
    const obj: any = {};
    if (message.alarms?.length) {
      obj.alarms = message.alarms.map((e) => GlobalAllowedAlarm.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GlobalAlarmAllowlist>, I>>(base?: I): GlobalAlarmAllowlist {
    return GlobalAlarmAllowlist.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GlobalAlarmAllowlist>, I>>(object: I): GlobalAlarmAllowlist {
    const message = createBaseGlobalAlarmAllowlist();
    message.alarms = object.alarms?.map((e) => GlobalAllowedAlarm.fromPartial(e)) || [];
    return message;
  },
};

function createBaseRobotAlarmAllowlist(): RobotAlarmAllowlist {
  return { alarms: [] };
}

export const RobotAlarmAllowlist: MessageFns<RobotAlarmAllowlist> = {
  encode(message: RobotAlarmAllowlist, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.alarms) {
      RobotAllowedAlarm.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RobotAlarmAllowlist {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRobotAlarmAllowlist();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.alarms.push(RobotAllowedAlarm.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RobotAlarmAllowlist {
    return {
      alarms: globalThis.Array.isArray(object?.alarms)
        ? object.alarms.map((e: any) => RobotAllowedAlarm.fromJSON(e))
        : [],
    };
  },

  toJSON(message: RobotAlarmAllowlist): unknown {
    const obj: any = {};
    if (message.alarms?.length) {
      obj.alarms = message.alarms.map((e) => RobotAllowedAlarm.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RobotAlarmAllowlist>, I>>(base?: I): RobotAlarmAllowlist {
    return RobotAlarmAllowlist.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RobotAlarmAllowlist>, I>>(object: I): RobotAlarmAllowlist {
    const message = createBaseRobotAlarmAllowlist();
    message.alarms = object.alarms?.map((e) => RobotAllowedAlarm.fromPartial(e)) || [];
    return message;
  },
};

function createBaseRobotAlarmBlocklist(): RobotAlarmBlocklist {
  return { alarms: [] };
}

export const RobotAlarmBlocklist: MessageFns<RobotAlarmBlocklist> = {
  encode(message: RobotAlarmBlocklist, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.alarms) {
      RobotBlockedAlarm.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RobotAlarmBlocklist {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRobotAlarmBlocklist();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.alarms.push(RobotBlockedAlarm.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RobotAlarmBlocklist {
    return {
      alarms: globalThis.Array.isArray(object?.alarms)
        ? object.alarms.map((e: any) => RobotBlockedAlarm.fromJSON(e))
        : [],
    };
  },

  toJSON(message: RobotAlarmBlocklist): unknown {
    const obj: any = {};
    if (message.alarms?.length) {
      obj.alarms = message.alarms.map((e) => RobotBlockedAlarm.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RobotAlarmBlocklist>, I>>(base?: I): RobotAlarmBlocklist {
    return RobotAlarmBlocklist.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RobotAlarmBlocklist>, I>>(object: I): RobotAlarmBlocklist {
    const message = createBaseRobotAlarmBlocklist();
    message.alarms = object.alarms?.map((e) => RobotBlockedAlarm.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGlobalAlarmlists(): GlobalAlarmlists {
  return { allowed: undefined };
}

export const GlobalAlarmlists: MessageFns<GlobalAlarmlists> = {
  encode(message: GlobalAlarmlists, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.allowed !== undefined) {
      GlobalAlarmAllowlist.encode(message.allowed, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GlobalAlarmlists {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGlobalAlarmlists();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.allowed = GlobalAlarmAllowlist.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GlobalAlarmlists {
    return { allowed: isSet(object.allowed) ? GlobalAlarmAllowlist.fromJSON(object.allowed) : undefined };
  },

  toJSON(message: GlobalAlarmlists): unknown {
    const obj: any = {};
    if (message.allowed !== undefined) {
      obj.allowed = GlobalAlarmAllowlist.toJSON(message.allowed);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GlobalAlarmlists>, I>>(base?: I): GlobalAlarmlists {
    return GlobalAlarmlists.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GlobalAlarmlists>, I>>(object: I): GlobalAlarmlists {
    const message = createBaseGlobalAlarmlists();
    message.allowed = (object.allowed !== undefined && object.allowed !== null)
      ? GlobalAlarmAllowlist.fromPartial(object.allowed)
      : undefined;
    return message;
  },
};

function createBaseRobotAlarmlists(): RobotAlarmlists {
  return { allowed: undefined, blocked: undefined };
}

export const RobotAlarmlists: MessageFns<RobotAlarmlists> = {
  encode(message: RobotAlarmlists, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.allowed !== undefined) {
      RobotAlarmAllowlist.encode(message.allowed, writer.uint32(10).fork()).join();
    }
    if (message.blocked !== undefined) {
      RobotAlarmBlocklist.encode(message.blocked, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RobotAlarmlists {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRobotAlarmlists();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.allowed = RobotAlarmAllowlist.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.blocked = RobotAlarmBlocklist.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RobotAlarmlists {
    return {
      allowed: isSet(object.allowed) ? RobotAlarmAllowlist.fromJSON(object.allowed) : undefined,
      blocked: isSet(object.blocked) ? RobotAlarmBlocklist.fromJSON(object.blocked) : undefined,
    };
  },

  toJSON(message: RobotAlarmlists): unknown {
    const obj: any = {};
    if (message.allowed !== undefined) {
      obj.allowed = RobotAlarmAllowlist.toJSON(message.allowed);
    }
    if (message.blocked !== undefined) {
      obj.blocked = RobotAlarmBlocklist.toJSON(message.blocked);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RobotAlarmlists>, I>>(base?: I): RobotAlarmlists {
    return RobotAlarmlists.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RobotAlarmlists>, I>>(object: I): RobotAlarmlists {
    const message = createBaseRobotAlarmlists();
    message.allowed = (object.allowed !== undefined && object.allowed !== null)
      ? RobotAlarmAllowlist.fromPartial(object.allowed)
      : undefined;
    message.blocked = (object.blocked !== undefined && object.blocked !== null)
      ? RobotAlarmBlocklist.fromPartial(object.blocked)
      : undefined;
    return message;
  },
};

function createBaseMaintenance(): Maintenance {
  return { db: undefined, message: "", restrictAccess: false };
}

export const Maintenance: MessageFns<Maintenance> = {
  encode(message: Maintenance, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.db !== undefined) {
      DB.encode(message.db, writer.uint32(10).fork()).join();
    }
    if (message.message !== "") {
      writer.uint32(18).string(message.message);
    }
    if (message.restrictAccess !== false) {
      writer.uint32(24).bool(message.restrictAccess);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Maintenance {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMaintenance();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.db = DB.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.restrictAccess = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Maintenance {
    return {
      db: isSet(object.db) ? DB.fromJSON(object.db) : undefined,
      message: isSet(object.message) ? globalThis.String(object.message) : "",
      restrictAccess: isSet(object.restrictAccess) ? globalThis.Boolean(object.restrictAccess) : false,
    };
  },

  toJSON(message: Maintenance): unknown {
    const obj: any = {};
    if (message.db !== undefined) {
      obj.db = DB.toJSON(message.db);
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    if (message.restrictAccess !== false) {
      obj.restrictAccess = message.restrictAccess;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Maintenance>, I>>(base?: I): Maintenance {
    return Maintenance.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Maintenance>, I>>(object: I): Maintenance {
    const message = createBaseMaintenance();
    message.db = (object.db !== undefined && object.db !== null) ? DB.fromPartial(object.db) : undefined;
    message.message = object.message ?? "";
    message.restrictAccess = object.restrictAccess ?? false;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
