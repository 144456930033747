import { Button } from "@mui/material";
import { formatModelinator } from "portal/components/modelinator/formatModelinator";
import { GREEN_BUTTON, WHITE_BUTTON } from "portal/utils/theme";
import { useTranslation } from "react-i18next";
import { useUnmountEffect } from "portal/utils/hooks/useUnmountEffect";
import CopyIcon from "@mui/icons-material/ContentCopyOutlined";
import React, { FunctionComponent, useRef, useState } from "react";
import type { Crop } from "protos/portal/veselka";
import type { PartitionedModelinator } from "portal/utils/modelinator";

/**
 * A button that the user can click to format a human-readable text description
 * of the modelinator and copy it to the clipboard.
 */
interface Props {
  modelinators: PartitionedModelinator | undefined;
  crops: Crop[] | undefined;
  hasUnsavedChanges: boolean;
}
export const ExportModelinatorButton: FunctionComponent<Props> = ({
  modelinators,
  crops,
  hasUnsavedChanges,
}) => {
  const { t, i18n } = useTranslation();

  const [copyId, setCopyId] = useState<number | undefined>(undefined);
  const wasCopied = copyId !== undefined;
  const nextCopyIdRef = useRef<number>(0);

  const isMountedRef = useRef<boolean>(true);
  useUnmountEffect(() => {
    isMountedRef.current = false;
  });

  const copyToClipboard =
    modelinators &&
    crops &&
    ((): void => {
      const text = formatModelinator(
        t,
        i18n,
        modelinators,
        crops,
        hasUnsavedChanges
      );
      navigator.clipboard.writeText(text);

      // show success state for 2 seconds
      const copyId = ++nextCopyIdRef.current;
      setCopyId(copyId);
      setTimeout(() => {
        if (isMountedRef.current && nextCopyIdRef.current === copyId) {
          setCopyId(undefined);
        }
      }, 2 * 1000);
    });

  const theme = wasCopied ? GREEN_BUTTON : WHITE_BUTTON;
  const strings = {
    copy: t("components.discriminator.configs.copy"),
    copied: t("components.CopyToClipboardButton.copied"),
  };
  return (
    <Button
      {...theme}
      startIcon={<CopyIcon />}
      disabled={!copyToClipboard}
      onClick={copyToClipboard}
    >
      <div className="flex flex-col items-stretch">
        {/* hidden spans to make the button wide enough for both states */}
        <span className="h-0 invisible">{strings.copy}</span>
        <span className="h-0 invisible">{strings.copied}</span>
        {/* actual span with current state */}
        <span>{wasCopied ? strings.copied : strings.copy}</span>
      </div>
    </Button>
  );
};
