// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.19.4
// source: portal/jobs.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Job } from "../frontend/jobs";
import { ConfigNodeSnapshot } from "../frontend/weeding_diagnostics";
import { Metrics } from "../metrics/metrics_aggregator_service";
import { DB } from "./db";
import { DailyMetricResponse } from "./metrics";

export const protobufPackage = "carbon.portal.jobs";

export interface PortalJob {
  db: DB | undefined;
  robotId: number;
  jobId: string;
  name: string;
  timestampMs: number;
  bandingProfile: string;
  thinningProfile: string;
  stopTimestampMs: number;
  acreage: number;
  completed: boolean;
  cropId: string;
  almanac: string;
  discriminator: string;
  metrics: DailyMetricResponse | undefined;
  crop: string;
  serial: string;
}

export interface UploadJobRequest {
  job: Job | undefined;
  robot: string;
}

export interface UploadJobConfigDumpRequest {
  jobId: string;
  rootConfig: ConfigNodeSnapshot | undefined;
}

export interface UploadJobMetricsRequest {
  jobId: string;
  jobMetrics: Metrics | undefined;
}

function createBasePortalJob(): PortalJob {
  return {
    db: undefined,
    robotId: 0,
    jobId: "",
    name: "",
    timestampMs: 0,
    bandingProfile: "",
    thinningProfile: "",
    stopTimestampMs: 0,
    acreage: 0,
    completed: false,
    cropId: "",
    almanac: "",
    discriminator: "",
    metrics: undefined,
    crop: "",
    serial: "",
  };
}

export const PortalJob: MessageFns<PortalJob> = {
  encode(message: PortalJob, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.db !== undefined) {
      DB.encode(message.db, writer.uint32(10).fork()).join();
    }
    if (message.robotId !== 0) {
      writer.uint32(16).uint64(message.robotId);
    }
    if (message.jobId !== "") {
      writer.uint32(26).string(message.jobId);
    }
    if (message.name !== "") {
      writer.uint32(34).string(message.name);
    }
    if (message.timestampMs !== 0) {
      writer.uint32(40).int64(message.timestampMs);
    }
    if (message.bandingProfile !== "") {
      writer.uint32(50).string(message.bandingProfile);
    }
    if (message.thinningProfile !== "") {
      writer.uint32(58).string(message.thinningProfile);
    }
    if (message.stopTimestampMs !== 0) {
      writer.uint32(64).int64(message.stopTimestampMs);
    }
    if (message.acreage !== 0) {
      writer.uint32(77).float(message.acreage);
    }
    if (message.completed !== false) {
      writer.uint32(80).bool(message.completed);
    }
    if (message.cropId !== "") {
      writer.uint32(90).string(message.cropId);
    }
    if (message.almanac !== "") {
      writer.uint32(98).string(message.almanac);
    }
    if (message.discriminator !== "") {
      writer.uint32(106).string(message.discriminator);
    }
    if (message.metrics !== undefined) {
      DailyMetricResponse.encode(message.metrics, writer.uint32(114).fork()).join();
    }
    if (message.crop !== "") {
      writer.uint32(122).string(message.crop);
    }
    if (message.serial !== "") {
      writer.uint32(130).string(message.serial);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PortalJob {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePortalJob();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.db = DB.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.robotId = longToNumber(reader.uint64());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.jobId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.name = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.timestampMs = longToNumber(reader.int64());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.bandingProfile = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.thinningProfile = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.stopTimestampMs = longToNumber(reader.int64());
          continue;
        case 9:
          if (tag !== 77) {
            break;
          }

          message.acreage = reader.float();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.completed = reader.bool();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.cropId = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.almanac = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.discriminator = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.metrics = DailyMetricResponse.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.crop = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.serial = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PortalJob {
    return {
      db: isSet(object.db) ? DB.fromJSON(object.db) : undefined,
      robotId: isSet(object.robotId) ? globalThis.Number(object.robotId) : 0,
      jobId: isSet(object.jobId) ? globalThis.String(object.jobId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      timestampMs: isSet(object.timestampMs) ? globalThis.Number(object.timestampMs) : 0,
      bandingProfile: isSet(object.bandingProfile) ? globalThis.String(object.bandingProfile) : "",
      thinningProfile: isSet(object.thinningProfile) ? globalThis.String(object.thinningProfile) : "",
      stopTimestampMs: isSet(object.stopTimestampMs) ? globalThis.Number(object.stopTimestampMs) : 0,
      acreage: isSet(object.acreage) ? globalThis.Number(object.acreage) : 0,
      completed: isSet(object.completed) ? globalThis.Boolean(object.completed) : false,
      cropId: isSet(object.cropId) ? globalThis.String(object.cropId) : "",
      almanac: isSet(object.almanac) ? globalThis.String(object.almanac) : "",
      discriminator: isSet(object.discriminator) ? globalThis.String(object.discriminator) : "",
      metrics: isSet(object.metrics) ? DailyMetricResponse.fromJSON(object.metrics) : undefined,
      crop: isSet(object.crop) ? globalThis.String(object.crop) : "",
      serial: isSet(object.serial) ? globalThis.String(object.serial) : "",
    };
  },

  toJSON(message: PortalJob): unknown {
    const obj: any = {};
    if (message.db !== undefined) {
      obj.db = DB.toJSON(message.db);
    }
    if (message.robotId !== 0) {
      obj.robotId = Math.round(message.robotId);
    }
    if (message.jobId !== "") {
      obj.jobId = message.jobId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.timestampMs !== 0) {
      obj.timestampMs = Math.round(message.timestampMs);
    }
    if (message.bandingProfile !== "") {
      obj.bandingProfile = message.bandingProfile;
    }
    if (message.thinningProfile !== "") {
      obj.thinningProfile = message.thinningProfile;
    }
    if (message.stopTimestampMs !== 0) {
      obj.stopTimestampMs = Math.round(message.stopTimestampMs);
    }
    if (message.acreage !== 0) {
      obj.acreage = message.acreage;
    }
    if (message.completed !== false) {
      obj.completed = message.completed;
    }
    if (message.cropId !== "") {
      obj.cropId = message.cropId;
    }
    if (message.almanac !== "") {
      obj.almanac = message.almanac;
    }
    if (message.discriminator !== "") {
      obj.discriminator = message.discriminator;
    }
    if (message.metrics !== undefined) {
      obj.metrics = DailyMetricResponse.toJSON(message.metrics);
    }
    if (message.crop !== "") {
      obj.crop = message.crop;
    }
    if (message.serial !== "") {
      obj.serial = message.serial;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PortalJob>, I>>(base?: I): PortalJob {
    return PortalJob.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PortalJob>, I>>(object: I): PortalJob {
    const message = createBasePortalJob();
    message.db = (object.db !== undefined && object.db !== null) ? DB.fromPartial(object.db) : undefined;
    message.robotId = object.robotId ?? 0;
    message.jobId = object.jobId ?? "";
    message.name = object.name ?? "";
    message.timestampMs = object.timestampMs ?? 0;
    message.bandingProfile = object.bandingProfile ?? "";
    message.thinningProfile = object.thinningProfile ?? "";
    message.stopTimestampMs = object.stopTimestampMs ?? 0;
    message.acreage = object.acreage ?? 0;
    message.completed = object.completed ?? false;
    message.cropId = object.cropId ?? "";
    message.almanac = object.almanac ?? "";
    message.discriminator = object.discriminator ?? "";
    message.metrics = (object.metrics !== undefined && object.metrics !== null)
      ? DailyMetricResponse.fromPartial(object.metrics)
      : undefined;
    message.crop = object.crop ?? "";
    message.serial = object.serial ?? "";
    return message;
  },
};

function createBaseUploadJobRequest(): UploadJobRequest {
  return { job: undefined, robot: "" };
}

export const UploadJobRequest: MessageFns<UploadJobRequest> = {
  encode(message: UploadJobRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.job !== undefined) {
      Job.encode(message.job, writer.uint32(10).fork()).join();
    }
    if (message.robot !== "") {
      writer.uint32(18).string(message.robot);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UploadJobRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadJobRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.job = Job.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.robot = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UploadJobRequest {
    return {
      job: isSet(object.job) ? Job.fromJSON(object.job) : undefined,
      robot: isSet(object.robot) ? globalThis.String(object.robot) : "",
    };
  },

  toJSON(message: UploadJobRequest): unknown {
    const obj: any = {};
    if (message.job !== undefined) {
      obj.job = Job.toJSON(message.job);
    }
    if (message.robot !== "") {
      obj.robot = message.robot;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UploadJobRequest>, I>>(base?: I): UploadJobRequest {
    return UploadJobRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UploadJobRequest>, I>>(object: I): UploadJobRequest {
    const message = createBaseUploadJobRequest();
    message.job = (object.job !== undefined && object.job !== null) ? Job.fromPartial(object.job) : undefined;
    message.robot = object.robot ?? "";
    return message;
  },
};

function createBaseUploadJobConfigDumpRequest(): UploadJobConfigDumpRequest {
  return { jobId: "", rootConfig: undefined };
}

export const UploadJobConfigDumpRequest: MessageFns<UploadJobConfigDumpRequest> = {
  encode(message: UploadJobConfigDumpRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.jobId !== "") {
      writer.uint32(10).string(message.jobId);
    }
    if (message.rootConfig !== undefined) {
      ConfigNodeSnapshot.encode(message.rootConfig, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UploadJobConfigDumpRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadJobConfigDumpRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jobId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.rootConfig = ConfigNodeSnapshot.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UploadJobConfigDumpRequest {
    return {
      jobId: isSet(object.jobId) ? globalThis.String(object.jobId) : "",
      rootConfig: isSet(object.rootConfig) ? ConfigNodeSnapshot.fromJSON(object.rootConfig) : undefined,
    };
  },

  toJSON(message: UploadJobConfigDumpRequest): unknown {
    const obj: any = {};
    if (message.jobId !== "") {
      obj.jobId = message.jobId;
    }
    if (message.rootConfig !== undefined) {
      obj.rootConfig = ConfigNodeSnapshot.toJSON(message.rootConfig);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UploadJobConfigDumpRequest>, I>>(base?: I): UploadJobConfigDumpRequest {
    return UploadJobConfigDumpRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UploadJobConfigDumpRequest>, I>>(object: I): UploadJobConfigDumpRequest {
    const message = createBaseUploadJobConfigDumpRequest();
    message.jobId = object.jobId ?? "";
    message.rootConfig = (object.rootConfig !== undefined && object.rootConfig !== null)
      ? ConfigNodeSnapshot.fromPartial(object.rootConfig)
      : undefined;
    return message;
  },
};

function createBaseUploadJobMetricsRequest(): UploadJobMetricsRequest {
  return { jobId: "", jobMetrics: undefined };
}

export const UploadJobMetricsRequest: MessageFns<UploadJobMetricsRequest> = {
  encode(message: UploadJobMetricsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.jobId !== "") {
      writer.uint32(10).string(message.jobId);
    }
    if (message.jobMetrics !== undefined) {
      Metrics.encode(message.jobMetrics, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UploadJobMetricsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadJobMetricsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jobId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.jobMetrics = Metrics.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UploadJobMetricsRequest {
    return {
      jobId: isSet(object.jobId) ? globalThis.String(object.jobId) : "",
      jobMetrics: isSet(object.jobMetrics) ? Metrics.fromJSON(object.jobMetrics) : undefined,
    };
  },

  toJSON(message: UploadJobMetricsRequest): unknown {
    const obj: any = {};
    if (message.jobId !== "") {
      obj.jobId = message.jobId;
    }
    if (message.jobMetrics !== undefined) {
      obj.jobMetrics = Metrics.toJSON(message.jobMetrics);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UploadJobMetricsRequest>, I>>(base?: I): UploadJobMetricsRequest {
    return UploadJobMetricsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UploadJobMetricsRequest>, I>>(object: I): UploadJobMetricsRequest {
    const message = createBaseUploadJobMetricsRequest();
    message.jobId = object.jobId ?? "";
    message.jobMetrics = (object.jobMetrics !== undefined && object.jobMetrics !== null)
      ? Metrics.fromPartial(object.jobMetrics)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
