// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.19.4
// source: geo/geo.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";

export const protobufPackage = "carbon.geo";

/**
 * Type of "fix", indicating the method by which a point was captured. This
 * informs the precision of the measurement.
 *
 * These categories map to fix types for GGA messages under the NMEA 0183 spec,
 * though the numeric enum values differ.
 */
export enum FixType {
  FIX_TYPE_UNSPECIFIED = 0,
  NO_FIX = 1,
  GNSS = 2,
  DIFFERENTIAL_GNSS = 3,
  RTK_FIXED = 4,
  RTK_FLOAT = 5,
  DEAD_RECKONING = 6,
  UNRECOGNIZED = -1,
}

export function fixTypeFromJSON(object: any): FixType {
  switch (object) {
    case 0:
    case "FIX_TYPE_UNSPECIFIED":
      return FixType.FIX_TYPE_UNSPECIFIED;
    case 1:
    case "NO_FIX":
      return FixType.NO_FIX;
    case 2:
    case "GNSS":
      return FixType.GNSS;
    case 3:
    case "DIFFERENTIAL_GNSS":
      return FixType.DIFFERENTIAL_GNSS;
    case 4:
    case "RTK_FIXED":
      return FixType.RTK_FIXED;
    case 5:
    case "RTK_FLOAT":
      return FixType.RTK_FLOAT;
    case 6:
    case "DEAD_RECKONING":
      return FixType.DEAD_RECKONING;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FixType.UNRECOGNIZED;
  }
}

export function fixTypeToJSON(object: FixType): string {
  switch (object) {
    case FixType.FIX_TYPE_UNSPECIFIED:
      return "FIX_TYPE_UNSPECIFIED";
    case FixType.NO_FIX:
      return "NO_FIX";
    case FixType.GNSS:
      return "GNSS";
    case FixType.DIFFERENTIAL_GNSS:
      return "DIFFERENTIAL_GNSS";
    case FixType.RTK_FIXED:
      return "RTK_FIXED";
    case FixType.RTK_FLOAT:
      return "RTK_FLOAT";
    case FixType.DEAD_RECKONING:
      return "DEAD_RECKONING";
    case FixType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Information about how a point was recorded. */
export interface CaptureInfo {
  fixType: FixType;
}

export interface Id {
  /**
   * Globally unique ID for this entity. Intended to be a short, high-entropy
   * string, like base62 over ~65 bits (e.g., `JVU8yUVxbf9`).
   */
  id: string;
}

/** A point on the earth represented in the WGS 84 geodetic coordinate system. */
export interface Point {
  /** Longitude, as degrees east of the prime meridian. */
  lng: number;
  /** Latitude, as degrees north of the equator. */
  lat: number;
  /** Altitude, as height in meters above the reference ellipsoid. */
  alt: number;
  captureInfo:
    | CaptureInfo
    | undefined;
  /**
   * Globally unique identifier for this point.
   *
   * Geometries containing points can include only the ID to incorporate
   * points by reference; e.g., the following `LineString` could
   * represent a closed path:
   *
   *    {
   *      points { id { id: "Xb8M8INv9bo" } }
   *      points { id { id: "B8pNtE3frX4" } }
   *      points { id { id: "a78CkdKl7gk" } }
   *      points { id { id: "Xb8M8INv9bo" } }
   *    }
   */
  id:
    | Id
    | undefined;
  /** Optional human-readable name for this point. */
  name: string;
}

export interface LineString {
  points: Point[];
}

/** An A-B segment, typically indicating a ray, a line, or a heading. */
export interface AbLine {
  /** The start of the line. */
  a:
    | Point
    | undefined;
  /** The end of the line. */
  b: Point | undefined;
}

/**
 * A region on the earth, which has an exterior boundary and possible some
 * interior holes. The exterior boundary must be wound counterclockwise and
 * each hole must be wound clockwise.
 */
export interface Polygon {
  boundary: PolygonRing | undefined;
  holes: PolygonRing[];
}

/**
 * A linear ring on the boundary of the polygon, forming an exterior boundary
 * or interior hole. The ring must contain at least four points, and the first
 * and last points must be identical.
 */
export interface PolygonRing {
  points: Point[];
}

/** A union of disjoint `Polygon`s. */
export interface MultiPolygon {
  polygons: Polygon[];
}

function createBaseCaptureInfo(): CaptureInfo {
  return { fixType: 0 };
}

export const CaptureInfo: MessageFns<CaptureInfo> = {
  encode(message: CaptureInfo, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.fixType !== 0) {
      writer.uint32(8).int32(message.fixType);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CaptureInfo {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCaptureInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.fixType = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CaptureInfo {
    return { fixType: isSet(object.fixType) ? fixTypeFromJSON(object.fixType) : 0 };
  },

  toJSON(message: CaptureInfo): unknown {
    const obj: any = {};
    if (message.fixType !== 0) {
      obj.fixType = fixTypeToJSON(message.fixType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CaptureInfo>, I>>(base?: I): CaptureInfo {
    return CaptureInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CaptureInfo>, I>>(object: I): CaptureInfo {
    const message = createBaseCaptureInfo();
    message.fixType = object.fixType ?? 0;
    return message;
  },
};

function createBaseId(): Id {
  return { id: "" };
}

export const Id: MessageFns<Id> = {
  encode(message: Id, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Id {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseId();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Id {
    return { id: isSet(object.id) ? globalThis.String(object.id) : "" };
  },

  toJSON(message: Id): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Id>, I>>(base?: I): Id {
    return Id.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Id>, I>>(object: I): Id {
    const message = createBaseId();
    message.id = object.id ?? "";
    return message;
  },
};

function createBasePoint(): Point {
  return { lng: 0, lat: 0, alt: 0, captureInfo: undefined, id: undefined, name: "" };
}

export const Point: MessageFns<Point> = {
  encode(message: Point, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.lng !== 0) {
      writer.uint32(9).double(message.lng);
    }
    if (message.lat !== 0) {
      writer.uint32(17).double(message.lat);
    }
    if (message.alt !== 0) {
      writer.uint32(25).double(message.alt);
    }
    if (message.captureInfo !== undefined) {
      CaptureInfo.encode(message.captureInfo, writer.uint32(34).fork()).join();
    }
    if (message.id !== undefined) {
      Id.encode(message.id, writer.uint32(42).fork()).join();
    }
    if (message.name !== "") {
      writer.uint32(50).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Point {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePoint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.lng = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.lat = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.alt = reader.double();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.captureInfo = CaptureInfo.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.id = Id.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Point {
    return {
      lng: isSet(object.lng) ? globalThis.Number(object.lng) : 0,
      lat: isSet(object.lat) ? globalThis.Number(object.lat) : 0,
      alt: isSet(object.alt) ? globalThis.Number(object.alt) : 0,
      captureInfo: isSet(object.captureInfo) ? CaptureInfo.fromJSON(object.captureInfo) : undefined,
      id: isSet(object.id) ? Id.fromJSON(object.id) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: Point): unknown {
    const obj: any = {};
    if (message.lng !== 0) {
      obj.lng = message.lng;
    }
    if (message.lat !== 0) {
      obj.lat = message.lat;
    }
    if (message.alt !== 0) {
      obj.alt = message.alt;
    }
    if (message.captureInfo !== undefined) {
      obj.captureInfo = CaptureInfo.toJSON(message.captureInfo);
    }
    if (message.id !== undefined) {
      obj.id = Id.toJSON(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Point>, I>>(base?: I): Point {
    return Point.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Point>, I>>(object: I): Point {
    const message = createBasePoint();
    message.lng = object.lng ?? 0;
    message.lat = object.lat ?? 0;
    message.alt = object.alt ?? 0;
    message.captureInfo = (object.captureInfo !== undefined && object.captureInfo !== null)
      ? CaptureInfo.fromPartial(object.captureInfo)
      : undefined;
    message.id = (object.id !== undefined && object.id !== null) ? Id.fromPartial(object.id) : undefined;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseLineString(): LineString {
  return { points: [] };
}

export const LineString: MessageFns<LineString> = {
  encode(message: LineString, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.points) {
      Point.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LineString {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLineString();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.points.push(Point.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LineString {
    return { points: globalThis.Array.isArray(object?.points) ? object.points.map((e: any) => Point.fromJSON(e)) : [] };
  },

  toJSON(message: LineString): unknown {
    const obj: any = {};
    if (message.points?.length) {
      obj.points = message.points.map((e) => Point.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LineString>, I>>(base?: I): LineString {
    return LineString.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LineString>, I>>(object: I): LineString {
    const message = createBaseLineString();
    message.points = object.points?.map((e) => Point.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAbLine(): AbLine {
  return { a: undefined, b: undefined };
}

export const AbLine: MessageFns<AbLine> = {
  encode(message: AbLine, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.a !== undefined) {
      Point.encode(message.a, writer.uint32(10).fork()).join();
    }
    if (message.b !== undefined) {
      Point.encode(message.b, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AbLine {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAbLine();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.a = Point.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.b = Point.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AbLine {
    return {
      a: isSet(object.a) ? Point.fromJSON(object.a) : undefined,
      b: isSet(object.b) ? Point.fromJSON(object.b) : undefined,
    };
  },

  toJSON(message: AbLine): unknown {
    const obj: any = {};
    if (message.a !== undefined) {
      obj.a = Point.toJSON(message.a);
    }
    if (message.b !== undefined) {
      obj.b = Point.toJSON(message.b);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AbLine>, I>>(base?: I): AbLine {
    return AbLine.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AbLine>, I>>(object: I): AbLine {
    const message = createBaseAbLine();
    message.a = (object.a !== undefined && object.a !== null) ? Point.fromPartial(object.a) : undefined;
    message.b = (object.b !== undefined && object.b !== null) ? Point.fromPartial(object.b) : undefined;
    return message;
  },
};

function createBasePolygon(): Polygon {
  return { boundary: undefined, holes: [] };
}

export const Polygon: MessageFns<Polygon> = {
  encode(message: Polygon, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.boundary !== undefined) {
      PolygonRing.encode(message.boundary, writer.uint32(10).fork()).join();
    }
    for (const v of message.holes) {
      PolygonRing.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Polygon {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePolygon();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.boundary = PolygonRing.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.holes.push(PolygonRing.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Polygon {
    return {
      boundary: isSet(object.boundary) ? PolygonRing.fromJSON(object.boundary) : undefined,
      holes: globalThis.Array.isArray(object?.holes) ? object.holes.map((e: any) => PolygonRing.fromJSON(e)) : [],
    };
  },

  toJSON(message: Polygon): unknown {
    const obj: any = {};
    if (message.boundary !== undefined) {
      obj.boundary = PolygonRing.toJSON(message.boundary);
    }
    if (message.holes?.length) {
      obj.holes = message.holes.map((e) => PolygonRing.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Polygon>, I>>(base?: I): Polygon {
    return Polygon.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Polygon>, I>>(object: I): Polygon {
    const message = createBasePolygon();
    message.boundary = (object.boundary !== undefined && object.boundary !== null)
      ? PolygonRing.fromPartial(object.boundary)
      : undefined;
    message.holes = object.holes?.map((e) => PolygonRing.fromPartial(e)) || [];
    return message;
  },
};

function createBasePolygonRing(): PolygonRing {
  return { points: [] };
}

export const PolygonRing: MessageFns<PolygonRing> = {
  encode(message: PolygonRing, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.points) {
      Point.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PolygonRing {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePolygonRing();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.points.push(Point.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PolygonRing {
    return { points: globalThis.Array.isArray(object?.points) ? object.points.map((e: any) => Point.fromJSON(e)) : [] };
  },

  toJSON(message: PolygonRing): unknown {
    const obj: any = {};
    if (message.points?.length) {
      obj.points = message.points.map((e) => Point.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PolygonRing>, I>>(base?: I): PolygonRing {
    return PolygonRing.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PolygonRing>, I>>(object: I): PolygonRing {
    const message = createBasePolygonRing();
    message.points = object.points?.map((e) => Point.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMultiPolygon(): MultiPolygon {
  return { polygons: [] };
}

export const MultiPolygon: MessageFns<MultiPolygon> = {
  encode(message: MultiPolygon, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.polygons) {
      Polygon.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): MultiPolygon {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMultiPolygon();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.polygons.push(Polygon.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MultiPolygon {
    return {
      polygons: globalThis.Array.isArray(object?.polygons) ? object.polygons.map((e: any) => Polygon.fromJSON(e)) : [],
    };
  },

  toJSON(message: MultiPolygon): unknown {
    const obj: any = {};
    if (message.polygons?.length) {
      obj.polygons = message.polygons.map((e) => Polygon.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MultiPolygon>, I>>(base?: I): MultiPolygon {
    return MultiPolygon.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MultiPolygon>, I>>(object: I): MultiPolygon {
    const message = createBaseMultiPolygon();
    message.polygons = object.polygons?.map((e) => Polygon.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
