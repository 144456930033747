import { BLUE_LOADING_BUTTON, TEXT_FIELD_DARK } from "portal/utils/theme";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { capitalize, titleCase } from "portal/utils/strings";
import { DateTime } from "luxon";
import { Field, Form, Formik } from "formik";
import { getCustomerPath } from "portal/utils/routing";
import { GlobalHotKeys } from "react-hotkeys";
import { LoadingButton } from "@mui/lab";
import { object, string } from "yup";
import { TextField } from "formik-mui";
import { useCreateCustomerMutation } from "portal/state/portalApi";
import { useMutationPopups } from "portal/utils/hooks/useApiPopups";
import { useNavigate } from "react-router-dom";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/AddOutlined";
import React, { FunctionComponent, useState } from "react";

interface InviteUserProps {
  hotkey?: string;
}
export const NewCustomer: FunctionComponent<InviteUserProps> = ({ hotkey }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { isInternal: isSelfInternal } = useSelf();

  const [isOpen, setOpen] = useState<boolean>(false);

  const [createCustomer] = useMutationPopups(useCreateCustomerMutation(), {
    success: capitalize(
      t("utils.actions.createdLong", {
        subject: t("models.customers.customer_one"),
      })
    ),
  });

  if (!isSelfInternal) {
    return;
  }

  return (
    <>
      {hotkey && (
        <GlobalHotKeys
          keyMap={{
            NEW_ROBOT: {
              name: titleCase(
                t("utils.actions.newLong", {
                  subject: t("models.customers.customer_one"),
                })
              ),
              action: "keyup",
              sequence: hotkey,
            },
          }}
          handlers={{
            NEW_ROBOT: () => setOpen(true),
          }}
        />
      )}
      <Button
        className="text-white"
        startIcon={<AddIcon />}
        onClick={() => setOpen(true)}
      >
        <span className="hidden sm:inline">
          {titleCase(
            t("utils.actions.newLong", {
              subject: t("models.customers.customer_one"),
            })
          )}
        </span>
        <span className="sm:hidden">{t("utils.actions.new")}</span>
      </Button>
      <Dialog open={isOpen} onClose={() => setOpen(false)}>
        <DialogTitle>
          {titleCase(
            t("utils.actions.newLong", {
              subject: t("models.customers.customer_one"),
            })
          )}
        </DialogTitle>
        <Formik
          enableReinitialize
          initialValues={{
            name: "",
          }}
          validationSchema={object({
            name: string().required(),
          })}
          onSubmit={async (values) => {
            const data = {
              name: values.name,
              sfdcAccountId: "",
              weeklyReportEnabled: true,
              weeklyReportDay: 1,
              weeklyReportHour: 9,
              weeklyReportLookbackDays: 14,
              weeklyReportTimezone: DateTime.local().toLocaleString(
                {
                  timeZoneName: "short",
                },
                { locale: i18n.language }
              ),
              emails: [],
            };
            const customer = await createCustomer(data).unwrap();
            navigate(getCustomerPath(String(customer.db?.id)));
            setOpen(false);
          }}
        >
          {({ submitForm, isSubmitting, dirty }) => (
            <Form>
              <DialogContent className="flex flex-col gap-4 pt-2">
                <Field
                  {...TEXT_FIELD_DARK}
                  component={TextField}
                  name="name"
                  label={t("models.customers.fields.name")}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  variant="text"
                  className="text-white"
                  disabled={isSubmitting}
                  onClick={() => setOpen(false)}
                >
                  {t("utils.actions.cancel")}
                </Button>
                <LoadingButton
                  {...BLUE_LOADING_BUTTON}
                  disabled={!dirty}
                  loading={isSubmitting}
                  onClick={submitForm}
                  startIcon={<AddIcon />}
                >
                  {t("utils.actions.create")}
                </LoadingButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};
