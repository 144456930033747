import { CarbonDateRangePicker } from "portal/components/CarbonDateRangePicker";
import { classes, SMALL_TEXT_FIELD, ThemeProps } from "portal/utils/theme";
import { DateRange } from "@mui/x-date-pickers-pro";
import { DateTime } from "luxon";
import { findWhere } from "portal/utils/arrays";
import { getCustomerSerial } from "portal/utils/robots";
import { getDateRangeShortcuts, useDateRange } from "portal/utils/reports";
import { isNil } from "portal/utils/identity";
import { ReportInstanceResponse } from "protos/portal/reports";
import { RobotMultiSelector } from "../robots/RobotMultiSelector";
import { RobotSummaryResponse } from "protos/portal/robots";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { FunctionComponent } from "react";

interface ReportToolsProps {
  className?: string;
  customerIds?: number[];
  dateRange?: DateRange<DateTime>;
  direction?: "horizontal" | "vertical";
  disabled?: boolean;
  onDateRangeChange?: (dateRange: DateRange<DateTime>) => void;
  onSelectedRobotsChange?: (selectedRobots: number[]) => void;
  readOnly?: boolean;
  selectedRobots?: number[];
  themeProps?: ThemeProps;
  instance?: ReportInstanceResponse;
}

export const getSelectedRobotsDescription = (
  t: TFunction,
  selectedRobots: number[] = [],
  robots: RobotSummaryResponse[] = [],
  isInternal: boolean = false
): string => {
  if (selectedRobots.length <= 2) {
    return selectedRobots
      .map((id) => {
        const summary = findWhere(robots, { robot: { db: { id } } });
        const name = isInternal
          ? summary?.robot?.serial
          : getCustomerSerial(t, summary?.robot?.serial);
        return name ?? t("models.robots.unknown");
      })
      .join(", ");
  }
  return `${selectedRobots.length} ${t("models.robots.robot", {
    count: selectedRobots.length,
  })}`;
};

export const ReportTools: FunctionComponent<ReportToolsProps> =
  withAuthenticationRequired(
    ({
      className,
      customerIds,
      dateRange: inputDateRange,
      direction = "horizontal",
      disabled = false,
      onDateRangeChange,
      onSelectedRobotsChange,
      readOnly = false,
      selectedRobots = [],
      themeProps,
      instance,
    }) => {
      const { t } = useTranslation();

      // keep track of date range
      const dateRange = useDateRange(inputDateRange);

      return (
        <div
          className={classes(
            "flex",
            {
              "flex-col items-stretch gap-4": direction === "vertical",
              "gap-2": direction === "horizontal",
            },
            className
          )}
        >
          <RobotMultiSelector
            customerIds={customerIds}
            className={classes("min-h-9", {
              "max-w-64": direction === "horizontal",
            })}
            disabled={disabled}
            selectedRobots={selectedRobots}
            onChange={onSelectedRobotsChange}
            readOnly={readOnly}
            themeProps={themeProps}
            instance={instance}
          />
          <CarbonDateRangePicker
            className={classes({ "w-64": direction === "horizontal" })}
            readOnly={readOnly}
            calendars={1}
            disableFuture
            disabled={disabled}
            value={dateRange}
            slotProps={{
              shortcuts: { items: getDateRangeShortcuts(t) },
              textField: {
                ...(themeProps?.field ?? SMALL_TEXT_FIELD),
                className: classes("ml-0", {
                  "flex-1": direction === "vertical",
                }),
              },
            }}
            onChange={(newRange) => {
              const [startDate, endDate] = newRange;
              if (isNil(startDate) || isNil(endDate)) {
                return;
              }
              onDateRangeChange?.(newRange);
            }}
          />
        </div>
      );
    }
  );
