import { DateRange } from "@mui/x-date-pickers-pro";
import { DateTime } from "luxon";
import { i18n as I18n, TFunction } from "i18next";

export const DATE_PATH_FORMAT = "yyyy-MM-dd";

export const DEFAULT_TIMEZONE = "America/Los_Angeles";

// luxon toLocaleString presets
export const DATETIME_EXCEL = "MM/dd/yyyy hh:mm a";
export const DATETIME_SHORT_WITHOUT_YEAR: Intl.DateTimeFormatOptions = {
  ...DateTime.DATETIME_SHORT,
  year: undefined,
};

export const formatDateOffset = (t: TFunction, offset: number): string => {
  if (offset === 0) {
    return t("components.DateRangePicker.today");
  }
  if (offset === 1) {
    return t("components.DateRangePicker.tomorrow");
  }
  if (offset === -1) {
    return t("components.DateRangePicker.yesterday");
  }
  if (offset < -1) {
    return t("components.DateRangePicker.minusDays", {
      days: Math.abs(offset),
    });
  }
  if (offset > 1) {
    return t("components.DateRangePicker.plusDays", { days: offset });
  }
  return t("components.DateRangePicker.invalid");
};

export const formatCompactDate = (
  t: TFunction,
  i18n: I18n,
  input?: DateTime | number
): string => {
  let date: DateTime | undefined;
  if (typeof input === "number") {
    date = DateTime.fromMillis(input);
  } else if (input instanceof DateTime) {
    date = input;
  }

  if (!date || !date.isValid) {
    return t("components.DateRangePicker.invalid");
  }

  const resultSet: string[] = [];

  if (!isToday(date)) {
    if (isThisWeek(date)) {
      resultSet.push(
        date.toLocaleString({ weekday: "long" }, { locale: i18n.language })
      );
    } else {
      resultSet.push(
        date.toLocaleString(
          { month: "short", day: "numeric" },
          { locale: i18n.language }
        )
      );
    }

    if (!isThisYear(date)) {
      resultSet.push(
        date.toLocaleString({ year: "numeric" }, { locale: i18n.language })
      );
    }
  }
  return resultSet.join(" ");
};

export const isToday = (date: DateTime): boolean =>
  isSameDate(date, DateTime.local());

export const isSameDate = (a: DateTime, b: DateTime): boolean =>
  a.toISODate() === b.toISODate();

export const isThisWeek = (date: DateTime): boolean =>
  date >
  DateTime.local()
    .minus({ days: 6 })
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

export const isThisMonth = (date: DateTime): boolean =>
  date >
  DateTime.local().set({
    day: 1,
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

export const isThisYear = (date: DateTime): boolean =>
  date.year === DateTime.local().year;

export const isSameRange = (
  a: DateRange<DateTime>,
  b: DateRange<DateTime>
): boolean => {
  const [aStart, aEnd] = a;
  const [bStart, bEnd] = b;
  if (!Array.isArray(a) || !Array.isArray(b)) {
    return a === b;
  }
  let startIsSame = false;
  startIsSame = !aStart || !bStart ? aStart === bStart : aStart.equals(bStart);
  let endIsSame = false;
  endIsSame = !aEnd || !bEnd ? aEnd === bEnd : aEnd.equals(bEnd);
  return startIsSame && endIsSame;
};

export const roundToMinute = (date: DateTime): DateTime =>
  DateTime.fromMillis(date.toMillis()).set({
    minute: date.second >= 30 ? date.minute + 1 : date.minute,
    second: 0,
    millisecond: 0,
  });

export const snapToDayStart = (date: DateTime): DateTime =>
  DateTime.fromMillis(date.toMillis()).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

export const snapToDayEnd = (date: DateTime): DateTime =>
  snapToDayStart(date).plus({ days: 1 }).minus({ milliseconds: 1 });
