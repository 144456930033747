import { createSlice, nanoid, type PayloadAction } from "@reduxjs/toolkit";
import type { VariantType } from "notistack";

export type Key = string;
export type { VariantType } from "notistack";

export interface Notification {
  message: string;
  variant: VariantType;
}

interface NotificationSlot {
  key: Key;
  dismissed: boolean;
  notification: Notification;
}

interface State {
  slots: NotificationSlot[];
}

const initialState: State = {
  slots: [],
};

export const notifications = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification: {
      prepare: (notification: Notification) => {
        const key = nanoid();
        return { payload: { key, dismissed: false, notification } };
      },
      reducer: (state, action: PayloadAction<NotificationSlot>) => {
        state.slots.push(action.payload);
      },
    },
    // Remove a notification from the UI. It's kept in the internal state
    // because we need to propagate the dismissal state to Notistack.
    dismissNotification: (state, action: PayloadAction<Key>) => {
      const key: Key = action.payload;
      for (const slot of state.slots) {
        if (slot.key === key) {
          slot.dismissed = true;
          break;
        }
      }
    },
    // Fully remove a notification from the state, after Notistack is done
    // caring about it. This should only be used by our middleware, not by
    // normal components that want to remove their notifications; use
    // `dismissNotification` for that instead.
    notificationExited: (state, action: PayloadAction<Key>) => {
      const key: Key = action.payload;
      state.slots = state.slots.filter((n) => n.key !== key);
    },
  },
});

export const { addNotification, dismissNotification, notificationExited } =
  notifications.actions;
