export const isNull = (input: unknown): input is null => input === null;

export const isUndefined = (input: unknown): input is undefined =>
  input === undefined;

export const isNil = (input: unknown): input is undefined | null =>
  isNull(input) || isUndefined(input);

export const isNotNil = (input: unknown): boolean => !isNil(input);

export const isInt = (input: number): boolean => input % 1 === 0;

export const isValidNumber = (input: unknown): input is number =>
  typeof input === "number" && !Number.isNaN(input);

// Compile-time check that a value is `never` and thus the branch should be
// unreachable. This function does not throw an error, to allow the caller to
// recover gracefully.
export const checkNever = (x: never): never => {
  return x;
};
