import { Alert, IconButton, Skeleton } from "@mui/material";
import { classes } from "portal/utils/theme";
import { FleetView } from "protos/portal/users";
import { range } from "portal/utils/arrays";
import { RobotSummaryCard } from "portal/components/robots/RobotSummaryCard";
import { RobotSummaryResponse } from "protos/portal/robots";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import ExpandLessIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreOutlined";
import Grid from "@mui/material/Unstable_Grid2";
import React, { FunctionComponent, useMemo, useState } from "react";

const GRID_PROPS = {
  className: "min-h-52",
  xs: 12,
  sm: 6,
  lg: 4,
  xl: 3,
};

interface Props {
  pinnedRobotIds?: FleetView["pinnedRobotIds"];
  columns?: FleetView["columns"];
  isLoading?: boolean;
  visibleRobots: RobotSummaryResponse[];
}

export const FleetRobotCards: FunctionComponent<Props> =
  withAuthenticationRequired(function FleetRobotCards({
    pinnedRobotIds,
    columns,
    visibleRobots,
    isLoading,
  }) {
    const { t } = useTranslation();

    const isEmpty = visibleRobots.length === 0 && !isLoading;

    const pinnedRobots = useMemo(
      () =>
        visibleRobots.filter(
          (summary) =>
            summary.robot?.db?.id &&
            pinnedRobotIds?.includes(summary.robot.db.id)
        ),
      [pinnedRobotIds, visibleRobots]
    );
    const hasPinnedRobots = pinnedRobots.length > 0;
    const otherRobots = useMemo(
      () =>
        visibleRobots.filter(
          (summary) =>
            summary.robot?.db?.id &&
            !pinnedRobotIds?.includes(summary.robot.db.id)
        ),
      [pinnedRobotIds, visibleRobots]
    );
    const hasOtherRobots = hasPinnedRobots && otherRobots.length > 0;

    const [isOtherOpen, setOtherOpen] = useState(false);

    const pinnedRobotCards = useMemo(
      () =>
        pinnedRobots.map((summary) => (
          <Grid key={summary.robot?.db?.id} {...GRID_PROPS}>
            {summary.robot && (
              <RobotSummaryCard
                columns={columns}
                summary={summary}
                serial={summary.robot.serial}
                link
                short
                className={classes("h-full")}
              />
            )}
          </Grid>
        )),
      [columns, pinnedRobots]
    );

    const otherRobotCards = useMemo(
      () =>
        otherRobots.map((summary) => (
          <Grid
            key={summary.robot?.db?.id}
            {...GRID_PROPS}
            className={classes(GRID_PROPS.className, {
              hidden: hasOtherRobots && !isOtherOpen,
            })}
          >
            {summary.robot && (
              <RobotSummaryCard
                columns={columns}
                summary={summary}
                serial={summary.robot.serial}
                link
                short
                className={classes("h-full")}
              />
            )}
          </Grid>
        )),
      [columns, hasOtherRobots, isOtherOpen, otherRobots]
    );

    return (
      <div className="overflow-y-auto">
        {isEmpty && (
          <Alert severity="warning">
            {t("views.fleet.robots.errors.empty")}
          </Alert>
        )}
        <Grid container spacing={2} className="w-full m-0">
          {isLoading &&
            range(4).map((index) => (
              <Grid
                key={index}
                {...GRID_PROPS}
                className={classes("min-h-52", {
                  // don't show 4th element on lg breakpoint or it looks weird
                  // (three on one line and one on the next)
                  "lg:invisible xl:visible": index === 3,
                })}
              >
                <Skeleton variant="rectangular" className="w-full h-full" />
              </Grid>
            ))}
          {pinnedRobots.length > 0 && (
            <>
              <Grid xs={12}>
                <h2 className="text-xl my-0">
                  {t("views.fleet.views.fields.pinnedRobotIds")}
                </h2>
              </Grid>
              {pinnedRobotCards}
            </>
          )}
          {otherRobots.length > 9 &&
            otherRobots.length < visibleRobots.length && (
              <Grid xs={12} className="flex items-center">
                <h2 className="text-xl my-0 flex-grow">
                  {t("views.fleet.views.fields.otherRobots", {
                    robotCount: otherRobots.length,
                  })}
                </h2>
                <IconButton
                  onClick={() => setOtherOpen(!isOtherOpen)}
                  className="mt-2 text-white"
                >
                  {isOtherOpen ? (
                    <ExpandLessIcon className="text-4xl" />
                  ) : (
                    <ExpandMoreIcon className="text-4xl" />
                  )}
                </IconButton>
              </Grid>
            )}
          {otherRobotCards}
        </Grid>
      </div>
    );
  });
