import { CarbonUser, isInternal } from "./auth";
import {
  CERTIFIED_METRICS,
  getMetricName,
  METRIC_ACRES_WEEDED,
  METRIC_CROP,
} from "./certifiedMetrics";
import { ConfigNode } from "protos/config/api/config_service";
import { CycleSlot, Metric, MetricValidation } from "./metrics";
import { DailyMetricResponse } from "protos/portal/metrics";
import { DateTime } from "luxon";
import { DATETIME_EXCEL } from "./dates";
import { FleetView, ViewMode, viewModeFromJSON } from "protos/portal/users";
import { formatBooleanForExport } from "portal/components/measurement/formatters";
import {
  getAlarmsStatus,
  getAlmanacStatus,
  getDiscriminatorStatus,
  getJobStatus,
  getLaserStatus,
  getLifetimeStatus,
  getLocaltimeStatus,
  getModelinatorStatus,
  getP2PStatus,
  getThinningStatus,
  getVersionStatus,
  getWeedingStatus,
} from "portal/components/robots/RobotStatus";
import {
  getAlmanacPath,
  getCustomerPath,
  getDiscriminatorPath,
  getModelinatorPath,
  getRobotHistoryPath,
  getRobotPath,
  RobotSubpath,
} from "./routing";
import {
  getCustomerSerial,
  getDisabledLasers,
  ImplementationStatus,
} from "./robots";
import { GridColDef } from "@mui/x-data-grid-premium";
import { i18n as I18n, TFunction } from "i18next";
import { isUndefined } from "./identity";
import { Measurement } from "portal/components/measurement/Measurement";
import { MeasurementSystem } from "./units/units";
import { RobotSummaryResponse } from "protos/portal/robots";
import { SummaryItemProps } from "portal/components/robots/RobotSummaryCard";
import { titleCase } from "./strings";
import { Tooltip } from "@mui/material";
import { values } from "./objects";
import MetricIcon from "@mui/icons-material/ShowChartOutlined";
import React from "react";

export type MetricColumnId = `metric-${Metric["id"]}`;
export enum InfoColumnId {
  GROUP = "group",
  ALARMS = "alarms",
  ALMANAC = "almanac",
  BANDING = "isBanding",
  BANDING_DYNAMIC = "isBandingDynamic",
  BANDING_PROFILE = "banding",
  CUSTOMER = "customer",
  DISCRIMINATOR = "discriminator",
  IMPLEMENTATION_STATUS = "implementationStatus",
  JOB = "job",
  LASERS_OFFLINE = "lasersOffline",
  LIFETIME_AREA = "lifetimeArea",
  LIFETIME_TIME = "lifetimeTime",
  LOCAL_TIME = "localTime",
  MODEL = "model",
  P2P = "p2p",
  REPORTED_AT = "reportedAt",
  ROBOT = "robot",
  SOFTWARE_VERSION = "softwareVersion",
  TARGET_VERSION = "targetVersion",
  THINNING = "isThinning",
  THINNING_PROFILE = "thinning",
  UPDATE_STATUS = "updateStatus",
  WEEDING = "isWeeding",
}
export type FleetColumnId = InfoColumnId | MetricColumnId;

const metricToFleetColumnId = (metric: Metric): MetricColumnId =>
  `metric-${metric.id}`;

const DEFAULT_COLUMNS: FleetColumnId[] = [
  InfoColumnId.GROUP,
  InfoColumnId.ROBOT,
  InfoColumnId.REPORTED_AT,
  InfoColumnId.CUSTOMER,
  InfoColumnId.LOCAL_TIME,
  InfoColumnId.WEEDING,
  metricToFleetColumnId(METRIC_CROP),
  InfoColumnId.THINNING,
  InfoColumnId.ALARMS,
  InfoColumnId.LASERS_OFFLINE,
  InfoColumnId.SOFTWARE_VERSION,
  InfoColumnId.TARGET_VERSION,
  InfoColumnId.UPDATE_STATUS,
  InfoColumnId.LIFETIME_AREA,
  InfoColumnId.LIFETIME_TIME,
];

export const isFleetColumnId = (id: string | number): id is string => {
  if (typeof id === "number") {
    return false;
  }
  return [
    ...values(InfoColumnId),
    ...CERTIFIED_METRICS.map((metric) => metricToFleetColumnId(metric)),
  ].includes(id as FleetColumnId);
};

const defaultColumn: Partial<GridColDef> = {
  type: "string",
  sortable: true,
  disableColumnMenu: true,
};

export const getColumns = (
  i18n: I18n,
  t: TFunction,
  user: CarbonUser,
  measurementSystem: MeasurementSystem
): FleetViewColumn[] => [
  {
    ...defaultColumn,
    field: InfoColumnId.GROUP,
    id: InfoColumnId.GROUP,
    headerName: "",
  },
  {
    ...defaultColumn,
    field: InfoColumnId.ROBOT,
    id: InfoColumnId.ROBOT,
    headerName: titleCase(t("models.robots.robot_one")),
    cellClassName: "font-mono font-bold",
    valueGetter: (value, summary) =>
      isInternal(user)
        ? summary.robot?.serial
        : getCustomerSerial(t, summary.robot?.serial ?? ""),
    getLink: (summary) => {
      const serial = summary.robot?.serial;
      return serial ? getRobotPath(serial, RobotSubpath.SUMMARY) : undefined;
    },
  },
  {
    ...defaultColumn,
    type: "dateTime",
    field: InfoColumnId.REPORTED_AT,
    id: InfoColumnId.REPORTED_AT,
    headerName: titleCase(t("models.robots.fields.reportedAt")),
    valueGetter: (value, summary) =>
      summary.robot?.health?.reportedAt
        ? DateTime.fromSeconds(summary.robot.health.reportedAt)
        : undefined,
    valueFormatter: (value: DateTime | undefined) =>
      value ? value.toFormat(DATETIME_EXCEL) : undefined,
    renderCell: ({ value }: { value?: DateTime | undefined }) =>
      value ? (
        <Tooltip
          title={value.toLocaleString(DateTime.DATETIME_FULL, {
            locale: i18n.language,
          })}
          arrow
        >
          <span className="whitespace-nowrap">
            {value.toRelative({
              locale: i18n.language,
            })}
          </span>
        </Tooltip>
      ) : undefined,
  },
  {
    ...defaultColumn,
    field: InfoColumnId.SOFTWARE_VERSION,
    id: InfoColumnId.SOFTWARE_VERSION,
    headerName: titleCase(t("models.robots.fields.softwareVersion")),
    valueGetter: (value, summary) => summary.robot?.health?.softwareVersion,
    sortComparator: new Intl.Collator("en-US", { numeric: true }).compare,
    renderForCard: (summary) => getVersionStatus(t, summary),
    getLink: (summary) => {
      const serial = summary.robot?.serial;
      return serial
        ? getRobotPath(serial, RobotSubpath.HARDWARE_VERSIONS)
        : undefined;
    },
  },
  {
    ...defaultColumn,
    field: InfoColumnId.TARGET_VERSION,
    id: InfoColumnId.TARGET_VERSION,
    headerName: titleCase(t("models.robots.fields.targetVersion")),
    valueGetter: (value, summary) => summary.robot?.health?.targetVersion,
    sortComparator: new Intl.Collator("en-US", { numeric: true }).compare,
    getLink: (summary) => {
      const serial = summary.robot?.serial;
      return serial
        ? getRobotPath(serial, RobotSubpath.HARDWARE_VERSIONS)
        : undefined;
    },
  },
  {
    ...defaultColumn,
    field: InfoColumnId.UPDATE_STATUS,
    id: InfoColumnId.UPDATE_STATUS,
    headerName: titleCase(t("views.fleet.robots.hardware.ready.name")),
    valueGetter: (value, summary) => {
      const softwareVersion = summary.robot?.health?.softwareVersion;
      const targetVersion = summary.robot?.health?.targetVersion;
      if (isUndefined(softwareVersion) && isUndefined(targetVersion)) {
        return t("utils.descriptors.unknown");
      }
      if (softwareVersion === targetVersion) {
        return t("views.fleet.robots.hardware.ready.values.installed");
      }
      switch (summary.robot?.health?.targetVersionReady) {
        case true: {
          return t("views.fleet.robots.hardware.ready.values.true");
        }
        case false: {
          return t("views.fleet.robots.hardware.ready.values.false");
        }
        default: {
          return t("utils.descriptors.unknown");
        }
      }
    },
  },
  {
    ...defaultColumn,
    type: "boolean",
    field: InfoColumnId.WEEDING,
    id: InfoColumnId.WEEDING,
    headerName: titleCase(t("models.robots.fields.isWeeding")),
    valueGetter: (value, summary) =>
      Boolean(summary.robot?.health?.fieldConfig?.isWeeding),
    valueFormatter: formatBooleanForExport,
    renderForCard: (summary) => getWeedingStatus(t, summary),
  },
  {
    ...defaultColumn,
    type: "boolean",
    field: InfoColumnId.THINNING,
    id: InfoColumnId.THINNING,
    headerName: titleCase(t("models.robots.fields.isThinning")),
    valueGetter: (value, summary) =>
      Boolean(summary.robot?.health?.fieldConfig?.isThinning),
    valueFormatter: formatBooleanForExport,
    renderForCard: (summary) => getThinningStatus(t, summary),
  },
  {
    ...defaultColumn,
    field: InfoColumnId.THINNING_PROFILE,
    id: InfoColumnId.THINNING_PROFILE,
    headerName: titleCase(
      t("components.robots.RobotSummary.thinning.withName", {
        name: t("views.fleet.robots.summary.banding.definition"),
      })
    ),
    valueGetter: (value, summary) => {
      const isThinning = summary.robot?.health?.fieldConfig?.isThinning;
      if (!isThinning) {
        return;
      }
      return summary.robot?.health?.fieldConfig?.activeThinningConfigName;
    },
  },
  {
    ...defaultColumn,
    type: "boolean",
    field: InfoColumnId.BANDING_DYNAMIC,
    id: InfoColumnId.BANDING_DYNAMIC,
    headerName: titleCase(
      t("components.robots.RobotSummary.banding.withName", {
        name: t("views.fleet.robots.summary.banding.dynamic"),
      })
    ),
    valueGetter: (value, summary) =>
      Boolean(summary.robot?.health?.fieldConfig?.bandingEnabled) &&
      Boolean(summary.robot?.health?.fieldConfig?.bandingDynamic),
    valueFormatter: formatBooleanForExport,
  },
  {
    ...defaultColumn,
    field: InfoColumnId.BANDING_PROFILE,
    id: InfoColumnId.BANDING_PROFILE,
    headerName: titleCase(
      t("components.robots.RobotSummary.banding.withName", {
        name: t("views.fleet.robots.summary.banding.definition"),
      })
    ),
    valueGetter: (value, summary) => {
      const isBanding = summary.robot?.health?.fieldConfig?.bandingEnabled;
      if (!isBanding) {
        return;
      }
      return summary.robot?.health?.fieldConfig?.activeBandConfigName;
    },
  },
  {
    ...defaultColumn,
    field: InfoColumnId.ALMANAC,
    id: InfoColumnId.ALMANAC,
    headerName: titleCase(t("models.almanacs.almanac_one")),
    valueGetter: (value, summary) =>
      summary.robot?.health?.fieldConfig?.activeAlmanacName,
    renderForCard: (summary) => getAlmanacStatus(t, summary),
    getLink: (summary) => {
      const serial = summary.robot?.serial;
      const uuid = summary.robot?.health?.fieldConfig?.activeAlmanacId;
      return serial && uuid ? getAlmanacPath(serial, uuid) : undefined;
    },
  },
  {
    ...defaultColumn,
    field: InfoColumnId.DISCRIMINATOR,
    id: InfoColumnId.DISCRIMINATOR,
    headerName: titleCase(t("models.discriminators.discriminator_one")),
    valueGetter: (value, summary) =>
      summary.robot?.health?.fieldConfig?.activeDiscriminatorName,
    renderForCard: (summary) => getDiscriminatorStatus(t, summary),
    getLink: (summary) => {
      const serial = summary.robot?.serial;
      const uuid = summary.robot?.health?.fieldConfig?.activeDiscriminatorId;
      return serial && uuid ? getDiscriminatorPath(serial, uuid) : undefined;
    },
  },
  {
    ...defaultColumn,
    field: InfoColumnId.MODEL,
    id: InfoColumnId.MODEL,
    headerName: titleCase(t("models.models.model_one")),
    valueGetter: (value, summary) => summary.robot?.health?.model,
    renderForCard: (summary) => getModelinatorStatus(t, summary),
    cellClassName: "font-mono",
    getLink: (summary) => {
      const serial = summary.robot?.serial;
      const cropId = summary.robot?.health?.cropId;
      const modelId = summary.robot?.health?.fieldConfig?.activeModelinatorId;
      return serial && cropId && modelId
        ? getModelinatorPath(serial, cropId, modelId)
        : undefined;
    },
  },
  {
    ...defaultColumn,
    field: InfoColumnId.P2P,
    id: InfoColumnId.P2P,
    headerName: titleCase(t("models.models.p2p_one")),
    cellClassName: "font-mono",
    valueGetter: (value, summary) => summary.robot?.health?.model,
    isInternal: true,
    renderForCard: (summary) => getP2PStatus(t, summary),
  },
  {
    ...defaultColumn,
    field: InfoColumnId.JOB,
    id: InfoColumnId.JOB,
    headerName: titleCase(t("models.jobs.job_one")),
    valueGetter: (value, summary) =>
      summary.robot?.health?.fieldConfig?.activeJobName,
    renderForCard: (summary) => getJobStatus(t, summary),
    getLink: (summary) => {
      const serial = summary.robot?.serial;
      const uuid = summary.robot?.health?.fieldConfig?.activeJobId;
      return serial && uuid ? getRobotHistoryPath(serial, uuid) : undefined;
    },
  },
  {
    ...defaultColumn,
    type: "number",
    field: InfoColumnId.ALARMS,
    id: InfoColumnId.ALARMS,
    headerName: titleCase(t("models.alarms.alarm_other")),
    valueGetter: (value, summary) =>
      summary.robot?.health?.activeAlarmCount ?? 0,
    renderForCard: (summary) => getAlarmsStatus(t, summary),
    getLink: (summary) => {
      const serial = summary.robot?.serial;
      return serial ? getRobotPath(serial, RobotSubpath.ALARMS) : undefined;
    },
  },
  {
    ...defaultColumn,
    type: "number",
    field: InfoColumnId.LIFETIME_TIME,
    id: InfoColumnId.LIFETIME_TIME,
    headerName: titleCase(t("models.robots.fields.lifetimeTime")),
    valueGetter: (value, summary) => summary.robot?.health?.robotRuntime240v,
    renderForCard: (summary) =>
      getLifetimeStatus(t, i18n, measurementSystem, summary),
    renderCell: ({ row: summary }) => (
      <Measurement
        value={summary.robot?.health?.robotRuntime240v}
        fromUnits="h"
        cycleSlot={CycleSlot.STATUS_TIME}
      />
    ),
  },
  {
    ...defaultColumn,
    type: "number",
    field: InfoColumnId.LIFETIME_AREA,
    id: InfoColumnId.LIFETIME_AREA,
    headerName: titleCase(t("models.robots.fields.lifetimeArea")),
    valueGetter: (value, summary) =>
      summary.dailyMetrics
        ? METRIC_ACRES_WEEDED.getValue(
            summary.robot?.health?.performance?.weeding?.areaWeededTotal,
            summary.dailyMetrics
          )
        : undefined,
    renderCell: ({ row: summary }) => (
      <Measurement
        value={summary.robot?.health?.performance?.weeding?.areaWeededTotal}
        metric={METRIC_ACRES_WEEDED}
        cycleSlot={CycleSlot.STATUS_AREA}
      />
    ),
  },
  {
    ...defaultColumn,
    field: InfoColumnId.CUSTOMER,
    id: InfoColumnId.CUSTOMER,
    headerName: titleCase(t("models.customers.customer_one")),
    valueGetter: (value, summary) => summary.customer?.name,
    getLink: (summary) => {
      const customerId = summary.customer?.db?.id;
      return customerId ? getCustomerPath(customerId.toString()) : undefined;
    },
  },
  {
    ...defaultColumn,
    type: "dateTime",
    field: InfoColumnId.LOCAL_TIME,
    id: InfoColumnId.LOCAL_TIME,
    headerName: titleCase(t("models.robots.fields.localTime")),
    valueGetter: (value, summary) => {
      const timezone = summary.robot?.health?.timezone;
      return timezone ? DateTime.local().setZone(timezone) : DateTime.local();
    },
    valueFormatter: (value: DateTime | undefined) =>
      value ? value.toFormat(DATETIME_EXCEL) : undefined,
    renderCell: ({ value }: { value?: DateTime | undefined }) =>
      value
        ? value.toLocaleString(
            { ...DateTime.TIME_SIMPLE, weekday: "short", day: "numeric" },
            { locale: i18n.language }
          )
        : undefined,
    renderForCard: (summary) => getLocaltimeStatus(t, i18n, summary),
  },
  {
    ...defaultColumn,
    type: "number",
    field: InfoColumnId.LASERS_OFFLINE,
    id: InfoColumnId.LASERS_OFFLINE,
    headerName: titleCase(t("models.robots.fields.lasersOffline")),
    valueGetter: (value, summary) =>
      getDisabledLasers(summary.robot?.serial, summary.config).length,
    renderForCard: (summary, config) =>
      getLaserStatus(t, summary.robot?.serial, config, summary, false),
  },
  ...CERTIFIED_METRICS.map((metric) => {
    const id = metricToFleetColumnId(metric);

    const metricName = getMetricName(t, metric);

    const column: FleetViewColumn = {
      ...defaultColumn,
      field: id,
      id,
      type: metric.type,
      headerName: metricName,
      valueGetter: (value, summary) => {
        const dailyMetrics =
          summary.dailyMetrics ?? DailyMetricResponse.fromPartial({});
        const rawValue = dailyMetrics[metric.id as keyof DailyMetricResponse];
        if (metric.type === "boolean") {
          return Boolean(rawValue);
        }
        return metric.getValue(rawValue, dailyMetrics, isInternal(user));
      },
      isInternal: metric.validation === MetricValidation.INTERNAL,
      isPending: metric.validation === MetricValidation.PENDING,
      renderForCard: (summary) => {
        const value = summary.dailyMetrics
          ? metric.getValue(
              summary.dailyMetrics[metric.id as keyof DailyMetricResponse],
              summary.dailyMetrics,
              isInternal(user)
            )
          : undefined;
        return {
          icon: <MetricIcon />,
          text: (
            <div className="flex items-center justify-start gap-1">
              {metricName}:{" "}
              <Measurement metric={metric} value={value} className="w-auto" />
            </div>
          ),
        };
      },
    };
    if (metric.type === "boolean") {
      column.valueFormatter = formatBooleanForExport;
    } else {
      column.renderCell = ({ value }) => (
        <Measurement value={value} metric={metric} />
      );
    }
    return column;
  }),
];

export interface GroupedRobotSummaryResponse extends RobotSummaryResponse {
  group: string;
}

export type FleetViewColumn = GridColDef<GroupedRobotSummaryResponse> & {
  id: string;
  isPending?: boolean;
  isInternal?: boolean;
  renderForCard?: (
    summary: RobotSummaryResponse,
    config?: ConfigNode
  ) => SummaryItemProps | undefined;
  getLink?: (summary: RobotSummaryResponse) => string | undefined;
};

export const generateDefaultFleetView = (
  t: TFunction,
  user?: CarbonUser
): FleetView => {
  const viewMode = isInternal(user)
    ? viewModeFromJSON(ViewMode.VIEW_MODE_TABLE)
    : viewModeFromJSON(ViewMode.VIEW_MODE_CARDS);
  const showMap = viewMode === viewModeFromJSON(ViewMode.VIEW_MODE_CARDS);
  return FleetView.fromPartial({
    columns: DEFAULT_COLUMNS,
    name: t("views.reports.tools.robotsLabel.all"),
    showInternal: true,
    showOffline: true,
    showMap,
    statuses: isInternal(user)
      ? [ImplementationStatus.ACTIVE, ImplementationStatus.IMPLEMENTATION]
      : [ImplementationStatus.ACTIVE],
    viewMode,
  });
};

export const getDefaultFleetView = (
  fleetViews: Record<number, FleetView>,
  user: CarbonUser | undefined
): FleetView | undefined => {
  const defaultFleetViewId = user?.userMetadata?.defaultFleetViewId;
  if (defaultFleetViewId) {
    return fleetViews[defaultFleetViewId];
  }
  const views = values(fleetViews);
  if (views.length > 0) {
    return views[0];
  }
};
