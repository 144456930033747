import {
  classes,
  SMALL_TEXT_FIELD,
  SMALL_TEXT_FIELD_DARK,
} from "portal/utils/theme";
import { GlobalHotKeys } from "react-hotkeys";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { withErrorBoundary } from "portal/components/ErrorBoundary";
import { WithSkeleton } from "portal/components/WithSkeleton";
import ClearIcon from "@mui/icons-material/ClearOutlined";
import React, { FunctionComponent, useRef } from "react";

interface SearchFieldProps {
  dark?: boolean;
  success?: boolean;
  value: string;
  onChange: (newSearch: string) => void;
  onSelect?: () => void;
  label?: string;
  className?: string;
  placeholder?: string;
}

export const SearchField: FunctionComponent<SearchFieldProps> =
  withErrorBoundary(
    function SearchField({
      dark = false,
      success = true,
      value,
      onChange,
      label,
      onSelect,
      className,
      placeholder,
    }) {
      const { t } = useTranslation();
      const inputRef = useRef<HTMLInputElement>();

      return (
        <>
          <GlobalHotKeys
            keyMap={{
              FOCUS: {
                name: t("components.header.search.focus"),
                action: "keyup",
                sequence: "ctrl+f",
                sequences: ["/", "ctrl+f"],
              },
            }}
            handlers={{
              FOCUS: (event) => {
                inputRef.current?.focus();
                event?.preventDefault();
              },
            }}
          />
          <WithSkeleton
            variant="rectangular"
            success={success}
            className={classes("h-10 min-w-40", className)}
          >
            <form
              onSubmit={(event) => {
                event.preventDefault();
                onSelect?.();
              }}
            >
              <TextField
                {...(dark ? SMALL_TEXT_FIELD_DARK : SMALL_TEXT_FIELD)}
                classes={{ root: className }}
                label={label ?? t("utils.actions.search")}
                value={value}
                placeholder={placeholder}
                inputRef={inputRef}
                onChange={(event) => onChange(event.target.value)}
                InputProps={{
                  ...(dark ? SMALL_TEXT_FIELD_DARK : SMALL_TEXT_FIELD)
                    .InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => onChange("")} edge="end">
                        <ClearIcon
                          className={classes("text-white", {
                            hidden: !value,
                          })}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </WithSkeleton>
        </>
      );
    },
    { i18nKey: "components.header.search.failed", small: true }
  );
