import { keys, values as objectValues } from "./objects";
import { TFunction } from "i18next";
import {
  TVEProfile,
  TVERowProfile,
} from "protos/target_velocity_estimator/target_velocity_estimator";

export const DEFAULT_ROW_PROFILE = TVERowProfile.fromPartial({
  primaryKillRate: 1,
  secondaryKillRate: 0.95,
});

export const getDefaultTargetVelocityEstimator = (t: TFunction): TVEProfile =>
  TVEProfile.fromPartial({
    name: t("models.velocityEstimators.velocityEstimator_one"),
    protected: false,
    cruiseOffsetPercent: 0,
    primaryRange: 0.2,
    secondaryRange: 0.2,
    increaseSmoothing: 0.03,
    decreaseSmoothing: 0.08,
    maxVelMph: 1.5,
    minVelMph: 0.05,
    rows: {
      1: structuredClone(DEFAULT_ROW_PROFILE),
      2: structuredClone(DEFAULT_ROW_PROFILE),
      3: structuredClone(DEFAULT_ROW_PROFILE),
    },
    row1: structuredClone(DEFAULT_ROW_PROFILE),
    row2: structuredClone(DEFAULT_ROW_PROFILE),
    row3: structuredClone(DEFAULT_ROW_PROFILE),
  });

export const areRowsSynced = (
  velocityEstimator: TVEProfile | undefined
): boolean => {
  if (!velocityEstimator) {
    return true;
  }
  for (const key of keys(DEFAULT_ROW_PROFILE)) {
    const values = new Set();
    for (const row of objectValues(velocityEstimator.rows)) {
      values.add(row[key]);
    }
    if (values.size > 1) {
      return false;
    }
  }
  return true;
};
