import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import {
  BLUE_LOADING_BUTTON,
  SMALL_INPUT_DARK,
  SMALL_LABEL,
  SMALL_SELECT_DARK,
} from "portal/utils/theme";
import { capitalize } from "portal/utils/strings";
import {
  formatImplementationStatus,
  ImplementationStatus,
  toImplementationStatus,
} from "portal/utils/robots";
import { LoadingButton } from "@mui/lab";
import { RobotSummaryResponse } from "protos/portal/robots";
import { useMutationPopups } from "portal/utils/hooks/useApiPopups";
import { useTranslation } from "react-i18next";
import { useUpdateRobotMutation } from "portal/state/portalApi";
import { values } from "portal/utils/objects";
import EditIcon from "@mui/icons-material/EditOutlined";
import React, { FunctionComponent, useState } from "react";
import SaveIcon from "@mui/icons-material/SaveOutlined";

export const RobotImplementationSelector: FunctionComponent<{
  summary?: RobotSummaryResponse;
  readOnly?: boolean;
}> = ({ summary, readOnly = false }) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<ImplementationStatus>(
    toImplementationStatus(summary?.robot?.implementationStatus)
  );

  const [updateRobot, { isLoading }] = useMutationPopups(
    useUpdateRobotMutation(),
    {
      success: capitalize(
        t("utils.actions.updatedLong", {
          subject: t("models.robots.robot_one"),
        })
      ),
    }
  );

  return (
    <>
      <div className="flex items-center justify-between">
        {t("components.RobotImplementationSelector.status")}:{" "}
        {formatImplementationStatus(t, summary?.robot?.implementationStatus)}
        {!readOnly && (
          <IconButton
            className="p-0 text-white"
            onClick={() => {
              setStatus(
                toImplementationStatus(summary?.robot?.implementationStatus)
              );
              setOpen(true);
            }}
          >
            <EditIcon />
          </IconButton>
        )}
      </div>
      <Dialog open={isOpen} onClose={() => setOpen(false)}>
        <DialogTitle>
          {t("components.RobotImplementationSelector.title")}
        </DialogTitle>
        <DialogContent>
          <Alert severity="warning" className="mb-4">
            {t("components.RobotImplementationSelector.warning")}
          </Alert>
          <FormControl>
            <InputLabel {...SMALL_LABEL}>
              {t("components.RobotImplementationSelector.status")}
            </InputLabel>
            <Select
              {...SMALL_SELECT_DARK}
              input={
                <OutlinedInput
                  {...SMALL_INPUT_DARK}
                  label={t("components.RobotImplementationSelector.status")}
                />
              }
              defaultValue={ImplementationStatus.ACTIVE}
              placeholder={t("components.Loading.placeholder")}
              value={status}
              onChange={(event) =>
                setStatus(toImplementationStatus(event.target.value))
              }
              renderValue={(status) => formatImplementationStatus(t, status)}
            >
              {values(ImplementationStatus).map((status) => (
                <MenuItem value={status} key={status}>
                  {formatImplementationStatus(
                    t,
                    toImplementationStatus(status)
                  )}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            className="text-white"
            disabled={isLoading}
            onClick={() => setOpen(false)}
          >
            {t("utils.actions.cancel")}
          </Button>
          <LoadingButton
            {...BLUE_LOADING_BUTTON}
            loading={isLoading}
            startIcon={<SaveIcon />}
            onClick={async () => {
              if (!summary?.robot) {
                return;
              }
              await updateRobot({
                serial: summary.robot.serial,
                implementationStatus: status,
              });
              setOpen(false);
            }}
          >
            {t("utils.actions.saveLong", {
              subject: t("components.RobotImplementationSelector.status"),
            })}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
