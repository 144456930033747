import { BlockResponse } from "protos/portal/spatial";
import {
  Bounds,
  DRAW_STYLE,
  FACILITIES,
  mergeBounds,
  Point,
  toLngLatBounds,
} from "portal/utils/geo";
import { Card, CircularProgress, Tooltip } from "@mui/material";
import { classes } from "portal/utils/theme";
import { convert } from "portal/utils/units/units";
import { debounce } from "portal/utils/timing";
import { DEFAULT_MAP_FILTERS, FilterData } from "portal/utils/map";
import { entries } from "portal/utils/objects";
import { FeatureFlag, useFeatureFlag } from "portal/utils/hooks/useFeatureFlag";
import { FilterControl } from "./filters/FilterControl";
import { FilterPane } from "./filters/FilterPane";
import { formatMeasurement } from "../measurement/formatters";
import { HealthLog } from "protos/portal/health";
import { HeatmapControl } from "./heatmaps/HeatmapControl";
import { HeatmapLegend } from "./heatmaps/HeatmapLegend";
import { HeatmapPane } from "./heatmaps/HeatmapPane";
import { HISTORY_STATUS_LOADING, useMapHistory } from "./useMapHistory";
import { isUndefined } from "portal/utils/identity";
import {
  LOCALSTORAGE_MAP_FILTERS,
  LOCALSTORAGE_MAP_FULLSCREEN,
} from "portal/utils/localStorage";
import { MeasureControl } from "./draw/MeasureControl";
import { RobotSummaryResponse } from "protos/portal/robots";
import { SPATIAL_DEFAULT } from "portal/utils/spatialMetrics";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useMapBlocks } from "./useMapBlocks";
import { useFacilities as useMapFacilities } from "./useMapFacilities";
import { useMapRobots } from "./useMapRobots";
import { useSelf } from "portal/state/store";
import { useStableObject } from "portal/utils/hooks/useStable";
import { useTranslation } from "react-i18next";
import { withErrorBoundary } from "portal/components/ErrorBoundary";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExitOutlined";
import FullscreenIcon from "@mui/icons-material/FullscreenOutlined";
import MapBox, {
  GeolocateControl,
  MapboxGeoJSONFeature,
  MapRef,
  NavigationControl,
  PaddingOptions,
} from "react-map-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ResetBoundsIcon from "@mui/icons-material/CenterFocusStrongOutlined";

interface Props {
  allowBorders?: boolean;
  allowEmpty?: boolean;
  animationDuration?: number;
  blocks?: BlockResponse[];
  boundsOffset?: PaddingOptions | number;
  canZoom?: boolean;
  className?: string;
  emptyError?: string;
  extraControls?: ReactNode;
  hideFullscreen?: boolean;
  hideMeasure?: boolean;
  hideRobots?: boolean;
  history?: HealthLog[];
  legendClassName?: string;
  loading?: boolean;
  onZoom?: () => void;
  robots?: RobotSummaryResponse[];
  zoomOnFocusChange?: boolean;
  zoomToFacilities?: boolean;
}

export const Map: FunctionComponent<Props> = withErrorBoundary(
  function Map({
    allowBorders = false,
    allowEmpty,
    animationDuration,
    blocks,
    boundsOffset = 100,
    canZoom = true,
    className,
    emptyError,
    extraControls,
    hideFullscreen = false,
    hideMeasure = false,
    hideRobots = false,
    history,
    legendClassName,
    loading = false,
    onZoom,
    robots,
    zoomOnFocusChange = false,
    zoomToFacilities = false,
  }) {
    const { t, i18n } = useTranslation();
    const { isInternal, measurementSystem } = useSelf();
    const { isEnabled: hasSpatial } = useFeatureFlag(FeatureFlag.SPATIAL);

    const hasHistory = Array.isArray(history) && history.length > 0;
    const hasBlocks = Array.isArray(blocks) && blocks.length > 0;

    // map state
    const map = useRef<MapRef | null>(null);
    const mapWrapper = useRef<HTMLDivElement | null>(null);
    const [isInitialized, setInitialized] = useState<boolean>(false);
    const [draw] = useState<MapboxDraw>(
      new MapboxDraw({ displayControlsDefault: false, styles: DRAW_STYLE })
    );

    const [isFullscreen, setFullscreen] = useLocalStorage<boolean>(
      LOCALSTORAGE_MAP_FULLSCREEN,
      false
    );

    // filters
    const [isFilterPaneOpen, setFilterPaneOpen] = useState<boolean>(false);
    const [filters, setFilters] = useLocalStorage<FilterData<boolean>>(
      LOCALSTORAGE_MAP_FILTERS,
      DEFAULT_MAP_FILTERS
    );

    // heatmap
    const [isRelative, setRelative] = useState<boolean>(true);
    const [selectedMetricId, setSelectedMetricId] = useState<string>(
      SPATIAL_DEFAULT.id
    );
    const [isHeatmapPaneOpen, setHeatmapPaneOpen] = useState<boolean>(false);
    const [hoverInfo, setHoverInfo] = useState<
      { feature: MapboxGeoJSONFeature; x: number; y: number } | undefined
    >();
    const [clickInfo, setClickInfo] = useState<
      { feature: MapboxGeoJSONFeature; x: number; y: number } | undefined
    >();
    const [touchStart, setTouchStart] = useState<[number, number] | undefined>(
      undefined
    );
    const [touchEnd, setTouchEnd] = useState<[number, number] | undefined>(
      undefined
    );

    // get history data
    const {
      lineSource,
      lineLayers,
      bounds: historyBounds,
      historyStatus,
      interactiveLayerIds: interactiveHistoryIds,
    } = useMapHistory(history, allowBorders);

    // get history data
    const {
      blockSource,
      blockLayers,
      bounds: blockBounds,
      historyStatus: blockStatus,
      interactiveLayerIds: interactiveBlockIds,
    } = useMapBlocks(blocks, selectedMetricId, isRelative, allowBorders);

    // get robot data
    const {
      markers: robotMarkers,
      focus: robotFocus,
      bounds: robotBounds,
    } = useMapRobots(robots, filters);

    // get facility data
    const {
      markers: facilityMarkers,
      focus: facilityFocus,
      bounds: facilityBounds,
    } = useMapFacilities(filters);

    // merge focus
    const carbonHQ = FACILITIES[0];
    const focus = useStableObject<Point>(
      robotFocus ??
        facilityFocus ?? [carbonHQ?.longitude ?? 0, carbonHQ?.latitude ?? 0]
    );

    // merge bounds and fit map
    const [triggerInitialZoom, setInitialZoom] = useState<boolean>(false);
    const bounds = useStableObject(
      useMemo<Bounds | undefined>(() => {
        let bounds: Bounds | undefined;
        // history
        bounds = mergeBounds(bounds, historyBounds);
        // blocks
        bounds = mergeBounds(bounds, blockBounds);
        // robots
        if (!hideRobots) {
          bounds = mergeBounds(bounds, robotBounds);
        }
        // facilities
        if (zoomToFacilities) {
          bounds = mergeBounds(bounds, facilityBounds);
        }
        return bounds;
      }, [
        facilityBounds,
        historyBounds,
        blockBounds,
        hideRobots,
        zoomToFacilities,
        robotBounds,
      ])
    );

    const resetBounds = useCallback(
      (input: Bounds | undefined): void => {
        if (isUndefined(input)) {
          map.current?.easeTo({
            center: focus,
            zoom: 12,
            padding: boundsOffset,
            ...(animationDuration ? { duration: animationDuration } : {}),
          });
          return;
        }
        map.current?.stop();
        const bounds = toLngLatBounds(input);
        if (!bounds) {
          return;
        }
        map.current?.fitBounds(bounds, {
          padding: boundsOffset,
          minZoom: 12,
          ...(animationDuration ? { duration: animationDuration } : {}),
        });
        onZoom?.();
      },
      [animationDuration, boundsOffset, focus, onZoom]
    );

    useEffect(() => {
      setTimeout(() => resetBounds(bounds), convert(1).from("s").to("ms"));
    }, [bounds, isFullscreen, resetBounds]);

    useEffect(() => {
      if (zoomOnFocusChange && canZoom && triggerInitialZoom) {
        resetBounds(bounds);
      }
    }, [bounds, canZoom, resetBounds, triggerInitialZoom, zoomOnFocusChange]);

    useEffect(() => {
      const currentMap = map.current;
      // handles resizing the map if the flexbox height updates so we don't see map deadspace
      if (isInitialized && mapWrapper.current) {
        const resizer = new ResizeObserver(
          debounce(
            () => {
              currentMap?.resize();
            },
            { wait: 100 }
          )
        );
        resizer.observe(mapWrapper.current);

        return () => {
          resizer.disconnect();
        };
      }
    }, [draw, isInitialized, isFullscreen]);

    // hack: pretend this is reactive and update whenever we happen to render
    const mapCurrent = isInitialized && !hideMeasure ? map.current : undefined;
    useEffect(() => {
      if (!mapCurrent) {
        return;
      }
      mapCurrent.addControl(draw);
      return () => {
        mapCurrent.removeControl(draw);
      };
    }, [draw, mapCurrent]);

    const hasData = robotFocus || hasBlocks || hasHistory;
    const isLoading =
      loading ||
      (hasHistory && historyStatus === HISTORY_STATUS_LOADING) ||
      (hasBlocks && blockStatus === HISTORY_STATUS_LOADING);

    return (
      <Card
        ref={mapWrapper}
        classes={{
          root: classes(
            "w-full relative flex items-stretch justify-items-stretch",
            {
              grayscale: !isLoading && !hasData && !allowEmpty,
            },
            className
          ),
        }}
      >
        {!isLoading && !hasData && !allowEmpty && (
          <div className="absolute inset-0 flex items-center justify-center font-bold z-50 bg-darken-500">
            {emptyError ?? t("components.map.errors.empty")}
          </div>
        )}
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center font-bold z-50 bg-darken-500">
            <CircularProgress color="inherit" size="1rem" className="mr-2" />
            {t("components.Loading.placeholder")}
          </div>
        )}
        <MapBox
          ref={map}
          onRender={() => {
            setInitialized(true);
            if (!isInitialized) {
              map.current?.jumpTo({
                center: focus,
                zoom: 5,
              });
              setTimeout(() => {
                setInitialZoom(true);
              }, 1 * 1000);
            }
          }}
          interactiveLayerIds={
            hasBlocks ? interactiveBlockIds : interactiveHistoryIds
          }
          mapboxAccessToken={window._jsenv.REACT_APP_MAPBOX_ACCESS_TOKEN}
          mapStyle="mapbox://styles/carbonrobotics/ckz5r36ej004115p4f2wpxw1b"
          initialViewState={{
            longitude: -120.009_048_324_219_38,
            latitude: 46.665_038_285_723_55,
            zoom: 5,
          }}
          style={{
            background: "black",
            height: isFullscreen ? "100vh" : "auto",
            width: isFullscreen ? "100vw" : "auto",
            position: isFullscreen ? "fixed" : "relative",
            top: isFullscreen ? 0 : "auto",
            left: isFullscreen ? 0 : "auto",
            zIndex: isFullscreen ? 1499 : 0,
            flexGrow: "1",
            cursor: "crosshair",
          }}
          onMouseMove={(event) => {
            const {
              features,
              point: { x, y },
            } = event;
            const hoveredFeature = features && features[0];

            if (map.current) {
              map.current.getCanvas().style.cursor =
                (hasSpatial || isInternal) && hoveredFeature ? "crosshair" : "";
            }

            setHoverInfo(hoveredFeature && { feature: hoveredFeature, x, y });
          }}
          // handle click
          onClick={(event) => {
            const {
              features,
              point: { x, y },
            } = event;
            const hoveredFeature = features && features[0];

            setClickInfo(
              hoveredFeature ? { feature: hoveredFeature, x, y } : undefined
            );
          }}
          onTouchStart={(event) => {
            const firstTouch = event.originalEvent.touches[0];
            if (!firstTouch) {
              return;
            }
            // track touch start so we can simulate clicks because Mapbox doesn't
            // do touch clicks correctly
            const startPosition: [number, number] = [
              firstTouch.clientX,
              firstTouch.clientY,
            ];
            setTouchStart(startPosition);
            setTouchEnd(startPosition);
          }}
          onTouchMove={(event) => {
            const firstTouch = event.originalEvent.touches[0];
            if (!firstTouch) {
              return;
            }
            // update touch position
            const currentPosition: [number, number] = [
              firstTouch.clientX,
              firstTouch.clientY,
            ];
            setTouchEnd(currentPosition);

            // close click info if we're dragging
            if (
              !touchStart ||
              Math.abs(touchStart[0] - currentPosition[0]) > 10 ||
              Math.abs(touchStart[1] - currentPosition[1]) > 10
            ) {
              setClickInfo(undefined);
            }
          }}
          onTouchEnd={(event) => {
            // ignore drags
            if (
              !touchStart ||
              !touchEnd ||
              Math.abs(touchStart[0] - touchEnd[0]) > 10 ||
              Math.abs(touchStart[1] - touchEnd[1]) > 10
            ) {
              return;
            }

            const {
              features,
              point: { x, y },
            } = event;
            const hoveredFeature = features && features[0];

            setClickInfo(
              hoveredFeature ? { feature: hoveredFeature, x, y } : undefined
            );
          }}
        >
          {/* markers */}
          {facilityMarkers}
          {!hideRobots && robotMarkers}

          {/* sources */}
          {blockSource}
          {lineSource}

          {/* layers */}
          {blockLayers}
          {lineLayers}

          {/* popups */}
          {(hoverInfo || clickInfo) &&
            (() => {
              const info = clickInfo ?? hoverInfo;
              if (!info) {
                return;
              }
              return (
                // not actually interactable, just to prevent map clicks
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                <div
                  className={classes(
                    "fixed bg-darken-700 text-white p-2 z-[3]",
                    {
                      "pointer-events-none": hoverInfo && !clickInfo,
                      "cursor-default": clickInfo && !hoverInfo,
                    }
                  )}
                  style={{
                    left:
                      (mapWrapper.current?.getBoundingClientRect().left ?? 0) +
                      info.x,
                    top:
                      (mapWrapper.current?.getBoundingClientRect().top ?? 0) +
                      info.y,
                  }}
                  onClick={(event) => event.stopPropagation()}
                >
                  {isInternal && (
                    <div className="italic text-xs mb-4 whitespace-nowrap">
                      {info.feature.properties?._time}
                    </div>
                  )}
                  {entries(info.feature.properties).map(
                    ([key, value], index) => {
                      if (key.startsWith("_")) {
                        return;
                      }
                      return (
                        <div
                          className={classes("text-sm whitespace-nowrap", {
                            "font-bold": index === 0,
                          })}
                          key={key}
                        >
                          {key}: {value}
                        </div>
                      );
                    }
                  )}
                  <div className="font-mono text-xs mt-4 whitespace-nowrap">
                    {t("components.map.heatmaps.fields.size", {
                      width: formatMeasurement(
                        t,
                        i18n,
                        measurementSystem,
                        info.feature.properties?._width,
                        "ft"
                      ),
                      length: formatMeasurement(
                        t,
                        i18n,
                        measurementSystem,
                        info.feature.properties?._length,
                        "ft"
                      ),
                      area: `${formatMeasurement(
                        t,
                        i18n,
                        measurementSystem,
                        (info.feature.properties?._width ?? 0) *
                          (info.feature.properties?._length ?? 0),
                        "ft2"
                      )}`,
                    })}
                  </div>
                  <div className="font-mono text-xs whitespace-nowrap">
                    {t("components.map.heatmaps.fields.location", {
                      latitude: info.feature.properties?._latitude,
                      longitude: info.feature.properties?._longitude,
                    })}
                  </div>
                  {isInternal && info.feature.properties?._id && (
                    <div className="font-mono text-xs whitespace-nowrap">
                      {t("components.map.heatmaps.fields.block", {
                        block: info.feature.properties._id,
                      })}
                    </div>
                  )}
                </div>
              );
            })()}

          {/* controls */}
          <GeolocateControl position="top-left" />
          <NavigationControl position="top-left" />
          <FilterControl
            className="mt-[146px] ml-[10px] print:hidden"
            open={isFilterPaneOpen}
            mapFilters={filters}
            setOpen={(isOpen) => {
              setFilterPaneOpen(isOpen);
              setHeatmapPaneOpen(false);
            }}
          />
          {isFilterPaneOpen && (
            <FilterPane
              filters={filters}
              onClose={() => setFilterPaneOpen(false)}
              onChange={(mapFilters) => setFilters(mapFilters)}
              robots={robots ?? []}
            />
          )}
          {(isInternal || hasSpatial) && hasBlocks && (
            <>
              <HeatmapControl
                className="mt-[10px] ml-[10px] print:hidden"
                open={isHeatmapPaneOpen}
                setOpen={(isOpen) => {
                  setHeatmapPaneOpen(isOpen);
                  setFilterPaneOpen(false);
                }}
                selectedMetricId={selectedMetricId}
              />
              <HeatmapLegend
                className={legendClassName}
                isRelative={isRelative}
                setRelative={setRelative}
                onOpenMenu={
                  isHeatmapPaneOpen
                    ? undefined
                    : () => {
                        setHeatmapPaneOpen(true);
                        setFilterPaneOpen(false);
                      }
                }
                selectedMetricId={selectedMetricId}
              />
              {isHeatmapPaneOpen && (
                <HeatmapPane
                  onClose={() => setHeatmapPaneOpen(false)}
                  selectedMetricId={selectedMetricId}
                  setSelectedMetricId={setSelectedMetricId}
                />
              )}
            </>
          )}
          {!hideMeasure && (
            <MeasureControl
              className="mt-[10px] ml-[10px] print:hidden"
              draw={draw}
              map={map.current}
              isMapReady={isInitialized}
            />
          )}
          <Tooltip
            title={t("components.map.bounds.reset")}
            placement="right"
            arrow
          >
            <div
              className={classes(
                "mt-[10px] ml-[10px] print:hidden",
                "mapboxgl-ctrl mapboxgl-ctrl-group w-fit"
              )}
            >
              <button onClick={() => resetBounds(bounds)}>
                <ResetBoundsIcon className="p-1 mapboxgl-ctrl-icon" />
              </button>
            </div>
          </Tooltip>
          {!hideFullscreen && (
            <Tooltip
              title={
                isFullscreen
                  ? t("utils.actions.exitLong", {
                      subject: t("components.map.fullscreen"),
                    })
                  : t("components.map.fullscreen")
              }
              placement="right"
              arrow
            >
              <div
                className={classes(
                  "mt-[10px] ml-[10px] print:hidden",
                  "mapboxgl-ctrl mapboxgl-ctrl-group w-fit"
                )}
              >
                <button onClick={() => setFullscreen(!isFullscreen)}>
                  {isFullscreen ? (
                    <FullscreenExitIcon className="p-1 mapboxgl-ctrl-icon" />
                  ) : (
                    <FullscreenIcon className="p-1 mapboxgl-ctrl-icon" />
                  )}
                </button>
              </div>
            </Tooltip>
          )}
          {extraControls}
        </MapBox>
      </Card>
    );
  },
  { i18nKey: "components.map.errors.failed" }
);
