import { addNotification } from "portal/state/notifications";
import { alignBlocks, getSeriesColor } from "portal/utils/charts";
import { BlockResponse } from "protos/portal/spatial";
import {
  CartesianScaleTypeRegistry,
  ChartData,
  Point,
  ScaleOptionsByType,
} from "chart.js";
import {
  Checkbox,
  FormControl,
  FormGroup,
  FormLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import {
  DATE_PATH_FORMAT,
  snapToDayEnd,
  snapToDayStart,
} from "portal/utils/dates";
import { DateTime } from "luxon";
import { DeepPartial } from "chart.js/dist/types/utils";
import { entries, keys, values } from "portal/utils/objects";
import { formatList, titleCase } from "portal/utils/strings";
import { formatMetric } from "portal/components/measurement/formatters";
import { getCustomerSerial } from "portal/utils/robots";
import {
  INPUT_DARK,
  LABEL,
  SELECT_DARK,
  TEXT_FIELD_DARK,
  theme,
} from "portal/utils/theme";
import {
  isSpatialMetricNumber,
  SpatialMetricNumber,
} from "portal/utils/metrics";
import { isUndefined } from "portal/utils/identity";
import { Line } from "react-chartjs-2";
import { Loading } from "portal/components/Loading";
import { QueryType, useQuery } from "portal/utils/hooks/useQuery";
import { range } from "portal/utils/arrays";
import { ReportTools } from "portal/components/reports/ReportTools";
import { SPATIAL_METRICS } from "portal/utils/spatialMetrics";
import { useDispatch } from "react-redux";
import {
  useLazyGetSpatialQuery,
  useListRobotsQuery,
} from "portal/state/portalApi";
import { useLazyPopups, useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { useMemoAsync } from "portal/utils/hooks/useMemoAsync";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { ViewPlaceholder } from "portal/components/ViewPlaceholder";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { WithSkeleton } from "portal/components/WithSkeleton";
import React, { FunctionComponent, useMemo } from "react";

const graphableMetrics = SPATIAL_METRICS.filter((metric) =>
  isSpatialMetricNumber(metric)
);

enum GroupBy {
  ROBOT = "robot",
  METRIC = "metric",
}

export const ExploreGraph: FunctionComponent = withAuthenticationRequired(
  function ExploreGraph() {
    const { isInternal, customer, measurementSystem } = useSelf();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    // date range
    const [startSeconds, setStartSeconds] = useQuery<number>(
      "startDate",
      QueryType.NUMBER,
      snapToDayStart(DateTime.local().minus({ days: 7 })).toUnixInteger()
    );
    const startDate = useMemo<DateTime | undefined>(
      () =>
        isUndefined(startSeconds)
          ? undefined
          : DateTime.fromSeconds(startSeconds),
      [startSeconds]
    );
    const [endSeconds, setEndSeconds] = useQuery<number>(
      "endDate",
      QueryType.NUMBER,
      snapToDayEnd(DateTime.local()).toUnixInteger()
    );
    const endDate = useMemo<DateTime | undefined>(() => {
      return isUndefined(endSeconds)
        ? undefined
        : DateTime.fromSeconds(endSeconds);
    }, [endSeconds]);
    const dates = useMemo<string[]>(() => {
      if (!endDate || !startDate) {
        return [];
      }
      const duration = endDate.diff(startDate);
      return range(Math.round(duration.as("days") + 1)).map((index) =>
        endDate.minus({ days: index }).toFormat(DATE_PATH_FORMAT)
      );
    }, [startDate, endDate]);

    // robots
    const [robotIds, setRobotIds] = useQuery<number[]>(
      "robots",
      QueryType.ARRAY_NUMBER,
      []
    );
    const {
      data: summaries,
      isLoading: isRobotsLoading,
      isError: isRobotsError,
      error: robotsError,
    } = useQueryPopups(useListRobotsQuery({}));
    const selectedSummaries = useMemo(() => {
      return summaries?.filter(
        (summary) =>
          summary.robot?.db?.id && robotIds?.includes(summary.robot.db.id)
      );
    }, [robotIds, summaries]);

    // blocks
    const [getSpatial, { isBlocksLoading }] = useLazyPopups(
      useLazyGetSpatialQuery()
    );
    const blocksBySerial = useMemoAsync<Record<string, BlockResponse[]>>(
      async () => {
        if (!selectedSummaries?.length || dates.length === 0) {
          return {};
        }
        const { data } = await getSpatial({
          serials: selectedSummaries
            .map((summary) => summary.robot?.serial)
            .filter((serial) => !isUndefined(serial)),
          dates,
        });
        if (!data) {
          return {};
        }
        let queryLimitReached = false;
        const allBlocks: Record<string, BlockResponse[]> = {};
        for (const [serial, blocksByDate] of entries(data.blocks)) {
          allBlocks[serial] = [];
          for (const response of values(blocksByDate.blocks)) {
            allBlocks[serial].push(...response.blocks);
            if (response.queryLimitReached) {
              queryLimitReached = true;
            }
          }
        }
        if (queryLimitReached) {
          dispatch(
            addNotification({
              message: t("components.ErrorBoundary.queryLimitReached"),
              variant: "warning",
            })
          );
        }
        return allBlocks;
      },
      [dates, dispatch, getSpatial, selectedSummaries, t],
      {}
    );

    const [groupBy, setGroupBy] = useQuery<GroupBy>(
      "groupBy",
      QueryType.STRING,
      GroupBy.ROBOT
    );

    const [selectedMetricIds, setSelectedMetricIds] = useQuery<string[]>(
      "metrics",
      QueryType.ARRAY_STRING,
      []
    );
    const selectedMetrics = useMemo(() => {
      return graphableMetrics.filter((metric) =>
        selectedMetricIds?.includes(metric.id)
      );
    }, [selectedMetricIds]);

    // data
    const data = useMemo<Record<string, ChartData<"line">>>(() => {
      const results: Record<string, ChartData<"line">> = {};
      if (!selectedMetricIds || !startDate || !endDate) {
        return results;
      }
      if (groupBy === GroupBy.ROBOT) {
        for (const summary of selectedSummaries ?? []) {
          const serial = summary.robot?.serial;
          if (!serial) {
            continue;
          }
          const title = isInternal ? serial : getCustomerSerial(t, serial);
          results[title] = {
            datasets: [],
          };
          if (!serial) {
            return results;
          }
          const blocks = blocksBySerial[serial];
          if (!blocks) {
            return results;
          }
          const alignedData = alignBlocks(blocks, selectedMetrics);
          for (const [index, metric] of selectedMetrics.entries()) {
            const data: Point[] = [];
            for (const block of alignedData) {
              const value = block[metric.id];
              if (isUndefined(value)) {
                continue;
              }
              data.push({
                x: Number(block.timestampMs),
                y: value,
              });
            }
            results[title].datasets.push({
              hoverBorderColor: theme.colors.white,
              pointHoverBackgroundColor: theme.colors.white,
              pointHoverBorderColor: theme.colors.white,
              fill: metric.fill ?? false,
              // carbon.actions.compareKeys.ignoreDynamic
              label: t(`utils.metrics.spatial.metrics.${metric.id}`),
              data,
              yAxisID: `y${metric.units.toString()}`,
              borderColor: getSeriesColor(index),
              backgroundColor: getSeriesColor(index),
            });
          }
        }
      } else {
        for (const metric of selectedMetrics) {
          // carbon.actions.compareKeys.ignoreDynamic
          const title = t(`utils.metrics.spatial.metrics.${metric.id}`);
          results[title] = {
            datasets: [],
          };
          for (const [index, [serial, blocks]] of entries(
            blocksBySerial
          ).entries()) {
            const data: Point[] = [];
            const alignedData = alignBlocks(blocks, [metric]);
            for (const block of alignedData) {
              const value = block[metric.id];
              if (isUndefined(value)) {
                continue;
              }
              data.push({
                x: Number(block.timestampMs),
                y: value,
              });
            }
            results[title].datasets.push({
              hoverBorderColor: theme.colors.white,
              pointHoverBackgroundColor: theme.colors.white,
              pointHoverBorderColor: theme.colors.white,
              fill: metric.fill ?? false,
              label: isInternal ? serial : getCustomerSerial(t, serial),
              data,
              yAxisID: `y${metric.units.toString()}`,
              borderColor: getSeriesColor(index),
              backgroundColor: getSeriesColor(index),
            });
          }
        }
      }
      return results;
    }, [
      blocksBySerial,
      endDate,
      groupBy,
      isInternal,
      selectedMetricIds,
      selectedMetrics,
      selectedSummaries,
      startDate,
      t,
    ]);

    const selectLabel =
      selectedMetricIds && selectedMetricIds.length > 0
        ? titleCase(t("utils.metrics.metric_other"))
        : t("views.reports.tools.metricsLabel.select");

    if (isRobotsLoading) {
      return <Loading failed={isRobotsError} error={robotsError} />;
    }

    return (
      <>
        <div className="flex gap-4">
          <ReportTools
            // MUI requires null
            // eslint-disable-next-line unicorn/no-null
            dateRange={[startDate ?? null, endDate ?? null]}
            customerIds={isInternal ? [] : [customer?.db?.id ?? -1]}
            selectedRobots={robotIds}
            onDateRangeChange={([startDate, endDate]) => {
              setStartSeconds(
                startDate
                  ? snapToDayStart(startDate).toUnixInteger()
                  : undefined
              );
              setEndSeconds(
                endDate ? snapToDayEnd(endDate).toUnixInteger() : undefined
              );
            }}
            onSelectedRobotsChange={(robotIds) => setRobotIds(robotIds)}
            themeProps={{
              field: TEXT_FIELD_DARK,
              label: LABEL,
              select: SELECT_DARK,
              input: INPUT_DARK,
            }}
          />
          <FormControl>
            <InputLabel {...LABEL}>{selectLabel}</InputLabel>
            <Select<string[]>
              {...SELECT_DARK}
              multiple
              value={selectedMetricIds}
              input={<OutlinedInput {...INPUT_DARK} label={selectLabel} />}
              onChange={(event) => {
                let selectedMetricIds = event.target.value;
                if (typeof selectedMetricIds === "string") {
                  selectedMetricIds = [selectedMetricIds];
                }
                setSelectedMetricIds(selectedMetricIds);
              }}
              defaultValue={[]}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return t("views.reports.tools.metricsLabel.select");
                }
                if (selected.length === graphableMetrics.length) {
                  return t("views.reports.tools.metricsLabel.all");
                }
                return formatList(
                  t,
                  selectedMetrics.map((metric) =>
                    // carbon.actions.compareKeys.ignoreDynamic
                    t(`utils.metrics.spatial.metrics.${metric.id}`)
                  )
                );
              }}
            >
              {graphableMetrics.map((metric) => (
                <MenuItem key={metric.id} value={metric.id}>
                  <Checkbox
                    checked={
                      selectedMetricIds && selectedMetricIds.includes(metric.id)
                    }
                  />
                  <ListItemText
                    // carbon.actions.compareKeys.ignoreDynamic
                    primary={t(`utils.metrics.spatial.metrics.${metric.id}`)}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel className="text-xs text-gray-500">
              {t("views.reports.explore.groupBy")}
            </FormLabel>
            <FormGroup row className="flex items-center">
              <Typography>{titleCase(t("models.robots.robot_one"))}</Typography>
              <Switch
                classes={{ thumb: "bg-white", track: "bg-gray-400" }}
                checked={groupBy === GroupBy.METRIC}
                onChange={(event, isEnabled) =>
                  setGroupBy(isEnabled ? GroupBy.METRIC : GroupBy.ROBOT)
                }
              />
              <Typography>
                {titleCase(t("utils.metrics.metric_one"))}
              </Typography>
            </FormGroup>
          </FormControl>
        </div>
        {!selectedSummaries?.length && (
          <ViewPlaceholder text={t("views.reports.tools.robotsLabel.select")} />
        )}
        {selectedMetrics.length === 0 && (
          <ViewPlaceholder
            text={t("views.reports.tools.metricsLabel.select")}
          />
        )}
        {selectedSummaries?.length &&
          selectedMetrics.length > 0 &&
          entries(data).map(([groupLabel, data]) => (
            <WithSkeleton
              key={groupLabel}
              variant="rectangular"
              className="w-full flex-grow min-h-96"
              success={!isBlocksLoading}
            >
              <div>
                <Line
                  options={{
                    elements: {
                      point: { radius: 0 },
                    },
                    locale: i18n.language,
                    scales: {
                      x: {
                        type: "time",
                        time: {
                          unit: "hour",
                        },
                        min: startDate?.toMillis(),
                        max: endDate?.toMillis(),
                        afterTickToLabelConversion: (axis) => {
                          for (const tick of axis.ticks) {
                            const tickDate = DateTime.fromMillis(tick.value);
                            const isMidnight = tickDate.hour === 0;
                            tick.major = isMidnight;
                            tick.label = tickDate.toLocaleString(
                              isMidnight
                                ? {
                                    day: "numeric",
                                    month: "short",
                                  }
                                : { hour: "numeric" },
                              {
                                locale: i18n.language,
                              }
                            );
                          }
                        },
                      },
                      ...(() => {
                        const unitExamples: Record<
                          string,
                          SpatialMetricNumber
                        > = {};
                        for (const metric of selectedMetrics) {
                          unitExamples[metric.units.toString()] = metric;
                        }
                        const units = keys(unitExamples);
                        const axes: Record<
                          string,
                          DeepPartial<
                            ScaleOptionsByType<keyof CartesianScaleTypeRegistry>
                          >
                        > = {};
                        for (const [index, unit] of units.entries()) {
                          const metric = unitExamples[unit];
                          if (!metric) {
                            continue;
                          }
                          const isFirst = index === 0;
                          axes[`y${unit}`] = {
                            suggestedMin: metric.minY ?? 0,
                            suggestedMax: metric.maxY,
                            ticks: {
                              callback: (value: any) =>
                                formatMetric(
                                  t,
                                  i18n,
                                  measurementSystem,
                                  metric,
                                  value
                                ).toString(),
                            },
                            grid: {
                              drawOnChartArea: isFirst,
                            },
                            position: isFirst ? "left" : "right",
                          };
                        }
                        return axes;
                      })(),
                    },
                    plugins: {
                      title: {
                        text: groupLabel,
                      },
                      tooltip: {
                        callbacks: {
                          title: (items) =>
                            items[0] &&
                            DateTime.fromMillis(
                              items[0].parsed.x
                            ).toLocaleString(
                              {
                                month: "short",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                              },
                              { locale: i18n.language }
                            ),
                          label: (context) => {
                            const metric = SPATIAL_METRICS.find(
                              (metric) =>
                                (groupBy === GroupBy.ROBOT
                                  ? context.dataset.label
                                  : groupLabel) ===
                                // carbon.actions.compareKeys.ignoreDynamic
                                t(`utils.metrics.spatial.metrics.${metric.id}`)
                            );
                            if (!metric) {
                              return `${context.dataset.label}: ${context.parsed.y}`;
                            }
                            return `${context.dataset.label}: ${formatMetric(
                              t,
                              i18n,
                              measurementSystem,
                              metric,
                              context.parsed.y
                            )}`;
                          },
                        },
                      },
                    },
                  }}
                  data={data}
                />
              </div>
            </WithSkeleton>
          ))}
      </>
    );
  }
);
