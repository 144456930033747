import { CornerFlag } from "./CornerFlag";
import { useTranslation } from "react-i18next";
import React, { FunctionComponent } from "react";

interface Props {
  title?: string;
}
export const BetaFlag: FunctionComponent<Props> = ({ title }) => {
  const { t } = useTranslation();
  return (
    <CornerFlag
      label={t("components.EnvironmentFlag.beta")}
      className="bg-blue-500 print:hidden"
      title={title ?? t("components.BetaFlag.tooltip")}
    />
  );
};
