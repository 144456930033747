import {
  HUMANIZE_DURATION_LANGUAGES,
  portalLanguageOrDefault,
} from "portal/i18nConstants";
import { i18n as I18n, TFunction } from "i18next";
import humanizeDuration, { Options } from "humanize-duration";

export const isString = (input: unknown): input is string =>
  typeof input === "string" || input instanceof String;

export const formatList = (t: TFunction, list: string[]): string => {
  if (list.length === 0) {
    return "";
  }
  if (list.length === 1) {
    return t("utils.lists.1", { a: list[0] });
  }
  if (list.length === 2) {
    return t("utils.lists.2", { a: list[0], b: list[1] });
  }
  const first = list.shift() ?? "";
  const last = list.pop();
  let result = first;
  for (const item of list) {
    result = t("utils.lists.3+", { a: result, b: item });
  }
  result = t("utils.lists.+3", { b: result, c: last });
  return result;
};

export const capitalize = (input?: string): string =>
  input ? input.toLowerCase().charAt(0).toUpperCase() + input.slice(1) : "";

export const uncapitalize = (input?: string): string =>
  input ? input.slice(0, 1).toLowerCase() + input.slice(1) : "";

export const titleCase = (input?: string): string =>
  input
    ? input
        .toLowerCase()
        .split(" ")
        .map((word) => capitalize(word))
        .join(" ")
    : "";

export const snakeToCamel = (input: string): string => {
  if (!input) {
    return "";
  }
  const pascal = snakeToPascal(input);
  const firstLetter = pascal[0];
  if (!firstLetter) {
    return "";
  }
  return firstLetter.toLowerCase() + pascal.slice(1);
};

// we use less readable methods here for performance reasons because this is run
// over all data receive via API
export const snakeToPascal = (input: string): string => {
  const segments = input.split("_");
  let index = segments.length;
  while (index-- > 0) {
    const segment = segments[index];
    if (!segment || segment.length === 0) {
      continue;
    }
    const firstLetter = segment[0];
    if (!firstLetter) {
      continue;
    }
    segments[index] = firstLetter.toUpperCase() + segment.slice(1);
  }
  return "".concat(...segments);
};

export const camelToSnake = (input?: string): string =>
  input
    ? input.replaceAll(/[A-Z]/g, (letter: string) => `_${letter.toLowerCase()}`)
    : "";

export const formatDuration = (
  milliseconds: number | undefined,
  i18n: I18n,
  options: Options = {}
): string => {
  return humanizeDuration(milliseconds ?? 0, {
    largest: options.largest ?? 1,
    round: options.round ?? false,
    maxDecimalPoints: options.maxDecimalPoints ?? 1,
    language:
      HUMANIZE_DURATION_LANGUAGES[portalLanguageOrDefault(i18n.language)],
    ...options,
  });
};
