import { Alert, Tab, Tabs } from "@mui/material";

import { AdminSubpath, getAdminPath } from "portal/utils/routing";
import { Config } from "portal/components/config/Config";
import {
  getTemplateSerial,
  RobotClass,
  toLocalizedRobotClass,
} from "portal/utils/robots";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { values } from "portal/utils/objects";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { FunctionComponent, useEffect } from "react";

export const AdminConfigs: FunctionComponent = withAuthenticationRequired(
  function AdminConfigs() {
    const { user, isInternal } = useSelf();
    const { class: inputClass } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
      if (
        !inputClass ||
        !values(RobotClass).includes(inputClass as RobotClass)
      ) {
        navigate(`${getAdminPath(AdminSubpath.CONFIG)}/${RobotClass.Slayers}`);
      }
    }, [inputClass, navigate]);
    const selected = inputClass as RobotClass;
    const { t } = useTranslation();
    if (!isInternal) {
      return;
    }

    return (
      <>
        {user && user.email !== "scochrane@carbonrobotics.com" && (
          <Alert severity="error" className="mb-4">
            {t("views.admin.config.warnings.notSimon")}
          </Alert>
        )}
        <Alert severity="warning" className="mb-8">
          {t("views.admin.config.warnings.global", {
            class: toLocalizedRobotClass(t, isInternal, selected),
          })}
        </Alert>
        <Tabs color="primary" className="mb-8" value={selected}>
          {[
            RobotClass.Slayers,
            RobotClass.Reapers,
            RobotClass.Rtcs,
            RobotClass.Simulators,
            RobotClass.Buds,
          ].map((robotClass) =>
            robotClass === RobotClass.Unknown ? undefined : (
              <Tab
                component={Link}
                key={robotClass}
                to={`${getAdminPath(AdminSubpath.CONFIG)}/${robotClass}`}
                value={robotClass}
                label={toLocalizedRobotClass(t, isInternal, robotClass)}
              />
            )
          )}
        </Tabs>
        {window._jsenv.REACT_APP_USE_ROBOT_SYNCER_TEMPLATES === "true" ? (
          <Config
            robotClass={selected}
            key={selected}
            basePath={`${getAdminPath(AdminSubpath.CONFIG)}/${selected}`}
          />
        ) : (
          <Config
            serial={getTemplateSerial(selected)}
            key={getTemplateSerial(selected)}
            basePath={`${getAdminPath(AdminSubpath.CONFIG)}/${selected}`}
          />
        )}
      </>
    );
  }
);
