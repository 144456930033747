import { Facility, FacilityType } from "./geo";
import { formatRobotClass, getClass, RobotClass } from "./robots";
import { keys } from "./objects";
import { RobotSummaryResponse } from "protos/portal/robots";
import { TFunction } from "i18next";

export interface FilterData<T> {
  facilities: Record<FacilityType, T>;
  robots: Record<RobotClass, T>;
}

export const DEFAULT_MAP_FILTERS: FilterData<boolean> = {
  facilities: {
    [FacilityType.CUSTOMER_OFFICE]: true,
    [FacilityType.HQ]: true,
    [FacilityType.PO_BOX]: true,
    [FacilityType.SHOP]: true,
    [FacilityType.STORAGE]: true,
    [FacilityType.SUPPORT_BASE]: true,
  },
  robots: {
    [RobotClass.Buds]: true,
    [RobotClass.Reapers]: true,
    [RobotClass.Rtcs]: true,
    [RobotClass.Simulators]: true,
    [RobotClass.Slayers]: true,
    [RobotClass.Unknown]: true,
  },
};

export type FilterGroupTypes = "facilities" | "robots";
export type FilterItemTypes = FacilityType | RobotClass;

export const isGroupFacilities = (
  groupName: FilterGroupTypes
): groupName is "facilities" => groupName === "facilities";

export const isItemFacility = (
  groupName: FilterGroupTypes,
  itemName: FilterItemTypes
): itemName is FacilityType => isGroupFacilities(groupName);

export const isGroupRobots = (
  groupName: FilterGroupTypes
): groupName is "robots" => groupName === "robots";

export const isItemRobot = (
  groupName: FilterGroupTypes,
  itemName: FilterItemTypes
): itemName is RobotClass => isGroupRobots(groupName);

export const getHiddenItemCount = (
  filters: FilterData<boolean>,
  groups?: FilterGroupTypes[]
): number => {
  let count = 0;
  // not sure why I can't get the types in this section to play nice
  for (const group of keys(filters) as FilterGroupTypes[]) {
    if (!groups || groups.includes(group)) {
      for (const itemType of keys(filters[group]) as FilterItemTypes[]) {
        if (isGroupFacilities(group) && isItemFacility(group, itemType)) {
          count = filters[group][itemType] ? count : count + 1;
          continue;
        }
        if (isGroupRobots(group) && isItemRobot(group, itemType)) {
          count = filters[group][itemType] ? count : count + 1;
          continue;
        }
        console.warn(
          `non-matching filter group and item: ${group} ${itemType}`
        );
      }
    }
  }
  return count;
};

export const showFilterDisplayName = (
  t: TFunction,
  isInternal: boolean,
  groupName: FilterGroupTypes,
  itemName: FilterItemTypes
): string => {
  if (isItemFacility(groupName, itemName)) {
    // carbon.actions.compareKeys.ignoreDynamic
    return t(`components.map.filters.${itemName}`);
  }
  if (isItemRobot(groupName, itemName)) {
    return formatRobotClass(t, isInternal, itemName);
  }
  return itemName;
};

export const getMapItemCounts = (
  facilities: Facility[],
  robots: RobotSummaryResponse[]
): FilterData<number> => {
  const counts: FilterData<number> = {
    facilities: {
      [FacilityType.CUSTOMER_OFFICE]: 0,
      [FacilityType.HQ]: 0,
      [FacilityType.PO_BOX]: 0,
      [FacilityType.SHOP]: 0,
      [FacilityType.STORAGE]: 0,
      [FacilityType.SUPPORT_BASE]: 0,
    },
    robots: {
      [RobotClass.Buds]: 0,
      [RobotClass.Reapers]: 0,
      [RobotClass.Rtcs]: 0,
      [RobotClass.Simulators]: 0,
      [RobotClass.Slayers]: 0,
      [RobotClass.Unknown]: 0,
    },
  };
  for (const facility of facilities) {
    counts.facilities[facility.type] += 1;
  }
  for (const summary of robots) {
    const type = getClass(summary.robot);
    counts.robots[type] += 1;
  }
  return counts;
};
