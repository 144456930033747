import { AdvancedCategory } from "./AdvancedCategory";
import { Button, Card, Menu, MenuItem, Typography } from "@mui/material";
import {
  CategoryClassification,
  categoryClassificationFromJSON,
  DiscriminatorConfig,
  DiscriminatorTypeCategory,
} from "protos/almanac/almanac";
import {
  DEFAULT_CATEGORY,
  getCategoryTitle,
  getDefaultWeedCategory,
} from "portal/utils/discriminator";
import {
  filterCategories,
  getWeedCategoryName,
  WEED_CATEGORIES,
} from "portal/utils/almanac";
import { findWhere, sortBy, without } from "portal/utils/arrays";
import OpenCategoryIcon from "@mui/icons-material/ChevronRightOutlined";

import { classes, WHITE_BUTTON } from "portal/utils/theme";
import { ConfigCrop } from "protos/portal/configs";
import { isUndefined } from "portal/utils/identity";
import { titleCase } from "portal/utils/strings";
import { useTranslation } from "react-i18next";
import NewIcon from "@mui/icons-material/AddOutlined";
import React, { FunctionComponent, useState } from "react";

interface Props {
  discriminator: DiscriminatorConfig;
  crops: ConfigCrop[];
  onChange: (newDescriminator: DiscriminatorConfig) => void;
}

export const AdvancedDiscriminator: FunctionComponent<Props> = ({
  discriminator,
  crops,
  onChange,
}) => {
  // selection state
  const [selectedCategory, setSelectedCategory] = useState<
    DiscriminatorTypeCategory | undefined
  >();

  const { t } = useTranslation();

  // menu anchors
  const [weedsAnchor, setWeedsAnchor] = useState<
    HTMLButtonElement | undefined
  >();
  const [cropsAnchor, setCropsAnchor] = useState<
    HTMLButtonElement | undefined
  >();

  // mutations
  const addCategory = (newCategory: DiscriminatorTypeCategory): void => {
    const updated = structuredClone(discriminator);
    updated.categories.push(newCategory);
    onChange(updated);
  };

  const removeCategory = (oldCategory: DiscriminatorTypeCategory): void => {
    const updated = structuredClone(discriminator);
    updated.categories = without(updated.categories, oldCategory);
    onChange(updated);
  };

  const updateCategory = (newCategory: DiscriminatorTypeCategory): void => {
    const updated = structuredClone(discriminator);
    const oldCategory = findWhere(updated.categories, {
      type: { category: newCategory.type?.category },
    });
    updated.categories = [
      ...without(updated.categories, oldCategory).filter(
        (category) => !isUndefined(category)
      ),
      newCategory,
    ];
    onChange(updated);
  };

  const { weedCategories, cropCategories } = filterCategories(
    discriminator.categories
  );

  // Show category overlay
  if (selectedCategory) {
    return (
      <AdvancedCategory
        category={selectedCategory}
        onChange={updateCategory}
        onClose={() => setSelectedCategory(undefined)}
        onDelete={() => {
          removeCategory(selectedCategory);
          setSelectedCategory(undefined);
        }}
        title={getCategoryTitle(t, selectedCategory, crops)}
        crops={crops}
      />
    );
  }

  return (
    <div className="flex flex-col h-full">
      {/* Weed Categories */}

      {/* Header */}
      <div className="flex items-center justify-between my-4">
        <Typography variant="h5">
          {titleCase(t("models.weeds.weed_other"))}
        </Typography>
        <Button
          {...WHITE_BUTTON}
          startIcon={<NewIcon />}
          disabled={weedCategories.length === WEED_CATEGORIES.size}
          onClick={(event) => setWeedsAnchor(event.currentTarget)}
        >
          {t("components.almanac.weeds.new")}
        </Button>
        <Menu
          anchorEl={weedsAnchor}
          open={Boolean(weedsAnchor)}
          onClose={() => setWeedsAnchor(undefined)}
        >
          {[...WEED_CATEGORIES].map((weed) => {
            const category = findWhere(weedCategories, {
              type: { category: weed },
            });
            if (category) {
              return;
            }
            return (
              <MenuItem
                key={weed}
                onClick={() => {
                  addCategory(getDefaultWeedCategory(weed));
                  setWeedsAnchor(undefined);
                }}
              >
                {getWeedCategoryName(t, weed)}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
      {/* List */}
      {weedCategories.length === 0 && (
        <div className="flex h-40 w-full items-center justify-center text-lighten-400">
          {t("components.almanac.weeds.none")}
        </div>
      )}
      {sortBy(weedCategories, (category) => getCategoryTitle(t, category)).map(
        (category) => (
          <AdvancedDiscriminatorCard
            key={category.type?.category}
            category={category}
            onClick={() => setSelectedCategory(category)}
            title={getCategoryTitle(t, category)}
            crops={crops}
          />
        )
      )}

      {/* Crop Categories */}

      {/* Header */}
      <div className="flex items-center justify-between my-4">
        <Typography variant="h5">
          {titleCase(t("models.crops.crop_other"))}
        </Typography>
        <Button
          {...WHITE_BUTTON}
          startIcon={<NewIcon />}
          onClick={(event) => setCropsAnchor(event.currentTarget)}
        >
          {t("components.almanac.crops.new")}
        </Button>
        <Menu
          anchorEl={cropsAnchor}
          open={Boolean(cropsAnchor)}
          onClose={() => setCropsAnchor(undefined)}
        >
          {sortBy(crops, "commonName").map((crop) => {
            const category = findWhere(cropCategories, {
              type: { category: crop.id },
            });
            if (category) {
              return;
            }
            return (
              <MenuItem
                key={crop.id}
                onClick={() => {
                  const newCategory = structuredClone(DEFAULT_CATEGORY);
                  newCategory.type = {
                    category: crop.id,
                    classification: categoryClassificationFromJSON(
                      CategoryClassification.CATEGORY_CROP
                    ),
                  };
                  addCategory(newCategory);
                  setCropsAnchor(undefined);
                }}
                className={classes({
                  "italic text-orange-200": !crop.isEnabled,
                })}
              >
                {crop.commonName}{" "}
                {crop.isEnabled ? "" : `(${t("utils.descriptors.disabled")})`}
              </MenuItem>
            );
          })}
        </Menu>
      </div>

      {/* List */}
      {cropCategories.length === 0 && (
        <div className="flex h-40 w-full items-center justify-center text-lighten-400">
          {t("components.almanac.crops.none")}
        </div>
      )}
      {sortBy(cropCategories, (category) =>
        getCategoryTitle(t, category, crops)
      ).map((category) => (
        <AdvancedDiscriminatorCard
          key={category.type?.category}
          category={category}
          onClick={() => setSelectedCategory(category)}
          title={getCategoryTitle(t, category, crops)}
          crops={crops}
        />
      ))}
    </div>
  );
};

interface CardProps {
  category: DiscriminatorTypeCategory;
  title: string;
  onClick: () => void;
  crops?: ConfigCrop[];
}

export const AdvancedDiscriminatorCard: FunctionComponent<CardProps> = ({
  category,
  title,
  onClick,
  crops,
}) => {
  const { t } = useTranslation();
  const isCrop =
    category.type?.classification === CategoryClassification.CATEGORY_CROP;
  const crop = crops?.find((crop) => crop.id === category.type?.category);
  if (isCrop && crops && !crop) {
    return;
  }
  return (
    <Card
      className="p-4 my-2 flex justify-between items-center cursor-pointer"
      onClick={() => onClick()}
    >
      <Typography
        variant="h6"
        className={classes({
          "italic text-orange-200": crop && !crop.isEnabled,
        })}
      >
        {title}{" "}
        {crop && !crop.isEnabled ? `(${t("utils.descriptors.disabled")})` : ""}
      </Typography>
      <OpenCategoryIcon />
    </Card>
  );
};
