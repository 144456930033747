export const NAMESPACE = "translations";
export const DEFAULT_LANG: PortalLanguage = "en-US";
export const LANGUAGES = [
  "da-DK",
  "de-DE",
  "en-US",
  "es-ES",
  "fr-FR",
  "lt-LT",
  "nl-NL",
  "pl-PL",
  "ro-RO",
] as const;

export type PortalLanguage = (typeof LANGUAGES)[number];
export type LanguageMap<V> = { [K in PortalLanguage]: V };

const LANGUAGES_SET = new Set<string>(LANGUAGES);

export const isPortalLanguage = (lang: string): lang is PortalLanguage => {
  return LANGUAGES_SET.has(lang);
};

export const portalLanguageOrDefault = (lang: string): PortalLanguage => {
  return isPortalLanguage(lang) ? lang : DEFAULT_LANG;
};

// We use the browser's Intl.DisplayNames but we want to use simpler names
// e.g. "English" instead of "American English" so we translate our specific
// locales to more general ones
export const DISPLAY_LANGUAGES: LanguageMap<string> = {
  "da-DK": "da",
  "de-DE": "de",
  "en-US": "en",
  "es-ES": "es",
  "fr-FR": "fr",
  "lt-LT": "lt",
  "nl-NL": "nl",
  "pl-PL": "pl",
  "ro-RO": "ro",
};

// https://github.com/EvanHahn/HumanizeDuration.js?tab=readme-ov-file#supported-languages
// Humanize Duration has a specific set of languages they support, so this
// matches up our locales with their list
export const HUMANIZE_DURATION_LANGUAGES: LanguageMap<string> = {
  "da-DK": "da",
  "de-DE": "de",
  "en-US": "en",
  "es-ES": "es",
  "fr-FR": "fr",
  "lt-LT": "lt",
  "nl-NL": "nl",
  "pl-PL": "pl",
  "ro-RO": "ro",
};
