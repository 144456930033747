import { useEffect, useRef, useState } from "react";

/**
 * TODO: should be replaced with useIntervalWhen from usehooks when that's stable
 * (currently it relies on experimental React features)
 *
 * This hook manages an interval timer that can be dynamically edited for timing,
 * and has pause/play/toggle functions. Also exposes pause state to consumers
 */
interface IntervalState {
  play: () => void;
  pause: () => void;
  toggle: () => void;
  isPaused: boolean;
}

export const useInterval = (
  callback: () => void,
  delay: number
): IntervalState => {
  const intervalRef = useRef<NodeJS.Timeout>();
  const [isPaused, setPaused] = useState(false);

  // keep track of the latest callback:
  const callbackRef = useRef(callback);
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (isPaused) {
      return;
    }

    intervalRef.current = setInterval(() => callbackRef.current(), delay);

    return () => clearInterval(intervalRef.current);
  }, [delay, isPaused]);

  // Returns a ref to the interval ID in case you want to clear it manually:
  return {
    isPaused,
    play: () => setPaused(false),
    pause: () => setPaused(true),
    toggle: () => setPaused((isPaused) => !isPaused),
  };
};
