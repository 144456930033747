import "chartjs-adapter-luxon";
import { BlockResponse } from "protos/portal/spatial";
import { carbon, GRAPH_COLORS, theme } from "./theme";
import {
  CategoryScale,
  Chart as ChartJS,
  Decimation,
  Filler,
  GridLineOptions,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TickOptions,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import { DateTime } from "luxon";
import { entries, mergeDeep } from "./objects";
import { mean } from "./math";
import { roundToMinute } from "./dates";
import { SpatialMetricNumber } from "./metrics";
import Zoom from "chartjs-plugin-zoom";

ChartJS.register(
  CategoryScale,
  Decimation,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
  Zoom
);
export const CHART_GRID_COLOR_MINOR = theme.colors.lighten["100"];
export const CHART_GRID_COLOR_MAJOR = theme.colors.lighten["300"];
export const CHART_LABEL_COLOR = theme.colors.lighten["500"];
const gridDefaults: Partial<GridLineOptions> = {
  color: (context) =>
    context.tick.major ? CHART_GRID_COLOR_MAJOR : CHART_GRID_COLOR_MINOR,
  tickColor: (context) =>
    context.tick.major ? CHART_GRID_COLOR_MAJOR : CHART_GRID_COLOR_MINOR,
};
const tickDefaults: Pick<TickOptions, "font" | "color"> = {
  font: {
    weight: (context) => (context.tick.major ? 800 : 400),
  },
  color: (context) =>
    context.tick.major ? theme.colors.white : CHART_LABEL_COLOR,
};

Object.assign(
  ChartJS.defaults,
  mergeDeep(ChartJS.defaults, {
    responsive: true,
    maintainAspectRatio: false,
    font: {
      family: carbon.typography.fontFamily,
      weight: 400,
    },
    elements: {
      line: {
        borderWidth: 2,
      },
    },
    color: theme.colors.white,
    interaction: {
      intersect: false,
      mode: "index",
      axis: "x",
    },
    scales: {
      linear: {
        grid: { ...gridDefaults },
        ticks: { ...tickDefaults },
      },
      time: {
        grid: { ...gridDefaults },
        ticks: { ...tickDefaults },
      },
    },
    parsing: false,
    plugins: {
      decimation: {
        algorithm: "lttb",
        enabled: true,
      },
      legend: {
        position: "top",
      },
      title: {
        color: theme.colors.white,
        display: true,
        font: {
          family: carbon.typography.h1.fontFamily,
          size: 24,
          weight: 400,
        },
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "xy",
        },
        limits: {
          x: {
            min: "original",
            max: "original",
          },
          y: {
            min: "original",
            max: "original",
          },
          y1: {
            min: "original",
            max: "original",
          },
          y2: {
            min: "original",
            max: "original",
          },
        },
        zoom: {
          wheel: {
            enabled: true,
            modifierKey: "ctrl",
          },
          pinch: {
            enabled: true,
          },
          mode: "xy",
        },
      },
    },
  })
);

Object.assign(
  ChartJS.overrides,
  mergeDeep(ChartJS.overrides, {
    line: {
      spanGaps: false,
    },
  })
);

export const getSeriesColor = (index: number): string =>
  GRAPH_COLORS[((index + 1) % GRAPH_COLORS.length) - 1] ?? theme.colors.white;

export const getTicks = (
  startTime: DateTime,
  endTime: DateTime
): Array<number> => {
  let currentHour = roundToMinute(startTime);
  const endHour = roundToMinute(endTime);
  const result: Array<number> = [];
  while (currentHour <= endHour) {
    result.push(currentHour.toUnixInteger());
    currentHour = currentHour.plus({ hours: 1 });
  }
  return result;
};

export interface AlignedBlock {
  [key: string | number]: number;
  timestampMs: number;
}

export const alignBlocks = (
  blocks: BlockResponse[],
  metrics: SpatialMetricNumber[]
): AlignedBlock[] => {
  const blocksByTime: Record<number, BlockResponse[]> = {};
  for (const block of blocks) {
    // drop blocks without timestamps
    if (!block.block?.end?.timestampMs || !block.block.start?.timestampMs) {
      continue;
    }
    const startTime = DateTime.fromMillis(
      Number(block.block.start.timestampMs)
    );
    const endTime = DateTime.fromMillis(Number(block.block.end.timestampMs));
    const duration = startTime.diff(endTime).as("seconds");
    // use the timestamp at the midpoint of the block rounded to the minute
    const time = roundToMinute(startTime.plus({ seconds: duration / 2 }));
    if (!(time.toUnixInteger() in blocksByTime)) {
      blocksByTime[time.toMillis()] = [];
    }
    const blockList = blocksByTime[time.toMillis()];
    if (!blockList) {
      continue;
    }
    blockList.push(block);
  }
  return entries(blocksByTime)
    .sort(([a], [b]) => a - b)
    .map(([time, blocks]) => {
      const alignedBlock: AlignedBlock = { timestampMs: time };
      for (const metric of metrics) {
        alignedBlock[metric.id] =
          mean(
            blocks.map((block) => {
              return metric.getValue(block);
            })
          ) ?? Number.NaN;
      }
      return alignedBlock;
    });
};

export const fillBlocks = (
  blocks: AlignedBlock[],
  startTime: DateTime,
  endTime: DateTime
): AlignedBlock[] => {
  let currentMinute = roundToMinute(startTime);
  let currentIndex = 0;
  const endMinute = roundToMinute(endTime);
  while (currentMinute <= endMinute) {
    const timestampMs = currentMinute.toMillis();
    if (blocks[currentIndex]?.timestampMs !== timestampMs) {
      blocks.splice(currentIndex, 0, {
        timestampMs,
      });
    }
    currentIndex++;
    currentMinute = currentMinute.plus({ minutes: 1 });
  }
  return blocks;
};
