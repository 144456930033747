import { isUndefined } from "./identity";

export const numberOrEmptyString = (
  value: number | string | undefined
): "" | number => {
  if (isUndefined(value)) {
    return "";
  }
  if (typeof value === "string" && value === "") {
    return "";
  }
  return Number(value);
};
