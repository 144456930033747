import { isUndefined } from "./identity";

export const round = (input: number, decimals: number = 0): number => {
  const scale = Math.pow(10, decimals);
  return Math.round((input + Number.EPSILON) * scale) / scale;
};

export const isEven = (input: number): boolean => input % 2 === 0;
export const isOdd = (input: number): boolean => input % 2 === 1;

export const mean = (input: number[]): number | undefined => {
  if (input.length === 0) {
    return;
  }
  const total = sum(input);
  return total / input.length;
};

export const constrain = (input: number, min: number, max: number): number => {
  return Math.min(Math.max(input, min), max);
};

export const sum = (input: number[]): number => {
  let sum = 0;
  for (const number of input) {
    sum += number;
  }
  return sum;
};

// input MUST BE SORTED
export const percentile = (
  input: number[],
  target: number
): number | undefined => {
  if (input.length === 0) {
    return;
  }
  if (target === 0) {
    return input[0];
  }
  if (target === 100) {
    return input.at(-1);
  }
  const index = (input.length - 1) * (target / 100);
  const lower = Math.floor(index);
  const upper = lower + 1;
  const weight = index % 1;
  const lowerValue = input[lower];
  const upperValue = input[upper];

  if (isUndefined(lowerValue) || isUndefined(upperValue)) {
    return undefined;
  }

  if (upper >= input.length) {
    return lowerValue;
  }
  return lowerValue * (1 - weight) + upperValue * weight;
};

export const randomBetween = (minimum: number, maximum: number): number =>
  Math.floor(Math.random() * (maximum - minimum + 1) + minimum);

export const clamp = (min: number, max: number, value: number): number =>
  Math.min(Math.max(value, min), max);
