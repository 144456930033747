// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.19.4
// source: portal/configs.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { ConfigNode } from "../config/api/config_service";

export const protobufPackage = "carbon.portal.configs";

export interface ConfigResponse {
  config: ConfigNode | undefined;
  template?: ConfigNode | undefined;
}

export interface ConfigCrop {
  commonName: string;
  carbonName: string;
  id: string;
  pinned: string;
  recommended: string;
  isEnabled: boolean;
}

function createBaseConfigResponse(): ConfigResponse {
  return { config: undefined, template: undefined };
}

export const ConfigResponse: MessageFns<ConfigResponse> = {
  encode(message: ConfigResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.config !== undefined) {
      ConfigNode.encode(message.config, writer.uint32(10).fork()).join();
    }
    if (message.template !== undefined) {
      ConfigNode.encode(message.template, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ConfigResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConfigResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.config = ConfigNode.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.template = ConfigNode.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConfigResponse {
    return {
      config: isSet(object.config) ? ConfigNode.fromJSON(object.config) : undefined,
      template: isSet(object.template) ? ConfigNode.fromJSON(object.template) : undefined,
    };
  },

  toJSON(message: ConfigResponse): unknown {
    const obj: any = {};
    if (message.config !== undefined) {
      obj.config = ConfigNode.toJSON(message.config);
    }
    if (message.template !== undefined) {
      obj.template = ConfigNode.toJSON(message.template);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ConfigResponse>, I>>(base?: I): ConfigResponse {
    return ConfigResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ConfigResponse>, I>>(object: I): ConfigResponse {
    const message = createBaseConfigResponse();
    message.config = (object.config !== undefined && object.config !== null)
      ? ConfigNode.fromPartial(object.config)
      : undefined;
    message.template = (object.template !== undefined && object.template !== null)
      ? ConfigNode.fromPartial(object.template)
      : undefined;
    return message;
  },
};

function createBaseConfigCrop(): ConfigCrop {
  return { commonName: "", carbonName: "", id: "", pinned: "", recommended: "", isEnabled: false };
}

export const ConfigCrop: MessageFns<ConfigCrop> = {
  encode(message: ConfigCrop, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.commonName !== "") {
      writer.uint32(10).string(message.commonName);
    }
    if (message.carbonName !== "") {
      writer.uint32(18).string(message.carbonName);
    }
    if (message.id !== "") {
      writer.uint32(26).string(message.id);
    }
    if (message.pinned !== "") {
      writer.uint32(34).string(message.pinned);
    }
    if (message.recommended !== "") {
      writer.uint32(42).string(message.recommended);
    }
    if (message.isEnabled !== false) {
      writer.uint32(48).bool(message.isEnabled);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ConfigCrop {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConfigCrop();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.commonName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.carbonName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.id = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pinned = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.recommended = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.isEnabled = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConfigCrop {
    return {
      commonName: isSet(object.commonName) ? globalThis.String(object.commonName) : "",
      carbonName: isSet(object.carbonName) ? globalThis.String(object.carbonName) : "",
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      pinned: isSet(object.pinned) ? globalThis.String(object.pinned) : "",
      recommended: isSet(object.recommended) ? globalThis.String(object.recommended) : "",
      isEnabled: isSet(object.isEnabled) ? globalThis.Boolean(object.isEnabled) : false,
    };
  },

  toJSON(message: ConfigCrop): unknown {
    const obj: any = {};
    if (message.commonName !== "") {
      obj.commonName = message.commonName;
    }
    if (message.carbonName !== "") {
      obj.carbonName = message.carbonName;
    }
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.pinned !== "") {
      obj.pinned = message.pinned;
    }
    if (message.recommended !== "") {
      obj.recommended = message.recommended;
    }
    if (message.isEnabled !== false) {
      obj.isEnabled = message.isEnabled;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ConfigCrop>, I>>(base?: I): ConfigCrop {
    return ConfigCrop.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ConfigCrop>, I>>(object: I): ConfigCrop {
    const message = createBaseConfigCrop();
    message.commonName = object.commonName ?? "";
    message.carbonName = object.carbonName ?? "";
    message.id = object.id ?? "";
    message.pinned = object.pinned ?? "";
    message.recommended = object.recommended ?? "";
    message.isEnabled = object.isEnabled ?? false;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
