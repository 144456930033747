import { Card, Typography } from "@mui/material";
import {
  CategoryClassification,
  DiscriminatorTypeCategory,
} from "protos/almanac/almanac";
import { TrustControl } from "./TrustControl";

import { classes } from "portal/utils/theme";
import { ConfigCrop } from "protos/portal/configs";
import { SIZE_INDEX } from "portal/utils/almanac";
import { useTranslation } from "react-i18next";
import React, { FunctionComponent, ReactNode } from "react";

interface Props {
  category: DiscriminatorTypeCategory;
  description?: ReactNode;
  onChange: (newCategory: DiscriminatorTypeCategory) => void;
  title: string;
  crops?: ConfigCrop[];
}

export const BasicDiscriminatorCard: FunctionComponent<Props> = ({
  category,
  description,
  onChange,
  title,
  crops,
}) => {
  const { t } = useTranslation();
  const isCrop =
    category.type?.classification === CategoryClassification.CATEGORY_CROP;
  const crop = crops?.find((crop) => crop.id === category.type?.category);
  if (isCrop && crops && !crop) {
    return;
  }
  return (
    <Card className="p-8 my-4 flex-shrink-0">
      <Typography
        variant="h6"
        className={classes("text-lg", {
          "italic text-orange-200": crop && !crop.isEnabled,
        })}
      >
        {title}{" "}
        {crop && !crop.isEnabled ? ` (${t("utils.descriptors.disabled")})` : ""}
      </Typography>
      {description}
      <div className="flex items-center justify-between">
        <strong>{t("components.discriminator.configs.avoid.label")}</strong>
        {[
          {
            sizeIndex: SIZE_INDEX.SMALL,
            title: t("utils.descriptors.small"),
          },
          {
            sizeIndex: SIZE_INDEX.MEDIUM,
            title: t("utils.descriptors.medium"),
          },
          {
            sizeIndex: SIZE_INDEX.LARGE,
            title: t("utils.descriptors.large"),
          },
        ].map(({ sizeIndex, title }) => (
          <div className="flex flex-col gap-4 items-center" key={sizeIndex}>
            <span className="text-lg">{title}</span>
            <TrustControl
              value={!category.trusts[sizeIndex]?.avoid}
              inverted
              onChange={(checked) => {
                const newCategory = structuredClone(category);
                const trust = newCategory.trusts[sizeIndex];
                if (!trust) {
                  return;
                }
                trust.avoid = !checked;
                onChange(newCategory);
              }}
            />
          </div>
        ))}
      </div>
    </Card>
  );
};
