export const debounce = <T extends (...arguments_: any) => any>(
  callback: T,
  {
    wait = 300,
    leading = false,
    trailing = true,
  }: { leading?: boolean; trailing?: boolean; wait?: number } = {}
): ((...arguments_: Parameters<T>) => void) => {
  let timeout: number | undefined;
  return (...arguments_: Parameters<T>): void => {
    const later = (): void => {
      timeout = undefined;
      if (trailing) {
        return callback.apply(this, arguments_);
      }
    };
    const callNow = leading && !timeout;
    clearTimeout(timeout);
    timeout = window.setTimeout(later, wait);
    if (callNow) {
      callback.apply(this, arguments_);
    }
  };
};

export const sleep = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));
