import { Loading } from "portal/components/Loading";
import { Path } from "portal/utils/routing";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import React, { FunctionComponent, useEffect } from "react";

export const Logout: FunctionComponent = () => {
  const navigate = useNavigate();
  const auth0State = useAuth0();
  const { logout, isLoading, isAuthenticated, error } = auth0State;

  console.debug("Logout.tsx:render: auth0State:", auth0State);
  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (isAuthenticated) {
      logout();
    } else {
      navigate(Path.BASE);
    }
  }, [isLoading, isAuthenticated, logout, navigate]);

  if (isLoading) {
    return <Loading failed={Boolean(error)} error={error} overlay />;
  }
};
