import { AdvancedDiscriminator } from "./AdvancedDiscriminator";
import { Alert, Button, IconButton } from "@mui/material";
import { BasicDiscriminator } from "./BasicDiscriminator";
import { capitalize } from "portal/utils/strings";
import { ConfirmationDialog } from "../ConfirmationDialog";
import { DiscriminatorConfig } from "protos/almanac/almanac";
import { EditableTypography } from "../EditableTypography";
import { getCrops, SortedCrops } from "portal/utils/crops";
import { isUndefined } from "portal/utils/identity";
import { Link, useNavigate } from "react-router-dom";
import { Loading } from "portal/components/Loading";
import { LOCALSTORAGE_DISCRIMINATOR_MODE } from "portal/utils/localStorage";
import { RED_BUTTON, WHITE_BUTTON } from "portal/utils/theme";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  useDeleteDiscriminatorMutation,
  useGetConfigQuery,
  useGetDiscriminatorQuery,
  useGetRobotQuery,
  useListRobotCropsQuery,
  useSetDiscriminatorMutation,
} from "portal/state/portalApi";
import { useLocalStorage } from "@uidotdev/usehooks";
import {
  useMutationPopups,
  useQueryPopups,
} from "portal/utils/hooks/useApiPopups";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import ParentIcon from "@mui/icons-material/ArrowBackOutlined";
import React, { FunctionComponent, useMemo, useState } from "react";

interface Props {
  parentLink?: string;
  serial: string;
  uuid: string;
}

export const Discriminator: FunctionComponent<Props> = ({
  uuid,
  parentLink,
  serial,
}) => {
  const { isInternal } = useSelf();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: summary } = useQueryPopups(useGetRobotQuery(serial), {
    errorVariant: "warning",
  });

  // crops
  const {
    data: config,
    isError: isConfigError,
    error: configError,
  } = useQueryPopups(useGetConfigQuery(serial ? [serial, {}] : skipToken), {
    errorVariant: "warning",
  });

  const {
    data: robotCrops,
    isError: isCropsError,
    error: cropsError,
  } = useQueryPopups(useListRobotCropsQuery(serial || skipToken));

  const sortedCrops = useMemo<SortedCrops | undefined>(
    () => (config ? getCrops(serial, config.config, robotCrops) : undefined),
    [serial, config, robotCrops]
  );
  const crops = isInternal ? sortedCrops?.all : sortedCrops?.enabled;

  // view state
  const [isAdvanced, setAdvanced] = useLocalStorage<boolean>(
    LOCALSTORAGE_DISCRIMINATOR_MODE,
    false
  );

  // local changes
  const [updatedDiscriminator, setUpdatedDiscriminator] = useState<
    DiscriminatorConfig | undefined
  >();

  // remote data
  const {
    data: freezedDiscriminator,
    isError: isDiscriminatorError,
    error: discriminatorError,
  } = useGetDiscriminatorQuery(uuid);

  // mutations
  const [deleteDiscriminator] = useMutationPopups(
    useDeleteDiscriminatorMutation(),
    {
      success: capitalize(
        t("utils.actions.deletedLong", {
          subject: t("models.discriminators.discriminator_one"),
        })
      ),
    }
  );
  const [updateDiscriminator] = useMutationPopups(
    useSetDiscriminatorMutation(),
    {
      success: capitalize(
        t("utils.actions.savedLong", {
          subject: t("models.discriminators.discriminator_one"),
        })
      ),
    }
  );

  // confirmation dialog
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  // unfreezed state
  const unfreezedDiscriminator = useMemo<DiscriminatorConfig | undefined>(
    () =>
      freezedDiscriminator
        ? DiscriminatorConfig.fromPartial(structuredClone(freezedDiscriminator))
        : undefined,
    [freezedDiscriminator]
  );

  // visible state
  const discriminator = updatedDiscriminator ?? unfreezedDiscriminator;
  const isActive =
    !isUndefined(discriminator?.id) &&
    discriminator.id ===
      summary?.robot?.health?.fieldConfig?.activeDiscriminatorId;

  if (!discriminator || !crops) {
    return (
      <Loading
        failed={isConfigError || isDiscriminatorError || isCropsError}
        error={configError ?? discriminatorError ?? cropsError}
      />
    );
  }

  return (
    <div className="flex flex-col min-h-full">
      {/* Header */}
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-8 w-full gap-4">
        {/* Left Controls */}
        <div className="flex items-center">
          {/* Breadcrumb */}
          {parentLink && (
            <IconButton component={Link} to={parentLink} className="text-white">
              <ParentIcon />
            </IconButton>
          )}

          {/* Mode switcher */}
          <Button {...WHITE_BUTTON} onClick={() => setAdvanced(!isAdvanced)}>
            {isAdvanced
              ? t("components.almanac.switchModeBasic")
              : t("components.almanac.switchModeAdvanced")}
          </Button>
        </div>

        {/* Title */}
        <EditableTypography
          variant="h4"
          value={discriminator.name}
          onEdit={async (name) => {
            const newDiscriminator = {
              ...discriminator,
              name,
            };
            setUpdatedDiscriminator(newDiscriminator);
            await updateDiscriminator([serial, newDiscriminator]);
            setUpdatedDiscriminator(undefined);
          }}
        />

        {/* Right Controls */}
        <div className="flex items-center gap-4">
          <Button
            {...RED_BUTTON}
            onClick={() => setConfirmDelete(true)}
            startIcon={<DeleteIcon />}
          >
            {t("utils.actions.delete")}
          </Button>
          {confirmDelete && (
            <ConfirmationDialog
              title={t("utils.actions.deleteLong", {
                subject: t("models.discriminators.discriminator_one"),
              })}
              description={
                isActive ? (
                  <Alert severity="warning" className="mb-8">
                    {t(
                      "components.ConfirmationDialog.delete.descriptionActive",
                      {
                        subject: t("models.discriminators.discriminator_one"),
                      }
                    )}
                  </Alert>
                ) : (
                  t("components.ConfirmationDialog.delete.description", {
                    subject: t("models.discriminators.discriminator_one"),
                  })
                )
              }
              destructive
              yesText={t("utils.actions.deleteLong", {
                subject: discriminator.name,
              })}
              yesDisabled={isActive}
              onClose={() => setConfirmDelete(false)}
              onYes={async () => {
                await deleteDiscriminator(discriminator.id);
                if (parentLink) {
                  navigate(parentLink);
                }
              }}
            />
          )}
        </div>
      </div>

      {isActive && (
        <Alert severity="warning" className="mb-8">
          {t("components.discriminator.warnings.production")}
        </Alert>
      )}

      {/* Main View */}
      {isAdvanced ? (
        <AdvancedDiscriminator
          discriminator={discriminator}
          crops={crops}
          onChange={async (newDiscriminator) => {
            setUpdatedDiscriminator(newDiscriminator);
            await updateDiscriminator([serial, newDiscriminator]);

            setUpdatedDiscriminator(undefined);
          }}
        />
      ) : (
        <BasicDiscriminator
          discriminator={discriminator}
          crops={crops}
          onChange={async (newDiscriminator) => {
            setUpdatedDiscriminator(newDiscriminator);
            await updateDiscriminator([serial, newDiscriminator]);
            setUpdatedDiscriminator(undefined);
          }}
        />
      )}
    </div>
  );
};
