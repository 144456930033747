import * as Comparators from "portal/utils/comparators";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import {
  classes,
  SMALL_INPUT,
  SMALL_LABEL,
  SMALL_SELECT,
  ThemeProps,
} from "portal/utils/theme";
import { findWhere, isEqual } from "portal/utils/arrays";
import { getCustomerSerial, robotSort } from "portal/utils/robots";
import { ReportInstanceResponse } from "protos/portal/reports";
import { RobotResponse, RobotSummaryResponse } from "protos/portal/robots";
import { TFunction } from "i18next";
import { titleCase } from "portal/utils/strings";
import { useListRobotsQuery } from "portal/state/portalApi";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { useSelf } from "portal/state/store";
import { useStableArray } from "portal/utils/hooks/useStable";
import { useTranslation } from "react-i18next";
import { WithSkeleton } from "portal/components/WithSkeleton";
import React, { FunctionComponent, useEffect } from "react";

interface Props {
  className?: string;
  customerIds?: number[];
  disabled?: boolean;
  onChange?: (selectedRobots: number[]) => void;
  readOnly?: boolean;
  selectedRobots?: number[];
  themeProps?: Partial<ThemeProps>;
  instance?: ReportInstanceResponse;
  label?: string;
}

export const getSelectedRobotsDescription = (
  t: TFunction,
  selectedRobots: number[] = [],
  robots: RobotSummaryResponse[] = [],
  isInternal: boolean = false
): string => {
  if (selectedRobots.length <= 2) {
    return selectedRobots
      .map((id) => {
        const summary = findWhere(robots, { robot: { db: { id } } });
        const name = isInternal
          ? summary?.robot?.serial
          : getCustomerSerial(t, summary?.robot?.serial);
        return name ?? t("models.robots.unknown");
      })
      .join(", ");
  }
  return `${selectedRobots.length} ${t("models.robots.robot", {
    count: selectedRobots.length,
  })}`;
};

export const RobotMultiSelector: FunctionComponent<Props> = ({
  className,
  customerIds,
  disabled = false,
  onChange,
  readOnly = false,
  selectedRobots: inputSelectedRobots = [],
  themeProps: inputThemeProps = {},
  instance,
  label,
}) => {
  const { isInternal } = useSelf();
  const { t } = useTranslation();
  const themeProps = {
    input: SMALL_INPUT,
    select: SMALL_SELECT,
    label: SMALL_LABEL,
    ...inputThemeProps,
  };
  // get all robots
  const { data: robots, isSuccess } = useQueryPopups(
    useListRobotsQuery({
      instance: instance ? instance.slug : undefined,
    })
  );
  // keep track of selected robots
  const selectedRobots = useStableArray<number>(inputSelectedRobots);
  const filteredRobots = robots?.filter(
    (robot) =>
      !customerIds ||
      customerIds.length === 0 ||
      (robot.customer?.db && customerIds.includes(robot.customer.db.id))
  );

  // if the customerId changes, filter the selected robots
  useEffect(() => {
    if (!customerIds) {
      return;
    }
    const filteredByCustomer = selectedRobots.filter((id) =>
      filteredRobots?.some((summary) => summary.robot?.db?.id === id)
    );
    if (isEqual(filteredByCustomer, selectedRobots)) {
      return;
    }
    onChange?.(filteredByCustomer);
  }, [customerIds, filteredRobots, onChange, selectedRobots]);

  let selectLabel: string;
  if (label) {
    selectLabel = label;
  } else if (selectedRobots.length > 0) {
    selectLabel = titleCase(t("models.robots.robot_other"));
  } else if (readOnly) {
    selectLabel = t("views.reports.tools.robotsLabel.none");
  } else {
    selectLabel = t("views.reports.tools.robotsLabel.select");
  }

  return (
    <WithSkeleton
      variant="rectangular"
      className={classes(className)}
      success={isSuccess}
    >
      <FormControl>
        <InputLabel {...themeProps.label}>{selectLabel}</InputLabel>
        <Select<number[]>
          {...themeProps.select}
          multiple
          disabled={disabled}
          readOnly={readOnly}
          value={selectedRobots}
          input={<OutlinedInput {...themeProps.input} label={selectLabel} />}
          onChange={(event) => {
            let selectedRobots = event.target.value;
            if (typeof selectedRobots === "string") {
              selectedRobots = [Number(selectedRobots)];
            }
            const idToRobot: Map<number, RobotResponse> = new Map();
            for (const { robot } of filteredRobots || []) {
              if (robot?.db?.id !== undefined) {
                idToRobot.set(robot.db.id, robot);
              }
            }
            selectedRobots.sort(
              Comparators.comparing(
                (id) => idToRobot.get(id),
                Comparators.nullsLast(robotSort)
              )
            );
            // filter out any robots not assigned to this customer
            if (customerIds) {
              selectedRobots = selectedRobots.filter((id) =>
                filteredRobots?.some((summary) => summary.robot?.db?.id === id)
              );
            }
            onChange?.(selectedRobots);
          }}
          defaultValue={[]}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return t("views.reports.tools.robotsLabel.select");
            }
            if (selected.length === filteredRobots?.length) {
              return t("views.reports.tools.robotsLabel.all");
            }
            return getSelectedRobotsDescription(
              t,
              selected,
              robots,
              isInternal
            );
          }}
        >
          {filteredRobots?.map(({ robot }) => (
            <MenuItem key={robot?.db?.id} value={robot?.db?.id ?? Number.NaN}>
              <Checkbox
                checked={selectedRobots.includes(robot?.db?.id ?? Number.NaN)}
              />
              <ListItemText
                primary={
                  isInternal
                    ? robot?.serial
                    : getCustomerSerial(t, robot?.serial)
                }
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </WithSkeleton>
  );
};
