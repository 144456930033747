import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Link,
  Typography,
} from "@mui/material";
import { BG_BACKDROP, BLUE_BUTTON } from "portal/utils/theme";
import { ReactComponent as Logo } from "portal/images/logo_stack.svg";
import { QueryType, useQuery } from "portal/utils/hooks/useQuery";
import { toQuery } from "portal/utils/browser";
import { Trans, useTranslation } from "react-i18next";
import ContinueIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import React, { FunctionComponent, useState } from "react";

export const GdprConsent: FunctionComponent = () => {
  const { t } = useTranslation();
  const [state] = useQuery<string>("state", QueryType.STRING);

  const [didAgree, setAgree] = useState<boolean>(false);

  return (
    <Backdrop open className={BG_BACKDROP}>
      <Box className="flex flex-col gap-10 items-center">
        <Logo className="text-white w-40" />
        <div className="flex flex-col items-center">
          <Typography variant="h5">
            {t("components.GdprConsent.title")}
          </Typography>
          <Typography variant="body1">
            {t("components.GdprConsent.description")}
          </Typography>
        </div>
        <div className="flex gap-2 items-center">
          <Checkbox
            color="info"
            checked={didAgree}
            onChange={(event) => setAgree(event.currentTarget.checked)}
          />
          <Trans
            i18nKey="components.GdprConsent.statement"
            t={t}
            components={[
              <Link
                key="terms"
                className="text-orange-500"
                href="https://carbonrobotics.com/about/terms"
                target="_blank"
                rel="noreferrer"
              />,
              <Link
                key="privacy"
                className="text-orange-500"
                href="https://carbonrobotics.com/about/privacy"
                target="_blank"
                rel="noreferrer"
              />,
            ]}
          />
        </div>
        <Button
          {...BLUE_BUTTON}
          disabled={!didAgree}
          classes={{ disabled: "bg-zinc-500" }}
          href={`https://${
            window._jsenv.REACT_APP_AUTH0_AUTH_DOMAIN
          }/continue?${toQuery({
            state,
          })}`}
          endIcon={<ContinueIcon />}
        >
          {t("utils.actions.continue")}
        </Button>
      </Box>
    </Backdrop>
  );
};
