import { Skeleton } from "@mui/material";
import { useSelf } from "portal/state/store";
import React, { FunctionComponent, ReactElement } from "react";

interface Props {
  internal?: ReactElement | null;
  customer?: ReactElement | null;
  loading?: ReactElement | null;
}

export const UserTypeSwitch: FunctionComponent<Props> = ({
  internal,
  customer,
  loading,
}) => {
  const { user, isInternal } = useSelf();

  if (!user) {
    // user not resolved
    return (
      loading ?? (
        <Skeleton variant="rectangular" className="min-w-10 min-h-10" />
      )
    );
  }

  return isInternal ? internal ?? <></> : customer ?? <></>;
};
