import {
  CategoryClassification,
  categoryClassificationFromJSON,
  type ModelTrust,
} from "protos/almanac/almanac";
import type { TFunction } from "i18next";

export const CROP_TRUSTS: Array<keyof ModelTrust> = [
  "minDoo",
  "weedingThreshold",
  "thinningThreshold",
  "bandingThreshold",
];
export const WEED_TRUSTS: Array<keyof ModelTrust> = [
  "minDoo",
  "weedingThreshold",
  "thinningThreshold",
];

// Set of keys whose translation key is namespaced under whether the trust is
// for a crop or a weed.
const VARIABLE_DESCRIPTIONS: Set<keyof ModelTrust> = new Set([
  "weedingThreshold",
  "thinningThreshold",
]);

interface ColumnStrings {
  label: string;
  description: string;
}
export const getColumnStrings = (
  t: TFunction,
  classification: CategoryClassification,
  key: keyof ModelTrust
): ColumnStrings => {
  let context = "";
  if (VARIABLE_DESCRIPTIONS.has(key)) {
    context =
      classification ===
      categoryClassificationFromJSON(CategoryClassification.CATEGORY_CROP)
        ? ".crop"
        : ".weed";
  }

  // carbon.actions.compareKeys.ignoreDynamic
  const label = t(`components.modelinator.configs.${key}${context}.label`);
  // carbon.actions.compareKeys.ignoreDynamic
  const description = t(
    `components.modelinator.configs.${key}${context}.description`
  );

  return { label, description };
};
