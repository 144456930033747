import { isUndefined } from "./identity";

export const without = <T>(array: T[] = [], element: T, key?: keyof T): T[] => {
  if (key) {
    return array.filter((item) => item[key] !== element[key]);
  } else {
    const targetIndex = array.indexOf(element);
    return targetIndex === -1
      ? array
      : array.filter((value, index) => index !== targetIndex);
  }
};

export const moveItem = <T>(
  array: T[] = [],
  fromIndex: number,
  toIndex: number
): T[] => {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    if (!item) {
      return array;
    }
    array.splice(endIndex, 0, item);
  }

  return array;
};

export const range = (length: number, oneIndex: boolean = false): number[] => {
  let index = 0;
  const result = [];
  while (result.length < length) {
    result.push(index + (oneIndex ? 1 : 0));
    index++;
  }
  return result;
};

export const isEmpty = (input: unknown[] | undefined): boolean => {
  if (isUndefined(input)) {
    return true;
  }
  return input.length === 0;
};

export const keyBy = <T extends Record<string, any>>(
  input: readonly T[] | T[] | undefined,
  index: keyof T | ((element: T) => string)
): Record<string, T> => {
  const result: Record<string, T> = {};
  if (isUndefined(input)) {
    return result;
  }
  for (const element of input) {
    if (typeof index === "function") {
      result[index(element)] = element;
    } else {
      result[element[index]] = element;
    }
  }
  return result;
};

export enum Order {
  DESC = "desc",
  ASC = "asc",
}

export const sortBy = <T extends Record<string, any>>(
  input: T[],
  key: keyof T | ((item: T) => string | number),
  order: Order = Order.ASC
): T[] => {
  const compare = (a: T, b: T): -1 | 0 | 1 => {
    const aString = typeof key === "function" ? key(a) : a[key];
    const bString = typeof key === "function" ? key(b) : b[key];
    if (aString < bString) {
      return order === Order.ASC ? -1 : 1;
    } else if (aString > bString) {
      return order === Order.ASC ? 1 : -1;
    } else {
      return 0;
    }
  };

  return [...input].sort(compare);
};

export const isEqual = <T extends any[]>(
  a: T | undefined,
  b: T | undefined
): boolean => {
  if (a === b) {
    return true;
  }
  if (!a || !b) {
    return false;
  }
  if (a.length !== b.length) {
    return false;
  }
  return a.every((item, index) => b[index] === item);
};

// can't use ./objects.matches because circular dependencies
const objectMatches = <T extends Record<string, any>>(
  input: T,
  target: Record<string, any>
): boolean => {
  return Object.entries(target).every(([key, value]) => {
    // can't use isObject because circular dependencies
    return typeof value === "object" && value !== null && !Array.isArray(value)
      ? objectMatches(input[key], target[key])
      : input[key] === value;
  });
};

export const findWhere = <T extends Record<string, any>>(
  input: T[] | undefined,
  target: Record<string, unknown>
): T | undefined => {
  if (isUndefined(input)) {
    return;
  }
  return input.find((element) => objectMatches(element, target));
};

export const filterWhere = <T extends Record<string, any>>(
  input: T[] | undefined,
  target: Record<string, unknown>
): T[] | undefined => {
  if (isUndefined(input)) {
    return;
  }
  return input.filter((element) => objectMatches(element, target));
};
