import "./i18n";
import "@fontsource/poppins";
import "@fontsource/russo-one";
import "helvatica-neue-lt/index.css";
import * as Sentry from "@sentry/react";
import { AdapterLuxon } from "@mui/x-date-pickers-pro/AdapterLuxon";
import { carbon } from "portal/utils/theme";
import { CarbonAuth0Provider } from "portal/components/CarbonAuth0Provider";
import { CarbonNotifications } from "portal/components/CarbonNotifications";
import { CarbonRouter } from "portal/components/CarbonRouter";
import { createRoot } from "react-dom/client";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { GlobalHotKeys } from "react-hotkeys";
import { LicenseInfo } from "@mui/x-license-pro";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "portal/state/store";
import { Provider } from "react-redux";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import App from "./App";
import React, { useEffect } from "react";

LicenseInfo.setLicenseKey(window._jsenv.REACT_APP_MUI_LICENSE_KEY);

if (window._jsenv.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    environment: window._jsenv.REACT_APP_SENTRY_ENVIRONMENT,
    // Will cause a deprecation warning, but the demise of `ignoreErrors` is still under discussion.
    // See: https://github.com/getsentry/raven-js/issues/73
    ignoreErrors: [
      // Random plugins/extensions
      "top.GLOBALS",
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      "originalCreateNotification",
      "canvas.contentDocument",
      "MyApp_RemoveAllHighlights",
      "http://tt.epicplay.com",
      "Can't find variable: ZiteReader",
      "jigsaw is not defined",
      "ComboSearch is not defined",
      "http://loading.retry.widdit.com/",
      "atomicFindClose",
      // Facebook borked
      "fb_xd_fragment",
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      "bmi_SafeAddOnload",
      "EBCallBackMessageReceived",
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      "conduitPage",
      // Generic error code from errors outside the security sandbox
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      "Script error.",
      // Avast extension error
      "_avast_submit",
    ],
    denyUrls: [
      // Google Adsense
      /pagead\/js/i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_us\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    dsn: window._jsenv.REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 0.25,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1,
    release: `portal@${window._jsenv.REACT_APP_COMMITHASH}`,
  });
}

const container = document.querySelector("#root");
if (container) {
  const root = createRoot(container);
  root.render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        {/* Allow TailwindCSS without !important */}
        <StyledEngineProvider injectFirst>
          {/* Inject custom mui theme */}
          <ThemeProvider theme={carbon}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <GlobalHotKeys>
                <CarbonRouter>
                  <CarbonAuth0Provider>
                    <CarbonNotifications>
                      <App />
                    </CarbonNotifications>
                  </CarbonAuth0Provider>
                </CarbonRouter>
              </GlobalHotKeys>
            </LocalizationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </PersistGate>
    </Provider>
  );
} else {
  console.debug("Not an SPA page");
}
