import { Breakpoint, useBreakpoint } from "portal/utils/hooks/useBreakpoint";
import { ReactComponent as CarbonArrow } from "portal/images/arrow.svg";
import { ReactComponent as CarbonLogo } from "portal/images/logo_ops_center.svg";
import { classes } from "portal/utils/theme";
import {
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItemButton,
  SwipeableDrawer,
  Switch,
  Tooltip,
} from "@mui/material";
import { GlobalHotKeys } from "react-hotkeys";
import { GROUP_CARBON } from "portal/utils/hotkeys";
import { isInternal as isActuallyInternal } from "portal/utils/auth";
import { Link, useLocation } from "react-router-dom";
import { LOCALSTORAGE_DRAWER_EXPANDED } from "portal/utils/localStorage";
import { setCustomerMode } from "portal/state/self";
import { useDispatch } from "react-redux";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { withErrorBoundary } from "portal/components/ErrorBoundary";
import React, { FunctionComponent, ReactElement } from "react";

interface BaseNavItem {
  label: ReactElement | string;
  icon?: ReactElement;
}

interface LinkNavItem extends BaseNavItem {
  href: string;
}

interface ActionNavItem extends BaseNavItem {
  onClick: () => void;
}

interface Divider {
  type: "divider";
}

export type NavItem = LinkNavItem | ActionNavItem | Divider;

const isActionItem = (item: NavItem): item is ActionNavItem =>
  "onClick" in item;

// const AVATAR_SIZE = 80;
export interface DrawerProps {
  items?: NavItem[];
}

export const Drawer: FunctionComponent<DrawerProps> = withErrorBoundary(
  function Drawer(props: DrawerProps) {
    const { items = [] } = props;
    const { user, isInternal } = useSelf();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isExpanded, setExpanded] = useLocalStorage<boolean>(
      LOCALSTORAGE_DRAWER_EXPANDED,
      false
    );
    const breakpoint = useBreakpoint();
    const isSmall = breakpoint <= Breakpoint.sm;

    const location = useLocation();

    return (
      <>
        <SwipeableDrawer
          anchor="left"
          open={isExpanded}
          className="relative"
          onClose={() => setExpanded(false)}
          onOpen={() => setExpanded(true)}
          classes={{
            paper: classes(
              "pt-16 transition-all",
              "overflow-x-hidden",
              isExpanded || isSmall ? "w-60" : "w-16"
            ),
          }}
          variant={isSmall ? "temporary" : "permanent"}
        >
          {isSmall && <CarbonLogo className="h-10 m-4" />}
          <List className="flex-grow justify-center flex flex-col">
            {items.map((item, index) => {
              if ("type" in item) {
                return <Divider key={index} />;
              }
              return (
                <Tooltip
                  arrow
                  title={item.label}
                  key={index}
                  placement="right"
                  classes={{
                    tooltip: "bg-carbon-gray-medium text-lighten-700 text-lg",
                    popper: isExpanded ? "hidden" : "",
                    arrow: "text-carbon-gray-medium",
                  }}
                >
                  <ListItemButton
                    key={index}
                    // ListItemButton accepts a component but the types don't reflect that
                    component={isActionItem(item) ? undefined : (Link as any)}
                    to={isActionItem(item) ? undefined : item.href}
                    onClick={isActionItem(item) ? item.onClick : undefined}
                    selected={
                      !isActionItem(item) &&
                      location.pathname.startsWith(item.href)
                    }
                    className="px-0 py-4 flex-grow-0 font-bold"
                    classes={{
                      selected: "text-white bg-orange-500 bg-opacity-70",
                    }}
                  >
                    <div className="w-16 flex justify-center items-center text-lighten-700 flex-shrink-0">
                      {item.icon}
                    </div>
                    {isExpanded && (
                      <span className={classes("whitespace-nowrap")}>
                        {item.label}
                      </span>
                    )}
                  </ListItemButton>
                </Tooltip>
              );
            })}
          </List>
          {isActuallyInternal(user) && isExpanded && (
            <>
              <GlobalHotKeys
                allowChanges
                keyMap={{
                  TOGGLE_CUSTOMER: {
                    name: t("utils.actions.toggleLong", {
                      subject: t("components.drawer.customerMode"),
                    }),
                    group: GROUP_CARBON,
                    action: "keydown",
                    sequence: "alt+c",
                  },
                }}
                handlers={{
                  TOGGLE_CUSTOMER: () => dispatch(setCustomerMode(isInternal)),
                }}
              />
              <FormControlLabel
                className="p-4"
                control={
                  <Switch
                    checked={!isInternal}
                    onChange={(event, checked) =>
                      dispatch(setCustomerMode(checked))
                    }
                    color="secondary"
                  />
                }
                label={t("components.drawer.customerMode")}
              />
            </>
          )}
        </SwipeableDrawer>
        {!isSmall && (
          <IconButton
            className={classes(
              "fixed top-20 transition-[left]",
              "flex items-center justify-center p-2",
              {
                "left-[56px]": !isExpanded,
                "left-[216px]": isExpanded,
              }
            )}
            onClick={() => setExpanded(!isExpanded)}
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          >
            <CarbonArrow
              className={classes(
                "text-orange-500 w-4 origin-center",
                "cursor-pointer hover:text-white",
                {
                  "rotate-180": !isExpanded,
                }
              )}
            />
          </IconButton>
        )}
      </>
    );
  },
  { i18nKey: "components.drawer.error" }
);
