import { ReactComponent as BudLogo } from "portal/images/bud_icon_border.svg";
import { ReactComponent as CarbonLogo } from "portal/images/logo_icon_border.svg";
import { classes } from "portal/utils/theme";
import { RobotClass } from "portal/utils/robots";
import { ReactComponent as SlayerLogo } from "portal/images/slayer_icon_border.svg";
import { useSelf } from "portal/state/store";
import React, { FunctionComponent } from "react";

interface Props {
  className?: string;
  robotType: RobotClass;
}
export const RobotTypeIcon: FunctionComponent<Props> = ({
  className,
  robotType,
}) => {
  const { isInternal } = useSelf();
  const baseClass = "w-4 h-4";
  if (!isInternal) {
    return (
      <CarbonLogo
        className={classes(className, baseClass, "text-orange-500")}
      />
    );
  }
  switch (robotType) {
    case RobotClass.Simulators:
    case RobotClass.Slayers: {
      return (
        <SlayerLogo
          className={classes(className, baseClass, "text-orange-500")}
        />
      );
    }
    case RobotClass.Buds: {
      return (
        <BudLogo className={classes(className, baseClass, "text-blue-500")} />
      );
    }
    default: {
      return (
        <CarbonLogo
          className={classes(className, baseClass, "text-orange-500")}
        />
      );
    }
  }
};
