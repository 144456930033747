import { entries } from "./objects";
import { isUndefined } from "./identity";
import { sortBy } from "./arrays";

export const toQuery = (data: Record<string, any> = {}): string => {
  const parameters = new URLSearchParams();
  const sortedParameters = sortBy(entries(data), 0);
  for (const [key, value] of sortedParameters) {
    if (isUndefined(value)) {
      continue;
    } else if (typeof value === "boolean") {
      if (value) {
        parameters.append(key, "true");
      }
    } else {
      parameters.append(key, String(value));
    }
  }
  if ([...parameters.keys()].length === 0) {
    return "";
  }
  return `${parameters.toString()}`;
};
