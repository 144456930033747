import { AlmanacConfig, AlmanacTypeCategory } from "protos/almanac/almanac";
import { BasicAlmanacCard } from "./BasicAlmanacCard";
import { ConfigCrop } from "protos/portal/configs";
import { Divider, Typography } from "@mui/material";
import {
  filterCategories,
  getCategoryTitle,
  getWeedCategoryName,
} from "portal/utils/almanac";
import { findWhere, sortBy, without } from "portal/utils/arrays";
import { isUndefined } from "portal/utils/identity";
import { titleCase } from "portal/utils/strings";
import { useTranslation } from "react-i18next";
import React, { FunctionComponent } from "react";

interface Props {
  almanac: AlmanacConfig;
  crops: ConfigCrop[];
  onChange: (newAlmanac: AlmanacConfig) => void;
}

export const BasicAlmanac: FunctionComponent<Props> = ({
  almanac,
  crops,
  onChange,
}) => {
  const { weedCategories, cropCategories, defaultCategory } = filterCategories(
    almanac.categories
  );

  const { t } = useTranslation();

  // mutations
  const updateCategory = (newCategory: AlmanacTypeCategory): void => {
    const updated = structuredClone(almanac);
    const oldCategory = findWhere(updated.categories, {
      type: { category: newCategory.type?.category },
    });
    updated.categories = [
      ...without(updated.categories, oldCategory).filter(
        (category) => !isUndefined(category)
      ),
      newCategory,
    ];
    onChange(updated);
  };

  if (!defaultCategory) {
    return;
  }

  return (
    <div className="flex flex-col h-full">
      <BasicAlmanacCard
        category={defaultCategory}
        title="Default"
        description={
          <div className="flex flex-col md:flex-row gap-4 text-sm justify-center my-4">
            <strong>{t("components.almanac.fineTuneTitle")}</strong>
            <span>{t("components.almanac.fineTuneDescription")}</span>
          </div>
        }
        onChange={updateCategory}
      />
      <Divider className="w-full my-8 border-lighten-400" />
      <Typography variant="h2" className="text-xl">
        {titleCase(t("models.weeds.weed_other"))}
      </Typography>
      {weedCategories.length === 0 && (
        <div className="flex h-40 w-full items-center justify-center text-lighten-400">
          {t("components.almanac.weeds.none")}
        </div>
      )}
      {sortBy(weedCategories, (category) => getCategoryTitle(t, category)).map(
        (category) => (
          <BasicAlmanacCard
            key={category.type?.category}
            category={category}
            title={getWeedCategoryName(t, category.type?.category ?? "")}
            onChange={updateCategory}
            crops={crops}
          />
        )
      )}
      <Typography variant="h2" className="text-xl">
        {titleCase(t("models.crops.crop_other"))}
      </Typography>
      {cropCategories.length === 0 && (
        <div className="flex h-40 w-full items-center justify-center text-lighten-400">
          {t("components.almanac.crops.none")}
        </div>
      )}
      {sortBy(cropCategories, (category) =>
        getCategoryTitle(t, category, crops)
      ).map((category) => (
        <BasicAlmanacCard
          key={category.type?.category}
          category={category}
          title={getCategoryTitle(t, category, crops)}
          crops={crops}
          onChange={updateCategory}
        />
      ))}
    </div>
  );
};
