import { CarbonUser, isInternal } from "./auth";
import { DateRange, PickersShortcutsItem } from "@mui/x-date-pickers-pro";
import { DateTime } from "luxon";
import { i18n as I18n, TFunction } from "i18next";
import { isSameRange } from "./dates";
import { isUndefined } from "./identity";
import {
  METRIC_ACRES_WEEDED,
  METRIC_AVERAGE_WEED_SIZE,
  METRIC_BANDING_ENABLED,
  METRIC_COVERAGE_SPEED,
  METRIC_CROP,
  METRIC_KILLED_WEEDS,
  METRIC_TOTAL_WEEDS_IN_BAND,
  METRIC_WEED_DENSITY,
  METRIC_WEEDING_EFFICIENCY,
  METRIC_WEEDING_TIME,
} from "portal/utils/certifiedMetrics";
import {
  ReportInstanceResponse,
  ReportMode,
  reportModeFromJSON,
  ReportResponse,
} from "protos/portal/reports";
import { Slug } from "./routing";
import { useEffect, useState } from "react";

export const GROUP_COLORS = [
  "bg-amber-700",
  "bg-cyan-700",
  "bg-emerald-700",
  "bg-violet-700",
  "bg-red-700",
  "bg-indigo-700",
  "bg-lime-700",
  "bg-fuchsia-700",
  "bg-yellow-700",
  "bg-rose-700",
  "bg-teal-700",
  "bg-purple-700",
];

export const PINNED_COLUMN_DATE = "date";
export const PINNED_COLUMN_JOB = "job";
export const PINNED_COLUMN_ROBOT = "robot";

export const DEFAULT_COLUMNS = [
  METRIC_CROP,
  METRIC_WEEDING_TIME,
  METRIC_ACRES_WEEDED,
  METRIC_COVERAGE_SPEED,
  METRIC_BANDING_ENABLED,
  METRIC_AVERAGE_WEED_SIZE,
  METRIC_WEED_DENSITY,
  METRIC_TOTAL_WEEDS_IN_BAND,
  METRIC_KILLED_WEEDS,
  METRIC_WEEDING_EFFICIENCY,
].map((metric) => metric.id);

export type ReportProperty = keyof ReportResponse;
export type ReportInstanceProperty = keyof ReportInstanceResponse;

export const toNewInstance = (
  user: CarbonUser,
  report: ReportResponse
): ReportInstanceResponse =>
  ReportInstanceResponse.fromPartial({
    authorId: user.userId ?? "",
    endDate: report.endDate,
    name: report.name,
    orderAsc: report.orderAsc,
    orderBy: report.orderBy,
    reportId: report.db?.id,
    robotIds: report.robotIds,
    showAverage: report.showAverage,
    showTotal: report.showTotal,
    slug: Slug.NEW,
    startDate: report.startDate,
    visibleColumns: report.visibleColumns,
    automated: false,
    authorName: user.name ?? user.email,
    mode: report.mode,
  });

export const isNewReport = (report?: ReportResponse): boolean =>
  !isUndefined(report) && (!report.slug || report.slug === Slug.NEW);

export const isNewInstance = (instance?: ReportInstanceResponse): boolean =>
  !isUndefined(instance) && (!instance.slug || instance.slug === Slug.NEW);

export const secondsToRange = (
  startSeconds: number | undefined,
  endSeconds: number | undefined
): DateRange<DateTime> => [
  isUndefined(startSeconds)
    ? DEFAULT_DATE_RANGE[0]
    : DateTime.fromSeconds(startSeconds),
  isUndefined(endSeconds)
    ? DEFAULT_DATE_RANGE[1]
    : DateTime.fromSeconds(endSeconds),
];

export const DEFAULT_REPORT: ReportResponse = ReportResponse.fromPartial({
  automateWeekly: false,
  endDate: DateTime.local().toUnixInteger(),
  mode: reportModeFromJSON(ReportMode.REPORT_MODE_JOBS),
  orderAsc: true,
  showAverage: true,
  showTotal: true,
  slug: Slug.NEW,
  startDate: DateTime.local().minus({ days: 3 }).toUnixInteger(),
  visibleColumns: DEFAULT_COLUMNS,
});

export const getDateRangeShortcuts = (
  t: TFunction
): PickersShortcutsItem<DateRange<DateTime>>[] => [
  {
    label: t("components.DateRangePicker.thisWeek"),
    getValue: () => {
      const today = DateTime.local();
      return [today.startOf("week"), today];
    },
  },
  {
    label: t("components.DateRangePicker.lastWeek"),
    getValue: () => {
      const today = DateTime.local();
      const previousWeek = today.minus({ days: 7 });
      return [previousWeek.startOf("week"), previousWeek.endOf("week")];
    },
  },
  {
    label: t("components.DateRangePicker.last7days"),
    getValue: () => {
      const today = DateTime.local();
      return [today.minus({ days: 7 }), today];
    },
  },
  {
    label: t("components.DateRangePicker.thisMonth"),
    getValue: () => {
      const today = DateTime.local();
      return [today.startOf("month"), today];
    },
  },
  {
    label: t("components.DateRangePicker.lastMonth"),
    getValue: () => {
      const today = DateTime.local();
      const previousMonth = today.minus({ days: 30 });
      return [previousMonth.startOf("month"), previousMonth.endOf("month")];
    },
  },
];

export const DEFAULT_DATE_RANGE: DateRange<DateTime> = [
  DateTime.local().minus({ days: 3 }),
  DateTime.local(),
];

export const useDateRange = (
  inputDateRange: DateRange<DateTime> = DEFAULT_DATE_RANGE
): DateRange<DateTime> => {
  const [dateRange, setDateRange] =
    useState<DateRange<DateTime>>(inputDateRange);
  useEffect(() => {
    if (!isSameRange(dateRange, inputDateRange)) {
      setDateRange(inputDateRange);
    }
  }, [inputDateRange, dateRange]);
  return dateRange;
};

export const isBlankValue = (value: any): boolean => {
  if (Array.isArray(value)) {
    return value.filter((v) => !isBlankValue(v)).length === 0;
  }
  return Boolean(isUndefined(value) || value === "" || Number.isNaN(value));
};

export const formatWeeklyDay = (
  t: TFunction,
  i18n: I18n,
  weekday?: number
): string => {
  if (!weekday || weekday < 0 || weekday > 6) {
    return t("components.DateRangePicker.invalid");
  }
  return DateTime.local()
    .set({ weekday })
    .toLocaleString({ weekday: "long" }, { locale: i18n.language });
};

export const formatWeeklyHour = (
  t: TFunction,
  i18n: I18n,
  hour?: number
): string => {
  if (!hour || hour < 0 || hour > 24) {
    return t("components.DateRangePicker.invalid");
  }
  return DateTime.local()
    .set({ hour, minute: 0 })
    .toLocaleString(DateTime.TIME_SIMPLE);
};

export const canDeleteReport = (
  report: ReportResponse | undefined,
  user: CarbonUser | undefined
): boolean =>
  !isNewReport(report) &&
  !isUndefined(report) &&
  !isUndefined(user) &&
  (isInternal(user) || report.authorId === user.userId);
