import { capitalize, titleCase } from "portal/utils/strings";
import {
  CarbonDataGrid,
  renderHeaderWithoutUnits,
} from "portal/components/CarbonDataGrid";
import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import { classes, STATUS_BG, STATUS_TEXT } from "portal/utils/theme";
import { convert, UnitNumberType } from "portal/utils/units/units";
import { DATE_PATH_FORMAT, DATETIME_EXCEL } from "portal/utils/dates";
import { DateTime } from "luxon";
import { formatMeasurement } from "./measurement/formatters";
import { getCustomerSerial } from "portal/utils/robots";
import {
  GridActionsColDef,
  GridColDef,
  GridToolbarExport,
} from "@mui/x-data-grid-premium";
import { isUndefined } from "portal/utils/identity";
import { LaserStats } from "protos/portal/hardware";
import { Measurement } from "./measurement/Measurement";
import { SearchField } from "./header/SearchField";
import { useFuzzySearch } from "portal/utils/hooks/useFuzzySearch";
import { useListRobotsQuery } from "portal/state/portalApi";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import React, { FunctionComponent, useMemo, useState } from "react";

const WARRANTY_HOURS = 1000;
const WARRANTY_MONTHS = 3;

interface ResolvedLaserStats extends LaserStats {
  robotSerial?: string;
}

interface WarrantyDetails {
  monthsInstalled: number | undefined;
  monthsRemaining: number | undefined;
  monthsPercent: number | undefined;
  hoursOn: number | undefined;
  hoursRemaining: number | undefined;
  hoursPercent: number | undefined;
  overallPercent: number | undefined;
}

const getWarrantyDetails = (laser: LaserStats): WarrantyDetails => {
  const monthsInstalled = laser.installedAt
    ? Math.abs(DateTime.fromMillis(laser.installedAt).diffNow().as("months"))
    : undefined;
  const monthsRemaining = (() => {
    if (isUndefined(monthsInstalled)) {
      return;
    }
    return Math.max(WARRANTY_MONTHS - monthsInstalled, 0);
  })();
  const monthsPercent = isUndefined(monthsRemaining)
    ? undefined
    : monthsRemaining / WARRANTY_MONTHS;

  const hoursOn = laser.lifetimeSec
    ? convert(laser.lifetimeSec).from("s").to("h")
    : undefined;
  const hoursRemaining = (() => {
    if (isUndefined(hoursOn)) {
      return;
    }
    return Math.max(WARRANTY_HOURS - hoursOn, 0);
  })();
  const hoursPercent = isUndefined(hoursRemaining)
    ? undefined
    : hoursRemaining / WARRANTY_HOURS;

  let overallPercent: number | undefined = Math.min(
    monthsPercent ?? Number.POSITIVE_INFINITY,
    hoursPercent ?? Number.POSITIVE_INFINITY
  );
  if (overallPercent === Number.POSITIVE_INFINITY) {
    overallPercent = undefined;
  }

  return {
    hoursOn,
    hoursPercent,
    hoursRemaining,
    monthsInstalled,
    monthsPercent,
    monthsRemaining,
    overallPercent,
  };
};

const defaultColumn: Partial<GridColDef> = {
  sortable: true,
  disableColumnMenu: true,
};

interface BaseProps {
  lasers: LaserStats[] | undefined;
  loading?: boolean;
}

interface FleetProps extends BaseProps {
  fleet: true;
}

interface RobotProps extends BaseProps {
  serial: string | undefined;
}

const isFleet = (props: Props): props is FleetProps => "fleet" in props;

type Props = FleetProps | RobotProps;

export const LaserTable: FunctionComponent<Props> = (props) => {
  const { isInternal, measurementSystem } = useSelf();
  const { t, i18n } = useTranslation();
  const { lasers, loading = false } = props;
  const { data: summaries, isLoading } = useQueryPopups(useListRobotsQuery({}));
  const [installedOnly, setInstalledOnly] = useState<boolean>(true);

  const filteredLasers = useMemo<ResolvedLaserStats[]>(() => {
    if (!lasers) {
      return [];
    }
    let filteredLasers = lasers;
    if (installedOnly) {
      filteredLasers = filteredLasers.filter((laser) => {
        return !laser.removedAt;
      });
    }
    return filteredLasers.map((laser) => ({
      ...laser,
      robotSerial: summaries?.find(
        (summary) => summary.robot?.db?.id === laser.robotId
      )?.robot?.serial,
    }));
  }, [installedOnly, lasers, summaries]);
  const { searchText, setSearchText, results } =
    useFuzzySearch<ResolvedLaserStats>({
      items: filteredLasers,
      options: {
        keys: [
          "rowNumber",
          "laserId",
          "cameraId",
          "laserSerial",
          "robotSerial",
        ],
      },
    });

  const columns: Array<GridColDef<LaserStats> | GridActionsColDef> = [
    {
      ...defaultColumn,
      field: "robotId",
      headerName: t("models.robots.robot_one"),
      cellClassName: ({ value }) =>
        classes({
          "font-mono font-bold": !isLoading && value !== "Unknown",
          [STATUS_TEXT.GRAY]: isLoading || value === "Unknown",
        }),
      valueGetter: (value, laser) => {
        if (isLoading) {
          return t("components.Loading.placeholder");
        }
        const serial = summaries?.find(
          (summary) => summary.robot?.db?.id === laser.robotId
        )?.robot?.serial;
        if (!serial) {
          return t("models.robots.unknown");
        }
        return isInternal ? serial : getCustomerSerial(t, serial);
      },
      width: 150,
    },
    {
      ...defaultColumn,
      field: "laserId",
      headerName: `${titleCase(
        t("models.lasers.fields.rowNumber")
      )}-${titleCase(t("models.lasers.laser_one"))}`,
      cellClassName: "font-mono",
      valueGetter: (value, laser) => `${laser.rowNumber}-${laser.laserId}`,
      sortComparator: new Intl.Collator(undefined, { numeric: true }).compare,
      width: 120,
    },
    {
      ...defaultColumn,
      field: "cameraId",
      headerName: t("models.lasers.fields.cameraId"),
      cellClassName: "font-mono",
      width: 150,
    },
    {
      ...defaultColumn,
      field: "laserSerial",
      headerName: t("models.lasers.fields.laserSerial.name"),
      cellClassName: ({ value }) =>
        classes({ "font-mono": value, [STATUS_TEXT.GRAY]: !value }),
      width: 175,
      valueFormatter: (value) =>
        // idk why valueFormatter's value is never because it definitely is the result of valueGetter
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        value || t("models.lasers.fields.laserSerial.values.unknown"),
      valueGetter: (value, laser) => laser.laserSerial.toUpperCase(),
    },
    {
      ...defaultColumn,
      field: "installedAt",
      headerName: t("models.lasers.fields.installedAt"),
      width: 100,
      cellClassName: ({ value }) =>
        classes({
          [STATUS_TEXT.GRAY]: !value,
        }),
      valueFormatter: (value) => {
        const date = DateTime.fromFormat(value, DATETIME_EXCEL);
        return date.isValid
          ? date.toLocaleString(
              { day: "numeric", month: "short", year: "numeric" },
              { locale: i18n.language }
            )
          : "";
      },
      valueGetter: (value, laser) =>
        laser.installedAt
          ? DateTime.fromMillis(laser.installedAt).toFormat(DATETIME_EXCEL)
          : "",
    },
    {
      ...defaultColumn,
      field: "removedAt",
      headerName: t("models.lasers.fields.removedAt"),
      width: 100,
      cellClassName: ({ value }) =>
        classes({
          [STATUS_TEXT.GRAY]: !value,
        }),
      valueFormatter: (value) => {
        const date = DateTime.fromFormat(value, DATETIME_EXCEL);
        return date.isValid
          ? date.toLocaleString(
              { day: "numeric", month: "short", year: "numeric" },
              { locale: i18n.language }
            )
          : "";
      },
      valueGetter: (value, laser) =>
        laser.removedAt
          ? DateTime.fromMillis(laser.removedAt).toFormat(DATETIME_EXCEL)
          : "",
    },
    {
      ...defaultColumn,
      field: "warranty",
      headerName: t("models.lasers.fields.warranty.name"),
      width: 80,
      cellClassName: ({ value }) =>
        classes({
          [STATUS_TEXT.GRAY]: isUndefined(value),
          [STATUS_TEXT.RED]: value === 0,
          [STATUS_TEXT.YELLOW]: value <= 3 && value !== 0,
        }),
      renderCell: ({ row: laser }) => {
        const {
          overallPercent,
          monthsInstalled,
          monthsPercent,
          hoursOn,
          hoursPercent,
        } = getWarrantyDetails(laser);
        if (isUndefined(overallPercent)) {
          return (
            <div className="w-full text-center">
              {t("models.lasers.fields.warranty.values.unknown")}
            </div>
          );
        }
        const formattedOverall = formatMeasurement(
          t,
          i18n,
          measurementSystem,
          overallPercent * 100,
          UnitNumberType.PERCENT,
          { unitsDelimiter: "" }
        );
        const formattedMonths = formatMeasurement(
          t,
          i18n,
          measurementSystem,
          monthsPercent ? monthsPercent * 100 : undefined,
          UnitNumberType.PERCENT,
          { unitsDelimiter: "" }
        );
        const formattedHours = formatMeasurement(
          t,
          i18n,
          measurementSystem,
          hoursPercent ? hoursPercent * 100 : undefined,
          UnitNumberType.PERCENT,
          { unitsDelimiter: "" }
        );
        return (
          <Tooltip
            arrow
            title={
              <div className="flex flex-col gap-1">
                <div
                  className={classes({
                    [STATUS_TEXT.RED]: monthsPercent === 0,
                    [STATUS_TEXT.YELLOW]: monthsPercent && monthsPercent <= 0.5,
                    [STATUS_TEXT.GREEN]: monthsPercent && monthsPercent > 0.5,
                  })}
                >
                  {t("models.lasers.fields.warranty.values.months", {
                    installed: monthsInstalled?.toFixed(1),
                    total: WARRANTY_MONTHS,
                    percent: isUndefined(monthsPercent)
                      ? t("models.lasers.fields.warranty.values.monthsUnknown")
                      : formattedMonths.toString(),
                  })}
                </div>
                <div
                  className={classes({
                    [STATUS_TEXT.RED]: hoursPercent === 0,
                    [STATUS_TEXT.YELLOW]: hoursPercent && hoursPercent <= 0.5,
                    [STATUS_TEXT.GREEN]: hoursPercent && hoursPercent > 0.5,
                  })}
                >
                  {t("models.lasers.fields.warranty.values.hours", {
                    installed: hoursOn?.toFixed(1),
                    total: WARRANTY_HOURS,
                    percent: isUndefined(hoursPercent)
                      ? t("models.lasers.fields.warranty.values.hoursUnknown")
                      : formattedHours.toString(),
                  })}
                </div>
              </div>
            }
          >
            {overallPercent === 0 ? (
              <div className="w-full text-center">
                {t("models.lasers.fields.warranty.values.expired")}
              </div>
            ) : (
              <div className="flex w-full h-full items-center justify-end">
                {overallPercent < 0.5 && (
                  <div className="text-center flex-grow">
                    {formattedOverall.toString()}
                  </div>
                )}
                <div
                  className={classes(
                    "text-white h-10 flex items-center justify-center",
                    "border-transparent border-4 border-solid",
                    {
                      [STATUS_BG.YELLOW]: overallPercent < 0.5,
                      [STATUS_BG.GREEN]: overallPercent >= 0.5,
                    }
                  )}
                  style={{ width: `${overallPercent * 100}%` }}
                >
                  {overallPercent >= 0.5 && formattedOverall.toString()}
                </div>
              </div>
            )}
          </Tooltip>
        );
      },
      valueGetter: (value, laser) => getWarrantyDetails(laser).overallPercent,
    },
    {
      ...defaultColumn,
      field: "error",
      headerName: t("utils.descriptors.error"),
      valueGetter: (value, laser) =>
        laser.error
          ? t("utils.descriptors.error")
          : t("models.lasers.fields.error.values.false"),
      cellClassName: ({ row: laser }) =>
        classes("text-center", {
          "font-bold text-red-500": laser.error,
          "text-gray-400": !laser.error,
        }),
      width: 80,
    },
    {
      ...defaultColumn,
      field: "totalFireCount",
      headerName: t("models.lasers.fields.totalFireCount"),
      renderCell: ({ value }) => <Measurement value={value} />,
      width: 100,
    },
    {
      ...defaultColumn,
      field: "powerLevel",
      headerName: `${t("models.lasers.fields.powerLevel")} (${t(
        "utils.units.W"
      )})`,
      renderHeader: renderHeaderWithoutUnits,
      renderCell: ({ value }) => (
        <Measurement value={value} fromUnits="W" toUnits="W" />
      ),
      width: 100,
    },
    {
      ...defaultColumn,
      field: "totalFireTimeMs",
      headerName: `${t("models.lasers.fields.totalFireTimeMs")} (${t(
        "utils.units.h"
      )})`,
      renderHeader: renderHeaderWithoutUnits,
      renderCell: ({ value }) => (
        <Measurement value={value} fromUnits="h" toUnits="h" />
      ),
      valueGetter: (value, laser) =>
        laser.totalFireTimeMs
          ? convert(laser.totalFireTimeMs).from("ms").to("h")
          : "",
      width: 125,
    },
    {
      ...defaultColumn,
      field: "lifetimeSec",
      headerName: `${t("models.lasers.fields.lifetimeSec")} (${t(
        "utils.units.h"
      )})`,
      renderHeader: renderHeaderWithoutUnits,
      renderCell: ({ value }) => (
        <Measurement value={value} fromUnits="h" toUnits="h" />
      ),
      width: 125,
      valueGetter: (value, laser) =>
        laser.lifetimeSec ? convert(laser.lifetimeSec).from("s").to("h") : "",
    },
  ];

  return (
    <div className="flex flex-col h-full">
      <CarbonDataGrid<ResolvedLaserStats>
        getRowId={(row) => row.db?.id ?? -1}
        header={
          <>
            <SearchField
              value={searchText}
              onChange={setSearchText}
              label={t("utils.actions.searchLong", {
                subject: capitalize(
                  t("models.lasers.laser", { count: lasers?.length ?? 0 })
                ),
              })}
            />
            <div className="flex items-center">
              <FormControlLabel
                className="flex-shrink-0"
                control={
                  <Checkbox
                    checked={installedOnly}
                    color="default"
                    onChange={(event, checked) => setInstalledOnly(checked)}
                  />
                }
                label={t("components.LaserTable.installedOnly")}
              />
              <GridToolbarExport
                csvOptions={{
                  fileName: t("components.LaserTable.export", {
                    robots: isFleet(props)
                      ? t("views.fleet.title")
                      : getCustomerSerial(t, props.serial),
                    date: DateTime.local().toFormat(DATE_PATH_FORMAT),
                  }),
                }}
                excelOptions={{ disableToolbarButton: true }}
                printOptions={{ disableToolbarButton: true }}
              />
            </div>
          </>
        }
        columnVisibilityModel={{
          robotId: isFleet(props),
          totalFireTimeMs: isInternal,
        }}
        className={classes("flex flex-1")}
        loading={loading || isUndefined(lasers)}
        rows={results}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [
              { field: "rowNumber", sort: "asc" },
              { field: "laserId", sort: "asc" },
            ],
          },
        }}
        hideFooter
        disableRowSelectionOnClick
      />
    </div>
  );
};
