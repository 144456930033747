import {
  Attachment,
  Avatar,
  EditMessageForm,
  MessageErrorIcon,
  MessageInput,
  MessageOptions,
  MessageRepliesCountButton,
  MessageStatus,
  MessageText,
  MessageTimestamp,
  MML,
  Modal,
  useMessageContext,
} from "stream-chat-react";
import { classes } from "portal/utils/theme";
import {
  getAuth0UserId,
  hasAttachments,
  hasMML,
  hasReactions,
} from "portal/utils/stream";
import { isInternal } from "portal/utils/auth";
import { ReactComponent as Logo } from "portal/images/logo_icon_border.svg";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetUserQuery } from "portal/state/portalApi";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import ActionsIcon from "@mui/icons-material/MoreVertOutlined";
import React, { FunctionComponent, useRef } from "react";
import ReactionIcon from "@mui/icons-material/AddReactionOutlined";
import ThreadIcon from "@mui/icons-material/MessageOutlined";

export const CarbonMessage: FunctionComponent<any> = () => {
  const { t } = useTranslation();
  const { isInternal: isSelfInternal } = useSelf();
  const messageInnerRef = useRef<HTMLDivElement | null>(null);
  const {
    additionalMessageInputProps,
    clearEditingState,
    editing,
    endOfGroup,
    firstOfGroup,
    groupedByUser,
    handleAction,
    handleOpenThread,
    highlighted,
    isMyMessage,
    // isReactionEnabled,
    message,
    renderText,
    // showDetailedReactions,
    threadList,
  } = useMessageContext();
  const { data: author } = useQueryPopups(
    useGetUserQuery(
      message.user?.id ? getAuth0UserId(message.user.id) : skipToken
    )
  );
  const showReplyCountButton = !threadList && Boolean(message.reply_count);
  const messageWrapperRef = useRef<HTMLDivElement | null>(null);

  // user display
  let userName: string | undefined;
  let userTitle: string | undefined;
  if (isSelfInternal || !isInternal(author?.user)) {
    // viewer is internal or author isn't. Show name or email.
    userName = author?.user?.name ?? author?.user?.email ?? message.user?.name;
  }
  if (isInternal(author?.user)) {
    // author is internal, show title
    userTitle = t("views.fleet.robots.support.carbon");
  }

  if (message.deleted_at) {
    return (
      <div
        className={classes(
          "flex gap-2 items-start",
          "str-chat__message",
          "italic text-lighten-700",
          {
            "str-chat__message--me flex-row-reverse": isMyMessage(),
            "str-chat__message--other": !isMyMessage(),
          }
        )}
      >
        <div className="rounded-full bg-slate-300 w-8 h-8 -mt-1" />
        {t("components.Chat.messageDeleted")}
      </div>
    );
  }

  return (
    <>
      {editing && (
        <Modal onClose={clearEditingState} open={editing}>
          <MessageInput
            clearEditingState={clearEditingState}
            grow
            hideSendButton
            Input={EditMessageForm}
            message={message}
            {...additionalMessageInputProps}
          />
        </Modal>
      )}
      <div
        ref={messageWrapperRef}
        className={classes(
          "flex gap-2 items-start",
          "str-chat__message",
          `str-chat__message--${message.type}`,
          `str-chat__message--${message.status}`,
          {
            "str-chat__message--has-text": message.text,
            "has-no-text": !message.text,
            "str-chat__message--me flex-row-reverse": isMyMessage(),
            "str-chat__message--other": !isMyMessage(),
            "pinned-message": message.pinned,
            "str-chat__message--has-attachment": hasAttachments(
              message.attachments
            ),
            "str-chat__message--highlighted": highlighted,
            "str-chat__message--with-reactions str-chat__message-with-thread-link":
              hasReactions(message.reaction_counts),
            "str-chat__message-send-can-be-retried":
              message.status === "failed" && message.errorStatusCode !== 403,
            "str-chat__virtual-message__wrapper--end": endOfGroup,
            "str-chat__virtual-message__wrapper--first": firstOfGroup,
          }
        )}
      >
        {message.user && (!groupedByUser || firstOfGroup) ? (
          <div
            className={classes({
              "-mt-1": !isMyMessage(),
            })}
          >
            {!isSelfInternal && isInternal(author?.user) ? (
              <Logo className="text-carbon-orange w-8 h-8" />
            ) : (
              <Avatar
                image={author?.user?.picture ?? message.user.image}
                name={userName}
                user={message.user}
              />
            )}
          </div>
        ) : (
          <div className="w-8 h-8 flex-shrink-0" />
        )}
        <div
          className={classes(
            "str-chat__message-inner relative",
            "flex flex-col gap-1"
          )}
          ref={messageInnerRef}
        >
          {/* {showDetailedReactions &&
            isReactionEnabled &&
            messageInnerRef.current && (
              <div
                className={classes("absolute -top-14", {
                  "left-0": isMyMessage(),
                  "right-0": !isMyMessage(),
                })}
              >
                <ReactionSelector />
              </div>
            )} */}
          {!isMyMessage() && (!groupedByUser || firstOfGroup) && (
            <div>
              {userName}
              {userName && userTitle && " • "}
              {userTitle && (
                <span className="text-carbon-orange font-bold">
                  {userTitle}
                </span>
              )}
            </div>
          )}
          <div
            className={classes("str-chat__message-bubble", {
              "bg-carbon-orange text-white": isInternal(author?.user),
            })}
          >
            {hasAttachments(message.attachments) && (
              <Attachment
                attachments={message.attachments}
                actionHandler={handleAction}
              />
            )}
            <MessageText message={message} renderText={renderText} />
            {hasMML(message.mml) && (
              <MML
                actionHandler={handleAction}
                source={message.mml}
                align={isMyMessage() ? "right" : "left"}
              />
            )}
            <MessageErrorIcon />
          </div>
          <div
            className={classes("flex flex-col", {
              "items-start": !isMyMessage(),
              "items-end": isMyMessage(),
            })}
          >
            {(!groupedByUser || endOfGroup) && (
              <div className="str-chat__message-data str-chat__message-metadata">
                <MessageTimestamp calendar />
              </div>
            )}
            {/* {hasReactions(message.reaction_counts) && <ReactionsList />} */}
            {showReplyCountButton && (
              <MessageRepliesCountButton
                onClick={handleOpenThread}
                reply_count={message.reply_count}
              />
            )}
            <MessageStatus />
          </div>
        </div>
        <MessageOptions
          messageWrapperRef={messageWrapperRef}
          ThreadIcon={ThreadIcon}
          ActionsIcon={ActionsIcon}
          ReactionIcon={ReactionIcon}
        />
      </div>
    </>
  );
};
