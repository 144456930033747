import { Field, Form, Formik } from "formik";
import { IconButton, Typography, TypographyProps } from "@mui/material";
import { object, string } from "yup";
import { TEXT_FIELD_DARK } from "portal/utils/theme";
import { TextField } from "formik-mui";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/ModeEditOutlined";
import React, { FunctionComponent, useState } from "react";
import SaveIcon from "@mui/icons-material/CheckOutlined";

interface Props extends TypographyProps {
  value: string;
  onEdit: (value: string) => void;
}

export const EditableTypography: FunctionComponent<Props> = ({
  value,
  onEdit,
  ...props
}) => {
  const [isEditing, setEditing] = useState<boolean>(false);
  const { t } = useTranslation();
  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: value,
      }}
      validationSchema={object({
        name: string().required(t("utils.form.required")),
      })}
      onSubmit={(values) => {
        onEdit(values.name);
        setEditing(false);
      }}
    >
      {({ submitForm }) => (
        <Form className="flex items-center gap-4 flex-shrink min-w-0">
          {isEditing ? (
            <>
              <Field {...TEXT_FIELD_DARK} component={TextField} name="name" />
              <IconButton className="text-orange-500" onClick={submitForm}>
                <SaveIcon />
              </IconButton>
            </>
          ) : (
            <>
              <Typography
                {...props}
                className="text-ellipsis whitespace-nowrap overflow-hidden"
              >
                {value}
              </Typography>
              <IconButton
                className="text-lighten-400 hover:text-white"
                onClick={() => setEditing(true)}
              >
                <EditIcon />
              </IconButton>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};
