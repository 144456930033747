import { Bounds, Path, Point, sanitizeBounds, worker } from "portal/utils/geo";
import {
  BufferRequest,
  BufferResponse,
  filteredListener,
  TYPE_BUFFER,
} from "portal/workers/mapWorker";
import { COLOR_BLUE, COLOR_WHITE } from "portal/utils/blocks";
import { Feature } from "geojson";
import { featureCollection, lineString, point } from "@turf/helpers";
import { formatTime } from "portal/utils/metrics";
import { HealthLog } from "protos/portal/health";
import { isUndefined } from "portal/utils/identity";
import { Layer, Source } from "react-map-gl";
import { LOCALSTORAGE_MAP_BORDERS } from "portal/utils/localStorage";
import { Status, statusFromJSON } from "protos/frontend/status_bar";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useMountEffect } from "portal/utils/hooks/useMountEffect";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import React, { ReactNode, useMemo, useState } from "react";

export const HISTORY_STATUS_IDLE = "idle";
export const HISTORY_STATUS_LOADING = "loading";
export const HISTORY_STATUS_TERMINATED = "terminated";
export type HistoryStatus =
  | typeof HISTORY_STATUS_IDLE
  | typeof HISTORY_STATUS_LOADING
  | typeof HISTORY_STATUS_TERMINATED;

enum Action {
  ACTIVE = "active",
  DRIVING = "driving",
}

const SOURCE_ID = "logs";

export const useMapHistory = (
  history: HealthLog[] | undefined,
  allowBorders: boolean = false
): {
  lineSource: ReactNode;
  lineLayers: ReactNode[];
  bounds?: Bounds;
  historyStatus: HistoryStatus;
  interactiveLayerIds: string[];
} => {
  const { isInternal } = useSelf();
  const [historyStatus, setHistoryStatus] =
    useState<HistoryStatus>(HISTORY_STATUS_IDLE);
  const [requestBorders] = useLocalStorage(
    LOCALSTORAGE_MAP_BORDERS,
    isInternal
  );
  const showBorders = requestBorders && allowBorders;

  const { i18n } = useTranslation();

  const [lines, setLines] = useState<Feature[]>([]);
  const [points, setPoints] = useState<Feature[]>([]);

  // do math in a seperate thread
  useMountEffect(() => {
    worker.addEventListener(
      "message",
      filteredListener((type, data) => {
        if (type === TYPE_BUFFER) {
          setHistoryStatus(HISTORY_STATUS_IDLE);
          const { lines } = data as BufferResponse;
          setLines(lines);
        }
      })
    );
  });

  const bounds = useMemo<Bounds | undefined>(() => {
    let hasBounds = false;
    let minX = Number.POSITIVE_INFINITY;
    let maxX = Number.NEGATIVE_INFINITY;
    let minY = Number.POSITIVE_INFINITY;
    let maxY = Number.NEGATIVE_INFINITY;
    let lastY = Number.POSITIVE_INFINITY;
    let lastX = Number.POSITIVE_INFINITY;
    setPoints([]);
    const paths: Feature[] = [];
    let path: Path = [];
    let action: Action | undefined;
    const newPoints = [];

    for (const log of history ?? []) {
      if (!log.location) {
        continue;
      }
      const newAction =
        log.status === statusFromJSON(Status.STATUS_WEEDING)
          ? Action.ACTIVE
          : Action.DRIVING;
      if (newAction !== action) {
        if (path.length >= 2) {
          newPoints.push(
            ...path.map((coordinates) =>
              point(coordinates, {
                _isPoint: true,
                _time: formatTime(i18n, log.reportedAt * 1000),
                _latitude: log.location?.x.toFixed(7),
                _longitude: log.location?.y.toFixed(7),
              })
            )
          );
          paths.push(
            lineString(path, {
              _action: action,
            })
          );
        }
        path = [];
        action = newAction;
      }
      const { x, y } = log.location;
      if (isUndefined(x) || isUndefined(y)) {
        continue;
      }
      const coordinates: Point = [y, x];
      // ignore true duplicate points
      if (y === lastY && x === lastX) {
        continue;
      }
      hasBounds = true;
      minX = Math.min(minX, x);
      maxX = Math.max(maxX, x);
      minY = Math.min(minY, y);
      maxY = Math.max(maxY, y);
      lastY = y;
      lastX = x;
      path.push(coordinates);
    }
    setPoints(newPoints);
    const message: BufferRequest = {
      index: 0,
      type: TYPE_BUFFER,
      features: paths,
      radius: 10,
      units: "feet",
    };
    worker.postMessage(message);
    setHistoryStatus(HISTORY_STATUS_LOADING);
    if (hasBounds && minX !== maxX && minY !== maxY) {
      return sanitizeBounds({ minX, maxX, minY, maxY });
    }
  }, [history, i18n]);

  const lineSource = useMemo<ReactNode | undefined>(
    () => (
      <Source
        id={SOURCE_ID}
        type="geojson"
        data={featureCollection([...lines, ...(showBorders ? points : [])])}
      />
    ),
    [lines, points, showBorders]
  );

  const lineLayers = useMemo<ReactNode[]>(
    () => [
      ...(showBorders
        ? [
            <Layer
              key="points"
              id="points"
              type="circle"
              paint={{
                "circle-radius": 4,
                "circle-stroke-width": 1,
                "circle-color": COLOR_WHITE.alpha(0).toString(),
                "circle-stroke-color": COLOR_WHITE.alpha(1).toString(),
              }}
              source={SOURCE_ID}
              filter={["==", true, ["get", "_isPoint"]]}
            />,
          ]
        : []),
      <Layer
        key="active"
        id="active"
        type="fill"
        beforeId={showBorders ? "points" : undefined}
        paint={{ "fill-color": COLOR_BLUE.toString() }}
        source={SOURCE_ID}
        filter={["==", Action.ACTIVE, ["get", "_action"]]}
      />,
      <Layer
        key="driving"
        id="driving"
        type="fill"
        beforeId={showBorders ? "points" : undefined}
        paint={{ "fill-color": COLOR_WHITE.toString() }}
        source={SOURCE_ID}
        filter={["==", Action.DRIVING, ["get", "_action"]]}
      />,
    ],
    [showBorders]
  );

  const interactiveLayerIds = useMemo<string[]>(
    () => [...(showBorders ? ["points"] : [])],
    [showBorders]
  );

  return { lineSource, lineLayers, bounds, historyStatus, interactiveLayerIds };
};
