import {
  Badge,
  Button,
  Menu,
  MenuItem,
  MenuItemProps,
  Tab,
  TabProps,
  Tabs,
  TabsProps,
} from "@mui/material";
import { classes } from "portal/utils/theme";
import { isObject, values } from "portal/utils/objects";
import { isUndefined } from "portal/utils/identity";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DropdownIcon from "@mui/icons-material/ArrowDropDownOutlined";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// const OVERFLOW_BUFFER = 100;

interface NavPage {
  count?: number;
  label: string;
  to: string;
  hidden?: boolean;
}

const trimVariables = (path: string): string => {
  const result: string[] = [];
  for (const segment of path.split("/")) {
    if (segment.startsWith(":") || segment.startsWith("*")) {
      break;
    }
    result.push(segment);
  }
  return result.join("/");
};

/**
 * Responsive subnav component for use in header
 * Shows tabs for large screens and dropdown menu for small ones
 */
interface ResponsiveSubnavProps
  extends Omit<TabsProps, "onChange" | "children"> {
  onChange?: (index: number, name: string) => void;
  pages: NavPage[];
}
export const ResponsiveSubnav: FunctionComponent<ResponsiveSubnavProps> = ({
  className,
  pages,
  onChange,
  ...props
}) => {
  const { t } = useTranslation();
  const [hasResized, setHasResized] = useState<boolean>(false);
  const [visibilityMap, setVisibilityMap] = useState<Record<string, boolean>>(
    {}
  );
  const visiblePages = useMemo(
    () => pages.filter((page) => !page.hidden),
    [pages]
  );
  const hasOverflow = values(visibilityMap).includes(false);

  // adjust overflow menu when available width changes
  const lastObserverRef = useRef<IntersectionObserver | undefined>(undefined);
  const makeIntersectionObserver = useCallback(
    (): IntersectionObserver =>
      new IntersectionObserver(
        (entries) => {
          const visibilityUpdates: Record<string, boolean> = {};
          for (const entry of entries) {
            const { target, isIntersecting } = entry;
            if (!(target instanceof HTMLElement)) {
              continue;
            }
            const key = target.dataset.observable;
            if (key !== undefined) {
              visibilityUpdates[key] = isIntersecting;
            }
          }
          setVisibilityMap((old) => ({ ...old, ...visibilityUpdates }));
          setHasResized(true);
        },
        {
          root: document.querySelector(".MuiToolbar-root"),
          threshold: 1,
        }
      ),
    []
  );
  const containerRef: React.RefCallback<HTMLDivElement> = useCallback(
    (container) => {
      const lastObserver = lastObserverRef.current;
      if (lastObserver) {
        lastObserver.disconnect();
        lastObserverRef.current = undefined;
      }
      if (container) {
        const observer = makeIntersectionObserver();
        for (const item of container.querySelectorAll("[data-observable]")) {
          observer.observe(item);
        }
        lastObserverRef.current = observer;
      }
    },
    [makeIntersectionObserver]
  );

  const { pathname } = useLocation();
  const currentIndex = visiblePages.findLastIndex((page) =>
    page.to.endsWith("*")
      ? pathname.startsWith(page.to.slice(0, -2))
      : pathname.startsWith(page.to)
  );
  const currentPage = visiblePages[currentIndex];
  useEffect(() => {
    if (isObject(currentPage) && "props" in currentPage) {
      onChange?.(currentIndex, currentPage.label);
    }
  }, [currentIndex, currentPage, onChange, visiblePages]);
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | undefined>();

  if (!currentPage) {
    return;
  }

  return (
    <div
      className="overflow-hidden flex-1 min-w-0 flex gap-4"
      ref={containerRef}
    >
      <Tabs
        className={classes(
          "h-16 flex-shrink",
          { invisible: !hasResized },
          className
        )}
        classes={{ indicator: "bg-white" }}
        value={
          currentIndex === -1 || !visibilityMap[currentPage.label]
            ? false
            : currentIndex
        }
        {...props}
      >
        {visiblePages.map((page) => (
          <CarbonTab
            {...page}
            data-observable={page.label}
            key={page.label}
            className={classes("font-bold", {
              invisible: !visibilityMap[page.label],
            })}
          />
        ))}
      </Tabs>
      {hasOverflow && (
        <>
          <Button
            onClick={(event) => setMenuAnchor(event.currentTarget)}
            variant="text"
            className={classes(" text-white font-bold")}
            endIcon={<DropdownIcon />}
          >
            {t("components.ResponsiveSubnav.more")}
          </Button>
          <Menu
            onClose={() => setMenuAnchor(undefined)}
            open={!isUndefined(menuAnchor)}
            anchorEl={menuAnchor}
            className={classes(" text-white")}
          >
            {visiblePages.map((page) => (
              <CarbonMenuItem
                {...page}
                className={classes({ hidden: visibilityMap[page.label] })}
                disabled={page.label === currentPage.label}
                key={page.label}
                onClick={() => setMenuAnchor(undefined)}
              />
            ))}
          </Menu>
        </>
      )}
    </div>
  );
};

/**
 * Wrapper around MUI Tab component
 * * adds count badge
 * * support wildcard paths
 */
const CarbonTab: FunctionComponent<NavPage & TabProps> = ({
  className,
  count = 0,
  hidden,
  label,
  to,
  ...props
}) => (
  <Tab
    data-observable
    component={Link}
    className={classes("h-16", { hidden: hidden ?? false }, className)}
    classes={{ selected: "text-white" }}
    to={trimVariables(to)}
    label={
      count > 0 ? (
        <Badge className="-mt-1 pt-1" badgeContent={count} color="secondary">
          {label}
        </Badge>
      ) : (
        label
      )
    }
    {...props}
  />
);

/**
 * Wrapper around MUI MenuItem component
 * * adds count badge
 * * support wildcard paths
 */
const CarbonMenuItem: FunctionComponent<NavPage & MenuItemProps> = ({
  className,
  to,
  count = 0,
  label,
  onClick,
  hidden,
  ...props
}) => (
  <MenuItem
    component={Link}
    to={trimVariables(to)}
    onClick={onClick}
    key={label}
    className={classes(className, {
      hidden: hidden ?? false,
    })}
    {...props}
  >
    {count > 0 ? (
      <Badge badgeContent={count} color="secondary">
        {label}
      </Badge>
    ) : (
      label
    )}
  </MenuItem>
);
