import { Breakpoint, createTheme } from "@mui/material/styles";
import {
  ButtonProps,
  InputLabelProps,
  OutlinedInputProps,
  SelectProps,
  TextFieldProps,
} from "@mui/material";
import { LoadingButtonProps } from "@mui/lab";
import { twMerge } from "tailwind-merge";
import { TypographyStyleOptions } from "@mui/material/styles/createTypography.js";
import clsx, { ClassValue } from "clsx";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "portal/../tailwind.config.js";

export const { theme } = resolveConfig(tailwindConfig);

export const classes = (...inputs: ClassValue[]): string =>
  twMerge(clsx(inputs));

export const STATUS_TEXT = {
  GRAY: "text-gray-500",
  RED: "text-red-500",
  ORANGE: "text-orange-200",
  YELLOW: "text-yellow-500",
  GREEN: "text-green-500",
};

export const STATUS_BG = {
  RED: "bg-red-500 text-white",
  YELLOW: "bg-yellow-500 text-white",
  GREEN: "bg-green-500 text-white",
};

export const LABEL: InputLabelProps = {
  classes: {
    focused: "text-white",
  },
};

export const TEXT_FIELD: Pick<
  TextFieldProps,
  "variant" | "InputProps" | "InputLabelProps"
> = {
  variant: "outlined",
  InputLabelProps: LABEL,
  InputProps: {
    classes: {
      root: "group",
      notchedOutline: classes(
        "border-darken-300",
        "group-[.Mui-focused]:border-white group-hover:border-lighten-500",
        "group-[.Mui-readOnly]:border-0 group-[.Mui-readOnly]:cursor-default"
      ),
    },
  },
};

export const TEXT_FIELD_DARK: Pick<
  TextFieldProps,
  "variant" | "InputProps" | "InputLabelProps"
> = {
  ...TEXT_FIELD,
  InputProps: {
    classes: {
      ...TEXT_FIELD.InputProps?.classes,
      notchedOutline: classes(
        "border-lighten-300",
        "group-[.Mui-focused]:border-white group-hover:border-lighten-500",
        "group-[.Mui-readOnly]:border-0 group-[.Mui-readOnly]:cursor-default"
      ),
    },
  },
};

export const INPUT: Pick<OutlinedInputProps, "classes"> = {
  classes: {
    root: "group",
    input: " group-[.Mui-readOnly]:cursor-default",
    notchedOutline: classes(
      "border-darken-300",
      "group-[.Mui-focused]:border-white group-hover:border-lighten-500",
      "group-[.Mui-readOnly]:border-0"
    ),
  },
};

export const INPUT_DARK: Pick<OutlinedInputProps, "classes"> = {
  classes: {
    ...INPUT.classes,
    notchedOutline: classes(
      "border-lighten-300",
      "group-[.Mui-focused]:border-white group-hover:border-lighten-500",
      "group-[.Mui-readOnly]:border-0 group-[.Mui-readOnly]:cursor-default"
    ),
  },
};

export const SELECT: SelectProps = {
  classes: {
    root: "w-80 max-w-full",
    icon: "text-darken-300 hover:text-white group-[.Mui-readOnly]:hidden",
    iconOpen: "text-white",
  },
};

export const SELECT_DARK: SelectProps = {
  classes: {
    ...SELECT.classes,
    icon: "text-lighten-300 hover:text-white",
  },
};

export const OUTLINED_BUTTON: ButtonProps = {
  variant: "outlined",
  className: classes(
    "h-10",
    "border-darken-300 hover:border-lighten-500",
    "hover:bg-lighten-100 active:bg-white",
    "text-white active:text-carbon-orange",
    "disabled:opacity-50"
  ),
};

export const OUTLINED_BUTTON_DARK: ButtonProps = {
  ...OUTLINED_BUTTON,
  className: classes(
    "h-10",
    "border-lighten-300 hover:border-lighten-500",
    "hover:bg-lighten-100 active:bg-white",
    "text-white active:text-carbon-orange",
    "disabled:opacity-50"
  ),
};

export const BUTTON: Pick<ButtonProps, "variant" | "className" | "classes"> = {
  variant: "contained",
  className: classes("h-10 whitespace-nowrap", "disabled:bg-zinc-500"),
};

export const SMALL_BUTTON: typeof BUTTON & Pick<ButtonProps, "size"> = {
  ...BUTTON,
  className: classes(BUTTON.className, "h-8"),
  size: "small",
};

export const LOADING_BUTTON: Pick<
  LoadingButtonProps,
  "variant" | "className" | "classes" | "loadingPosition"
> = {
  ...BUTTON,
  loadingPosition: "start",
  classes: {
    loadingIndicator: "text-white",
  },
};

export const WHITE_BUTTON: typeof BUTTON = {
  ...BUTTON,
  className: classes(
    BUTTON.className,
    "bg-white text-black",
    "hover:bg-zinc-100 active:bg-zinc-200"
  ),
};

export const RED_BUTTON: typeof BUTTON = {
  ...BUTTON,
  className: classes(
    BUTTON.className,
    "bg-red-700 text-white",
    "hover:bg-red-600 active:bg-zinc-500"
  ),
};

export const GREEN_BUTTON: typeof BUTTON = {
  ...BUTTON,
  className: classes(
    BUTTON.className,
    "bg-green-700 text-white",
    "hover:bg-green-600 active:bg-zinc-500"
  ),
};

export const SMALL_SECRET_RAINBOW_BUTTON: typeof SMALL_BUTTON = {
  ...SMALL_BUTTON,
  variant: "outlined",
  className: classes(
    SMALL_BUTTON.className,
    "group border-white text-white",
    "hover:bg-darken-200"
  ),
};

export const BLUE_BUTTON: typeof BUTTON = {
  ...BUTTON,
  className: classes(
    BUTTON.className,
    "bg-blue-500 text-white",
    "hover:bg-cyan-600 active:bg-cyan-500"
  ),
};

export const BLUE_LOADING_BUTTON: typeof LOADING_BUTTON = {
  ...LOADING_BUTTON,
  ...BLUE_BUTTON,
};

export const RED_LOADING_BUTTON: typeof LOADING_BUTTON = {
  ...LOADING_BUTTON,
  ...RED_BUTTON,
};

export const WHITE_LOADING_BUTTON: typeof LOADING_BUTTON = {
  ...LOADING_BUTTON,
  ...WHITE_BUTTON,
};

export const GREEN_LOADING_BUTTON: typeof LOADING_BUTTON = {
  ...LOADING_BUTTON,
  ...GREEN_BUTTON,
};

export const SMALL_LABEL: InputLabelProps = {
  ...LABEL,
  size: "small",
};

export const SMALL_TEXT_FIELD: Pick<TextFieldProps, "size"> &
  typeof TEXT_FIELD = {
  ...TEXT_FIELD,
  size: "small",
};

export const SMALL_TEXT_FIELD_DARK: Pick<TextFieldProps, "size"> &
  typeof TEXT_FIELD = {
  ...TEXT_FIELD_DARK,
  size: "small",
};

export const SMALL_INPUT: Pick<OutlinedInputProps, "size"> & typeof INPUT = {
  ...INPUT,
  size: "small",
};

export const SMALL_INPUT_DARK: Pick<OutlinedInputProps, "size"> & typeof INPUT =
  {
    ...INPUT_DARK,
    size: "small",
  };

export const SMALL_SELECT: SelectProps = {
  ...SELECT,
  size: "small",
};

export const SMALL_SELECT_DARK: SelectProps = {
  ...SELECT_DARK,
  size: "small",
};

export interface ThemeProps {
  field: Partial<TextFieldProps>;
  label: Partial<InputLabelProps>;
  input: Partial<OutlinedInputProps>;
  select: Partial<SelectProps>;
}

export const BG_BACKDROP = "bg-carbon-gray-dark";
export const BG_CARD = "bg-carbon-gray-medium";

const mainHeadings: TypographyStyleOptions = {
  fontFamily: ["Russo One", "Poppins", "sans-serif"].join(", "),
  fontWeight: "normal",
  textTransform: "uppercase",
};

const subHeadings: TypographyStyleOptions = {
  fontFamily: ["Poppins", "sans-serif"].join(", "),
  fontWeight: "bold",
};

export const GRAPH_COLORS = [
  "#a34316",
  "#1676a3",
  "#a31630",
  "#a38916",
  "#76a316",
  "#16a343",
];

export const carbon = createTheme({
  typography: {
    fontSize: 13.5,
    fontFamily: ["Poppins", "sans-serif"].join(", "),
    h1: mainHeadings,
    h2: mainHeadings,
    h3: mainHeadings,
    h4: mainHeadings,
    h5: subHeadings,
    h6: subHeadings,
  },
  palette: {
    primary: {
      light: theme.colors.orange["300"],
      main: theme.colors.orange["500"],
      dark: theme.colors.orange["800"],
    },
    secondary: {
      light: theme.colors.blue["300"],
      main: theme.colors.blue["500"],
      dark: theme.colors.blue["800"],
    },
    warning: {
      light: theme.colors.yellow["300"],
      main: theme.colors.yellow["500"],
      dark: theme.colors.yellow["800"],
    },
    info: {
      light: theme.colors.blue["300"],
      main: theme.colors.white,
      dark: theme.colors.blue["800"],
    },
    background: {
      paper: theme.colors.carbon.gray.medium,
      default: theme.colors.carbon.gray.medium,
    },
    text: {
      primary: theme.colors.lighten["900"],
      secondary: theme.colors.lighten["600"],
      disabled: theme.colors.lighten["200"],
    },
  },
  shape: {
    borderRadius: 2,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  components: {
    MuiTooltip: {
      defaultProps: {
        classes: { tooltip: "bg-darken-800", arrow: "text-darken-800" },
      },
    },
  },
});

/**
 * There is also a good reason to disable the flag, which is if you need to use
 * the :nth-child(an + b of <selector>) pseudo-class for which no :nth-of-type()
 * equivalent exists
 */
export const FLAG_DISABLE_SSR_WARNING =
  "/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */";

export type Breakpoints = Partial<Record<Breakpoint, number>>;
