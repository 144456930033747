import { isEqual } from "../arrays";
import { isUndefined } from "../identity";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

/**
 * Hook to read and write to query string parameters as if they were state variables
 *
 * Supports encoding and parsing a number of query types (boolean, string, number, array_string, array_number)
 *
 * Can optionally pass an initial value
 */

export enum QueryType {
  BOOLEAN = "boolean",
  STRING = "string",
  NUMBER = "number",
  ARRAY_STRING = "array_string",
  ARRAY_NUMBER = "array_number",
}

function parseValue(
  value: string | undefined,
  type: QueryType.ARRAY_NUMBER
): number[] | undefined;
function parseValue(
  value: string | undefined,
  type: QueryType.ARRAY_STRING
): string[] | undefined;
function parseValue(
  value: string | undefined,
  type: QueryType.NUMBER
): number | undefined;
function parseValue(
  value: string | undefined,
  type: QueryType.BOOLEAN
): boolean | undefined;
function parseValue(value: string | undefined, type: QueryType): any;
function parseValue(value: string | undefined, type: QueryType): any {
  if (isUndefined(value)) {
    return undefined;
  }
  if (type === QueryType.BOOLEAN) {
    return Boolean(value);
  }
  if (type === QueryType.NUMBER) {
    return Number(value);
  }
  if (type === QueryType.ARRAY_STRING) {
    return value.split(",");
  }
  if (type === QueryType.ARRAY_NUMBER) {
    return value.split(",").map(Number);
  }
  return value;
}

function encodeValue(
  value: number[] | undefined,
  type: QueryType.ARRAY_NUMBER
): string | undefined;
function encodeValue(
  value: string[] | undefined,
  type: QueryType.ARRAY_STRING
): string | undefined;
function encodeValue(
  value: number | undefined,
  type: QueryType.NUMBER
): string | undefined;
function encodeValue(
  value: boolean | undefined,
  type: QueryType.BOOLEAN
): string | undefined;
function encodeValue(value: any, type: QueryType): string | undefined;
function encodeValue(value: any, type: QueryType): string | undefined {
  if (isUndefined(value)) {
    return undefined;
  }
  if (type === QueryType.BOOLEAN) {
    return String(value);
  }
  if (type === QueryType.NUMBER) {
    return String(value);
  }
  if (type === QueryType.ARRAY_STRING) {
    return value.join(",");
  }
  if (type === QueryType.ARRAY_NUMBER) {
    return value.map(String).join(",");
  }
  return value;
}

export const useQuery = <T>(
  key: string,
  type: QueryType,
  initialValue?: T
): [
  T | undefined,
  (value: T | undefined | ((value: T | undefined) => T | undefined)) => void
] => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const query = new URLSearchParams(window.location.search);
  const value = query.get(key) ?? undefined;

  const [storedValue, setStoredValue] = useState<T | undefined>(
    parseValue(value, type)
  );

  const setValue = useCallback(
    (
      value: T | undefined | ((value: T | undefined) => T | undefined)
    ): void => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore = encodeValue(
          value instanceof Function ? value(storedValue) : value,
          type
        );

        if (valueToStore !== storedValue) {
          setStoredValue(parseValue(valueToStore, type));
        }

        const query = new URLSearchParams(window.location.search);
        if (valueToStore) {
          query.set(key, valueToStore);
        } else {
          query.delete(key);
        }
        navigate(
          {
            pathname,
            search: query.toString(),
          },
          {
            replace: true,
          }
        );
      } catch (error) {
        console.warn(`Failed to update query string ${key}: ${value}`, error);
      }
    },
    [key, navigate, pathname, storedValue, type]
  );

  useEffect(() => {
    const newValue = value ? parseValue(value, type) : initialValue;
    if (
      Array.isArray(newValue) && Array.isArray(storedValue)
        ? !isEqual(newValue, storedValue)
        : newValue !== storedValue
    ) {
      setValue(newValue);
    }
  }, [value, type, initialValue, storedValue, setValue]);

  return [storedValue, setValue];
};
