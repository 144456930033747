import {
  TextField as FormikTextField,
  TextFieldProps as FormikTextFieldProps,
} from "formik-mui";
import React, { ReactNode } from "react";

export type Props = FormikTextFieldProps & {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
};

// A `formik-mui` text field that allows you to pass additional `onChange`
// and/or `onBlur` handlers, which will be called in addition to the ones
// provided by Formik.
//
// Use as the `component` of a Formik `<Field />`, like this:
//
//    return (
//      <Formik<Values> {...formikProps}>
//        <Form>
//          <Field
//            name="myFormField"
//            label="My field"
//            component={EffectfulTextField}
//            onChange={(event) => updateSomePreview(event.target.value)}
//            onBlur={(event) => prettifySomeValue(event.target.value)}
//            // plus any other props to MUI `TextField`, e.g. `multiline`
//          />
//        </Form>
//      </Formik>
//    );
export const EffectfulTextField = (props: Props): ReactNode => {
  const {
    onChange: extraOnChange,
    onBlur: extraOnBlur,
    field: { onChange: fieldOnChange, onBlur: fieldOnBlur, ...field },
    ...rest
  } = props;
  return (
    <FormikTextField
      {...rest}
      field={{
        ...field,
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
          fieldOnChange(event);
          extraOnChange?.(event);
        },
        onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
          fieldOnBlur(event);
          extraOnBlur?.(event);
        },
      }}
    />
  );
};
