import { DeepPartial } from "@reduxjs/toolkit";
import {
  isEmpty as isArrayEmpty,
  isEqual as isArraySame,
} from "portal/utils/arrays";
import { isUndefined } from "portal/utils/identity";

export const isObject = (input: unknown): input is Record<string, any> =>
  typeof input === "object" && input !== null && !Array.isArray(input);

export const findKey = (
  object: Record<string, unknown>,
  targetValue: unknown
): string | undefined => {
  for (const [key, value] of entries(object)) {
    if (value === targetValue) {
      return key;
    }
  }
};

export const isKeyOf = <T extends object>(
  object: T,
  possibleKey: keyof any
): possibleKey is keyof T => possibleKey in object;

export const omit = <T extends object, K extends keyof T>(
  object: T,
  ...keys: K[]
): Pick<T, Exclude<keyof T, K[][number]>> => {
  let result = object;
  for (const key of keys) {
    result = omitOne(result, key) as T;
  }
  return result;
};

export const pick = <T extends object, K extends keyof T>(
  object: T,
  ...keys: K[]
): { [k: string]: T[K] } => {
  return Object.fromEntries(
    keys.filter((key) => key in keys).map((key) => [key, object[key]])
  );
};

const omitOne = <T extends object, K extends keyof T>(
  object: T,
  key: K
): Omit<T, K> => {
  const { [key]: _, ...result } = object;
  return result;
};

/**
 * Utility to setting values in nested Record<string | number, *> objects
 * Recursively dives into object along path, creating new intermediate objects
 * as necessary until it can set value
 **/
export const setNested = (
  object: Record<string | number, any>,
  path: Array<string | number>,
  value: unknown
): void => {
  const [nextKey, ...keys] = path;
  if (!nextKey) {
    return;
  }
  if (isArrayEmpty(keys)) {
    object[nextKey] = value;
    return;
  }
  if (!(nextKey in object)) {
    object[nextKey] = {};
  }
  return setNested(object[nextKey], keys, value);
};

export const isEmpty = (object: Record<string, any> | undefined): boolean => {
  if (isUndefined(object)) {
    return true;
  }
  return keys(object).length === 0;
};

// TypeScript chooses not use generic types for Object.* utility methods
// I think that's dumb so here we are...
// https://github.com/microsoft/TypeScript/pull/12253#issuecomment-263132208
export const keys = Object.keys as <T>(object: T) => Extract<keyof T, string>[];

export const entries = Object.entries as <T>(
  object: T
) => T extends ArrayLike<infer U>
  ? [string, U][]
  : { [K in keyof T]: [K, T[K]] }[keyof T][];

export const values = Object.values as <T>(
  object: T
) => T extends ArrayLike<infer U> ? U[] : T[keyof T][];

export const mapValues = <T extends Record<string, any>>(
  object: T,
  mapFunction: (value: any, key: keyof T, object: T) => any
): T => {
  const result: Record<string, any> = {};
  for (const [key, value] of entries(object)) {
    result[key] = mapFunction(value, key, object);
  }
  return result as T;
};

const getType = (input: unknown): string => {
  return Object.prototype.toString.call(input).slice(8, -1).toLowerCase();
};

const areObjectsEqual = (
  a: Record<string, any>,
  b: Record<string, any>
): boolean => {
  if (Object.keys(a).length !== Object.keys(b).length) {
    return false;
  }

  for (const key in a) {
    if (
      Object.prototype.hasOwnProperty.call(a, key) &&
      !isEqual(a[key], b[key])
    ) {
      return false;
    }
  }

  return true;
};

// we explicitly want to compare any function-like inputs
// eslint-disable-next-line @typescript-eslint/ban-types
const areFunctionsEqual = (a: Function, b: Function): boolean => {
  return a.toString() === b.toString();
};

const arePrimativesEqual = (a: unknown, b: unknown): boolean => a === b;

export const isEqual = (a: unknown, b: unknown): boolean => {
  // Get the object type
  const type = getType(a);

  // If the two items are not the same type, return false
  if (type !== getType(b)) {
    return false;
  }

  // Compare based on type
  if (Array.isArray(a) && Array.isArray(b)) {
    return isArraySame(a, b);
  }
  if (isObject(a) && isObject(b)) {
    return areObjectsEqual(a, b);
  }
  if (typeof a === "function" && typeof b === "function") {
    return areFunctionsEqual(a, b);
  }
  return arePrimativesEqual(a, b);
};

/**
 * recursively transform the keys for a given object
 * e.g. snakeToCamel, etc...
 *  we use less readable methods here for performance reasons because this is run
 * over all data receive via API
 */
export const transformKeys = <T extends Record<string, any> | Array<unknown>>(
  input: T,
  transform: (input: string) => string
): T => {
  if (Array.isArray(input)) {
    const result = Array.from<any>({ length: input.length });
    let index = input.length;
    while (--index >= 0) {
      const item = input[index];
      result[index] = transformKeys(item, transform);
    }
    return result as T;
  } else if (isObject(input)) {
    const result: Record<string, any> = {};
    const keys = Object.keys(input);
    let index = keys.length;
    while (--index >= 0) {
      const key = keys[index];
      if (isUndefined(key)) {
        continue;
      }
      const value = input[key];
      if (isUndefined(value)) {
        continue;
      }
      result[transform(key)] =
        isObject(value) || Array.isArray(value)
          ? transformKeys(value, transform)
          : value;
    }
    return result as T;
  } else {
    return input;
  }
};

export const mergeDeep = <T extends Record<string, any>>(
  original: T,
  ...overrides: DeepPartial<T>[]
): T => {
  if (overrides.length === 0) {
    return { ...original };
  }
  const result = { ...original };
  for (const override of overrides) {
    for (const entry of entries(override)) {
      if (entry === undefined) {
        continue; // shouldn't happen; TypeScript is tryin' its best
      }
      const [key, value] = entry;
      Object.assign(result, {
        [key]:
          isObject(value) || Array.isArray(value)
            ? mergeDeep(result[key], value)
            : value,
      });
    }
  }
  return result;
};
