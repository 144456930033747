import { BLUE_BUTTON, WHITE_BUTTON } from "portal/utils/theme";
import { Button, Card, Grid, Typography } from "@mui/material";
import { TFunction } from "i18next";
import { Trust } from "protos/almanac/almanac";
import { TrustControl } from "./TrustControl";
import { useTranslation } from "react-i18next";
import CopyIcon from "@mui/icons-material/ContentCopyOutlined";
import PasteIcon from "@mui/icons-material/ContentPasteOutlined";
import React, { Fragment, FunctionComponent } from "react";

const getTrusts = (
  t: TFunction
): Array<{
  label: string;
  description: string;
  key: keyof Trust;
  invert?: boolean;
}> => [
  {
    label: t("components.discriminator.configs.ignorable.label"),
    key: "ignorable",
    description: t("components.discriminator.configs.ignorable.description"),
  },
  {
    label: t("components.discriminator.configs.avoid.label"),
    key: "avoid",
    description: t("components.discriminator.configs.avoid.description"),
    invert: true,
  },
];

interface Props {
  copiedTrusts: Trust | undefined;
  trusts?: Trust;
  onChange: (newTrusts: Trust) => void;
  setCopiedTrusts: (trusts: Trust | undefined) => void;
  title: string;
}

export const TrustsCard: FunctionComponent<Props> = ({
  copiedTrusts,
  trusts,
  onChange,
  setCopiedTrusts,
  title,
}) => {
  const { t } = useTranslation();
  return (
    <Card className="p-8 my-4">
      {/* Header */}
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-8 w-full gap-4">
        <Typography variant="h6">{title}</Typography>
        <div className="flex items-center gap-2">
          <Button
            {...WHITE_BUTTON}
            startIcon={<CopyIcon />}
            onClick={() => setCopiedTrusts(trusts)}
          >
            {t("components.discriminator.configs.copy")}
          </Button>
          {copiedTrusts && (
            <Button
              {...BLUE_BUTTON}
              startIcon={<PasteIcon />}
              onClick={() => {
                onChange(structuredClone(copiedTrusts));
              }}
            >
              {t("components.discriminator.configs.paste")}
            </Button>
          )}
        </div>
      </div>

      {/* Form */}
      <Grid
        container
        rowSpacing={2}
        columnSpacing={2}
        className="w-full items-center justify-center"
      >
        {getTrusts(t).map(({ label, description, key, invert }) => (
          <Fragment key={key}>
            <Grid item xs={6} md={3} className="font-bold">
              {label}
            </Grid>
            <Grid item xs={6} md={1.5}>
              <TrustControl
                value={(() => {
                  if (!trusts) {
                    return false;
                  }
                  return invert ? !trusts[key] : trusts[key];
                })()}
                inverted={invert}
                onChange={(checked) =>
                  onChange(
                    Trust.fromPartial({
                      ...trusts,
                      [key]: invert ? !checked : checked,
                    })
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={7.5} className="text-sm">
              {description}
            </Grid>
          </Fragment>
        ))}
      </Grid>
    </Card>
  );
};
