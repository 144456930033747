import { carbon } from "portal/utils/theme";
import { useMemo } from "react";
import { useWindowSize } from "@uidotdev/usehooks";

export enum Breakpoint {
  xs = carbon.breakpoints.values.xs,
  sm = carbon.breakpoints.values.sm,
  md = carbon.breakpoints.values.md,
  lg = carbon.breakpoints.values.lg,
  xl = carbon.breakpoints.values.xl,
}

const widthToBreakpoint = (width: number): Breakpoint => {
  if (width >= Breakpoint.xl) {
    return Breakpoint.xl;
  } else if (width >= Breakpoint.lg) {
    return Breakpoint.lg;
  } else if (width >= Breakpoint.md) {
    return Breakpoint.md;
  } else if (width >= Breakpoint.sm) {
    return Breakpoint.sm;
  } else {
    return Breakpoint.xs;
  }
};

/**
 * Hook to get the current MUI breakpoint based on the window width
 */
export const useBreakpoint = (): Breakpoint => {
  const { width } = useWindowSize();
  return useMemo<Breakpoint>(
    () => widthToBreakpoint(width ?? Breakpoint.sm),
    [width]
  );
};
