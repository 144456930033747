import { Alert, Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import { BetaFlag } from "portal/components/BetaFlag";
import { classes } from "portal/utils/theme";
import { formatMeasurement } from "portal/components/measurement/formatters";
import { getBreakpoints } from "portal/utils/blocks";
import {
  getSpatialMetricById,
  isSpatialOneOf,
  SPATIAL_COVERAGE,
  SPATIAL_THINNING_METRICS,
  SPATIAL_WEEDING_METRICS,
} from "portal/utils/spatialMetrics";
import { isEmpty } from "portal/utils/arrays";
import { LOCALSTORAGE_MAP_LEGEND } from "portal/utils/localStorage";
import { Measurement } from "portal/components/measurement/Measurement";
import { SpatialMetric } from "portal/utils/metrics";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import LegendToggleIcon from "@mui/icons-material/LegendToggleOutlined";
import MinimizeIcon from "@mui/icons-material/ArrowOutwardOutlined";
import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useMemo,
} from "react";

interface Props {
  className?: string;
  onOpenMenu?: () => void;
  selectedMetricId: string;
  isRelative: boolean;
  setRelative: Dispatch<SetStateAction<boolean>>;
}

export const HeatmapLegend: FunctionComponent<Props> = ({
  className,
  onOpenMenu,
  selectedMetricId,
  isRelative,
  setRelative,
}) => {
  const { t, i18n } = useTranslation();
  const { measurementSystem } = useSelf();

  const [isLegendVisible, setLegendVisible] = useLocalStorage<boolean>(
    LOCALSTORAGE_MAP_LEGEND,
    true
  );

  const selectedMetric = useMemo<SpatialMetric | undefined>(
    () =>
      selectedMetricId ? getSpatialMetricById(selectedMetricId) : undefined,
    [selectedMetricId]
  );

  if (!selectedMetric || !selectedMetric.canMap) {
    return;
  }

  if (selectedMetric === SPATIAL_COVERAGE) {
    return;
  }

  const { colors, id } = selectedMetric;
  const breakpoints = getBreakpoints(colors, isRelative);
  const isUnknown = breakpoints.every((breakpoint) => breakpoint === 0);
  const isNotWeeding =
    isSpatialOneOf(selectedMetricId, SPATIAL_WEEDING_METRICS) && isUnknown;
  const isNotThinning =
    isSpatialOneOf(selectedMetricId, SPATIAL_THINNING_METRICS) && isUnknown;

  let content = <></>;

  if (isEmpty(breakpoints) || isEmpty(colors.colors)) {
    content = (
      <Alert severity="error">
        {t("components.map.heatmaps.errors.legend")}
      </Alert>
    );
  } else if (isNotWeeding) {
    content = (
      <span className="font-bold text-md text-gray-600">
        {t("components.map.heatmaps.errors.notWeeding")}
      </span>
    );
  } else if (isNotThinning) {
    content = (
      <span className="font-bold text-md text-gray-600">
        {t("components.map.heatmaps.errors.notThinning")}
      </span>
    );
  } else if (isUnknown) {
    content = (
      <span className="font-bold text-md text-gray-600">
        {t("components.map.heatmaps.errors.unknown")}
      </span>
    );
  } else {
    content = (
      <>
        {colors.binary ? (
          <div className="flex justify-around w-full">
            {colors.colors.map((color) => (
              <div
                key={color.toString()}
                className="w-5 h-5"
                style={{
                  background: color.toString(),
                  printColorAdjust: "exact",
                  WebkitPrintColorAdjust: "exact",
                }}
              />
            ))}
          </div>
        ) : (
          <div
            className="w-full rounded-full h-5 color"
            style={{
              background: `linear-gradient(to right, ${colors.colors.join(
                ", "
              )})`,
              printColorAdjust: "exact",
              WebkitPrintColorAdjust: "exact",
            }}
          />
        )}
        <div className="flex justify-between w-full">
          {breakpoints.map((value, index, labels) => {
            const isFirst = index === 0;
            const isLast = index === labels.length - 1;
            const isMiddle = !isFirst && !isLast;
            const label = colors.labels?.[index];
            return (
              <div
                key={index}
                className={classes(
                  "text-xs whitespace-nowrap flex flex-col items-center",
                  {
                    "pr-3": !colors.binary && isFirst,
                    "px-3": colors.binary || isMiddle,
                    "pl-3": !colors.binary && isLast,
                  }
                )}
                style={{
                  width: `1/${labels.length}`,
                }}
              >
                {label && !isRelative && !colors.binary && (
                  <span>{t(label.i18nKey)}</span>
                )}
                <Measurement value={value} metric={selectedMetric} />
              </div>
            );
          })}
        </div>
      </>
    );
  }

  return (
    <>
      {!isLegendVisible && (
        <Tooltip title="Show Legend" placement="left" arrow>
          <div
            className={classes(
              "absolute top-3 right-3 md:hidden",
              "mapboxgl-ctrl mapboxgl-ctrl-group w-fit"
            )}
          >
            <button onClick={() => setLegendVisible(true)}>
              <LegendToggleIcon />
            </button>
          </div>
        </Tooltip>
      )}
      <div
        className={classes(
          "absolute top-[10px] right-[10px] z-[2]",
          "text-black bg-white rounded",
          "p-2 flex flex-col items-center gap-2",
          "cursor-default overflow-hidden",
          { "hidden sm:flex": !isLegendVisible },
          className
        )}
      >
        <BetaFlag title={t("components.BetaFlag.spatial.description")} />
        <div className="text-center font-bold flex items-center justify-center h-6 px-5">
          <span
            onClick={() => onOpenMenu?.()}
            className="reset"
            role="button"
            tabIndex={0}
          >
            {/* carbon.actions.compareKeys.ignoreDynamic */}
            {t(`utils.metrics.spatial.metrics.${id}`)}
          </span>
          <MinimizeIcon
            className="ml-1 -mr-[20px] cursor-pointer print:hidden md:hidden"
            onClick={() => setLegendVisible(false)}
          />
        </div>
        <div className="text-center text-xs italic -mt-2">
          {t("utils.metrics.spatial.heatmapWarning", {
            area: formatMeasurement(t, i18n, measurementSystem, 400, "ft2"),
          })}
        </div>
        {isNotThinning}
        {content}

        {colors.absoluteBreakpoints && !colors.binary && (
          <FormControlLabel
            className={classes("flex-shrink-0 print:hidden")}
            control={
              <Checkbox
                checked={isRelative}
                classes={{ root: "text-black" }}
                color="primary"
                onChange={(event, checked) => setRelative(checked)}
              />
            }
            label={
              <span className="whitespace-nowrap text-xs text-black">
                {t("components.map.heatmaps.relative")}
              </span>
            }
          />
        )}
      </div>
    </>
  );
};
