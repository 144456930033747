import { FleetAlarmList } from "./FleetAlarmList";
import { FleetHardware } from "./FleetHardware";
import { FleetRobotList } from "./FleetRobotList";
import { FleetSubpath, getFleetPath, Path } from "portal/utils/routing";
import { GlobalHotKeys } from "react-hotkeys";
import { GROUP_FLEET } from "portal/utils/hotkeys";
import { Header } from "portal/components/header/Header";
import { MissionControl } from "./MissionControl";
import {
  Navigate,
  useLocation,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import { NewRobot } from "portal/components/robots/NewRobot";
import { Page } from "portal/components/Page";
import { ResponsiveSubnav } from "portal/components/ResponsiveSubnav";
import { titleCase } from "portal/utils/strings";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { FunctionComponent } from "react";

export const Fleet: FunctionComponent = withAuthenticationRequired(
  function Fleet() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { pathname } = useLocation();

    // ### USER
    const { isInternal } = useSelf();

    // ### ROUTING
    const element = useRoutes([
      {
        path: Path.BASE,
        element: <Navigate to={getFleetPath(FleetSubpath.ROBOTS)} replace />,
      },
      {
        path: `${FleetSubpath.ROBOTS}`,
        element: <FleetRobotList />,
      },
      {
        path: `${FleetSubpath.MISSION_CONTROL}`,
        element: <MissionControl />,
      },
      {
        path: `${FleetSubpath.ALARMS}`,
        element: <FleetAlarmList />,
      },
      {
        path: `${FleetSubpath.HARDWARE}`,
        element: <FleetHardware />,
      },
    ]);

    return (
      <>
        <GlobalHotKeys
          allowChanges
          keyMap={{
            GO_ROBOTS: {
              name: t("utils.actions.goToLong", {
                subject: titleCase(t("models.robots.robot_other")),
              }),
              group: GROUP_FLEET,
              action: "keyup",
              sequence: "f r",
            },
            GO_ALARMS: {
              name: t("utils.actions.goToLong", {
                subject: titleCase(t("models.alarms.alarm_other")),
              }),
              group: GROUP_FLEET,
              action: "keyup",
              sequence: "f a",
            },
            ...(isInternal
              ? {
                  GO_HARDWARE: {
                    name: t("utils.actions.goToLong", {
                      subject: t("views.fleet.robots.hardware.title"),
                    }),
                    group: GROUP_FLEET,
                    action: "keyup",
                    sequence: "f h",
                  },
                }
              : {}),
            GO_MISSION_CONTROL: {
              name: t("utils.actions.goToLong", {
                subject: titleCase(t("views.fleet.missionControl.title")),
              }),
              group: GROUP_FLEET,
              action: "keyup",
              sequence: "f m",
            },
          }}
          handlers={{
            GO_ROBOTS: () => navigate(getFleetPath(FleetSubpath.ROBOTS)),
            GO_ALARMS: () => navigate(getFleetPath(FleetSubpath.ALARMS)),
            GO_HARDWARE: () => navigate(getFleetPath(FleetSubpath.HARDWARE)),
            GO_MISSION_CONTROL: () =>
              navigate(getFleetPath(FleetSubpath.MISSION_CONTROL)),
          }}
        />
        <Header title={t("views.fleet.title")}>
          <ResponsiveSubnav
            pages={[
              {
                label: titleCase(t("models.robots.robot_other")),
                to: getFleetPath(FleetSubpath.ROBOTS),
              },
              {
                label: titleCase(t("models.alarms.alarm_other")),
                to: getFleetPath(FleetSubpath.ALARMS),
              },
              {
                label: titleCase(t("views.fleet.robots.hardware.title")),
                to: getFleetPath(FleetSubpath.HARDWARE),
                hidden: !isInternal,
              },
            ]}
          />
          {pathname.startsWith(getFleetPath(FleetSubpath.ROBOTS)) && (
            <NewRobot />
          )}
        </Header>
        <Page>{element}</Page>
      </>
    );
  }
);
