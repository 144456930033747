import { Alert, Backdrop, Box, Button, CircularProgress } from "@mui/material";
import { BG_BACKDROP, classes } from "portal/utils/theme";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { formatError } from "portal/state/portalApi";
import { ReactComponent as Logo } from "portal/images/logo_stack.svg";
import { mapBackgroundStyle } from "./Page";
import { SerializedError } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import BrokenIcon from "@mui/icons-material/HeartBrokenOutlined";
import React, { FunctionComponent } from "react";

interface Props {
  error?: string | SerializedError | FetchBaseQueryError;
  failed?: boolean;
  loading?: boolean;
  overlay?: boolean;
  variant?: "opaque" | "translucent";
}

export const Loading: FunctionComponent<Props> = ({
  error,
  failed,
  loading = true,
  overlay,
  variant = "opaque",
}) => {
  const { t } = useTranslation();
  const loadingContent = (
    <Box className="flex flex-col gap-10 items-center h-full w-full justify-center">
      <Logo className="text-white w-40" />
      {failed ? (
        <Alert
          severity="error"
          icon={<BrokenIcon />}
          action={
            <Button color="inherit" size="small" component="a" href="/logout">
              {t("utils.actions.reload")}
            </Button>
          }
        >
          <div className="text-lg">{t("components.Loading.failed")}</div>
          {error && (
            <div className="font-mono text-sm mt-2">
              {formatError(t, error)}
            </div>
          )}
        </Alert>
      ) : (
        <CircularProgress classes={{ circle: "text-white" }} />
      )}
    </Box>
  );
  if (!loading) {
    return;
  }
  return overlay ? (
    <Backdrop
      open
      className={classes({
        "bg-opacity-60": variant === "translucent",
        [BG_BACKDROP]: variant !== "translucent",
      })}
      style={variant === "translucent" ? undefined : mapBackgroundStyle}
    >
      {loadingContent}
    </Backdrop>
  ) : (
    <div
      className={classes(BG_BACKDROP, "absolute inset-0")}
      style={variant === "translucent" ? undefined : mapBackgroundStyle}
    >
      {loadingContent}
    </div>
  );
};
