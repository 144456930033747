import { Chip, Link, Tooltip } from "@mui/material";
import { classes, STATUS_TEXT } from "portal/utils/theme";
import { ConfigNode } from "protos/config/api/config_service";
import { convert, MeasurementSystem } from "portal/utils/units/units";
import { CopyToClipboardButton } from "../CopyToClipboardButton";
import { ReactComponent as CustomerIcon } from "portal/images/icons/farm.svg";
import { CycleSlot } from "portal/utils/metrics";
import { DateTime } from "luxon";
import { ReactComponent as DeepweedIcon } from "portal/images/icons/model.svg";
import {
  getAlmanacPath,
  getCropPath,
  getDiscriminatorPath,
  getModelinatorPath,
  getRobotHistoryPath,
  getRobotPath,
  RobotSubpath,
} from "portal/utils/routing";
import {
  getDisabledLasers,
  hasAlarms,
  ImplementationStatus,
  isBud,
  isOffline,
  toImplementationStatus,
} from "portal/utils/robots";
import { i18n as I18n, TFunction } from "i18next";
import { isEmpty } from "portal/utils/objects";
import { isUndefined } from "portal/utils/identity";
import { LaserHealth } from "./RobotMachineHealth";
import { Measurement } from "../measurement/Measurement";
import { METRIC_ACRES_WEEDED } from "portal/utils/certifiedMetrics";
import { RobotCustomerSelector } from "./RobotCustomerSelector";
import { RobotImplementationSelector } from "./RobotImplementationSelector";
import { RobotSummaryResponse } from "protos/portal/robots";
import { Link as RouterLink } from "react-router-dom";
import { SortedCrops } from "portal/utils/crops";
import { Status } from "protos/frontend/status_bar";
import { SummaryItemProps } from "portal/components/robots/RobotSummaryCard";
import { Trans } from "react-i18next";
import AlarmIcon from "@mui/icons-material/NotificationImportantOutlined";
import AlmanacIcon from "@mui/icons-material/LanguageOutlined";
import AreaIcon from "@mui/icons-material/StraightenOutlined";
import BandingIcon from "@mui/icons-material/ViewColumnOutlined";
import CropIcon from "@mui/icons-material/GrassOutlined";
import CropsIcon from "@mui/icons-material/BookmarksOutlined";
import DiscriminatorIcon from "@mui/icons-material/SearchOutlined";
import ImplementationIcon from "@mui/icons-material/BallotOutlined";
import JobIcon from "@mui/icons-material/AssignmentOutlined";
import LasersIcon from "@mui/icons-material/AdjustOutlined";
import LifetimeIcon from "@mui/icons-material/AvTimerOutlined";
import LocationIcon from "@mui/icons-material/PlaceOutlined";
import P2PIcon from "@mui/icons-material/LocationSearchingOutlined";
import React, { ReactNode } from "react";
import StaleIcon from "@mui/icons-material/RunningWithErrorsOutlined";
import ThinningIcon from "@mui/icons-material/FlakyOutlined";
import TimeIcon from "@mui/icons-material/AccessTimeOutlined";
import VersionIcon from "@mui/icons-material/CommitOutlined";
import WeedingIcon from "@mui/icons-material/HighlightOffOutlined";

const withStaleOffline = (
  t: TFunction,
  summary: RobotSummaryResponse | undefined,
  item: SummaryItemProps
): SummaryItemProps => {
  const isRobotOffline = isOffline(summary?.robot);

  if (!isRobotOffline) {
    return item;
  }

  return {
    ...item,
    className: STATUS_TEXT.GRAY,
    actions: [
      ...(item.actions ?? []),
      <Tooltip
        key="stale"
        title={t("components.robots.RobotSummary.staleDescription")}
        arrow
        enterDelay={convert(0.5).from("s").to("ms")}
      >
        <Chip
          icon={<StaleIcon />}
          label={t("components.robots.RobotSummary.stale")}
          size="small"
          classes={{
            root: classes(
              "group",
              "text-gray-400",
              "bg-transparent hover:bg-gray-600",
              "pr-2 hover:pr-0"
            ),
            label: "hidden group-hover:block",
            icon: "text-gray-500 group-hover:text-gray-400",
          }}
        />
      </Tooltip>,
    ],
  };
};

interface LaserStatusProps extends SummaryItemProps {
  showDetails?: boolean;
}
export const getLaserStatus = (
  t: TFunction,
  serial?: string | undefined,
  config?: ConfigNode | undefined,
  summary?: RobotSummaryResponse,
  showDetails: boolean = true,
  link: boolean = true
): LaserStatusProps => {
  let disabledLasers = -1;
  if (config) {
    disabledLasers = getDisabledLasers(summary?.robot?.serial, config).length;
  }
  const totalLasers = isBud(summary?.robot) ? 8 : 30;
  const lasersText = t("components.robots.RobotSummary.lasers", {
    online: disabledLasers === -1 ? "..." : totalLasers - disabledLasers,
    total: totalLasers,
  });
  const lasersLink = (
    <Link
      component={RouterLink}
      to={getRobotPath(summary?.robot?.serial, RobotSubpath.HARDWARE_LASERS)}
      underline="hover"
      color="inherit"
    >
      {lasersText}
    </Link>
  );
  return withStaleOffline(t, summary, {
    icon: <LasersIcon />,
    className: classes({
      [STATUS_TEXT.YELLOW]: disabledLasers > 0 && disabledLasers < 10,
      [STATUS_TEXT.RED]: disabledLasers >= 10,
    }),
    text: (() => {
      const content = link ? lasersLink : lasersText;
      return showDetails && serial && config && disabledLasers >= 1 ? (
        <LaserHealth
          serial={serial}
          config={config}
          expandableTitle={content}
        />
      ) : (
        content
      );
    })(),
    isNormal: disabledLasers === 0,
    isEmpty: !config,
  });
};

export const getVersionStatus = (
  t: TFunction,
  summary?: RobotSummaryResponse,
  link: boolean = true
): SummaryItemProps => {
  const { softwareVersion, targetVersion, targetVersionReady } =
    summary?.robot?.health ?? {};
  const versionText = `${
    softwareVersion ??
    t("components.robots.RobotSummary.version.values.unknown")
  }${(() => {
    if (!targetVersion || targetVersion === softwareVersion) {
      return "";
    }
    return targetVersionReady
      ? t("components.robots.RobotSummary.version.values.updateReady", {
          version: targetVersion,
        })
      : t("components.robots.RobotSummary.version.values.updateDownloading", {
          version: targetVersion,
        });
  })()}`;
  return withStaleOffline(t, summary, {
    icon: <VersionIcon />,
    text:
      link && softwareVersion ? (
        <Link
          component={RouterLink}
          to={getRobotPath(
            summary?.robot?.serial,
            RobotSubpath.HARDWARE_VERSIONS
          )}
          underline="hover"
          color="inherit"
        >
          {versionText}
        </Link>
      ) : (
        versionText
      ),
    className: classes({
      [STATUS_TEXT.YELLOW]:
        !softwareVersion ||
        (targetVersion && targetVersion !== softwareVersion),
    }),
    isNormal: !targetVersion || targetVersion === softwareVersion,
    isEmpty: !softwareVersion,
  });
};

export const getWeedingStatus = (
  t: TFunction,
  summary?: RobotSummaryResponse
): SummaryItemProps => {
  const { isWeeding: isWeedingField } =
    summary?.robot?.health?.fieldConfig ?? {};
  const isWeedingStatus =
    summary?.robot?.health?.status === Status.STATUS_WEEDING;
  const isWeeding = isWeedingField || isWeedingStatus;
  const weedingText = isWeeding
    ? t("components.robots.RobotSummary.weeding", {
        crop: summary?.robot?.health?.crop,
      })
    : t("components.robots.RobotSummary.weedingDisabled");
  return withStaleOffline(t, summary, {
    icon: <WeedingIcon />,
    text: weedingText,
    className: isWeeding ? STATUS_TEXT.GREEN : STATUS_TEXT.GRAY,
    isNormal: isWeeding,
    isEmpty: !isWeeding,
  });
};

export const getCropStatus = (
  t: TFunction,
  summary?: RobotSummaryResponse,
  link: boolean = true
): SummaryItemProps => {
  const { crop, cropId } = summary?.robot?.health ?? {};
  let cropText: string;
  if (crop) {
    cropText = crop;
  } else if (cropId) {
    cropText = cropId;
  } else {
    cropText = t("models.crops.categories.unknown");
  }
  return withStaleOffline(t, summary, {
    icon: <CropIcon />,
    text:
      link && crop && cropId ? (
        <Link
          component={RouterLink}
          to={getCropPath(summary?.robot?.serial ?? "", cropId)}
          underline="hover"
          color="inherit"
        >
          {cropText}
        </Link>
      ) : (
        cropText
      ),
    className: crop ? undefined : STATUS_TEXT.YELLOW,
    isNormal: Boolean(crop),
    isEmpty: !crop,
  });
};

export const getBandingStatus = (
  t: TFunction,
  summary?: RobotSummaryResponse
): SummaryItemProps => {
  const {
    bandingEnabled,
    bandingDynamic,
    activeBandConfig,
    activeBandConfigName,
  } = summary?.robot?.health?.fieldConfig ?? {};
  let bandingText: string;
  if (bandingEnabled) {
    bandingText =
      activeBandConfigName || activeBandConfig
        ? t("components.robots.RobotSummary.banding.withName", {
            name: activeBandConfigName ?? activeBandConfig,
          })
        : t("components.robots.RobotSummary.banding.enabled");

    if (!bandingDynamic) {
      bandingText += ` ${t("components.robots.RobotSummary.banding.static")}`;
    }
  } else {
    bandingText = t("components.robots.RobotSummary.banding.disabled");
  }
  return withStaleOffline(t, summary, {
    icon: <BandingIcon />,
    text: bandingText,
    className: bandingEnabled ? undefined : STATUS_TEXT.GRAY,
    isNormal: bandingEnabled,
    isEmpty: !bandingEnabled,
  });
};

export const getThinningStatus = (
  t: TFunction,
  summary?: RobotSummaryResponse
): SummaryItemProps => {
  const { activeThinningConfigId, activeThinningConfigName, isThinning } =
    summary?.robot?.health?.fieldConfig ?? {};
  let thinningText: string;
  if (isThinning) {
    thinningText =
      activeThinningConfigName || activeThinningConfigId
        ? t("components.robots.RobotSummary.thinning.withName", {
            name: activeThinningConfigName ?? activeThinningConfigId,
          })
        : t("components.robots.RobotSummary.thinning.enabled");
  } else {
    thinningText = t("components.robots.RobotSummary.thinning.disabled");
  }
  return withStaleOffline(t, summary, {
    icon: <ThinningIcon />,
    text: thinningText,
    className: isThinning ? STATUS_TEXT.GREEN : STATUS_TEXT.GRAY,
    isNormal: isThinning,
    isEmpty: !isThinning,
  });
};

export const getAlmanacStatus = (
  t: TFunction,
  summary?: RobotSummaryResponse,
  link: boolean = true
): SummaryItemProps => {
  const { activeAlmanacId, activeAlmanacName } =
    summary?.robot?.health?.fieldConfig ?? {};
  let hasAlmanac = false;
  let almanacText: string;
  if (activeAlmanacName) {
    almanacText = t("components.robots.RobotSummary.almanac.withName", {
      name: activeAlmanacName,
    });
    hasAlmanac = true;
  } else if (activeAlmanacId) {
    almanacText = t("components.robots.RobotSummary.almanac.withName", {
      name: activeAlmanacId,
    });
    hasAlmanac = true;
  } else {
    almanacText = t("components.robots.RobotSummary.almanac.unknown");
  }
  return withStaleOffline(t, summary, {
    icon: <AlmanacIcon />,
    text:
      link && hasAlmanac && activeAlmanacId ? (
        <Link
          component={RouterLink}
          to={getAlmanacPath(summary?.robot?.serial ?? "", activeAlmanacId)}
          underline="hover"
          color="inherit"
        >
          {almanacText}
        </Link>
      ) : (
        almanacText
      ),
    className: hasAlmanac ? undefined : STATUS_TEXT.YELLOW,
    isNormal: hasAlmanac,
    isEmpty: !hasAlmanac,
  });
};

export const getDiscriminatorStatus = (
  t: TFunction,
  summary?: RobotSummaryResponse,
  link: boolean = true
): SummaryItemProps => {
  const { activeDiscriminatorId, activeDiscriminatorName } =
    summary?.robot?.health?.fieldConfig ?? {};
  let hasDiscriminator = false;
  let discriminatorText: string;
  if (activeDiscriminatorName) {
    discriminatorText = t(
      "components.robots.RobotSummary.discriminator.withName",
      {
        name: activeDiscriminatorName,
      }
    );
    hasDiscriminator = true;
  } else if (activeDiscriminatorId) {
    discriminatorText = t(
      "components.robots.RobotSummary.discriminator.withName",
      {
        name: activeDiscriminatorId,
      }
    );
    hasDiscriminator = true;
  } else {
    discriminatorText = t(
      "components.robots.RobotSummary.discriminator.unknown"
    );
  }
  return withStaleOffline(t, summary, {
    icon: <DiscriminatorIcon />,
    text:
      link && hasDiscriminator && activeDiscriminatorId ? (
        <Link
          component={RouterLink}
          to={getDiscriminatorPath(
            summary?.robot?.serial ?? "",
            activeDiscriminatorId
          )}
          underline="hover"
          color="inherit"
        >
          {discriminatorText}
        </Link>
      ) : (
        discriminatorText
      ),
    className: hasDiscriminator ? undefined : STATUS_TEXT.YELLOW,
    isNormal: hasDiscriminator,
    isEmpty: !hasDiscriminator,
  });
};

export const getModelinatorStatus = (
  t: TFunction,
  summary?: RobotSummaryResponse,
  link: boolean = true
): SummaryItemProps => {
  const { model, cropId } = summary?.robot?.health ?? {};
  let hasModelinator = false;
  let modelinatorText: ReactNode;
  if (model && cropId) {
    modelinatorText = (
      <Trans
        i18nKey="components.robots.RobotSummary.model.withName"
        values={{
          name: model,
        }}
        components={[<span key={0} className="text-xs font-mono" />]}
      />
    );
    hasModelinator = true;
  } else {
    modelinatorText = <>{t("models.models.unknown")}</>;
  }
  return withStaleOffline(t, summary, {
    icon: <DeepweedIcon />,
    text:
      link && hasModelinator && model && cropId ? (
        <>
          <Link
            component={RouterLink}
            to={getModelinatorPath(summary?.robot?.serial ?? "", cropId, model)}
            underline="hover"
            color="inherit"
          >
            {modelinatorText}
          </Link>
        </>
      ) : (
        modelinatorText
      ),
    actions: [<CopyToClipboardButton key="clipboard" text={model ?? ""} />],
    className: hasModelinator ? undefined : STATUS_TEXT.YELLOW,
    isNormal: hasModelinator,
    isEmpty: !hasModelinator,
  });
};

export const getJobStatus = (
  t: TFunction,
  summary?: RobotSummaryResponse,
  link: boolean = true
): SummaryItemProps => {
  const { activeJobId, activeJobName } =
    summary?.robot?.health?.fieldConfig ?? {};
  let hasJob = false;
  let jobText: string;
  if (activeJobName || activeJobId) {
    jobText = t("components.robots.RobotSummary.job.withName", {
      name: activeJobName ?? activeJobId,
    });
    hasJob = true;
  } else {
    jobText = t("components.robots.RobotSummary.job.none");
  }
  return withStaleOffline(t, summary, {
    icon: <JobIcon />,
    text:
      link && hasJob && activeJobId ? (
        <Link
          component={RouterLink}
          to={getRobotHistoryPath(summary?.robot?.serial ?? "", activeJobId)}
          underline="hover"
          color="inherit"
        >
          {jobText}
        </Link>
      ) : (
        jobText
      ),
    className: hasJob ? undefined : STATUS_TEXT.GRAY,
    isNormal: hasJob,
    isEmpty: !hasJob,
    hideEmpty: true,
  });
};

export const getCropLibraryStatus = (
  t: TFunction,
  summary?: RobotSummaryResponse,
  crops?: SortedCrops,
  link: boolean = true
): SummaryItemProps => {
  const cropsText = crops
    ? t("components.robots.RobotSummary.crop.summary", {
        enabled: crops.enabled.length,
        total: crops.all.length,
        pinned: crops.pinnedAndEnabledCrops,
      })
    : t("components.Loading.placeholder");
  return {
    icon: <CropsIcon />,
    text: link ? (
      <Link
        component={RouterLink}
        to={getRobotPath(summary?.robot?.serial, RobotSubpath.CROPS)}
        underline="hover"
        color="inherit"
      >
        {cropsText}
      </Link>
    ) : (
      cropsText
    ),
    className: classes({
      [STATUS_TEXT.YELLOW]: crops && crops.pinnedAndEnabledCrops > 0,
    }),
    isNormal:
      crops && crops.enabled.length > 0 && crops.pinnedAndEnabledCrops === 0,
    isEmpty: !crops,
  };
};

export const getAlarmsStatus = (
  t: TFunction,
  summary?: RobotSummaryResponse,
  link: boolean = true
): SummaryItemProps => {
  const activeAlarmCount = summary?.robot?.health?.activeAlarmCount ?? 0;
  const alarmText = `${activeAlarmCount} ${t("models.alarms.alarm", {
    count: activeAlarmCount,
  })}`;

  return withStaleOffline(t, summary, {
    icon: <AlarmIcon />,
    text: link ? (
      <Link
        component={RouterLink}
        to={getRobotPath(summary?.robot?.serial, RobotSubpath.ALARMS)}
        underline="hover"
        color="inherit"
      >
        {alarmText}
      </Link>
    ) : (
      alarmText
    ),
    className: classes({
      [STATUS_TEXT.GRAY]: !summary,
      [STATUS_TEXT.YELLOW]: hasAlarms(summary),
    }),
    isNormal: activeAlarmCount === 0,
    isEmpty: activeAlarmCount === 0,
  });
};

export const getTodayStatus = (
  t: TFunction,
  i18n: I18n,
  summary?: RobotSummaryResponse
): SummaryItemProps => {
  const areaToday = summary?.dailyMetrics?.acresWeeded;
  const timeToday = (summary?.dailyMetrics?.weedingUptimeSeconds ?? 0) * 1000;
  const hasData = !isUndefined(areaToday) || timeToday > 0;
  return withStaleOffline(t, summary, {
    icon: <AreaIcon />,
    text:
      areaToday || timeToday ? (
        <div className="flex items-center gap-1 whitespace-nowrap">
          {t("components.DateRangePicker.today")}:
          <Measurement
            value={summary?.dailyMetrics?.weedingUptimeSeconds}
            fromUnits="s"
            cycleSlot={CycleSlot.STATUS_TIME}
          />
          <Measurement
            fromUnits="ac"
            value={areaToday}
            cycleSlot={CycleSlot.STATUS_AREA}
          />
        </div>
      ) : (
        t("components.robots.RobotSummary.today.none")
      ),
    className: classes({
      [STATUS_TEXT.GRAY]: !hasData,
    }),
    isNormal: hasData,
    isEmpty: !hasData,
  });
};

export const getLocationStatus = (
  t: TFunction,
  summary?: RobotSummaryResponse
): SummaryItemProps => {
  const latitude = summary?.robot?.health?.location?.x;
  const longitude = summary?.robot?.health?.location?.y;
  return withStaleOffline(t, summary, {
    icon: <LocationIcon />,
    text: isEmpty(location) ? (
      t("components.robots.RobotSummary.location.unknown")
    ) : (
      <Trans
        i18nKey="components.robots.RobotSummary.location.known"
        values={{
          latitude,
          longitude,
        }}
        components={[<span key={0} className="font-mono text-xs" />]}
      />
    ),
    actions: [
      <CopyToClipboardButton
        key="clipboard"
        text={`${latitude}, ${longitude}`}
      />,
    ],
    className: classes({
      [STATUS_TEXT.GRAY]: !location,
    }),
    isNormal: !isUndefined(location),
    isEmpty: isUndefined(location),
  });
};

export const getCustomerStatus = (
  summary?: RobotSummaryResponse,
  isInternal: boolean = false,
  readOnly: boolean = false
): SummaryItemProps | undefined => {
  if (!isInternal) {
    return;
  }
  const customerId = summary?.customer?.db?.id;
  return {
    icon: <CustomerIcon />,
    text: <RobotCustomerSelector summary={summary} readOnly={readOnly} />,
    isNormal: !isUndefined(customerId),
    isEmpty: isUndefined(customerId),
    hideEmpty: true,
  };
};

export const getImplementationStatus = (
  summary?: RobotSummaryResponse,
  readOnly: boolean = false
): SummaryItemProps => {
  const status = toImplementationStatus(summary?.robot?.implementationStatus);
  return {
    icon: <ImplementationIcon />,
    text: <RobotImplementationSelector summary={summary} readOnly={readOnly} />,
    className: classes({
      [STATUS_TEXT.YELLOW]: status !== ImplementationStatus.ACTIVE,
    }),
    isNormal: status === ImplementationStatus.ACTIVE,
  };
};

export const getP2PStatus = (
  t: TFunction,
  summary?: RobotSummaryResponse
): SummaryItemProps => {
  const { p2p } = summary?.robot?.health ?? {};
  return withStaleOffline(t, summary, {
    icon: <P2PIcon />,

    text: p2p ? (
      <>
        <Trans
          i18nKey="components.robots.RobotSummary.p2p.known"
          values={{ p2p }}
          components={[<span key={0} className="font-mono text-xs" />]}
        />
      </>
    ) : (
      t("components.robots.RobotSummary.p2p.unknown")
    ),
    actions: [<CopyToClipboardButton key="clipboard" text={p2p ?? ""} />],
    className: p2p ? undefined : STATUS_TEXT.YELLOW,
    isNormal: !isUndefined(p2p),
    isEmpty: isUndefined(p2p),
  });
};

export const getLifetimeStatus = (
  t: TFunction,
  i18n: I18n,
  measurementSystem: MeasurementSystem,
  summary?: RobotSummaryResponse
): SummaryItemProps => {
  const robotRuntime240v = summary?.robot?.health?.robotRuntime240v;
  const areaWeededTotal =
    summary?.robot?.health?.performance?.weeding?.areaWeededTotal;
  return withStaleOffline(t, summary, {
    icon: <LifetimeIcon />,
    className: classes({
      [STATUS_TEXT.GRAY]: !robotRuntime240v,
    }),
    text: (
      <div className="flex items-center gap-1 whitespace-nowrap">
        {t("components.robots.RobotSummary.lifetime")}
        <Measurement
          value={robotRuntime240v}
          fromUnits="h"
          cycleSlot={CycleSlot.STATUS_TIME}
        />
        <Measurement
          value={areaWeededTotal}
          metric={METRIC_ACRES_WEEDED}
          cycleSlot={CycleSlot.STATUS_AREA}
        />
      </div>
    ),
    isNormal: !isUndefined(robotRuntime240v),
    isEmpty: isUndefined(robotRuntime240v),
  });
};

export const getLocaltimeStatus = (
  t: TFunction,
  i18n: I18n,
  summary?: RobotSummaryResponse
): SummaryItemProps => {
  const timezone = summary?.robot?.health?.timezone;
  return {
    icon: <TimeIcon />,
    text: t("views.fleet.robots.support.localTime", {
      time: DateTime.local()
        .setZone(timezone)
        .toLocaleString(
          { ...DateTime.TIME_SIMPLE, weekday: "short", day: "numeric" },
          { locale: i18n.language }
        ),
    }),
    isNormal: Boolean(timezone),
    isEmpty: !timezone,
    hideEmpty: true,
  };
};
