import { classes } from "portal/utils/theme";
import { DATE_PATH_FORMAT } from "portal/utils/dates";
import {
  DateRange,
  DateRangePicker,
  DateRangePickerProps,
  MultiInputDateRangeField,
} from "@mui/x-date-pickers-pro";
import { DateTime } from "luxon";
import { useShadowValue } from "portal/utils/hooks/useShadowValue";
import { useTranslation } from "react-i18next";
import { withErrorBoundary } from "portal/components/ErrorBoundary";
import React, { FunctionComponent } from "react";

export const getDateRangeDescription = (range: DateRange<DateTime>): string =>
  `${range[0]?.toFormat(DATE_PATH_FORMAT)} - ${range[1]?.toFormat(
    DATE_PATH_FORMAT
  )}`;

export const CarbonDateRangePicker: FunctionComponent<
  DateRangePickerProps<DateTime>
> = withErrorBoundary(
  function CarbonDateRangePicker({
    slots,
    slotProps,
    value: externalValue,
    readOnly,
    onChange,
    ...props
  }) {
    const { t } = useTranslation();

    const [value, setValue, { skipRender }] = useShadowValue<
      DateRange<DateTime> | undefined
    >(externalValue);

    if (skipRender) {
      return;
    }

    return (
      <DateRangePicker<DateTime>
        readOnly={readOnly}
        localeText={{
          start: t("components.DateRangePicker.startDate"),
          end: t("components.DateRangePicker.endDate"),
        }}
        slots={{
          field: MultiInputDateRangeField,
          ...slots,
        }}
        value={value ?? externalValue}
        onChange={([startDate, endDate], event) => {
          setValue([startDate, endDate]);
          if (
            !startDate ||
            !startDate.isValid ||
            !endDate ||
            !endDate.isValid
          ) {
            return;
          }
          onChange?.([startDate, endDate], event);
        }}
        slotProps={{
          fieldRoot: {
            className: classes("group flex gap-2", props.className, {
              "Mui-readOnly": readOnly,
            }),
          },
          fieldSeparator: {
            className: "hidden",
          },
          ...slotProps,
        }}
        {...props}
      />
    );
  },
  { i18nKey: "components.DateRangePicker.error", small: true }
);
