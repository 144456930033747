import { AdminAlarms } from "./AdminAlarms";
import { AdminConfigs } from "./AdminConfigs";
import { AdminPortal } from "./AdminPortal";
import { AdminSubpath, getAdminPath } from "portal/utils/routing";
import { AlmanacList } from "portal/components/almanac/AlmanacList";
import { Header } from "portal/components/header/Header";
import { Navigate, useRoutes } from "react-router-dom";
import { Page } from "portal/components/Page";
import { ResponsiveSubnav } from "portal/components/ResponsiveSubnav";
import { RobotClass } from "portal/utils/robots";
import { titleCase } from "portal/utils/strings";
import {
  useDeleteGlobalAlmanacMutation,
  useDeleteGlobalTargetVelocityEstimatorMutation,
  useGetGlobalAlmanacQuery,
  useGetGlobalTargetVelocityEstimatorQuery,
  useListGlobalAlmanacsQuery,
  useListGlobalTargetVelocityEstimatorsQuery,
  useSetGlobalAlmanacMutation,
  useSetGlobalTargetVelocityEstimatorMutation,
} from "portal/state/portalApi";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { VelocityEstimatorList } from "../../components/velocityEstimator/VelocityEstimatorList";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { FunctionComponent, useState } from "react";

export const Admin: FunctionComponent = withAuthenticationRequired(
  function Admin() {
    const { isInternal } = useSelf();

    const { t } = useTranslation();

    // ### ROUTING
    const element = useRoutes([
      {
        path: `${AdminSubpath.CONFIG}/:class/*`,
        element: <AdminConfigs />,
      },
      {
        path: `${AdminSubpath.CONFIG}`,
        element: (
          <Navigate
            to={`${getAdminPath(AdminSubpath.CONFIG)}/${RobotClass.Slayers}`}
            replace
          />
        ),
      },
      { path: AdminSubpath.ALARMS, element: <AdminAlarms /> },
      {
        path: `${AdminSubpath.ALMANAC}/*`,
        element: (
          <AlmanacList
            adminEditing
            basePath={getAdminPath(AdminSubpath.ALMANAC)}
            useListQuery={useListGlobalAlmanacsQuery}
            useGetQuery={useGetGlobalAlmanacQuery}
            useDeleteMutation={useDeleteGlobalAlmanacMutation}
            useSetMutation={useSetGlobalAlmanacMutation}
          />
        ),
      },
      {
        path: `${AdminSubpath.VELOCITY_ESTIMATOR}/*`,
        element: (
          <VelocityEstimatorList
            adminEditing
            basePath={getAdminPath(AdminSubpath.VELOCITY_ESTIMATOR)}
            useListQuery={useListGlobalTargetVelocityEstimatorsQuery}
            useGetQuery={useGetGlobalTargetVelocityEstimatorQuery}
            useSetMutation={useSetGlobalTargetVelocityEstimatorMutation}
            useDeleteMutation={useDeleteGlobalTargetVelocityEstimatorMutation}
          />
        ),
      },
      {
        path: AdminSubpath.PORTAL,
        element: <AdminPortal />,
      },
      {
        path: "*",
        element: <Navigate to={getAdminPath(AdminSubpath.CONFIG)} replace />,
      },
    ]);

    const [subtitle, setSubtitle] = useState<string>(t("views.admin.title"));

    if (!isInternal) {
      return;
    }

    return (
      <>
        <Header title={subtitle}>
          <ResponsiveSubnav
            onChange={(index, name) => setSubtitle(name)}
            pages={[
              {
                label: titleCase(t("models.configs.config_other")),
                to: `/admin${AdminSubpath.CONFIG}`,
              },
              {
                label: titleCase(t("models.alarms.alarm_other")),
                to: `/admin${AdminSubpath.ALARMS}`,
              },
              {
                label: titleCase(t("models.almanacs.almanac_other")),
                to: `/admin${AdminSubpath.ALMANAC}`,
              },
              {
                label: titleCase(
                  t("models.velocityEstimators.velocityEstimator_other")
                ),
                to: `/admin${AdminSubpath.VELOCITY_ESTIMATOR}`,
              },
              {
                label: titleCase(t("views.admin.portal.title")),
                to: `/admin${AdminSubpath.PORTAL}`,
              },
            ]}
          />
        </Header>
        <Page>{element}</Page>
      </>
    );
  }
);
