import { Loading } from "portal/components/Loading";
import { useAuth0 } from "@auth0/auth0-react";
import React, { ReactElement } from "react";

export const Auth0Callback = (): ReactElement => {
  const auth0State = useAuth0();
  const { error } = auth0State;

  console.debug("Auth0Callback.tsx:render: auth0State:", auth0State);
  return <Loading failed={Boolean(error)} error={error} overlay />;
};
