import { capitalize, formatList } from "portal/utils/strings";
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import {
  SMALL_INPUT_DARK,
  SMALL_LABEL,
  SMALL_SELECT_DARK,
} from "portal/utils/theme";
import { useListCustomersQuery } from "portal/state/portalApi";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { useTranslation } from "react-i18next";
import { WithSkeleton } from "../WithSkeleton";
import React, { FunctionComponent, useMemo } from "react";

interface BaseProps {
  readOnly?: boolean;
}

interface SingleProps extends BaseProps {
  value?: number | undefined;
  multiple?: false | undefined;
  onChange: (customerId: number | undefined) => void;
}

interface MultipleProps extends BaseProps {
  value?: number[] | undefined;
  multiple: true;
  onChange: (customerIds: number[] | undefined) => void;
}

export const CustomerSelector: FunctionComponent<
  SingleProps | MultipleProps
> = ({ onChange, readOnly = false, value, multiple }) => {
  const { data: allCustomers, isSuccess } = useQueryPopups(
    useListCustomersQuery()
  );
  const { t } = useTranslation();

  const selectedCustomerIds = useMemo<number[]>(() => {
    if (!value) {
      return [-1];
    }
    return multiple ? value : [value];
  }, [value, multiple]);

  const selectedCustomers = useMemo(
    () =>
      allCustomers?.filter(
        (customer) =>
          customer.db && selectedCustomerIds.includes(customer.db.id)
      ) ?? [],
    [allCustomers, selectedCustomerIds]
  );

  const label = capitalize(
    multiple
      ? t("models.customers.customer_other")
      : t("models.customers.customer_one")
  );
  const placeholder = t("components.customers.CustomerSelector.empty");

  if (readOnly) {
    return (
      <span>
        {formatList(
          t,
          selectedCustomers.map(({ name }) => name)
        ) || t("components.customers.CustomerSelector.empty")}
      </span>
    );
  }

  return (
    <WithSkeleton variant="rectangular" success={isSuccess}>
      <FormControl fullWidth className="mt-1">
        <InputLabel {...SMALL_LABEL}>{label}</InputLabel>
        <Select<typeof value>
          {...SMALL_SELECT_DARK}
          input={<OutlinedInput {...SMALL_INPUT_DARK} label={label} />}
          placeholder={placeholder}
          className="w-full"
          multiple={multiple}
          defaultValue={selectedCustomerIds}
          value={selectedCustomerIds}
          onChange={(event) => {
            const selectedCustomerIds = multiple
              ? (event.target.value as number[])
              : [event.target.value as number];
            if (multiple) {
              onChange(selectedCustomerIds);
            } else {
              const selectedCustomerId = selectedCustomerIds[0];
              if (selectedCustomerId === -1) {
                onChange(undefined);
              } else {
                onChange(selectedCustomerId);
              }
            }
          }}
          renderValue={(value) => {
            let selectedCustomerIds = value as number[];
            if (!multiple && selectedCustomerIds[0] === -1) {
              selectedCustomerIds = [];
            }
            if (selectedCustomerIds.length === 0) {
              return (
                <span className="italic text-gray-400">{placeholder}</span>
              );
            } else if (status === QueryStatus.pending) {
              return (
                <span className="italic">
                  {" "}
                  {t("components.Loading.placeholder")}
                </span>
              );
            } else {
              return formatList(
                t,
                selectedCustomers.map(({ name }) => name)
              );
            }
          }}
        >
          {!multiple && (
            <MenuItem value={-1} key={-1} className="italic">
              {placeholder}
            </MenuItem>
          )}
          {allCustomers?.map((customer) => (
            <MenuItem value={customer.db?.id} key={customer.db?.id}>
              {customer.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </WithSkeleton>
  );
};
