import { AlmanacList } from "portal/components/almanac/AlmanacList";
import { Config } from "portal/components/config/Config";
import { convert } from "portal/utils/units/units";
import { DATE_PATH_FORMAT } from "portal/utils/dates";
import { DateTime } from "luxon";
import { FeatureFlag, useFeatureFlag } from "portal/utils/hooks/useFeatureFlag";
import { getActiveAlarms, getCustomerSerial } from "portal/utils/robots";
import { getRobotPath, Path, RobotSubpath, Slug } from "portal/utils/routing";
import { GlobalHotKeys } from "react-hotkeys";
import { GROUP_ROBOT } from "portal/utils/hotkeys";
import { Header } from "portal/components/header/Header";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
  useRoutes,
} from "react-router-dom";
import { Page } from "portal/components/Page";
import { range } from "portal/utils/arrays";
import { ResponsiveSubnav } from "portal/components/ResponsiveSubnav";
import { RobotAdmin } from "./RobotAdmin";
import { RobotAlarmList } from "portal/views/robots/RobotAlarmList";
import { RobotCropList } from "portal/views/robots/RobotCropList";
import { RobotDiscriminatorList } from "./RobotDiscriminatorList";
import { RobotFields } from "portal/views/robots/RobotFields";
import { RobotHardware } from "./RobotHardware";
import { RobotHistory } from "portal/views/robots/RobotHistory";
import { RobotModelinator } from "./RobotModelinator";
import { RobotSelector } from "portal/components/robots/RobotSelector";
import { RobotSummary } from "portal/views/robots/RobotSummary";
import { RobotSupport } from "portal/views/robots/RobotSupport";
import { RobotUploads } from "portal/views/robots/RobotUploads";
import { Skeleton } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { titleCase } from "portal/utils/strings";
import {
  useDeleteAlmanacMutation,
  useDeleteTargetVelocityEstimatorMutation,
  useGetAlmanacQuery,
  useGetRobotQuery,
  useGetTargetVelocityEstimatorQuery,
  useListAlmanacsForRobotQuery,
  useListTargetVelocityEstimatorsQuery,
  useSetAlmanacMutation,
  useSetTargetVelocityEstimatorMutation,
} from "portal/state/portalApi";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { VelocityEstimatorList } from "../../components/velocityEstimator/VelocityEstimatorList";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { FunctionComponent, useState } from "react";

export const Robot: FunctionComponent = withAuthenticationRequired(
  function Robot() {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { t } = useTranslation();

    // ### USER
    const { isInternal } = useSelf();
    const { isEnabled: hasAlmanac } = useFeatureFlag(FeatureFlag.ALMANAC);
    const { isEnabled: hasTargetVelocityEstimator } = useFeatureFlag(
      FeatureFlag.TARGET_VELOCITY_ESTIMATOR
    );

    // ### ROBOT
    const { serial } = useParams();
    const { data: summary, isSuccess } = useQueryPopups(
      useGetRobotQuery(serial ?? skipToken, {
        pollingInterval: convert(1).from("min").to("ms"),
      })
    );

    // ### ROUTING
    const element = useRoutes([
      { path: Path.BASE, element: <RobotSummary /> },
      {
        path: `${RobotSubpath.HISTORY}/:startDate/:endDate`,
        element: <RobotHistory />,
      },
      {
        path: `${RobotSubpath.HISTORY}/*`,
        element: (() => {
          const today = DateTime.local();
          const lastWeek = today.minus({ days: 7 });
          return (
            <Navigate
              to={`${getRobotPath(
                serial,
                RobotSubpath.HISTORY
              )}/${lastWeek.toFormat(DATE_PATH_FORMAT)}/${today.toFormat(
                DATE_PATH_FORMAT
              )}`}
            />
          );
        })(),
      },
      { path: `${RobotSubpath.CROPS}/:cropId?`, element: <RobotCropList /> },
      {
        path: `${RobotSubpath.CROPS}/:cropId/:modelId`,
        element: <RobotModelinator />,
      },
      { path: RobotSubpath.ALARMS, element: <RobotAlarmList /> },
      { path: RobotSubpath.SUPPORT, element: <RobotSupport /> },
      ...(hasAlmanac
        ? [
            {
              path: `${RobotSubpath.ALMANAC}/*`,
              element: serial ? (
                <AlmanacList
                  serial={serial}
                  basePath={getRobotPath(serial, RobotSubpath.ALMANAC)}
                  useListQuery={useListAlmanacsForRobotQuery}
                  useGetQuery={useGetAlmanacQuery}
                  useDeleteMutation={useDeleteAlmanacMutation}
                  useSetMutation={useSetAlmanacMutation}
                />
              ) : (
                <></>
              ),
            },
            {
              path: `${RobotSubpath.DISCRIMINATOR}/*`,
              element: <RobotDiscriminatorList />,
            },
          ]
        : []),
      ...(hasTargetVelocityEstimator
        ? [
            {
              path: `${RobotSubpath.VELOCITY_ESTIMATOR}/*`,
              element: serial ? (
                <VelocityEstimatorList
                  serial={serial}
                  basePath={getRobotPath(
                    serial,
                    RobotSubpath.VELOCITY_ESTIMATOR
                  )}
                  useListQuery={useListTargetVelocityEstimatorsQuery}
                  useGetQuery={useGetTargetVelocityEstimatorQuery}
                  useSetMutation={useSetTargetVelocityEstimatorMutation}
                  useDeleteMutation={useDeleteTargetVelocityEstimatorMutation}
                />
              ) : (
                <></>
              ),
            },
          ]
        : []),
      ...(isInternal
        ? [
            {
              path: `${RobotSubpath.CONFIG}/*`,
              element: (
                <Config
                  serial={serial}
                  basePath={getRobotPath(serial, RobotSubpath.CONFIG)}
                />
              ),
            },
            {
              path: `${RobotSubpath.HARDWARE}/*`,
              element: <RobotHardware />,
            },
            { path: RobotSubpath.UPLOADS, element: <RobotUploads /> },
            {
              path: `${RobotSubpath.FIELDS}/${Slug.NEW}`,
              element: <RobotFields serial={serial} />,
            },
            { path: RobotSubpath.ADMIN, element: <RobotAdmin /> },
          ]
        : []),
    ]);
    const [subtitle, setSubtitle] = useState<string>("");

    let name: string = "";
    if (isSuccess) {
      name = isInternal
        ? summary.robot?.serial ?? t("models.robots.unknown")
        : getCustomerSerial(t, summary.robot?.serial);
    }

    return (
      <>
        <GlobalHotKeys
          allowChanges
          keyMap={{
            GO_SUMMARY: {
              name: t("utils.actions.goToLong", {
                subject: t("views.fleet.robots.summary.navTitle"),
              }),
              group: GROUP_ROBOT,
              action: "keyup",
              sequence: "r s",
            },
            GO_HISTORY: {
              name: t("utils.actions.goToLong", {
                subject: t("views.fleet.robots.history.navTitle"),
              }),
              group: GROUP_ROBOT,
              action: "keyup",
              sequence: "r h",
            },
            GO_CROPS: {
              name: t("utils.actions.goToLong", {
                subject: titleCase(t("models.crops.crop_other")),
              }),
              group: GROUP_ROBOT,
              action: "keyup",
              sequence: "r o",
            },
            GO_ALARMS: {
              name: t("utils.actions.goToLong", {
                subject: titleCase(t("models.alarms.alarm_other")),
              }),
              group: GROUP_ROBOT,
              action: "keyup",
              sequence: "r a",
            },
            GO_SUPPORT: {
              name: t("utils.actions.goToLong", {
                subject: t("views.fleet.robots.support.navTitle"),
              }),
              group: GROUP_ROBOT,
              action: "keyup",
              sequence: "r t",
            },
            ...(hasAlmanac
              ? {
                  GO_ALMANANC: {
                    name: t("utils.actions.goToLong", {
                      subject: titleCase(t("models.almanacs.almanac_other")),
                    }),
                    group: GROUP_ROBOT,
                    action: "keyup",
                    sequence: "r l",
                  },
                  GO_DISCRIMINATOR: {
                    name: t("utils.actions.goToLong", {
                      subject: titleCase(
                        t("models.discriminators.discriminator_other")
                      ),
                    }),
                    group: GROUP_ROBOT,
                    action: "keyup",
                    sequence: "r d",
                  },
                }
              : {}),
            ...(hasTargetVelocityEstimator
              ? {
                  GO_VELOCITYESTIMATOR: {
                    name: t("utils.actions.goToLong", {
                      subject: titleCase(
                        t("models.velocityEstimators.velocityEstimator_other")
                      ),
                    }),
                    group: GROUP_ROBOT,
                    action: "keyup",
                    sequence: "r v",
                  },
                }
              : {}),
            ...(isInternal
              ? {
                  GO_CONFIG: {
                    name: t("utils.actions.goToLong", {
                      subject: titleCase(t("models.configs.config_one")),
                    }),
                    group: GROUP_ROBOT,
                    action: "keyup",
                    sequence: "r c",
                  },
                  GO_HARDWARE: {
                    name: t("utils.actions.goToLong", {
                      subject: t("views.fleet.robots.hardware.title"),
                    }),
                    group: GROUP_ROBOT,
                    action: "keyup",
                    sequence: "r r",
                  },
                  GO_UPLOADS: {
                    name: t("utils.actions.goToLong", {
                      subject: titleCase(t("models.images.image_other")),
                    }),
                    group: GROUP_ROBOT,
                    action: "keyup",
                    sequence: "r u",
                  },
                  GO_ADMIN: {
                    name: t("utils.actions.goToLong", {
                      subject: t("views.admin.title"),
                    }),
                    group: GROUP_ROBOT,
                    action: "keyup",
                    sequence: "r a",
                  },
                }
              : {}),
          }}
          handlers={{
            GO_SUMMARY: () => navigate(getRobotPath(serial)),
            GO_HISTORY: () =>
              navigate(getRobotPath(serial, RobotSubpath.HISTORY)),
            GO_CONFIG: () =>
              navigate(getRobotPath(serial, RobotSubpath.CONFIG)),
            GO_CROPS: () => navigate(getRobotPath(serial, RobotSubpath.CROPS)),
            GO_ALARMS: () =>
              navigate(getRobotPath(serial, RobotSubpath.ALARMS)),
            GO_SUPPORT: () =>
              navigate(getRobotPath(serial, RobotSubpath.SUPPORT)),
            GO_HARDWARE: () =>
              navigate(getRobotPath(serial, RobotSubpath.HARDWARE)),
            GO_ALMANAC: () =>
              navigate(getRobotPath(serial, RobotSubpath.ALMANAC)),
            GO_DISCRIMINATOR: () =>
              navigate(getRobotPath(serial, RobotSubpath.DISCRIMINATOR)),
            GO_VELOCITYESTIMATOR: () =>
              navigate(getRobotPath(serial, RobotSubpath.VELOCITY_ESTIMATOR)),
            GO_UPLOADS: () =>
              navigate(getRobotPath(serial, RobotSubpath.UPLOADS)),
            GO_ADMIN: () => navigate(getRobotPath(serial, RobotSubpath.ADMIN)),
          }}
        />
        <Header
          hideTitle={true}
          title={name}
          subtitle={subtitle}
          parentLink={Path.FLEET}
        >
          <RobotSelector
            serial={serial}
            onChange={({ robot }) => {
              if (!serial || !robot) {
                return;
              }
              navigate(pathname.replace(serial, robot.serial));
            }}
            typography="h1"
            typographyClassName="text-lg"
          />
          {summary ? (
            <ResponsiveSubnav
              onChange={(index, name) => setSubtitle(name)}
              pages={[
                {
                  label: t("views.fleet.robots.summary.navTitle"),
                  to: getRobotPath(serial),
                },
                {
                  label: t("views.fleet.robots.history.navTitle"),
                  to: `${getRobotPath(serial, RobotSubpath.HISTORY)}/*`,
                },
                {
                  label: titleCase(t("models.configs.config_one")),
                  to: `${getRobotPath(serial, RobotSubpath.CONFIG)}/*`,
                  hidden: !isInternal,
                },
                {
                  count: getActiveAlarms(summary).length,
                  label: titleCase(t("models.alarms.alarm_other")),
                  to: getRobotPath(serial, RobotSubpath.ALARMS),
                },
                {
                  label: t("views.fleet.robots.support.navTitle"),
                  to: getRobotPath(serial, RobotSubpath.SUPPORT),
                },
                {
                  label: titleCase(t("models.crops.crop_other")),
                  to: getRobotPath(serial, RobotSubpath.CROPS),
                },
                {
                  label: titleCase(t("models.almanacs.almanac_other")),
                  to: `${getRobotPath(serial, RobotSubpath.ALMANAC)}/*`,
                  hidden: !hasAlmanac,
                },
                {
                  label: titleCase(
                    t("models.discriminators.discriminator_other")
                  ),
                  to: `${getRobotPath(serial, RobotSubpath.DISCRIMINATOR)}/*`,
                  hidden: !hasAlmanac,
                },
                {
                  label: titleCase(
                    t("models.velocityEstimators.velocityEstimator_other")
                  ),
                  to: `${getRobotPath(
                    serial,
                    RobotSubpath.VELOCITY_ESTIMATOR
                  )}/*`,
                  hidden: !hasTargetVelocityEstimator,
                },
                {
                  label: titleCase(t("views.fleet.robots.hardware.title")),
                  to: getRobotPath(serial, RobotSubpath.HARDWARE),
                  hidden: !isInternal,
                },
                {
                  label: titleCase(t("models.images.image_other")),
                  to: getRobotPath(serial, RobotSubpath.UPLOADS),
                  hidden: !isInternal,
                },
                {
                  label: t("views.admin.title"),
                  to: getRobotPath(serial, RobotSubpath.ADMIN),
                  hidden: !isInternal,
                },
              ]}
            />
          ) : (
            <>
              {range(3).map((index) => (
                <Skeleton
                  variant="rectangular"
                  className="w-16 h-full"
                  key={index}
                />
              ))}
            </>
          )}
        </Header>
        <Page>{element}</Page>
      </>
    );
  }
);
