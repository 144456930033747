import { AdvancedCategory } from "./AdvancedCategory";
import {
  AlmanacConfig,
  AlmanacTypeCategory,
  CategoryClassification,
  Formula,
} from "protos/almanac/almanac";
import {
  Button,
  Card,
  Divider,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { classes, WHITE_BUTTON } from "portal/utils/theme";
import { ConfigCrop } from "protos/portal/configs";
import {
  filterCategories,
  getCategoryTitle,
  getDefaultWeedCategory,
  getWeedCategoryName,
  WEED_CATEGORIES,
} from "portal/utils/almanac";
import { findWhere, sortBy, without } from "portal/utils/arrays";
import { isUndefined } from "portal/utils/identity";
import {
  LOCALSTORAGE_ALMANAC_FORMULA,
  LOCALSTORAGE_ALMANAC_SIZES,
} from "portal/utils/localStorage";
import { titleCase } from "portal/utils/strings";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import NewIcon from "@mui/icons-material/AddOutlined";
import OpenCategoryIcon from "@mui/icons-material/ChevronRightOutlined";
import React, { FunctionComponent, useState } from "react";

interface Props {
  adminEditing?: boolean;
  almanac: AlmanacConfig;
  crops: ConfigCrop[];
  onChange: (newAlmanac: AlmanacConfig) => void;
}

export const AdvancedAlmanac: FunctionComponent<Props> = ({
  adminEditing = false,
  almanac,
  crops,
  onChange,
}) => {
  const { isInternal } = useSelf();
  const canEdit = (adminEditing && isInternal) || !almanac.protected;

  const { t } = useTranslation();

  // selection state
  const [selectedCategory, setSelectedCategory] = useState<
    AlmanacTypeCategory | undefined
  >();

  // menu anchors
  const [weedsAnchor, setWeedsAnchor] = useState<
    HTMLButtonElement | undefined
  >();
  const [cropsAnchor, setCropsAnchor] = useState<
    HTMLButtonElement | undefined
  >();

  // clipboard state
  const [copiedFormula, setCopiedFormula] = useLocalStorage<
    Formula | undefined
  >(LOCALSTORAGE_ALMANAC_FORMULA);
  const [copiedSizes, setCopiedSizes] = useLocalStorage<number[] | undefined>(
    LOCALSTORAGE_ALMANAC_SIZES
  );

  // mutations
  const addCategory = (newCategory: AlmanacTypeCategory): void => {
    const updated = structuredClone(almanac);
    updated.categories.push(newCategory);
    onChange(updated);
  };

  const removeCategory = (oldCategory: AlmanacTypeCategory): void => {
    const updated = structuredClone(almanac);
    updated.categories = without(updated.categories, oldCategory);
    onChange(updated);
  };

  const updateCategory = (newCategory: AlmanacTypeCategory): void => {
    const updated = structuredClone(almanac);
    const oldCategory = findWhere(updated.categories, {
      type: { category: newCategory.type?.category },
    });
    updated.categories = [
      ...without(updated.categories, oldCategory).filter(
        (category) => !isUndefined(category)
      ),
      newCategory,
    ];
    onChange(updated);
  };

  const { weedCategories, cropCategories, defaultCategory } = filterCategories(
    almanac.categories
  );

  if (!defaultCategory) {
    return;
  }

  // Show category overlay
  if (selectedCategory) {
    return (
      <AdvancedCategory
        isProtected={!canEdit}
        category={selectedCategory}
        onChange={updateCategory}
        onClose={() => setSelectedCategory(undefined)}
        onDelete={
          selectedCategory === defaultCategory
            ? undefined
            : () => {
                removeCategory(selectedCategory);
                setSelectedCategory(undefined);
              }
        }
        title={getCategoryTitle(t, selectedCategory, crops)}
        crops={crops}
        copiedFormula={copiedFormula}
        copiedSizes={copiedSizes}
        setCopiedFormula={setCopiedFormula}
        setCopiedSizes={setCopiedSizes}
      />
    );
  }

  return (
    <div className="flex flex-col h-full">
      {/* Default Category */}
      <AdvancedAlmanacCard
        category={defaultCategory}
        onClick={() => setSelectedCategory(defaultCategory)}
        title={getCategoryTitle(t, defaultCategory, crops)}
      />
      <Divider className="w-full my-4 border-lighten-400" />

      {/* Weed Categories */}

      {/* Header */}
      <div className="flex items-center justify-between my-4">
        <Typography variant="h2" className="text-xl">
          {titleCase(t("models.weeds.weed_other"))}
        </Typography>
        <Button
          {...WHITE_BUTTON}
          startIcon={<NewIcon />}
          disabled={weedCategories.length === WEED_CATEGORIES.size}
          onClick={(event) => setWeedsAnchor(event.currentTarget)}
        >
          {t("components.almanac.weeds.new")}
        </Button>
        <Menu
          anchorEl={weedsAnchor}
          open={Boolean(weedsAnchor)}
          onClose={() => setWeedsAnchor(undefined)}
        >
          {[...WEED_CATEGORIES].map((weed) => {
            const category = findWhere(weedCategories, {
              type: { category: weed },
            });
            if (category) {
              return;
            }
            return (
              <MenuItem
                key={weed}
                onClick={() => {
                  addCategory(getDefaultWeedCategory(weed));
                  setWeedsAnchor(undefined);
                }}
              >
                {getWeedCategoryName(t, weed)}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
      {/* List */}
      {weedCategories.length === 0 && (
        <div className="flex h-40 w-full items-center justify-center text-lighten-400">
          {t("components.almanac.weeds.none")}
        </div>
      )}
      {sortBy(weedCategories, (category) => getCategoryTitle(t, category)).map(
        (category) => (
          <AdvancedAlmanacCard
            key={category.type?.category}
            category={category}
            onClick={() => setSelectedCategory(category)}
            title={getCategoryTitle(t, category)}
            crops={crops}
          />
        )
      )}

      {/* Crop Categories */}

      {/* Header */}
      <div className="flex items-center justify-between my-4">
        <Typography variant="h2" className="text-xl">
          {titleCase(t("models.crops.crop_other"))}
        </Typography>
        <Button
          {...WHITE_BUTTON}
          startIcon={<NewIcon />}
          onClick={(event) => setCropsAnchor(event.currentTarget)}
        >
          {t("components.almanac.crops.new")}
        </Button>
        <Menu
          anchorEl={cropsAnchor}
          open={Boolean(cropsAnchor)}
          onClose={() => setCropsAnchor(undefined)}
        >
          {sortBy(crops, "commonName").map((crop) => {
            const category = findWhere(cropCategories, {
              type: { category: crop.id },
            });
            if (category) {
              return;
            }
            return (
              <MenuItem
                key={crop.id}
                onClick={() => {
                  const newCategory = structuredClone(defaultCategory);
                  newCategory.type = {
                    category: crop.id,
                    classification: CategoryClassification.CATEGORY_CROP,
                  };
                  addCategory(newCategory);
                  setCropsAnchor(undefined);
                }}
                className={classes({
                  "italic text-orange-200": !crop.isEnabled,
                })}
              >
                {crop.commonName}{" "}
                {crop.isEnabled ? "" : `(${t("utils.descriptors.disabled")})`}
              </MenuItem>
            );
          })}
        </Menu>
      </div>

      {/* List */}
      {cropCategories.length === 0 && (
        <div className="flex h-40 w-full items-center justify-center text-lighten-400">
          {t("components.almanac.crops.none")}
        </div>
      )}
      {sortBy(cropCategories, (category) =>
        getCategoryTitle(t, category, crops)
      ).map((category) => (
        <AdvancedAlmanacCard
          key={category.type?.category}
          category={category}
          onClick={() => setSelectedCategory(category)}
          title={getCategoryTitle(t, category, crops)}
          crops={crops}
        />
      ))}
    </div>
  );
};

interface CardProps {
  category: AlmanacTypeCategory;
  title: string;
  onClick: () => void;
  crops?: ConfigCrop[];
}

export const AdvancedAlmanacCard: FunctionComponent<CardProps> = ({
  category,
  title,
  onClick,
  crops,
}) => {
  const { t } = useTranslation();
  const isCrop =
    category.type?.classification === CategoryClassification.CATEGORY_CROP;
  const crop = crops?.find((crop) => crop.id === category.type?.category);
  if (isCrop && crops && !crop) {
    return;
  }
  return (
    <Card
      className="p-4 my-2 flex justify-between items-center cursor-pointer"
      onClick={() => onClick()}
    >
      <Typography
        variant="h6"
        className={classes({
          "italic text-orange-200": crop && !crop.isEnabled,
        })}
      >
        {title}{" "}
        {crop && !crop.isEnabled ? `(${t("utils.descriptors.disabled")})` : ""}
      </Typography>
      <OpenCategoryIcon />
    </Card>
  );
};
