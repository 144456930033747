import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import { Breakpoint, useBreakpoint } from "portal/utils/hooks/useBreakpoint";
import { capitalize } from "portal/utils/strings";
import { carbon, classes } from "portal/utils/theme";
import { ReactComponent as CarbonLogo } from "portal/images/logo_ops_center.svg";
import { CustomerDrawer } from "portal/components/drawer/CustomerDrawer";
import { FeatureFlag, useFeatureFlag } from "portal/utils/hooks/useFeatureFlag";
import { GlobalHotKeys, GlobalHotKeysProps, KeyMap } from "react-hotkeys";
import { GROUP_NAVIGATION } from "portal/utils/hotkeys";
import { Helmet } from "react-helmet";
import { InternalDrawer } from "portal/components/drawer/InternalDrawer";
import { isUndefined } from "portal/utils/identity";
import { Link, useNavigate } from "react-router-dom";
import { LOCALSTORAGE_DRAWER_EXPANDED } from "portal/utils/localStorage";
import { Path } from "portal/utils/routing";
import { useLocalStorage } from "@uidotdev/usehooks";
import { UserTypeSwitch } from "portal/components/UserTypeSwitch";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { withErrorBoundary } from "portal/components/ErrorBoundary";
import { WithSkeleton } from "../WithSkeleton";
import CloseMenuIcon from "@mui/icons-material/MenuOpenOutlined";
import MenuIcon from "@mui/icons-material/MenuOutlined";
import ParentIcon from "@mui/icons-material/ArrowBackOutlined";
import React, { FunctionComponent, ReactNode } from "react";

interface Props {
  children?: ReactNode;
  hideDrawer?: boolean;
  hideMascot?: boolean;
  hideTitle?: boolean;
  parentLink?: string;
  subtitle?: string;
  title?: string;
}

export const Header: FunctionComponent<Props> = withErrorBoundary(
  function Header({
    children,
    hideDrawer = false,
    hideTitle = false,
    parentLink,
    subtitle,
    title,
  }) {
    const { t } = useTranslation();

    const breakpoint = useBreakpoint();
    const isSmall = breakpoint <= Breakpoint.sm;

    const [isExpanded, setExpanded] = useLocalStorage<boolean>(
      LOCALSTORAGE_DRAWER_EXPANDED,
      false
    );

    // feature flags
    const { isEnabled: hasReports } = useFeatureFlag(FeatureFlag.REPORTS);
    const { isInternal } = useSelf();

    /**
     * Navigation hotkeys
     */
    const navigate = useNavigate();
    const keyMap: KeyMap = {
      GO_SETTINGS: {
        group: GROUP_NAVIGATION,
        name: t("utils.actions.goToLong", {
          subject: t("views.settings.title"),
        }),
        action: "keyup",
        sequence: "g s",
      },
      GO_USERS: {
        group: GROUP_NAVIGATION,
        name: t("utils.actions.goToLong", {
          subject: capitalize(t("models.users.user_other")),
        }),
        action: "keyup",
        sequence: "g u",
      },
      GO_FLEET: {
        group: GROUP_NAVIGATION,
        name: t("utils.actions.goToLong", {
          subject: t("views.fleet.title"),
        }),
        action: "keyup",
        sequence: "g f",
      },
      GO_KNOWLEDGE: {
        group: GROUP_NAVIGATION,
        name: t("utils.actions.goToLong", {
          subject: t("views.knowledge.title"),
        }),
        action: "keyup",
        sequence: "g k",
      },
      GO_OFFLINE: {
        group: GROUP_NAVIGATION,
        name: t("utils.actions.goToLong", {
          subject: t("views.offline.title"),
        }),
        action: "keyup",
        sequence: "g o",
      },
      ...(hasReports
        ? {
            GO_REPORTS: {
              group: GROUP_NAVIGATION,
              name: t("utils.actions.goToLong", {
                subject: capitalize(t("models.reports.report_other")),
              }),
              action: "keyup",
              sequence: "g r",
            },
          }
        : {}),
      ...(isInternal
        ? {
            GO_CUSTOMERS: {
              group: GROUP_NAVIGATION,
              name: t("utils.actions.goToLong", {
                subject: capitalize(t("models.customers.customer_other")),
              }),
              action: "keyup",
              sequence: "g c",
            },
            GO_ADMIN: {
              group: GROUP_NAVIGATION,
              name: t("utils.actions.goToLong", {
                subject: t("views.admin.title"),
              }),
              action: "keyup",
              sequence: "g d",
            },
          }
        : {}),
    };
    const keyHandlers: GlobalHotKeysProps["handlers"] = {
      GO_SETTINGS: () => navigate(Path.SETTINGS),
      GO_ADMIN: () => navigate(Path.ADMIN),
      GO_CUSTOMERS: () => navigate(Path.CUSTOMERS),
      GO_FLEET: () => navigate(Path.FLEET),
      GO_KNOWLEDGE: () => navigate(Path.KNOWLEDGE),
      GO_OFFLINE: () => navigate(Path.OFFLINE),
      GO_REPORTS: () => navigate(Path.REPORTS),
      GO_USERS: () => navigate(Path.USERS),
    };

    return (
      <>
        <GlobalHotKeys keyMap={keyMap} handlers={keyHandlers} />
        {title && (
          <Helmet>
            <title>
              {/* no need to localize proper nouns */}
              {/* eslint-disable-next-line i18next/no-literal-string */}
              {title} {subtitle ? `${subtitle} ` : ""}- Carbon Robotics
            </title>
          </Helmet>
        )}
        <AppBar
          position="fixed"
          className={classes("items-center print:hidden")}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar
            className="w-full flex"
            style={{ maxWidth: carbon.breakpoints.values.xl }}
          >
            {!isSmall && (
              <Link to={Path.FLEET} className="2xl:fixed 2xl:top-4 2xl:left-4">
                <CarbonLogo className="h-8 mr-4" />
              </Link>
            )}
            {!hideDrawer && isSmall && (
              <IconButton
                className="flex-shrink-0"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => setExpanded(!isExpanded)}
              >
                {isExpanded ? <CloseMenuIcon /> : <MenuIcon />}
              </IconButton>
            )}

            {parentLink && (
              <IconButton
                className="flex-shrink-0"
                edge="start"
                color="inherit"
                aria-label="back to parent"
                component={Link}
                to={parentLink}
              >
                <ParentIcon />
              </IconButton>
            )}
            <WithSkeleton
              variant="rectangular"
              className="min-h-6 mr-4 whitespace-nowrap text-ellipsis overflow-hidden min-w-10 flex-shrink"
              success={!isUndefined(title)}
            >
              {hideTitle ? (
                <></>
              ) : (
                <Typography
                  variant="h1"
                  color="inherit"
                  component="div"
                  className="text-lg"
                >
                  {title}
                </Typography>
              )}
            </WithSkeleton>
            <div className="flex items-center justify-between gap-4 flex-1 overflow-hidden self-stretch">
              {children}
            </div>
          </Toolbar>
        </AppBar>
        {!hideDrawer && (
          <UserTypeSwitch
            customer={<CustomerDrawer />}
            internal={<InternalDrawer />}
          />
        )}
      </>
    );
  },
  { i18nKey: "components.header.failed", small: true }
);
