import { Card, CardContent, Typography } from "@mui/material";
import { classes, STATUS_TEXT } from "portal/utils/theme";
import { DateTime } from "luxon";
import { DEFAULT_TIMEZONE } from "portal/utils/dates";
import { FleetView } from "protos/portal/users";
import {
  formatStatus,
  getColorClass,
  getCustomerSerial,
  getDisabledLasers,
  isOffline,
} from "portal/utils/robots";
import {
  getAlarmsStatus,
  getAlmanacStatus,
  getBandingStatus,
  getCropLibraryStatus,
  getCropStatus,
  getCustomerStatus,
  getDiscriminatorStatus,
  getImplementationStatus,
  getJobStatus,
  getLaserStatus,
  getLifetimeStatus,
  getLocaltimeStatus,
  getLocationStatus,
  getModelinatorStatus,
  getP2PStatus,
  getThinningStatus,
  getTodayStatus,
  getVersionStatus,
  getWeedingStatus,
} from "portal/components/robots/RobotStatus";
import { getColumns, InfoColumnId } from "portal/utils/fleetViews";
import { getConfigNode } from "portal/utils/configs";
import { getCrops } from "portal/utils/crops";
import { getRobotPath } from "portal/utils/routing";
import { isEmpty } from "portal/utils/arrays";
import { Link } from "react-router-dom";
import { RobotSummaryResponse } from "protos/portal/robots";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetConfigQuery, useGetRobotQuery } from "portal/state/portalApi";
import { useIntersectionObserver } from "@uidotdev/usehooks";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { withErrorBoundary } from "portal/components/ErrorBoundary";
import CheckinIcon from "@mui/icons-material/CellTowerOutlined";
import PendingIcon from "@mui/icons-material/PendingOutlined";
import React, {
  Fragment,
  FunctionComponent,
  ReactElement,
  useMemo,
} from "react";
import StatusErrorIcon from "@mui/icons-material/ErrorOutlined";
import StatusOkIcon from "@mui/icons-material/CheckCircleOutlined";

interface SummaryProps {
  summary?: RobotSummaryResponse;
  className?: string;
}

export const RobotSummaryIcon: FunctionComponent<SummaryProps> =
  withErrorBoundary(function RobotSummaryIcon({ summary, className = "" }) {
    if (!summary) {
      return;
    }
    const commonClasses = `${className}`;
    const colorClass = getColorClass(summary);
    if (isOffline(summary.robot)) {
      return <PendingIcon className={classes(colorClass, commonClasses)} />;
    } else if (STATUS_TEXT.RED === colorClass) {
      return <StatusErrorIcon className={classes(colorClass, commonClasses)} />;
    } else if (STATUS_TEXT.YELLOW === colorClass) {
      return <PendingIcon className={classes(colorClass, commonClasses)} />;
    } else {
      return <StatusOkIcon className={classes(colorClass, commonClasses)} />;
    }
  });

export const RobotSummaryText: FunctionComponent<SummaryProps> =
  withErrorBoundary(
    function RobotSummaryText({ summary, className = "" }) {
      const { t, i18n } = useTranslation();
      if (!summary) {
        return;
      }
      return (
        <span
          className={classes(
            getColorClass(summary),
            "text-xs whitespace-nowrap overflow-ellipsis",
            className
          )}
        >
          {formatStatus(t, i18n, summary.robot)}
        </span>
      );
    },
    { i18nKey: "components.robots.RobotSummary.failed", small: true }
  );

export const RobotSummaryCheckedin: FunctionComponent<SummaryProps> =
  withErrorBoundary(
    function RobotSummaryCheckedin({ summary, className = "" }) {
      const { t, i18n } = useTranslation();
      const commonClasses = `flex items-center ${className}`;
      const today = DateTime.local();
      const checkin = summary?.robot?.health?.reportedAt
        ? DateTime.fromSeconds(summary.robot.health.reportedAt, {
            zone: "utc",
          }).setZone(
            summary.robot.health.timezone === ""
              ? DEFAULT_TIMEZONE
              : summary.robot.health.timezone
          )
        : undefined;
      const icon = <CheckinIcon className="mr-2" />;
      if (!DateTime.isDateTime(checkin)) {
        return (
          <div className={classes(STATUS_TEXT.YELLOW, commonClasses)}>
            {icon}
            {t("components.robots.RobotSummary.checkedIn.never")}
          </div>
        );
      }
      if (checkin.day > today.day - 1) {
        return (
          <div className={classes(STATUS_TEXT.RED, commonClasses)}>
            {icon}
            {t("components.robots.RobotSummary.checkedIn.withTime", {
              time: checkin.toRelative({ locale: i18n.language }),
            })}
          </div>
        );
      }
      return (
        <div className={classes(STATUS_TEXT.YELLOW, commonClasses)}>
          {icon}
          {t("components.robots.RobotSummary.checkedIn.withTime", {
            time: checkin.toRelative({ locale: i18n.language }),
          })}
        </div>
      );
    },
    { i18nKey: "components.robots.RobotSummary.checkedIn.failed", small: true }
  );

export interface SummaryItemProps {
  icon: ReactElement;
  className?: string;
  text: ReactElement | string;
  actions?: ReactElement[];
  isEmpty?: boolean;
  isNormal?: boolean;
  hideEmpty?: boolean;
}

export const RobotSummaryItem: FunctionComponent<SummaryItemProps> =
  withErrorBoundary(
    function RobotSummaryItem({
      icon,
      className: colorClass,
      text,
      isEmpty,
      hideEmpty = false,
      actions,
    }) {
      if (hideEmpty && isEmpty) {
        return;
      }
      return (
        <li className={classes(colorClass, "flex items-start gap-2")}>
          {icon}
          <span className="flex-1 whitespace-nowrap overflow-ellipsis overflow-hidden">
            {text}
          </span>
          {actions && <div className="flex-shrink-0">{actions}</div>}
        </li>
      );
    },
    { i18nKey: "components.robots.RobotSummary.failedShort", small: true }
  );

interface Props {
  className?: string;
  hideEmpty?: boolean;
  hideTitle?: boolean;
  link?: boolean;
  serial: string;
  short?: boolean;
  summary?: RobotSummaryResponse;
  columns?: FleetView["columns"];
}

export const RobotSummaryCard: FunctionComponent<Props> = withErrorBoundary(
  function RobotSummaryCard({
    className = "",
    hideEmpty = false,
    hideTitle = false,
    link = false,
    serial,
    short = false,
    summary: inputData,
    columns,
  }) {
    const { t, i18n } = useTranslation();
    const { user, isInternal, measurementSystem } = useSelf();

    // only load config if we're on screen
    const [ref, intersection] = useIntersectionObserver();
    const isOnScreen = intersection?.isIntersecting ?? false;

    const { data: robotQueryData } = useQueryPopups(
      useGetRobotQuery(inputData ? skipToken : serial)
    );

    const summary = inputData ?? robotQueryData;

    const { data: configQueryData } = useQueryPopups(
      useGetConfigQuery(
        (() => {
          // don't fetch config if off-screen
          if (!isOnScreen) {
            return skipToken;
          }
          // don't fetch config if we don't need it
          if (!columns || !columns.includes(InfoColumnId.LASERS_OFFLINE)) {
            return skipToken;
          }
          // don't fetch config if a config is provided for us
          if (inputData?.config) {
            return skipToken;
          }
          // don't fetch config if we don't know who we are
          if (!summary?.robot?.serial) {
            return skipToken;
          }
          return [summary.robot.serial, {}];
        })()
      ),
      { errorVariant: "warning" }
    );

    const configData = inputData?.config ?? configQueryData?.config;
    const config = configData ? getConfigNode(configData) : undefined;

    const name = isInternal
      ? summary?.robot?.serial ?? t("models.robots.unknown")
      : getCustomerSerial(t, summary?.robot?.serial);
    const crops = useMemo(
      () => getCrops(summary?.robot?.serial, config),
      [config, summary?.robot?.serial]
    );
    const weedingStatus = useMemo(
      () => getWeedingStatus(t, summary),
      [summary, t]
    );
    const thinningStatus = useMemo(
      () => getThinningStatus(t, summary),
      [summary, t]
    );
    const bandingStatus = useMemo(
      () => getBandingStatus(t, summary),
      [summary, t]
    );
    const jobStatus = useMemo(
      () => getJobStatus(t, summary, true),
      [summary, t]
    );
    const alarmStatus = useMemo(
      () => getAlarmsStatus(t, summary, true),
      [summary, t]
    );
    const versionStatus = useMemo(
      () => getVersionStatus(t, summary, isInternal),
      [isInternal, summary, t]
    );
    const todayStatus = useMemo(
      () => getTodayStatus(t, i18n, summary),
      [i18n, summary, t]
    );
    const cropStatus = useMemo(
      () => getCropStatus(t, summary, true),
      [summary, t]
    );
    const cropLibraryStatus = useMemo(
      () => getCropLibraryStatus(t, summary, crops, true),
      [crops, summary, t]
    );
    const almanacStatus = useMemo(
      () => getAlmanacStatus(t, summary, isInternal),
      [isInternal, summary, t]
    );
    const discriminatorStatus = useMemo(
      () => getDiscriminatorStatus(t, summary, isInternal),
      [summary, t, isInternal]
    );
    const modelinatorStatus = useMemo(
      () => getModelinatorStatus(t, summary, isInternal),
      [summary, t, isInternal]
    );
    const laserStatus = getLaserStatus(
      t,
      serial,
      config,
      summary,
      !short, // don't display disabled laser summary matrix on shortened views
      isInternal
    );
    const locationStatus = useMemo(
      () => getLocationStatus(t, summary),
      [summary, t]
    );
    const p2pStatus = useMemo(() => getP2PStatus(t, summary), [summary, t]);
    const lifetimeStatus = useMemo(
      () => getLifetimeStatus(t, i18n, measurementSystem, summary),
      [i18n, measurementSystem, summary, t]
    );
    const loadingStatus: SummaryItemProps = useMemo(
      () => ({
        icon: (
          <div className="w-6 text-center">
            {/* for some reason this is causing fatal performance issues */}
            {/* <CircularProgress size="1.2rem" className="text-white" /> */}
          </div>
        ),
        text: (
          <span className="text-gray-500">
            {t("components.Loading.placeholder")}
          </span>
        ),
      }),
      [t]
    );
    const implementationStatus = getImplementationStatus(
      summary,
      link || !isInternal
    );
    const localtimeStatus = getLocaltimeStatus(t, i18n, summary);
    const customerStatus = getCustomerStatus(summary, isInternal, !isInternal);

    const priorityStatus = useMemo(() => {
      const result: SummaryItemProps[] = [];
      if (columns && summary && user) {
        const allColumns = getColumns(i18n, t, user, measurementSystem);
        for (const columnId of columns) {
          const column = allColumns.find((column) => column.id === columnId);
          if (column?.renderForCard) {
            const status = column.renderForCard(summary, config);
            if (status) {
              result.push(status);
            }
          }
        }
      } else {
        if (customerStatus) {
          result.push(customerStatus);
        }
        result.push(
          implementationStatus,
          localtimeStatus,
          todayStatus,
          alarmStatus
        );

        // conditional lasers
        if (!config) {
          result.push(loadingStatus);
        } else if (
          getDisabledLasers(summary?.robot?.serial, config).length > 0
        ) {
          result.push(laserStatus);
        }

        // conditional version
        const softwareVersion = summary?.robot?.health?.softwareVersion;
        const targetVersion = summary?.robot?.health?.targetVersion;
        if (
          !softwareVersion ||
          (targetVersion && targetVersion !== softwareVersion)
        ) {
          result.push(versionStatus);
        }

        result.push(lifetimeStatus);
      }
      return result;
    }, [
      alarmStatus,
      columns,
      config,
      customerStatus,
      i18n,
      implementationStatus,
      laserStatus,
      lifetimeStatus,
      loadingStatus,
      localtimeStatus,
      measurementSystem,
      summary,
      t,
      todayStatus,
      user,
      versionStatus,
    ]);

    const summarySections = useMemo(() => {
      const field: SummaryItemProps[] = [
        cropStatus,
        weedingStatus,
        thinningStatus,
        jobStatus,
        bandingStatus,
      ];

      const software: SummaryItemProps[] = [
        versionStatus,
        cropLibraryStatus,
        modelinatorStatus,
        almanacStatus,
        discriminatorStatus,
      ];

      const hardware: SummaryItemProps[] = [
        implementationStatus,
        localtimeStatus,
        alarmStatus,
        laserStatus,
        locationStatus,
        lifetimeStatus,
      ];

      const management: SummaryItemProps[] = [todayStatus];

      if (isInternal) {
        if (customerStatus) {
          management.push(customerStatus);
        }
        software.push(p2pStatus);
      }

      const result: {
        label: string;
        items: SummaryItemProps[];
      }[] = [
        {
          label: t("utils.metrics.groups.field"),
          items: field,
        },
        {
          label: t("views.fleet.robots.summary.sections.software"),
          items: software,
        },
        {
          label: t("views.fleet.robots.hardware.title"),
          items: hardware,
        },
        {
          label: t("views.fleet.robots.summary.sections.management"),
          items: management,
        },
      ];

      return result;
    }, [
      alarmStatus,
      almanacStatus,
      bandingStatus,
      cropLibraryStatus,
      cropStatus,
      customerStatus,
      discriminatorStatus,
      implementationStatus,
      isInternal,
      jobStatus,
      laserStatus,
      lifetimeStatus,
      localtimeStatus,
      locationStatus,
      modelinatorStatus,
      p2pStatus,
      t,
      thinningStatus,
      todayStatus,
      versionStatus,
      weedingStatus,
    ]);

    const linkPath = getRobotPath(summary?.robot?.serial);

    const content = useMemo(() => {
      if (short) {
        return (
          <ul className="p-0 flex flex-col gap-2 mb-0">
            {priorityStatus.map((status, index) => (
              <RobotSummaryItem {...status} key={index} />
            ))}
          </ul>
        );
      }
      return (
        <>
          {summarySections.map(({ label, items }) => (
            <Fragment key={label}>
              {!isEmpty(items) && (
                <div className="flex flex-col gap-2 pt-2">
                  <Typography variant="subtitle2" className="font-bold">
                    {label}
                  </Typography>
                  {items.map((status, index) => (
                    <RobotSummaryItem
                      {...status}
                      key={index}
                      hideEmpty={hideEmpty}
                    />
                  ))}
                </div>
              )}
            </Fragment>
          ))}
        </>
      );
    }, [hideEmpty, priorityStatus, short, summarySections]);

    return (
      <Card
        ref={ref}
        className={classes("flex flex-col", className, {
          "cursor-pointer": link,
        })}
      >
        <CardContent
          component={link ? Link : "div"}
          to={link ? linkPath : undefined}
        >
          {!hideTitle && (
            <div className="flex items-center gap-2">
              <RobotSummaryIcon summary={summary} />
              <Typography
                variant="h4"
                className="text-ellipsis whitespace-nowrap overflow-hidden text-rtl text-lg"
              >
                {name}
              </Typography>
              <RobotSummaryText
                summary={summary}
                className="text-ellipsis whitespace-nowrap overflow-hidden"
              />
            </div>
          )}
          {content}
        </CardContent>
      </Card>
    );
  },
  { i18nKey: "views.fleet.robots.summary.failed" }
);
