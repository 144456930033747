import { DependencyList, useEffect, useState } from "react";

// version of useMemo that allows for async functions
export const useMemoAsync = <T>(
  factory: () => Promise<T>,
  dependencies: DependencyList,
  defaultValue: T
): T => {
  const [result, setResult] = useState<T>(defaultValue);

  useEffect(() => {
    const abortController = new AbortController();
    factory()
      .then((value) => {
        if (abortController.signal.aborted) {
          return;
        }
        setResult(value);
      })
      .catch(() => {
        setResult(defaultValue);
      });

    return () => abortController.abort();
    // doesn't like the spread, validated upstream using the additionalHooks option
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return result;
};
