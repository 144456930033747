import { sleepMs } from "portal/utils/sleepMs";

/**
 * A container that allows blocking until all registered in-flight promises
 * have been completed. Used for preventing use-after-free bugs when a cleanup
 * handler needs to wait for requests from other lexical call sites.
 */
export class WaitGroup {
  _pending: Set<WeakRef<Promise<unknown>>>;

  constructor() {
    this._pending = new Set();
  }

  /**
   * Registers a promise with this wait group, such that subsequent calls to
   * `wait` will block until this promise has fulfilled (or until timeout).
   * Returns a promise equivalent to the input.
   */
  add<T>(promise: Promise<T>): Promise<T> {
    promise = Promise.resolve(promise); // defensive, in case of type-corrupted non-promise value
    const ref = new WeakRef(promise);
    this._pending.add(ref);
    return promise.finally(() => this._pending.delete(ref));
  }

  /**
   * Waits for all promises that have been `add`ed or will be added by the end
   * of this event loop; or for the provided timeout, whichever comes first.
   *
   * Note that promises added while waiting for existing promises to resolve
   * will themselves not be waited for.
   */
  async wait(timeoutMs: number): Promise<void> {
    // Defer a frame so that any promises that are added to the wait group this
    // event loop get properly awaited. Particularly useful since execution
    // order of React `useEffect` cleanup handlers is undefined by design:
    // https://github.com/facebook/react/issues/16728#issuecomment-584208473
    await sleepMs(0);

    const pending = Array.from(this._pending, (weakRef) => weakRef.deref());
    await Promise.race([sleepMs(timeoutMs), Promise.allSettled(pending)]);
  }
}
