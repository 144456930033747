import { BlockResponse } from "protos/portal/spatial";
import { DATE_PATH_FORMAT } from "portal/utils/dates";
import { DateTime } from "luxon";
import { FeatureFlag, useFeatureFlag } from "portal/utils/hooks/useFeatureFlag";
import { HealthLog } from "protos/portal/health";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  useGetJobBlocksQuery,
  useGetJobHistoryQuery,
  useGetRobotBlocksQuery,
  useGetRobotHistoryQuery,
} from "portal/state/portalApi";
import { useMemo } from "react";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { useSelf } from "portal/state/store";

interface RobotHistory {
  blocks: BlockResponse[] | undefined;
  hasBlocks: boolean;
  hasLogs: boolean;
  isLoading: boolean;
  logs: HealthLog[] | undefined;
}

export const useRobotHistory = (
  serial: string | undefined,
  jobId?: string,
  date?: DateTime
): RobotHistory => {
  const { isInternal } = useSelf();
  const { isEnabled: hasSpatial } = useFeatureFlag(FeatureFlag.SPATIAL);

  const today = useMemo(() => DateTime.local(), []);

  const { data: jobBlocksResponse, isLoading: isJobsBlocksLoading } =
    useQueryPopups(useGetJobBlocksQuery(jobId ?? skipToken));

  // get logs if selected job is a job and no blocks exist
  const { data: jobLogsResponse, isLoading: isJobHistoryLoading } =
    useQueryPopups(
      useGetJobHistoryQuery(
        jobId && !isJobsBlocksLoading && !jobBlocksResponse?.blocks.length
          ? jobId
          : skipToken
      ),
      {
        errorVariant: "warning",
      }
    );

  const { data: dayBlocksResponse, isLoading: isDayBlocksLoading } =
    useQueryPopups(
      useGetRobotBlocksQuery(
        (hasSpatial || isInternal) && serial && !jobId
          ? [serial, (date ?? today).toFormat(DATE_PATH_FORMAT)]
          : skipToken
      ),
      {
        errorVariant: "warning",
      }
    );

  // get logs if selected job is a date and there are no blocks
  const { data: dayLogsResponse, isLoading: isDayHistoryLoading } =
    useQueryPopups(
      useGetRobotHistoryQuery(
        serial &&
          !jobId &&
          !isDayBlocksLoading &&
          !dayBlocksResponse?.blocks.length
          ? [serial, (date ?? today).toFormat(DATE_PATH_FORMAT)]
          : skipToken
      ),
      {
        errorVariant: "warning",
      }
    );

  const blocks = useMemo<BlockResponse[] | undefined>(
    () => (jobId ? jobBlocksResponse?.blocks : dayBlocksResponse?.blocks),
    [jobId, jobBlocksResponse?.blocks, dayBlocksResponse?.blocks]
  );

  const logs = useMemo<HealthLog[] | undefined>(
    () => (jobId ? jobLogsResponse?.logs : dayLogsResponse?.logs),
    [jobId, jobLogsResponse?.logs, dayLogsResponse?.logs]
  );

  const isLoading = useMemo<boolean>(
    () =>
      isDayHistoryLoading ||
      isDayBlocksLoading ||
      isJobHistoryLoading ||
      isJobsBlocksLoading,
    [
      isDayHistoryLoading,
      isDayBlocksLoading,
      isJobHistoryLoading,
      isJobsBlocksLoading,
    ]
  );

  return {
    blocks,
    hasBlocks: Array.isArray(blocks) && blocks.length > 0,
    hasLogs: Array.isArray(logs) && logs.length > 0,
    isLoading,
    logs,
  };
};
