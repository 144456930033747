import {
  BLANK_VALUE,
  formatMetric,
} from "portal/components/measurement/formatters";
import { BlockResponse } from "protos/portal/spatial";
import { CarbonUnit, loadSavedUnit, MeasurementSystem } from "./units/units";
import {
  ConclusionType,
  conclusionTypeFromJSON,
} from "protos/weed_tracking/weed_tracking";
import { CycleSlot, SpatialMetric } from "./metrics";
import { FillLayer } from "mapbox-gl";
import { i18n as I18n, ParseKeys, TFunction } from "i18next";
import { isUndefined, isValidNumber } from "./identity";
import { percentile } from "./math";
import { point, Properties, Units } from "@turf/helpers";
import Color from "color";
import distance from "@turf/distance";

export const isWeeding = (block?: BlockResponse): boolean => {
  let totalCount = 0;
  const armedWeed = block?.block?.weedCount?.conclusionCounts?.armedWeed ?? [];
  for (const [conclusionType, count] of armedWeed.entries()) {
    if (
      ![
        conclusionTypeFromJSON(ConclusionType.NOT_WEEDING),
        conclusionTypeFromJSON(ConclusionType.FLICKER),
      ].includes(conclusionType)
    ) {
      totalCount += count;
    }
  }

  return totalCount > 0;
};

export const isThinning = (block?: BlockResponse): boolean => {
  let totalCount = 0;
  const armedCrop = block?.block?.weedCount?.conclusionCounts?.armedCrop ?? [];
  for (const [conclusionType, count] of armedCrop.entries()) {
    if (
      ![
        conclusionTypeFromJSON(ConclusionType.NOT_WEEDING),
        conclusionTypeFromJSON(ConclusionType.FLICKER),
      ].includes(conclusionType)
    ) {
      totalCount += count;
    }
  }

  return totalCount > 0;
};

export const isActive = (block?: BlockResponse): boolean =>
  isWeeding(block) || isThinning(block);

export const isCountableBlock = (block?: BlockResponse): boolean =>
  isActive(block) &&
  !(block?.block?.hwMetric?.lifted ?? false) &&
  (block?.block?.hwMetric?.laserKey ?? false) &&
  !(block?.block?.hwMetric?.estopped ?? false) &&
  (block?.block?.hwMetric?.interlock ?? false) &&
  (block?.block?.hwMetric?.waterProtect ?? false);

export const getBlockStyle = (color: string): FillLayer => ({
  id: color,
  type: "fill",
  paint: {
    "fill-color": color,
  },
});

export const getBreakpoints = (
  colors: BlockColors,
  isRelative: boolean = false
): number[] => {
  const absolute =
    colors.absoluteBreakpoints ?? colors.relativeBreakpoints ?? [];
  if (colors.binary) {
    return absolute;
  }
  const relative =
    colors.relativeBreakpoints ?? colors.absoluteBreakpoints ?? [];
  return isRelative ? relative : absolute;
};

export interface BlockColors {
  absoluteBreakpoints?: number[];
  relativeBreakpoints?: number[];
  colors: Color[];
  labels?: { i18nKey: ParseKeys }[];
  binary?: boolean;
}

export interface BlockRange {
  min: number;
  low: number;
  medium: number;
  high: number;
  max: number;
}

export const mixColor = (
  value: number,
  colors: BlockColors,
  isRelative: boolean = false
): string => {
  let color: Color | undefined = COLOR_WHITE;
  const breakpoints = getBreakpoints(colors, isRelative);
  const lowestBreakpoint = breakpoints[0];
  const highestBreakpoint = breakpoints.at(-1);
  if (isUndefined(lowestBreakpoint) || isUndefined(highestBreakpoint)) {
    return color.toString();
  }
  for (const [index, low] of breakpoints.entries()) {
    // value is at or below lowest breakpoint
    if (value <= lowestBreakpoint) {
      color = colors.colors[0];
      break;
    }
    // value is at or above highest breakpoint
    if (value >= highestBreakpoint) {
      color = colors.colors.at(-1);
      break;
    }
    const high = breakpoints[index + 1];
    if (isUndefined(high)) {
      break;
    }
    if (low <= value && value <= high) {
      const lowColor = colors.colors[index];
      const highColor = colors.colors[index + 1];
      if (!lowColor || !highColor) {
        break;
      }
      const percent = (value - low) / (high - low);
      color = lowColor.mix(highColor, percent);
      break;
    }
  }
  return color ? color.toString() : "";
};

export const COLOR_BLACK = Color.rgb(0, 0, 0);
export const COLOR_BLUE = Color.rgb(71, 119, 247);
export const COLOR_BROWN = Color.rgb(71, 44, 13);
export const COLOR_GREEN = Color.rgb(71, 179, 89);
export const COLOR_ORANGE = Color.rgb(212, 101, 53);
export const COLOR_PINK = Color.rgb(190, 37, 182);
export const COLOR_RED = Color.rgb(202, 44, 42);
export const COLOR_WHITE = Color.rgb(255, 255, 255).alpha(0.25);
export const COLOR_YELLOW = Color.rgb(229, 178, 0);

export const getProperties = (
  t: TFunction,
  i18n: I18n,
  measurementSystem: MeasurementSystem,
  block: BlockResponse,
  metric: SpatialMetric,
  cycleSlots: Record<CycleSlot, CarbonUnit>
): Properties => {
  const properties: Properties = {};

  for (const popupMetric of metric.popup ?? []) {
    // carbon.actions.compareKeys.ignoreDynamic
    properties[t(`utils.metrics.spatial.metrics.${popupMetric.id}`)] =
      formatMetric(
        t,
        i18n,
        measurementSystem,
        popupMetric,
        popupMetric.getValue(block),
        {
          toUnits: loadSavedUnit(
            cycleSlots,
            popupMetric.cycleSlot,
            measurementSystem
          ),
          blankValue: BLANK_VALUE,
        }
      ).toString();
  }

  return properties;
};

export const getLength = (
  block: BlockResponse | undefined,
  units: Units
): number | undefined => {
  if (isUndefined(block)) {
    return;
  }
  const startY = block.block?.start?.longitude;
  const startX = block.block?.start?.latitude;
  const endY = block.block?.end?.longitude;
  const endX = block.block?.end?.latitude;
  if (
    isUndefined(startY) ||
    isUndefined(startX) ||
    isUndefined(endY) ||
    isUndefined(endX)
  ) {
    return;
  }
  return distance(point([startY, startX]), point([endY, endX]), {
    units,
  });
};

export const getBlockRange = (
  blocks: BlockResponse[] | undefined,
  metric: SpatialMetric
): BlockRange | undefined => {
  if (isUndefined(blocks)) {
    return;
  }
  const values = blocks
    .filter((block) => isCountableBlock(block))
    .map((block) => metric.getValue(block))
    .filter((value) => isValidNumber(value))
    .sort((a, b) => a - b);
  if (values.length === 0) {
    return;
  }
  const min = values[0];
  const low = percentile(values, 10);
  const medium = percentile(values, 50);
  const high = percentile(values, 90);
  const max = values.at(-1);
  if (
    isUndefined(min) ||
    isUndefined(low) ||
    isUndefined(medium) ||
    isUndefined(high) ||
    isUndefined(max)
  ) {
    return;
  }
  return {
    min,
    low,
    medium,
    high,
    max,
  };
};

export const aggregateConclusionCounts = (
  conclusionCounts: number[],
  conclusionTypes: ConclusionType[]
): number => {
  let total = 0;
  for (const conclusionType of conclusionTypes) {
    total += conclusionCounts[conclusionType] ?? 0;
  }
  return total;
};
