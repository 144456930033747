import { ALL_SIZES, type SIZE_INDEX } from "portal/utils/almanac";
import {
  CategoryClassification,
  categoryClassificationFromJSON,
  ModelinatorConfig,
  ModelinatorTypeCategory,
  ModelTrust,
  TypeCategory,
} from "protos/almanac/almanac";
import { isEqual } from "./objects";
import { isUndefined } from "./identity";

/**
 * A "structured modelinator" is a representation of a `ModelinatorConfig`'s
 * categories that reifies the notion of "synced/split" categories/sizes used
 * in the UI.
 */
export type StructuredModelinator = SyncedModelinator | SplitModelinator;
export interface SyncedModelinator {
  type: Uniformity.SYNCED;
  categoryTypes: Array<TypeCategory | undefined>;
  eachCategory: StructuredCategory;
}
export interface SplitModelinator {
  type: Uniformity.SPLIT;
  categories: Array<NamedCategory>;
}
interface NamedCategory {
  type: TypeCategory | undefined;
  category: StructuredCategory;
}

export type StructuredCategory = SyncedCategory | SplitCategory;
export interface SyncedCategory {
  type: Uniformity.SYNCED;
  eachSize: ModelTrust;
}
export interface SplitCategory {
  type: Uniformity.SPLIT;
  sizes: ModelTrust[];
}

export enum Uniformity {
  SYNCED = "synced",
  SPLIT = "split",
}

type UnstructuredModelinator = ModelinatorTypeCategory[];

const fromStructured = (
  modelinator: StructuredModelinator
): UnstructuredModelinator => {
  switch (modelinator.type) {
    case Uniformity.SYNCED: {
      const { categoryTypes, eachCategory } = modelinator;
      return categoryTypes.map((type) =>
        categoryFromStructured({ type, category: eachCategory })
      );
    }
    case Uniformity.SPLIT: {
      const { categories } = modelinator;
      return categories.map((c) => categoryFromStructured(c));
    }
  }
};

const categoryFromStructured = ({
  type,
  category,
}: NamedCategory): ModelinatorTypeCategory => {
  switch (category.type) {
    case Uniformity.SYNCED: {
      return { type, trusts: ALL_SIZES.map((_) => category.eachSize) };
    }
    case Uniformity.SPLIT: {
      return {
        type,
        trusts: ALL_SIZES.map((size) => category.sizes[size]).filter(
          (size) => !isUndefined(size)
        ),
      };
    }
  }
};

const fromUnstructured = (
  modelinator: UnstructuredModelinator
): StructuredModelinator => {
  const categories = modelinator.map((c) => categoryFromUnstructured(c));
  if (categories.length === 0) {
    return { type: Uniformity.SPLIT, categories: [] };
  }
  const [first, ...rest] = categories;
  if (!first) {
    // something wrong with state: categories are not synced.
    return { type: Uniformity.SPLIT, categories: [] };
  }
  for (const other of rest) {
    if (areStructuredCategoriesEqual(first.category, other.category)) {
      continue;
    }
    // Else, found a discrepancy: categories are not synced.
    return { type: Uniformity.SPLIT, categories };
  }
  // If we got here, all categories are the same.
  const types = categories.map(({ type }) => type);
  const { category: eachCategory } = first;
  return syncCategories(types, eachCategory);
};

const categoryFromUnstructured = (
  category: ModelinatorTypeCategory
): NamedCategory => {
  const { type, trusts } = category;
  const [eachSize] = trusts;
  return eachSize && trusts.length > 0 && areTrustsEqual(...trusts)
    ? { type, category: syncSizes(eachSize) }
    : { type, category: { type: Uniformity.SPLIT, sizes: trusts } };
};

export const areTrustsEqual = (
  ...trusts: Array<ModelTrust | undefined>
): boolean => {
  if (trusts.length <= 1) {
    return true;
  }
  const [first, ...rest] = trusts;
  return rest.every((other) => isEqual(first, other));
};

export const areStructuredCategoriesEqual = (
  c1: StructuredCategory,
  c2: StructuredCategory
): boolean => {
  if (c1.type === Uniformity.SYNCED && c2.type === Uniformity.SYNCED) {
    return areTrustsEqual(c1.eachSize, c2.eachSize);
  }
  if (c1.type === Uniformity.SPLIT && c2.type === Uniformity.SPLIT) {
    return ALL_SIZES.every((size) =>
      areTrustsEqual(c1.sizes[size], c2.sizes[size])
    );
  }
  return false;
};

export interface PartitionedModelinator {
  crops: StructuredModelinator;
  weeds: StructuredModelinator;
  metadata: Omit<ModelinatorConfig, "categories">;
}

export const partitionedFromConfig = (
  modelinator: ModelinatorConfig
): PartitionedModelinator => {
  const { categories, ...metadata } = modelinator;
  const cropCategories = [];
  const weedCategories = [];
  for (const category of categories) {
    const { type } = category;
    if (!type) {
      weedCategories.push(category);
      continue;
    }
    const { classification } = type;
    if (
      classification ===
      categoryClassificationFromJSON(CategoryClassification.CATEGORY_CROP)
    ) {
      cropCategories.push(category);
    } else {
      weedCategories.push(category);
    }
  }
  const crops = fromUnstructured(cropCategories);
  const weeds = fromUnstructured(weedCategories);
  return { crops, weeds, metadata };
};

export const configFromPartitioned = (
  modelinator: PartitionedModelinator
): ModelinatorConfig => {
  const { crops, weeds, metadata } = modelinator;
  const categories = [...fromStructured(crops), ...fromStructured(weeds)];
  return { ...metadata, categories };
};

////
// Utilities for converting between synced and split forms

export const splitCategories = (
  modelinator: SyncedModelinator
): SplitModelinator => {
  const { categoryTypes, eachCategory } = modelinator;
  return {
    type: Uniformity.SPLIT,
    categories: categoryTypes.map((type) => ({ type, category: eachCategory })),
  };
};

export const syncCategories = (
  categoryTypes: Array<TypeCategory | undefined>,
  eachCategory: StructuredCategory
): SyncedModelinator => {
  return { type: Uniformity.SYNCED, categoryTypes, eachCategory };
};

export const splitSizes = (category: SyncedCategory): SplitCategory => {
  const { eachSize } = category;
  return {
    type: Uniformity.SPLIT,
    sizes: ALL_SIZES.map((_) => eachSize),
  };
};

export const syncSizes = (eachSize: ModelTrust): SyncedCategory => {
  return {
    type: Uniformity.SYNCED,
    eachSize,
  };
};

////
// Utilities for updating single elements of a split structure

export const replaceCategory = (
  modelinator: SplitModelinator,
  newCategory: NamedCategory
): SplitModelinator => {
  return {
    ...modelinator,
    categories: modelinator.categories.map((oldCategory) =>
      isEqual(oldCategory.type, newCategory.type) ? newCategory : oldCategory
    ),
  };
};

export const replaceSize = (
  category: SplitCategory,
  newSize: { index: SIZE_INDEX; trusts: ModelTrust }
): SplitCategory => {
  return {
    ...category,
    sizes: category.sizes.map((oldTrusts, oldIndex) =>
      oldIndex === newSize.index ? newSize.trusts : oldTrusts
    ),
  };
};
