import {
  Alert,
  AlertTitle,
  InputAdornment,
  LinearProgress,
} from "@mui/material";
import { BLUE_LOADING_BUTTON, TEXT_FIELD_DARK } from "portal/utils/theme";
import { convert } from "portal/utils/units/units";
import { DateTime } from "luxon";
import { Field, Form, Formik } from "formik";
import { formatCompactDate, isSameDate } from "portal/utils/dates";
import { getCustomerSerial, isOffline } from "portal/utils/robots";
import { isInternal } from "portal/utils/auth";
import { isNil } from "portal/utils/identity";
import { LabelDivider } from "portal/components/LabelDivider";
import { LegacyChatMessage } from "portal/components/chat/LegacyChatMessage";
import { LoadingButton } from "@mui/lab";
import { object, string } from "yup";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { sleep } from "portal/utils/timing";
import { TextField } from "formik-mui";
import {
  useGetRobotQuery,
  useListMessagesQuery,
  useSendMessageMutation,
} from "portal/state/portalApi";
import {
  useMutationPopups,
  useQueryPopups,
} from "portal/utils/hooks/useApiPopups";
import { useParams } from "react-router-dom";
import { usePrevious } from "@uidotdev/usehooks";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import React, { FunctionComponent, ReactNode, useEffect, useRef } from "react";
import semver from "semver";
import SendIcon from "@mui/icons-material/SendOutlined";

const MINIMUM_SUPPORT_VERSION = ">=1.13";
export const LegacyChat: FunctionComponent = () => {
  const { t, i18n } = useTranslation();

  const { serial } = useParams();
  const { user, isInternal: isSelfInternal } = useSelf();

  const { data: summary } = useQueryPopups(
    useGetRobotQuery(serial ?? skipToken),
    {
      errorVariant: "warning",
    }
  );

  const bottomBookmark = useRef<HTMLDivElement | null>(null);
  const robotVersion = summary?.robot?.health?.softwareVersion ?? "";
  const isDevelopment = robotVersion === "DEVELOPMENT";
  const isSupported =
    isDevelopment ||
    semver.satisfies(
      semver.coerce(robotVersion) ?? new semver.SemVer("0.0.0"),
      MINIMUM_SUPPORT_VERSION
    );

  const { data: messages, isLoading } = useQueryPopups(
    useListMessagesQuery(serial ? [serial] : skipToken, {
      pollingInterval: convert(10).from("s").to("ms"),
    })
  );

  // automatically scroll to bottom for new messages
  const oldMessages = usePrevious<typeof messages>(messages);

  useEffect(() => {
    if (!messages) {
      return;
    }
    const shouldScroll = messages.length > (oldMessages?.length ?? 0);
    if (shouldScroll) {
      setTimeout(async () => {
        while (isNil(bottomBookmark.current)) {
          await sleep(convert(0.1).from("s").to("ms"));
        }
        bottomBookmark.current.scrollIntoView({ behavior: "smooth" });
      });
    }
  }, [messages, oldMessages]);

  const [sendMessage] = useMutationPopups(useSendMessageMutation());

  const timezone = summary?.robot?.health?.timezone;

  if (!summary?.robot?.health?.softwareVersion) {
    return;
  }

  if (!isSupported) {
    return (
      <Alert severity="error">
        <AlertTitle>
          {t("views.fleet.robots.support.errors.old.title")}
        </AlertTitle>
        {t("views.fleet.robots.support.errors.old.description", {
          serial,
          version: summary.robot.health.softwareVersion,
          target: MINIMUM_SUPPORT_VERSION,
        })}
      </Alert>
    );
  }

  return (
    <>
      <div className="flex flex-col justify-end min-h-full">
        {messages?.map((message, index) => {
          const result: ReactNode[] = [];

          const previousMessage = index === 0 ? undefined : messages[index - 1];
          const date = DateTime.fromMillis(message.db?.createdAt ?? Number.NaN);
          if (
            !isSameDate(
              date,
              DateTime.fromMillis(previousMessage?.db?.createdAt ?? Number.NaN)
            )
          ) {
            result.push(
              <LabelDivider
                colorClassName="bg-zinc-200 text-black"
                key={date.toUnixInteger()}
              >
                {formatCompactDate(t, i18n, date)}
              </LabelDivider>
            );
          }
          result.push(
            <LegacyChatMessage
              message={message}
              prevMessage={messages[index - 1]}
              nextMessage={messages[index + 1]}
              key={message.db?.id ?? index}
              user={user}
            />
          );
          return <>{result}</>;
        })}
        {isLoading && (
          <LinearProgress
            color="secondary"
            className="fixed bottom-0 left-0 right-0 "
          />
        )}
        <Formik
          enableReinitialize
          initialValues={{ message: "" }}
          validationSchema={object({
            message: string(),
          })}
          onSubmit={async (values, { resetForm }) => {
            if (!serial) {
              return;
            }
            await sendMessage([serial, values.message]);
            resetForm();
          }}
        >
          {({ submitForm, isSubmitting, values }) => (
            <Form className="flex flex-col items-stretch gap-4 w-full">
              <Field
                {...TEXT_FIELD_DARK}
                component={TextField}
                name="message"
                className="flex-grow"
                placeholder={
                  isInternal(user)
                    ? t("views.fleet.robots.support.toOperator")
                    : t("views.fleet.robots.support.toCarbon")
                }
                color="secondary"
                multiline
                onKeyDown={(event: KeyboardEvent) => {
                  // submit on enter
                  if (event.key === "Enter" && !event.shiftKey) {
                    submitForm();
                    event.preventDefault();
                  }
                }}
                InputProps={{
                  ...TEXT_FIELD_DARK.InputProps,
                  endAdornment:
                    values.message.length > 0 ? (
                      <InputAdornment position="end">
                        <LoadingButton
                          {...BLUE_LOADING_BUTTON}
                          loading={isSubmitting}
                          size="large"
                          variant="contained"
                          color="secondary"
                          onClick={submitForm}
                          startIcon={<SendIcon />}
                        >
                          {t("utils.actions.send")}
                        </LoadingButton>
                      </InputAdornment>
                    ) : (
                      <></>
                    ),
                }}
              />
              {timezone && (
                <span className="text-right w-full opacity-50 -mt-4">
                  {t("views.fleet.robots.support.localTime", {
                    time: DateTime.local()
                      .setZone(timezone)
                      .toLocaleString(DateTime.TIME_SIMPLE, {
                        locale: i18n.language,
                      }),
                  })}
                </span>
              )}
              {isOffline(summary.robot) && (
                <Alert severity="warning">
                  <AlertTitle>
                    {t("views.fleet.robots.support.warnings.offline.title")}
                  </AlertTitle>
                  {t(
                    "views.fleet.robots.support.warnings.offline.description",
                    {
                      serial: isSelfInternal
                        ? serial
                        : getCustomerSerial(t, serial),
                    }
                  )}
                </Alert>
              )}
            </Form>
          )}
        </Formik>
      </div>
      <div className="float-left clear-both" ref={bottomBookmark} />
    </>
  );
};
