import { convert } from "portal/utils/units/units";
import { DEFAULT_IMPLEMENT_WIDTH_FT } from "portal/utils/robots";
import { Feature } from "geojson";
import { isUndefined } from "portal/utils/identity";
import {
  lineString,
  point,
  Polygon,
  polygon,
  Properties,
  Units,
} from "@turf/helpers";
import { Point } from "portal/utils/geo";
import { SpatialMetricBlock, SpatialPosition } from "protos/metrics/metrics";
import buffer from "@turf/buffer";
import distance from "@turf/distance";
import getBearing from "@turf/bearing";
import transformTranslate from "@turf/transform-translate";

export const TYPE_BUFFER = "buffer";

export const isSpatialPositionProbablyWrong = ({
  latitude,
  longitude,
}: SpatialPosition): boolean =>
  isLatOrLngProbablyWrong(latitude) || isLatOrLngProbablyWrong(longitude);

export const isPointProbablyWrong = ([latitude, longitude]: Point): boolean =>
  isLatOrLngProbablyWrong(latitude) || isLatOrLngProbablyWrong(longitude);

export const isLatOrLngProbablyWrong = (value: number): boolean =>
  Math.abs(value) < 0.1;

export interface BufferRequest {
  features: Feature[];
  index: number;
  properties?: Properties;
  radius: number;
  type: string;
  units: Units;
}

export interface BufferResponse {
  index: number;
  lines: Feature[];
  type: string;
}

export const TYPE_BLOCKIFY = "blockify";
export interface BlockifyRequest {
  blocks: SpatialMetricBlock[];
  index: number;
  properties?: Properties;
  type: string;
  units: Units;
}

export interface BlockifyResponse {
  blocks: Feature<Polygon>[];
  index: number;
  type: string;
}

export const TYPE_LINEIFY = "lineify";
export interface LineifyRequest {
  blocks: SpatialMetricBlock[];
  index: number;
  properties?: Properties;
  type: string;
}

export interface LineifyResponse {
  lines: Feature[];
  index: number;
  type: string;
}

export const filteredListener =
  (callback: (type: string, data: any) => void) =>
  ({ data }: MessageEvent<any>) => {
    if (!data.type) {
      return;
    }
    callback(data.type, data);
  };

const handleBuffer = ({
  features,
  index,
  properties = {},
  radius,
  units,
}: BufferRequest): void => {
  const response: BufferResponse = {
    type: TYPE_BUFFER,
    index,
    lines: features.map((feature) => {
      const lines = buffer(feature, radius, { units });
      lines.properties = { ...feature.properties, ...properties };
      return lines;
    }),
  };
  self.postMessage(response);
};

const handleBlockify = (request: BlockifyRequest): void => {
  const { blocks, index } = request;
  const response: BlockifyResponse = {
    type: TYPE_BLOCKIFY,
    index,
    blocks: blocks
      .map((block) => blockToPolygon(block, request))
      .filter((feature) => !isUndefined(feature)),
  };
  self.postMessage(response);
};

const blockToPolygon = (
  block: SpatialMetricBlock,
  { properties, units }: BlockifyRequest
): Feature<Polygon> | undefined => {
  if (block.suspicious) {
    console.warn("Skipping suspicious block");
    return;
  }

  const _width = block.implementWidthData?.widthMm
    ? convert(block.implementWidthData.widthMm).from("mm").to("ft")
    : DEFAULT_IMPLEMENT_WIDTH_FT;
  properties = { ...properties, _width };

  if (
    block.startLeft &&
    block.startRight &&
    block.endLeft &&
    block.endRight &&
    block.implementWidthData?.widthMm
  ) {
    // we have all for corners, just use those
    if (
      isSpatialPositionProbablyWrong(block.startLeft) ||
      isSpatialPositionProbablyWrong(block.startRight) ||
      isSpatialPositionProbablyWrong(block.endLeft) ||
      isSpatialPositionProbablyWrong(block.endRight)
    ) {
      console.warn("Block parameters are probably wrong");
      return;
    }

    return polygon(
      [
        [
          [block.startLeft.longitude, block.startLeft.latitude],
          [block.startRight.longitude, block.startRight.latitude],
          [block.endRight.longitude, block.endRight.latitude],
          [block.endLeft.longitude, block.endLeft.latitude],
          [block.startLeft.longitude, block.startLeft.latitude],
        ],
      ],
      {
        ...properties,
        _length: distance(
          [block.startLeft.longitude, block.startLeft.latitude],
          [block.endLeft.longitude, block.endLeft.latitude],
          { units: "feet" }
        ),
      }
    );
  } else if (block.start && block.end) {
    // we get the center line, use the width to calculate the rest
    const startPoint: Point = [block.start.longitude, block.start.latitude];
    const endPoint: Point = [block.end.longitude, block.end.latitude];

    if (isPointProbablyWrong(startPoint) || isPointProbablyWrong(endPoint)) {
      console.warn("Block parameters are probably wrong");
      return;
    }

    const width =
      block.implementWidthData?.widthMm ??
      convert(DEFAULT_IMPLEMENT_WIDTH_FT).from("ft").to("mm");
    const bearing = getBearing(startPoint, endPoint);
    const startLeft = transformTranslate(
      point(endPoint),
      width / 2,
      bearing - 90,
      { units }
    );
    const startRight = transformTranslate(
      point(endPoint),
      width / 2,
      bearing + 90,
      { units }
    );
    const endLeft = transformTranslate(
      point(startPoint),
      width / 2,
      bearing - 90,
      { units }
    );
    const endRight = transformTranslate(
      point(startPoint),
      width / 2,
      bearing + 90,
      { units }
    );

    return polygon(
      [
        [
          startLeft.geometry.coordinates,
          startRight.geometry.coordinates,
          endRight.geometry.coordinates,
          endLeft.geometry.coordinates,
          startLeft.geometry.coordinates,
        ],
      ],
      properties
    );
  } else {
    console.warn("Block has no parameters");
  }
};

const handleLineify = (request: LineifyRequest): void => {
  const { blocks, index } = request;
  const response: LineifyResponse = {
    type: TYPE_LINEIFY,
    index,
    lines: blocks.map((block) => blockToLine(block, request)),
  };
  self.postMessage(response);
};

const blockToLine = (
  block: SpatialMetricBlock,
  { properties = {} }: LineifyRequest
): Feature => {
  if (!block.start || !block.end) {
    console.warn("Block has not parameters");
    return lineString([[]]);
  }

  const startPoint = [block.start.longitude, block.start.latitude];
  const endPoint = [block.end.longitude, block.end.latitude];
  return lineString([startPoint, endPoint], properties);
};

self.addEventListener(
  "message",
  filteredListener((type, data) => {
    switch (type) {
      case TYPE_BUFFER: {
        handleBuffer(data as BufferRequest);
        break;
      }
      case TYPE_BLOCKIFY: {
        handleBlockify(data as BlockifyRequest);
        break;
      }
      case TYPE_LINEIFY: {
        handleLineify(data as LineifyRequest);
        break;
      }
    }
  })
);
