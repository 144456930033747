// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.19.4
// source: almanac/almanac.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";

export const protobufPackage = "carbon.aimbot.almanac";

export enum CategoryClassification {
  CATEGORY_WEED = 0,
  CATEGORY_CROP = 1,
  UNRECOGNIZED = -1,
}

export function categoryClassificationFromJSON(object: any): CategoryClassification {
  switch (object) {
    case 0:
    case "CATEGORY_WEED":
      return CategoryClassification.CATEGORY_WEED;
    case 1:
    case "CATEGORY_CROP":
      return CategoryClassification.CATEGORY_CROP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CategoryClassification.UNRECOGNIZED;
  }
}

export function categoryClassificationToJSON(object: CategoryClassification): string {
  switch (object) {
    case CategoryClassification.CATEGORY_WEED:
      return "CATEGORY_WEED";
    case CategoryClassification.CATEGORY_CROP:
      return "CATEGORY_CROP";
    case CategoryClassification.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Formula {
  /** A */
  multiplier: number;
  /** b */
  offset: number;
  /** e */
  exponent: number;
  /** discrete val 1-10 */
  fineTuneMultiplier: number;
  /** Time to cap all shooting at */
  maxTime: number;
  /** how big each step is for moving ftm: F= 1.0 + (5-ftm)*ftm_val */
  fineTuneMultiplierVal: number;
}

export interface Trust {
  /** In weeding Can be skipped if not enough time (will not */
  ignorable: boolean;
  /** be used in VE, but can be targeted still) */
  avoid: boolean;
}

export interface ModelTrust {
  /** Minimum detections over opportuninty */
  minDoo: number;
  /** for weed act as WPT for crop this is crop protect */
  weedingThreshold: number;
  /** only used for crop to decide if should be */
  thinningThreshold: number;
  /** looked at or ignored in thinning */
  bandingThreshold: number;
}

export interface TypeCategory {
  /** This is a uuid which maps to a crop id or weed id */
  category: string;
  classification: CategoryClassification;
}

export interface AlmanacTypeCategory {
  type:
    | TypeCategory
    | undefined;
  /** Each size goes from [sizes[i-1], sizes[i]) for i=0 we use [0, */
  sizes: number[];
  /**
   * sizes[i]) additionally we add [sizes[N], inf) to the end so for 3
   * categories only need 2 values
   */
  formulas: Formula[];
}

export interface DiscriminatorTypeCategory {
  type: TypeCategory | undefined;
  trusts: Trust[];
}

export interface ModelinatorTypeCategory {
  type: TypeCategory | undefined;
  trusts: ModelTrust[];
}

export interface AlmanacConfig {
  /** auto generated uuid */
  id: string;
  name: string;
  protected: boolean;
  updatedTs: number;
  /** Must contain an instance with category="DEFAULT" */
  categories: AlmanacTypeCategory[];
}

export interface DiscriminatorConfig {
  /** auto generated uuid */
  id: string;
  name: string;
  protected: boolean;
  updatedTs: number;
  categories: DiscriminatorTypeCategory[];
}

export interface ModelinatorConfig {
  /** must match an existing model id */
  modelId: string;
  /** must match an existing crop id */
  cropId: string;
  /** Set if has been modifed by user from model suggestion */
  modified: boolean;
  categories: ModelinatorTypeCategory[];
}

function createBaseFormula(): Formula {
  return { multiplier: 0, offset: 0, exponent: 0, fineTuneMultiplier: 0, maxTime: 0, fineTuneMultiplierVal: 0 };
}

export const Formula: MessageFns<Formula> = {
  encode(message: Formula, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.multiplier !== 0) {
      writer.uint32(13).float(message.multiplier);
    }
    if (message.offset !== 0) {
      writer.uint32(21).float(message.offset);
    }
    if (message.exponent !== 0) {
      writer.uint32(29).float(message.exponent);
    }
    if (message.fineTuneMultiplier !== 0) {
      writer.uint32(32).uint32(message.fineTuneMultiplier);
    }
    if (message.maxTime !== 0) {
      writer.uint32(40).uint32(message.maxTime);
    }
    if (message.fineTuneMultiplierVal !== 0) {
      writer.uint32(53).float(message.fineTuneMultiplierVal);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Formula {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFormula();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.multiplier = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.offset = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.exponent = reader.float();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.fineTuneMultiplier = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.maxTime = reader.uint32();
          continue;
        case 6:
          if (tag !== 53) {
            break;
          }

          message.fineTuneMultiplierVal = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Formula {
    return {
      multiplier: isSet(object.multiplier) ? globalThis.Number(object.multiplier) : 0,
      offset: isSet(object.offset) ? globalThis.Number(object.offset) : 0,
      exponent: isSet(object.exponent) ? globalThis.Number(object.exponent) : 0,
      fineTuneMultiplier: isSet(object.fineTuneMultiplier) ? globalThis.Number(object.fineTuneMultiplier) : 0,
      maxTime: isSet(object.maxTime) ? globalThis.Number(object.maxTime) : 0,
      fineTuneMultiplierVal: isSet(object.fineTuneMultiplierVal) ? globalThis.Number(object.fineTuneMultiplierVal) : 0,
    };
  },

  toJSON(message: Formula): unknown {
    const obj: any = {};
    if (message.multiplier !== 0) {
      obj.multiplier = message.multiplier;
    }
    if (message.offset !== 0) {
      obj.offset = message.offset;
    }
    if (message.exponent !== 0) {
      obj.exponent = message.exponent;
    }
    if (message.fineTuneMultiplier !== 0) {
      obj.fineTuneMultiplier = Math.round(message.fineTuneMultiplier);
    }
    if (message.maxTime !== 0) {
      obj.maxTime = Math.round(message.maxTime);
    }
    if (message.fineTuneMultiplierVal !== 0) {
      obj.fineTuneMultiplierVal = message.fineTuneMultiplierVal;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Formula>, I>>(base?: I): Formula {
    return Formula.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Formula>, I>>(object: I): Formula {
    const message = createBaseFormula();
    message.multiplier = object.multiplier ?? 0;
    message.offset = object.offset ?? 0;
    message.exponent = object.exponent ?? 0;
    message.fineTuneMultiplier = object.fineTuneMultiplier ?? 0;
    message.maxTime = object.maxTime ?? 0;
    message.fineTuneMultiplierVal = object.fineTuneMultiplierVal ?? 0;
    return message;
  },
};

function createBaseTrust(): Trust {
  return { ignorable: false, avoid: false };
}

export const Trust: MessageFns<Trust> = {
  encode(message: Trust, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.ignorable !== false) {
      writer.uint32(8).bool(message.ignorable);
    }
    if (message.avoid !== false) {
      writer.uint32(16).bool(message.avoid);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Trust {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrust();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.ignorable = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.avoid = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Trust {
    return {
      ignorable: isSet(object.ignorable) ? globalThis.Boolean(object.ignorable) : false,
      avoid: isSet(object.avoid) ? globalThis.Boolean(object.avoid) : false,
    };
  },

  toJSON(message: Trust): unknown {
    const obj: any = {};
    if (message.ignorable !== false) {
      obj.ignorable = message.ignorable;
    }
    if (message.avoid !== false) {
      obj.avoid = message.avoid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Trust>, I>>(base?: I): Trust {
    return Trust.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Trust>, I>>(object: I): Trust {
    const message = createBaseTrust();
    message.ignorable = object.ignorable ?? false;
    message.avoid = object.avoid ?? false;
    return message;
  },
};

function createBaseModelTrust(): ModelTrust {
  return { minDoo: 0, weedingThreshold: 0, thinningThreshold: 0, bandingThreshold: 0 };
}

export const ModelTrust: MessageFns<ModelTrust> = {
  encode(message: ModelTrust, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.minDoo !== 0) {
      writer.uint32(13).float(message.minDoo);
    }
    if (message.weedingThreshold !== 0) {
      writer.uint32(21).float(message.weedingThreshold);
    }
    if (message.thinningThreshold !== 0) {
      writer.uint32(29).float(message.thinningThreshold);
    }
    if (message.bandingThreshold !== 0) {
      writer.uint32(37).float(message.bandingThreshold);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ModelTrust {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModelTrust();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.minDoo = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.weedingThreshold = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.thinningThreshold = reader.float();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.bandingThreshold = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ModelTrust {
    return {
      minDoo: isSet(object.minDoo) ? globalThis.Number(object.minDoo) : 0,
      weedingThreshold: isSet(object.weedingThreshold) ? globalThis.Number(object.weedingThreshold) : 0,
      thinningThreshold: isSet(object.thinningThreshold) ? globalThis.Number(object.thinningThreshold) : 0,
      bandingThreshold: isSet(object.bandingThreshold) ? globalThis.Number(object.bandingThreshold) : 0,
    };
  },

  toJSON(message: ModelTrust): unknown {
    const obj: any = {};
    if (message.minDoo !== 0) {
      obj.minDoo = message.minDoo;
    }
    if (message.weedingThreshold !== 0) {
      obj.weedingThreshold = message.weedingThreshold;
    }
    if (message.thinningThreshold !== 0) {
      obj.thinningThreshold = message.thinningThreshold;
    }
    if (message.bandingThreshold !== 0) {
      obj.bandingThreshold = message.bandingThreshold;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ModelTrust>, I>>(base?: I): ModelTrust {
    return ModelTrust.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ModelTrust>, I>>(object: I): ModelTrust {
    const message = createBaseModelTrust();
    message.minDoo = object.minDoo ?? 0;
    message.weedingThreshold = object.weedingThreshold ?? 0;
    message.thinningThreshold = object.thinningThreshold ?? 0;
    message.bandingThreshold = object.bandingThreshold ?? 0;
    return message;
  },
};

function createBaseTypeCategory(): TypeCategory {
  return { category: "", classification: 0 };
}

export const TypeCategory: MessageFns<TypeCategory> = {
  encode(message: TypeCategory, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.category !== "") {
      writer.uint32(10).string(message.category);
    }
    if (message.classification !== 0) {
      writer.uint32(16).int32(message.classification);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TypeCategory {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTypeCategory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.category = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.classification = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TypeCategory {
    return {
      category: isSet(object.category) ? globalThis.String(object.category) : "",
      classification: isSet(object.classification) ? categoryClassificationFromJSON(object.classification) : 0,
    };
  },

  toJSON(message: TypeCategory): unknown {
    const obj: any = {};
    if (message.category !== "") {
      obj.category = message.category;
    }
    if (message.classification !== 0) {
      obj.classification = categoryClassificationToJSON(message.classification);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TypeCategory>, I>>(base?: I): TypeCategory {
    return TypeCategory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TypeCategory>, I>>(object: I): TypeCategory {
    const message = createBaseTypeCategory();
    message.category = object.category ?? "";
    message.classification = object.classification ?? 0;
    return message;
  },
};

function createBaseAlmanacTypeCategory(): AlmanacTypeCategory {
  return { type: undefined, sizes: [], formulas: [] };
}

export const AlmanacTypeCategory: MessageFns<AlmanacTypeCategory> = {
  encode(message: AlmanacTypeCategory, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.type !== undefined) {
      TypeCategory.encode(message.type, writer.uint32(10).fork()).join();
    }
    writer.uint32(18).fork();
    for (const v of message.sizes) {
      writer.float(v);
    }
    writer.join();
    for (const v of message.formulas) {
      Formula.encode(v!, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AlmanacTypeCategory {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAlmanacTypeCategory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.type = TypeCategory.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag === 21) {
            message.sizes.push(reader.float());

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.sizes.push(reader.float());
            }

            continue;
          }

          break;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.formulas.push(Formula.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AlmanacTypeCategory {
    return {
      type: isSet(object.type) ? TypeCategory.fromJSON(object.type) : undefined,
      sizes: globalThis.Array.isArray(object?.sizes) ? object.sizes.map((e: any) => globalThis.Number(e)) : [],
      formulas: globalThis.Array.isArray(object?.formulas) ? object.formulas.map((e: any) => Formula.fromJSON(e)) : [],
    };
  },

  toJSON(message: AlmanacTypeCategory): unknown {
    const obj: any = {};
    if (message.type !== undefined) {
      obj.type = TypeCategory.toJSON(message.type);
    }
    if (message.sizes?.length) {
      obj.sizes = message.sizes;
    }
    if (message.formulas?.length) {
      obj.formulas = message.formulas.map((e) => Formula.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AlmanacTypeCategory>, I>>(base?: I): AlmanacTypeCategory {
    return AlmanacTypeCategory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AlmanacTypeCategory>, I>>(object: I): AlmanacTypeCategory {
    const message = createBaseAlmanacTypeCategory();
    message.type = (object.type !== undefined && object.type !== null)
      ? TypeCategory.fromPartial(object.type)
      : undefined;
    message.sizes = object.sizes?.map((e) => e) || [];
    message.formulas = object.formulas?.map((e) => Formula.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDiscriminatorTypeCategory(): DiscriminatorTypeCategory {
  return { type: undefined, trusts: [] };
}

export const DiscriminatorTypeCategory: MessageFns<DiscriminatorTypeCategory> = {
  encode(message: DiscriminatorTypeCategory, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.type !== undefined) {
      TypeCategory.encode(message.type, writer.uint32(10).fork()).join();
    }
    for (const v of message.trusts) {
      Trust.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DiscriminatorTypeCategory {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDiscriminatorTypeCategory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.type = TypeCategory.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.trusts.push(Trust.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DiscriminatorTypeCategory {
    return {
      type: isSet(object.type) ? TypeCategory.fromJSON(object.type) : undefined,
      trusts: globalThis.Array.isArray(object?.trusts) ? object.trusts.map((e: any) => Trust.fromJSON(e)) : [],
    };
  },

  toJSON(message: DiscriminatorTypeCategory): unknown {
    const obj: any = {};
    if (message.type !== undefined) {
      obj.type = TypeCategory.toJSON(message.type);
    }
    if (message.trusts?.length) {
      obj.trusts = message.trusts.map((e) => Trust.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DiscriminatorTypeCategory>, I>>(base?: I): DiscriminatorTypeCategory {
    return DiscriminatorTypeCategory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DiscriminatorTypeCategory>, I>>(object: I): DiscriminatorTypeCategory {
    const message = createBaseDiscriminatorTypeCategory();
    message.type = (object.type !== undefined && object.type !== null)
      ? TypeCategory.fromPartial(object.type)
      : undefined;
    message.trusts = object.trusts?.map((e) => Trust.fromPartial(e)) || [];
    return message;
  },
};

function createBaseModelinatorTypeCategory(): ModelinatorTypeCategory {
  return { type: undefined, trusts: [] };
}

export const ModelinatorTypeCategory: MessageFns<ModelinatorTypeCategory> = {
  encode(message: ModelinatorTypeCategory, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.type !== undefined) {
      TypeCategory.encode(message.type, writer.uint32(10).fork()).join();
    }
    for (const v of message.trusts) {
      ModelTrust.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ModelinatorTypeCategory {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModelinatorTypeCategory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.type = TypeCategory.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.trusts.push(ModelTrust.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ModelinatorTypeCategory {
    return {
      type: isSet(object.type) ? TypeCategory.fromJSON(object.type) : undefined,
      trusts: globalThis.Array.isArray(object?.trusts) ? object.trusts.map((e: any) => ModelTrust.fromJSON(e)) : [],
    };
  },

  toJSON(message: ModelinatorTypeCategory): unknown {
    const obj: any = {};
    if (message.type !== undefined) {
      obj.type = TypeCategory.toJSON(message.type);
    }
    if (message.trusts?.length) {
      obj.trusts = message.trusts.map((e) => ModelTrust.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ModelinatorTypeCategory>, I>>(base?: I): ModelinatorTypeCategory {
    return ModelinatorTypeCategory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ModelinatorTypeCategory>, I>>(object: I): ModelinatorTypeCategory {
    const message = createBaseModelinatorTypeCategory();
    message.type = (object.type !== undefined && object.type !== null)
      ? TypeCategory.fromPartial(object.type)
      : undefined;
    message.trusts = object.trusts?.map((e) => ModelTrust.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAlmanacConfig(): AlmanacConfig {
  return { id: "", name: "", protected: false, updatedTs: 0, categories: [] };
}

export const AlmanacConfig: MessageFns<AlmanacConfig> = {
  encode(message: AlmanacConfig, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.protected !== false) {
      writer.uint32(24).bool(message.protected);
    }
    if (message.updatedTs !== 0) {
      writer.uint32(32).uint64(message.updatedTs);
    }
    for (const v of message.categories) {
      AlmanacTypeCategory.encode(v!, writer.uint32(42).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AlmanacConfig {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAlmanacConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.protected = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.updatedTs = longToNumber(reader.uint64());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.categories.push(AlmanacTypeCategory.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AlmanacConfig {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      protected: isSet(object.protected) ? globalThis.Boolean(object.protected) : false,
      updatedTs: isSet(object.updatedTs) ? globalThis.Number(object.updatedTs) : 0,
      categories: globalThis.Array.isArray(object?.categories)
        ? object.categories.map((e: any) => AlmanacTypeCategory.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AlmanacConfig): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.protected !== false) {
      obj.protected = message.protected;
    }
    if (message.updatedTs !== 0) {
      obj.updatedTs = Math.round(message.updatedTs);
    }
    if (message.categories?.length) {
      obj.categories = message.categories.map((e) => AlmanacTypeCategory.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AlmanacConfig>, I>>(base?: I): AlmanacConfig {
    return AlmanacConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AlmanacConfig>, I>>(object: I): AlmanacConfig {
    const message = createBaseAlmanacConfig();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.protected = object.protected ?? false;
    message.updatedTs = object.updatedTs ?? 0;
    message.categories = object.categories?.map((e) => AlmanacTypeCategory.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDiscriminatorConfig(): DiscriminatorConfig {
  return { id: "", name: "", protected: false, updatedTs: 0, categories: [] };
}

export const DiscriminatorConfig: MessageFns<DiscriminatorConfig> = {
  encode(message: DiscriminatorConfig, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.protected !== false) {
      writer.uint32(24).bool(message.protected);
    }
    if (message.updatedTs !== 0) {
      writer.uint32(32).uint64(message.updatedTs);
    }
    for (const v of message.categories) {
      DiscriminatorTypeCategory.encode(v!, writer.uint32(42).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DiscriminatorConfig {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDiscriminatorConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.protected = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.updatedTs = longToNumber(reader.uint64());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.categories.push(DiscriminatorTypeCategory.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DiscriminatorConfig {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      protected: isSet(object.protected) ? globalThis.Boolean(object.protected) : false,
      updatedTs: isSet(object.updatedTs) ? globalThis.Number(object.updatedTs) : 0,
      categories: globalThis.Array.isArray(object?.categories)
        ? object.categories.map((e: any) => DiscriminatorTypeCategory.fromJSON(e))
        : [],
    };
  },

  toJSON(message: DiscriminatorConfig): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.protected !== false) {
      obj.protected = message.protected;
    }
    if (message.updatedTs !== 0) {
      obj.updatedTs = Math.round(message.updatedTs);
    }
    if (message.categories?.length) {
      obj.categories = message.categories.map((e) => DiscriminatorTypeCategory.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DiscriminatorConfig>, I>>(base?: I): DiscriminatorConfig {
    return DiscriminatorConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DiscriminatorConfig>, I>>(object: I): DiscriminatorConfig {
    const message = createBaseDiscriminatorConfig();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.protected = object.protected ?? false;
    message.updatedTs = object.updatedTs ?? 0;
    message.categories = object.categories?.map((e) => DiscriminatorTypeCategory.fromPartial(e)) || [];
    return message;
  },
};

function createBaseModelinatorConfig(): ModelinatorConfig {
  return { modelId: "", cropId: "", modified: false, categories: [] };
}

export const ModelinatorConfig: MessageFns<ModelinatorConfig> = {
  encode(message: ModelinatorConfig, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.modelId !== "") {
      writer.uint32(10).string(message.modelId);
    }
    if (message.cropId !== "") {
      writer.uint32(18).string(message.cropId);
    }
    if (message.modified !== false) {
      writer.uint32(24).bool(message.modified);
    }
    for (const v of message.categories) {
      ModelinatorTypeCategory.encode(v!, writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ModelinatorConfig {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModelinatorConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modelId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.cropId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.modified = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.categories.push(ModelinatorTypeCategory.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ModelinatorConfig {
    return {
      modelId: isSet(object.modelId) ? globalThis.String(object.modelId) : "",
      cropId: isSet(object.cropId) ? globalThis.String(object.cropId) : "",
      modified: isSet(object.modified) ? globalThis.Boolean(object.modified) : false,
      categories: globalThis.Array.isArray(object?.categories)
        ? object.categories.map((e: any) => ModelinatorTypeCategory.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ModelinatorConfig): unknown {
    const obj: any = {};
    if (message.modelId !== "") {
      obj.modelId = message.modelId;
    }
    if (message.cropId !== "") {
      obj.cropId = message.cropId;
    }
    if (message.modified !== false) {
      obj.modified = message.modified;
    }
    if (message.categories?.length) {
      obj.categories = message.categories.map((e) => ModelinatorTypeCategory.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ModelinatorConfig>, I>>(base?: I): ModelinatorConfig {
    return ModelinatorConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ModelinatorConfig>, I>>(object: I): ModelinatorConfig {
    const message = createBaseModelinatorConfig();
    message.modelId = object.modelId ?? "";
    message.cropId = object.cropId ?? "";
    message.modified = object.modified ?? false;
    message.categories = object.categories?.map((e) => ModelinatorTypeCategory.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
