// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.19.4
// source: portal/users.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { CustomerResponse } from "./customers";
import { DB } from "./db";

export const protobufPackage = "carbon.portal.users";

export enum ViewMode {
  VIEW_MODE_UNSPECIFIED = 0,
  VIEW_MODE_CARDS = 1,
  VIEW_MODE_TABLE = 2,
  UNRECOGNIZED = -1,
}

export function viewModeFromJSON(object: any): ViewMode {
  switch (object) {
    case 0:
    case "VIEW_MODE_UNSPECIFIED":
      return ViewMode.VIEW_MODE_UNSPECIFIED;
    case 1:
    case "VIEW_MODE_CARDS":
      return ViewMode.VIEW_MODE_CARDS;
    case 2:
    case "VIEW_MODE_TABLE":
      return ViewMode.VIEW_MODE_TABLE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ViewMode.UNRECOGNIZED;
  }
}

export function viewModeToJSON(object: ViewMode): string {
  switch (object) {
    case ViewMode.VIEW_MODE_UNSPECIFIED:
      return "VIEW_MODE_UNSPECIFIED";
    case ViewMode.VIEW_MODE_CARDS:
      return "VIEW_MODE_CARDS";
    case ViewMode.VIEW_MODE_TABLE:
      return "VIEW_MODE_TABLE";
    case ViewMode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface AppMetadata {
  customerId?: number | undefined;
  isActivated?: boolean | undefined;
  isInvited?: boolean | undefined;
  isRobot?: boolean | undefined;
  permissions: string[];
  sfdcAccountId?: number | undefined;
}

export interface UserMetadata {
  consentGiven?: boolean | undefined;
  consentTimestamp?: number | undefined;
  experimental?: boolean | undefined;
  language?: string | undefined;
  showMascot?: boolean | undefined;
  unit?: string | undefined;
  defaultFleetViewId?: number | undefined;
}

export interface Auth0Profile {
  email: string;
  emailVerified: boolean;
  name: string;
  username: string;
  givenName: string;
  phoneNumber: string;
  phoneVerified: boolean;
  familyName: string;
}

export interface Auth0Identity {
  connection: string;
  userId: string;
  provider: string;
  isSocial: boolean;
  accessToken: string;
  accessTokenSecret: string;
  refreshToken: string;
  profileData: Auth0Profile | undefined;
}

export interface Auth0User {
  userId: string;
  email: string;
  emailVerified: boolean;
  username: string;
  phoneNumber: string;
  phoneVerified: boolean;
  createdAt: string;
  updatedAt: string;
  identities: Auth0Identity[];
  appMetadata: AppMetadata | undefined;
  userMetadata: UserMetadata | undefined;
  picture: string;
  name: string;
  nickname: string;
  multifactor: string[];
  lastIp: string;
  lastLogin: string;
  loginsCount: number;
  blocked: boolean;
  givenName: string;
  familyName: string;
}

export interface FleetView {
  db: DB | undefined;
  userAuth0Id: string;
  name: string;
  columns: string[];
  search: string;
  showInternal: boolean;
  showMap: boolean;
  showOffline: boolean;
  statuses: string[];
  pinnedRobotIds: number[];
  /** @deprecated */
  customerId: number;
  viewMode: ViewMode;
  customerIds: number[];
}

export interface UserResponse {
  /** auth0 user object */
  user: Auth0User | undefined;
  customer: CustomerResponse | undefined;
  fleetViews: FleetView[];
}

function createBaseAppMetadata(): AppMetadata {
  return {
    customerId: undefined,
    isActivated: undefined,
    isInvited: undefined,
    isRobot: undefined,
    permissions: [],
    sfdcAccountId: undefined,
  };
}

export const AppMetadata: MessageFns<AppMetadata> = {
  encode(message: AppMetadata, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.customerId !== undefined) {
      writer.uint32(8).int64(message.customerId);
    }
    if (message.isActivated !== undefined) {
      writer.uint32(16).bool(message.isActivated);
    }
    if (message.isInvited !== undefined) {
      writer.uint32(24).bool(message.isInvited);
    }
    if (message.isRobot !== undefined) {
      writer.uint32(32).bool(message.isRobot);
    }
    for (const v of message.permissions) {
      writer.uint32(42).string(v!);
    }
    if (message.sfdcAccountId !== undefined) {
      writer.uint32(48).int64(message.sfdcAccountId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AppMetadata {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAppMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.customerId = longToNumber(reader.int64());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isActivated = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isInvited = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.isRobot = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.permissions.push(reader.string());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.sfdcAccountId = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AppMetadata {
    return {
      customerId: isSet(object.customerId) ? globalThis.Number(object.customerId) : undefined,
      isActivated: isSet(object.isActivated) ? globalThis.Boolean(object.isActivated) : undefined,
      isInvited: isSet(object.isInvited) ? globalThis.Boolean(object.isInvited) : undefined,
      isRobot: isSet(object.isRobot) ? globalThis.Boolean(object.isRobot) : undefined,
      permissions: globalThis.Array.isArray(object?.permissions)
        ? object.permissions.map((e: any) => globalThis.String(e))
        : [],
      sfdcAccountId: isSet(object.sfdcAccountId) ? globalThis.Number(object.sfdcAccountId) : undefined,
    };
  },

  toJSON(message: AppMetadata): unknown {
    const obj: any = {};
    if (message.customerId !== undefined) {
      obj.customerId = Math.round(message.customerId);
    }
    if (message.isActivated !== undefined) {
      obj.isActivated = message.isActivated;
    }
    if (message.isInvited !== undefined) {
      obj.isInvited = message.isInvited;
    }
    if (message.isRobot !== undefined) {
      obj.isRobot = message.isRobot;
    }
    if (message.permissions?.length) {
      obj.permissions = message.permissions;
    }
    if (message.sfdcAccountId !== undefined) {
      obj.sfdcAccountId = Math.round(message.sfdcAccountId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AppMetadata>, I>>(base?: I): AppMetadata {
    return AppMetadata.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AppMetadata>, I>>(object: I): AppMetadata {
    const message = createBaseAppMetadata();
    message.customerId = object.customerId ?? undefined;
    message.isActivated = object.isActivated ?? undefined;
    message.isInvited = object.isInvited ?? undefined;
    message.isRobot = object.isRobot ?? undefined;
    message.permissions = object.permissions?.map((e) => e) || [];
    message.sfdcAccountId = object.sfdcAccountId ?? undefined;
    return message;
  },
};

function createBaseUserMetadata(): UserMetadata {
  return {
    consentGiven: undefined,
    consentTimestamp: undefined,
    experimental: undefined,
    language: undefined,
    showMascot: undefined,
    unit: undefined,
    defaultFleetViewId: undefined,
  };
}

export const UserMetadata: MessageFns<UserMetadata> = {
  encode(message: UserMetadata, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.consentGiven !== undefined) {
      writer.uint32(8).bool(message.consentGiven);
    }
    if (message.consentTimestamp !== undefined) {
      writer.uint32(16).int64(message.consentTimestamp);
    }
    if (message.experimental !== undefined) {
      writer.uint32(24).bool(message.experimental);
    }
    if (message.language !== undefined) {
      writer.uint32(34).string(message.language);
    }
    if (message.showMascot !== undefined) {
      writer.uint32(40).bool(message.showMascot);
    }
    if (message.unit !== undefined) {
      writer.uint32(50).string(message.unit);
    }
    if (message.defaultFleetViewId !== undefined) {
      writer.uint32(56).int64(message.defaultFleetViewId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UserMetadata {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.consentGiven = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.consentTimestamp = longToNumber(reader.int64());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.experimental = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.language = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.showMascot = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.unit = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.defaultFleetViewId = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserMetadata {
    return {
      consentGiven: isSet(object.consentGiven) ? globalThis.Boolean(object.consentGiven) : undefined,
      consentTimestamp: isSet(object.consentTimestamp) ? globalThis.Number(object.consentTimestamp) : undefined,
      experimental: isSet(object.experimental) ? globalThis.Boolean(object.experimental) : undefined,
      language: isSet(object.language) ? globalThis.String(object.language) : undefined,
      showMascot: isSet(object.showMascot) ? globalThis.Boolean(object.showMascot) : undefined,
      unit: isSet(object.unit) ? globalThis.String(object.unit) : undefined,
      defaultFleetViewId: isSet(object.defaultFleetViewId) ? globalThis.Number(object.defaultFleetViewId) : undefined,
    };
  },

  toJSON(message: UserMetadata): unknown {
    const obj: any = {};
    if (message.consentGiven !== undefined) {
      obj.consentGiven = message.consentGiven;
    }
    if (message.consentTimestamp !== undefined) {
      obj.consentTimestamp = Math.round(message.consentTimestamp);
    }
    if (message.experimental !== undefined) {
      obj.experimental = message.experimental;
    }
    if (message.language !== undefined) {
      obj.language = message.language;
    }
    if (message.showMascot !== undefined) {
      obj.showMascot = message.showMascot;
    }
    if (message.unit !== undefined) {
      obj.unit = message.unit;
    }
    if (message.defaultFleetViewId !== undefined) {
      obj.defaultFleetViewId = Math.round(message.defaultFleetViewId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserMetadata>, I>>(base?: I): UserMetadata {
    return UserMetadata.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserMetadata>, I>>(object: I): UserMetadata {
    const message = createBaseUserMetadata();
    message.consentGiven = object.consentGiven ?? undefined;
    message.consentTimestamp = object.consentTimestamp ?? undefined;
    message.experimental = object.experimental ?? undefined;
    message.language = object.language ?? undefined;
    message.showMascot = object.showMascot ?? undefined;
    message.unit = object.unit ?? undefined;
    message.defaultFleetViewId = object.defaultFleetViewId ?? undefined;
    return message;
  },
};

function createBaseAuth0Profile(): Auth0Profile {
  return {
    email: "",
    emailVerified: false,
    name: "",
    username: "",
    givenName: "",
    phoneNumber: "",
    phoneVerified: false,
    familyName: "",
  };
}

export const Auth0Profile: MessageFns<Auth0Profile> = {
  encode(message: Auth0Profile, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    if (message.emailVerified !== false) {
      writer.uint32(16).bool(message.emailVerified);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.username !== "") {
      writer.uint32(34).string(message.username);
    }
    if (message.givenName !== "") {
      writer.uint32(42).string(message.givenName);
    }
    if (message.phoneNumber !== "") {
      writer.uint32(50).string(message.phoneNumber);
    }
    if (message.phoneVerified !== false) {
      writer.uint32(56).bool(message.phoneVerified);
    }
    if (message.familyName !== "") {
      writer.uint32(66).string(message.familyName);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Auth0Profile {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuth0Profile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.emailVerified = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.username = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.givenName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.phoneVerified = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.familyName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Auth0Profile {
    return {
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      emailVerified: isSet(object.emailVerified) ? globalThis.Boolean(object.emailVerified) : false,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      username: isSet(object.username) ? globalThis.String(object.username) : "",
      givenName: isSet(object.givenName) ? globalThis.String(object.givenName) : "",
      phoneNumber: isSet(object.phoneNumber) ? globalThis.String(object.phoneNumber) : "",
      phoneVerified: isSet(object.phoneVerified) ? globalThis.Boolean(object.phoneVerified) : false,
      familyName: isSet(object.familyName) ? globalThis.String(object.familyName) : "",
    };
  },

  toJSON(message: Auth0Profile): unknown {
    const obj: any = {};
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.emailVerified !== false) {
      obj.emailVerified = message.emailVerified;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.username !== "") {
      obj.username = message.username;
    }
    if (message.givenName !== "") {
      obj.givenName = message.givenName;
    }
    if (message.phoneNumber !== "") {
      obj.phoneNumber = message.phoneNumber;
    }
    if (message.phoneVerified !== false) {
      obj.phoneVerified = message.phoneVerified;
    }
    if (message.familyName !== "") {
      obj.familyName = message.familyName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Auth0Profile>, I>>(base?: I): Auth0Profile {
    return Auth0Profile.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Auth0Profile>, I>>(object: I): Auth0Profile {
    const message = createBaseAuth0Profile();
    message.email = object.email ?? "";
    message.emailVerified = object.emailVerified ?? false;
    message.name = object.name ?? "";
    message.username = object.username ?? "";
    message.givenName = object.givenName ?? "";
    message.phoneNumber = object.phoneNumber ?? "";
    message.phoneVerified = object.phoneVerified ?? false;
    message.familyName = object.familyName ?? "";
    return message;
  },
};

function createBaseAuth0Identity(): Auth0Identity {
  return {
    connection: "",
    userId: "",
    provider: "",
    isSocial: false,
    accessToken: "",
    accessTokenSecret: "",
    refreshToken: "",
    profileData: undefined,
  };
}

export const Auth0Identity: MessageFns<Auth0Identity> = {
  encode(message: Auth0Identity, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.connection !== "") {
      writer.uint32(10).string(message.connection);
    }
    if (message.userId !== "") {
      writer.uint32(18).string(message.userId);
    }
    if (message.provider !== "") {
      writer.uint32(26).string(message.provider);
    }
    if (message.isSocial !== false) {
      writer.uint32(32).bool(message.isSocial);
    }
    if (message.accessToken !== "") {
      writer.uint32(42).string(message.accessToken);
    }
    if (message.accessTokenSecret !== "") {
      writer.uint32(50).string(message.accessTokenSecret);
    }
    if (message.refreshToken !== "") {
      writer.uint32(58).string(message.refreshToken);
    }
    if (message.profileData !== undefined) {
      Auth0Profile.encode(message.profileData, writer.uint32(66).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Auth0Identity {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuth0Identity();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.connection = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.provider = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.isSocial = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.accessToken = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.accessTokenSecret = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.refreshToken = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.profileData = Auth0Profile.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Auth0Identity {
    return {
      connection: isSet(object.connection) ? globalThis.String(object.connection) : "",
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
      provider: isSet(object.provider) ? globalThis.String(object.provider) : "",
      isSocial: isSet(object.isSocial) ? globalThis.Boolean(object.isSocial) : false,
      accessToken: isSet(object.accessToken) ? globalThis.String(object.accessToken) : "",
      accessTokenSecret: isSet(object.accessTokenSecret) ? globalThis.String(object.accessTokenSecret) : "",
      refreshToken: isSet(object.refreshToken) ? globalThis.String(object.refreshToken) : "",
      profileData: isSet(object.profileData) ? Auth0Profile.fromJSON(object.profileData) : undefined,
    };
  },

  toJSON(message: Auth0Identity): unknown {
    const obj: any = {};
    if (message.connection !== "") {
      obj.connection = message.connection;
    }
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.provider !== "") {
      obj.provider = message.provider;
    }
    if (message.isSocial !== false) {
      obj.isSocial = message.isSocial;
    }
    if (message.accessToken !== "") {
      obj.accessToken = message.accessToken;
    }
    if (message.accessTokenSecret !== "") {
      obj.accessTokenSecret = message.accessTokenSecret;
    }
    if (message.refreshToken !== "") {
      obj.refreshToken = message.refreshToken;
    }
    if (message.profileData !== undefined) {
      obj.profileData = Auth0Profile.toJSON(message.profileData);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Auth0Identity>, I>>(base?: I): Auth0Identity {
    return Auth0Identity.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Auth0Identity>, I>>(object: I): Auth0Identity {
    const message = createBaseAuth0Identity();
    message.connection = object.connection ?? "";
    message.userId = object.userId ?? "";
    message.provider = object.provider ?? "";
    message.isSocial = object.isSocial ?? false;
    message.accessToken = object.accessToken ?? "";
    message.accessTokenSecret = object.accessTokenSecret ?? "";
    message.refreshToken = object.refreshToken ?? "";
    message.profileData = (object.profileData !== undefined && object.profileData !== null)
      ? Auth0Profile.fromPartial(object.profileData)
      : undefined;
    return message;
  },
};

function createBaseAuth0User(): Auth0User {
  return {
    userId: "",
    email: "",
    emailVerified: false,
    username: "",
    phoneNumber: "",
    phoneVerified: false,
    createdAt: "",
    updatedAt: "",
    identities: [],
    appMetadata: undefined,
    userMetadata: undefined,
    picture: "",
    name: "",
    nickname: "",
    multifactor: [],
    lastIp: "",
    lastLogin: "",
    loginsCount: 0,
    blocked: false,
    givenName: "",
    familyName: "",
  };
}

export const Auth0User: MessageFns<Auth0User> = {
  encode(message: Auth0User, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    if (message.email !== "") {
      writer.uint32(18).string(message.email);
    }
    if (message.emailVerified !== false) {
      writer.uint32(24).bool(message.emailVerified);
    }
    if (message.username !== "") {
      writer.uint32(34).string(message.username);
    }
    if (message.phoneNumber !== "") {
      writer.uint32(42).string(message.phoneNumber);
    }
    if (message.phoneVerified !== false) {
      writer.uint32(48).bool(message.phoneVerified);
    }
    if (message.createdAt !== "") {
      writer.uint32(58).string(message.createdAt);
    }
    if (message.updatedAt !== "") {
      writer.uint32(66).string(message.updatedAt);
    }
    for (const v of message.identities) {
      Auth0Identity.encode(v!, writer.uint32(74).fork()).join();
    }
    if (message.appMetadata !== undefined) {
      AppMetadata.encode(message.appMetadata, writer.uint32(82).fork()).join();
    }
    if (message.userMetadata !== undefined) {
      UserMetadata.encode(message.userMetadata, writer.uint32(90).fork()).join();
    }
    if (message.picture !== "") {
      writer.uint32(98).string(message.picture);
    }
    if (message.name !== "") {
      writer.uint32(106).string(message.name);
    }
    if (message.nickname !== "") {
      writer.uint32(114).string(message.nickname);
    }
    for (const v of message.multifactor) {
      writer.uint32(122).string(v!);
    }
    if (message.lastIp !== "") {
      writer.uint32(130).string(message.lastIp);
    }
    if (message.lastLogin !== "") {
      writer.uint32(138).string(message.lastLogin);
    }
    if (message.loginsCount !== 0) {
      writer.uint32(144).int64(message.loginsCount);
    }
    if (message.blocked !== false) {
      writer.uint32(152).bool(message.blocked);
    }
    if (message.givenName !== "") {
      writer.uint32(162).string(message.givenName);
    }
    if (message.familyName !== "") {
      writer.uint32(170).string(message.familyName);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Auth0User {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuth0User();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.emailVerified = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.username = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.phoneVerified = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.createdAt = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.updatedAt = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.identities.push(Auth0Identity.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.appMetadata = AppMetadata.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.userMetadata = UserMetadata.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.picture = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.name = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.nickname = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.multifactor.push(reader.string());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.lastIp = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.lastLogin = reader.string();
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.loginsCount = longToNumber(reader.int64());
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.blocked = reader.bool();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.givenName = reader.string();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.familyName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Auth0User {
    return {
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      emailVerified: isSet(object.emailVerified) ? globalThis.Boolean(object.emailVerified) : false,
      username: isSet(object.username) ? globalThis.String(object.username) : "",
      phoneNumber: isSet(object.phoneNumber) ? globalThis.String(object.phoneNumber) : "",
      phoneVerified: isSet(object.phoneVerified) ? globalThis.Boolean(object.phoneVerified) : false,
      createdAt: isSet(object.createdAt) ? globalThis.String(object.createdAt) : "",
      updatedAt: isSet(object.updatedAt) ? globalThis.String(object.updatedAt) : "",
      identities: globalThis.Array.isArray(object?.identities)
        ? object.identities.map((e: any) => Auth0Identity.fromJSON(e))
        : [],
      appMetadata: isSet(object.appMetadata) ? AppMetadata.fromJSON(object.appMetadata) : undefined,
      userMetadata: isSet(object.userMetadata) ? UserMetadata.fromJSON(object.userMetadata) : undefined,
      picture: isSet(object.picture) ? globalThis.String(object.picture) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      nickname: isSet(object.nickname) ? globalThis.String(object.nickname) : "",
      multifactor: globalThis.Array.isArray(object?.multifactor)
        ? object.multifactor.map((e: any) => globalThis.String(e))
        : [],
      lastIp: isSet(object.lastIp) ? globalThis.String(object.lastIp) : "",
      lastLogin: isSet(object.lastLogin) ? globalThis.String(object.lastLogin) : "",
      loginsCount: isSet(object.loginsCount) ? globalThis.Number(object.loginsCount) : 0,
      blocked: isSet(object.blocked) ? globalThis.Boolean(object.blocked) : false,
      givenName: isSet(object.givenName) ? globalThis.String(object.givenName) : "",
      familyName: isSet(object.familyName) ? globalThis.String(object.familyName) : "",
    };
  },

  toJSON(message: Auth0User): unknown {
    const obj: any = {};
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.emailVerified !== false) {
      obj.emailVerified = message.emailVerified;
    }
    if (message.username !== "") {
      obj.username = message.username;
    }
    if (message.phoneNumber !== "") {
      obj.phoneNumber = message.phoneNumber;
    }
    if (message.phoneVerified !== false) {
      obj.phoneVerified = message.phoneVerified;
    }
    if (message.createdAt !== "") {
      obj.createdAt = message.createdAt;
    }
    if (message.updatedAt !== "") {
      obj.updatedAt = message.updatedAt;
    }
    if (message.identities?.length) {
      obj.identities = message.identities.map((e) => Auth0Identity.toJSON(e));
    }
    if (message.appMetadata !== undefined) {
      obj.appMetadata = AppMetadata.toJSON(message.appMetadata);
    }
    if (message.userMetadata !== undefined) {
      obj.userMetadata = UserMetadata.toJSON(message.userMetadata);
    }
    if (message.picture !== "") {
      obj.picture = message.picture;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.nickname !== "") {
      obj.nickname = message.nickname;
    }
    if (message.multifactor?.length) {
      obj.multifactor = message.multifactor;
    }
    if (message.lastIp !== "") {
      obj.lastIp = message.lastIp;
    }
    if (message.lastLogin !== "") {
      obj.lastLogin = message.lastLogin;
    }
    if (message.loginsCount !== 0) {
      obj.loginsCount = Math.round(message.loginsCount);
    }
    if (message.blocked !== false) {
      obj.blocked = message.blocked;
    }
    if (message.givenName !== "") {
      obj.givenName = message.givenName;
    }
    if (message.familyName !== "") {
      obj.familyName = message.familyName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Auth0User>, I>>(base?: I): Auth0User {
    return Auth0User.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Auth0User>, I>>(object: I): Auth0User {
    const message = createBaseAuth0User();
    message.userId = object.userId ?? "";
    message.email = object.email ?? "";
    message.emailVerified = object.emailVerified ?? false;
    message.username = object.username ?? "";
    message.phoneNumber = object.phoneNumber ?? "";
    message.phoneVerified = object.phoneVerified ?? false;
    message.createdAt = object.createdAt ?? "";
    message.updatedAt = object.updatedAt ?? "";
    message.identities = object.identities?.map((e) => Auth0Identity.fromPartial(e)) || [];
    message.appMetadata = (object.appMetadata !== undefined && object.appMetadata !== null)
      ? AppMetadata.fromPartial(object.appMetadata)
      : undefined;
    message.userMetadata = (object.userMetadata !== undefined && object.userMetadata !== null)
      ? UserMetadata.fromPartial(object.userMetadata)
      : undefined;
    message.picture = object.picture ?? "";
    message.name = object.name ?? "";
    message.nickname = object.nickname ?? "";
    message.multifactor = object.multifactor?.map((e) => e) || [];
    message.lastIp = object.lastIp ?? "";
    message.lastLogin = object.lastLogin ?? "";
    message.loginsCount = object.loginsCount ?? 0;
    message.blocked = object.blocked ?? false;
    message.givenName = object.givenName ?? "";
    message.familyName = object.familyName ?? "";
    return message;
  },
};

function createBaseFleetView(): FleetView {
  return {
    db: undefined,
    userAuth0Id: "",
    name: "",
    columns: [],
    search: "",
    showInternal: false,
    showMap: false,
    showOffline: false,
    statuses: [],
    pinnedRobotIds: [],
    customerId: 0,
    viewMode: 0,
    customerIds: [],
  };
}

export const FleetView: MessageFns<FleetView> = {
  encode(message: FleetView, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.db !== undefined) {
      DB.encode(message.db, writer.uint32(10).fork()).join();
    }
    if (message.userAuth0Id !== "") {
      writer.uint32(18).string(message.userAuth0Id);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    for (const v of message.columns) {
      writer.uint32(34).string(v!);
    }
    if (message.search !== "") {
      writer.uint32(42).string(message.search);
    }
    if (message.showInternal !== false) {
      writer.uint32(48).bool(message.showInternal);
    }
    if (message.showMap !== false) {
      writer.uint32(56).bool(message.showMap);
    }
    if (message.showOffline !== false) {
      writer.uint32(64).bool(message.showOffline);
    }
    for (const v of message.statuses) {
      writer.uint32(74).string(v!);
    }
    writer.uint32(82).fork();
    for (const v of message.pinnedRobotIds) {
      writer.int64(v);
    }
    writer.join();
    if (message.customerId !== 0) {
      writer.uint32(88).int64(message.customerId);
    }
    if (message.viewMode !== 0) {
      writer.uint32(96).int32(message.viewMode);
    }
    writer.uint32(106).fork();
    for (const v of message.customerIds) {
      writer.int64(v);
    }
    writer.join();
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FleetView {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFleetView();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.db = DB.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userAuth0Id = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.columns.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.search = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.showInternal = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.showMap = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.showOffline = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.statuses.push(reader.string());
          continue;
        case 10:
          if (tag === 80) {
            message.pinnedRobotIds.push(longToNumber(reader.int64()));

            continue;
          }

          if (tag === 82) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.pinnedRobotIds.push(longToNumber(reader.int64()));
            }

            continue;
          }

          break;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.customerId = longToNumber(reader.int64());
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.viewMode = reader.int32() as any;
          continue;
        case 13:
          if (tag === 104) {
            message.customerIds.push(longToNumber(reader.int64()));

            continue;
          }

          if (tag === 106) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.customerIds.push(longToNumber(reader.int64()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FleetView {
    return {
      db: isSet(object.db) ? DB.fromJSON(object.db) : undefined,
      userAuth0Id: isSet(object.userAuth0Id) ? globalThis.String(object.userAuth0Id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      columns: globalThis.Array.isArray(object?.columns) ? object.columns.map((e: any) => globalThis.String(e)) : [],
      search: isSet(object.search) ? globalThis.String(object.search) : "",
      showInternal: isSet(object.showInternal) ? globalThis.Boolean(object.showInternal) : false,
      showMap: isSet(object.showMap) ? globalThis.Boolean(object.showMap) : false,
      showOffline: isSet(object.showOffline) ? globalThis.Boolean(object.showOffline) : false,
      statuses: globalThis.Array.isArray(object?.statuses) ? object.statuses.map((e: any) => globalThis.String(e)) : [],
      pinnedRobotIds: globalThis.Array.isArray(object?.pinnedRobotIds)
        ? object.pinnedRobotIds.map((e: any) => globalThis.Number(e))
        : [],
      customerId: isSet(object.customerId) ? globalThis.Number(object.customerId) : 0,
      viewMode: isSet(object.viewMode) ? viewModeFromJSON(object.viewMode) : 0,
      customerIds: globalThis.Array.isArray(object?.customerIds)
        ? object.customerIds.map((e: any) => globalThis.Number(e))
        : [],
    };
  },

  toJSON(message: FleetView): unknown {
    const obj: any = {};
    if (message.db !== undefined) {
      obj.db = DB.toJSON(message.db);
    }
    if (message.userAuth0Id !== "") {
      obj.userAuth0Id = message.userAuth0Id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.columns?.length) {
      obj.columns = message.columns;
    }
    if (message.search !== "") {
      obj.search = message.search;
    }
    if (message.showInternal !== false) {
      obj.showInternal = message.showInternal;
    }
    if (message.showMap !== false) {
      obj.showMap = message.showMap;
    }
    if (message.showOffline !== false) {
      obj.showOffline = message.showOffline;
    }
    if (message.statuses?.length) {
      obj.statuses = message.statuses;
    }
    if (message.pinnedRobotIds?.length) {
      obj.pinnedRobotIds = message.pinnedRobotIds.map((e) => Math.round(e));
    }
    if (message.customerId !== 0) {
      obj.customerId = Math.round(message.customerId);
    }
    if (message.viewMode !== 0) {
      obj.viewMode = viewModeToJSON(message.viewMode);
    }
    if (message.customerIds?.length) {
      obj.customerIds = message.customerIds.map((e) => Math.round(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FleetView>, I>>(base?: I): FleetView {
    return FleetView.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FleetView>, I>>(object: I): FleetView {
    const message = createBaseFleetView();
    message.db = (object.db !== undefined && object.db !== null) ? DB.fromPartial(object.db) : undefined;
    message.userAuth0Id = object.userAuth0Id ?? "";
    message.name = object.name ?? "";
    message.columns = object.columns?.map((e) => e) || [];
    message.search = object.search ?? "";
    message.showInternal = object.showInternal ?? false;
    message.showMap = object.showMap ?? false;
    message.showOffline = object.showOffline ?? false;
    message.statuses = object.statuses?.map((e) => e) || [];
    message.pinnedRobotIds = object.pinnedRobotIds?.map((e) => e) || [];
    message.customerId = object.customerId ?? 0;
    message.viewMode = object.viewMode ?? 0;
    message.customerIds = object.customerIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseUserResponse(): UserResponse {
  return { user: undefined, customer: undefined, fleetViews: [] };
}

export const UserResponse: MessageFns<UserResponse> = {
  encode(message: UserResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.user !== undefined) {
      Auth0User.encode(message.user, writer.uint32(10).fork()).join();
    }
    if (message.customer !== undefined) {
      CustomerResponse.encode(message.customer, writer.uint32(18).fork()).join();
    }
    for (const v of message.fleetViews) {
      FleetView.encode(v!, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UserResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user = Auth0User.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.customer = CustomerResponse.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fleetViews.push(FleetView.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserResponse {
    return {
      user: isSet(object.user) ? Auth0User.fromJSON(object.user) : undefined,
      customer: isSet(object.customer) ? CustomerResponse.fromJSON(object.customer) : undefined,
      fleetViews: globalThis.Array.isArray(object?.fleetViews)
        ? object.fleetViews.map((e: any) => FleetView.fromJSON(e))
        : [],
    };
  },

  toJSON(message: UserResponse): unknown {
    const obj: any = {};
    if (message.user !== undefined) {
      obj.user = Auth0User.toJSON(message.user);
    }
    if (message.customer !== undefined) {
      obj.customer = CustomerResponse.toJSON(message.customer);
    }
    if (message.fleetViews?.length) {
      obj.fleetViews = message.fleetViews.map((e) => FleetView.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserResponse>, I>>(base?: I): UserResponse {
    return UserResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserResponse>, I>>(object: I): UserResponse {
    const message = createBaseUserResponse();
    message.user = (object.user !== undefined && object.user !== null) ? Auth0User.fromPartial(object.user) : undefined;
    message.customer = (object.customer !== undefined && object.customer !== null)
      ? CustomerResponse.fromPartial(object.customer)
      : undefined;
    message.fleetViews = object.fleetViews?.map((e) => FleetView.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
