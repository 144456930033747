import { classes } from "portal/utils/theme";
import React, { FunctionComponent, PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  colorClassName?: string;
}

export const LabelDivider: FunctionComponent<Props> = ({
  colorClassName = "bg-zinc-500 text-white",
  children,
}) => (
  <div className="div self-justify flex my-8 items-center">
    <div className={classes("h-[2px] ml-4 flex-1", colorClassName)} />
    <div
      className={classes(
        "rounded-full px-4 py-1 text-sm font-bold",
        colorClassName
      )}
    >
      {children}
    </div>
    <div className={classes("h-[2px] mr-4 flex-1", colorClassName)} />
  </div>
);
