import {
  AnyMetric,
  carbonConvert,
  isCount,
  isMetricBoolean,
  isMetricDuration,
  isMetricNumber,
  isMetricTime,
  isPercent,
  isSpatialMetricBoolean,
  isSpatialMetricDuration,
  isSpatialMetricNumber,
  isSpatialMetricTime,
  unitsToI18nKey,
} from "portal/utils/metrics";
import {
  CarbonUnit,
  getCarbonBest,
  MeasurementSystem,
  resultToTArguments,
  UnitNumberType,
} from "portal/utils/units/units";
import { DateTime } from "luxon";
import { i18n as I18n, TFunction } from "i18next";
import { isUndefined } from "portal/utils/identity";
import {
  type PortalLanguage,
  portalLanguageOrDefault,
} from "portal/i18nConstants";

export const BLANK_VALUE = "—";

export interface FormattedMeasurement {
  // User-facing formatted value: e.g., "1.234,56"
  value: string;
  // User-facing formatted units: e.g., "mes" (Spanish for month)
  units: string;
  // Machine-facing value, if numeric: e.g., 1234.56
  converted: number | undefined;
  // Machine-facing units, if numeric: e.g., "month"
  carbonUnits: CarbonUnit | undefined;
  // String to go between `value` and `units`: e.g., " "
  unitsDelimiter: string;
  toString: () => string;
}

export const formatBooleanForExport = (value: boolean): string =>
  value ? "TRUE" : "FALSE";

export const formatMetric = (
  t: TFunction,
  i18n: I18n,
  measurementSystem: MeasurementSystem,
  metric: AnyMetric,
  value: any,
  options?: {
    toUnits?: CarbonUnit | undefined;
    decimalPlaces?: number | undefined;
    blankValue?: string | undefined;
    forExport?: boolean | undefined;
  }
): FormattedMeasurement => {
  if (isMetricTime(metric) || isSpatialMetricTime(metric)) {
    return formatDateTimeMillis(t, i18n, value, {
      blankValue: options?.blankValue,
    });
  } else if (isMetricNumber(metric) || isSpatialMetricNumber(metric)) {
    return formatMeasurement(t, i18n, measurementSystem, value, metric.units, {
      toUnits: options?.toUnits ?? metric.toUnits,
      decimalPlaces: metric.decimalPlaces ?? options?.decimalPlaces,
      unitsDelimiter: metric.unitsDelimiter,
      blankValue: options?.blankValue,
    });
  } else if (isMetricBoolean(metric) || isSpatialMetricBoolean(metric)) {
    if (options?.forExport) {
      return formatString(value);
    }
    return formatBoolean(t, i18n, value, {
      truei18nKey: metric.truei18nKey,
      falsei18nKey: metric.falsei18nKey,
      blankValue: options?.blankValue,
    });
  } else if (isMetricDuration(metric) || isSpatialMetricDuration(metric)) {
    return formatMeasurement(t, i18n, measurementSystem, value, metric.units, {
      toUnits: options?.toUnits,
      decimalPlaces: options?.decimalPlaces,
      unitsDelimiter: "",
      blankValue: options?.blankValue,
    });
  } else {
    return formatString(value || (options?.blankValue ?? BLANK_VALUE));
  }
};

const numberFormatterCache: Map<string, Intl.NumberFormat> = new Map();

function getNumberFormatter(
  locale: PortalLanguage,
  decimalPlaces: number
): Intl.NumberFormat {
  const key = `lng=${locale};dp=${decimalPlaces}`;
  let formatter = numberFormatterCache.get(key);
  if (!formatter) {
    formatter = new Intl.NumberFormat(locale, {
      // Force Arabic ("Latin") numerals even in locales like ar-EG, bn-BG.
      numberingSystem: "latn",
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    });
    numberFormatterCache.set(key, formatter);
  }
  return formatter;
}

const formatNumber = (
  i18n: I18n,
  value: number,
  decimalPlaces?: number
): string => {
  if (isUndefined(decimalPlaces) || decimalPlaces < 0) {
    decimalPlaces = value < 10 ? 1 : 0;
  }
  const locale = portalLanguageOrDefault(i18n.language);
  const formatter = getNumberFormatter(locale, decimalPlaces);
  return formatter.format(value);
};

export const getDecimalPlaces = (
  value: number | undefined,
  decimalPlaces: number | undefined
): number => {
  if (!isUndefined(decimalPlaces)) {
    return decimalPlaces;
  }
  if (!value) {
    return 0;
  }
  // we only care about absolute value when calculating decimals
  value = Math.abs(value);
  if (value < 0.5) {
    return 3;
  } else if (value < 10) {
    return 2;
  } else if (value < 100) {
    return 1;
  } else {
    return 0;
  }
};

export const formatString = (value: string): FormattedMeasurement => {
  return {
    units: "",
    unitsDelimiter: "",
    converted: undefined,
    carbonUnits: undefined,
    value,
    toString: () => value,
  };
};

export const formatBoolean = (
  t: TFunction,
  i18n: I18n,
  value: boolean | number | undefined,
  options?: {
    truei18nKey?: string;
    falsei18nKey?: string;
    blankValue?: string;
    forExport?: boolean;
  }
): FormattedMeasurement => {
  let stringValue: string;
  if (value === true || value === 1) {
    stringValue = t(
      options?.truei18nKey || /* i18nKey: */ "utils.descriptors.yes"
    );
  } else if (value === false || value === 0) {
    stringValue = t(
      options?.falsei18nKey || /* i18nKey: */ "utils.descriptors.no"
    );
  } else {
    stringValue = options?.blankValue ?? BLANK_VALUE;
  }
  return formatString(stringValue);
};

export const formatDateTimeMillis = (
  t: TFunction,
  i18n: I18n,
  epochMs: number | undefined,
  options?: { blankValue?: string }
): FormattedMeasurement => {
  if (epochMs) {
    const dateString = DateTime.fromMillis(epochMs).toLocaleString(
      {
        hour12: true,
        hour: "numeric",
        minute: "2-digit",
        weekday: "long",
        month: "long",
        day: "numeric",
      },
      { locale: i18n.language }
    );
    return formatString(dateString);
  } else {
    return formatString(options?.blankValue ?? BLANK_VALUE);
  }
};

export const formatMeasurement = (
  t: TFunction,
  i18n: I18n,
  measurementSystem: MeasurementSystem,
  value: number | undefined,
  fromUnits: CarbonUnit,
  options?: {
    toUnits?: CarbonUnit | undefined;
    decimalPlaces?: number | undefined;
    unitsDelimiter?: string | undefined;
    blankValue?: string | undefined;
    longUnits?: boolean | undefined;
  }
): FormattedMeasurement => {
  const isPresent = !isUndefined(value) && !Number.isNaN(value);

  let formattedValue: string;
  let formattedUnits: string;
  let convertedValue: number | undefined;
  let carbonUnits: CarbonUnit | undefined;
  let unitsDelimiter: string;
  const decimalPlaces = getDecimalPlaces(value, options?.decimalPlaces);
  if (isCount(fromUnits)) {
    formattedValue = isPresent
      ? formatNumber(i18n, value, decimalPlaces)
      : options?.blankValue ?? BLANK_VALUE;
    formattedUnits = "";
    unitsDelimiter = "";
    carbonUnits = UnitNumberType.COUNT;
    convertedValue = value;
  } else if (isPercent(fromUnits)) {
    formattedValue = isPresent
      ? formatNumber(i18n, value, decimalPlaces)
      : options?.blankValue ?? BLANK_VALUE;
    formattedUnits = t("utils.units.%");
    unitsDelimiter = i18n.language.startsWith("fr") ? " " : "";
    carbonUnits = UnitNumberType.PERCENT;
    convertedValue = value;
  } else if (isPresent) {
    const converted = options?.toUnits
      ? {
          val: carbonConvert(value, fromUnits, options.toUnits),
          unit: unitsToI18nKey(options.toUnits),
          singular: "",
          plural: "",
        }
      : getCarbonBest(value, fromUnits, measurementSystem);
    convertedValue = converted?.val || 0;
    formattedValue = formatNumber(
      i18n,
      convertedValue,
      getDecimalPlaces(converted?.val, options?.decimalPlaces)
    );
    carbonUnits = converted?.unit as CarbonUnit | undefined;
    const tArguments = converted ? resultToTArguments(converted) : undefined;
    formattedUnits = tArguments
      ? t(tArguments.i18nKey, tArguments.options).toString()
      : "";
    unitsDelimiter = options?.unitsDelimiter ?? " ";
  } else {
    convertedValue = value;
    formattedValue = options?.blankValue ?? BLANK_VALUE;
    carbonUnits = undefined;
    formattedUnits = "";
    unitsDelimiter = "";
  }
  return {
    value: formattedValue,
    unitsDelimiter,
    units: formattedUnits,
    converted: convertedValue,
    carbonUnits,
    toString() {
      return `${this.value}${this.unitsDelimiter}${this.units}`;
    },
  };
};
