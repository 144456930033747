import { classes } from "portal/utils/theme";
import { makeStyles } from "tss-react/mui";
import { Switch } from "@mui/material";
import ignoreIcon from "portal/images/icons/material_do_not_disturb.svg";
import React, { FunctionComponent } from "react";
import targetIcon from "portal/images/icons/material_point_scan.svg";

interface Props {
  inverted?: boolean;
  onChange: (value: boolean) => void;
  value: boolean;
}

export const TrustControl: FunctionComponent<Props> = ({
  inverted = false,
  onChange,
  value,
}) => {
  const useStyles = makeStyles()({
    root: {
      // style thumb
      "& .MuiSwitch-thumb": {
        "&::before": {
          content: "''",
          display: "block",
          position: "absolute",
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "50%",
        },
      },
      "& .Mui-checked .MuiSwitch-thumb::before": {
        backgroundImage: `url('${ignoreIcon}')`,
      },
      "& .MuiSwitch-thumb::before": {
        backgroundImage: `url('${targetIcon}')`,
      },
      "&.inverted .Mui-checked .MuiSwitch-thumb::before": {
        backgroundImage: `url('${targetIcon}')`,
      },
      "&.inverted .MuiSwitch-thumb::before": {
        backgroundImage: `url('${ignoreIcon}')`,
      },
    },
  });

  const { classes: generated } = useStyles();

  return (
    <Switch
      classes={{
        root: "text-white",
        thumb: value ? "bg-green-500" : "bg-red-500",
        track: value ? "bg-green-700" : "bg-red-700",
      }}
      className={classes(generated.root, { inverted })}
      checked={value}
      onChange={(event) => onChange(event.currentTarget.checked)}
    />
  );
};
