import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  LinearProgress,
  Link,
  Typography,
} from "@mui/material";
import { classes } from "portal/utils/theme";
import { ReactComponent as Logo } from "portal/images/logo_stack.svg";
import { Trans, useTranslation } from "react-i18next";
import { useSelf } from "portal/state/store";
import React, { ReactElement } from "react";

export const PendingActivationOverlay = (): ReactElement => {
  const { t } = useTranslation();
  const { user } = useSelf();
  const isCarbon = user?.email?.endsWith("@carbonrobotics.com");

  let greeting = "";
  if (user) {
    greeting = t("components.PendingActivationOverlay.hi", {
      name: user.givenName,
    });
  }

  return (
    <Backdrop open className="bg-zinc-800">
      <Box
        className={classes(
          "flex flex-col gap-10 items-center",
          "text-lighten-800",
          "p-10"
        )}
      >
        <Logo className="text-white w-40" />
        {isCarbon ? (
          <Alert severity="error">
            <AlertTitle>
              {t("components.PendingActivationOverlay.errors.carbon.title")}
            </AlertTitle>
            {t("components.PendingActivationOverlay.errors.carbon.description")}
          </Alert>
        ) : (
          <>
            <Typography variant="h4">
              {greeting}
              {greeting && " "}
              {t("components.PendingActivationOverlay.title")}
            </Typography>
            <Typography variant="body1" align="center">
              {t("components.PendingActivationOverlay.description")}
            </Typography>
            <LinearProgress className="w-3/4" />
          </>
        )}
        <Typography variant="body2" align="center">
          <Trans
            t={t}
            i18nKey="components.PendingActivationOverlay.logOut"
            components={[<Link href="/logout" key={0} />]}
          />
        </Typography>
      </Box>
    </Backdrop>
  );
};
