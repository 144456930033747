import { classes } from "portal/utils/theme";
import { Container, ContainerProps, Fab } from "@mui/material";
import { FeedbackContextProvider, FeedbackDialog } from "./FeedbackDialog";
import { useTranslation } from "react-i18next";
import { withErrorBoundary } from "portal/components/ErrorBoundary";
import backgroundMap from "portal/images/background_map.svg";
import FeedbackIcon from "@mui/icons-material/FeedbackOutlined";
import React, { FunctionComponent, PropsWithChildren, useState } from "react";

export const mapBackgroundStyle = {
  backgroundImage: `url(${backgroundMap})`,
  backgroundAttachment: "fixed",
  backgroundPosition: "bottom right",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};

interface Props extends PropsWithChildren {
  maxWidth?: ContainerProps["maxWidth"];
}
/**
 * Basic page wrapper for portal.
 * Handles breakpoints, scrolling, default layout, etc
 */
export const Page: FunctionComponent<Props> = withErrorBoundary(function Page({
  maxWidth = "xl",
  children,
}) {
  const { t } = useTranslation();
  const [isFeedbackOpen, setFeedbackOpen] = useState<boolean>(false);

  return (
    <>
      <div
        id="page"
        className="overflow-auto w-full mt-16 print:mt-0 flex-grow flex flex-col"
        style={mapBackgroundStyle}
      >
        <Container
          maxWidth={maxWidth}
          className={classes(
            "relative",
            "py-10 print:py-0 w-full print:px-0",
            "flex-grow flex flex-col"
          )}
        >
          {children}
        </Container>
      </div>
      <Fab
        className="fixed right-4 bottom-4 z-10 print:hidden"
        disabled={isFeedbackOpen}
        color="secondary"
        size="medium"
        aria-label={t("components.FeedbackDialog.feedback")}
        onClick={() => setFeedbackOpen(true)}
      >
        <FeedbackIcon />
      </Fab>
      <FeedbackContextProvider>
        <FeedbackDialog
          open={isFeedbackOpen}
          setOpen={(open) => setFeedbackOpen(open)}
          required
          commentText={t("components.FeedbackDialog.feedback")}
        />
      </FeedbackContextProvider>
    </>
  );
});

/**
 * Wrapper for an element inside a Page that removes the Page's
 * scroll behavior
 *
 * Can be dynamically disabled to allow scrolling for specific breakpoints
 */
interface NoScrollProps extends PropsWithChildren {
  className?: string;
  disable?: boolean;
}
export const NoScroll: FunctionComponent<NoScrollProps> = ({
  className,
  children,
  disable,
}) => {
  return disable ? (
    children
  ) : (
    <div
      className={classes(
        "flex flex-col overflow-hidden absolute inset-0 md:inset-10",
        className
      )}
    >
      {children}
    </div>
  );
};
