import { Badge, Tooltip } from "@mui/material";
import { classes } from "portal/utils/theme";
import { FilterData, getHiddenItemCount } from "portal/utils/map";
import { useTranslation } from "react-i18next";
import FilterAltIcon from "@mui/icons-material/FilterAltOutlined";
import React, { FunctionComponent } from "react";

interface FilterControlProps {
  className?: string;
  open: boolean;
  mapFilters: FilterData<boolean>;
  setOpen: (isOpen: boolean) => void;
}

export const FilterControl: FunctionComponent<FilterControlProps> = ({
  className,
  open,
  mapFilters,
  setOpen,
}) => {
  const { t } = useTranslation();
  const icon = <FilterAltIcon className="p-1 mapboxgl-ctrl-icon" />;
  return (
    <Tooltip title={t("components.map.filters.name")} placement="right" arrow>
      <div
        className={classes(
          className,
          "mapboxgl-ctrl mapboxgl-ctrl-group w-fit"
        )}
      >
        <button onClick={() => setOpen(!open)}>
          {getHiddenItemCount(mapFilters) > 0 ? (
            <Badge
              color="secondary"
              variant="dot"
              classes={{
                badge: "top-2 right-2 border border-white border-solid",
              }}
            >
              {icon}
            </Badge>
          ) : (
            icon
          )}
        </button>
      </div>
    </Tooltip>
  );
};
