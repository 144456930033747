import { useEffect, useRef } from "react";

// syntactic sugar for useEffect(() => () => doSomething(), [])
export const useUnmountEffect = (effect: () => void): void => {
  const effectRef = useRef(effect);

  // keep track of effect in case it changes
  useEffect(() => {
    effectRef.current = effect;
  }, [effect]);

  useEffect(() => {
    return () => {
      effectRef.current();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
