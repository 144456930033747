import { classes } from "portal/utils/theme";
import { Tooltip } from "@mui/material";
import React, { FunctionComponent } from "react";

/**
 * Visual flag for upper left corner with optional tooltip message
 */
interface Props {
  className?: string;
  label?: string;
  title?: string;
}
export const CornerFlag: FunctionComponent<Props> = ({
  className,
  label,
  title,
}) => (
  <Tooltip title={title} color="secondary">
    <div
      className={classes(
        "absolute top-1 -left-10 z-10",
        "-rotate-45",
        "w-28 py-1 shadow",
        "flex items-center justify-center",
        "text-white font-mono font-bold text-xs",
        className
      )}
    >
      {label}
    </div>
  </Tooltip>
);
