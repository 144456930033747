import { ReactComponent as ConfluenceIcon } from "portal/images/icons/confluence.svg";
import { Drawer } from "./Drawer";
import { DrawerProps } from "portal/components/drawer/Drawer";
import { ReactComponent as FarmIcon } from "portal/images/icons/farm.svg";
import { ReactComponent as FleetIcon } from "portal/images/icons/slayer_side_outlined.svg";
import { ReactComponent as JiraIcon } from "portal/images/icons/jira.svg";
import { Path } from "portal/utils/routing";
import { titleCase } from "portal/utils/strings";
import { useTranslation } from "react-i18next";
import AdminSettingsIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import ExternalIcon from "@mui/icons-material/OpenInNewOutlined";
import React, { FunctionComponent } from "react";
import ReportIcon from "@mui/icons-material/AssessmentOutlined";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import SupportIcon from "@mui/icons-material/HelpCenterOutlined";
import UploadIcon from "@mui/icons-material/CloudUploadOutlined";
import UsersIcon from "@mui/icons-material/GroupsOutlined";

export const InternalDrawer: FunctionComponent<DrawerProps> = ({
  items,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Drawer
      items={[
        {
          label: t("views.settings.title"),
          href: Path.SETTINGS,
          icon: <SettingsIcon />,
        },
        {
          label: t("views.fleet.title"),
          icon: <FleetIcon />,
          href: Path.FLEET,
        },
        {
          label: titleCase(t("models.reports.report_other")),
          icon: <ReportIcon />,
          href: Path.REPORTS,
        },
        {
          label: titleCase(t("models.users.user_other")),
          icon: <UsersIcon />,
          href: Path.USERS,
        },
        {
          label: titleCase(t("models.customers.customer_other")),
          icon: <FarmIcon />,
          href: Path.CUSTOMERS,
        },
        {
          label: t("views.knowledge.title"),
          icon: <SupportIcon />,
          href: Path.KNOWLEDGE,
        },
        {
          label: t("views.offline.title"),
          icon: <UploadIcon />,
          href: Path.OFFLINE,
        },
        {
          label: t("views.admin.title"),
          icon: <AdminSettingsIcon />,
          href: Path.ADMIN,
        },
        {
          label: (
            // not need to localize proper noun
            // eslint-disable-next-line i18next/no-literal-string
            <div className="flex items-center">
              Jira <ExternalIcon className="ml-2 text-sm text-lighten-500" />
            </div>
          ),
          icon: <JiraIcon />,
          href: "https://carbonrobotics.atlassian.net/jira/your-work",
        },
        {
          label: (
            // not need to localize proper noun
            // eslint-disable-next-line i18next/no-literal-string
            <div className="flex items-center">
              Confluence{" "}
              <ExternalIcon className="ml-2 text-sm text-lighten-500" />
            </div>
          ),
          icon: <ConfluenceIcon />,
          href: "https://carbonrobotics.atlassian.net/wiki/spaces/Home/overview",
        },
        ...(items ?? []),
      ]}
      {...props}
    />
  );
};
