import { addNotification } from "portal/state/notifications";
import { BlockResponse } from "protos/portal/spatial";
import { DATE_PATH_FORMAT, isToday } from "portal/utils/dates";
import { DateTime } from "luxon";
import {
  INPUT_DARK,
  LABEL,
  SELECT_DARK,
  TEXT_FIELD_DARK,
} from "portal/utils/theme";
import { isUndefined } from "portal/utils/identity";
import { Loading } from "portal/components/Loading";
import { Map } from "portal/components/map/Map";
import { QueryType, useQuery } from "portal/utils/hooks/useQuery";
import { range } from "portal/utils/arrays";
import { ReportTools } from "portal/components/reports/ReportTools";
import { secondsToRange } from "portal/utils/reports";
import { useDispatch } from "react-redux";
import {
  useLazyGetSpatialQuery,
  useListRobotsQuery,
} from "portal/state/portalApi";
import { useLazyPopups, useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { useMemoAsync } from "portal/utils/hooks/useMemoAsync";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { values } from "portal/utils/objects";
import { ViewPlaceholder } from "portal/components/ViewPlaceholder";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";

export const ExploreMap: FunctionComponent = withAuthenticationRequired(
  function ExploreMap() {
    const { isInternal, customer } = useSelf();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // date range
    const [startSeconds, setStartSeconds] = useQuery<number>(
      "startDate",
      QueryType.NUMBER,
      DateTime.local().minus({ days: 7 }).toUnixInteger()
    );
    const startDate = useMemo<DateTime | undefined>(
      () =>
        isUndefined(startSeconds)
          ? undefined
          : DateTime.fromSeconds(startSeconds),
      [startSeconds]
    );
    const [endSeconds, setEndSeconds] = useQuery<number>(
      "endDate",
      QueryType.NUMBER,
      DateTime.local().toUnixInteger()
    );
    const endDate = useMemo<DateTime | undefined>(() => {
      return isUndefined(endSeconds)
        ? undefined
        : DateTime.fromSeconds(endSeconds);
    }, [endSeconds]);
    const dates = useMemo<string[]>(() => {
      if (!endDate || !startDate) {
        return [];
      }
      const duration = endDate.diff(startDate);
      return range(duration.as("days") + 1).map((index) =>
        endDate.minus({ days: index }).toFormat(DATE_PATH_FORMAT)
      );
    }, [endDate, startDate]);

    // robots
    const [robotIds, setRobotIds] = useQuery<number[]>(
      "robots",
      QueryType.ARRAY_NUMBER,
      []
    );
    const {
      data: summaries,
      isLoading: isRobotsLoading,
      isError: isRobotsError,
      error: robotsError,
    } = useQueryPopups(useListRobotsQuery({}));
    const selectedSummaries = useMemo(() => {
      return summaries?.filter(
        (summary) =>
          summary.robot?.db?.id && robotIds?.includes(summary.robot.db.id)
      );
    }, [robotIds, summaries]);

    // blocks
    const [getSpatial, { isBlocksLoading }] = useLazyPopups(
      useLazyGetSpatialQuery()
    );
    const blocks = useMemoAsync<BlockResponse[]>(
      async () => {
        if (!selectedSummaries?.length || dates.length === 0) {
          return [];
        }
        const { data } = await getSpatial({
          serials: selectedSummaries
            .map((summary) => summary.robot?.serial)
            .filter((serial) => !isUndefined(serial)),
          dates,
        });
        if (!data) {
          return [];
        }
        let queryLimitReached = false;
        const allBlocks: BlockResponse[] = [];
        for (const blocksByDate of values(data.blocks)) {
          for (const response of values(blocksByDate.blocks)) {
            allBlocks.push(...response.blocks);
            if (response.queryLimitReached) {
              queryLimitReached = true;
            }
          }
        }
        if (queryLimitReached) {
          dispatch(
            addNotification({
              message: t("components.ErrorBoundary.queryLimitReached"),
              variant: "warning",
            })
          );
        }
        return allBlocks;
      },
      [dates, dispatch, getSpatial, selectedSummaries, t],
      []
    );

    // zoom management
    const [canZoom, setCanZoom] = useState<boolean>(true);
    useEffect(() => {
      setCanZoom(true);
    }, [selectedSummaries, blocks]);

    if (isRobotsLoading) {
      return <Loading failed={isRobotsError} error={robotsError} />;
    }

    return (
      <>
        <ReportTools
          dateRange={secondsToRange(startSeconds, endSeconds)}
          customerIds={isInternal ? [] : [customer?.db?.id ?? -1]}
          selectedRobots={robotIds}
          onDateRangeChange={([startDate, endDate]) => {
            setStartSeconds(startDate?.toUnixInteger());
            setEndSeconds(endDate?.toUnixInteger());
          }}
          onSelectedRobotsChange={(robotIds) => setRobotIds(robotIds)}
          themeProps={{
            field: TEXT_FIELD_DARK,
            label: LABEL,
            select: SELECT_DARK,
            input: INPUT_DARK,
          }}
        />
        {selectedSummaries?.length ? (
          <Map
            className="w-full flex-grow min-h-96"
            robots={selectedSummaries}
            blocks={blocks}
            loading={isBlocksLoading}
            canZoom={canZoom}
            allowBorders
            onZoom={() => setCanZoom(false)}
            hideRobots={
              !endSeconds || !isToday(DateTime.fromMillis(endSeconds))
            }
          />
        ) : (
          <ViewPlaceholder text={t("views.reports.tools.robotsLabel.select")} />
        )}
      </>
    );
  }
);
