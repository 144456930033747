import { LaserTable } from "portal/components/LaserTable";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetRobotHardwareQuery } from "portal/state/portalApi";
import { useParams } from "react-router-dom";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { FunctionComponent } from "react";

export const RobotLasersList: FunctionComponent = withAuthenticationRequired(
  function RobotLasersList() {
    const { serial } = useParams();

    const { data: hardware } = useQueryPopups(
      useGetRobotHardwareQuery(serial ?? skipToken)
    );

    return <LaserTable lasers={hardware?.lasers ?? []} serial={serial} />;
  }
);
