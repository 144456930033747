// Importing necessary components to gather information from the internal config service.
import {
  Breakpoints,
  classes,
  SMALL_SECRET_RAINBOW_BUTTON,
  STATUS_BG,
  STATUS_TEXT,
} from "portal/utils/theme";
import {
  Button,
  Card,
  CardContent,
  Chip,
  Link,
  Typography,
} from "@mui/material";
import { CachedRobotHealth, RobotSummaryResponse } from "protos/portal/robots";
import { CarbonUnit, MeasurementSystem } from "portal/utils/units/units";
import { ConfigNode } from "protos/config/api/config_service";
import { CycleSlot } from "portal/utils/metrics";
import { DateTime } from "luxon";
import {
  eachNode,
  getConfigChanges,
  getNodeFromPath,
  getValue,
} from "portal/utils/configs";
import { getDisabledLasers } from "portal/utils/robots";
import { getRobotPath, RobotSubpath } from "portal/utils/routing";
import { isEmpty } from "portal/utils/objects";
import { isUndefined } from "portal/utils/identity";
import { Measurement, MeasurementProps } from "../measurement/Measurement";
import { range, sortBy } from "portal/utils/arrays";
import { Link as RouterLink } from "react-router-dom";
import { titleCase } from "portal/utils/strings";
import { toQuery } from "portal/utils/browser";
import { useTranslation } from "react-i18next";
import { withErrorBoundary } from "../ErrorBoundary";
import { WithSkeleton } from "../WithSkeleton";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Unstable_Grid2";
import ImagesIcon from "@mui/icons-material/Filter";
import React, {
  Fragment,
  FunctionComponent,
  ReactElement,
  ReactNode,
} from "react";

interface BandingHealthProps {
  config?: ConfigNode;
  health?: CachedRobotHealth;
}

const BandingHealth: FunctionComponent<BandingHealthProps> = ({
  config,
  health,
}) => {
  const { t } = useTranslation();
  const bandingVersion = getValue<boolean>(
    getNodeFromPath(config, "common/static_banding_v2_enabled")
  )
    ? t("views.fleet.robots.summary.banding.v2")
    : t("views.fleet.robots.summary.banding.v1");
  let bandingState = t("views.fleet.robots.summary.banding.unknown");
  let bandingMode = t("views.fleet.robots.summary.banding.unknown");
  const v1BandingRows: string[] = [];
  if (bandingVersion === "v1") {
    bandingMode = t("views.fleet.robots.summary.banding.static");
    for (const row of range(3, true)) {
      if (
        getValue<boolean>(
          getNodeFromPath(config, `row${row}/aimbot/banding/enabled`)
        )
      ) {
        v1BandingRows.push(String(row));
      }
    }
    bandingState =
      v1BandingRows.length > 0
        ? t("utils.descriptors.enabled")
        : t("utils.descriptors.disabled");
  } else if (bandingVersion === "v2") {
    if (health?.fieldConfig) {
      bandingState = health.fieldConfig.bandingEnabled
        ? t("utils.descriptors.enabled")
        : t("utils.descriptors.disabled");
    }
    switch (health?.fieldConfig?.bandingDynamic === true) {
      case true: {
        bandingMode = t("views.fleet.robots.summary.banding.dynamic");
        break;
      }
      case false: {
        bandingMode = t("views.fleet.robots.summary.banding.static");
        break;
      }
    }
  }
  const isDynamicBandingFlagOn = getValue<boolean>(
    getNodeFromPath(config, "common/dynamic_banding_enabled")
  );

  const bandingSummary: SummaryItem[] = [];

  bandingSummary.push({
    label: t("views.fleet.robots.summary.banding.version"),
    value: bandingVersion,
  });

  if (
    bandingVersion === "v1" &&
    bandingState === t("utils.descriptors.enabled")
  ) {
    bandingSummary.push({
      label: t("views.fleet.robots.summary.banding.rows"),
      value: v1BandingRows.join(", "),
    });
  }

  if (bandingVersion === "v2") {
    bandingSummary.push(
      {
        label: t("views.fleet.robots.summary.banding.type"),
        value: `${bandingMode}${
          isDynamicBandingFlagOn
            ? ""
            : ` ${t("views.fleet.robots.summary.banding.dynamicDisabled")}`
        }`,
      },
      {
        label: t("views.fleet.robots.summary.banding.definition"),
        value:
          health?.fieldConfig?.activeBandConfig ??
          t("views.fleet.robots.summary.banding.unknown"),
      }
    );
  }

  return (
    <details
      className="duration-300"
      open={bandingState === t("utils.descriptors.enabled")}
    >
      <summary className="bg-inherit border border-white-200 cursor-pointer">
        <strong>
          {t("components.robots.RobotSummary.banding.withName", {
            name:
              health?.fieldConfig?.activeBandConfigName ??
              t("views.fleet.robots.summary.banding.unknown"),
          })}
        </strong>
        <span
          className={classes("ml-1", {
            [STATUS_TEXT.RED]: bandingState !== t("utils.descriptors.disabled"),
            [STATUS_TEXT.GREEN]:
              bandingState === t("utils.descriptors.enabled"),
          })}
        >
          ({bandingState})
        </span>
      </summary>
      <Grid container spacing={1} className="max-w-full mt-2">
        {bandingSummary.map(({ label, value }) => (
          <Fragment key={label}>
            <Grid className="text-right" xs={4}>
              {label}:
            </Grid>
            <Grid className="font-mono font-bold" xs={8}>
              {value}
            </Grid>
          </Fragment>
        ))}
      </Grid>
    </details>
  );
};

interface LaserHealthProps {
  serial?: string;
  config?: ConfigNode;
  expandableTitle?: ReactNode;
}

export const LaserHealth: FunctionComponent<LaserHealthProps> = ({
  serial,
  config,
  expandableTitle,
}) => {
  const { t } = useTranslation();
  const disabledLasers = getDisabledLasers(serial, config);
  if (isEmpty(disabledLasers)) {
    return;
  }
  return (
    <details className="duration-300">
      <summary className="bg-inherit border border-white-200 cursor-pointer">
        {expandableTitle ?? (
          <strong>
            {t("views.fleet.robots.summary.lasers.disabled", {
              count: disabledLasers.length,
            })}
          </strong>
        )}
      </summary>
      <div className="mt-2">
        {range(3, true).map((row) => (
          <LasersStatus config={config} row={row} key={row} />
        ))}
      </div>
    </details>
  );
};

interface LasersStatusProps {
  config?: ConfigNode;
  row: number;
}

const LasersStatus: FunctionComponent<LasersStatusProps> = ({
  config,
  row,
}) => {
  const { t } = useTranslation();
  let disabledCount = 0;
  const content = config ? (
    <>
      {range(10, true).map((laser) => {
        const isEnabled = getValue<boolean>(
          getNodeFromPath(
            config,
            `row${row}/aimbot/scanners/scanner${laser}/enabled`
          )
        );
        if (!isEnabled) {
          disabledCount++;
        }
        return (
          <div
            key={laser}
            className={classes(
              "flex-1 font-bold font-mono text-white text-center",
              {
                "bg-green-500": isEnabled,
                "bg-gray-500": !isEnabled,
              }
            )}
          >
            {laser}
          </div>
        );
      })}
    </>
  ) : (
    <></>
  );

  return (
    <div className="flex">
      <span
        className={classes("font-mono mr-2", {
          "text-white": disabledCount === 0,
          [STATUS_TEXT.YELLOW]: disabledCount > 0 && disabledCount < 5,
          [STATUS_TEXT.RED]: disabledCount >= 5,
        })}
      >
        {t("views.fleet.robots.summary.lasers.row", { row })}
      </span>
      {content}
    </div>
  );
};

interface ConfigHealthProps {
  className?: string;
  config?: ConfigNode;
  template?: ConfigNode;
  health?: CachedRobotHealth;
}

interface SummaryItem {
  label: string;
  labelClass?: string;
  labelBreakpoints?: Breakpoints;
  labelSize?: number;
  value: string;
  valueClass?: string;
  valueBreakpoints?: Breakpoints;
}

export const ConfigHealthCard: FunctionComponent<ConfigHealthProps> =
  withErrorBoundary(function ConfigHealthCard({
    className,
    config,
    template,
    health,
  }) {
    const { t } = useTranslation();
    const { allChangedPaths } = getConfigChanges(config, template);
    const nonAlmanacChangedPaths = allChangedPaths.filter(
      (path) => !path.includes("almanac")
    );

    const configSummary: SummaryItem[] = health?.fieldConfig
      ?.activeModelinatorId
      ? []
      : [
          {
            label: t("views.fleet.robots.summary.config.wpt"),
            labelBreakpoints: { xs: 6 },
            labelClass: "text-right",
            value:
              getValue<number>(
                getNodeFromPath(config, "common/deepweed/weed_point_threshold")
              )?.toFixed(2) ?? t("views.fleet.robots.summary.banding.unknown"),
            valueBreakpoints: { xs: 6 },
          },
          {
            label: t("views.fleet.robots.summary.config.cpt"),
            labelBreakpoints: { xs: 6 },
            labelClass: "text-right",
            value:
              getValue<number>(
                getNodeFromPath(config, "common/deepweed/crop_point_threshold")
              )?.toFixed(2) ?? t("views.fleet.robots.summary.banding.unknown"),
            valueBreakpoints: { xs: 6 },
          },
        ];

    return (
      <WithSkeleton
        variant="rectangular"
        success={!isUndefined(config)}
        className={classes(
          "w-full min-h-full flex-shrink-0 flex-grow",
          className
        )}
      >
        <Card>
          <CardContent>
            <Typography variant="h6" className="mb-4">
              {titleCase(t("models.configs.config_one"))}
              {nonAlmanacChangedPaths.length > 0 && (
                <Chip
                  label={t("components.config.changedKey", {
                    count: nonAlmanacChangedPaths.length,
                  })}
                  className="font-normal ml-4 bg-yellow-500 text-black cursor-pointer"
                  component={RouterLink}
                  to={`${window.location.pathname}/config`}
                  state={{ showChanged: true }}
                />
              )}
            </Typography>
            {renderConfigs(configSummary)}
            <div className="flex flex-col gap-2">
              {!health?.fieldConfig?.activeAlmanacId && (
                <Almanac config={config} template={template} />
              )}
              <FeatureFlags config={config} />
              <BandingHealth config={config} health={health} />
            </div>
          </CardContent>
        </Card>
      </WithSkeleton>
    );
  });

interface FeatureFlagsProps {
  config?: ConfigNode;
}

const FeatureFlags: FunctionComponent<FeatureFlagsProps> = withErrorBoundary(
  function FeatureFlags({ config }) {
    const { t } = useTranslation();
    let total = 0;
    let enabled = 0;

    const flags = getNodeFromPath(config, "common/feature_flags");
    const flagSummary: SummaryItem[] =
      flags?.children.map((flag) => {
        total++;
        const isEnabled = getValue<boolean>(flag);
        if (isEnabled) {
          enabled++;
        }
        const item: SummaryItem = {
          label: flag.name.replace("_feature", ""),
          labelClass: "text-right font-mono text-xs",
          value: isEnabled
            ? t("models.customers.fields.featureFlags.on")
            : t("models.customers.fields.featureFlags.off"),
          valueClass: isEnabled ? "text-green-500" : "text-gray-500",
        };
        return item;
      }) ?? [];

    return (
      <details className="duration-300">
        <summary className="bg-inherit border border-white-200 cursor-pointer mb-2">
          <strong>
            {t("models.customers.fields.featureFlags.summary", {
              enabled,
              total,
            })}
          </strong>
        </summary>
        {renderConfigs(sortBy(flagSummary, "label"))}
      </details>
    );
  }
);

interface AlmanacProps {
  config?: ConfigNode;
  template?: ConfigNode;
}

export const Almanac: FunctionComponent<AlmanacProps> = withErrorBoundary(
  function Almanac({ config, template }) {
    const { t } = useTranslation();
    const { allChangedPaths } = getConfigChanges(config, template);
    const almanacChangedPaths = allChangedPaths.filter((path) =>
      path.startsWith("common/almanac")
    );

    if (almanacChangedPaths.length === 0) {
      return;
    }

    const almanac = getNodeFromPath(config, "common/almanac");
    const almanacChanges: SummaryItem[] = [];
    eachNode(almanac, (node, path) => {
      const fullPath = `common/${path}`;
      if (almanacChangedPaths.includes(fullPath)) {
        almanacChanges.push({
          label: path.replace("almanac/", ""),
          labelClass: "font-mono text-xs text-left md:text-right -mb-2 md:mb-0",
          labelBreakpoints: { xs: 12, md: 6 },
          value: `${getValue(node)} ${t(
            "views.fleet.robots.summary.config.default",
            {
              value: getValue(getNodeFromPath(template, fullPath)),
            }
          )}`,
          valueClass: STATUS_TEXT.YELLOW,
          valueBreakpoints: { xs: 12, md: 6 },
        });
      }
    });

    return (
      <details className="duration-300">
        <summary className="bg-inherit border border-white-200 cursor-pointer mb-2">
          <strong className={STATUS_TEXT.YELLOW}>
            {t("views.fleet.robots.summary.config.changes", {
              count: almanacChanges.length,
            })}
          </strong>
        </summary>
        {renderConfigs(almanacChanges)}
      </details>
    );
  }
);

const renderConfigs = (
  configs: SummaryItem[],
  title?: string
): ReactElement => (
  <Grid container spacing={1} className="items-start">
    {title && (
      <span className="mb-2 mt-4 font-bold block w-full text-center">
        {title}
      </span>
    )}
    {configs.map(
      ({
        label,
        labelClass = "",
        labelBreakpoints = { xs: 8 },
        value,
        valueClass = "",
        valueBreakpoints = { xs: 4 },
      }) => (
        <Fragment key={label}>
          <Grid
            className={classes("text-ellipsis overflow-hidden", labelClass)}
            {...labelBreakpoints}
          >
            {label}:
          </Grid>
          <Grid
            className={classes(
              "font-mono font-bold text-ellipsis overflow-hidden",
              valueClass
            )}
            {...valueBreakpoints}
          >
            {value}
          </Grid>
        </Fragment>
      )
    )}
  </Grid>
);

interface ConfigFieldProps {
  enabled: boolean;
  label: string;
  measurementProps: MeasurementProps;
  path: string;
  search: string;
  serial?: string;
}

export const ConfigField: FunctionComponent<ConfigFieldProps> = ({
  enabled,
  label,
  measurementProps,
  path,
  search,
  serial,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Link
        className="flex items-center gap-1"
        component={RouterLink}
        to={`${getRobotPath(serial ?? "", RobotSubpath.CONFIG)}/${path}`}
        underline="hover"
        state={{ search }}
        color="inherit"
      >
        <span className="text-xs">{label}</span>
        <EditIcon className="text-sm" />
      </Link>
      <div
        className={classes(
          "py-2 px-4 text-white w-full text-center rounded-sm",
          {
            "bg-zinc-600": !enabled,
            [STATUS_BG.GREEN]: enabled,
            "line-through": !enabled,
          }
        )}
      >
        {isUndefined(measurementProps.value) ? (
          t("views.fleet.robots.summary.encoders.unknown")
        ) : (
          <Measurement {...measurementProps} />
        )}
      </div>
    </>
  );
};

interface WheelEncoderSummary {
  label: string;
  diameter?: number;
  enabled: boolean;
  path: string;
  search: string;
}

interface WheelEncodersProps {
  className?: string;
  serial?: string;
  config?: ConfigNode;
}

export const WheelEncodersCard: FunctionComponent<WheelEncodersProps> =
  withErrorBoundary(function WheelEncodersCard({ className, serial, config }) {
    const { t } = useTranslation();
    const wheelEncoders: WheelEncoderSummary[] = [
      {
        label: t("views.fleet.robots.summary.encoders.frontLeft"),
        enabled:
          getValue<boolean>(
            getNodeFromPath(config, "common/wheel_encoders/front_left/enabled")
          ) ?? false,
        diameter: getValue<number>(
          getNodeFromPath(
            config,
            "common/wheel_encoders/front_left/diameter_in"
          )
        ),
        path: "common/wheel_encoders/front_left/diameter_in",
        search: "common/wheel_encoders/front_left",
      },
      {
        label: t("views.fleet.robots.summary.encoders.frontRight"),
        enabled:
          getValue<boolean>(
            getNodeFromPath(config, "common/wheel_encoders/front_right/enabled")
          ) ?? false,
        diameter: getValue<number>(
          getNodeFromPath(
            config,
            "common/wheel_encoders/front_right/diameter_in"
          )
        ),
        path: "common/wheel_encoders/front_right/diameter_in",
        search: "common/wheel_encoders/front_right",
      },
      {
        label: t("views.fleet.robots.summary.encoders.backLeft"),
        enabled:
          getValue<boolean>(
            getNodeFromPath(config, "common/wheel_encoders/back_left/enabled")
          ) ?? false,
        diameter: getValue<number>(
          getNodeFromPath(config, "common/wheel_encoders/back_left/diameter_in")
        ),
        path: "common/wheel_encoders/back_left/diameter_in",
        search: "common/wheel_encoders/back_left",
      },
      {
        label: t("views.fleet.robots.summary.encoders.backRight"),
        enabled:
          getValue<boolean>(
            getNodeFromPath(config, "common/wheel_encoders/back_right/enabled")
          ) ?? false,
        diameter: getValue<number>(
          getNodeFromPath(
            config,
            "common/wheel_encoders/back_right/diameter_in"
          )
        ),
        path: "common/wheel_encoders/back_right/diameter_in",
        search: "common/wheel_encoders/back_right",
      },
    ];
    return (
      <WithSkeleton
        variant="rectangular"
        success={!isUndefined(config)}
        className={classes(
          "w-full min-h-full flex-shrink-0 flex-grow",
          className
        )}
      >
        <Card>
          <CardContent>
            <Typography variant="h6" className="mb-4">
              {t("views.fleet.robots.summary.encoders.title")}
            </Typography>
            <Grid container spacing={4} className="w-full">
              {wheelEncoders.map(
                ({ label, enabled, diameter, path, search }) => (
                  <Grid
                    key={label}
                    xs={6}
                    sm={12}
                    lg={6}
                    className="flex flex-col gap-2 items-start"
                  >
                    <ConfigField
                      enabled={enabled}
                      path={path}
                      serial={serial}
                      search={search}
                      label={label}
                      measurementProps={{
                        value: diameter,
                        fromUnits: "in",
                        cycle: {
                          [MeasurementSystem.imperial]: ["in"],
                          [MeasurementSystem.metric]: ["in", "cm"],
                        },
                        decimalPlaces: 2,
                        cycleSlot: CycleSlot.COMPONENT_ENCODERS,
                      }}
                    />
                  </Grid>
                )
              )}
            </Grid>
          </CardContent>
        </Card>
      </WithSkeleton>
    );
  });

interface SafetyRadiusSummary {
  label: string;
  radius?: number;
  enabled: boolean;
  fromUnits: CarbonUnit;
  toUnits: CarbonUnit;
  path: string;
  search: string;
}

interface SafetyRadiusProps {
  className?: string;
  serial?: string;
  config?: ConfigNode;
}

export const SafetyRadiusCard: FunctionComponent<SafetyRadiusProps> =
  withErrorBoundary(function SafetyRadiusCard({ className, serial, config }) {
    const { t } = useTranslation();
    const safetyRadius: SafetyRadiusSummary[] = [
      {
        label: t("views.fleet.robots.summary.safetyRadius.driptape"),
        enabled:
          getValue<boolean>(
            getNodeFromPath(
              config,
              "common/deepweed/segmentationCategories/DRIPTAPE/enabled"
            )
          ) ?? false,
        radius: getValue<number>(
          getNodeFromPath(
            config,
            "common/deepweed/segmentationCategories/DRIPTAPE/safety_radius_in"
          )
        ),
        fromUnits: "in",
        toUnits: "in",
        path: "common/deepweed/segmentationCategories/DRIPTAPE/safety_radius_in",
        search: "common/deepweed/segmentationCategories/DRIPTAPE",
      },
      {
        label: titleCase(t("models.crops.crop_one")),
        enabled:
          getValue<boolean>(
            getNodeFromPath(config, "common/protector/allow_unprotect")
          ) ?? false,
        radius: getValue<number>(
          getNodeFromPath(config, "common/protector/crop_protect_radius")
        ),
        fromUnits: "mm",
        toUnits: "mm",
        path: "common/protector/crop_protect_radius",
        search: "common/protector",
      },
    ];
    return (
      <WithSkeleton
        variant="rectangular"
        success={!isUndefined(config)}
        className={classes(
          "w-full min-h-full flex-shrink-0 flex-grow",
          className
        )}
      >
        <Card>
          <CardContent>
            <Typography variant="h6" className="mb-4">
              {t("views.fleet.robots.summary.safetyRadius.title")}
            </Typography>
            <Grid container spacing={4} className="w-full">
              {safetyRadius.map(
                ({
                  label,
                  enabled,
                  radius,
                  fromUnits,
                  toUnits,
                  path,
                  search,
                }) => (
                  <Grid
                    key={label}
                    xs={6}
                    sm={12}
                    lg={6}
                    className="flex flex-col gap-2 items-start"
                  >
                    <ConfigField
                      enabled={enabled}
                      path={path}
                      serial={serial}
                      search={search}
                      label={label}
                      measurementProps={{
                        value: radius,
                        fromUnits,
                        toUnits,
                      }}
                    />
                  </Grid>
                )
              )}
            </Grid>
          </CardContent>
        </Card>
      </WithSkeleton>
    );
  });

interface SupportLinksCardProps {
  summary?: RobotSummaryResponse;
}

export const SupportLinksCard: FunctionComponent<SupportLinksCardProps> =
  withErrorBoundary(({ summary }) => {
    const { t } = useTranslation();
    return (
      <WithSkeleton variant="rectangular" success={!isUndefined(summary)}>
        <Card>
          <CardContent>
            <Typography variant="h6" className="mb-4">
              {t("views.fleet.robots.summary.supportLinks.title")}
            </Typography>
            <Button
              {...SMALL_SECRET_RAINBOW_BUTTON}
              LinkComponent="a"
              className={classes(
                SMALL_SECRET_RAINBOW_BUTTON.className,
                "flex gap-1"
              )}
              target="_blank"
              href={`https://panelytics.cloud.carbonrobotics.com/dataset_vis?${toQuery(
                {
                  model_id: summary?.robot?.health?.model,
                  timestamp: DateTime.now().toISO(),
                  location: `${summary?.robot?.health?.location?.x}, ${summary?.robot?.health?.location?.y}`,
                }
              )}`}
            >
              <ImagesIcon />
              <span className="group-hover:text-rainbow">
                {t(
                  "views.fleet.robots.summary.supportLinks.datasetVisualization"
                )}
              </span>
            </Button>
          </CardContent>
        </Card>
      </WithSkeleton>
    );
  });
