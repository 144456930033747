import { Alert, Typography } from "@mui/material";
import { capitalize } from "portal/utils/strings";
import { GlobalAllowedAlarm } from "protos/portal/admin";
import { ListManager } from "portal/components/ListManager";
import {
  useGetGlobalAlarmListsQuery,
  useSetGlobalAlarmListsMutation,
} from "portal/state/portalApi";
import {
  useMutationPopups,
  useQueryPopups,
} from "portal/utils/hooks/useApiPopups";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { without } from "portal/utils/arrays";
import React, { FunctionComponent } from "react";

export const AdminAlarms: FunctionComponent = withAuthenticationRequired(
  function AdminAlarms() {
    const { isInternal } = useSelf();
    const { t } = useTranslation();

    const { data: globalLists } = useQueryPopups(useGetGlobalAlarmListsQuery());
    const [setGlobalAlarmLists, { isLoading }] = useMutationPopups(
      useSetGlobalAlarmListsMutation(),
      {
        success: capitalize(
          t("utils.actions.savedLong", {
            subject: t("views.admin.alarms.lists"),
          })
        ),
      }
    );

    if (!isInternal) {
      return;
    }

    return (
      <div className="max-w-80">
        <Typography variant="h5">{t("views.admin.alarms.title")}</Typography>
        <Alert severity="warning" className="my-4">
          {t("views.admin.alarms.allowWarning")}
        </Alert>
        <ListManager
          list={globalLists?.allowed?.alarms.map((alarm) => alarm.code)}
          loading={isLoading}
          onAdd={(newCode) => {
            setGlobalAlarmLists({
              allowed: {
                alarms: [
                  ...(globalLists?.allowed?.alarms ?? []),
                  GlobalAllowedAlarm.fromPartial({ code: newCode }),
                ],
              },
            });
          }}
          onRemove={(removedCode) => {
            setGlobalAlarmLists({
              allowed: {
                alarms: without(
                  globalLists?.allowed?.alarms,
                  GlobalAllowedAlarm.fromPartial({ code: removedCode }),
                  "code"
                ),
              },
            });
          }}
        />
      </div>
    );
  }
);
