import { FormikContextType, useFormikContext } from "formik";
import { usePrevious } from "@uidotdev/usehooks";
import React, { FunctionComponent, useEffect } from "react";

interface Props {
  onChange: (
    previousFormik: FormikContextType<any> | undefined,
    nextFormik: FormikContextType<any>
  ) => void;
}

export const FormikEffect: FunctionComponent<Props> = ({ onChange }) => {
  const nextFormik = useFormikContext();
  const previousFormik = usePrevious(nextFormik);
  useEffect(() => {
    onChange(previousFormik, nextFormik);
  }, [previousFormik, nextFormik, onChange]);

  return <></>;
};
