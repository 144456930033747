import { Badge, Tooltip } from "@mui/material";
import { classes } from "portal/utils/theme";
import { SPATIAL_DEFAULT } from "portal/utils/spatialMetrics";
import { useTranslation } from "react-i18next";
import LayersIcon from "@mui/icons-material/LayersOutlined";
import React, { FunctionComponent } from "react";

interface Props {
  className?: string;
  open: boolean;
  selectedMetricId: string;
  setOpen: (isOpen: boolean) => void;
}

export const HeatmapControl: FunctionComponent<Props> = ({
  className,
  open,
  selectedMetricId,
  setOpen,
}) => {
  const { t } = useTranslation();
  const dirty = selectedMetricId !== SPATIAL_DEFAULT.id;
  const icon = <LayersIcon className="p-1 mapboxgl-ctrl-icon" />;
  return (
    <Tooltip title={t("components.map.heatmaps.name")} placement="right" arrow>
      <div
        className={classes(
          className,
          "mapboxgl-ctrl mapboxgl-ctrl-group w-fit"
        )}
      >
        <button onClick={() => setOpen(!open)}>
          {dirty ? (
            <Badge
              color="secondary"
              variant="dot"
              classes={{
                badge: "top-2 right-2 border border-white border-solid",
              }}
            >
              {icon}
            </Badge>
          ) : (
            icon
          )}
        </button>
      </div>
    </Tooltip>
  );
};
