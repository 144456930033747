import {
  Alert,
  Button,
  ButtonGroup,
  Divider,
  Drawer,
  Typography,
} from "@mui/material";
import { array, boolean, number, object, string } from "yup";
import {
  BLUE_LOADING_BUTTON,
  BUTTON,
  classes,
  OUTLINED_BUTTON,
  RED_BUTTON,
  TEXT_FIELD,
} from "portal/utils/theme";
import {
  canDeleteReport,
  isNewReport,
  secondsToRange,
} from "portal/utils/reports";
import { capitalize } from "portal/utils/strings";
import {
  CERTIFIED_METRICS,
  getMetricById,
  getMetricName,
} from "portal/utils/certifiedMetrics";
import { CheckboxWithLabel, TextField } from "formik-mui";
import {
  closestCorners,
  DndContext,
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { ConfirmationDialog } from "../ConfirmationDialog";
import { CustomerSelector } from "../customers/CustomerSelector";
import { DailyMetricResponse } from "protos/portal/metrics";
import { FeatureFlag, useFeatureFlag } from "portal/utils/hooks/useFeatureFlag";
import { Field, Form, Formik } from "formik";
import { FormikEffect } from "../FormikEffect";
import { isEqual, values } from "portal/utils/objects";
import { isMetricNumber, Metric, MetricValidation } from "portal/utils/metrics";
import { isUndefined } from "portal/utils/identity";
import { LoadingButton } from "@mui/lab";
import { Measurement } from "../measurement/Measurement";
import { moveItem } from "portal/utils/arrays";
import { Path } from "portal/utils/routing";
import {
  ReportMode,
  reportModeFromJSON,
  ReportResponse,
} from "protos/portal/reports";
import { ReportTools } from "portal/components/reports/ReportTools";
import { SortableContainer } from "portal/components/Sortable";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { SwitchWithLabel } from "../SwitchWithLabel";
import { TFunction } from "i18next";
import {
  useDeleteReportMutation,
  useListReportsQuery,
} from "portal/state/portalApi";
import {
  useMutationPopups,
  useQueryPopups,
} from "portal/utils/hooks/useApiPopups";
import { useNavigate } from "react-router-dom";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/AddOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import React, { FunctionComponent, useMemo, useState } from "react";
import RemoveIcon from "@mui/icons-material/RemoveOutlined";
import SaveIcon from "@mui/icons-material/SaveOutlined";

interface ReportEditorProps {
  report: ReportResponse;
  open?: boolean;
  onChange: (editingReport: ReportResponse) => void;
  onSave: (editingReport: ReportResponse) => void;
  onCancel: () => void;
}

export const ReportEditor: FunctionComponent<ReportEditorProps> = ({
  report,
  open = false,
  onChange,
  onSave,
  onCancel,
}) => {
  const navigate = useNavigate();
  const { isInternal, user } = useSelf();
  const { isEnabled: hasUnvalidatedMetrics } = useFeatureFlag(
    FeatureFlag.UNVALIDATED_METRICS
  );
  const { data: reports, isSuccess: hasFetchedReports } = useQueryPopups(
    useListReportsQuery()
  );

  const { t } = useTranslation();

  const [deleteReport] = useMutationPopups(useDeleteReportMutation(), {
    success: t("utils.actions.deletedLong", {
      subject: capitalize(t("models.reports.report_one")),
    }),
  });
  // confirmation dialog
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  // columns
  const visibleColumns = useMemo<Metric[]>(() => {
    return report.visibleColumns
      .map((id) => getMetricById(id as keyof DailyMetricResponse))
      .filter((metric) => !isUndefined(metric));
  }, [report.visibleColumns]);
  const hiddenColumns = useMemo<Metric[]>(
    () =>
      CERTIFIED_METRICS.filter(
        (metric) => !visibleColumns.some((visible) => visible.id === metric.id)
      ),
    [visibleColumns]
  );

  const [dragging, setDragging] = useState<Metric | undefined>(undefined);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 6,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const setList = (list: Metric[]): void => {
    if (list === visibleColumns) {
      onChange({ ...report, visibleColumns: list.map((metric) => metric.id) });
    }
  };

  const handleDragStart = ({ active }: DragStartEvent): void => {
    const metric = getMetricById(active.id as keyof DailyMetricResponse);
    if (!metric) {
      return;
    }
    setDragging(metric);
  };

  const handleDragEnd = ({ active, over }: DragEndEvent): void => {
    setList(
      moveItem(
        visibleColumns,
        active.data.current?.sortable.index,
        over?.data.current?.sortable.index
      )
    );

    setDragging(undefined);
  };

  const getRenderMetric =
    (t: TFunction, isHidden: boolean = false) =>
    (metric: Metric): JSX.Element => {
      if (
        isHidden &&
        metric.validation === MetricValidation.PENDING &&
        !hasUnvalidatedMetrics &&
        !isInternal
      ) {
        return <></>;
      }
      if (
        isHidden &&
        metric.validation === MetricValidation.INTERNAL &&
        !isInternal
      ) {
        return <></>;
      }
      return (
        <li
          key={metric.id}
          className={classes(
            "group whitespace-nowrap flex items-center rounded-sm",
            {
              "bg-slate-600": metric.id === dragging?.id,
              "italic text-blue-600":
                metric.validation === MetricValidation.PENDING,
              "italic text-orange-200":
                metric.validation === MetricValidation.INTERNAL,
            }
          )}
        >
          <button
            type="button"
            className="flex items-center whitespace-nowrap border-0 bg-transparent text-inherit p-0"
            onClick={() => {
              const newColumns = isHidden
                ? [...visibleColumns, metric]
                : visibleColumns.filter(({ id }) => id !== metric.id);
              onChange({
                ...report,
                visibleColumns: newColumns.map(({ id }) => id),
              });
            }}
          >
            {isHidden ? (
              <AddIcon className="cursor-pointer hover:text-green-700" />
            ) : (
              <RemoveIcon className="cursor-pointer hover:text-red-700" />
            )}
            <strong
              className={classes("text-ellipsis ml-1 text-base", {
                "cursor-row-resize": !isHidden,
                "cursor-pointer": isHidden,
              })}
            >
              {getMetricName(t, metric)}
            </strong>
            {isMetricNumber(metric) && (
              <Measurement
                unitClassName="txt-xs ml-1 opacity-40"
                metric={metric}
                onlyUnits
              />
            )}
          </button>
        </li>
      );
    };

  if (!open) {
    return;
  }

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      classes={{
        paper: classes("h-auto p-4 bottom-0", "top-16", "w-screen md:w-80"),
      }}
    >
      <Formik
        enableReinitialize
        initialValues={{
          name: report.name,
          customerId: report.customerId,
          automateWeekly: report.automateWeekly,
          showAverage: report.showAverage,
          showTotal: report.showTotal,
          mode: report.mode || reportModeFromJSON(ReportMode.REPORT_MODE_DAYS),
          startDate: report.startDate,
          endDate: report.endDate,
          robotIds: report.robotIds,
        }}
        validationSchema={object({
          name: string().required(t("utils.form.required")),
          showAverage: boolean(),
          customerId: number()
            .transform((value) => (Number.isNaN(value) ? undefined : value))
            .nullable(),
          automateWeekly: boolean(),
          showTotal: boolean(),
          mode: number().oneOf(values(ReportMode)),
          startDate: number(),
          endDate: number(),
          robotIds: array().of(number()),
        })}
        onSubmit={async ({ customerId, ...values }) => {
          const result = { ...report, ...values };
          if (!isUndefined(result.customerId)) {
            result.customerId = customerId;
          }
          await onSave(result);
        }}
      >
        {({ submitForm, isSubmitting, values, setFieldValue }) => (
          <Form className={classes("flex flex-col gap-4")}>
            <FormikEffect
              onChange={(previous, next) => {
                if (
                  !previous?.values ||
                  !next.values ||
                  isEqual(previous.values, next.values)
                ) {
                  return;
                }
                onChange({
                  ...report,
                  ...next.values,
                });
              }}
            />
            <div className="flex justify-end gap-2">
              {canDeleteReport(report, user) && (
                <Button
                  {...RED_BUTTON}
                  onClick={() => setConfirmDelete(true)}
                  startIcon={<DeleteIcon />}
                >
                  {t("utils.actions.delete")}
                </Button>
              )}
              {confirmDelete && (
                <ConfirmationDialog
                  title={t("utils.actions.deleteLong", {
                    subject: t("models.reports.report_one"),
                  })}
                  description={t(
                    "components.ConfirmationDialog.delete.description",
                    {
                      subject: report.name,
                    }
                  )}
                  destructive
                  yesText={t("utils.actions.delete", {
                    subject: report.name,
                  })}
                  onClose={() => setConfirmDelete(false)}
                  onYes={async () => {
                    await deleteReport(report.slug);
                    navigate(Path.REPORTS);
                  }}
                />
              )}
              <Button
                variant="text"
                className="text-white"
                onClick={() => onCancel()}
              >
                {isNewReport(report)
                  ? t("utils.actions.discard")
                  : t("utils.actions.cancel")}
              </Button>
              <LoadingButton
                {...BLUE_LOADING_BUTTON}
                disabled={!report}
                loading={isSubmitting}
                onClick={submitForm}
                startIcon={isNewReport(report) ? <AddIcon /> : <SaveIcon />}
              >
                {isNewReport(report)
                  ? t("utils.actions.create")
                  : t("utils.actions.save")}
              </LoadingButton>
            </div>
            <Field
              {...TEXT_FIELD}
              component={TextField}
              label={t("views.reports.scheduled.editor.fields.name")}
              name="name"
              className="text-lg"
            />
            {hasFetchedReports &&
              (() => {
                const duplicateNameCount = reports.filter(
                  ({ name }) => name === values.name
                ).length;
                if (
                  isNewReport(report)
                    ? duplicateNameCount > 0
                    : duplicateNameCount > 1
                ) {
                  return (
                    <Alert severity="warning">
                      {t("views.reports.scheduled.editor.duplicateNames", {
                        count: duplicateNameCount,
                      })}
                    </Alert>
                  );
                }
              })()}
            {isInternal && (
              <CustomerSelector
                value={values.customerId}
                onChange={(customerId) => {
                  setFieldValue("customerId", customerId);
                  setFieldValue("robotIds", []);
                }}
              />
            )}
            {isInternal && (
              <Field
                component={SwitchWithLabel}
                type="checkbox"
                name="automateWeekly"
                label={t(
                  "views.reports.scheduled.editor.fields.automateWeekly"
                )}
              />
            )}
            {!values.automateWeekly && (
              <ReportTools
                dateRange={secondsToRange(values.startDate, values.endDate)}
                direction="vertical"
                customerIds={values.customerId ? [values.customerId] : []}
                selectedRobots={values.robotIds}
                onDateRangeChange={([startDate, endDate]) => {
                  setFieldValue("startDate", startDate?.toUnixInteger());
                  setFieldValue("endDate", endDate?.toUnixInteger());
                }}
                onSelectedRobotsChange={(robotIds) =>
                  setFieldValue("robotIds", robotIds)
                }
              />
            )}
            <Divider />
            <div>
              <Field
                type="checkbox"
                component={CheckboxWithLabel}
                className="text-white"
                name="showAverage"
                Label={{
                  label: t(
                    "views.reports.scheduled.editor.fields.showAverages"
                  ),
                }}
              />
              <Field
                type="checkbox"
                component={CheckboxWithLabel}
                className="text-white"
                name="showTotal"
                Label={{
                  label: t("views.reports.scheduled.editor.fields.showTotals"),
                }}
              />
            </div>
            <Divider />
            <ButtonGroup variant="contained" className="w-full flex">
              <Button
                {...(values.mode ===
                reportModeFromJSON(ReportMode.REPORT_MODE_DAYS)
                  ? BUTTON
                  : OUTLINED_BUTTON)}
                classes={{ root: "w-1/2" }}
                onClick={() => {
                  setFieldValue(
                    "mode",
                    reportModeFromJSON(ReportMode.REPORT_MODE_DAYS)
                  );
                }}
              >
                {t("utils.units.dLong_other")}
              </Button>
              <Button
                {...(values.mode ===
                reportModeFromJSON(ReportMode.REPORT_MODE_JOBS)
                  ? BUTTON
                  : OUTLINED_BUTTON)}
                classes={{ root: "w-1/2" }}
                onClick={() => {
                  setFieldValue(
                    "mode",
                    reportModeFromJSON(ReportMode.REPORT_MODE_JOBS)
                  );
                }}
              >
                {t("models.jobs.job_other")}
              </Button>
            </ButtonGroup>
            <Divider />
            <Typography variant="subtitle2">
              {t("views.reports.scheduled.editor.columnsVisible")}
            </Typography>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCorners}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
            >
              <SortableContainer
                id="visible"
                items={visibleColumns}
                render={getRenderMetric(t, false)}
              />
            </DndContext>
            <Typography variant="subtitle2">
              {t("views.reports.scheduled.editor.columnsHidden")}
            </Typography>
            <ul className="p-0 m-0 list-none">
              {hiddenColumns.map(getRenderMetric(t, true))}
            </ul>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};
