import { classes } from "portal/utils/theme";
import { FacilityType } from "portal/utils/geo";
import { ReactComponent as FarmIcon } from "portal/images/icons/farm.svg";
import CustomerOfficeIcon from "@mui/icons-material/BusinessOutlined";
import HQIcon from "@mui/icons-material/StarOutlined";
import POBoxIcon from "@mui/icons-material/MailOutlined";
import React, { FunctionComponent } from "react";
import ShopIcon from "@mui/icons-material/FactoryOutlined";
import StorageIcon from "@mui/icons-material/WarehouseOutlined";

interface Props {
  className?: string;
  facilityType: FacilityType;
}
export const FacilityTypeIcon: FunctionComponent<Props> = ({
  className = "text-base",
  facilityType,
}) => {
  const baseClass = "h-4 w-4";
  switch (facilityType) {
    case FacilityType.CUSTOMER_OFFICE: {
      return <CustomerOfficeIcon className={classes(baseClass, className)} />;
    }
    case FacilityType.HQ: {
      return <HQIcon className={classes(baseClass, className)} />;
    }
    case FacilityType.PO_BOX: {
      return <POBoxIcon className={classes(baseClass, className)} />;
    }
    case FacilityType.SHOP: {
      return <ShopIcon className={classes(baseClass, className)} />;
    }
    case FacilityType.STORAGE: {
      return <StorageIcon className={classes(baseClass, className)} />;
    }
    case FacilityType.SUPPORT_BASE: {
      return (
        <FarmIcon
          className={classes(baseClass, className)}
          style={{ padding: 1 }}
        />
      );
    }
    default: {
      return <HQIcon className={classes(baseClass, className)} />;
    }
  }
};
