import { classes } from "portal/utils/theme";
import { CornerFlag } from "./CornerFlag";
import { useTranslation } from "react-i18next";
import React, { FunctionComponent } from "react";

export const isProduction =
  typeof window === "undefined"
    ? false
    : window.location.host === "customer.cloud.carbonrobotics.com";
export const isStaging =
  typeof window === "undefined"
    ? false
    : window.location.host === "customer-stg.cloud.carbonrobotics.com";
export const isTest = typeof jest !== "undefined";
export const isDevelopment = !isProduction && !isStaging;

/**
 * Visual flag for upper left corner showing what the current environment is
 */
export const EnvironmentFlag: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <CornerFlag
      label={(() => {
        if (isStaging) {
          return t("components.EnvironmentFlag.beta");
        }
        if (isDevelopment) {
          return t("components.EnvironmentFlag.dev");
        }
        return "";
      })()}
      className={classes("pointer-events-none fixed z-[99999] print:hidden", {
        hidden: isProduction,
        "bg-red-800": isStaging,
        "bg-purple-800": isDevelopment,
      })}
    />
  );
};
