import type { i18n } from "i18next";
import type { PortalLanguage } from "portal/i18nConstants";

export const setLocale = (i18n: i18n, locale: PortalLanguage): void => {
  const documentElement = globalThis.document.documentElement;
  documentElement.lang = locale;

  if (locale !== i18n.language) {
    i18n.changeLanguage(locale);
  }
};
