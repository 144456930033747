import { DefaultStreamChatGenerics, StreamMessage } from "stream-chat-react";
import { isUndefined } from "./identity";
import { values } from "./objects";

export interface StreamToken {
  exp: number;
  iat: number;
  token: string;
  userId: string;
}

export const hasAttachments = <
  T extends StreamMessage<DefaultStreamChatGenerics>["attachments"]
>(
  attachments: T
): attachments is Exclude<T, undefined> => (attachments?.length ?? 0) > 0;

export const hasReactions = <
  T extends StreamMessage<DefaultStreamChatGenerics>["reaction_counts"]
>(
  reactionCounts: T
): reactionCounts is Exclude<T, undefined> =>
  values(reactionCounts ?? {}).some((count) => count > 0);

export const hasMML = <
  T extends StreamMessage<DefaultStreamChatGenerics>["mml"]
>(
  mml: T
): mml is Exclude<T, undefined> => !isUndefined(mml);

export const isBounced = (
  message:
    | Pick<
        StreamMessage<DefaultStreamChatGenerics>,
        "type" | "moderation_details"
      >
    | undefined
): boolean =>
  message?.type === "error" &&
  message.moderation_details?.action === "MESSAGE_RESPONSE_ACTION_BOUNCE";

export const getAuth0UserId = (streamUserId: string): string =>
  streamUserId.replace("_", "|");
