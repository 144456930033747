// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.19.4
// source: portal/customers.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { DB } from "./db";

export const protobufPackage = "carbon.portal.customers";

export interface FeatureFlags {
  reports: boolean;
  almanac: boolean;
  jobs: boolean;
  unvalidatedMetrics: boolean;
  spatial: boolean;
  velocityEstimator: boolean;
  explore: boolean;
}

export interface CustomerResponse {
  db: DB | undefined;
  name: string;
  /** @deprecated */
  sfdcAccountId: string;
  emails: string[];
  featureFlags: FeatureFlags | undefined;
  weeklyReportHour: number;
  weeklyReportDay: number;
  weeklyReportEnabled: boolean;
  weeklyReportLookbackDays: number;
  weeklyReportTimezone: string;
}

function createBaseFeatureFlags(): FeatureFlags {
  return {
    reports: false,
    almanac: false,
    jobs: false,
    unvalidatedMetrics: false,
    spatial: false,
    velocityEstimator: false,
    explore: false,
  };
}

export const FeatureFlags: MessageFns<FeatureFlags> = {
  encode(message: FeatureFlags, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.reports !== false) {
      writer.uint32(8).bool(message.reports);
    }
    if (message.almanac !== false) {
      writer.uint32(16).bool(message.almanac);
    }
    if (message.jobs !== false) {
      writer.uint32(24).bool(message.jobs);
    }
    if (message.unvalidatedMetrics !== false) {
      writer.uint32(32).bool(message.unvalidatedMetrics);
    }
    if (message.spatial !== false) {
      writer.uint32(40).bool(message.spatial);
    }
    if (message.velocityEstimator !== false) {
      writer.uint32(48).bool(message.velocityEstimator);
    }
    if (message.explore !== false) {
      writer.uint32(56).bool(message.explore);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FeatureFlags {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFeatureFlags();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.reports = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.almanac = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.jobs = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.unvalidatedMetrics = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.spatial = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.velocityEstimator = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.explore = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FeatureFlags {
    return {
      reports: isSet(object.reports) ? globalThis.Boolean(object.reports) : false,
      almanac: isSet(object.almanac) ? globalThis.Boolean(object.almanac) : false,
      jobs: isSet(object.jobs) ? globalThis.Boolean(object.jobs) : false,
      unvalidatedMetrics: isSet(object.unvalidatedMetrics) ? globalThis.Boolean(object.unvalidatedMetrics) : false,
      spatial: isSet(object.spatial) ? globalThis.Boolean(object.spatial) : false,
      velocityEstimator: isSet(object.velocityEstimator) ? globalThis.Boolean(object.velocityEstimator) : false,
      explore: isSet(object.explore) ? globalThis.Boolean(object.explore) : false,
    };
  },

  toJSON(message: FeatureFlags): unknown {
    const obj: any = {};
    if (message.reports !== false) {
      obj.reports = message.reports;
    }
    if (message.almanac !== false) {
      obj.almanac = message.almanac;
    }
    if (message.jobs !== false) {
      obj.jobs = message.jobs;
    }
    if (message.unvalidatedMetrics !== false) {
      obj.unvalidatedMetrics = message.unvalidatedMetrics;
    }
    if (message.spatial !== false) {
      obj.spatial = message.spatial;
    }
    if (message.velocityEstimator !== false) {
      obj.velocityEstimator = message.velocityEstimator;
    }
    if (message.explore !== false) {
      obj.explore = message.explore;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FeatureFlags>, I>>(base?: I): FeatureFlags {
    return FeatureFlags.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FeatureFlags>, I>>(object: I): FeatureFlags {
    const message = createBaseFeatureFlags();
    message.reports = object.reports ?? false;
    message.almanac = object.almanac ?? false;
    message.jobs = object.jobs ?? false;
    message.unvalidatedMetrics = object.unvalidatedMetrics ?? false;
    message.spatial = object.spatial ?? false;
    message.velocityEstimator = object.velocityEstimator ?? false;
    message.explore = object.explore ?? false;
    return message;
  },
};

function createBaseCustomerResponse(): CustomerResponse {
  return {
    db: undefined,
    name: "",
    sfdcAccountId: "",
    emails: [],
    featureFlags: undefined,
    weeklyReportHour: 0,
    weeklyReportDay: 0,
    weeklyReportEnabled: false,
    weeklyReportLookbackDays: 0,
    weeklyReportTimezone: "",
  };
}

export const CustomerResponse: MessageFns<CustomerResponse> = {
  encode(message: CustomerResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.db !== undefined) {
      DB.encode(message.db, writer.uint32(10).fork()).join();
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.sfdcAccountId !== "") {
      writer.uint32(26).string(message.sfdcAccountId);
    }
    for (const v of message.emails) {
      writer.uint32(34).string(v!);
    }
    if (message.featureFlags !== undefined) {
      FeatureFlags.encode(message.featureFlags, writer.uint32(42).fork()).join();
    }
    if (message.weeklyReportHour !== 0) {
      writer.uint32(48).int64(message.weeklyReportHour);
    }
    if (message.weeklyReportDay !== 0) {
      writer.uint32(56).int64(message.weeklyReportDay);
    }
    if (message.weeklyReportEnabled !== false) {
      writer.uint32(64).bool(message.weeklyReportEnabled);
    }
    if (message.weeklyReportLookbackDays !== 0) {
      writer.uint32(72).int64(message.weeklyReportLookbackDays);
    }
    if (message.weeklyReportTimezone !== "") {
      writer.uint32(82).string(message.weeklyReportTimezone);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CustomerResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCustomerResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.db = DB.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.sfdcAccountId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.emails.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.featureFlags = FeatureFlags.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.weeklyReportHour = longToNumber(reader.int64());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.weeklyReportDay = longToNumber(reader.int64());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.weeklyReportEnabled = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.weeklyReportLookbackDays = longToNumber(reader.int64());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.weeklyReportTimezone = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CustomerResponse {
    return {
      db: isSet(object.db) ? DB.fromJSON(object.db) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      sfdcAccountId: isSet(object.sfdcAccountId) ? globalThis.String(object.sfdcAccountId) : "",
      emails: globalThis.Array.isArray(object?.emails) ? object.emails.map((e: any) => globalThis.String(e)) : [],
      featureFlags: isSet(object.featureFlags) ? FeatureFlags.fromJSON(object.featureFlags) : undefined,
      weeklyReportHour: isSet(object.weeklyReportHour) ? globalThis.Number(object.weeklyReportHour) : 0,
      weeklyReportDay: isSet(object.weeklyReportDay) ? globalThis.Number(object.weeklyReportDay) : 0,
      weeklyReportEnabled: isSet(object.weeklyReportEnabled) ? globalThis.Boolean(object.weeklyReportEnabled) : false,
      weeklyReportLookbackDays: isSet(object.weeklyReportLookbackDays)
        ? globalThis.Number(object.weeklyReportLookbackDays)
        : 0,
      weeklyReportTimezone: isSet(object.weeklyReportTimezone) ? globalThis.String(object.weeklyReportTimezone) : "",
    };
  },

  toJSON(message: CustomerResponse): unknown {
    const obj: any = {};
    if (message.db !== undefined) {
      obj.db = DB.toJSON(message.db);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.sfdcAccountId !== "") {
      obj.sfdcAccountId = message.sfdcAccountId;
    }
    if (message.emails?.length) {
      obj.emails = message.emails;
    }
    if (message.featureFlags !== undefined) {
      obj.featureFlags = FeatureFlags.toJSON(message.featureFlags);
    }
    if (message.weeklyReportHour !== 0) {
      obj.weeklyReportHour = Math.round(message.weeklyReportHour);
    }
    if (message.weeklyReportDay !== 0) {
      obj.weeklyReportDay = Math.round(message.weeklyReportDay);
    }
    if (message.weeklyReportEnabled !== false) {
      obj.weeklyReportEnabled = message.weeklyReportEnabled;
    }
    if (message.weeklyReportLookbackDays !== 0) {
      obj.weeklyReportLookbackDays = Math.round(message.weeklyReportLookbackDays);
    }
    if (message.weeklyReportTimezone !== "") {
      obj.weeklyReportTimezone = message.weeklyReportTimezone;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CustomerResponse>, I>>(base?: I): CustomerResponse {
    return CustomerResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CustomerResponse>, I>>(object: I): CustomerResponse {
    const message = createBaseCustomerResponse();
    message.db = (object.db !== undefined && object.db !== null) ? DB.fromPartial(object.db) : undefined;
    message.name = object.name ?? "";
    message.sfdcAccountId = object.sfdcAccountId ?? "";
    message.emails = object.emails?.map((e) => e) || [];
    message.featureFlags = (object.featureFlags !== undefined && object.featureFlags !== null)
      ? FeatureFlags.fromPartial(object.featureFlags)
      : undefined;
    message.weeklyReportHour = object.weeklyReportHour ?? 0;
    message.weeklyReportDay = object.weeklyReportDay ?? 0;
    message.weeklyReportEnabled = object.weeklyReportEnabled ?? false;
    message.weeklyReportLookbackDays = object.weeklyReportLookbackDays ?? 0;
    message.weeklyReportTimezone = object.weeklyReportTimezone ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
