import { classes } from "portal/utils/theme";
import { FieldProps } from "formik";
import { fieldToSwitch, SwitchProps } from "formik-mui";
import FormControlLabel from "@mui/material/FormControlLabel";
import MuiSwitch from "@mui/material/Switch";
import React, { FunctionComponent } from "react";

/**
 * Exclude props that are passed directly to the control
 * https://github.com/mui-org/material-ui/blob/v3.1.1/packages/material-ui/src/FormControlLabel/FormControlLabel.js#L71
 */
export interface SwitchWithLabelProps extends FieldProps, SwitchProps {
  className: string;
  label: string;
}

export const SwitchWithLabel: FunctionComponent<SwitchWithLabelProps> = ({
  className,
  label,
  ...props
}) => {
  return (
    <FormControlLabel
      classes={{ label: classes(className) }}
      control={<MuiSwitch {...fieldToSwitch(props)} />}
      label={label}
    />
  );
};

SwitchWithLabel.displayName = "FormikMaterialUISwitchWithLabel";
