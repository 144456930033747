import { BLUE_LOADING_BUTTON, RED_LOADING_BUTTON } from "portal/utils/theme";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import React, { FunctionComponent, ReactNode, useState } from "react";

interface Props {
  description?: string | ReactNode;
  destructive?: boolean;
  onClose: () => void;
  onYes: () => void;
  noText?: string;
  title?: string | ReactNode;
  yesText?: string;
  yesIcon?: ReactNode;
  yesDisabled?: boolean;
}

export const ConfirmationDialog: FunctionComponent<Props> = ({
  description,
  destructive = false,
  onClose,
  onYes,
  noText,
  title,
  yesText,
  yesIcon,
  yesDisabled = false,
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>
        {title ?? t("components.ConfirmationDialog.title")}
      </DialogTitle>
      {description && (
        <DialogContent dividers className="flex flex-col gap-4 items-stretch">
          {description}
        </DialogContent>
      )}
      <DialogActions>
        <Button variant="text" className="text-white" onClick={onClose}>
          {noText ?? t("utils.actions.cancel")}
        </Button>
        <LoadingButton
          {...(destructive ? RED_LOADING_BUTTON : BLUE_LOADING_BUTTON)}
          loading={isLoading}
          disabled={yesDisabled}
          onClick={async () => {
            setLoading(true);
            await onYes();
            setLoading(false);
            onClose();
          }}
          startIcon={yesIcon}
          loadingPosition={yesIcon ? "start" : "center"}
        >
          {yesText ?? t("utils.actions.confirm")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
