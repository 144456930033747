import {
  AlmanacConfig,
  AlmanacTypeCategory,
  CategoryClassification,
  categoryClassificationFromJSON,
  DiscriminatorTypeCategory,
  Formula,
  ModelinatorConfig,
  ModelinatorTypeCategory,
  TypeCategory,
} from "protos/almanac/almanac";
import { ConfigCrop } from "protos/portal/configs";
import { Crop } from "protos/portal/veselka";
import { findWhere } from "./arrays";
import { keys } from "./objects";
import { TFunction } from "i18next";

export enum SIZE_INDEX {
  SMALL = 0,
  MEDIUM = 1,
  LARGE = 2,
}
export const ALL_SIZES = Object.freeze([
  SIZE_INDEX.SMALL,
  SIZE_INDEX.MEDIUM,
  SIZE_INDEX.LARGE,
] as const);

export const WEED_CATEGORY_BROADLEAF = "9fd4e9d0-2d01-4ae1-89c5-f4b4f6de9a3c";
export const WEED_CATEGORY_GRASS = "f032b206-92cf-400a-b79e-e22bdb7930e3";
export const WEED_CATEGORY_OFFSHOOT = "2e678c61-f476-4485-aa9c-6a0e4bc7c626";
export const WEED_CATEGORY_PURSLANE = "1659226a-fe1c-450c-828f-88dc8819c25d";
export const WEED_CATEGORY_BLOSSOM = "bb55614f-44df-4ee0-a754-b891a70961d2";
export const WEED_CATEGORY_PREBLOSSOM = "a0e9ec55-4414-4dd1-bf71-c19a138fedd6";
export const WEED_CATEGORY_FRUIT = "12051fbb-ed5e-4a56-b7a4-a17c7a976550";
export const WEED_CATEGORY_RUNNER = "8fcb6d5a-6c28-4b38-b48b-485178979086";

export const WEED_CATEGORIES = new Set([
  WEED_CATEGORY_BROADLEAF,
  WEED_CATEGORY_GRASS,
  WEED_CATEGORY_OFFSHOOT,
  WEED_CATEGORY_PURSLANE,
  WEED_CATEGORY_BLOSSOM,
  WEED_CATEGORY_PREBLOSSOM,
  WEED_CATEGORY_FRUIT,
  WEED_CATEGORY_RUNNER,
]);

export const SIZE_SMALL_TO_MEDIUM = 8;
export const SIZE_MEDIUM_TO_LARGE = 20;
export const DEFAULT_SIZES = [SIZE_SMALL_TO_MEDIUM, SIZE_MEDIUM_TO_LARGE];
export const DEFAULT_FORMULA = Formula.fromPartial({
  multiplier: 40.63,
  offset: 15,
  exponent: 1,
  fineTuneMultiplier: 5,
  maxTime: 3000,
  fineTuneMultiplierVal: 0.2,
});

export const DEFAULT_CATEGORY = AlmanacTypeCategory.fromPartial({
  type: {
    category: "",
    classification: categoryClassificationFromJSON(
      categoryClassificationFromJSON(CategoryClassification.CATEGORY_WEED)
    ),
  },
  sizes: DEFAULT_SIZES,
  formulas: [
    {
      ...DEFAULT_FORMULA,
      multiplier: 40.63,
    },
    {
      ...DEFAULT_FORMULA,
      multiplier: 56.875,
    },
    {
      ...DEFAULT_FORMULA,
      multiplier: 65,
    },
  ],
});

export const isCategorySynced = (category: AlmanacTypeCategory): boolean => {
  for (const key of keys(DEFAULT_FORMULA)) {
    const values = new Set();
    for (const formula of category.formulas) {
      values.add(formula[key]);
    }
    if (values.size > 1) {
      return false;
    }
  }
  return true;
};

export const getFormulaBySize = (
  category: AlmanacTypeCategory,
  size: SIZE_INDEX
): Formula => {
  const formula = category.formulas[size];
  if (!formula) {
    console.error(`Formula for size ${size} not found`);
    return Formula.fromPartial({});
  }
  return formula;
};

const mergeFormulas = (
  small: Partial<Formula>,
  medium: Partial<Formula>,
  large: Partial<Formula>
): Omit<AlmanacTypeCategory, "type"> => ({
  ...DEFAULT_CATEGORY,
  formulas: [
    { ...getFormulaBySize(DEFAULT_CATEGORY, SIZE_INDEX.SMALL), ...small },
    { ...getFormulaBySize(DEFAULT_CATEGORY, SIZE_INDEX.MEDIUM), ...medium },
    { ...getFormulaBySize(DEFAULT_CATEGORY, SIZE_INDEX.LARGE), ...large },
  ],
});

export const DEFAULT_WEED_BROADLEAF = AlmanacTypeCategory.fromPartial({
  ...DEFAULT_CATEGORY,
  ...mergeFormulas(
    { multiplier: 40.63 },
    { multiplier: 56.875 },
    { multiplier: 65 }
  ),
  type: {
    category: WEED_CATEGORY_BROADLEAF,
    classification: categoryClassificationFromJSON(
      CategoryClassification.CATEGORY_WEED
    ),
  },
});

export const DEFAULT_WEED_GRASS = AlmanacTypeCategory.fromPartial({
  ...DEFAULT_CATEGORY,
  ...mergeFormulas(
    { multiplier: 21.875 },
    { multiplier: 30.625 },
    { multiplier: 35 }
  ),
  type: {
    category: WEED_CATEGORY_GRASS,
    classification: categoryClassificationFromJSON(
      CategoryClassification.CATEGORY_WEED
    ),
  },
});

export const DEFAULT_WEED_OFFSHOOT = AlmanacTypeCategory.fromPartial({
  ...DEFAULT_CATEGORY,
  ...mergeFormulas(
    { multiplier: 37 },
    { multiplier: 42.5 },
    { multiplier: 42 }
  ),
  type: {
    category: WEED_CATEGORY_OFFSHOOT,
    classification: categoryClassificationFromJSON(
      CategoryClassification.CATEGORY_WEED
    ),
  },
});

export const DEFAULT_WEED_PURSLANE = AlmanacTypeCategory.fromPartial({
  ...DEFAULT_CATEGORY,
  ...mergeFormulas(
    { multiplier: 37 },
    { multiplier: 42.5 },
    { multiplier: 42 }
  ),
  type: {
    category: WEED_CATEGORY_PURSLANE,
    classification: categoryClassificationFromJSON(
      CategoryClassification.CATEGORY_WEED
    ),
  },
});

export const getDefaultAlmanac = (t: TFunction): AlmanacConfig =>
  AlmanacConfig.fromPartial({
    name: t("utils.descriptors.default"),
    categories: [
      DEFAULT_WEED_BROADLEAF,
      DEFAULT_WEED_GRASS,
      DEFAULT_WEED_OFFSHOOT,
      DEFAULT_WEED_PURSLANE,
      {
        type: {
          category: "DEFAULT",
          classification: categoryClassificationFromJSON(
            CategoryClassification.CATEGORY_WEED
          ),
        },
        sizes: [8, 20],
        formulas: [
          {
            multiplier: 43.75,
            offset: 15,
            exponent: 1,
            fineTuneMultiplier: 5,
            maxTime: 3000,
            fineTuneMultiplierVal: 0.2,
          },
          {
            multiplier: 61.25,
            offset: 15,
            exponent: 1,
            fineTuneMultiplier: 5,
            maxTime: 3000,
            fineTuneMultiplierVal: 0.2,
          },
          {
            multiplier: 70,
            offset: 15,
            exponent: 1,
            fineTuneMultiplier: 5,
            maxTime: 3000,
            fineTuneMultiplierVal: 0.2,
          },
        ],
      },
    ],
    protected: false,
  });

export const getWeedCategoryName = (t: TFunction, uuid: string): string => {
  switch (uuid) {
    case WEED_CATEGORY_BROADLEAF: {
      return t("models.weeds.categories.broadleaf");
    }
    case WEED_CATEGORY_GRASS: {
      return t("models.weeds.categories.grass");
    }
    case WEED_CATEGORY_OFFSHOOT: {
      return t("models.weeds.categories.offshoot");
    }
    case WEED_CATEGORY_PURSLANE: {
      return t("models.weeds.categories.purslane");
    }
    case WEED_CATEGORY_BLOSSOM: {
      return t("models.weeds.categories.blossom");
    }
    case WEED_CATEGORY_PREBLOSSOM: {
      return t("models.weeds.categories.preblossom");
    }
    case WEED_CATEGORY_FRUIT: {
      return t("models.weeds.categories.fruit");
    }
    case WEED_CATEGORY_RUNNER: {
      return t("models.weeds.categories.runner");
    }
    default: {
      return t("models.weeds.categories.unknown");
    }
  }
};

export const getDefaultWeedCategory = (uuid: string): AlmanacTypeCategory => {
  let newCategory = DEFAULT_CATEGORY;
  switch (uuid) {
    case WEED_CATEGORY_BROADLEAF: {
      newCategory = DEFAULT_WEED_BROADLEAF;
      break;
    }
    case WEED_CATEGORY_GRASS: {
      newCategory = DEFAULT_WEED_GRASS;
      break;
    }
    case WEED_CATEGORY_OFFSHOOT: {
      newCategory = DEFAULT_WEED_OFFSHOOT;
      break;
    }
    case WEED_CATEGORY_PURSLANE: {
      newCategory = DEFAULT_WEED_PURSLANE;
      break;
    }
  }
  return structuredClone(newCategory);
};

export const filterCategories = <
  T extends
    | DiscriminatorTypeCategory
    | AlmanacTypeCategory
    | ModelinatorTypeCategory
>(
  categories?: T[]
): {
  weedCategories: T[];
  cropCategories: T[];
  defaultCategory: T | undefined;
} => {
  const defaultCategory = categories?.find(
    (category) => category.type?.category === "DEFAULT"
  );
  const weedCategories: T[] =
    categories?.filter((category) =>
      WEED_CATEGORIES.has(category.type?.category ?? "")
    ) ?? [];
  const cropCategories: T[] =
    categories?.filter(
      (category) =>
        !WEED_CATEGORIES.has(category.type?.category ?? "") &&
        category !== defaultCategory
    ) ?? [];

  return { weedCategories, cropCategories, defaultCategory };
};

export interface FineTuneMultipliers {
  small: number;
  medium: number;
  large: number;
}

export const getFineTuneMultipliers = (
  category: AlmanacTypeCategory
): FineTuneMultipliers => ({
  small: getFormulaBySize(category, 0).fineTuneMultiplier,
  medium: getFormulaBySize(category, 1).fineTuneMultiplier,
  large: getFormulaBySize(category, 2).fineTuneMultiplier,
});

export const withFineTuneMulipliers = (
  category: AlmanacTypeCategory,
  fineTuneMultipliers: FineTuneMultipliers
): AlmanacTypeCategory => {
  const updated = structuredClone(category);
  getFormulaBySize(updated, 0).fineTuneMultiplier = fineTuneMultipliers.small;
  getFormulaBySize(updated, 1).fineTuneMultiplier = fineTuneMultipliers.medium;
  getFormulaBySize(updated, 2).fineTuneMultiplier = fineTuneMultipliers.large;
  return updated;
};

export const getTypeCategoryTitle = (
  t: TFunction,
  type: TypeCategory | undefined,
  crops: Array<Crop | ConfigCrop> = []
): string => {
  if (!type?.category) {
    return t("models.weeds.categories.unknown");
  }
  if (type.category === "DEFAULT") {
    return t("utils.descriptors.default");
  }

  if (WEED_CATEGORIES.has(type.category)) {
    return getWeedCategoryName(t, type.category);
  }

  const crop = findWhere(crops, { id: type.category });
  return crop?.commonName ?? t("models.crops.categories.unknown");
};

export const getCategoryTitle = (
  t: TFunction,
  category: AlmanacTypeCategory | ModelinatorTypeCategory,
  crops: ConfigCrop[] = []
): string => {
  return getTypeCategoryTitle(t, category.type, crops);
};

export const getModelinatorId = (
  serial: string,
  modelinator: ModelinatorConfig
): string => `${serial}/${modelinator.cropId}-${modelinator.modelId}`;
