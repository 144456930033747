import { Loading } from "portal/components/Loading";
import { Path } from "portal/utils/routing";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import React, { FunctionComponent, useEffect } from "react";

export const Signup: FunctionComponent = () => {
  const navigate = useNavigate();
  const { loginWithRedirect, isAuthenticated, isLoading, error } = useAuth0();

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (isAuthenticated) {
      navigate(Path.BASE);
    } else {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, navigate, loginWithRedirect]);

  if (isLoading) {
    return <Loading failed={Boolean(error)} error={error} overlay />;
  }
};
