import { useTranslation } from "react-i18next";
import React, { FunctionComponent, ReactNode } from "react";
import WarningAmberIcon from "@mui/icons-material/WarningAmberOutlined";

interface Props {
  modelId: string | undefined;
  children: ReactNode;
}

/**
 * Wraps the display of a model id with a warning that appears when a PRT model is used
 */
export const ModelName: FunctionComponent<Props> = ({ modelId, children }) => {
  const { t } = useTranslation();
  const isPrtModel = modelId ? modelId.startsWith("prt-") : false;

  return (
    <div className="grid">
      {children}
      {isPrtModel && (
        <div className="flex items-center text-yellow-200 text-xs font-normal">
          <WarningAmberIcon className="mr-2 text-xs h-full" />
          <span>{t("components.ModelName.warning")}</span>
        </div>
      )}
    </div>
  );
};
