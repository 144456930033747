// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.19.4
// source: portal/veselka.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { DB } from "./db";

export const protobufPackage = "carbon.portal.veselka";

export interface Image {
  id: string;
  location: string;
  robotId: string;
  useCase: string;
  role: string;
  created: number;
  url: string;
  height: number;
  width: number;
  ppi: number;
  valid: number;
  capturedAt: number;
  detectionJson: string;
  reasonJson: string;
  priority: string;
  camId: string;
  rowId: string;
  geoJson: string;
  crop: string;
  imageType: string;
  city: string;
  correctedHw: boolean;
  sessionName: string;
  cropId: string;
  focusMetric: number;
  geohash: string;
  quarantineReason: string;
}

export interface CropConfidence {
  db: DB | undefined;
  robotId: number;
  cropId: string;
  latitude: number;
  longitude: number;
  precision: number;
  numTotalImages: number;
  numRegionalImages: number;
  confidence: string;
}

export interface Crop {
  archived: boolean;
  carbonName: string;
  commonName: string;
  created: number;
  description: string;
  id: string;
  legacyCropName: string;
  notes: string;
  updated: number;
}

export interface RobotCrop {
  crop: Crop | undefined;
  confidence: CropConfidence | undefined;
}

export interface Model {
  id: string;
  created: number;
  updated: number;
  url: string;
  customer: string;
  crop: string;
  version: number;
  trainingDockerTag: string;
  gitSha: string;
  checksum: string;
  location: string;
  trainedAt: number;
  type: string;
  description: string;
  metadataJson: string;
  productionContainerVersion: string;
  testResultsJson: string;
  wandbJson: string;
  snapshotJson: string;
  isStub: boolean;
  isGoodToDeploy: boolean;
  robotName: string;
  environment: string;
  deploy: boolean;
  isPretraining: boolean;
  subType: string;
  datasetId: string;
  containerVersion: string;
  containerId: string;
  pipelineId: string;
  parentModelId: string;
  viableCropIds: string[];
}

function createBaseImage(): Image {
  return {
    id: "",
    location: "",
    robotId: "",
    useCase: "",
    role: "",
    created: 0,
    url: "",
    height: 0,
    width: 0,
    ppi: 0,
    valid: 0,
    capturedAt: 0,
    detectionJson: "",
    reasonJson: "",
    priority: "",
    camId: "",
    rowId: "",
    geoJson: "",
    crop: "",
    imageType: "",
    city: "",
    correctedHw: false,
    sessionName: "",
    cropId: "",
    focusMetric: 0,
    geohash: "",
    quarantineReason: "",
  };
}

export const Image: MessageFns<Image> = {
  encode(message: Image, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.location !== "") {
      writer.uint32(18).string(message.location);
    }
    if (message.robotId !== "") {
      writer.uint32(26).string(message.robotId);
    }
    if (message.useCase !== "") {
      writer.uint32(34).string(message.useCase);
    }
    if (message.role !== "") {
      writer.uint32(42).string(message.role);
    }
    if (message.created !== 0) {
      writer.uint32(48).int64(message.created);
    }
    if (message.url !== "") {
      writer.uint32(58).string(message.url);
    }
    if (message.height !== 0) {
      writer.uint32(64).int64(message.height);
    }
    if (message.width !== 0) {
      writer.uint32(72).int64(message.width);
    }
    if (message.ppi !== 0) {
      writer.uint32(80).int64(message.ppi);
    }
    if (message.valid !== 0) {
      writer.uint32(88).int64(message.valid);
    }
    if (message.capturedAt !== 0) {
      writer.uint32(96).int64(message.capturedAt);
    }
    if (message.detectionJson !== "") {
      writer.uint32(106).string(message.detectionJson);
    }
    if (message.reasonJson !== "") {
      writer.uint32(114).string(message.reasonJson);
    }
    if (message.priority !== "") {
      writer.uint32(122).string(message.priority);
    }
    if (message.camId !== "") {
      writer.uint32(130).string(message.camId);
    }
    if (message.rowId !== "") {
      writer.uint32(138).string(message.rowId);
    }
    if (message.geoJson !== "") {
      writer.uint32(146).string(message.geoJson);
    }
    if (message.crop !== "") {
      writer.uint32(154).string(message.crop);
    }
    if (message.imageType !== "") {
      writer.uint32(162).string(message.imageType);
    }
    if (message.city !== "") {
      writer.uint32(170).string(message.city);
    }
    if (message.correctedHw !== false) {
      writer.uint32(176).bool(message.correctedHw);
    }
    if (message.sessionName !== "") {
      writer.uint32(186).string(message.sessionName);
    }
    if (message.cropId !== "") {
      writer.uint32(194).string(message.cropId);
    }
    if (message.focusMetric !== 0) {
      writer.uint32(200).int64(message.focusMetric);
    }
    if (message.geohash !== "") {
      writer.uint32(210).string(message.geohash);
    }
    if (message.quarantineReason !== "") {
      writer.uint32(218).string(message.quarantineReason);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Image {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.location = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.robotId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.useCase = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.role = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.created = longToNumber(reader.int64());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.url = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.height = longToNumber(reader.int64());
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.width = longToNumber(reader.int64());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.ppi = longToNumber(reader.int64());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.valid = longToNumber(reader.int64());
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.capturedAt = longToNumber(reader.int64());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.detectionJson = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.reasonJson = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.priority = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.camId = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.rowId = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.geoJson = reader.string();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.crop = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.imageType = reader.string();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.city = reader.string();
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.correctedHw = reader.bool();
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.sessionName = reader.string();
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.cropId = reader.string();
          continue;
        case 25:
          if (tag !== 200) {
            break;
          }

          message.focusMetric = longToNumber(reader.int64());
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.geohash = reader.string();
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.quarantineReason = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Image {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      location: isSet(object.location) ? globalThis.String(object.location) : "",
      robotId: isSet(object.robotId) ? globalThis.String(object.robotId) : "",
      useCase: isSet(object.useCase) ? globalThis.String(object.useCase) : "",
      role: isSet(object.role) ? globalThis.String(object.role) : "",
      created: isSet(object.created) ? globalThis.Number(object.created) : 0,
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      height: isSet(object.height) ? globalThis.Number(object.height) : 0,
      width: isSet(object.width) ? globalThis.Number(object.width) : 0,
      ppi: isSet(object.ppi) ? globalThis.Number(object.ppi) : 0,
      valid: isSet(object.valid) ? globalThis.Number(object.valid) : 0,
      capturedAt: isSet(object.capturedAt) ? globalThis.Number(object.capturedAt) : 0,
      detectionJson: isSet(object.detectionJson) ? globalThis.String(object.detectionJson) : "",
      reasonJson: isSet(object.reasonJson) ? globalThis.String(object.reasonJson) : "",
      priority: isSet(object.priority) ? globalThis.String(object.priority) : "",
      camId: isSet(object.camId) ? globalThis.String(object.camId) : "",
      rowId: isSet(object.rowId) ? globalThis.String(object.rowId) : "",
      geoJson: isSet(object.geoJson) ? globalThis.String(object.geoJson) : "",
      crop: isSet(object.crop) ? globalThis.String(object.crop) : "",
      imageType: isSet(object.imageType) ? globalThis.String(object.imageType) : "",
      city: isSet(object.city) ? globalThis.String(object.city) : "",
      correctedHw: isSet(object.correctedHw) ? globalThis.Boolean(object.correctedHw) : false,
      sessionName: isSet(object.sessionName) ? globalThis.String(object.sessionName) : "",
      cropId: isSet(object.cropId) ? globalThis.String(object.cropId) : "",
      focusMetric: isSet(object.focusMetric) ? globalThis.Number(object.focusMetric) : 0,
      geohash: isSet(object.geohash) ? globalThis.String(object.geohash) : "",
      quarantineReason: isSet(object.quarantineReason) ? globalThis.String(object.quarantineReason) : "",
    };
  },

  toJSON(message: Image): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.location !== "") {
      obj.location = message.location;
    }
    if (message.robotId !== "") {
      obj.robotId = message.robotId;
    }
    if (message.useCase !== "") {
      obj.useCase = message.useCase;
    }
    if (message.role !== "") {
      obj.role = message.role;
    }
    if (message.created !== 0) {
      obj.created = Math.round(message.created);
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.height !== 0) {
      obj.height = Math.round(message.height);
    }
    if (message.width !== 0) {
      obj.width = Math.round(message.width);
    }
    if (message.ppi !== 0) {
      obj.ppi = Math.round(message.ppi);
    }
    if (message.valid !== 0) {
      obj.valid = Math.round(message.valid);
    }
    if (message.capturedAt !== 0) {
      obj.capturedAt = Math.round(message.capturedAt);
    }
    if (message.detectionJson !== "") {
      obj.detectionJson = message.detectionJson;
    }
    if (message.reasonJson !== "") {
      obj.reasonJson = message.reasonJson;
    }
    if (message.priority !== "") {
      obj.priority = message.priority;
    }
    if (message.camId !== "") {
      obj.camId = message.camId;
    }
    if (message.rowId !== "") {
      obj.rowId = message.rowId;
    }
    if (message.geoJson !== "") {
      obj.geoJson = message.geoJson;
    }
    if (message.crop !== "") {
      obj.crop = message.crop;
    }
    if (message.imageType !== "") {
      obj.imageType = message.imageType;
    }
    if (message.city !== "") {
      obj.city = message.city;
    }
    if (message.correctedHw !== false) {
      obj.correctedHw = message.correctedHw;
    }
    if (message.sessionName !== "") {
      obj.sessionName = message.sessionName;
    }
    if (message.cropId !== "") {
      obj.cropId = message.cropId;
    }
    if (message.focusMetric !== 0) {
      obj.focusMetric = Math.round(message.focusMetric);
    }
    if (message.geohash !== "") {
      obj.geohash = message.geohash;
    }
    if (message.quarantineReason !== "") {
      obj.quarantineReason = message.quarantineReason;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Image>, I>>(base?: I): Image {
    return Image.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Image>, I>>(object: I): Image {
    const message = createBaseImage();
    message.id = object.id ?? "";
    message.location = object.location ?? "";
    message.robotId = object.robotId ?? "";
    message.useCase = object.useCase ?? "";
    message.role = object.role ?? "";
    message.created = object.created ?? 0;
    message.url = object.url ?? "";
    message.height = object.height ?? 0;
    message.width = object.width ?? 0;
    message.ppi = object.ppi ?? 0;
    message.valid = object.valid ?? 0;
    message.capturedAt = object.capturedAt ?? 0;
    message.detectionJson = object.detectionJson ?? "";
    message.reasonJson = object.reasonJson ?? "";
    message.priority = object.priority ?? "";
    message.camId = object.camId ?? "";
    message.rowId = object.rowId ?? "";
    message.geoJson = object.geoJson ?? "";
    message.crop = object.crop ?? "";
    message.imageType = object.imageType ?? "";
    message.city = object.city ?? "";
    message.correctedHw = object.correctedHw ?? false;
    message.sessionName = object.sessionName ?? "";
    message.cropId = object.cropId ?? "";
    message.focusMetric = object.focusMetric ?? 0;
    message.geohash = object.geohash ?? "";
    message.quarantineReason = object.quarantineReason ?? "";
    return message;
  },
};

function createBaseCropConfidence(): CropConfidence {
  return {
    db: undefined,
    robotId: 0,
    cropId: "",
    latitude: 0,
    longitude: 0,
    precision: 0,
    numTotalImages: 0,
    numRegionalImages: 0,
    confidence: "",
  };
}

export const CropConfidence: MessageFns<CropConfidence> = {
  encode(message: CropConfidence, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.db !== undefined) {
      DB.encode(message.db, writer.uint32(10).fork()).join();
    }
    if (message.robotId !== 0) {
      writer.uint32(16).int64(message.robotId);
    }
    if (message.cropId !== "") {
      writer.uint32(26).string(message.cropId);
    }
    if (message.latitude !== 0) {
      writer.uint32(37).float(message.latitude);
    }
    if (message.longitude !== 0) {
      writer.uint32(45).float(message.longitude);
    }
    if (message.precision !== 0) {
      writer.uint32(48).int64(message.precision);
    }
    if (message.numTotalImages !== 0) {
      writer.uint32(56).int64(message.numTotalImages);
    }
    if (message.numRegionalImages !== 0) {
      writer.uint32(64).int64(message.numRegionalImages);
    }
    if (message.confidence !== "") {
      writer.uint32(74).string(message.confidence);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CropConfidence {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCropConfidence();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.db = DB.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.robotId = longToNumber(reader.int64());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.cropId = reader.string();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.latitude = reader.float();
          continue;
        case 5:
          if (tag !== 45) {
            break;
          }

          message.longitude = reader.float();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.precision = longToNumber(reader.int64());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.numTotalImages = longToNumber(reader.int64());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.numRegionalImages = longToNumber(reader.int64());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.confidence = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CropConfidence {
    return {
      db: isSet(object.db) ? DB.fromJSON(object.db) : undefined,
      robotId: isSet(object.robotId) ? globalThis.Number(object.robotId) : 0,
      cropId: isSet(object.cropId) ? globalThis.String(object.cropId) : "",
      latitude: isSet(object.latitude) ? globalThis.Number(object.latitude) : 0,
      longitude: isSet(object.longitude) ? globalThis.Number(object.longitude) : 0,
      precision: isSet(object.precision) ? globalThis.Number(object.precision) : 0,
      numTotalImages: isSet(object.numTotalImages) ? globalThis.Number(object.numTotalImages) : 0,
      numRegionalImages: isSet(object.numRegionalImages) ? globalThis.Number(object.numRegionalImages) : 0,
      confidence: isSet(object.confidence) ? globalThis.String(object.confidence) : "",
    };
  },

  toJSON(message: CropConfidence): unknown {
    const obj: any = {};
    if (message.db !== undefined) {
      obj.db = DB.toJSON(message.db);
    }
    if (message.robotId !== 0) {
      obj.robotId = Math.round(message.robotId);
    }
    if (message.cropId !== "") {
      obj.cropId = message.cropId;
    }
    if (message.latitude !== 0) {
      obj.latitude = message.latitude;
    }
    if (message.longitude !== 0) {
      obj.longitude = message.longitude;
    }
    if (message.precision !== 0) {
      obj.precision = Math.round(message.precision);
    }
    if (message.numTotalImages !== 0) {
      obj.numTotalImages = Math.round(message.numTotalImages);
    }
    if (message.numRegionalImages !== 0) {
      obj.numRegionalImages = Math.round(message.numRegionalImages);
    }
    if (message.confidence !== "") {
      obj.confidence = message.confidence;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CropConfidence>, I>>(base?: I): CropConfidence {
    return CropConfidence.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CropConfidence>, I>>(object: I): CropConfidence {
    const message = createBaseCropConfidence();
    message.db = (object.db !== undefined && object.db !== null) ? DB.fromPartial(object.db) : undefined;
    message.robotId = object.robotId ?? 0;
    message.cropId = object.cropId ?? "";
    message.latitude = object.latitude ?? 0;
    message.longitude = object.longitude ?? 0;
    message.precision = object.precision ?? 0;
    message.numTotalImages = object.numTotalImages ?? 0;
    message.numRegionalImages = object.numRegionalImages ?? 0;
    message.confidence = object.confidence ?? "";
    return message;
  },
};

function createBaseCrop(): Crop {
  return {
    archived: false,
    carbonName: "",
    commonName: "",
    created: 0,
    description: "",
    id: "",
    legacyCropName: "",
    notes: "",
    updated: 0,
  };
}

export const Crop: MessageFns<Crop> = {
  encode(message: Crop, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.archived !== false) {
      writer.uint32(8).bool(message.archived);
    }
    if (message.carbonName !== "") {
      writer.uint32(18).string(message.carbonName);
    }
    if (message.commonName !== "") {
      writer.uint32(26).string(message.commonName);
    }
    if (message.created !== 0) {
      writer.uint32(32).int64(message.created);
    }
    if (message.description !== "") {
      writer.uint32(42).string(message.description);
    }
    if (message.id !== "") {
      writer.uint32(50).string(message.id);
    }
    if (message.legacyCropName !== "") {
      writer.uint32(58).string(message.legacyCropName);
    }
    if (message.notes !== "") {
      writer.uint32(66).string(message.notes);
    }
    if (message.updated !== 0) {
      writer.uint32(72).int64(message.updated);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Crop {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCrop();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.archived = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.carbonName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.commonName = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.created = longToNumber(reader.int64());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.description = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.id = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.legacyCropName = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.notes = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.updated = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Crop {
    return {
      archived: isSet(object.archived) ? globalThis.Boolean(object.archived) : false,
      carbonName: isSet(object.carbonName) ? globalThis.String(object.carbonName) : "",
      commonName: isSet(object.commonName) ? globalThis.String(object.commonName) : "",
      created: isSet(object.created) ? globalThis.Number(object.created) : 0,
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      legacyCropName: isSet(object.legacyCropName) ? globalThis.String(object.legacyCropName) : "",
      notes: isSet(object.notes) ? globalThis.String(object.notes) : "",
      updated: isSet(object.updated) ? globalThis.Number(object.updated) : 0,
    };
  },

  toJSON(message: Crop): unknown {
    const obj: any = {};
    if (message.archived !== false) {
      obj.archived = message.archived;
    }
    if (message.carbonName !== "") {
      obj.carbonName = message.carbonName;
    }
    if (message.commonName !== "") {
      obj.commonName = message.commonName;
    }
    if (message.created !== 0) {
      obj.created = Math.round(message.created);
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.legacyCropName !== "") {
      obj.legacyCropName = message.legacyCropName;
    }
    if (message.notes !== "") {
      obj.notes = message.notes;
    }
    if (message.updated !== 0) {
      obj.updated = Math.round(message.updated);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Crop>, I>>(base?: I): Crop {
    return Crop.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Crop>, I>>(object: I): Crop {
    const message = createBaseCrop();
    message.archived = object.archived ?? false;
    message.carbonName = object.carbonName ?? "";
    message.commonName = object.commonName ?? "";
    message.created = object.created ?? 0;
    message.description = object.description ?? "";
    message.id = object.id ?? "";
    message.legacyCropName = object.legacyCropName ?? "";
    message.notes = object.notes ?? "";
    message.updated = object.updated ?? 0;
    return message;
  },
};

function createBaseRobotCrop(): RobotCrop {
  return { crop: undefined, confidence: undefined };
}

export const RobotCrop: MessageFns<RobotCrop> = {
  encode(message: RobotCrop, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.crop !== undefined) {
      Crop.encode(message.crop, writer.uint32(10).fork()).join();
    }
    if (message.confidence !== undefined) {
      CropConfidence.encode(message.confidence, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RobotCrop {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRobotCrop();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.crop = Crop.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.confidence = CropConfidence.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RobotCrop {
    return {
      crop: isSet(object.crop) ? Crop.fromJSON(object.crop) : undefined,
      confidence: isSet(object.confidence) ? CropConfidence.fromJSON(object.confidence) : undefined,
    };
  },

  toJSON(message: RobotCrop): unknown {
    const obj: any = {};
    if (message.crop !== undefined) {
      obj.crop = Crop.toJSON(message.crop);
    }
    if (message.confidence !== undefined) {
      obj.confidence = CropConfidence.toJSON(message.confidence);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RobotCrop>, I>>(base?: I): RobotCrop {
    return RobotCrop.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RobotCrop>, I>>(object: I): RobotCrop {
    const message = createBaseRobotCrop();
    message.crop = (object.crop !== undefined && object.crop !== null) ? Crop.fromPartial(object.crop) : undefined;
    message.confidence = (object.confidence !== undefined && object.confidence !== null)
      ? CropConfidence.fromPartial(object.confidence)
      : undefined;
    return message;
  },
};

function createBaseModel(): Model {
  return {
    id: "",
    created: 0,
    updated: 0,
    url: "",
    customer: "",
    crop: "",
    version: 0,
    trainingDockerTag: "",
    gitSha: "",
    checksum: "",
    location: "",
    trainedAt: 0,
    type: "",
    description: "",
    metadataJson: "",
    productionContainerVersion: "",
    testResultsJson: "",
    wandbJson: "",
    snapshotJson: "",
    isStub: false,
    isGoodToDeploy: false,
    robotName: "",
    environment: "",
    deploy: false,
    isPretraining: false,
    subType: "",
    datasetId: "",
    containerVersion: "",
    containerId: "",
    pipelineId: "",
    parentModelId: "",
    viableCropIds: [],
  };
}

export const Model: MessageFns<Model> = {
  encode(message: Model, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.created !== 0) {
      writer.uint32(16).int64(message.created);
    }
    if (message.updated !== 0) {
      writer.uint32(24).int64(message.updated);
    }
    if (message.url !== "") {
      writer.uint32(34).string(message.url);
    }
    if (message.customer !== "") {
      writer.uint32(42).string(message.customer);
    }
    if (message.crop !== "") {
      writer.uint32(50).string(message.crop);
    }
    if (message.version !== 0) {
      writer.uint32(56).int64(message.version);
    }
    if (message.trainingDockerTag !== "") {
      writer.uint32(66).string(message.trainingDockerTag);
    }
    if (message.gitSha !== "") {
      writer.uint32(74).string(message.gitSha);
    }
    if (message.checksum !== "") {
      writer.uint32(82).string(message.checksum);
    }
    if (message.location !== "") {
      writer.uint32(90).string(message.location);
    }
    if (message.trainedAt !== 0) {
      writer.uint32(96).int64(message.trainedAt);
    }
    if (message.type !== "") {
      writer.uint32(106).string(message.type);
    }
    if (message.description !== "") {
      writer.uint32(114).string(message.description);
    }
    if (message.metadataJson !== "") {
      writer.uint32(122).string(message.metadataJson);
    }
    if (message.productionContainerVersion !== "") {
      writer.uint32(130).string(message.productionContainerVersion);
    }
    if (message.testResultsJson !== "") {
      writer.uint32(138).string(message.testResultsJson);
    }
    if (message.wandbJson !== "") {
      writer.uint32(146).string(message.wandbJson);
    }
    if (message.snapshotJson !== "") {
      writer.uint32(154).string(message.snapshotJson);
    }
    if (message.isStub !== false) {
      writer.uint32(160).bool(message.isStub);
    }
    if (message.isGoodToDeploy !== false) {
      writer.uint32(168).bool(message.isGoodToDeploy);
    }
    if (message.robotName !== "") {
      writer.uint32(178).string(message.robotName);
    }
    if (message.environment !== "") {
      writer.uint32(186).string(message.environment);
    }
    if (message.deploy !== false) {
      writer.uint32(192).bool(message.deploy);
    }
    if (message.isPretraining !== false) {
      writer.uint32(200).bool(message.isPretraining);
    }
    if (message.subType !== "") {
      writer.uint32(210).string(message.subType);
    }
    if (message.datasetId !== "") {
      writer.uint32(218).string(message.datasetId);
    }
    if (message.containerVersion !== "") {
      writer.uint32(226).string(message.containerVersion);
    }
    if (message.containerId !== "") {
      writer.uint32(234).string(message.containerId);
    }
    if (message.pipelineId !== "") {
      writer.uint32(242).string(message.pipelineId);
    }
    if (message.parentModelId !== "") {
      writer.uint32(250).string(message.parentModelId);
    }
    for (const v of message.viableCropIds) {
      writer.uint32(258).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Model {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.created = longToNumber(reader.int64());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.updated = longToNumber(reader.int64());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.url = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.customer = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.crop = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.version = longToNumber(reader.int64());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.trainingDockerTag = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.gitSha = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.checksum = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.location = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.trainedAt = longToNumber(reader.int64());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.type = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.description = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.metadataJson = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.productionContainerVersion = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.testResultsJson = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.wandbJson = reader.string();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.snapshotJson = reader.string();
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.isStub = reader.bool();
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.isGoodToDeploy = reader.bool();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.robotName = reader.string();
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.environment = reader.string();
          continue;
        case 24:
          if (tag !== 192) {
            break;
          }

          message.deploy = reader.bool();
          continue;
        case 25:
          if (tag !== 200) {
            break;
          }

          message.isPretraining = reader.bool();
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.subType = reader.string();
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.datasetId = reader.string();
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.containerVersion = reader.string();
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.containerId = reader.string();
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.pipelineId = reader.string();
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.parentModelId = reader.string();
          continue;
        case 32:
          if (tag !== 258) {
            break;
          }

          message.viableCropIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Model {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      created: isSet(object.created) ? globalThis.Number(object.created) : 0,
      updated: isSet(object.updated) ? globalThis.Number(object.updated) : 0,
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      customer: isSet(object.customer) ? globalThis.String(object.customer) : "",
      crop: isSet(object.crop) ? globalThis.String(object.crop) : "",
      version: isSet(object.version) ? globalThis.Number(object.version) : 0,
      trainingDockerTag: isSet(object.trainingDockerTag) ? globalThis.String(object.trainingDockerTag) : "",
      gitSha: isSet(object.gitSha) ? globalThis.String(object.gitSha) : "",
      checksum: isSet(object.checksum) ? globalThis.String(object.checksum) : "",
      location: isSet(object.location) ? globalThis.String(object.location) : "",
      trainedAt: isSet(object.trainedAt) ? globalThis.Number(object.trainedAt) : 0,
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      metadataJson: isSet(object.metadataJson) ? globalThis.String(object.metadataJson) : "",
      productionContainerVersion: isSet(object.productionContainerVersion)
        ? globalThis.String(object.productionContainerVersion)
        : "",
      testResultsJson: isSet(object.testResultsJson) ? globalThis.String(object.testResultsJson) : "",
      wandbJson: isSet(object.wandbJson) ? globalThis.String(object.wandbJson) : "",
      snapshotJson: isSet(object.snapshotJson) ? globalThis.String(object.snapshotJson) : "",
      isStub: isSet(object.isStub) ? globalThis.Boolean(object.isStub) : false,
      isGoodToDeploy: isSet(object.isGoodToDeploy) ? globalThis.Boolean(object.isGoodToDeploy) : false,
      robotName: isSet(object.robotName) ? globalThis.String(object.robotName) : "",
      environment: isSet(object.environment) ? globalThis.String(object.environment) : "",
      deploy: isSet(object.deploy) ? globalThis.Boolean(object.deploy) : false,
      isPretraining: isSet(object.isPretraining) ? globalThis.Boolean(object.isPretraining) : false,
      subType: isSet(object.subType) ? globalThis.String(object.subType) : "",
      datasetId: isSet(object.datasetId) ? globalThis.String(object.datasetId) : "",
      containerVersion: isSet(object.containerVersion) ? globalThis.String(object.containerVersion) : "",
      containerId: isSet(object.containerId) ? globalThis.String(object.containerId) : "",
      pipelineId: isSet(object.pipelineId) ? globalThis.String(object.pipelineId) : "",
      parentModelId: isSet(object.parentModelId) ? globalThis.String(object.parentModelId) : "",
      viableCropIds: globalThis.Array.isArray(object?.viableCropIds)
        ? object.viableCropIds.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: Model): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.created !== 0) {
      obj.created = Math.round(message.created);
    }
    if (message.updated !== 0) {
      obj.updated = Math.round(message.updated);
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.customer !== "") {
      obj.customer = message.customer;
    }
    if (message.crop !== "") {
      obj.crop = message.crop;
    }
    if (message.version !== 0) {
      obj.version = Math.round(message.version);
    }
    if (message.trainingDockerTag !== "") {
      obj.trainingDockerTag = message.trainingDockerTag;
    }
    if (message.gitSha !== "") {
      obj.gitSha = message.gitSha;
    }
    if (message.checksum !== "") {
      obj.checksum = message.checksum;
    }
    if (message.location !== "") {
      obj.location = message.location;
    }
    if (message.trainedAt !== 0) {
      obj.trainedAt = Math.round(message.trainedAt);
    }
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.metadataJson !== "") {
      obj.metadataJson = message.metadataJson;
    }
    if (message.productionContainerVersion !== "") {
      obj.productionContainerVersion = message.productionContainerVersion;
    }
    if (message.testResultsJson !== "") {
      obj.testResultsJson = message.testResultsJson;
    }
    if (message.wandbJson !== "") {
      obj.wandbJson = message.wandbJson;
    }
    if (message.snapshotJson !== "") {
      obj.snapshotJson = message.snapshotJson;
    }
    if (message.isStub !== false) {
      obj.isStub = message.isStub;
    }
    if (message.isGoodToDeploy !== false) {
      obj.isGoodToDeploy = message.isGoodToDeploy;
    }
    if (message.robotName !== "") {
      obj.robotName = message.robotName;
    }
    if (message.environment !== "") {
      obj.environment = message.environment;
    }
    if (message.deploy !== false) {
      obj.deploy = message.deploy;
    }
    if (message.isPretraining !== false) {
      obj.isPretraining = message.isPretraining;
    }
    if (message.subType !== "") {
      obj.subType = message.subType;
    }
    if (message.datasetId !== "") {
      obj.datasetId = message.datasetId;
    }
    if (message.containerVersion !== "") {
      obj.containerVersion = message.containerVersion;
    }
    if (message.containerId !== "") {
      obj.containerId = message.containerId;
    }
    if (message.pipelineId !== "") {
      obj.pipelineId = message.pipelineId;
    }
    if (message.parentModelId !== "") {
      obj.parentModelId = message.parentModelId;
    }
    if (message.viableCropIds?.length) {
      obj.viableCropIds = message.viableCropIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Model>, I>>(base?: I): Model {
    return Model.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Model>, I>>(object: I): Model {
    const message = createBaseModel();
    message.id = object.id ?? "";
    message.created = object.created ?? 0;
    message.updated = object.updated ?? 0;
    message.url = object.url ?? "";
    message.customer = object.customer ?? "";
    message.crop = object.crop ?? "";
    message.version = object.version ?? 0;
    message.trainingDockerTag = object.trainingDockerTag ?? "";
    message.gitSha = object.gitSha ?? "";
    message.checksum = object.checksum ?? "";
    message.location = object.location ?? "";
    message.trainedAt = object.trainedAt ?? 0;
    message.type = object.type ?? "";
    message.description = object.description ?? "";
    message.metadataJson = object.metadataJson ?? "";
    message.productionContainerVersion = object.productionContainerVersion ?? "";
    message.testResultsJson = object.testResultsJson ?? "";
    message.wandbJson = object.wandbJson ?? "";
    message.snapshotJson = object.snapshotJson ?? "";
    message.isStub = object.isStub ?? false;
    message.isGoodToDeploy = object.isGoodToDeploy ?? false;
    message.robotName = object.robotName ?? "";
    message.environment = object.environment ?? "";
    message.deploy = object.deploy ?? false;
    message.isPretraining = object.isPretraining ?? false;
    message.subType = object.subType ?? "";
    message.datasetId = object.datasetId ?? "";
    message.containerVersion = object.containerVersion ?? "";
    message.containerId = object.containerId ?? "";
    message.pipelineId = object.pipelineId ?? "";
    message.parentModelId = object.parentModelId ?? "";
    message.viableCropIds = object.viableCropIds?.map((e) => e) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
