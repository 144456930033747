import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { capitalize, titleCase } from "portal/utils/strings";
import { classes } from "portal/utils/theme";
import {
  DEFAULT_MAP_FILTERS,
  FilterData,
  FilterGroupTypes,
  FilterItemTypes,
  getHiddenItemCount,
  getMapItemCounts,
  isItemFacility,
  isItemRobot,
  showFilterDisplayName,
} from "portal/utils/map";
import { FACILITIES } from "portal/utils/geo";
import { FacilityTypeIcon } from "portal/components/FacilityTypeIcon";
import { getCustomerId } from "portal/utils/auth";
import { keys } from "portal/utils/objects";
import { RobotSummaryResponse } from "protos/portal/robots";
import { RobotTypeIcon } from "portal/components/RobotTypeIcon";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import CheckBoxIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import ClearIcon from "@mui/icons-material/ClearOutlined";
import FilterAltIcon from "@mui/icons-material/FilterAltOutlined";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import React, { Fragment, FunctionComponent, ReactNode } from "react";

interface Props {
  onClose: () => void;
  filters: FilterData<boolean>;
  onChange: (filters: FilterData<boolean>) => void;
  robots: RobotSummaryResponse[];
}

export const FilterPane: FunctionComponent<Props> = ({
  onClose,
  filters,
  onChange,
  robots,
}) => {
  const { isInternal, user } = useSelf();
  const customerId = getCustomerId(user);
  const { t } = useTranslation();

  const facilities = isInternal
    ? FACILITIES
    : FACILITIES.filter((facility) => facility.customerId === customerId);

  const mapItemCounts = getMapItemCounts(facilities, robots);

  const checkboxIcons = {
    icon: <CheckBoxOutlineBlankIcon className="text-black text-lg" />,
    checkedIcon: <CheckBoxIcon className="text-black text-lg" />,
  };

  const handleChange = (
    value: boolean,
    groupName: FilterGroupTypes,
    itemName?: string
  ): void => {
    if (itemName) {
      const updatedFilters = {
        ...filters,
        [groupName]: {
          ...filters[groupName],
          [itemName]: value,
        },
      };
      onChange(updatedFilters);
    } else {
      // handle selection by group
      const updatedFilters = {
        ...filters,
        [groupName]: Object.fromEntries(
          keys(filters[groupName]).map((key) => [key, value])
        ),
      };
      onChange(updatedFilters);
    }
  };

  const renderItemIcon = (
    groupName: FilterGroupTypes,
    itemName: FilterItemTypes
  ): ReactNode => {
    if (isItemFacility(groupName, itemName)) {
      return <FacilityTypeIcon facilityType={itemName} />;
    }
    if (isItemRobot(groupName, itemName)) {
      return <RobotTypeIcon robotType={itemName} className="text-black" />;
    }
    return <></>;
  };

  return (
    <div
      className={classes(
        "absolute top-[10px] left-[50px] z-[3]",
        "text-black bg-white rounded",
        "overflow-x-hidden overflow-y-auto",
        "cursor-default"
      )}
    >
      <div className="flex justify-end">
        <IconButton
          onClick={onClose}
          className="mr-1"
          edge="end"
          size="small"
          disableRipple
        >
          <ClearIcon className="text-sm" />
        </IconButton>
      </div>
      <div className="m-6 mt-0">
        <div className="flex items-center">
          <FilterAltIcon />
          <h3 className="mx-1 my-0">{t("components.map.filters.name")}</h3>
          {getHiddenItemCount(filters) > 0 && (
            <Button
              color="secondary"
              className="ml-2 px-1 py-0"
              onClick={() => {
                onChange(DEFAULT_MAP_FILTERS);
                onClose();
              }}
              variant="outlined"
              size="small"
            >
              {titleCase(
                t("utils.actions.resetLong", {
                  subject: t("components.map.filters.name"),
                })
              )}
            </Button>
          )}
        </div>
        <div>
          {keys(filters).map((groupName, index) => {
            const hiddenItemsInGroupCount = getHiddenItemCount(filters, [
              groupName,
            ]);
            const totalItemCount = Object.keys(filters[groupName]).length;
            const totalItemsInGroup = Object.values(
              mapItemCounts[groupName]
            ).reduce((sum, typeCount) => sum + typeCount);
            return (
              totalItemsInGroup > 0 && (
                <Fragment key={groupName}>
                  <div className=" ml-4">
                    <FormControlLabel
                      className="mb-1"
                      label={
                        <Typography variant="body1" className="font-bold">
                          {capitalize(groupName)}
                          {` (${totalItemsInGroup})`}
                        </Typography>
                      }
                      control={
                        <Checkbox
                          className="p-0 pr-2"
                          checked={hiddenItemsInGroupCount === 0}
                          indeterminate={
                            hiddenItemsInGroupCount > 0 &&
                            hiddenItemsInGroupCount < totalItemCount
                          }
                          onChange={(event_) => {
                            handleChange(event_.target.checked, groupName);
                          }}
                          indeterminateIcon={
                            <IndeterminateCheckBoxIcon className="text-black text-lg" />
                          }
                          {...checkboxIcons}
                          size="small"
                          color="secondary"
                        />
                      }
                    />
                    <div className="flex flex-col ml-3 mb-2">
                      {keys(filters[groupName]).map((itemName) =>
                        mapItemCounts[groupName][itemName] > 0 ? (
                          <FormControlLabel
                            key={`${groupName}-${itemName}`}
                            label={
                              <div className="flex flex-row items-center">
                                {renderItemIcon(groupName, itemName)}
                                <Typography variant="body1" className="ml-1">
                                  {showFilterDisplayName(
                                    t,
                                    isInternal,
                                    groupName,
                                    itemName
                                  )}
                                  {" ("}
                                  {mapItemCounts[groupName][itemName]}
                                  {")"}
                                </Typography>
                              </div>
                            }
                            control={
                              <Checkbox
                                className="p-0 pr-2"
                                checked={filters[groupName][itemName]}
                                onChange={(event_) =>
                                  handleChange(
                                    event_.target.checked,
                                    groupName,
                                    itemName
                                  )
                                }
                                {...checkboxIcons}
                                size="small"
                                color="secondary"
                              />
                            }
                          />
                        ) : undefined
                      )}
                    </div>
                  </div>
                  {index < Object.keys(filters).length - 1 && (
                    <hr className="m-0 mb-1"></hr>
                  )}
                </Fragment>
              )
            );
          })}
        </div>
      </div>
    </div>
  );
};
