import { DEFAULT_LANG, type LanguageMap, NAMESPACE } from "./i18nConstants";
import { initReactI18next } from "react-i18next";
import { isDevelopment, isTest } from "./components/EnvironmentFlag";
import daDK from "localization-cloud/translations/da-DK.json";
import deDE from "localization-cloud/translations/de-DE.json";
import enUS from "localization-cloud/translations/en-US.json";
import esES from "localization-cloud/translations/es-ES.json";
import frFR from "localization-cloud/translations/fr-FR.json";
import i18n, { type ResourceLanguage } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ltLT from "localization-cloud/translations/lt-LT.json";
import nlNL from "localization-cloud/translations/nl-NL.json";
import plPL from "localization-cloud/translations/pl-PL.json";
import roRO from "localization-cloud/translations/ro-RO.json";

// types to use for getDataByLanguage which doesn't have correct types
export interface TranslationRoot {
  translations: Record<string, TranslationData>;
}
type TranslationData = string | { [key: string]: TranslationData };

export const getFromTranslationData = (
  path: string,
  data: TranslationRoot
): TranslationData | undefined => {
  const parts = path.split(".");
  let current: TranslationData = data.translations;
  for (const part of parts) {
    if (!current || typeof current === "string") {
      return;
    }
    const currentPart: TranslationData | undefined = current[part];
    if (!currentPart) {
      return;
    }
    current = currentPart;
  }
  return current;
};

export const RESOURCES: LanguageMap<ResourceLanguage> = {
  "de-DE": { [NAMESPACE]: deDE },
  "en-US": { [NAMESPACE]: enUS },
  "es-ES": { [NAMESPACE]: esES },
  "fr-FR": { [NAMESPACE]: frFR },
  "da-DK": { [NAMESPACE]: daDK },
  "lt-LT": { [NAMESPACE]: ltLT },
  "nl-NL": { [NAMESPACE]: nlNL },
  "pl-PL": { [NAMESPACE]: plPL },
  "ro-RO": { [NAMESPACE]: roRO },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    missingKeyHandler: (language, namespace, key, fallbackValue) => {
      throw new Error(`Key not found ${key}, ${language}, ${fallbackValue}`);
    },
    ns: NAMESPACE,
    resources: RESOURCES,
    fallbackLng: DEFAULT_LANG,
    debug: isDevelopment && !isTest,
    returnEmptyString: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export { default } from "i18next";
