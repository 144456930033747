import { DateRange } from "@mui/x-date-pickers-pro";
import { DateTime } from "luxon";
import { isEqual as isArrayEqual } from "../arrays";
import { isEqual as isObjectEqual } from "../objects";
import { isSameRange } from "../dates";
import { useState } from "react";

/**
 * A set of hooks that "stabilize" complex objects by selectively deep comparing them
 * to prevent unnecessary re-renders.
 */

export const useStable = <T>(input: T, eq: (a: T, b: T) => boolean): T => {
  const [previous, setPrevious] = useState(input);
  if (eq(input, previous)) {
    return previous;
  } else {
    setPrevious(input);
    return input;
  }
};

export const useStableArray = <T>(input: T[]): T[] =>
  useStable(input, isArrayEqual);

export const useStableObject = <T>(input: T): T =>
  useStable(input, isObjectEqual);

export const useStableDateTime = (input: DateTime): DateTime =>
  useStable(input, (a, b) => a.equals(b));

export const useStableDateRange = (
  input: DateRange<DateTime>
): DateRange<DateTime> => useStable(input, isSameRange);
