import { classes, STATUS_BG, STATUS_TEXT } from "portal/utils/theme";
import { IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useUnmountEffect } from "portal/utils/hooks/useUnmountEffect";
import CopyIcon from "@mui/icons-material/ContentCopyOutlined";
import React, { FunctionComponent, useRef, useState } from "react";

/**
 * Copies a value to the user's clipboard
 */
interface Props {
  className?: string;
  text: string;
  disabled?: boolean;
}
export const CopyToClipboardButton: FunctionComponent<Props> = ({
  className,
  text,
  disabled = false,
}) => {
  const { t } = useTranslation();

  // tooltip state
  const [naturallyOpen, setNaturallyOpen] = useState<boolean>(false);
  const [forceOpen, setForceOpen] = useState<boolean>(false);

  // whether the button was clicked since the last time that the tooltip was dismissed
  const [wasCopied, setCopied] = useState<boolean>(false);

  const isMountedRef = useRef<boolean>(true);
  useUnmountEffect(() => {
    isMountedRef.current = false;
  });

  const copyToClipboard = (): void => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    // show success tooltip for 2 seconds
    setForceOpen(true);
    setTimeout(() => {
      if (isMountedRef.current) {
        setForceOpen(false);
      }
    }, 2 * 1000);
  };

  return (
    <Tooltip
      title={
        wasCopied
          ? t("components.CopyToClipboardButton.copied")
          : t("components.CopyToClipboardButton.click")
      }
      open={naturallyOpen || forceOpen}
      onOpen={() => setNaturallyOpen(true)}
      onClose={() => setNaturallyOpen(false)}
      arrow
      classes={{
        tooltip: classes("text-white", {
          [STATUS_BG.GREEN]: wasCopied,
        }),
        arrow: classes({
          [STATUS_TEXT.GREEN]: wasCopied,
        }),
      }}
      TransitionProps={{
        onExited: () => {
          if (isMountedRef.current) {
            setCopied(false);
          }
        },
      }}
    >
      <IconButton
        className={classes("text-sm text-inherit hover:text-white", className)}
        size="small"
        disabled={disabled}
        aria-label={t("components.CopyToClipboardButton.click")}
        onClick={copyToClipboard}
      >
        <CopyIcon style={{ fontSize: "inherit" }} />
      </IconButton>
    </Tooltip>
  );
};
