export const LOCALSTORAGE_ALMANAC_FORMULA = "almanac_formula";
export const LOCALSTORAGE_ALMANAC_MODE = "almanac_mode";
export const LOCALSTORAGE_ALMANAC_SIZES = "almanac_sizes";
export const LOCALSTORAGE_DISCRIMINATOR_MODE = "discriminator_mode";
export const LOCALSTORAGE_DISCRIMINATOR_TRUSTS = "discriminator_trusts";
export const LOCALSTORAGE_DRAWER_EXPANDED = "drawer_expanded";
export const LOCALSTORAGE_MAP_BORDERS = "map_borders";
export const LOCALSTORAGE_MAP_FILTERS = "map_filters";
export const LOCALSTORAGE_MAP_FULLSCREEN = "map_fullscreen";
export const LOCALSTORAGE_MAP_LEGEND = "map_legend";
export const LOCALSTORAGE_MODELINATOR_TRUSTS = "modelinator_trusts";
export const LOCALSTORAGE_SUPPORT_CHAT = "chat";
export const LOCALSTORAGE_USERS_ACTIVATED = "users_activated";
export const LOCALSTORAGE_USERS_CONTRACTORS = "users_contractors";
export const LOCALSTORAGE_USERS_STAFF = "users_staff";
