import { CarbonUploadCard } from "portal/components/CarbonUploadCard";
import { Header } from "portal/components/header/Header";
import { Page } from "portal/components/Page";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { FunctionComponent } from "react";

export const Offline: FunctionComponent = withAuthenticationRequired(
  function Offline() {
    const { t } = useTranslation();
    return (
      <>
        <Header title={t("views.offline.title")} />
        <Page>
          <div className="flex flex-col sm:flex-row gap-10">
            <CarbonUploadCard />
          </div>
        </Page>
      </>
    );
  }
);
