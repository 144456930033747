import { Dispatch, SetStateAction, useMemo, useState } from "react";
import Fuse from "fuse.js";

/**
 * Hook to contain the shared logic for fuzzy searching over a list of items
 * using a Fuse.js index.
 *
 * Includes search field state management for use with <SearchField />
 */

interface FuzzySearchProps<T> {
  items: T[];
  defaultSearchText?: string;
  options?: Fuse.IFuseOptions<T>;
}

interface FuzzySearchState<T> {
  results: T[];
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
}

export const useFuzzySearch = <T>({
  items,
  defaultSearchText = "",
  options,
}: FuzzySearchProps<T>): FuzzySearchState<T> => {
  const [searchText, setSearchText] = useState<string>(defaultSearchText);

  const fuse = useMemo<Fuse<T>>(() => {
    return new Fuse<T>(items, {
      threshold: 0.1,
      shouldSort: true,
      includeMatches: true,
      keys: ["name"],
      ...options,
    });
  }, [items, options]);

  const results = useMemo<T[]>(() => {
    if (searchText.length === 0) {
      return items;
    }
    return fuse.search(searchText).map((result) => result.item);
  }, [fuse, items, searchText]);

  return {
    results,
    searchText,
    setSearchText,
  };
};
