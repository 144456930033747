import { AlarmImpact, AlarmLevel } from "protos/frontend/alarm";
import { AlarmResponse } from "protos/portal/alarms";
import { isNil } from "./identity";
import { TFunction } from "i18next";

export const formatAlarmLevel = (t: TFunction, level: AlarmLevel): string => {
  switch (level) {
    case AlarmLevel.AL_CRITICAL: {
      return t("models.alarms.fields.level.values.critical");
    }
    case AlarmLevel.AL_HIGH: {
      return t("models.alarms.fields.level.values.high");
    }
    case AlarmLevel.AL_MEDIUM: {
      return t("models.alarms.fields.level.values.medium");
    }
    case AlarmLevel.AL_LOW: {
      return t("models.alarms.fields.level.values.low");
    }
    case AlarmLevel.AL_HIDDEN: {
      return t("models.alarms.fields.level.values.hidden");
    }
    default: {
      return t("models.alarms.fields.level.values.unknown");
    }
  }
};

export const formatAlarmImpact = (t: TFunction, level: AlarmImpact): string => {
  switch (level) {
    case AlarmImpact.AI_CRITICAL: {
      return t("models.alarms.fields.impact.values.critical");
    }
    case AlarmImpact.AI_OFFLINE: {
      return t("models.alarms.fields.impact.values.offline");
    }
    case AlarmImpact.AI_DEGRADED: {
      return t("models.alarms.fields.impact.values.degraded");
    }
    case AlarmImpact.AI_NONE: {
      return t("models.alarms.fields.impact.values.none");
    }
    default: {
      return t("models.alarms.fields.impact.values.unknown");
    }
  }
};

export const isOngoing = (alarm?: AlarmResponse): boolean => {
  return alarm
    ? isNil(alarm.endedAt) || alarm.endedAt === Number.POSITIVE_INFINITY
    : false;
};
