import { Admin } from "portal/views/admin/Admin";
import { Auth0Callback } from "portal/components/Auth0Callback";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouteObject,
  RouterProvider,
} from "react-router-dom";
import { CustomerList } from "portal/views/customers/CustomerList";
import { Fleet } from "portal/views/fleet/Fleet";
import { GdprConsent } from "portal/views/GdprConsent";
import {
  getAdminPath,
  getCustomerPath,
  getFleetPath,
  getReportInstancePath,
  getReportInstancesPath,
  getReportPath,
  getRobotPath,
  getUserPath,
  Path,
} from "portal/utils/routing";
import { Knowledge } from "portal/views/Knowledge";
import { Login } from "portal/views/Login";
import { Logout } from "portal/views/Logout";
import { Offline } from "portal/views/Offline";
import { Report } from "portal/views/reports/Report";
import { ReportInstance } from "portal/views/reports/ReportInstance";
import { ReportInstanceList } from "portal/views/reports/ReportInstanceList";
import { Reports } from "portal/views/reports/Reports";
import { Robot } from "portal/views/robots/Robot";
import { Settings } from "portal/views/Settings";
import { Signup } from "portal/views/Signup";
import { User } from "portal/views/users/User";
import { UserList } from "portal/views/users/UserList";
import ErrorBoundary from "portal/components/ErrorBoundary";
import React, {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  useContext,
  useMemo,
} from "react";

// `CarbonRouter` interfaces with React Router while still letting us define
// our providers in a traditional hierarchy, instead of having to define all
// providers underneath the router as part of the route elements themselves.
//
// Descendants of this component should include a React Router `<Outlet />`
// somewhere, like this:
//
//      <CarbonRouter>
//        <SomeOtherProvider>
//          <SomePageChrome>
//            <Outlet />
//          </SomePageChrome>
//        </SomeOtherProvider>
//      </CarbonRouter>
//
// This will be replaced by the main page contents.
export const CarbonRouter: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const router = useMemo(() => makeRouter(), []);
  return (
    <CarbonRouterContext.Provider value={children}>
      <RouterProvider router={router} />
    </CarbonRouterContext.Provider>
  );
};

const CarbonRouterContext = createContext<ReactNode>(<Outlet />);

const CarbonRouterContextLayout = (): ReactNode => {
  return useContext(CarbonRouterContext);
};

const DEFAULT_ROUTE: Pick<RouteObject, "errorElement"> = {
  errorElement: <ErrorBoundary />,
};

export function makeRouter(): ReturnType<typeof createBrowserRouter> {
  return createBrowserRouter([
    {
      ...DEFAULT_ROUTE,
      element: <CarbonRouterContextLayout />,
      children: [
        { ...DEFAULT_ROUTE, path: Path.GDPR_CONSENT, element: <GdprConsent /> },
        { ...DEFAULT_ROUTE, path: Path.SETTINGS, element: <Settings /> },
        { ...DEFAULT_ROUTE, path: Path.CALLBACK, element: <Auth0Callback /> },
        { ...DEFAULT_ROUTE, path: getRobotPath(), element: <Robot /> },
        { ...DEFAULT_ROUTE, path: getFleetPath(), element: <Fleet /> },
        { ...DEFAULT_ROUTE, path: Path.KNOWLEDGE, element: <Knowledge /> },
        { ...DEFAULT_ROUTE, path: Path.LOGIN, element: <Login /> },
        { ...DEFAULT_ROUTE, path: Path.LOGOUT, element: <Logout /> },
        { ...DEFAULT_ROUTE, path: Path.OFFLINE, element: <Offline /> },
        { ...DEFAULT_ROUTE, path: Path.SIGNUP, element: <Signup /> },
        { ...DEFAULT_ROUTE, path: Path.USERS, element: <UserList /> },
        { ...DEFAULT_ROUTE, path: getUserPath(), element: <User /> },
        { ...DEFAULT_ROUTE, path: `${Path.REPORTS}/*`, element: <Reports /> },
        { ...DEFAULT_ROUTE, path: getReportPath(), element: <Report /> },
        {
          ...DEFAULT_ROUTE,
          path: getReportInstancesPath(),
          element: <ReportInstanceList />,
        },
        {
          ...DEFAULT_ROUTE,
          path: getReportInstancePath(),
          element: <ReportInstance />,
        },
        { ...DEFAULT_ROUTE, path: getAdminPath(), element: <Admin /> },
        { ...DEFAULT_ROUTE, path: Path.CUSTOMERS, element: <CustomerList /> },
        {
          ...DEFAULT_ROUTE,
          path: getCustomerPath(),
          element: <CustomerList />,
        },
        // redirect old robots paths
        {
          ...DEFAULT_ROUTE,
          path: "/robots/*",
          element: (
            <>
              {window.location.pathname.startsWith("/robots") && (
                <Navigate
                  to={`${Path.FLEET}${window.location.pathname}`}
                  replace
                />
              )}
            </>
          ),
        },
        {
          ...DEFAULT_ROUTE,
          path: "*",
          element: <Navigate to={Path.FLEET} replace />,
        },
      ],
    },
  ]);
}
