// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.19.4
// source: frontend/weeding_diagnostics.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { GetDimensionsResponse } from "../core/controls/exterminator/controllers/aimbot/process/aimbot";
import { DeepweedOutput } from "../cv/runtime/cv_runtime";
import { DeepweedPredictionRecord, RotaryTicksRecord } from "../recorder/recorder";
import { ConfigDefinition } from "../thinning/thinning";
import { Timestamp } from "../util/util";
import { DiagnosticsSnapshot, TrajectorySnapshot } from "../weed_tracking/weed_tracking";
import { Annotations } from "./image_stream";

export const protobufPackage = "carbon.frontend.weeding_diagnostics";

export enum UploadState {
  NONE = 0,
  IN_PROGRESS = 1,
  DONE = 2,
  UNRECOGNIZED = -1,
}

export function uploadStateFromJSON(object: any): UploadState {
  switch (object) {
    case 0:
    case "NONE":
      return UploadState.NONE;
    case 1:
    case "IN_PROGRESS":
      return UploadState.IN_PROGRESS;
    case 2:
    case "DONE":
      return UploadState.DONE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UploadState.UNRECOGNIZED;
  }
}

export function uploadStateToJSON(object: UploadState): string {
  switch (object) {
    case UploadState.NONE:
      return "NONE";
    case UploadState.IN_PROGRESS:
      return "IN_PROGRESS";
    case UploadState.DONE:
      return "DONE";
    case UploadState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface RecordWeedingDiagnosticsRequest {
  name: string;
  ttlSec: number;
  cropImagesPerSec: number;
  weedImagesPerSec: number;
}

export interface GetCurrentTrajectoriesRequest {
  rowId: number;
}

export interface GetRecordingsListRequest {
}

export interface GetRecordingsListResponse {
  name: string[];
}

export interface GetSnapshotRequest {
  recordingName: string;
  rowId: number;
  snapshotNumber: number;
}

export interface GetSnapshotResponse {
  snapshot: DiagnosticsSnapshot | undefined;
}

export interface OpenRecordingRequest {
  recordingName: string;
}

export interface TrajectoriesWithImages {
  ids: number[];
}

export interface PredictImages {
  names: string[];
}

export interface PredictImagesPerCam {
  images: { [key: number]: PredictImages };
}

export interface PredictImagesPerCam_ImagesEntry {
  key: number;
  value: PredictImages | undefined;
}

export interface OpenRecordingResponse {
  numSnapshotsPerRow: { [key: number]: number };
  recordingData: StaticRecordingData | undefined;
  trajectoryImagesPerRow: { [key: number]: TrajectoriesWithImages };
  predictImagesPerRow: { [key: number]: PredictImagesPerCam };
}

export interface OpenRecordingResponse_NumSnapshotsPerRowEntry {
  key: number;
  value: number;
}

export interface OpenRecordingResponse_TrajectoryImagesPerRowEntry {
  key: number;
  value: TrajectoriesWithImages | undefined;
}

export interface OpenRecordingResponse_PredictImagesPerRowEntry {
  key: number;
  value: PredictImagesPerCam | undefined;
}

export interface DeleteRecordingRequest {
  recordingName: string;
}

export interface ConfigNodeSnapshot {
  values: { [key: string]: string };
  childNodes: { [key: string]: ConfigNodeSnapshot };
}

export interface ConfigNodeSnapshot_ValuesEntry {
  key: string;
  value: string;
}

export interface ConfigNodeSnapshot_ChildNodesEntry {
  key: string;
  value: ConfigNodeSnapshot | undefined;
}

export interface CameraDimensions {
  width: number;
  height: number;
}

export interface RowCameras {
  cams: { [key: string]: CameraDimensions };
}

export interface RowCameras_CamsEntry {
  key: string;
  value: CameraDimensions | undefined;
}

export interface StaticRecordingData {
  lasersEnabled: { [key: string]: boolean };
  rootConfig: ConfigNodeSnapshot | undefined;
  rowsRecorded: number[];
  rowDimensions: { [key: number]: GetDimensionsResponse };
  cropSafetyRadiusMmPerRow: { [key: number]: number };
  thinningConfig: ConfigDefinition | undefined;
  recordingTimestamp: number;
  rowCameras: { [key: number]: RowCameras };
  weedPointThreshold: number;
  cropPointThreshold: number;
  wheelDiameterBackLeftIn: number;
  wheelDiameterBackRightIn: number;
  wheelDiameterFrontLeftIn: number;
  wheelDiameterFrontRightIn: number;
}

export interface StaticRecordingData_LasersEnabledEntry {
  key: string;
  value: boolean;
}

export interface StaticRecordingData_RowDimensionsEntry {
  key: number;
  value: GetDimensionsResponse | undefined;
}

export interface StaticRecordingData_CropSafetyRadiusMmPerRowEntry {
  key: number;
  value: number;
}

export interface StaticRecordingData_RowCamerasEntry {
  key: number;
  value: RowCameras | undefined;
}

export interface GetTrajectoryDataRequest {
  recordingName: string;
  rowId: number;
  trajectoryId: number;
}

export interface LastSnapshot {
  trajectory: TrajectorySnapshot | undefined;
  diagnosticsSnapshotNumber: number;
}

export interface ExtraTrajectoryField {
  label: string;
  value: string;
  color: string;
}

export interface TargetImage {
  name: string;
  timestamp: number;
  p2pPredictX: number;
  p2pPredictY: number;
}

export interface TrajectoryPredictImageMetadata {
  centerXPx: number;
  centerYPx: number;
  radiusPx: number;
  ts: Timestamp | undefined;
  pcamId: string;
}

export interface TrajectoryData {
  predictImage: TrajectoryPredictImageMetadata | undefined;
  targetImages: TargetImage[];
  lastSnapshot: LastSnapshot | undefined;
  extraFields: ExtraTrajectoryField[];
  crosshairX: number;
  crosshairY: number;
}

export interface GetTrajectoryPredictImageRequest {
  recordingName: string;
  rowId: number;
  trajectoryId: number;
}

export interface GetTrajectoryTargetImageRequest {
  recordingName: string;
  rowId: number;
  trajectoryId: number;
  imageName: string;
}

export interface ImageChunk {
  imageChunk: Uint8Array;
}

export interface GetPredictImageMetadataRequest {
  recordingName: string;
  rowId: number;
  imageName: string;
}

export interface GetPredictImageMetadataResponse {
  ts: Timestamp | undefined;
  annotations: Annotations | undefined;
  deepweedOutput: DeepweedOutput | undefined;
  deepweedOutputBelowThreshold: DeepweedOutput | undefined;
}

export interface GetPredictImageRequest {
  recordingName: string;
  rowId: number;
  imageName: string;
}

export interface StartUploadRequest {
  recordingName: string;
}

export interface GetNextUploadStateRequest {
  recordingName: string;
  ts: Timestamp | undefined;
}

export interface GetNextUploadStateResponse {
  uploadState: UploadState;
  ts: Timestamp | undefined;
  percentUploaded: number;
}

export interface GetDeepweedPredictionsCountRequest {
  recordingName: string;
  row: number;
  camId: number;
}

export interface GetDeepweedPredictionsCountResponse {
  count: number;
}

export interface GetDeepweedPredictionsRequest {
  recordingName: string;
  row: number;
  camId: number;
  idx: number;
}

export interface GetDeepweedPredictionsResponse {
  predictions: DeepweedPredictionRecord | undefined;
}

export interface FindTrajectoryRequest {
  recordingName: string;
  rowId: number;
  trajectoryId: number;
}

export interface FindTrajectoryResponse {
  snapshotId: number;
  trajectory: TrajectorySnapshot | undefined;
}

export interface GetRotaryTicksRequest {
  recordingName: string;
  rowId: number;
}

export interface GetRotaryTicksResponse {
  records: RotaryTicksRecord[];
  wheelEncoderResolution: number;
}

function createBaseRecordWeedingDiagnosticsRequest(): RecordWeedingDiagnosticsRequest {
  return { name: "", ttlSec: 0, cropImagesPerSec: 0, weedImagesPerSec: 0 };
}

export const RecordWeedingDiagnosticsRequest: MessageFns<RecordWeedingDiagnosticsRequest> = {
  encode(message: RecordWeedingDiagnosticsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.ttlSec !== 0) {
      writer.uint32(16).uint32(message.ttlSec);
    }
    if (message.cropImagesPerSec !== 0) {
      writer.uint32(29).float(message.cropImagesPerSec);
    }
    if (message.weedImagesPerSec !== 0) {
      writer.uint32(37).float(message.weedImagesPerSec);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RecordWeedingDiagnosticsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRecordWeedingDiagnosticsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.ttlSec = reader.uint32();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.cropImagesPerSec = reader.float();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.weedImagesPerSec = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RecordWeedingDiagnosticsRequest {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      ttlSec: isSet(object.ttlSec) ? globalThis.Number(object.ttlSec) : 0,
      cropImagesPerSec: isSet(object.cropImagesPerSec) ? globalThis.Number(object.cropImagesPerSec) : 0,
      weedImagesPerSec: isSet(object.weedImagesPerSec) ? globalThis.Number(object.weedImagesPerSec) : 0,
    };
  },

  toJSON(message: RecordWeedingDiagnosticsRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.ttlSec !== 0) {
      obj.ttlSec = Math.round(message.ttlSec);
    }
    if (message.cropImagesPerSec !== 0) {
      obj.cropImagesPerSec = message.cropImagesPerSec;
    }
    if (message.weedImagesPerSec !== 0) {
      obj.weedImagesPerSec = message.weedImagesPerSec;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RecordWeedingDiagnosticsRequest>, I>>(base?: I): RecordWeedingDiagnosticsRequest {
    return RecordWeedingDiagnosticsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RecordWeedingDiagnosticsRequest>, I>>(
    object: I,
  ): RecordWeedingDiagnosticsRequest {
    const message = createBaseRecordWeedingDiagnosticsRequest();
    message.name = object.name ?? "";
    message.ttlSec = object.ttlSec ?? 0;
    message.cropImagesPerSec = object.cropImagesPerSec ?? 0;
    message.weedImagesPerSec = object.weedImagesPerSec ?? 0;
    return message;
  },
};

function createBaseGetCurrentTrajectoriesRequest(): GetCurrentTrajectoriesRequest {
  return { rowId: 0 };
}

export const GetCurrentTrajectoriesRequest: MessageFns<GetCurrentTrajectoriesRequest> = {
  encode(message: GetCurrentTrajectoriesRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.rowId !== 0) {
      writer.uint32(8).uint32(message.rowId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetCurrentTrajectoriesRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCurrentTrajectoriesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.rowId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCurrentTrajectoriesRequest {
    return { rowId: isSet(object.rowId) ? globalThis.Number(object.rowId) : 0 };
  },

  toJSON(message: GetCurrentTrajectoriesRequest): unknown {
    const obj: any = {};
    if (message.rowId !== 0) {
      obj.rowId = Math.round(message.rowId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCurrentTrajectoriesRequest>, I>>(base?: I): GetCurrentTrajectoriesRequest {
    return GetCurrentTrajectoriesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCurrentTrajectoriesRequest>, I>>(
    object: I,
  ): GetCurrentTrajectoriesRequest {
    const message = createBaseGetCurrentTrajectoriesRequest();
    message.rowId = object.rowId ?? 0;
    return message;
  },
};

function createBaseGetRecordingsListRequest(): GetRecordingsListRequest {
  return {};
}

export const GetRecordingsListRequest: MessageFns<GetRecordingsListRequest> = {
  encode(_: GetRecordingsListRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetRecordingsListRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRecordingsListRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetRecordingsListRequest {
    return {};
  },

  toJSON(_: GetRecordingsListRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetRecordingsListRequest>, I>>(base?: I): GetRecordingsListRequest {
    return GetRecordingsListRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetRecordingsListRequest>, I>>(_: I): GetRecordingsListRequest {
    const message = createBaseGetRecordingsListRequest();
    return message;
  },
};

function createBaseGetRecordingsListResponse(): GetRecordingsListResponse {
  return { name: [] };
}

export const GetRecordingsListResponse: MessageFns<GetRecordingsListResponse> = {
  encode(message: GetRecordingsListResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.name) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetRecordingsListResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRecordingsListResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetRecordingsListResponse {
    return { name: globalThis.Array.isArray(object?.name) ? object.name.map((e: any) => globalThis.String(e)) : [] };
  },

  toJSON(message: GetRecordingsListResponse): unknown {
    const obj: any = {};
    if (message.name?.length) {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetRecordingsListResponse>, I>>(base?: I): GetRecordingsListResponse {
    return GetRecordingsListResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetRecordingsListResponse>, I>>(object: I): GetRecordingsListResponse {
    const message = createBaseGetRecordingsListResponse();
    message.name = object.name?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetSnapshotRequest(): GetSnapshotRequest {
  return { recordingName: "", rowId: 0, snapshotNumber: 0 };
}

export const GetSnapshotRequest: MessageFns<GetSnapshotRequest> = {
  encode(message: GetSnapshotRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.recordingName !== "") {
      writer.uint32(10).string(message.recordingName);
    }
    if (message.rowId !== 0) {
      writer.uint32(16).uint32(message.rowId);
    }
    if (message.snapshotNumber !== 0) {
      writer.uint32(24).uint32(message.snapshotNumber);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetSnapshotRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSnapshotRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recordingName = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.rowId = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.snapshotNumber = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSnapshotRequest {
    return {
      recordingName: isSet(object.recordingName) ? globalThis.String(object.recordingName) : "",
      rowId: isSet(object.rowId) ? globalThis.Number(object.rowId) : 0,
      snapshotNumber: isSet(object.snapshotNumber) ? globalThis.Number(object.snapshotNumber) : 0,
    };
  },

  toJSON(message: GetSnapshotRequest): unknown {
    const obj: any = {};
    if (message.recordingName !== "") {
      obj.recordingName = message.recordingName;
    }
    if (message.rowId !== 0) {
      obj.rowId = Math.round(message.rowId);
    }
    if (message.snapshotNumber !== 0) {
      obj.snapshotNumber = Math.round(message.snapshotNumber);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSnapshotRequest>, I>>(base?: I): GetSnapshotRequest {
    return GetSnapshotRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSnapshotRequest>, I>>(object: I): GetSnapshotRequest {
    const message = createBaseGetSnapshotRequest();
    message.recordingName = object.recordingName ?? "";
    message.rowId = object.rowId ?? 0;
    message.snapshotNumber = object.snapshotNumber ?? 0;
    return message;
  },
};

function createBaseGetSnapshotResponse(): GetSnapshotResponse {
  return { snapshot: undefined };
}

export const GetSnapshotResponse: MessageFns<GetSnapshotResponse> = {
  encode(message: GetSnapshotResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.snapshot !== undefined) {
      DiagnosticsSnapshot.encode(message.snapshot, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetSnapshotResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSnapshotResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.snapshot = DiagnosticsSnapshot.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSnapshotResponse {
    return { snapshot: isSet(object.snapshot) ? DiagnosticsSnapshot.fromJSON(object.snapshot) : undefined };
  },

  toJSON(message: GetSnapshotResponse): unknown {
    const obj: any = {};
    if (message.snapshot !== undefined) {
      obj.snapshot = DiagnosticsSnapshot.toJSON(message.snapshot);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSnapshotResponse>, I>>(base?: I): GetSnapshotResponse {
    return GetSnapshotResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSnapshotResponse>, I>>(object: I): GetSnapshotResponse {
    const message = createBaseGetSnapshotResponse();
    message.snapshot = (object.snapshot !== undefined && object.snapshot !== null)
      ? DiagnosticsSnapshot.fromPartial(object.snapshot)
      : undefined;
    return message;
  },
};

function createBaseOpenRecordingRequest(): OpenRecordingRequest {
  return { recordingName: "" };
}

export const OpenRecordingRequest: MessageFns<OpenRecordingRequest> = {
  encode(message: OpenRecordingRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.recordingName !== "") {
      writer.uint32(10).string(message.recordingName);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): OpenRecordingRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOpenRecordingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recordingName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OpenRecordingRequest {
    return { recordingName: isSet(object.recordingName) ? globalThis.String(object.recordingName) : "" };
  },

  toJSON(message: OpenRecordingRequest): unknown {
    const obj: any = {};
    if (message.recordingName !== "") {
      obj.recordingName = message.recordingName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OpenRecordingRequest>, I>>(base?: I): OpenRecordingRequest {
    return OpenRecordingRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OpenRecordingRequest>, I>>(object: I): OpenRecordingRequest {
    const message = createBaseOpenRecordingRequest();
    message.recordingName = object.recordingName ?? "";
    return message;
  },
};

function createBaseTrajectoriesWithImages(): TrajectoriesWithImages {
  return { ids: [] };
}

export const TrajectoriesWithImages: MessageFns<TrajectoriesWithImages> = {
  encode(message: TrajectoriesWithImages, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    writer.uint32(10).fork();
    for (const v of message.ids) {
      writer.uint32(v);
    }
    writer.join();
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TrajectoriesWithImages {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrajectoriesWithImages();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.ids.push(reader.uint32());

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.ids.push(reader.uint32());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TrajectoriesWithImages {
    return { ids: globalThis.Array.isArray(object?.ids) ? object.ids.map((e: any) => globalThis.Number(e)) : [] };
  },

  toJSON(message: TrajectoriesWithImages): unknown {
    const obj: any = {};
    if (message.ids?.length) {
      obj.ids = message.ids.map((e) => Math.round(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TrajectoriesWithImages>, I>>(base?: I): TrajectoriesWithImages {
    return TrajectoriesWithImages.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TrajectoriesWithImages>, I>>(object: I): TrajectoriesWithImages {
    const message = createBaseTrajectoriesWithImages();
    message.ids = object.ids?.map((e) => e) || [];
    return message;
  },
};

function createBasePredictImages(): PredictImages {
  return { names: [] };
}

export const PredictImages: MessageFns<PredictImages> = {
  encode(message: PredictImages, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.names) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PredictImages {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePredictImages();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.names.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PredictImages {
    return { names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [] };
  },

  toJSON(message: PredictImages): unknown {
    const obj: any = {};
    if (message.names?.length) {
      obj.names = message.names;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PredictImages>, I>>(base?: I): PredictImages {
    return PredictImages.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PredictImages>, I>>(object: I): PredictImages {
    const message = createBasePredictImages();
    message.names = object.names?.map((e) => e) || [];
    return message;
  },
};

function createBasePredictImagesPerCam(): PredictImagesPerCam {
  return { images: {} };
}

export const PredictImagesPerCam: MessageFns<PredictImagesPerCam> = {
  encode(message: PredictImagesPerCam, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    Object.entries(message.images).forEach(([key, value]) => {
      PredictImagesPerCam_ImagesEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PredictImagesPerCam {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePredictImagesPerCam();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = PredictImagesPerCam_ImagesEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.images[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PredictImagesPerCam {
    return {
      images: isObject(object.images)
        ? Object.entries(object.images).reduce<{ [key: number]: PredictImages }>((acc, [key, value]) => {
          acc[globalThis.Number(key)] = PredictImages.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: PredictImagesPerCam): unknown {
    const obj: any = {};
    if (message.images) {
      const entries = Object.entries(message.images);
      if (entries.length > 0) {
        obj.images = {};
        entries.forEach(([k, v]) => {
          obj.images[k] = PredictImages.toJSON(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PredictImagesPerCam>, I>>(base?: I): PredictImagesPerCam {
    return PredictImagesPerCam.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PredictImagesPerCam>, I>>(object: I): PredictImagesPerCam {
    const message = createBasePredictImagesPerCam();
    message.images = Object.entries(object.images ?? {}).reduce<{ [key: number]: PredictImages }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[globalThis.Number(key)] = PredictImages.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBasePredictImagesPerCam_ImagesEntry(): PredictImagesPerCam_ImagesEntry {
  return { key: 0, value: undefined };
}

export const PredictImagesPerCam_ImagesEntry: MessageFns<PredictImagesPerCam_ImagesEntry> = {
  encode(message: PredictImagesPerCam_ImagesEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== 0) {
      writer.uint32(8).int32(message.key);
    }
    if (message.value !== undefined) {
      PredictImages.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PredictImagesPerCam_ImagesEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePredictImagesPerCam_ImagesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.key = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = PredictImages.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PredictImagesPerCam_ImagesEntry {
    return {
      key: isSet(object.key) ? globalThis.Number(object.key) : 0,
      value: isSet(object.value) ? PredictImages.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: PredictImagesPerCam_ImagesEntry): unknown {
    const obj: any = {};
    if (message.key !== 0) {
      obj.key = Math.round(message.key);
    }
    if (message.value !== undefined) {
      obj.value = PredictImages.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PredictImagesPerCam_ImagesEntry>, I>>(base?: I): PredictImagesPerCam_ImagesEntry {
    return PredictImagesPerCam_ImagesEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PredictImagesPerCam_ImagesEntry>, I>>(
    object: I,
  ): PredictImagesPerCam_ImagesEntry {
    const message = createBasePredictImagesPerCam_ImagesEntry();
    message.key = object.key ?? 0;
    message.value = (object.value !== undefined && object.value !== null)
      ? PredictImages.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseOpenRecordingResponse(): OpenRecordingResponse {
  return { numSnapshotsPerRow: {}, recordingData: undefined, trajectoryImagesPerRow: {}, predictImagesPerRow: {} };
}

export const OpenRecordingResponse: MessageFns<OpenRecordingResponse> = {
  encode(message: OpenRecordingResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    Object.entries(message.numSnapshotsPerRow).forEach(([key, value]) => {
      OpenRecordingResponse_NumSnapshotsPerRowEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).join();
    });
    if (message.recordingData !== undefined) {
      StaticRecordingData.encode(message.recordingData, writer.uint32(18).fork()).join();
    }
    Object.entries(message.trajectoryImagesPerRow).forEach(([key, value]) => {
      OpenRecordingResponse_TrajectoryImagesPerRowEntry.encode({ key: key as any, value }, writer.uint32(26).fork())
        .join();
    });
    Object.entries(message.predictImagesPerRow).forEach(([key, value]) => {
      OpenRecordingResponse_PredictImagesPerRowEntry.encode({ key: key as any, value }, writer.uint32(34).fork())
        .join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): OpenRecordingResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOpenRecordingResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = OpenRecordingResponse_NumSnapshotsPerRowEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.numSnapshotsPerRow[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.recordingData = StaticRecordingData.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = OpenRecordingResponse_TrajectoryImagesPerRowEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.trajectoryImagesPerRow[entry3.key] = entry3.value;
          }
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          const entry4 = OpenRecordingResponse_PredictImagesPerRowEntry.decode(reader, reader.uint32());
          if (entry4.value !== undefined) {
            message.predictImagesPerRow[entry4.key] = entry4.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OpenRecordingResponse {
    return {
      numSnapshotsPerRow: isObject(object.numSnapshotsPerRow)
        ? Object.entries(object.numSnapshotsPerRow).reduce<{ [key: number]: number }>((acc, [key, value]) => {
          acc[globalThis.Number(key)] = Number(value);
          return acc;
        }, {})
        : {},
      recordingData: isSet(object.recordingData) ? StaticRecordingData.fromJSON(object.recordingData) : undefined,
      trajectoryImagesPerRow: isObject(object.trajectoryImagesPerRow)
        ? Object.entries(object.trajectoryImagesPerRow).reduce<{ [key: number]: TrajectoriesWithImages }>(
          (acc, [key, value]) => {
            acc[globalThis.Number(key)] = TrajectoriesWithImages.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
      predictImagesPerRow: isObject(object.predictImagesPerRow)
        ? Object.entries(object.predictImagesPerRow).reduce<{ [key: number]: PredictImagesPerCam }>(
          (acc, [key, value]) => {
            acc[globalThis.Number(key)] = PredictImagesPerCam.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
    };
  },

  toJSON(message: OpenRecordingResponse): unknown {
    const obj: any = {};
    if (message.numSnapshotsPerRow) {
      const entries = Object.entries(message.numSnapshotsPerRow);
      if (entries.length > 0) {
        obj.numSnapshotsPerRow = {};
        entries.forEach(([k, v]) => {
          obj.numSnapshotsPerRow[k] = Math.round(v);
        });
      }
    }
    if (message.recordingData !== undefined) {
      obj.recordingData = StaticRecordingData.toJSON(message.recordingData);
    }
    if (message.trajectoryImagesPerRow) {
      const entries = Object.entries(message.trajectoryImagesPerRow);
      if (entries.length > 0) {
        obj.trajectoryImagesPerRow = {};
        entries.forEach(([k, v]) => {
          obj.trajectoryImagesPerRow[k] = TrajectoriesWithImages.toJSON(v);
        });
      }
    }
    if (message.predictImagesPerRow) {
      const entries = Object.entries(message.predictImagesPerRow);
      if (entries.length > 0) {
        obj.predictImagesPerRow = {};
        entries.forEach(([k, v]) => {
          obj.predictImagesPerRow[k] = PredictImagesPerCam.toJSON(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OpenRecordingResponse>, I>>(base?: I): OpenRecordingResponse {
    return OpenRecordingResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OpenRecordingResponse>, I>>(object: I): OpenRecordingResponse {
    const message = createBaseOpenRecordingResponse();
    message.numSnapshotsPerRow = Object.entries(object.numSnapshotsPerRow ?? {}).reduce<{ [key: number]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[globalThis.Number(key)] = globalThis.Number(value);
        }
        return acc;
      },
      {},
    );
    message.recordingData = (object.recordingData !== undefined && object.recordingData !== null)
      ? StaticRecordingData.fromPartial(object.recordingData)
      : undefined;
    message.trajectoryImagesPerRow = Object.entries(object.trajectoryImagesPerRow ?? {}).reduce<
      { [key: number]: TrajectoriesWithImages }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[globalThis.Number(key)] = TrajectoriesWithImages.fromPartial(value);
      }
      return acc;
    }, {});
    message.predictImagesPerRow = Object.entries(object.predictImagesPerRow ?? {}).reduce<
      { [key: number]: PredictImagesPerCam }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[globalThis.Number(key)] = PredictImagesPerCam.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseOpenRecordingResponse_NumSnapshotsPerRowEntry(): OpenRecordingResponse_NumSnapshotsPerRowEntry {
  return { key: 0, value: 0 };
}

export const OpenRecordingResponse_NumSnapshotsPerRowEntry: MessageFns<OpenRecordingResponse_NumSnapshotsPerRowEntry> =
  {
    encode(
      message: OpenRecordingResponse_NumSnapshotsPerRowEntry,
      writer: BinaryWriter = new BinaryWriter(),
    ): BinaryWriter {
      if (message.key !== 0) {
        writer.uint32(8).uint32(message.key);
      }
      if (message.value !== 0) {
        writer.uint32(16).uint32(message.value);
      }
      return writer;
    },

    decode(input: BinaryReader | Uint8Array, length?: number): OpenRecordingResponse_NumSnapshotsPerRowEntry {
      const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
      let end = length === undefined ? reader.len : reader.pos + length;
      const message = createBaseOpenRecordingResponse_NumSnapshotsPerRowEntry();
      while (reader.pos < end) {
        const tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            if (tag !== 8) {
              break;
            }

            message.key = reader.uint32();
            continue;
          case 2:
            if (tag !== 16) {
              break;
            }

            message.value = reader.uint32();
            continue;
        }
        if ((tag & 7) === 4 || tag === 0) {
          break;
        }
        reader.skip(tag & 7);
      }
      return message;
    },

    fromJSON(object: any): OpenRecordingResponse_NumSnapshotsPerRowEntry {
      return {
        key: isSet(object.key) ? globalThis.Number(object.key) : 0,
        value: isSet(object.value) ? globalThis.Number(object.value) : 0,
      };
    },

    toJSON(message: OpenRecordingResponse_NumSnapshotsPerRowEntry): unknown {
      const obj: any = {};
      if (message.key !== 0) {
        obj.key = Math.round(message.key);
      }
      if (message.value !== 0) {
        obj.value = Math.round(message.value);
      }
      return obj;
    },

    create<I extends Exact<DeepPartial<OpenRecordingResponse_NumSnapshotsPerRowEntry>, I>>(
      base?: I,
    ): OpenRecordingResponse_NumSnapshotsPerRowEntry {
      return OpenRecordingResponse_NumSnapshotsPerRowEntry.fromPartial(base ?? ({} as any));
    },
    fromPartial<I extends Exact<DeepPartial<OpenRecordingResponse_NumSnapshotsPerRowEntry>, I>>(
      object: I,
    ): OpenRecordingResponse_NumSnapshotsPerRowEntry {
      const message = createBaseOpenRecordingResponse_NumSnapshotsPerRowEntry();
      message.key = object.key ?? 0;
      message.value = object.value ?? 0;
      return message;
    },
  };

function createBaseOpenRecordingResponse_TrajectoryImagesPerRowEntry(): OpenRecordingResponse_TrajectoryImagesPerRowEntry {
  return { key: 0, value: undefined };
}

export const OpenRecordingResponse_TrajectoryImagesPerRowEntry: MessageFns<
  OpenRecordingResponse_TrajectoryImagesPerRowEntry
> = {
  encode(
    message: OpenRecordingResponse_TrajectoryImagesPerRowEntry,
    writer: BinaryWriter = new BinaryWriter(),
  ): BinaryWriter {
    if (message.key !== 0) {
      writer.uint32(8).uint32(message.key);
    }
    if (message.value !== undefined) {
      TrajectoriesWithImages.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): OpenRecordingResponse_TrajectoryImagesPerRowEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOpenRecordingResponse_TrajectoryImagesPerRowEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.key = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = TrajectoriesWithImages.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OpenRecordingResponse_TrajectoryImagesPerRowEntry {
    return {
      key: isSet(object.key) ? globalThis.Number(object.key) : 0,
      value: isSet(object.value) ? TrajectoriesWithImages.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: OpenRecordingResponse_TrajectoryImagesPerRowEntry): unknown {
    const obj: any = {};
    if (message.key !== 0) {
      obj.key = Math.round(message.key);
    }
    if (message.value !== undefined) {
      obj.value = TrajectoriesWithImages.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OpenRecordingResponse_TrajectoryImagesPerRowEntry>, I>>(
    base?: I,
  ): OpenRecordingResponse_TrajectoryImagesPerRowEntry {
    return OpenRecordingResponse_TrajectoryImagesPerRowEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OpenRecordingResponse_TrajectoryImagesPerRowEntry>, I>>(
    object: I,
  ): OpenRecordingResponse_TrajectoryImagesPerRowEntry {
    const message = createBaseOpenRecordingResponse_TrajectoryImagesPerRowEntry();
    message.key = object.key ?? 0;
    message.value = (object.value !== undefined && object.value !== null)
      ? TrajectoriesWithImages.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseOpenRecordingResponse_PredictImagesPerRowEntry(): OpenRecordingResponse_PredictImagesPerRowEntry {
  return { key: 0, value: undefined };
}

export const OpenRecordingResponse_PredictImagesPerRowEntry: MessageFns<
  OpenRecordingResponse_PredictImagesPerRowEntry
> = {
  encode(
    message: OpenRecordingResponse_PredictImagesPerRowEntry,
    writer: BinaryWriter = new BinaryWriter(),
  ): BinaryWriter {
    if (message.key !== 0) {
      writer.uint32(8).uint32(message.key);
    }
    if (message.value !== undefined) {
      PredictImagesPerCam.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): OpenRecordingResponse_PredictImagesPerRowEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOpenRecordingResponse_PredictImagesPerRowEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.key = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = PredictImagesPerCam.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OpenRecordingResponse_PredictImagesPerRowEntry {
    return {
      key: isSet(object.key) ? globalThis.Number(object.key) : 0,
      value: isSet(object.value) ? PredictImagesPerCam.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: OpenRecordingResponse_PredictImagesPerRowEntry): unknown {
    const obj: any = {};
    if (message.key !== 0) {
      obj.key = Math.round(message.key);
    }
    if (message.value !== undefined) {
      obj.value = PredictImagesPerCam.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OpenRecordingResponse_PredictImagesPerRowEntry>, I>>(
    base?: I,
  ): OpenRecordingResponse_PredictImagesPerRowEntry {
    return OpenRecordingResponse_PredictImagesPerRowEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OpenRecordingResponse_PredictImagesPerRowEntry>, I>>(
    object: I,
  ): OpenRecordingResponse_PredictImagesPerRowEntry {
    const message = createBaseOpenRecordingResponse_PredictImagesPerRowEntry();
    message.key = object.key ?? 0;
    message.value = (object.value !== undefined && object.value !== null)
      ? PredictImagesPerCam.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseDeleteRecordingRequest(): DeleteRecordingRequest {
  return { recordingName: "" };
}

export const DeleteRecordingRequest: MessageFns<DeleteRecordingRequest> = {
  encode(message: DeleteRecordingRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.recordingName !== "") {
      writer.uint32(10).string(message.recordingName);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeleteRecordingRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteRecordingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recordingName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteRecordingRequest {
    return { recordingName: isSet(object.recordingName) ? globalThis.String(object.recordingName) : "" };
  },

  toJSON(message: DeleteRecordingRequest): unknown {
    const obj: any = {};
    if (message.recordingName !== "") {
      obj.recordingName = message.recordingName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteRecordingRequest>, I>>(base?: I): DeleteRecordingRequest {
    return DeleteRecordingRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteRecordingRequest>, I>>(object: I): DeleteRecordingRequest {
    const message = createBaseDeleteRecordingRequest();
    message.recordingName = object.recordingName ?? "";
    return message;
  },
};

function createBaseConfigNodeSnapshot(): ConfigNodeSnapshot {
  return { values: {}, childNodes: {} };
}

export const ConfigNodeSnapshot: MessageFns<ConfigNodeSnapshot> = {
  encode(message: ConfigNodeSnapshot, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    Object.entries(message.values).forEach(([key, value]) => {
      ConfigNodeSnapshot_ValuesEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).join();
    });
    Object.entries(message.childNodes).forEach(([key, value]) => {
      ConfigNodeSnapshot_ChildNodesEntry.encode({ key: key as any, value }, writer.uint32(18).fork()).join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ConfigNodeSnapshot {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConfigNodeSnapshot();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = ConfigNodeSnapshot_ValuesEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.values[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = ConfigNodeSnapshot_ChildNodesEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.childNodes[entry2.key] = entry2.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConfigNodeSnapshot {
    return {
      values: isObject(object.values)
        ? Object.entries(object.values).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      childNodes: isObject(object.childNodes)
        ? Object.entries(object.childNodes).reduce<{ [key: string]: ConfigNodeSnapshot }>((acc, [key, value]) => {
          acc[key] = ConfigNodeSnapshot.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: ConfigNodeSnapshot): unknown {
    const obj: any = {};
    if (message.values) {
      const entries = Object.entries(message.values);
      if (entries.length > 0) {
        obj.values = {};
        entries.forEach(([k, v]) => {
          obj.values[k] = v;
        });
      }
    }
    if (message.childNodes) {
      const entries = Object.entries(message.childNodes);
      if (entries.length > 0) {
        obj.childNodes = {};
        entries.forEach(([k, v]) => {
          obj.childNodes[k] = ConfigNodeSnapshot.toJSON(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ConfigNodeSnapshot>, I>>(base?: I): ConfigNodeSnapshot {
    return ConfigNodeSnapshot.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ConfigNodeSnapshot>, I>>(object: I): ConfigNodeSnapshot {
    const message = createBaseConfigNodeSnapshot();
    message.values = Object.entries(object.values ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = globalThis.String(value);
      }
      return acc;
    }, {});
    message.childNodes = Object.entries(object.childNodes ?? {}).reduce<{ [key: string]: ConfigNodeSnapshot }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = ConfigNodeSnapshot.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseConfigNodeSnapshot_ValuesEntry(): ConfigNodeSnapshot_ValuesEntry {
  return { key: "", value: "" };
}

export const ConfigNodeSnapshot_ValuesEntry: MessageFns<ConfigNodeSnapshot_ValuesEntry> = {
  encode(message: ConfigNodeSnapshot_ValuesEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ConfigNodeSnapshot_ValuesEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConfigNodeSnapshot_ValuesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConfigNodeSnapshot_ValuesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: ConfigNodeSnapshot_ValuesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ConfigNodeSnapshot_ValuesEntry>, I>>(base?: I): ConfigNodeSnapshot_ValuesEntry {
    return ConfigNodeSnapshot_ValuesEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ConfigNodeSnapshot_ValuesEntry>, I>>(
    object: I,
  ): ConfigNodeSnapshot_ValuesEntry {
    const message = createBaseConfigNodeSnapshot_ValuesEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseConfigNodeSnapshot_ChildNodesEntry(): ConfigNodeSnapshot_ChildNodesEntry {
  return { key: "", value: undefined };
}

export const ConfigNodeSnapshot_ChildNodesEntry: MessageFns<ConfigNodeSnapshot_ChildNodesEntry> = {
  encode(message: ConfigNodeSnapshot_ChildNodesEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      ConfigNodeSnapshot.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ConfigNodeSnapshot_ChildNodesEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConfigNodeSnapshot_ChildNodesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = ConfigNodeSnapshot.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConfigNodeSnapshot_ChildNodesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? ConfigNodeSnapshot.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: ConfigNodeSnapshot_ChildNodesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = ConfigNodeSnapshot.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ConfigNodeSnapshot_ChildNodesEntry>, I>>(
    base?: I,
  ): ConfigNodeSnapshot_ChildNodesEntry {
    return ConfigNodeSnapshot_ChildNodesEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ConfigNodeSnapshot_ChildNodesEntry>, I>>(
    object: I,
  ): ConfigNodeSnapshot_ChildNodesEntry {
    const message = createBaseConfigNodeSnapshot_ChildNodesEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? ConfigNodeSnapshot.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseCameraDimensions(): CameraDimensions {
  return { width: 0, height: 0 };
}

export const CameraDimensions: MessageFns<CameraDimensions> = {
  encode(message: CameraDimensions, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.width !== 0) {
      writer.uint32(8).uint32(message.width);
    }
    if (message.height !== 0) {
      writer.uint32(16).uint32(message.height);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CameraDimensions {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCameraDimensions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.width = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.height = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CameraDimensions {
    return {
      width: isSet(object.width) ? globalThis.Number(object.width) : 0,
      height: isSet(object.height) ? globalThis.Number(object.height) : 0,
    };
  },

  toJSON(message: CameraDimensions): unknown {
    const obj: any = {};
    if (message.width !== 0) {
      obj.width = Math.round(message.width);
    }
    if (message.height !== 0) {
      obj.height = Math.round(message.height);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CameraDimensions>, I>>(base?: I): CameraDimensions {
    return CameraDimensions.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CameraDimensions>, I>>(object: I): CameraDimensions {
    const message = createBaseCameraDimensions();
    message.width = object.width ?? 0;
    message.height = object.height ?? 0;
    return message;
  },
};

function createBaseRowCameras(): RowCameras {
  return { cams: {} };
}

export const RowCameras: MessageFns<RowCameras> = {
  encode(message: RowCameras, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    Object.entries(message.cams).forEach(([key, value]) => {
      RowCameras_CamsEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RowCameras {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRowCameras();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = RowCameras_CamsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.cams[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RowCameras {
    return {
      cams: isObject(object.cams)
        ? Object.entries(object.cams).reduce<{ [key: string]: CameraDimensions }>((acc, [key, value]) => {
          acc[key] = CameraDimensions.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: RowCameras): unknown {
    const obj: any = {};
    if (message.cams) {
      const entries = Object.entries(message.cams);
      if (entries.length > 0) {
        obj.cams = {};
        entries.forEach(([k, v]) => {
          obj.cams[k] = CameraDimensions.toJSON(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RowCameras>, I>>(base?: I): RowCameras {
    return RowCameras.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RowCameras>, I>>(object: I): RowCameras {
    const message = createBaseRowCameras();
    message.cams = Object.entries(object.cams ?? {}).reduce<{ [key: string]: CameraDimensions }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = CameraDimensions.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseRowCameras_CamsEntry(): RowCameras_CamsEntry {
  return { key: "", value: undefined };
}

export const RowCameras_CamsEntry: MessageFns<RowCameras_CamsEntry> = {
  encode(message: RowCameras_CamsEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      CameraDimensions.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RowCameras_CamsEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRowCameras_CamsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = CameraDimensions.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RowCameras_CamsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? CameraDimensions.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: RowCameras_CamsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = CameraDimensions.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RowCameras_CamsEntry>, I>>(base?: I): RowCameras_CamsEntry {
    return RowCameras_CamsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RowCameras_CamsEntry>, I>>(object: I): RowCameras_CamsEntry {
    const message = createBaseRowCameras_CamsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? CameraDimensions.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseStaticRecordingData(): StaticRecordingData {
  return {
    lasersEnabled: {},
    rootConfig: undefined,
    rowsRecorded: [],
    rowDimensions: {},
    cropSafetyRadiusMmPerRow: {},
    thinningConfig: undefined,
    recordingTimestamp: 0,
    rowCameras: {},
    weedPointThreshold: 0,
    cropPointThreshold: 0,
    wheelDiameterBackLeftIn: 0,
    wheelDiameterBackRightIn: 0,
    wheelDiameterFrontLeftIn: 0,
    wheelDiameterFrontRightIn: 0,
  };
}

export const StaticRecordingData: MessageFns<StaticRecordingData> = {
  encode(message: StaticRecordingData, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    Object.entries(message.lasersEnabled).forEach(([key, value]) => {
      StaticRecordingData_LasersEnabledEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).join();
    });
    if (message.rootConfig !== undefined) {
      ConfigNodeSnapshot.encode(message.rootConfig, writer.uint32(18).fork()).join();
    }
    writer.uint32(26).fork();
    for (const v of message.rowsRecorded) {
      writer.int32(v);
    }
    writer.join();
    Object.entries(message.rowDimensions).forEach(([key, value]) => {
      StaticRecordingData_RowDimensionsEntry.encode({ key: key as any, value }, writer.uint32(34).fork()).join();
    });
    Object.entries(message.cropSafetyRadiusMmPerRow).forEach(([key, value]) => {
      StaticRecordingData_CropSafetyRadiusMmPerRowEntry.encode({ key: key as any, value }, writer.uint32(42).fork())
        .join();
    });
    if (message.thinningConfig !== undefined) {
      ConfigDefinition.encode(message.thinningConfig, writer.uint32(50).fork()).join();
    }
    if (message.recordingTimestamp !== 0) {
      writer.uint32(56).int64(message.recordingTimestamp);
    }
    Object.entries(message.rowCameras).forEach(([key, value]) => {
      StaticRecordingData_RowCamerasEntry.encode({ key: key as any, value }, writer.uint32(66).fork()).join();
    });
    if (message.weedPointThreshold !== 0) {
      writer.uint32(77).float(message.weedPointThreshold);
    }
    if (message.cropPointThreshold !== 0) {
      writer.uint32(85).float(message.cropPointThreshold);
    }
    if (message.wheelDiameterBackLeftIn !== 0) {
      writer.uint32(93).float(message.wheelDiameterBackLeftIn);
    }
    if (message.wheelDiameterBackRightIn !== 0) {
      writer.uint32(101).float(message.wheelDiameterBackRightIn);
    }
    if (message.wheelDiameterFrontLeftIn !== 0) {
      writer.uint32(109).float(message.wheelDiameterFrontLeftIn);
    }
    if (message.wheelDiameterFrontRightIn !== 0) {
      writer.uint32(117).float(message.wheelDiameterFrontRightIn);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StaticRecordingData {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStaticRecordingData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = StaticRecordingData_LasersEnabledEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.lasersEnabled[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.rootConfig = ConfigNodeSnapshot.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag === 24) {
            message.rowsRecorded.push(reader.int32());

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.rowsRecorded.push(reader.int32());
            }

            continue;
          }

          break;
        case 4:
          if (tag !== 34) {
            break;
          }

          const entry4 = StaticRecordingData_RowDimensionsEntry.decode(reader, reader.uint32());
          if (entry4.value !== undefined) {
            message.rowDimensions[entry4.key] = entry4.value;
          }
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          const entry5 = StaticRecordingData_CropSafetyRadiusMmPerRowEntry.decode(reader, reader.uint32());
          if (entry5.value !== undefined) {
            message.cropSafetyRadiusMmPerRow[entry5.key] = entry5.value;
          }
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.thinningConfig = ConfigDefinition.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.recordingTimestamp = longToNumber(reader.int64());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          const entry8 = StaticRecordingData_RowCamerasEntry.decode(reader, reader.uint32());
          if (entry8.value !== undefined) {
            message.rowCameras[entry8.key] = entry8.value;
          }
          continue;
        case 9:
          if (tag !== 77) {
            break;
          }

          message.weedPointThreshold = reader.float();
          continue;
        case 10:
          if (tag !== 85) {
            break;
          }

          message.cropPointThreshold = reader.float();
          continue;
        case 11:
          if (tag !== 93) {
            break;
          }

          message.wheelDiameterBackLeftIn = reader.float();
          continue;
        case 12:
          if (tag !== 101) {
            break;
          }

          message.wheelDiameterBackRightIn = reader.float();
          continue;
        case 13:
          if (tag !== 109) {
            break;
          }

          message.wheelDiameterFrontLeftIn = reader.float();
          continue;
        case 14:
          if (tag !== 117) {
            break;
          }

          message.wheelDiameterFrontRightIn = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StaticRecordingData {
    return {
      lasersEnabled: isObject(object.lasersEnabled)
        ? Object.entries(object.lasersEnabled).reduce<{ [key: string]: boolean }>((acc, [key, value]) => {
          acc[key] = Boolean(value);
          return acc;
        }, {})
        : {},
      rootConfig: isSet(object.rootConfig) ? ConfigNodeSnapshot.fromJSON(object.rootConfig) : undefined,
      rowsRecorded: globalThis.Array.isArray(object?.rowsRecorded)
        ? object.rowsRecorded.map((e: any) => globalThis.Number(e))
        : [],
      rowDimensions: isObject(object.rowDimensions)
        ? Object.entries(object.rowDimensions).reduce<{ [key: number]: GetDimensionsResponse }>((acc, [key, value]) => {
          acc[globalThis.Number(key)] = GetDimensionsResponse.fromJSON(value);
          return acc;
        }, {})
        : {},
      cropSafetyRadiusMmPerRow: isObject(object.cropSafetyRadiusMmPerRow)
        ? Object.entries(object.cropSafetyRadiusMmPerRow).reduce<{ [key: number]: number }>((acc, [key, value]) => {
          acc[globalThis.Number(key)] = Number(value);
          return acc;
        }, {})
        : {},
      thinningConfig: isSet(object.thinningConfig) ? ConfigDefinition.fromJSON(object.thinningConfig) : undefined,
      recordingTimestamp: isSet(object.recordingTimestamp) ? globalThis.Number(object.recordingTimestamp) : 0,
      rowCameras: isObject(object.rowCameras)
        ? Object.entries(object.rowCameras).reduce<{ [key: number]: RowCameras }>((acc, [key, value]) => {
          acc[globalThis.Number(key)] = RowCameras.fromJSON(value);
          return acc;
        }, {})
        : {},
      weedPointThreshold: isSet(object.weedPointThreshold) ? globalThis.Number(object.weedPointThreshold) : 0,
      cropPointThreshold: isSet(object.cropPointThreshold) ? globalThis.Number(object.cropPointThreshold) : 0,
      wheelDiameterBackLeftIn: isSet(object.wheelDiameterBackLeftIn)
        ? globalThis.Number(object.wheelDiameterBackLeftIn)
        : 0,
      wheelDiameterBackRightIn: isSet(object.wheelDiameterBackRightIn)
        ? globalThis.Number(object.wheelDiameterBackRightIn)
        : 0,
      wheelDiameterFrontLeftIn: isSet(object.wheelDiameterFrontLeftIn)
        ? globalThis.Number(object.wheelDiameterFrontLeftIn)
        : 0,
      wheelDiameterFrontRightIn: isSet(object.wheelDiameterFrontRightIn)
        ? globalThis.Number(object.wheelDiameterFrontRightIn)
        : 0,
    };
  },

  toJSON(message: StaticRecordingData): unknown {
    const obj: any = {};
    if (message.lasersEnabled) {
      const entries = Object.entries(message.lasersEnabled);
      if (entries.length > 0) {
        obj.lasersEnabled = {};
        entries.forEach(([k, v]) => {
          obj.lasersEnabled[k] = v;
        });
      }
    }
    if (message.rootConfig !== undefined) {
      obj.rootConfig = ConfigNodeSnapshot.toJSON(message.rootConfig);
    }
    if (message.rowsRecorded?.length) {
      obj.rowsRecorded = message.rowsRecorded.map((e) => Math.round(e));
    }
    if (message.rowDimensions) {
      const entries = Object.entries(message.rowDimensions);
      if (entries.length > 0) {
        obj.rowDimensions = {};
        entries.forEach(([k, v]) => {
          obj.rowDimensions[k] = GetDimensionsResponse.toJSON(v);
        });
      }
    }
    if (message.cropSafetyRadiusMmPerRow) {
      const entries = Object.entries(message.cropSafetyRadiusMmPerRow);
      if (entries.length > 0) {
        obj.cropSafetyRadiusMmPerRow = {};
        entries.forEach(([k, v]) => {
          obj.cropSafetyRadiusMmPerRow[k] = v;
        });
      }
    }
    if (message.thinningConfig !== undefined) {
      obj.thinningConfig = ConfigDefinition.toJSON(message.thinningConfig);
    }
    if (message.recordingTimestamp !== 0) {
      obj.recordingTimestamp = Math.round(message.recordingTimestamp);
    }
    if (message.rowCameras) {
      const entries = Object.entries(message.rowCameras);
      if (entries.length > 0) {
        obj.rowCameras = {};
        entries.forEach(([k, v]) => {
          obj.rowCameras[k] = RowCameras.toJSON(v);
        });
      }
    }
    if (message.weedPointThreshold !== 0) {
      obj.weedPointThreshold = message.weedPointThreshold;
    }
    if (message.cropPointThreshold !== 0) {
      obj.cropPointThreshold = message.cropPointThreshold;
    }
    if (message.wheelDiameterBackLeftIn !== 0) {
      obj.wheelDiameterBackLeftIn = message.wheelDiameterBackLeftIn;
    }
    if (message.wheelDiameterBackRightIn !== 0) {
      obj.wheelDiameterBackRightIn = message.wheelDiameterBackRightIn;
    }
    if (message.wheelDiameterFrontLeftIn !== 0) {
      obj.wheelDiameterFrontLeftIn = message.wheelDiameterFrontLeftIn;
    }
    if (message.wheelDiameterFrontRightIn !== 0) {
      obj.wheelDiameterFrontRightIn = message.wheelDiameterFrontRightIn;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StaticRecordingData>, I>>(base?: I): StaticRecordingData {
    return StaticRecordingData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StaticRecordingData>, I>>(object: I): StaticRecordingData {
    const message = createBaseStaticRecordingData();
    message.lasersEnabled = Object.entries(object.lasersEnabled ?? {}).reduce<{ [key: string]: boolean }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.Boolean(value);
        }
        return acc;
      },
      {},
    );
    message.rootConfig = (object.rootConfig !== undefined && object.rootConfig !== null)
      ? ConfigNodeSnapshot.fromPartial(object.rootConfig)
      : undefined;
    message.rowsRecorded = object.rowsRecorded?.map((e) => e) || [];
    message.rowDimensions = Object.entries(object.rowDimensions ?? {}).reduce<{ [key: number]: GetDimensionsResponse }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[globalThis.Number(key)] = GetDimensionsResponse.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.cropSafetyRadiusMmPerRow = Object.entries(object.cropSafetyRadiusMmPerRow ?? {}).reduce<
      { [key: number]: number }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[globalThis.Number(key)] = globalThis.Number(value);
      }
      return acc;
    }, {});
    message.thinningConfig = (object.thinningConfig !== undefined && object.thinningConfig !== null)
      ? ConfigDefinition.fromPartial(object.thinningConfig)
      : undefined;
    message.recordingTimestamp = object.recordingTimestamp ?? 0;
    message.rowCameras = Object.entries(object.rowCameras ?? {}).reduce<{ [key: number]: RowCameras }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[globalThis.Number(key)] = RowCameras.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.weedPointThreshold = object.weedPointThreshold ?? 0;
    message.cropPointThreshold = object.cropPointThreshold ?? 0;
    message.wheelDiameterBackLeftIn = object.wheelDiameterBackLeftIn ?? 0;
    message.wheelDiameterBackRightIn = object.wheelDiameterBackRightIn ?? 0;
    message.wheelDiameterFrontLeftIn = object.wheelDiameterFrontLeftIn ?? 0;
    message.wheelDiameterFrontRightIn = object.wheelDiameterFrontRightIn ?? 0;
    return message;
  },
};

function createBaseStaticRecordingData_LasersEnabledEntry(): StaticRecordingData_LasersEnabledEntry {
  return { key: "", value: false };
}

export const StaticRecordingData_LasersEnabledEntry: MessageFns<StaticRecordingData_LasersEnabledEntry> = {
  encode(message: StaticRecordingData_LasersEnabledEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== false) {
      writer.uint32(16).bool(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StaticRecordingData_LasersEnabledEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStaticRecordingData_LasersEnabledEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StaticRecordingData_LasersEnabledEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.Boolean(object.value) : false,
    };
  },

  toJSON(message: StaticRecordingData_LasersEnabledEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== false) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StaticRecordingData_LasersEnabledEntry>, I>>(
    base?: I,
  ): StaticRecordingData_LasersEnabledEntry {
    return StaticRecordingData_LasersEnabledEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StaticRecordingData_LasersEnabledEntry>, I>>(
    object: I,
  ): StaticRecordingData_LasersEnabledEntry {
    const message = createBaseStaticRecordingData_LasersEnabledEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? false;
    return message;
  },
};

function createBaseStaticRecordingData_RowDimensionsEntry(): StaticRecordingData_RowDimensionsEntry {
  return { key: 0, value: undefined };
}

export const StaticRecordingData_RowDimensionsEntry: MessageFns<StaticRecordingData_RowDimensionsEntry> = {
  encode(message: StaticRecordingData_RowDimensionsEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== 0) {
      writer.uint32(8).uint32(message.key);
    }
    if (message.value !== undefined) {
      GetDimensionsResponse.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StaticRecordingData_RowDimensionsEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStaticRecordingData_RowDimensionsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.key = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = GetDimensionsResponse.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StaticRecordingData_RowDimensionsEntry {
    return {
      key: isSet(object.key) ? globalThis.Number(object.key) : 0,
      value: isSet(object.value) ? GetDimensionsResponse.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: StaticRecordingData_RowDimensionsEntry): unknown {
    const obj: any = {};
    if (message.key !== 0) {
      obj.key = Math.round(message.key);
    }
    if (message.value !== undefined) {
      obj.value = GetDimensionsResponse.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StaticRecordingData_RowDimensionsEntry>, I>>(
    base?: I,
  ): StaticRecordingData_RowDimensionsEntry {
    return StaticRecordingData_RowDimensionsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StaticRecordingData_RowDimensionsEntry>, I>>(
    object: I,
  ): StaticRecordingData_RowDimensionsEntry {
    const message = createBaseStaticRecordingData_RowDimensionsEntry();
    message.key = object.key ?? 0;
    message.value = (object.value !== undefined && object.value !== null)
      ? GetDimensionsResponse.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseStaticRecordingData_CropSafetyRadiusMmPerRowEntry(): StaticRecordingData_CropSafetyRadiusMmPerRowEntry {
  return { key: 0, value: 0 };
}

export const StaticRecordingData_CropSafetyRadiusMmPerRowEntry: MessageFns<
  StaticRecordingData_CropSafetyRadiusMmPerRowEntry
> = {
  encode(
    message: StaticRecordingData_CropSafetyRadiusMmPerRowEntry,
    writer: BinaryWriter = new BinaryWriter(),
  ): BinaryWriter {
    if (message.key !== 0) {
      writer.uint32(8).uint32(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(21).float(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StaticRecordingData_CropSafetyRadiusMmPerRowEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStaticRecordingData_CropSafetyRadiusMmPerRowEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.key = reader.uint32();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.value = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StaticRecordingData_CropSafetyRadiusMmPerRowEntry {
    return {
      key: isSet(object.key) ? globalThis.Number(object.key) : 0,
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
    };
  },

  toJSON(message: StaticRecordingData_CropSafetyRadiusMmPerRowEntry): unknown {
    const obj: any = {};
    if (message.key !== 0) {
      obj.key = Math.round(message.key);
    }
    if (message.value !== 0) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StaticRecordingData_CropSafetyRadiusMmPerRowEntry>, I>>(
    base?: I,
  ): StaticRecordingData_CropSafetyRadiusMmPerRowEntry {
    return StaticRecordingData_CropSafetyRadiusMmPerRowEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StaticRecordingData_CropSafetyRadiusMmPerRowEntry>, I>>(
    object: I,
  ): StaticRecordingData_CropSafetyRadiusMmPerRowEntry {
    const message = createBaseStaticRecordingData_CropSafetyRadiusMmPerRowEntry();
    message.key = object.key ?? 0;
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseStaticRecordingData_RowCamerasEntry(): StaticRecordingData_RowCamerasEntry {
  return { key: 0, value: undefined };
}

export const StaticRecordingData_RowCamerasEntry: MessageFns<StaticRecordingData_RowCamerasEntry> = {
  encode(message: StaticRecordingData_RowCamerasEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== 0) {
      writer.uint32(8).uint32(message.key);
    }
    if (message.value !== undefined) {
      RowCameras.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StaticRecordingData_RowCamerasEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStaticRecordingData_RowCamerasEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.key = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = RowCameras.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StaticRecordingData_RowCamerasEntry {
    return {
      key: isSet(object.key) ? globalThis.Number(object.key) : 0,
      value: isSet(object.value) ? RowCameras.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: StaticRecordingData_RowCamerasEntry): unknown {
    const obj: any = {};
    if (message.key !== 0) {
      obj.key = Math.round(message.key);
    }
    if (message.value !== undefined) {
      obj.value = RowCameras.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StaticRecordingData_RowCamerasEntry>, I>>(
    base?: I,
  ): StaticRecordingData_RowCamerasEntry {
    return StaticRecordingData_RowCamerasEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StaticRecordingData_RowCamerasEntry>, I>>(
    object: I,
  ): StaticRecordingData_RowCamerasEntry {
    const message = createBaseStaticRecordingData_RowCamerasEntry();
    message.key = object.key ?? 0;
    message.value = (object.value !== undefined && object.value !== null)
      ? RowCameras.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseGetTrajectoryDataRequest(): GetTrajectoryDataRequest {
  return { recordingName: "", rowId: 0, trajectoryId: 0 };
}

export const GetTrajectoryDataRequest: MessageFns<GetTrajectoryDataRequest> = {
  encode(message: GetTrajectoryDataRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.recordingName !== "") {
      writer.uint32(10).string(message.recordingName);
    }
    if (message.rowId !== 0) {
      writer.uint32(16).uint32(message.rowId);
    }
    if (message.trajectoryId !== 0) {
      writer.uint32(24).uint32(message.trajectoryId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetTrajectoryDataRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTrajectoryDataRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recordingName = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.rowId = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.trajectoryId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTrajectoryDataRequest {
    return {
      recordingName: isSet(object.recordingName) ? globalThis.String(object.recordingName) : "",
      rowId: isSet(object.rowId) ? globalThis.Number(object.rowId) : 0,
      trajectoryId: isSet(object.trajectoryId) ? globalThis.Number(object.trajectoryId) : 0,
    };
  },

  toJSON(message: GetTrajectoryDataRequest): unknown {
    const obj: any = {};
    if (message.recordingName !== "") {
      obj.recordingName = message.recordingName;
    }
    if (message.rowId !== 0) {
      obj.rowId = Math.round(message.rowId);
    }
    if (message.trajectoryId !== 0) {
      obj.trajectoryId = Math.round(message.trajectoryId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTrajectoryDataRequest>, I>>(base?: I): GetTrajectoryDataRequest {
    return GetTrajectoryDataRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTrajectoryDataRequest>, I>>(object: I): GetTrajectoryDataRequest {
    const message = createBaseGetTrajectoryDataRequest();
    message.recordingName = object.recordingName ?? "";
    message.rowId = object.rowId ?? 0;
    message.trajectoryId = object.trajectoryId ?? 0;
    return message;
  },
};

function createBaseLastSnapshot(): LastSnapshot {
  return { trajectory: undefined, diagnosticsSnapshotNumber: 0 };
}

export const LastSnapshot: MessageFns<LastSnapshot> = {
  encode(message: LastSnapshot, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.trajectory !== undefined) {
      TrajectorySnapshot.encode(message.trajectory, writer.uint32(10).fork()).join();
    }
    if (message.diagnosticsSnapshotNumber !== 0) {
      writer.uint32(16).uint32(message.diagnosticsSnapshotNumber);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LastSnapshot {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLastSnapshot();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.trajectory = TrajectorySnapshot.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.diagnosticsSnapshotNumber = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LastSnapshot {
    return {
      trajectory: isSet(object.trajectory) ? TrajectorySnapshot.fromJSON(object.trajectory) : undefined,
      diagnosticsSnapshotNumber: isSet(object.diagnosticsSnapshotNumber)
        ? globalThis.Number(object.diagnosticsSnapshotNumber)
        : 0,
    };
  },

  toJSON(message: LastSnapshot): unknown {
    const obj: any = {};
    if (message.trajectory !== undefined) {
      obj.trajectory = TrajectorySnapshot.toJSON(message.trajectory);
    }
    if (message.diagnosticsSnapshotNumber !== 0) {
      obj.diagnosticsSnapshotNumber = Math.round(message.diagnosticsSnapshotNumber);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LastSnapshot>, I>>(base?: I): LastSnapshot {
    return LastSnapshot.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LastSnapshot>, I>>(object: I): LastSnapshot {
    const message = createBaseLastSnapshot();
    message.trajectory = (object.trajectory !== undefined && object.trajectory !== null)
      ? TrajectorySnapshot.fromPartial(object.trajectory)
      : undefined;
    message.diagnosticsSnapshotNumber = object.diagnosticsSnapshotNumber ?? 0;
    return message;
  },
};

function createBaseExtraTrajectoryField(): ExtraTrajectoryField {
  return { label: "", value: "", color: "" };
}

export const ExtraTrajectoryField: MessageFns<ExtraTrajectoryField> = {
  encode(message: ExtraTrajectoryField, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.label !== "") {
      writer.uint32(10).string(message.label);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    if (message.color !== "") {
      writer.uint32(26).string(message.color);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ExtraTrajectoryField {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExtraTrajectoryField();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.label = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.color = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExtraTrajectoryField {
    return {
      label: isSet(object.label) ? globalThis.String(object.label) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
      color: isSet(object.color) ? globalThis.String(object.color) : "",
    };
  },

  toJSON(message: ExtraTrajectoryField): unknown {
    const obj: any = {};
    if (message.label !== "") {
      obj.label = message.label;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    if (message.color !== "") {
      obj.color = message.color;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExtraTrajectoryField>, I>>(base?: I): ExtraTrajectoryField {
    return ExtraTrajectoryField.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExtraTrajectoryField>, I>>(object: I): ExtraTrajectoryField {
    const message = createBaseExtraTrajectoryField();
    message.label = object.label ?? "";
    message.value = object.value ?? "";
    message.color = object.color ?? "";
    return message;
  },
};

function createBaseTargetImage(): TargetImage {
  return { name: "", timestamp: 0, p2pPredictX: 0, p2pPredictY: 0 };
}

export const TargetImage: MessageFns<TargetImage> = {
  encode(message: TargetImage, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.timestamp !== 0) {
      writer.uint32(16).uint64(message.timestamp);
    }
    if (message.p2pPredictX !== 0) {
      writer.uint32(24).uint32(message.p2pPredictX);
    }
    if (message.p2pPredictY !== 0) {
      writer.uint32(32).uint32(message.p2pPredictY);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TargetImage {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTargetImage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.timestamp = longToNumber(reader.uint64());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.p2pPredictX = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.p2pPredictY = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TargetImage {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      timestamp: isSet(object.timestamp) ? globalThis.Number(object.timestamp) : 0,
      p2pPredictX: isSet(object.p2pPredictX) ? globalThis.Number(object.p2pPredictX) : 0,
      p2pPredictY: isSet(object.p2pPredictY) ? globalThis.Number(object.p2pPredictY) : 0,
    };
  },

  toJSON(message: TargetImage): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.timestamp !== 0) {
      obj.timestamp = Math.round(message.timestamp);
    }
    if (message.p2pPredictX !== 0) {
      obj.p2pPredictX = Math.round(message.p2pPredictX);
    }
    if (message.p2pPredictY !== 0) {
      obj.p2pPredictY = Math.round(message.p2pPredictY);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TargetImage>, I>>(base?: I): TargetImage {
    return TargetImage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TargetImage>, I>>(object: I): TargetImage {
    const message = createBaseTargetImage();
    message.name = object.name ?? "";
    message.timestamp = object.timestamp ?? 0;
    message.p2pPredictX = object.p2pPredictX ?? 0;
    message.p2pPredictY = object.p2pPredictY ?? 0;
    return message;
  },
};

function createBaseTrajectoryPredictImageMetadata(): TrajectoryPredictImageMetadata {
  return { centerXPx: 0, centerYPx: 0, radiusPx: 0, ts: undefined, pcamId: "" };
}

export const TrajectoryPredictImageMetadata: MessageFns<TrajectoryPredictImageMetadata> = {
  encode(message: TrajectoryPredictImageMetadata, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.centerXPx !== 0) {
      writer.uint32(8).int32(message.centerXPx);
    }
    if (message.centerYPx !== 0) {
      writer.uint32(16).int32(message.centerYPx);
    }
    if (message.radiusPx !== 0) {
      writer.uint32(24).int32(message.radiusPx);
    }
    if (message.ts !== undefined) {
      Timestamp.encode(message.ts, writer.uint32(34).fork()).join();
    }
    if (message.pcamId !== "") {
      writer.uint32(42).string(message.pcamId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TrajectoryPredictImageMetadata {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrajectoryPredictImageMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.centerXPx = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.centerYPx = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.radiusPx = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.ts = Timestamp.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.pcamId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TrajectoryPredictImageMetadata {
    return {
      centerXPx: isSet(object.centerXPx) ? globalThis.Number(object.centerXPx) : 0,
      centerYPx: isSet(object.centerYPx) ? globalThis.Number(object.centerYPx) : 0,
      radiusPx: isSet(object.radiusPx) ? globalThis.Number(object.radiusPx) : 0,
      ts: isSet(object.ts) ? Timestamp.fromJSON(object.ts) : undefined,
      pcamId: isSet(object.pcamId) ? globalThis.String(object.pcamId) : "",
    };
  },

  toJSON(message: TrajectoryPredictImageMetadata): unknown {
    const obj: any = {};
    if (message.centerXPx !== 0) {
      obj.centerXPx = Math.round(message.centerXPx);
    }
    if (message.centerYPx !== 0) {
      obj.centerYPx = Math.round(message.centerYPx);
    }
    if (message.radiusPx !== 0) {
      obj.radiusPx = Math.round(message.radiusPx);
    }
    if (message.ts !== undefined) {
      obj.ts = Timestamp.toJSON(message.ts);
    }
    if (message.pcamId !== "") {
      obj.pcamId = message.pcamId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TrajectoryPredictImageMetadata>, I>>(base?: I): TrajectoryPredictImageMetadata {
    return TrajectoryPredictImageMetadata.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TrajectoryPredictImageMetadata>, I>>(
    object: I,
  ): TrajectoryPredictImageMetadata {
    const message = createBaseTrajectoryPredictImageMetadata();
    message.centerXPx = object.centerXPx ?? 0;
    message.centerYPx = object.centerYPx ?? 0;
    message.radiusPx = object.radiusPx ?? 0;
    message.ts = (object.ts !== undefined && object.ts !== null) ? Timestamp.fromPartial(object.ts) : undefined;
    message.pcamId = object.pcamId ?? "";
    return message;
  },
};

function createBaseTrajectoryData(): TrajectoryData {
  return {
    predictImage: undefined,
    targetImages: [],
    lastSnapshot: undefined,
    extraFields: [],
    crosshairX: 0,
    crosshairY: 0,
  };
}

export const TrajectoryData: MessageFns<TrajectoryData> = {
  encode(message: TrajectoryData, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.predictImage !== undefined) {
      TrajectoryPredictImageMetadata.encode(message.predictImage, writer.uint32(10).fork()).join();
    }
    for (const v of message.targetImages) {
      TargetImage.encode(v!, writer.uint32(18).fork()).join();
    }
    if (message.lastSnapshot !== undefined) {
      LastSnapshot.encode(message.lastSnapshot, writer.uint32(26).fork()).join();
    }
    for (const v of message.extraFields) {
      ExtraTrajectoryField.encode(v!, writer.uint32(34).fork()).join();
    }
    if (message.crosshairX !== 0) {
      writer.uint32(40).uint32(message.crosshairX);
    }
    if (message.crosshairY !== 0) {
      writer.uint32(48).uint32(message.crosshairY);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TrajectoryData {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrajectoryData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.predictImage = TrajectoryPredictImageMetadata.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.targetImages.push(TargetImage.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.lastSnapshot = LastSnapshot.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.extraFields.push(ExtraTrajectoryField.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.crosshairX = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.crosshairY = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TrajectoryData {
    return {
      predictImage: isSet(object.predictImage)
        ? TrajectoryPredictImageMetadata.fromJSON(object.predictImage)
        : undefined,
      targetImages: globalThis.Array.isArray(object?.targetImages)
        ? object.targetImages.map((e: any) => TargetImage.fromJSON(e))
        : [],
      lastSnapshot: isSet(object.lastSnapshot) ? LastSnapshot.fromJSON(object.lastSnapshot) : undefined,
      extraFields: globalThis.Array.isArray(object?.extraFields)
        ? object.extraFields.map((e: any) => ExtraTrajectoryField.fromJSON(e))
        : [],
      crosshairX: isSet(object.crosshairX) ? globalThis.Number(object.crosshairX) : 0,
      crosshairY: isSet(object.crosshairY) ? globalThis.Number(object.crosshairY) : 0,
    };
  },

  toJSON(message: TrajectoryData): unknown {
    const obj: any = {};
    if (message.predictImage !== undefined) {
      obj.predictImage = TrajectoryPredictImageMetadata.toJSON(message.predictImage);
    }
    if (message.targetImages?.length) {
      obj.targetImages = message.targetImages.map((e) => TargetImage.toJSON(e));
    }
    if (message.lastSnapshot !== undefined) {
      obj.lastSnapshot = LastSnapshot.toJSON(message.lastSnapshot);
    }
    if (message.extraFields?.length) {
      obj.extraFields = message.extraFields.map((e) => ExtraTrajectoryField.toJSON(e));
    }
    if (message.crosshairX !== 0) {
      obj.crosshairX = Math.round(message.crosshairX);
    }
    if (message.crosshairY !== 0) {
      obj.crosshairY = Math.round(message.crosshairY);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TrajectoryData>, I>>(base?: I): TrajectoryData {
    return TrajectoryData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TrajectoryData>, I>>(object: I): TrajectoryData {
    const message = createBaseTrajectoryData();
    message.predictImage = (object.predictImage !== undefined && object.predictImage !== null)
      ? TrajectoryPredictImageMetadata.fromPartial(object.predictImage)
      : undefined;
    message.targetImages = object.targetImages?.map((e) => TargetImage.fromPartial(e)) || [];
    message.lastSnapshot = (object.lastSnapshot !== undefined && object.lastSnapshot !== null)
      ? LastSnapshot.fromPartial(object.lastSnapshot)
      : undefined;
    message.extraFields = object.extraFields?.map((e) => ExtraTrajectoryField.fromPartial(e)) || [];
    message.crosshairX = object.crosshairX ?? 0;
    message.crosshairY = object.crosshairY ?? 0;
    return message;
  },
};

function createBaseGetTrajectoryPredictImageRequest(): GetTrajectoryPredictImageRequest {
  return { recordingName: "", rowId: 0, trajectoryId: 0 };
}

export const GetTrajectoryPredictImageRequest: MessageFns<GetTrajectoryPredictImageRequest> = {
  encode(message: GetTrajectoryPredictImageRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.recordingName !== "") {
      writer.uint32(10).string(message.recordingName);
    }
    if (message.rowId !== 0) {
      writer.uint32(16).uint32(message.rowId);
    }
    if (message.trajectoryId !== 0) {
      writer.uint32(24).uint32(message.trajectoryId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetTrajectoryPredictImageRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTrajectoryPredictImageRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recordingName = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.rowId = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.trajectoryId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTrajectoryPredictImageRequest {
    return {
      recordingName: isSet(object.recordingName) ? globalThis.String(object.recordingName) : "",
      rowId: isSet(object.rowId) ? globalThis.Number(object.rowId) : 0,
      trajectoryId: isSet(object.trajectoryId) ? globalThis.Number(object.trajectoryId) : 0,
    };
  },

  toJSON(message: GetTrajectoryPredictImageRequest): unknown {
    const obj: any = {};
    if (message.recordingName !== "") {
      obj.recordingName = message.recordingName;
    }
    if (message.rowId !== 0) {
      obj.rowId = Math.round(message.rowId);
    }
    if (message.trajectoryId !== 0) {
      obj.trajectoryId = Math.round(message.trajectoryId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTrajectoryPredictImageRequest>, I>>(
    base?: I,
  ): GetTrajectoryPredictImageRequest {
    return GetTrajectoryPredictImageRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTrajectoryPredictImageRequest>, I>>(
    object: I,
  ): GetTrajectoryPredictImageRequest {
    const message = createBaseGetTrajectoryPredictImageRequest();
    message.recordingName = object.recordingName ?? "";
    message.rowId = object.rowId ?? 0;
    message.trajectoryId = object.trajectoryId ?? 0;
    return message;
  },
};

function createBaseGetTrajectoryTargetImageRequest(): GetTrajectoryTargetImageRequest {
  return { recordingName: "", rowId: 0, trajectoryId: 0, imageName: "" };
}

export const GetTrajectoryTargetImageRequest: MessageFns<GetTrajectoryTargetImageRequest> = {
  encode(message: GetTrajectoryTargetImageRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.recordingName !== "") {
      writer.uint32(10).string(message.recordingName);
    }
    if (message.rowId !== 0) {
      writer.uint32(16).uint32(message.rowId);
    }
    if (message.trajectoryId !== 0) {
      writer.uint32(24).uint32(message.trajectoryId);
    }
    if (message.imageName !== "") {
      writer.uint32(34).string(message.imageName);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetTrajectoryTargetImageRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTrajectoryTargetImageRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recordingName = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.rowId = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.trajectoryId = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.imageName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTrajectoryTargetImageRequest {
    return {
      recordingName: isSet(object.recordingName) ? globalThis.String(object.recordingName) : "",
      rowId: isSet(object.rowId) ? globalThis.Number(object.rowId) : 0,
      trajectoryId: isSet(object.trajectoryId) ? globalThis.Number(object.trajectoryId) : 0,
      imageName: isSet(object.imageName) ? globalThis.String(object.imageName) : "",
    };
  },

  toJSON(message: GetTrajectoryTargetImageRequest): unknown {
    const obj: any = {};
    if (message.recordingName !== "") {
      obj.recordingName = message.recordingName;
    }
    if (message.rowId !== 0) {
      obj.rowId = Math.round(message.rowId);
    }
    if (message.trajectoryId !== 0) {
      obj.trajectoryId = Math.round(message.trajectoryId);
    }
    if (message.imageName !== "") {
      obj.imageName = message.imageName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTrajectoryTargetImageRequest>, I>>(base?: I): GetTrajectoryTargetImageRequest {
    return GetTrajectoryTargetImageRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTrajectoryTargetImageRequest>, I>>(
    object: I,
  ): GetTrajectoryTargetImageRequest {
    const message = createBaseGetTrajectoryTargetImageRequest();
    message.recordingName = object.recordingName ?? "";
    message.rowId = object.rowId ?? 0;
    message.trajectoryId = object.trajectoryId ?? 0;
    message.imageName = object.imageName ?? "";
    return message;
  },
};

function createBaseImageChunk(): ImageChunk {
  return { imageChunk: new Uint8Array(0) };
}

export const ImageChunk: MessageFns<ImageChunk> = {
  encode(message: ImageChunk, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.imageChunk.length !== 0) {
      writer.uint32(10).bytes(message.imageChunk);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ImageChunk {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageChunk();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.imageChunk = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageChunk {
    return { imageChunk: isSet(object.imageChunk) ? bytesFromBase64(object.imageChunk) : new Uint8Array(0) };
  },

  toJSON(message: ImageChunk): unknown {
    const obj: any = {};
    if (message.imageChunk.length !== 0) {
      obj.imageChunk = base64FromBytes(message.imageChunk);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageChunk>, I>>(base?: I): ImageChunk {
    return ImageChunk.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ImageChunk>, I>>(object: I): ImageChunk {
    const message = createBaseImageChunk();
    message.imageChunk = object.imageChunk ?? new Uint8Array(0);
    return message;
  },
};

function createBaseGetPredictImageMetadataRequest(): GetPredictImageMetadataRequest {
  return { recordingName: "", rowId: 0, imageName: "" };
}

export const GetPredictImageMetadataRequest: MessageFns<GetPredictImageMetadataRequest> = {
  encode(message: GetPredictImageMetadataRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.recordingName !== "") {
      writer.uint32(10).string(message.recordingName);
    }
    if (message.rowId !== 0) {
      writer.uint32(16).uint32(message.rowId);
    }
    if (message.imageName !== "") {
      writer.uint32(26).string(message.imageName);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetPredictImageMetadataRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPredictImageMetadataRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recordingName = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.rowId = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.imageName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetPredictImageMetadataRequest {
    return {
      recordingName: isSet(object.recordingName) ? globalThis.String(object.recordingName) : "",
      rowId: isSet(object.rowId) ? globalThis.Number(object.rowId) : 0,
      imageName: isSet(object.imageName) ? globalThis.String(object.imageName) : "",
    };
  },

  toJSON(message: GetPredictImageMetadataRequest): unknown {
    const obj: any = {};
    if (message.recordingName !== "") {
      obj.recordingName = message.recordingName;
    }
    if (message.rowId !== 0) {
      obj.rowId = Math.round(message.rowId);
    }
    if (message.imageName !== "") {
      obj.imageName = message.imageName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetPredictImageMetadataRequest>, I>>(base?: I): GetPredictImageMetadataRequest {
    return GetPredictImageMetadataRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetPredictImageMetadataRequest>, I>>(
    object: I,
  ): GetPredictImageMetadataRequest {
    const message = createBaseGetPredictImageMetadataRequest();
    message.recordingName = object.recordingName ?? "";
    message.rowId = object.rowId ?? 0;
    message.imageName = object.imageName ?? "";
    return message;
  },
};

function createBaseGetPredictImageMetadataResponse(): GetPredictImageMetadataResponse {
  return { ts: undefined, annotations: undefined, deepweedOutput: undefined, deepweedOutputBelowThreshold: undefined };
}

export const GetPredictImageMetadataResponse: MessageFns<GetPredictImageMetadataResponse> = {
  encode(message: GetPredictImageMetadataResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.ts !== undefined) {
      Timestamp.encode(message.ts, writer.uint32(10).fork()).join();
    }
    if (message.annotations !== undefined) {
      Annotations.encode(message.annotations, writer.uint32(18).fork()).join();
    }
    if (message.deepweedOutput !== undefined) {
      DeepweedOutput.encode(message.deepweedOutput, writer.uint32(26).fork()).join();
    }
    if (message.deepweedOutputBelowThreshold !== undefined) {
      DeepweedOutput.encode(message.deepweedOutputBelowThreshold, writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetPredictImageMetadataResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPredictImageMetadataResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ts = Timestamp.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.annotations = Annotations.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.deepweedOutput = DeepweedOutput.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.deepweedOutputBelowThreshold = DeepweedOutput.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetPredictImageMetadataResponse {
    return {
      ts: isSet(object.ts) ? Timestamp.fromJSON(object.ts) : undefined,
      annotations: isSet(object.annotations) ? Annotations.fromJSON(object.annotations) : undefined,
      deepweedOutput: isSet(object.deepweedOutput) ? DeepweedOutput.fromJSON(object.deepweedOutput) : undefined,
      deepweedOutputBelowThreshold: isSet(object.deepweedOutputBelowThreshold)
        ? DeepweedOutput.fromJSON(object.deepweedOutputBelowThreshold)
        : undefined,
    };
  },

  toJSON(message: GetPredictImageMetadataResponse): unknown {
    const obj: any = {};
    if (message.ts !== undefined) {
      obj.ts = Timestamp.toJSON(message.ts);
    }
    if (message.annotations !== undefined) {
      obj.annotations = Annotations.toJSON(message.annotations);
    }
    if (message.deepweedOutput !== undefined) {
      obj.deepweedOutput = DeepweedOutput.toJSON(message.deepweedOutput);
    }
    if (message.deepweedOutputBelowThreshold !== undefined) {
      obj.deepweedOutputBelowThreshold = DeepweedOutput.toJSON(message.deepweedOutputBelowThreshold);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetPredictImageMetadataResponse>, I>>(base?: I): GetPredictImageMetadataResponse {
    return GetPredictImageMetadataResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetPredictImageMetadataResponse>, I>>(
    object: I,
  ): GetPredictImageMetadataResponse {
    const message = createBaseGetPredictImageMetadataResponse();
    message.ts = (object.ts !== undefined && object.ts !== null) ? Timestamp.fromPartial(object.ts) : undefined;
    message.annotations = (object.annotations !== undefined && object.annotations !== null)
      ? Annotations.fromPartial(object.annotations)
      : undefined;
    message.deepweedOutput = (object.deepweedOutput !== undefined && object.deepweedOutput !== null)
      ? DeepweedOutput.fromPartial(object.deepweedOutput)
      : undefined;
    message.deepweedOutputBelowThreshold =
      (object.deepweedOutputBelowThreshold !== undefined && object.deepweedOutputBelowThreshold !== null)
        ? DeepweedOutput.fromPartial(object.deepweedOutputBelowThreshold)
        : undefined;
    return message;
  },
};

function createBaseGetPredictImageRequest(): GetPredictImageRequest {
  return { recordingName: "", rowId: 0, imageName: "" };
}

export const GetPredictImageRequest: MessageFns<GetPredictImageRequest> = {
  encode(message: GetPredictImageRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.recordingName !== "") {
      writer.uint32(10).string(message.recordingName);
    }
    if (message.rowId !== 0) {
      writer.uint32(16).uint32(message.rowId);
    }
    if (message.imageName !== "") {
      writer.uint32(26).string(message.imageName);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetPredictImageRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPredictImageRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recordingName = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.rowId = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.imageName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetPredictImageRequest {
    return {
      recordingName: isSet(object.recordingName) ? globalThis.String(object.recordingName) : "",
      rowId: isSet(object.rowId) ? globalThis.Number(object.rowId) : 0,
      imageName: isSet(object.imageName) ? globalThis.String(object.imageName) : "",
    };
  },

  toJSON(message: GetPredictImageRequest): unknown {
    const obj: any = {};
    if (message.recordingName !== "") {
      obj.recordingName = message.recordingName;
    }
    if (message.rowId !== 0) {
      obj.rowId = Math.round(message.rowId);
    }
    if (message.imageName !== "") {
      obj.imageName = message.imageName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetPredictImageRequest>, I>>(base?: I): GetPredictImageRequest {
    return GetPredictImageRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetPredictImageRequest>, I>>(object: I): GetPredictImageRequest {
    const message = createBaseGetPredictImageRequest();
    message.recordingName = object.recordingName ?? "";
    message.rowId = object.rowId ?? 0;
    message.imageName = object.imageName ?? "";
    return message;
  },
};

function createBaseStartUploadRequest(): StartUploadRequest {
  return { recordingName: "" };
}

export const StartUploadRequest: MessageFns<StartUploadRequest> = {
  encode(message: StartUploadRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.recordingName !== "") {
      writer.uint32(10).string(message.recordingName);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StartUploadRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartUploadRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recordingName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StartUploadRequest {
    return { recordingName: isSet(object.recordingName) ? globalThis.String(object.recordingName) : "" };
  },

  toJSON(message: StartUploadRequest): unknown {
    const obj: any = {};
    if (message.recordingName !== "") {
      obj.recordingName = message.recordingName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StartUploadRequest>, I>>(base?: I): StartUploadRequest {
    return StartUploadRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StartUploadRequest>, I>>(object: I): StartUploadRequest {
    const message = createBaseStartUploadRequest();
    message.recordingName = object.recordingName ?? "";
    return message;
  },
};

function createBaseGetNextUploadStateRequest(): GetNextUploadStateRequest {
  return { recordingName: "", ts: undefined };
}

export const GetNextUploadStateRequest: MessageFns<GetNextUploadStateRequest> = {
  encode(message: GetNextUploadStateRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.recordingName !== "") {
      writer.uint32(10).string(message.recordingName);
    }
    if (message.ts !== undefined) {
      Timestamp.encode(message.ts, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetNextUploadStateRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNextUploadStateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recordingName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ts = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNextUploadStateRequest {
    return {
      recordingName: isSet(object.recordingName) ? globalThis.String(object.recordingName) : "",
      ts: isSet(object.ts) ? Timestamp.fromJSON(object.ts) : undefined,
    };
  },

  toJSON(message: GetNextUploadStateRequest): unknown {
    const obj: any = {};
    if (message.recordingName !== "") {
      obj.recordingName = message.recordingName;
    }
    if (message.ts !== undefined) {
      obj.ts = Timestamp.toJSON(message.ts);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNextUploadStateRequest>, I>>(base?: I): GetNextUploadStateRequest {
    return GetNextUploadStateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNextUploadStateRequest>, I>>(object: I): GetNextUploadStateRequest {
    const message = createBaseGetNextUploadStateRequest();
    message.recordingName = object.recordingName ?? "";
    message.ts = (object.ts !== undefined && object.ts !== null) ? Timestamp.fromPartial(object.ts) : undefined;
    return message;
  },
};

function createBaseGetNextUploadStateResponse(): GetNextUploadStateResponse {
  return { uploadState: 0, ts: undefined, percentUploaded: 0 };
}

export const GetNextUploadStateResponse: MessageFns<GetNextUploadStateResponse> = {
  encode(message: GetNextUploadStateResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.uploadState !== 0) {
      writer.uint32(8).int32(message.uploadState);
    }
    if (message.ts !== undefined) {
      Timestamp.encode(message.ts, writer.uint32(18).fork()).join();
    }
    if (message.percentUploaded !== 0) {
      writer.uint32(24).uint32(message.percentUploaded);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetNextUploadStateResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNextUploadStateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.uploadState = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ts = Timestamp.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.percentUploaded = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNextUploadStateResponse {
    return {
      uploadState: isSet(object.uploadState) ? uploadStateFromJSON(object.uploadState) : 0,
      ts: isSet(object.ts) ? Timestamp.fromJSON(object.ts) : undefined,
      percentUploaded: isSet(object.percentUploaded) ? globalThis.Number(object.percentUploaded) : 0,
    };
  },

  toJSON(message: GetNextUploadStateResponse): unknown {
    const obj: any = {};
    if (message.uploadState !== 0) {
      obj.uploadState = uploadStateToJSON(message.uploadState);
    }
    if (message.ts !== undefined) {
      obj.ts = Timestamp.toJSON(message.ts);
    }
    if (message.percentUploaded !== 0) {
      obj.percentUploaded = Math.round(message.percentUploaded);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNextUploadStateResponse>, I>>(base?: I): GetNextUploadStateResponse {
    return GetNextUploadStateResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNextUploadStateResponse>, I>>(object: I): GetNextUploadStateResponse {
    const message = createBaseGetNextUploadStateResponse();
    message.uploadState = object.uploadState ?? 0;
    message.ts = (object.ts !== undefined && object.ts !== null) ? Timestamp.fromPartial(object.ts) : undefined;
    message.percentUploaded = object.percentUploaded ?? 0;
    return message;
  },
};

function createBaseGetDeepweedPredictionsCountRequest(): GetDeepweedPredictionsCountRequest {
  return { recordingName: "", row: 0, camId: 0 };
}

export const GetDeepweedPredictionsCountRequest: MessageFns<GetDeepweedPredictionsCountRequest> = {
  encode(message: GetDeepweedPredictionsCountRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.recordingName !== "") {
      writer.uint32(10).string(message.recordingName);
    }
    if (message.row !== 0) {
      writer.uint32(16).uint32(message.row);
    }
    if (message.camId !== 0) {
      writer.uint32(24).uint32(message.camId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDeepweedPredictionsCountRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeepweedPredictionsCountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recordingName = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.row = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.camId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeepweedPredictionsCountRequest {
    return {
      recordingName: isSet(object.recordingName) ? globalThis.String(object.recordingName) : "",
      row: isSet(object.row) ? globalThis.Number(object.row) : 0,
      camId: isSet(object.camId) ? globalThis.Number(object.camId) : 0,
    };
  },

  toJSON(message: GetDeepweedPredictionsCountRequest): unknown {
    const obj: any = {};
    if (message.recordingName !== "") {
      obj.recordingName = message.recordingName;
    }
    if (message.row !== 0) {
      obj.row = Math.round(message.row);
    }
    if (message.camId !== 0) {
      obj.camId = Math.round(message.camId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDeepweedPredictionsCountRequest>, I>>(
    base?: I,
  ): GetDeepweedPredictionsCountRequest {
    return GetDeepweedPredictionsCountRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDeepweedPredictionsCountRequest>, I>>(
    object: I,
  ): GetDeepweedPredictionsCountRequest {
    const message = createBaseGetDeepweedPredictionsCountRequest();
    message.recordingName = object.recordingName ?? "";
    message.row = object.row ?? 0;
    message.camId = object.camId ?? 0;
    return message;
  },
};

function createBaseGetDeepweedPredictionsCountResponse(): GetDeepweedPredictionsCountResponse {
  return { count: 0 };
}

export const GetDeepweedPredictionsCountResponse: MessageFns<GetDeepweedPredictionsCountResponse> = {
  encode(message: GetDeepweedPredictionsCountResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.count !== 0) {
      writer.uint32(8).uint32(message.count);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDeepweedPredictionsCountResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeepweedPredictionsCountResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.count = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeepweedPredictionsCountResponse {
    return { count: isSet(object.count) ? globalThis.Number(object.count) : 0 };
  },

  toJSON(message: GetDeepweedPredictionsCountResponse): unknown {
    const obj: any = {};
    if (message.count !== 0) {
      obj.count = Math.round(message.count);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDeepweedPredictionsCountResponse>, I>>(
    base?: I,
  ): GetDeepweedPredictionsCountResponse {
    return GetDeepweedPredictionsCountResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDeepweedPredictionsCountResponse>, I>>(
    object: I,
  ): GetDeepweedPredictionsCountResponse {
    const message = createBaseGetDeepweedPredictionsCountResponse();
    message.count = object.count ?? 0;
    return message;
  },
};

function createBaseGetDeepweedPredictionsRequest(): GetDeepweedPredictionsRequest {
  return { recordingName: "", row: 0, camId: 0, idx: 0 };
}

export const GetDeepweedPredictionsRequest: MessageFns<GetDeepweedPredictionsRequest> = {
  encode(message: GetDeepweedPredictionsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.recordingName !== "") {
      writer.uint32(10).string(message.recordingName);
    }
    if (message.row !== 0) {
      writer.uint32(16).uint32(message.row);
    }
    if (message.camId !== 0) {
      writer.uint32(24).uint32(message.camId);
    }
    if (message.idx !== 0) {
      writer.uint32(32).uint32(message.idx);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDeepweedPredictionsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeepweedPredictionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recordingName = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.row = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.camId = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.idx = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeepweedPredictionsRequest {
    return {
      recordingName: isSet(object.recordingName) ? globalThis.String(object.recordingName) : "",
      row: isSet(object.row) ? globalThis.Number(object.row) : 0,
      camId: isSet(object.camId) ? globalThis.Number(object.camId) : 0,
      idx: isSet(object.idx) ? globalThis.Number(object.idx) : 0,
    };
  },

  toJSON(message: GetDeepweedPredictionsRequest): unknown {
    const obj: any = {};
    if (message.recordingName !== "") {
      obj.recordingName = message.recordingName;
    }
    if (message.row !== 0) {
      obj.row = Math.round(message.row);
    }
    if (message.camId !== 0) {
      obj.camId = Math.round(message.camId);
    }
    if (message.idx !== 0) {
      obj.idx = Math.round(message.idx);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDeepweedPredictionsRequest>, I>>(base?: I): GetDeepweedPredictionsRequest {
    return GetDeepweedPredictionsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDeepweedPredictionsRequest>, I>>(
    object: I,
  ): GetDeepweedPredictionsRequest {
    const message = createBaseGetDeepweedPredictionsRequest();
    message.recordingName = object.recordingName ?? "";
    message.row = object.row ?? 0;
    message.camId = object.camId ?? 0;
    message.idx = object.idx ?? 0;
    return message;
  },
};

function createBaseGetDeepweedPredictionsResponse(): GetDeepweedPredictionsResponse {
  return { predictions: undefined };
}

export const GetDeepweedPredictionsResponse: MessageFns<GetDeepweedPredictionsResponse> = {
  encode(message: GetDeepweedPredictionsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.predictions !== undefined) {
      DeepweedPredictionRecord.encode(message.predictions, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDeepweedPredictionsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeepweedPredictionsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.predictions = DeepweedPredictionRecord.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeepweedPredictionsResponse {
    return {
      predictions: isSet(object.predictions) ? DeepweedPredictionRecord.fromJSON(object.predictions) : undefined,
    };
  },

  toJSON(message: GetDeepweedPredictionsResponse): unknown {
    const obj: any = {};
    if (message.predictions !== undefined) {
      obj.predictions = DeepweedPredictionRecord.toJSON(message.predictions);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDeepweedPredictionsResponse>, I>>(base?: I): GetDeepweedPredictionsResponse {
    return GetDeepweedPredictionsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDeepweedPredictionsResponse>, I>>(
    object: I,
  ): GetDeepweedPredictionsResponse {
    const message = createBaseGetDeepweedPredictionsResponse();
    message.predictions = (object.predictions !== undefined && object.predictions !== null)
      ? DeepweedPredictionRecord.fromPartial(object.predictions)
      : undefined;
    return message;
  },
};

function createBaseFindTrajectoryRequest(): FindTrajectoryRequest {
  return { recordingName: "", rowId: 0, trajectoryId: 0 };
}

export const FindTrajectoryRequest: MessageFns<FindTrajectoryRequest> = {
  encode(message: FindTrajectoryRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.recordingName !== "") {
      writer.uint32(10).string(message.recordingName);
    }
    if (message.rowId !== 0) {
      writer.uint32(16).uint32(message.rowId);
    }
    if (message.trajectoryId !== 0) {
      writer.uint32(24).uint32(message.trajectoryId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FindTrajectoryRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFindTrajectoryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recordingName = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.rowId = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.trajectoryId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FindTrajectoryRequest {
    return {
      recordingName: isSet(object.recordingName) ? globalThis.String(object.recordingName) : "",
      rowId: isSet(object.rowId) ? globalThis.Number(object.rowId) : 0,
      trajectoryId: isSet(object.trajectoryId) ? globalThis.Number(object.trajectoryId) : 0,
    };
  },

  toJSON(message: FindTrajectoryRequest): unknown {
    const obj: any = {};
    if (message.recordingName !== "") {
      obj.recordingName = message.recordingName;
    }
    if (message.rowId !== 0) {
      obj.rowId = Math.round(message.rowId);
    }
    if (message.trajectoryId !== 0) {
      obj.trajectoryId = Math.round(message.trajectoryId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FindTrajectoryRequest>, I>>(base?: I): FindTrajectoryRequest {
    return FindTrajectoryRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FindTrajectoryRequest>, I>>(object: I): FindTrajectoryRequest {
    const message = createBaseFindTrajectoryRequest();
    message.recordingName = object.recordingName ?? "";
    message.rowId = object.rowId ?? 0;
    message.trajectoryId = object.trajectoryId ?? 0;
    return message;
  },
};

function createBaseFindTrajectoryResponse(): FindTrajectoryResponse {
  return { snapshotId: 0, trajectory: undefined };
}

export const FindTrajectoryResponse: MessageFns<FindTrajectoryResponse> = {
  encode(message: FindTrajectoryResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.snapshotId !== 0) {
      writer.uint32(8).uint32(message.snapshotId);
    }
    if (message.trajectory !== undefined) {
      TrajectorySnapshot.encode(message.trajectory, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FindTrajectoryResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFindTrajectoryResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.snapshotId = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.trajectory = TrajectorySnapshot.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FindTrajectoryResponse {
    return {
      snapshotId: isSet(object.snapshotId) ? globalThis.Number(object.snapshotId) : 0,
      trajectory: isSet(object.trajectory) ? TrajectorySnapshot.fromJSON(object.trajectory) : undefined,
    };
  },

  toJSON(message: FindTrajectoryResponse): unknown {
    const obj: any = {};
    if (message.snapshotId !== 0) {
      obj.snapshotId = Math.round(message.snapshotId);
    }
    if (message.trajectory !== undefined) {
      obj.trajectory = TrajectorySnapshot.toJSON(message.trajectory);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FindTrajectoryResponse>, I>>(base?: I): FindTrajectoryResponse {
    return FindTrajectoryResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FindTrajectoryResponse>, I>>(object: I): FindTrajectoryResponse {
    const message = createBaseFindTrajectoryResponse();
    message.snapshotId = object.snapshotId ?? 0;
    message.trajectory = (object.trajectory !== undefined && object.trajectory !== null)
      ? TrajectorySnapshot.fromPartial(object.trajectory)
      : undefined;
    return message;
  },
};

function createBaseGetRotaryTicksRequest(): GetRotaryTicksRequest {
  return { recordingName: "", rowId: 0 };
}

export const GetRotaryTicksRequest: MessageFns<GetRotaryTicksRequest> = {
  encode(message: GetRotaryTicksRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.recordingName !== "") {
      writer.uint32(10).string(message.recordingName);
    }
    if (message.rowId !== 0) {
      writer.uint32(16).uint32(message.rowId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetRotaryTicksRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRotaryTicksRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recordingName = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.rowId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetRotaryTicksRequest {
    return {
      recordingName: isSet(object.recordingName) ? globalThis.String(object.recordingName) : "",
      rowId: isSet(object.rowId) ? globalThis.Number(object.rowId) : 0,
    };
  },

  toJSON(message: GetRotaryTicksRequest): unknown {
    const obj: any = {};
    if (message.recordingName !== "") {
      obj.recordingName = message.recordingName;
    }
    if (message.rowId !== 0) {
      obj.rowId = Math.round(message.rowId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetRotaryTicksRequest>, I>>(base?: I): GetRotaryTicksRequest {
    return GetRotaryTicksRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetRotaryTicksRequest>, I>>(object: I): GetRotaryTicksRequest {
    const message = createBaseGetRotaryTicksRequest();
    message.recordingName = object.recordingName ?? "";
    message.rowId = object.rowId ?? 0;
    return message;
  },
};

function createBaseGetRotaryTicksResponse(): GetRotaryTicksResponse {
  return { records: [], wheelEncoderResolution: 0 };
}

export const GetRotaryTicksResponse: MessageFns<GetRotaryTicksResponse> = {
  encode(message: GetRotaryTicksResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.records) {
      RotaryTicksRecord.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.wheelEncoderResolution !== 0) {
      writer.uint32(16).uint32(message.wheelEncoderResolution);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetRotaryTicksResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRotaryTicksResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.records.push(RotaryTicksRecord.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.wheelEncoderResolution = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetRotaryTicksResponse {
    return {
      records: globalThis.Array.isArray(object?.records)
        ? object.records.map((e: any) => RotaryTicksRecord.fromJSON(e))
        : [],
      wheelEncoderResolution: isSet(object.wheelEncoderResolution)
        ? globalThis.Number(object.wheelEncoderResolution)
        : 0,
    };
  },

  toJSON(message: GetRotaryTicksResponse): unknown {
    const obj: any = {};
    if (message.records?.length) {
      obj.records = message.records.map((e) => RotaryTicksRecord.toJSON(e));
    }
    if (message.wheelEncoderResolution !== 0) {
      obj.wheelEncoderResolution = Math.round(message.wheelEncoderResolution);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetRotaryTicksResponse>, I>>(base?: I): GetRotaryTicksResponse {
    return GetRotaryTicksResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetRotaryTicksResponse>, I>>(object: I): GetRotaryTicksResponse {
    const message = createBaseGetRotaryTicksResponse();
    message.records = object.records?.map((e) => RotaryTicksRecord.fromPartial(e)) || [];
    message.wheelEncoderResolution = object.wheelEncoderResolution ?? 0;
    return message;
  },
};

function bytesFromBase64(b64: string): Uint8Array {
  if ((globalThis as any).Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if ((globalThis as any).Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
