import {
  AlmanacTypeCategory,
  CategoryClassification,
} from "protos/almanac/almanac";
import { Card, Typography } from "@mui/material";
import { classes } from "portal/utils/theme";
import { ConfigCrop } from "protos/portal/configs";
import {
  getFineTuneMultipliers,
  withFineTuneMulipliers,
} from "portal/utils/almanac";
import { TuningControl } from "./TuningControl";
import { useTranslation } from "react-i18next";
import React, { FunctionComponent, ReactNode } from "react";

interface Props {
  category: AlmanacTypeCategory;
  description?: ReactNode;
  onChange: (newCategory: AlmanacTypeCategory) => void;
  title: string;
  crops?: ConfigCrop[];
}

export const BasicAlmanacCard: FunctionComponent<Props> = ({
  category,
  description,
  onChange,
  title,
  crops,
}) => {
  const { t } = useTranslation();
  const fineTuneMultipliers = getFineTuneMultipliers(category);
  const isCrop =
    category.type?.classification === CategoryClassification.CATEGORY_CROP;
  const crop = crops?.find((crop) => crop.id === category.type?.category);
  if (isCrop && crops && !crop) {
    return;
  }
  return (
    <Card className="p-8 my-4 flex-shrink-0">
      <Typography
        variant="h5"
        className={classes("text-lg", {
          "italic text-orange-200": crop && !crop.isEnabled,
        })}
      >
        {title}{" "}
        {crop && !crop.isEnabled ? ` (${t("utils.descriptors.disabled")})` : ""}
      </Typography>
      {description}
      <div className="flex flex-col md:flex-row items-center justify-between">
        <strong className="hidden md:block">
          {t("components.almanac.fineTuneTitle")}
        </strong>
        <TuningControl
          label={t("utils.descriptors.small")}
          value={fineTuneMultipliers.small}
          onChange={(value) =>
            onChange(
              withFineTuneMulipliers(category, {
                ...fineTuneMultipliers,
                small: value,
              })
            )
          }
          max={10}
          min={1}
        />
        <TuningControl
          label={t("utils.descriptors.medium")}
          value={fineTuneMultipliers.medium}
          onChange={(value) =>
            onChange(
              withFineTuneMulipliers(category, {
                ...fineTuneMultipliers,
                medium: value,
              })
            )
          }
          max={10}
          min={1}
        />
        <TuningControl
          label={t("utils.descriptors.large")}
          value={fineTuneMultipliers.large}
          onChange={(value) =>
            onChange(
              withFineTuneMulipliers(category, {
                ...fineTuneMultipliers,
                large: value,
              })
            )
          }
          max={10}
          min={1}
        />
      </div>
    </Card>
  );
};
