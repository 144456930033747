import {
  CircularProgress,
  IconButton,
  List,
  ListItem,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { TEXT_FIELD_DARK } from "portal/utils/theme";

import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/AddOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import React, { FunctionComponent, useState } from "react";

interface Props {
  list?: string[];
  loading?: boolean;
  onAdd: (added: string) => void;
  onRemove: (removed: string) => void;
}

export const ListManager: FunctionComponent<Props> = ({
  list,
  onAdd,
  onRemove,
  loading = false,
}) => {
  const { t } = useTranslation();
  const [newItem, setNewItem] = useState<string>("");
  return (
    <List>
      {list?.map((item) => (
        <ListItem
          key={item}
          secondaryAction={
            <IconButton
              aria-label={t("utils.actions.delete")}
              onClick={() => onRemove(item)}
            >
              {loading ? (
                <CircularProgress classes={{ circle: "text-white text-2xl" }} />
              ) : (
                <DeleteIcon className="text-white text-3xl" />
              )}
            </IconButton>
          }
          className="font-mono text-lg"
        >
          {item}
        </ListItem>
      ))}
      <ListItem
        secondaryAction={
          <LoadingButton
            loading={loading}
            disabled={loading || !newItem}
            className="text-white"
            classes={{ disabled: "opacity-50" }}
            onClick={() => onAdd(newItem)}
          >
            {t("utils.actions.add")} <AddIcon className="text-4xl" />
          </LoadingButton>
        }
      >
        <TextField
          {...TEXT_FIELD_DARK}
          disabled={loading}
          className="w-40"
          variant="outlined"
          placeholder={t("components.ListManager.new")}
          value={newItem}
          onChange={(event) => setNewItem(event.target.value)}
        />
      </ListItem>
    </List>
  );
};
