import { Drawer } from "./Drawer";
import { DrawerProps } from "portal/components/drawer/Drawer";
import { FeatureFlag, useFeatureFlag } from "portal/utils/hooks/useFeatureFlag";
import { ReactComponent as FleetIcon } from "portal/images/icons/slayer_side_outlined.svg";
import { Path } from "portal/utils/routing";
import { titleCase } from "portal/utils/strings";
import { useTranslation } from "react-i18next";
import React, { FunctionComponent } from "react";
import ReportIcon from "@mui/icons-material/AssessmentOutlined";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import SupportIcon from "@mui/icons-material/HelpCenterOutlined";
import UploadIcon from "@mui/icons-material/CloudUploadOutlined";
import UsersIcon from "@mui/icons-material/GroupsOutlined";

export const CustomerDrawer: FunctionComponent<DrawerProps> = ({
  items,
  ...props
}) => {
  const { isEnabled: hasReports } = useFeatureFlag(FeatureFlag.REPORTS);
  const { t } = useTranslation();

  return (
    <Drawer
      items={[
        {
          label: t("views.settings.title"),
          href: Path.SETTINGS,
          icon: <SettingsIcon />,
        },
        {
          label: t("views.fleet.title"),
          icon: <FleetIcon />,
          href: Path.FLEET,
        },
        ...(hasReports
          ? [
              {
                label: titleCase(t("models.reports.report_other")),
                icon: <ReportIcon />,
                href: Path.REPORTS,
              },
            ]
          : []),
        {
          label: titleCase(t("models.users.user_other")),
          icon: <UsersIcon />,
          href: Path.USERS,
        },
        {
          label: t("views.knowledge.title"),
          icon: <SupportIcon />,
          href: Path.KNOWLEDGE,
        },
        {
          label: t("views.offline.title"),
          icon: <UploadIcon />,
          href: Path.OFFLINE,
        },
        ...(items ?? []),
      ]}
      {...props}
    />
  );
};
