import { isEmpty, keys } from "../objects";
import { isUndefined } from "../identity";
import { useSelf } from "portal/state/store";

export enum FeatureFlag {
  REPORTS = "reports",
  ALMANAC = "almanac",
  JOBS = "jobs",
  UNVALIDATED_METRICS = "unvalidatedMetrics",
  SPATIAL = "spatial",
  TARGET_VELOCITY_ESTIMATOR = "velocityEstimator",
  EXPLORE = "explore",
}

export const toggleableByFlag: Record<FeatureFlag, boolean> = {
  [FeatureFlag.REPORTS]: false,
  [FeatureFlag.ALMANAC]: true,
  [FeatureFlag.JOBS]: false,
  [FeatureFlag.UNVALIDATED_METRICS]: true,
  [FeatureFlag.SPATIAL]: true,
  [FeatureFlag.TARGET_VELOCITY_ESTIMATOR]: true,
  [FeatureFlag.EXPLORE]: true,
};

interface FeatureFlagState {
  isEnabled: boolean | undefined;
  isLoading: boolean;
}

/**
 * Hook to get the value and loading status of a given feature flag for the current user.
 * Returns isLoading based on whether we have resolved the user state yet
 */
export const useFeatureFlag = (flag: FeatureFlag): FeatureFlagState => {
  const { user, isInternal, customer } = useSelf();

  // loading
  if (!user) {
    return { isEnabled: undefined, isLoading: true };
  }

  // internal user
  if (isInternal) {
    return { isEnabled: true, isLoading: false };
  }

  // no customer assigned
  if (isUndefined(customer) || isEmpty(customer)) {
    return { isEnabled: false, isLoading: false };
  }

  return {
    isEnabled: (keys(customer.featureFlags) as FeatureFlag[]).includes(flag),
    isLoading: false,
  };
};
