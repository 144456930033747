import { getFleetPath, getRobotPath } from "portal/utils/routing";
import { Modelinator } from "portal/components/modelinator/Modelinator";
import { Navigate, useParams } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { FunctionComponent } from "react";

export const RobotModelinator: FunctionComponent = withAuthenticationRequired(
  function RobotModelinator() {
    const { serial, cropId, modelId } = useParams();
    if (!serial || !cropId || !modelId) {
      console.error("Missing path params:", { serial, cropId, modelId });
      return (
        <Navigate
          to={serial ? getRobotPath(serial) : getFleetPath()}
          replace={true}
        />
      );
    }
    return <Modelinator serial={serial} cropId={cropId} modelId={modelId} />;
  }
);
