import { Button, Card, CardContent, Typography } from "@mui/material";
import { classes } from "portal/utils/theme";
import { entries, keys } from "portal/utils/objects";
import { useDropzone } from "react-dropzone";
import { useLazyGetS3UploadUrlQuery } from "portal/state/portalApi";
import { useLazyPopups } from "portal/utils/hooks/useApiPopups";
import { useTranslation } from "react-i18next";
import React, { FunctionComponent, useState } from "react";
import UploadIcon from "@mui/icons-material/CloudUploadOutlined";

type Uploads = Record<string, number>;

export const CarbonUploadCard: FunctionComponent = () => {
  const { t } = useTranslation();
  const [uploads, setUploads] = useState<Uploads>({});
  const uploadCount = keys(uploads).length;
  const [getS3UploadUrl] = useLazyPopups(useLazyGetS3UploadUrlQuery(), {
    success: t("utils.actions.uploaded"),
  });

  const uploadFiles = async (files?: File[]): Promise<void> => {
    for (const file of files ?? []) {
      setUploads((uploads) => ({ ...uploads, [file.name]: 0 }));

      const url = await getS3UploadUrl(file.name).unwrap();

      const request = new XMLHttpRequest();
      request.upload.addEventListener("progress", (event) => {
        const percent = (event.loaded / event.total) * 100;
        setUploads((uploads) => ({
          ...uploads,
          [file.name]: percent,
        }));
      });
      request.addEventListener("readystatechange", () => {
        if (request.readyState !== 4) {
          return;
        }
        if (request.status !== 200) {
          console.error(`Failed to upload ${file.name}`);
        }
        setUploads((uploads) => {
          const newUploads = { ...uploads };
          delete newUploads[file.name];
          return newUploads;
        });
      });
      request.open("PUT", url, true);
      request.send(file);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: uploadFiles,
  });

  return (
    <Card
      className={classes("w-full sm:w-1/2 relative", {
        "bg-blue-500": isDragActive,
      })}
      {...getRootProps()}
    >
      <CardContent>
        <Typography variant="h4" className="text-lg flex items-center mb-2">
          {uploadCount
            ? t("views.offline.uploading", {
                subject: t("views.offline.file", { count: uploadCount }),
              })
            : t("views.offline.upload")}
        </Typography>
        {!uploadCount && (
          <Typography variant="body1" className="mb-6">
            {t("views.offline.drop")}
          </Typography>
        )}
        <input {...getInputProps()} />
        {!uploadCount && (
          <div className="w-full flex items-center justify-center mt-14">
            <Button
              variant="contained"
              color="secondary"
              size="large"
              startIcon={<UploadIcon />}
            >
              {t("views.offline.select")}
            </Button>
          </div>
        )}
      </CardContent>
      <div className="flex flex-col items-start">
        {entries(uploads).map(([name, percent]) => (
          <div
            className={classes(
              "flex items-center justify-start",
              "bg-blue-300",
              "text-blue-500 text-left text-ellipsis text-xs whitespace-nowrap",
              "p-4"
            )}
            style={{ width: `${percent}%` }}
            key={name}
          >
            {name}
          </div>
        ))}
      </div>
    </Card>
  );
};
